import { useEffect, useState } from "react";
import { LCMDHookResult } from "../../app/types/LCMDHookResult";
import { CoreExtensions } from "../../extensions/core.extensions";
import { useLCMD } from "../../app/LCMContext";

let calendarSettingsCache;

// @todo: add functionality to also set and update calendar settings
/**
 * Hook to get the Current Default Calendar Settings for the current active Project
 */
export function useCalendarSettings() {
    const LCMD = useLCMD();
    const [hookResult, setHookResult] = useState<LCMDHookResult<any>>({
        isLoading: !calendarSettingsCache,
        isError: false,
        error: undefined,
        data: calendarSettingsCache,
    });

    useEffect(() => {
        LCMD.getProjectExtension(CoreExtensions.CALENDAR_DEFAULT_SETTINGS, (error, data: any) => {
            calendarSettingsCache = data;
            setHookResult({ ...hookResult, isLoading: false, data });
        });
    }, []);

    return hookResult;
}
