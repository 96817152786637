import * as React from "react";
import { ReactNode } from "react";

import { IStackTokens, mergeStyleSets, Stack } from "@fluentui/react";

export type LayoutProps = {
    children: ReactNode;
};

const classNames = mergeStyleSets({
    layout: {
        backgroundColor: "#E5E5E5",
        overflow: "auto",
        width: "100%", // need to fill full width remove when Dashboard component will be insert not in flex box
    },
});

const tokenProps: IStackTokens = {
    padding: 15,
    childrenGap: 20,
};

function Layout({ children }: LayoutProps) {
    return (
        <Stack tokens={tokenProps} className={classNames.layout}>
            {children}
        </Stack>
    );
}

export { Layout };
