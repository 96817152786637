import * as React from "react";
import {
    DateToMonday,
    FILTER_SPECIAL_VALUE,
    FILTER_SPECIAL_UNIT_MASK,
    FILTER_SPECIAL_UNIT_MONTH,
    FILTER_SPECIAL_VALUE_MASK,
    assert,
    FILTER_SPECIAL_UNIT_WEEKS,
} from "lcmd2framework";
import { IChoiceGroupOption, ChoiceGroup, Stack, mergeStyleSets } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";
import { filterToStartEndDate } from "../../../../core/lcmd2core";

import { StartEndDateControl } from "../../../utils/start-end-date-control";

const classNames = mergeStyleSets({
    container: {
        boxSizing: "border-box",
        padding: 24,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        rowGap: 5,
    },
});

export const SelectionDatesConst = {
    CUSTOM: -1,
    EVERY: 0,
    FOUR_WEEKS: 4,
    SIX_WEEKS: 6,
    FOUR_MONTH: "PM4",
};

const SelectionDatesTypesOptions = [
    {
        key: SelectionDatesConst.EVERY.toString(),
        styles: { root: { backgroundColor: "white" } },
    },
    {
        key: SelectionDatesConst.FOUR_WEEKS.toString(),
        styles: { root: { backgroundColor: "white" } },
        data: "db",
    },
    {
        key: SelectionDatesConst.SIX_WEEKS.toString(),
        styles: { root: { backgroundColor: "white" } },
        data: "db",
    },
    {
        key: SelectionDatesConst.FOUR_MONTH.toString(),
        styles: { root: { backgroundColor: "white" } },
    },
    {
        key: SelectionDatesConst.CUSTOM.toString(),
        styles: { root: { backgroundColor: "white" } },
    },
];

function composeSelectedKey(dateFilterEnd) {
    let result = SelectionDatesConst.CUSTOM.toString();

    if (0 <= dateFilterEnd && dateFilterEnd < FILTER_SPECIAL_VALUE) {
        const unit = dateFilterEnd & FILTER_SPECIAL_UNIT_MASK;

        if (FILTER_SPECIAL_UNIT_MONTH === unit) {
            result = "PM" + (dateFilterEnd & FILTER_SPECIAL_VALUE_MASK).toString(10);
        } else {
            assert(FILTER_SPECIAL_UNIT_WEEKS === unit);
            result = (dateFilterEnd & FILTER_SPECIAL_VALUE_MASK).toString(10);
        }
    }

    return result;
}

export function DateScreen(props) {
    const {
        dialog,
        route: {
            params: { initialDateFilter, onSelected, filterOptions, info },
        },
    } = props;

    const isDB = filterOptions?.modeDailyBoard;
    const [dateFilterStart, setDateFilterStart] = React.useState(initialDateFilter ? initialDateFilter[0] : 0);
    const [dateFilterEnd, setDateFilterEnd] = React.useState(
        initialDateFilter ? initialDateFilter[1] : SelectionDatesConst.EVERY,
    );

    const choiceSelectedKey = composeSelectedKey(dateFilterEnd);

    React.useLayoutEffect(() => {
        dialog.setOptions({
            title: intl.get("filter.date.title"),
            onOK: () => {
                if (SelectionDatesConst.EVERY === dateFilterEnd) {
                    onSelected([]);
                } else if (dateFilterEnd > 0) {
                    onSelected([dateFilterStart, dateFilterEnd]);
                }
            },
            onClose: onSelected.bind(null, undefined),
            onCancel: onSelected.bind(null, undefined),
        });
    }, [dialog, dateFilterStart, dateFilterEnd]);

    const onChoiceSelected = React.useCallback(
        (key: string) => {
            if (key.startsWith("PM")) {
                const v = Number.parseInt(key.substring(2), 10);
                setDateFilterEnd(v | FILTER_SPECIAL_UNIT_MONTH);
            } else {
                const v = Number.parseInt(key, 10);
                setDateFilterEnd(v);
            }
        },
        [setDateFilterEnd],
    );

    const choiceOptions: IChoiceGroupOption[] = React.useMemo(
        () =>
            SelectionDatesTypesOptions.filter((option) => !isDB || option.data).map((option) => {
                return {
                    ...option,
                    text: intl.get(["filter.date", option.key, "text"].join(".")),
                    //title: intl.get(["filter.date", option.key, "title"].join('.')),
                    iconProps: {
                        iconName: intl.get(["filter.date", option.key, "icon"].join(".")),
                    },
                    onSelect: onChoiceSelected.bind(null, option.key),
                    onClick: onChoiceSelected.bind(null, option.key),
                };
            }),
        [onChoiceSelected],
    );

    const onIsDateFilterChange = React.useCallback(
        (start, end) => {
            if (isDB && start) {
                // for the DailyBaord => always start at a monday
                start = DateToMonday(start);
            }
            const _start = start ? start.getTime() : 0;
            const _end = end ? end.getTime() : 0;
            if (_start > FILTER_SPECIAL_VALUE) {
                setDateFilterStart(_start);
            }
            if (_start > FILTER_SPECIAL_VALUE && _end > FILTER_SPECIAL_VALUE && _start <= _end) {
                setDateFilterEnd(_end);
            }
        },
        [setDateFilterStart, setDateFilterEnd, isDB],
    );

    const startEnd = React.useMemo(() => {
        if (SelectionDatesConst.CUSTOM === dateFilterEnd) {
            return {
                start: null,
                end: null,
            };
        } else {
            assert("number" === typeof dateFilterStart && dateFilterStart >= 0);
            if ("number" === typeof dateFilterEnd && dateFilterEnd > 0) {
                return filterToStartEndDate([dateFilterStart, dateFilterEnd]);
            } else {
                const helper = filterToStartEndDate(
                    [dateFilterStart, 4 /* 4 weeks, we will throw it away anyway*/],
                    true,
                );
                return {
                    start: helper.start,
                    end: null,
                };
            }
        }
    }, [dateFilterStart, dateFilterEnd]);

    const isEvery = SelectionDatesConst.EVERY.toString() === choiceSelectedKey;
    const disabledStart = isEvery;
    const disabledEnd = SelectionDatesConst.CUSTOM.toString() !== choiceSelectedKey;

    return (
        <div className={classNames.container}>
            <Stack horizontal horizontalAlign="center">
                <ChoiceGroup selectedKey={choiceSelectedKey} options={choiceOptions} />
            </Stack>

            <StartEndDateControl
                overrideStartEnd={isEvery ? info : undefined}
                defaultStart={startEnd.start || undefined}
                defaultEnd={startEnd.end || undefined}
                onChange={onIsDateFilterChange}
                utc={false}
                // TODO: show dates when start and end disabled
                disabledStart={disabledStart}
                disabledEnd={disabledEnd}
            />
        </div>
    );
}
