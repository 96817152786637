import { ItemPriority } from "../../ToDo/interface";
import { intl } from "@/legacy/GlobalHelperReact";
import * as React from "react";

export function Priority({ priorityNumber, hideLabel = true }) {
    const stylePriority = {
        width: 2,
        height: 8,
        marginRight: 2,
        borderRadius: 11,
        backgroundColor: "#009BD4",
        display: "inline-block",
    };

    const labelStyles = {
        marginLeft: 10,
    };

    return (
        <div>
            {ItemPriority.LOW === priorityNumber ? (
                <>
                    <span style={{ ...stylePriority, height: 4 }}></span>
                    <span style={{ ...stylePriority, backgroundColor: "#999EA1", height: 2 }}></span>
                    <span style={{ ...stylePriority, backgroundColor: "#999EA1", height: 2 }}></span>
                    {!hideLabel ? <span style={labelStyles}>{intl.get("ToDo.priority.low")}</span> : null}
                </>
            ) : null}

            {ItemPriority.MEDIUM === priorityNumber ? (
                <>
                    <span style={{ ...stylePriority, backgroundColor: "#EBB600", height: 4 }}></span>
                    <span style={{ ...stylePriority, backgroundColor: "#EBB600", height: 6 }}></span>
                    <span style={{ ...stylePriority, backgroundColor: "#999EA1", height: 2 }}></span>
                    {!hideLabel ? <span style={labelStyles}>{intl.get("ToDo.priority.medium")}</span> : null}
                </>
            ) : null}

            {ItemPriority.HIGH === priorityNumber || ItemPriority.HIGHER === priorityNumber ? (
                <>
                    <span style={{ ...stylePriority, backgroundColor: "#D83B01", height: 4 }}></span>
                    <span style={{ ...stylePriority, backgroundColor: "#D83B01", height: 6 }}></span>
                    <span style={{ ...stylePriority, backgroundColor: "#D83B01" }}></span>
                    {!hideLabel ? <span style={labelStyles}>{intl.get("ToDo.priority.high")}</span> : null}
                </>
            ) : null}
        </div>
    );
}
