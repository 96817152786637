import { Icon, mergeStyleSets, Stack, StackItem, Toggle } from "@fluentui/react";
import { SettingsHeader } from "../../common/settingsHeader/settingsHeader";
import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { SettingsText } from "../../common/settingsText/SettingsText";

const styles = mergeStyleSets({
    iconContainer: {
        position: "relative",
        height: "140px",
    },
    icon: {
        fontSize: "20px",
        position: "absolute",
        bottom: "25px",
        left: "5px",
    },
});

export type CanvasSettings = {
    automaticTextResizingChecked: boolean;
    onChange: (newValue) => void;
};

export function CanvasSettings({ automaticTextResizingChecked, onChange }: CanvasSettings) {
    let checked = automaticTextResizingChecked;
    const toggleChecked = () => {
        setCheckedWithCallback(!checked);
    };

    function setCheckedWithCallback(newValue: boolean) {
        checked = newValue;
        onChange(newValue);
    }

    if (typeof automaticTextResizingChecked !== "boolean") {
        throw new Error("automaticTextResizingChecked param needs to be type of boolean");
    }

    if (typeof onChange !== "function") {
        throw new Error("onChange param needs to be type of function");
    }

    return (
        <Stack tokens={{ childrenGap: 20 }}>
            <StackItem>
                <SettingsHeader headerText={intl.get("PreferenceSettings.Canvas.header")} />
            </StackItem>
            <StackItem styles={{ root: { padding: "0 22px" } }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <Stack tokens={{ childrenGap: 16 }}>
                            <StackItem>
                                <SettingsText
                                    title={intl.get("PreferenceSettings.Canvas.AutomaticTextResizing.header")}
                                    description={intl.get(
                                        "PreferenceSettings.Canvas.AutomaticTextResizing.description",
                                    )}
                                />
                            </StackItem>
                            <StackItem>
                                <Toggle
                                    checked={checked}
                                    onText={intl.get(
                                        "PreferenceSettings.Canvas.AutomaticTextResizing.toggleSettings.on",
                                    )}
                                    offText={intl.get(
                                        "PreferenceSettings.Canvas.AutomaticTextResizing.toggleSettings.off",
                                    )}
                                    onChange={toggleChecked}
                                />
                            </StackItem>
                        </Stack>
                    </StackItem>
                    <StackItem>
                        <Stack horizontal tokens={{ childrenGap: 16 }}>
                            <StackItem
                                className={styles.iconContainer}
                                onClick={() => {
                                    checked && setCheckedWithCallback(false);
                                }}
                            >
                                <Icon
                                    iconName={"CompletedSolid"}
                                    data-testid={"iconWhenUnchecked"}
                                    className={styles.icon}
                                    styles={{ root: { display: !checked ? "inline" : "none" } }}
                                />
                                <img
                                    data-testid={"imageWhenUnchecked"}
                                    style={{ borderRadius: "5px", border: !checked ? "2px solid black" : "0px" }}
                                    src={
                                        intl.options.currentLocale === "de-DE"
                                            ? "/img/settings/preferences/SameSize_DE.png"
                                            : "/img/settings/preferences/SameSize_EN.png"
                                    }
                                />
                            </StackItem>
                            <StackItem
                                className={styles.iconContainer}
                                onClick={() => {
                                    !checked && setCheckedWithCallback(true);
                                }}
                            >
                                <Icon
                                    iconName={"CompletedSolid"}
                                    data-testid={"iconWhenChecked"}
                                    className={styles.icon}
                                    styles={{ root: { display: checked ? "inline" : "none" } }}
                                />
                                <img
                                    data-testid={"imageWhenChecked"}
                                    style={{ borderRadius: "5px", border: checked ? "2px solid black" : "0px" }}
                                    src={
                                        intl.options.currentLocale === "de-DE"
                                            ? "/img/settings/preferences/AutomaticTextResizing_DE.png"
                                            : "/img/settings/preferences/AutomaticTextResizing_EN.png"
                                    }
                                />
                            </StackItem>
                        </Stack>
                    </StackItem>
                </Stack>
            </StackItem>
        </Stack>
    );
}
