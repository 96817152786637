import * as React from "react";
import { Fragment, useContext, useState } from "react";
import { DefaultButton, IconButton, IContextualMenuProps, mergeStyleSets, Stack, Text } from "@fluentui/react";
import { EditTradeModal } from "./edit-trade/EditTradeModal";
import { EditNameField } from "./EditNameField";
import { Icons } from "../../RegisteredIcons";

import {
    DigitalPlanningBoardSelectionEvent,
    DigitalPlanningBoardTradeDetails,
    DigitalPlanningBoardTradesEvent,
    intl,
    LocalizedDatePicker,
    UNIT_TYPES,
} from "lcmd2framework";
import { weekNumber } from "weeknumber";
import { getCurrentLanguage } from "../../utils/date/locale";
import { ProcessDependenciesModal } from "./process-dependencies-modal";
import { TradeLabel } from "../../common/trade/TradeLabel";
import { decimalToRgb } from "../../utils/color/decimal-to-rgb";
import { useBoolean } from "@fluentui/react-hooks";
import { useLCMD } from "../../../app/LCMContext";
import { toUTCDate } from "../../Utils";
import { SidebarContext } from "../../../app/components/SidebarHost";
import {
    LCMDContextTaskDetailsResult,
    LCMDContextTaskDetailsResultDependencyDetails,
    LCMDContextTaskDetailsResultTradeDetails,
    LCMDContextUnitType,
} from "../../../app/LCMDContextTypes";
import { useCanvasNavigation } from "@/components/hooks/useCanvasNavigation.hook";
import { dependencyTypes } from "@/components/utils/maps";
import {Counter} from "@/components/common/counter/CounterV2";

export enum DependencyType {
    PREDECESSOR,
    SUCCESSOR,
}

const styles = mergeStyleSets({
    topContent: {
        borderBottom: "1px solid #E1E4E5",
    },
    label: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "-0.02em",
    },
    main: {
        height: "calc(100% - 100px)",
    },

    header: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "-0.015em",
        color: "#565C60",
        marginBottom: "12px",
    },
    trade: {
        borderBottom: "1px solid #E1E4E5",
        padding: "12px 16px",
    },
    duration: {
        borderBottom: "1px solid #E1E4E5",
        padding: "12px 16px",
    },
    underDateText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
    },
    workforce: {
        borderBottom: "1px solid #E1E4E5",
        padding: "12px 16px",
    },
    predecessor: {
        padding: "12px 16px",
    },
    successor: {
        padding: "12px 16px",
    },
    processText: {
        paddingLeft: "10px",
    },
    secondaryProcessText: {
        paddingLeft: "10px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "-0.01em",

        "& :first-child": {
            color: "#999EA1",
        },
        "&: last-child": {
            color: "#565C60",
        },
    },
    dependecyConflictBackground: {
        backgroundColor: "#FFC80A33",
        borderRadius: "4px",
    },
});

function DependencyDetails({
    dependency,
    onDependencyDetailsChanged,
    onDeleteDependency,
}: {
    dependency: LCMDContextTaskDetailsResultDependencyDetails;
    onDependencyDetailsChanged: (editedDependency: LCMDContextTaskDetailsResultDependencyDetails) => void;
    onDeleteDependency: (dependencyToDelete: LCMDContextTaskDetailsResultDependencyDetails) => void;
}) {
    const iconStyle = { color: "#565C60" };
    const menuItems: IContextualMenuProps = {
        items: [
            {
                key: "com.lcmd.dependency.edit",
                text: intl.get("DependencyDetails.menuList.edit.text"),
                iconProps: {
                    iconName: "Edit",
                    style: iconStyle,
                },
                onClick: () => {
                    setShowDependencyEditModal(true);
                },
            },
            {
                key: "com.lcmd.dependency.delete",
                text: intl.get("DependencyDetails.menuList.delete.text"),
                iconProps: {
                    iconName: "Delete",
                    style: iconStyle,
                },
                onClick: () => {
                    onDeleteDependency(dependency);
                },
            },
        ],
    };

    const [showDependencyEditModal, setShowDependencyEditModal] = useState(false);

    return (
        <Stack
            horizontal
            horizontalAlign={"space-between"}
            className={dependency.conflictOffset ? `${styles.dependecyConflictBackground}` : ""}
        >
            <Stack.Item>
                <Text className={styles.processText}>{dependency.targetName}</Text>
                <div className={styles.secondaryProcessText}>
                    <span>#{dependency.targetId} · </span>
                    <span>
                        {intl.get(`DependencyDetails.dependencyRelation.${dependencyTypes.get(dependency.type)}`)}
                        {Math.sign(dependency.lag) >= 0 ? "+" : ""}
                        {dependency.lag}
                        {UNIT_TYPES[dependency.unit]}
                    </span>
                </div>
            </Stack.Item>
            <Stack.Item>
                <IconButton
                    iconProps={{ iconName: "MoreVertical", style: iconStyle }}
                    menuProps={menuItems}
                    onRenderMenuIcon={() => null}
                />
                <ProcessDependenciesModal
                    show={showDependencyEditModal}
                    processDependency={dependency}
                    onCancel={() => {
                        setShowDependencyEditModal(false);
                    }}
                    onClose={(editedDependency) => {
                        setShowDependencyEditModal(false);
                        onDependencyDetailsChanged(editedDependency);
                    }}
                />
            </Stack.Item>
        </Stack>
    );
}

export function DependencyOverview({
    dependencies,
    className,
    dependencyType,
    indeterminate,
    onDependencyDetailsChanged,
    onDeleteDependency,
    onClick = () => {},
}: {
    dependencies: LCMDContextTaskDetailsResultDependencyDetails[] | null;
    className: string;
    dependencyType: DependencyType;
    indeterminate?: boolean;
    onDependencyDetailsChanged: (
        editedDependency: LCMDContextTaskDetailsResultDependencyDetails,
        dependencyType,
    ) => void;
    onDeleteDependency: (dependencyToDelete: LCMDContextTaskDetailsResultDependencyDetails, dependencyType) => void;
    onClick?: (dependency: LCMDContextTaskDetailsResultDependencyDetails) => void;
}) {
    return (
        <Stack className={className}>
            <Stack.Item>
                <Text className={styles.header}>
                    {dependencyType === DependencyType.PREDECESSOR
                        ? intl.get("DependencyOverview.predecessor")
                        : intl.get("DependencyOverview.successor")}
                </Text>
            </Stack.Item>
            {dependencies && !indeterminate
                ? dependencies.map((dependenciy: LCMDContextTaskDetailsResultDependencyDetails, i) => {
                      return (
                          <div
                              key={`${dependenciy.pid}+${dependenciy.targetId}-type-${dependenciy.type}`}
                              onClick={() => {
                                  onClick(dependenciy);
                              }}
                          >
                              <DependencyDetails
                                  dependency={dependenciy}
                                  onDependencyDetailsChanged={(editedDependency) => {
                                      onDependencyDetailsChanged(editedDependency, dependencyType);
                                  }}
                                  onDeleteDependency={(dependencyToDelete) => {
                                      onDeleteDependency(dependencyToDelete, dependencyType);
                                  }}
                              />
                          </div>
                      );
                  })
                : undefined}
            {indeterminate && <Text>{intl.get("DependencyOverview.mixed")}</Text>}
        </Stack>
    );
}

export function CountableSection({
    classNames,
    value,
    headerText,
    footerText,
    unit,
    onValueChange,
}: {
    classNames: { root?: string; header?: string };
    value: number;
    headerText: string;
    footerText?: string;
    unit: string;
    onValueChange: (changedValue: number) => void;
}) {
    return (
        <Stack className={classNames.root} tokens={{ childrenGap: "10px" }}>
            <Stack.Item>
                <Text className={classNames.header}>{headerText}</Text>
            </Stack.Item>
            <Stack style={{ marginBottom: "16px" }}>
                <Stack horizontal verticalAlign={"center"}>
                    <Stack.Item>
                        <Counter value={value} onChange={onValueChange} />
                    </Stack.Item>
                    <Stack.Item style={{ marginLeft: "16px" }}>
                        <Text>{unit}</Text>
                    </Stack.Item>
                </Stack>
                <Stack style={{ width: "100%" }}>
                    <Text>{footerText}</Text>
                </Stack>
            </Stack>
        </Stack>
    );
}

function NoProcessSelectedView() {
    return (
        <Stack styles={{ root: { padding: "20px" } }}>
            <Text variant={"mediumPlus"}>{intl.get("NoProcessSelectedView.text")}</Text>
        </Stack>
    );
}

export function DetailsView() {
    const [name, setName] = useState<string>(null);
    const [trades, setTrades] = useState<LCMDContextTaskDetailsResultTradeDetails[]>(null);
    const [duration, setDuration] = useState<number>(0);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [workforce, setWorkforce] = useState<number>(0);
    const [predecessors, setPredecessors] = useState<any[]>(null);
    const [successors, setSuccessors] = useState<any[]>(null);
    const [tradesIndeterminate, setTradesIndeterminate] = useState(false);
    const [processDetails, setProcessDetails] = useState<LCMDContextTaskDetailsResult>(null);
    const [tradeList, setTradeList] = useState<DigitalPlanningBoardTradesEvent>(null);
    // const [checklists, setChecklists] = useState<AttachedChecklist[]>([]);
    const [editTradeModalVisible, { setTrue: showTradeModal, setFalse: hideTradeModal }] = useBoolean(false);
    const [
        editTradeForMultipleProcessesModalVisible,
        { setTrue: showTradeForMultipleProcessesModal, setFalse: hideTradeForMultipleProcessesModal },
    ] = useBoolean(false);
    let cwStart = startDate ? weekNumber(new Date(startDate)) : -1;
    let cwEnd = endDate ? weekNumber(new Date(endDate)) : -1;

    const propsContext = useContext(SidebarContext);

    const LCMD = useLCMD();
    const { goTo } = useCanvasNavigation();
    const [selectedProcesses, setSelectedProcesses] = React.useState<DigitalPlanningBoardSelectionEvent>(null);
    const iconStyle = { color: "#565C60" };

    propsContext.sidebarCtx.useSelectionEffect(
        (ev: DigitalPlanningBoardSelectionEvent) => {
            setSelectedProcesses(ev);
        },
        [setSelectedProcesses],
    );

    /*    useEffect(() => {
            if (selectedProcesses) {
                attachedChecklistsView(selectedProcesses);
            }
        }, [selectedProcesses]);*/

    LCMD.useProcessDetailsEffect(
        selectedProcesses?.pid,
        selectedProcesses?.isWhiteboard,
        (error, data) => {
            if (!data) {
                return;
            }
            setProcessDetails(data);
            setName(data.name?.value || "");
            setTradesIndeterminate(data.trades?.indeterminate || false);
            setTrades(data.trades?.value || null);
            let durationValue = 0;
            if (data.duration) {
                durationValue = data.duration.wd ? data.duration.wd : data.duration.value ? data.duration.value : 0;
            }
            setDuration(durationValue);
            setStartDate(data.start?.date || null);
            setEndDate(data.end?.date || null);
            setWorkforce(data.workforce?.value || 0);
            setPredecessors(data.predecessors?.value || null);
            setSuccessors(data.successors?.value || null);
            cwStart = startDate ? weekNumber(new Date(data.start?.value || 0)) : -1;
            cwEnd = endDate ? weekNumber(new Date(data.end?.value || 0)) : -1;
        },
        [],
        propsContext.ppTS,
    );

    propsContext.sidebarCtx.useTradesEffect(
        (ev: DigitalPlanningBoardTradesEvent) => {
            setTradeList(ev);
        },
        [setTradeList],
    );

    function getDependencyTargets(
        dependency: LCMDContextTaskDetailsResultDependencyDetails,
        dependencyType: DependencyType,
    ) {
        let src = dependency.pid;
        let dest = dependency.targetId;
        if (dependencyType == DependencyType.SUCCESSOR) {
            src = dependency.targetId;
            dest = dependency.pid;
        }
        return { src, dest };
    }

    function handleSaveChangedDependency(
        changedDependency: LCMDContextTaskDetailsResultDependencyDetails,
        dependencyType: DependencyType,
    ) {
        const { src, dest } = getDependencyTargets(changedDependency, dependencyType);

        LCMD.setDependencyDetails(src, dest, {
            lag: changedDependency.lag,
            type: changedDependency.type,
            unit: changedDependency.unit,
        });
    }

    function handleDeleteDependency(
        dependencyToDelete: LCMDContextTaskDetailsResultDependencyDetails,
        dependencyType: DependencyType,
    ) {
        const { src, dest } = getDependencyTargets(dependencyToDelete, dependencyType);
        LCMD.setDependencyDetails(src, dest, null);
    }

    function onDurationChange(newDuration: number) {
        LCMD.setProcessDetails(processDetails.pid.value, {
            duration: {
                value: newDuration,
                unit: LCMDContextUnitType.DAYS,
            },
        });
    }

    function onWorkforceChange(newWorkforce: number) {
        LCMD.setProcessDetails(processDetails.pid.value, {
            workforce: {
                value: newWorkforce,
            },
        });
    }

    function onTradeChange(changedTrade: DigitalPlanningBoardTradeDetails) {
        LCMD.setProcessDetails(processDetails.pid.value, {
            trades: {
                value: [changedTrade.id],
            },
        });
    }

    function handleStartDateChange(newDate: Date) {
        LCMD.setProcessDetails(processDetails.pid.value, {
            start: {
                value: toUTCDate(newDate).getTime(),
            },
        });
    }

    const handleProcessNameChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ) => {
        LCMD.setProcessDetails(processDetails.pid.value, {
            name: {
                value: newValue,
            },
        });
    };

    function dependencyClicked(dep) {
        goTo(dep.targetId);
    }

    //@nikhil checklist_api
    /*    function attachedChecklistsView(_selectedProcesses) {
            //const _checklists = LCMD.getAttachedChecklists(_selectedProcesses?.pid as number);
            const _checklists = LCMD.getAllAttachedChecklists();
            _checklists.then((value: AttachedChecklist[]) => { // Use appropriate type instead of 'any' if known
                setChecklists(value);
            }).catch(error => {
                console.error("Error fetching checklists:", error);
            });
        }*/

    return 0 === (selectedProcesses?.pid || []).length ? (
        <NoProcessSelectedView />
    ) : (
        <Fragment>
            <Stack className={styles.topContent}>
                <Stack tokens={{ padding: "0 10px 7px 10px" }}>
                    <EditNameField
                        placeholder={processDetails?.name?.indeterminate ? intl.get("DetailsView.mixed") : ""}
                        value={name}
                        onChange={handleProcessNameChange}
                    />
                </Stack>
                {/*@nikhil checklist_api*/}
                {/*<div
                    onClick={() => {
                        LCMD.attachCheckListToProcess("UUID-2", processDetails?.pid?.value as number);
                    }}
                >
                    ATTACH Checklist
                </div>

                <div
                    onClick={() => {
                        LCMD.updateAttachedChecklist(processDetails?.pid?.value as number, 11, [1, 2, 3]);
                    }}
                >
                    UPDATE Checklist
                </div>

                <div
                    onClick={() => {
                        LCMD.getAttachedChecklists(processDetails?.pid?.value as number);
                    }}
                >
                    GET Checklist {<div style={{ flexDirection: "row" }}>
                    {
                        checklists.map((checklist: AttachedChecklist, index: number) => {
                            return <div key={index} onClick={() => {
                                const _checklist = LCMD.getAttachedChecklistsById(selectedProcesses?.pid, checklist.id);
                                _checklist.then(value => console.log(value));
                            }}>{checklist.id}</div>;
                        })
                    }
                </div>}
                </div>
                <div
                    onClick={() => {
                        LCMD.deleteAttachedChecklist(processDetails?.pid?.value as number, 8);
                        LCMD.deleteAttachedChecklist(processDetails?.pid?.value as number, 9);
                        LCMD.deleteAttachedChecklist(processDetails?.pid?.value as number, 10);
                    }}
                >
                    DELETE Checklist
                </div>*/}
            </Stack>
            <Stack className={styles.main}>
                <Stack className={styles.trade}>
                    <Stack>
                        <Text className={styles.header}>{intl.get("DetailsView.tradeTitle")}</Text>
                    </Stack>
                    <Stack verticalAlign={"center"}>
                        {trades ? (
                            <Fragment>
                                {trades.length < 2 ? (
                                    trades.map((trade: LCMDContextTaskDetailsResultTradeDetails) => {
                                        return (
                                            <Stack key={trade.trid} horizontal horizontalAlign={"space-between"}>
                                                <div style={{ width: "90%" }}>
                                                    <TradeLabel name={trade.name} color={decimalToRgb(trade.color)} />
                                                </div>
                                                {!tradesIndeterminate ? (
                                                    <Fragment>
                                                        <div style={{ width: "10%" }}>
                                                            {!processDetails.trades?.readonly ? (
                                                                <IconButton
                                                                    iconProps={{
                                                                        iconName: Icons.Lcmd_Edit_Filled,
                                                                        style: iconStyle,
                                                                    }}
                                                                    disabled={processDetails.trades?.readonly}
                                                                    title={
                                                                        processDetails.trades?.readonly
                                                                            ? intl.get(
                                                                                  "DetailsView.list.editButton.disabledText",
                                                                              )
                                                                            : intl.get(
                                                                                  "DetailsView.list.editButton.text",
                                                                              )
                                                                    }
                                                                    onClick={showTradeModal}
                                                                />
                                                            ) : undefined}
                                                        </div>
                                                        {editTradeModalVisible && (
                                                            <EditTradeModal
                                                                open={editTradeModalVisible}
                                                                currentTrade={trade}
                                                                projectTrades={tradeList}
                                                                processDetails={processDetails}
                                                                onCancel={hideTradeModal}
                                                                onClose={(selectedTrade) => {
                                                                    onTradeChange(selectedTrade);
                                                                    hideTradeModal();
                                                                }}
                                                            />
                                                        )}
                                                    </Fragment>
                                                ) : undefined}
                                            </Stack>
                                        );
                                    })
                                ) : (
                                    <Stack styles={{ root: { paddingBottom: 10 } }}>
                                        {intl.get("DetailsView.mixed")}
                                    </Stack>
                                )}
                                {tradesIndeterminate ? (
                                    <Stack>
                                        {!processDetails.trades?.readonly ? (
                                            <DefaultButton
                                                styles={{ flexContainer: { flexDirection: "row-reverse" } }}
                                                onClick={showTradeForMultipleProcessesModal}
                                                iconProps={{ iconName: Icons.Lcmd_Edit_Filled }}
                                            >
                                                {intl.get("DetailsView.list.multiselectedEditButton.text")}
                                            </DefaultButton>
                                        ) : undefined}
                                        {editTradeForMultipleProcessesModalVisible && (
                                            <EditTradeModal
                                                open={editTradeForMultipleProcessesModalVisible}
                                                currentTrade={null}
                                                projectTrades={tradeList}
                                                processDetails={processDetails}
                                                onCancel={hideTradeForMultipleProcessesModal}
                                                onClose={(selectedTrade) => {
                                                    onTradeChange(selectedTrade);
                                                    hideTradeForMultipleProcessesModal();
                                                }}
                                            />
                                        )}
                                    </Stack>
                                ) : undefined}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <DefaultButton
                                    onClick={showTradeModal}
                                    disabled={tradesIndeterminate}
                                    iconProps={{ iconName: "Add" }}
                                >
                                    {intl.get("DetailsView.list.setTradeButton.text")}
                                </DefaultButton>
                                {editTradeModalVisible && (
                                    <EditTradeModal
                                        open={editTradeModalVisible}
                                        currentTrade={null}
                                        projectTrades={tradeList}
                                        processDetails={processDetails}
                                        onCancel={hideTradeModal}
                                        onClose={(selectedTrade) => {
                                            onTradeChange(selectedTrade);
                                            hideTradeModal();
                                        }}
                                    />
                                )}
                            </Fragment>
                        )}
                    </Stack>
                </Stack>
                <Stack className={styles.duration}>
                    <CountableSection
                        classNames={{ header: styles.header }}
                        headerText={intl.get("DetailsView.duration.text")}
                        onValueChange={onDurationChange}
                        unit={intl.get("DetailsView.duration.unitDays")}
                        value={duration}
                    />
                    <Stack horizontal tokens={{ childrenGap: 16 }}>
                        <Stack.Item styles={{ root: { width: "50%" } }}>
                            <LocalizedDatePicker
                                label={intl.get("fw.startEndDateControl.start.text")}
                                placeholder={
                                    processDetails?.start?.indeterminate
                                        ? intl.get("DetailsView.mixed")
                                        : intl.get("fw.startEndDateControl.start.placeholder")
                                }
                                value={processDetails?.start?.indeterminate ? null : startDate}
                                onSelectDate={handleStartDateChange}
                            />
                            <Text className={styles.underDateText}>
                                {cwStart >= 0
                                    ? intl.get("fw.startEndDateControl.cw", { cw: cwStart.toString(10) })
                                    : undefined}{" "}
                                {startDate?.toLocaleDateString(getCurrentLanguage(), { weekday: "long" })}
                            </Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: "50%" } }}>
                            <LocalizedDatePicker
                                label={intl.get("fw.startEndDateControl.end.text")}
                                placeholder={
                                    processDetails?.start?.indeterminate
                                        ? intl.get("DetailsView.mixed")
                                        : intl.get("fw.startEndDateControl.end.placeholder")
                                }
                                value={processDetails?.start?.indeterminate ? null : endDate}
                                disabled={true}
                            />
                            <Text className={styles.underDateText}>
                                {cwEnd >= 0
                                    ? intl.get("fw.startEndDateControl.cw", { cw: cwEnd.toString(10) })
                                    : undefined}{" "}
                                {endDate?.toLocaleDateString(getCurrentLanguage(), { weekday: "long" })}
                            </Text>
                        </Stack.Item>
                    </Stack>
                </Stack>
                <CountableSection
                    classNames={{ root: styles.workforce, header: styles.header }}
                    headerText={intl.get("DetailsView.workforce.text")}
                    onValueChange={onWorkforceChange}
                    unit={intl.get("DetailsView.workforce.unitPeople")}
                    value={workforce}
                />
                <Stack styles={{ root: { overflowY: "scroll", overflowX: "hidden" } }}>
                    <DependencyOverview
                        className={styles.predecessor}
                        dependencies={predecessors}
                        dependencyType={DependencyType.PREDECESSOR}
                        indeterminate={processDetails?.predecessors?.indeterminate}
                        onDependencyDetailsChanged={handleSaveChangedDependency}
                        onDeleteDependency={handleDeleteDependency}
                        onClick={dependencyClicked}
                    />
                    <DependencyOverview
                        className={styles.successor}
                        dependencies={successors}
                        dependencyType={DependencyType.SUCCESSOR}
                        indeterminate={processDetails?.successors?.indeterminate}
                        onDependencyDetailsChanged={handleSaveChangedDependency}
                        onDeleteDependency={handleDeleteDependency}
                        onClick={dependencyClicked}
                    />
                </Stack>
            </Stack>
        </Fragment>
    );
}
