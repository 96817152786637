import * as React from "react";
import { SidebarMenuItem } from "../interface";
import { DirectionalHint, IconButton, TooltipHost, IButtonStyles } from "@fluentui/react";

export type MenuItemState = {
    menuItem: SidebarMenuItem;
    disabled?: boolean;
    checked?: boolean;
    onClick?: (e: any) => void;
};

export function MenuItem({ menuItem, disabled, checked, onClick }: MenuItemState): JSX.Element {
    const disabledValue = typeof disabled === "boolean" ? disabled : menuItem.disabled;

    const defaultIconsStyle: IButtonStyles = {
        root: {
            padding: "20px",
        },
        rootHovered: {
            backgroundColor: "rgba(199, 231, 244)",
        },
        rootChecked: {
            backgroundColor: "rgba(199, 231, 244)",
        },
        rootDisabled: {
            backgroundColor: menuItem.disabled ? "transparent" : "",
        },
        icon: {
            fontSize: "26px",
            color: menuItem.disabled ? "rgba(86, 92, 96, 0.5)" : "rgb(86, 92, 96)",
            display: "contents",
        },
        iconChecked: {
            color: "#009BD4",
        },
        iconHovered: {
            color: "rgb(0, 155, 212)",
        },
    };
    const allIconsStyle = menuItem.style ? { ...defaultIconsStyle, ...menuItem.style } : defaultIconsStyle;

    return (
        <div>
            <TooltipHost content={menuItem.name} directionalHint={DirectionalHint.rightCenter}>
                <IconButton
                    checked={checked}
                    role="menuitem"
                    data-userpilot-id={`MenuItem-${menuItem.title}`}
                    aria-label={menuItem.name}
                    iconProps={{ iconName: menuItem.iconName }}
                    styles={allIconsStyle}
                    onClick={onClick}
                    disabled={disabledValue}
                    data-userflow-id={`MenuItem-${menuItem.title}`}
                />
            </TooltipHost>
        </div>
    );
}
