﻿import { FrameworkUpdateNeededError, generateWarmupId } from "../model/GlobalHelper";
import { notifyError } from "../model/ApiHelper";
import * as ReactDOM from "react-dom";
import { SERVICES } from "../model/services";
import * as React from "react";

export interface OnErrorProps {
    e: {
        message: string;
    };
    forcedUpdate: boolean;
    buildInfo: {
        build: string;
        hash: string;
    };
    projectId: string;
}

let _onErrorHandler: (props: OnErrorProps) => JSX.Element = null;
let _onErrorRoot: HTMLDivElement = null;
export function setOnErrorHandler(onError: (props: OnErrorProps) => JSX.Element) {
    _onErrorHandler = onError;
}

export function showScreenOfDeath(e?, event?) {
    const root = document.getElementById("root");
    if (!root) {
        return;
    }

    console.error(e);
    let stack = undefined;
    let auth_token = undefined;
    let warmupId = undefined;
    let projectId = undefined;

    try {
        stack = e?.stack || undefined;
        auth_token = (self as any)?.lcmdigital?.pipe?.auth?.auth_token;
        warmupId = (self as any)?.lcmdigital?.pipe?.warmupId;
        projectId = (self as any)?.lcmdigital?.pipe?.nav?.session?.pid;
    } catch (_e) {
        // nothing to do
    }

    if ("string" !== typeof warmupId || 0 === warmupId.length) {
        warmupId = generateWarmupId();
    }

    if (true || "development" !== process.env.NODE_ENV) {
        notifyError(
            {
                appId: "PP",
                sessionId: warmupId,
                logLevel: e ? 3 : 2,
            },
            (
                e ||
                JSON.stringify({
                    error: null,
                    message: event?.message,
                    filename: event?.filename,
                    lineno: event?.lineno,
                    colno: event?.colno,
                })
            ).toString(),
            (error, result) => {
                if (error) {
                } else {
                    console.info("error logged");
                }
            },
            auth_token,
            stack,
        );
    }

    if (!e) {
        return;
    }

    if (!_onErrorHandler) {
        const div = document.createElement("div");
        div.id = "crash";
        div.innerHTML =
            "<div><p>Development Mode!</p>Please try to reproduce this and report to development!<p><p>Please reload page to continue...<p></div>";
        root.parentNode.replaceChild(div, root);
        return;
    }

    if (_onErrorRoot) {
        return;
    }

    _onErrorRoot = document.createElement("div");
    _onErrorRoot.style.position = "absolute";
    _onErrorRoot.style.left = "0px";
    _onErrorRoot.style.right = "0px";
    _onErrorRoot.style.top = "0px";
    _onErrorRoot.style.bottom = "0px";
    document.body.appendChild(_onErrorRoot);
    ReactDOM.render(
        <_onErrorHandler
            e={e || { message: event?.message || "?" }}
            forcedUpdate={e instanceof FrameworkUpdateNeededError}
            buildInfo={{
                build: SERVICES?.BUILD?.BUNDLE_HASH || null,
                hash: SERVICES?.BUILD?.WORKER_HASH || null,
            }}
            projectId={projectId}
        />,
        _onErrorRoot,
    );
}
