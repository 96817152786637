import { IComboBoxOption } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";

export type PaperSize = {
    width: number;
    height: number;
    size: string;
    alignment: "portrait" | "landscape";
    label: string;
    colsPerPageSize?: number;
};

export const paperSizes: PaperSize[] = [
    {
        width: 841,
        height: 1189,
        size: "A0",
        alignment: "portrait",
        label: "A0P",
        colsPerPageSize: 200,
    },
    {
        width: 1189,
        height: 841,
        size: "A0",
        alignment: "landscape",
        label: "A0L",
        colsPerPageSize: 200,
    },
    {
        width: 841,
        height: 594,
        size: "A1",
        alignment: "portrait",
        label: "A1P",
        colsPerPageSize: 150,
    },
    {
        width: 594,
        height: 841,
        size: "A1",
        alignment: "landscape",
        label: "A1L",
        colsPerPageSize: 150,
    },
    {
        width: 420,
        height: 594,
        size: "A2",
        alignment: "portrait",
        label: "A2P",
        colsPerPageSize: 100,
    },
    {
        width: 594,
        height: 420,
        size: "A2",
        alignment: "landscape",
        label: "A2L",
        colsPerPageSize: 100,
    },
    {
        width: 297,
        height: 420,
        size: "A3",
        alignment: "portrait",
        label: "A3P",
        colsPerPageSize: 50,
    },
    {
        width: 420,
        height: 297,
        size: "A3",
        alignment: "landscape",
        label: "A3L",
        colsPerPageSize: 50,
    },
    {
        width: 210,
        height: 297,
        size: "A4",
        alignment: "portrait",
        label: "A4P",
        colsPerPageSize: 25,
    },
    {
        width: 297,
        height: 210,
        size: "A4",
        alignment: "landscape",
        label: "A4L",
        colsPerPageSize: 25,
    },
];
export const paperSizeOptions: IComboBoxOption[] = [
    { key: "A0", text: "DIN A0" },
    { key: "A1", text: "DIN A1" },
    { key: "A2", text: "DIN A2" },
    { key: "A3", text: "DIN A3" },
    { key: "A4", text: "DIN A4" },
];

export const translationPrefix = "pdf.export.paperSizeSelector.alignmentOptions";

export function getPaperAlignmentOptions(): IComboBoxOption[] {
    return [
        { key: "P", text: intl.get(`${translationPrefix}.portrait`) },
        { key: "L", text: intl.get(`${translationPrefix}.landscape`) },
    ];
}
