import { CanvasDataView } from "particlecore";
import { assert, EpochDaystoEpochMS, EpochMStoEpochDays } from "particlecore";
import { ProcessGetter } from "../model/api/processGetter";
import { CanvasDataViewReducer, CanvasDataViewReducerCtx } from "../model/api/coreTypes";
import { getMilestoneStatus, getStatusForCards } from "@/components/common/ProcessTooltip/helper";

function _initKappaDataView(ctx: CanvasDataViewReducerCtx, kappa: { state: CanvasDataView } & any) {
    kappa.grid = null;
    kappa.trades = null;
    kappa.tradeIds = [];
    kappa.maxHeight = 0;
}

function _invalidateKappaDataView(
    ctx: CanvasDataViewReducerCtx,
    kappa: { state: CanvasDataView; grid: Uint32Array; trades: { [id: number]: Uint32Array }; tradesIds: number[] },
) {
    kappa.grid = new Uint32Array(ctx.core.getViewCols());
    kappa.trades = {};
    assert(
        0 <= kappa.state.view.col0 &&
            kappa.state.view.col0 <= kappa.state.view.col1 &&
            kappa.state.view.col1 <= kappa.grid.length,
    );
    kappa.tradesIds = null;
}

function _reduceKappaDataView(
    ctx: CanvasDataViewReducerCtx,
    kappa: { state: CanvasDataView; grid: Uint32Array; trades: { [id: number]: Float32Array }; tradesIds: number[] },
    pctx: CanvasDataViewProcessReduceCtx,
) {
    const options = kappa.state.options;
    const dynamicView = options?.dynamicView ? true : false;
    const workforce = options?.workforce ? true : false;
    const n_trades = pctx.trades.length;
    const n_cards = pctx.cards.length;
    const wf = pctx.pGt.value<number>("wf", pctx.pGt.aux<number>("_wf", 0));
    for (let i_card = 0; i_card < n_cards; i_card++) {
        const card = pctx.cards[i_card];
        const g = ctx.core.dateToGrid(EpochDaystoEpochMS(card.date));
        if (0 <= g && g < kappa.grid.length) {
            const d = workforce ? ("number" === typeof card.wf ? card.wf : wf) : 1;
            if (0 === n_trades) {
                kappa.grid[g] = kappa.grid[g] + d;
            } else {
                for (let i_trade = 0; i_trade < n_trades; i_trade++) {
                    const trade = pctx.trades[i_trade];
                    kappa.trades[trade.id] = kappa.trades[trade.id] || new Float32Array(kappa.grid.length);
                    assert(0 <= g && g < kappa.trades[trade.id].length);
                    kappa.trades[trade.id][g] = kappa.trades[trade.id][g] + d;
                }
            }
        } else {
            console.warn("card outside grid!");
        }
    }
}

function _postprocessKappaDataView(
    ctx: CanvasDataViewReducerCtx,
    kappa: {
        state: CanvasDataView;
        grid: Uint32Array;
        trades: { [id: number]: Uint32Array };
        tradesIds: number[];
        workforce: boolean;
        dynamicView: boolean;
    },
) {
    const tradesIds = Object.getOwnPropertyNames(kappa.trades).map((id) => Number.parseInt(id));
    kappa.tradesIds = tradesIds;
}

function _updateKappaDataView(
    ctx: CanvasDataViewReducerCtx,
    { transferable },
    _stats: { trade: any; maxVisible: number; max: number },
    kappa: { state: CanvasDataView; grid: Uint32Array; trades: { [id: number]: Uint32Array }; tradesIds: number[] },
) {
    const col0 = kappa.state.view.col0;
    const col1 = kappa.state.view.col1;
    const cols = col1 - col0;
    _stats.trade = kappa.tradesIds.reduce((ret, trid) => {
        //@TODO cache!!
        ctx.trGt.reset(trid);
        const a = kappa.trades[trid].slice(col0, col1);
        const max = a.reduce((ret, v) => Math.max(ret, v), 0);
        const name = ctx.trGt.value<string>("name", "");
        const color = ctx.trGt.value<number>("color", 0);
        if (max > 0) {
            ret[trid] = { trid, a, max, name, color };
        }
        return ret;
    }, {});
    const tids = Object.getOwnPropertyNames(_stats.trade);
    transferable = tids.reduce((ret, trid) => {
        ret.push(_stats.trade[trid].a.buffer);
        return ret;
    }, transferable || []);
    const n_trades = tids.length;
    let maxVisible = 0;
    for (let i_col = 0; i_col < cols; i_col++) {
        let m = 0;
        for (let i_trade = 0; i_trade < n_trades; i_trade++) {
            m += _stats.trade[tids[i_trade]].a[i_col];
        }
        maxVisible = Math.max(m, maxVisible);
    }
    _stats.maxVisible = maxVisible;
    const maxCols = ctx.core.getViewCols();
    const tradesIds = kappa.tradesIds;
    const n_tradesIds = tradesIds.length;
    let max = 0; //@TODO cache!!
    for (let i_col = 0; i_col < maxCols; i_col++) {
        let m = 0;
        for (let i_trade = 0; i_trade < n_tradesIds; i_trade++) {
            m += kappa.trades[tradesIds[i_trade]][i_col];
        }
        max = Math.max(m, max);
    }
    _stats.max = max;
}

function _initActualDataView(ctx: CanvasDataViewReducerCtx, ret: { state: CanvasDataView } & any) {
    ret.actual = null;
    ret.baseline = null;
}

function _invalidateActualDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; actual: Float32Array; baseline: Float32Array },
) {
    ret.actual = new Float32Array(ret.state.view.col1 - ret.state.view.col0);
    ret.baseline = null;
}

function _reduceActualDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; actual: Float32Array; baseline: Float32Array },
    pctx: CanvasDataViewProcessReduceCtx,
) {
    const col0 = ret.state.view.col0;
    const col1 = ret.state.view.col1;
    const n_cards = pctx.cards.length;
    for (let i_card = 0; i_card < n_cards; i_card++) {
        const card = pctx.cards[i_card];
        const g = ctx.core.dateToGrid(EpochDaystoEpochMS(card.date));
        if (col0 <= g && g < col1) {
            const _g = g - col0;
            assert(0 <= _g && _g < ret.actual.length);
            ret.actual[_g]++;
        }
    }
    if (pctx.bcards) {
        if (null === ret.baseline) {
            ret.baseline = new Float32Array(ret.actual.length);
        }
        const cards = pctx.bcards;
        const n_cards = cards.length;
        for (let i_card = 0; i_card < n_cards; i_card++) {
            const card = cards[i_card];
            const g = ctx.core.dateToGrid(EpochDaystoEpochMS(card.date));
            if (col0 <= g && g < col1) {
                const _g = g - col0;
                assert(0 <= _g && _g < ret.actual.length);
                ret.baseline[_g]++;
            }
        }
    }
}

function _postprocessActualDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; actual: Float32Array; baseline: Float32Array },
) {
    const a = ret.actual;
    const n = a.length;
    for (let i = 1; i < n; i++) {
        a[i] += a[i - 1];
    }
    for (let i = 0; i < n; i++) {
        a[i] = a[n - 1] > 0 ? a[i] / a[n - 1] : 0;
    }

    if (ret.baseline) {
        const b = ret.baseline;
        assert(a.length === b.length);
        for (let i = 1; i < n; i++) {
            b[i] += b[i - 1];
        }
        for (let i = 0; i < n; i++) {
            b[i] = b[n - 1] > 0 ? b[i] / b[n - 1] : 0;
        }
    }
}

function _updateActualDataView(
    ctx: CanvasDataViewReducerCtx,
    { transferable },
    _stats: { actual: any; baseline: any },
    ret: { state: CanvasDataView; actual: Float32Array; baseline: Uint32Array },
) {
    _stats.actual = ret.actual.slice();
    transferable.push(_stats.actual.buffer);
    if (ret.baseline) {
        _stats.baseline = ret.baseline.slice();
        transferable.push(_stats.baseline.buffer);
    }
}

//
function _initProgressDataView(ctx: CanvasDataViewReducerCtx, ret: { state: CanvasDataView } & any) {
    ret.actual = null;
    ret.done = null;
}

function _invalidateProgressDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; actual: Float32Array; done: Float32Array },
) {
    ret.actual = new Float32Array(ret.state.view.col1 - ret.state.view.col0);
    ret.done = new Float32Array(ret.state.view.col1 - ret.state.view.col0);
}

function _reduceProgressDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; actual: Float32Array; done: Float32Array },
    pctx: CanvasDataViewProcessReduceCtx,
) {
    const col0 = ret.state.view.col0;
    const col1 = ret.state.view.col1;
    const n_trades = pctx.trades.length;
    const n_cards = pctx.cards.length;
    for (let i_card = 0; i_card < n_cards; i_card++) {
        const card = pctx.cards[i_card];
        const g = ctx.core.dateToGrid(EpochDaystoEpochMS(card.date));
        if (col0 <= g && g < col1) {
            const _g = g - col0;
            assert(0 <= _g && _g < ret.actual.length);
            ret.actual[_g]++;
            if (card.s === 2 /*DailyBoardAPICardStatus.DONE*/) {
                ret.done[_g]++;
            }
        }
    }
}

function _postprocessProgressDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; actual: Float32Array; done: Float32Array },
) {
    const a = ret.actual;
    const n_a = a.length;
    for (let i = 1; i < n_a; i++) {
        a[i] += a[i - 1];
    }
    const m = a[n_a - 1];
    for (let i = 0; i < n_a; i++) {
        a[i] = m > 0 ? a[i] / m : 0;
    }
    const d = ret.done;
    const n_d = a.length;
    for (let i = 1; i < n_d; i++) {
        d[i] += d[i - 1];
    }
    for (let i = 0; i < n_d; i++) {
        d[i] = m > 0 ? d[i] / m : 0;
    }
}

function _updateProgressDataView(
    ctx: CanvasDataViewReducerCtx,
    { transferable },
    _stats: { actual: any; done: any },
    ret: { state: CanvasDataView; actual: Float32Array; done: Float32Array },
) {
    const col0 = ret.state.view.col0;
    const col1 = ret.state.view.col1;
    const cols = col1 - col0;

    _stats.actual = ret.actual.slice();
    _stats.done = ret.done.slice();
    transferable.push(_stats.actual.buffer);
    transferable.push(_stats.done.buffer);
}

//
function _initStatusDataView(ctx: CanvasDataViewReducerCtx, ret: { state: CanvasDataView } & any) {
    ret.status = null;
}

function _invalidateStatusDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; status: [number, number, number, number, number] },
) {
    ret.status = [0, 0, 0, 0, 0];
}

function _reduceStatusDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; status: [number, number, number, number, number] },
    pctx: CanvasDataViewProcessReduceCtx,
) {
    const col0 = ret.state.view.col0;
    const col1 = ret.state.view.col1;
    const startDate = pctx.pGt.aux<number>("_x1");
    const endDate = pctx.pGt.aux<number>("_x2");
    const g0 = ctx.core.dateToGrid(startDate);
    const g1 = ctx.core.dateToGrid(endDate);
    if (Math.max(col0, g0) < Math.min(col1, g1)) {
        const today = ctx.core.getViewConst().today;
        // todo: check if g1 is epochDay or epochMS
        const { s } = pctx.pGt.hasMilestoneState()
            ? getMilestoneStatus(pctx.pGt.getMilestoneState(), today, endDate)
            : getStatusForCards(today, endDate, pctx.cards);
        ret.status[s]++;
    }
}

function _postprocessStatusDataView(ctx: CanvasDataViewReducerCtx, ret: { state: CanvasDataView }) {}

function _updateStatusDataView(
    ctx: CanvasDataViewReducerCtx,
    { transferable },
    _stats: { status: [number, number, number, number, number] },
    ret: { state: CanvasDataView; status: [number, number, number, number, number] },
) {
    _stats.status = ret.status;
}

//
function _initStabilityDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; stability: [number, number, number, number, number] } & any,
) {
    ret.stability = null;
}

function _invalidateStabilityDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; stability: [number, number, number, number, number] },
) {
    ret.stability = [0, 0, 0, 0, 0];
    assert(5 == ret.stability.length);
}

function _reduceStabilityDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; stability: [number, number, number, number, number] },
    pctx: CanvasDataViewProcessReduceCtx,
) {
    const col0 = ret.state.view.col0;
    const col1 = ret.state.view.col1;
    const g0 = ctx.core.dateToGrid(pctx.pGt.aux<number>("_x1"));
    const g1 = ctx.core.dateToGrid(pctx.pGt.aux<number>("_x2"));
    if (Math.max(col0, g0) < Math.min(col1, g1)) {
        const delta =
            pctx.bpGt && pctx.pGt.id === pctx.bpGt.id
                ? EpochMStoEpochDays(pctx.pGt.aux<number>("_x2")) - EpochMStoEpochDays(pctx.bpGt.aux<number>("_x2"))
                : 0;
        if (delta < -3) {
            ret.stability[0]++;
        } else if (delta >= -3 && delta < 0) {
            ret.stability[1]++;
        } else if (0 === delta) {
            ret.stability[2]++;
        } else if (delta > 0 && delta <= 3) {
            ret.stability[3]++;
        } else if (delta > 3) {
            ret.stability[4]++;
        } else {
            assert(false);
        }
    }
}

function _postprocessStabilityDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; stability: [number, number, number, number, number] },
) {}

function _updateStabilityDataView(
    ctx: CanvasDataViewReducerCtx,
    { transferable },
    _stats: { stability: [number, number, number, number, number] },
    ret: { state: CanvasDataView; stability: [number, number, number, number, number] },
) {
    _stats.stability = ret.stability;
}

//
function _initMilestonesDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; milestones: number[] } & any,
) {
    ret.milestones = null;
}

function _invalidateMilestonesDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; milestones: number[] },
) {
    ret.milestones = [];
}

function _reduceMilestonesDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; milestones: { tid: number; n: string; x: number; b: number; c: number }[] },
    pctx: CanvasDataViewProcessReduceCtx,
) {
    const days = pctx.pGt.id >= 0 ? pctx.pGt.value<number>("days", pctx.pGt.aux<number>("_days", 0)) : null;
    const baseline_days =
        pctx.bpGt && pctx.bpGt.id >= 0 ? pctx.bpGt.value<number>("days", pctx.bpGt.aux<number>("_days", 0)) : null;
    if (0 === days || 0 === baseline_days) {
        // milestone
        const x = 0 === days ? ctx.core.dateToGrid(pctx.pGt.aux<number>("_x1")) : -1;
        const b = 0 === baseline_days ? ctx.core.dateToGrid(pctx.bpGt.aux<number>("_x1")) : -1;
        ret.milestones.push({
            tid: pctx.pGt.id,
            n: pctx.pGt.value<string>("name", pctx.pGt.aux<string>("_name", "")),
            x,
            b,
            c: ctx.core.getColorForProcess(pctx.pGt),
        });
    }
}

function _postprocessMilestonesDataView(
    ctx: CanvasDataViewReducerCtx,
    ret: { state: CanvasDataView; milestones: { tid: number; n: string; x: number; b: number; c: number }[] },
) {
    ret.milestones.sort((m1, m2) => {
        let r = m1.x - m2.x;
        if (0 === r) {
            r = m1.n.localeCompare(m2.n);
            if (0 === r) {
                r = m1.tid - m2.tid;
            }
        }
        return r;
    });
}

function _updateMilestonesDataView(
    ctx: CanvasDataViewReducerCtx,
    { transferable },
    _stats: { milestones: { tid: number; x: number; b: number; c: number }[] },
    ret: { state: CanvasDataView; milestones: { tid: number; x: number; b: number; c: number }[] },
) {
    _stats.milestones = ret.milestones;
}

export type CanvasDataViewProcessReduceCtx = {
    pGt: ProcessGetter;
    bpGt?: ProcessGetter;
    trades: {
        id: number;
    }[];
    cards: {
        date: number;
        wf: number;
        s: number;
    }[];
    bcards?: {
        date: number;
        wf: number;
        s: number;
    }[];
};

function preprocessReduceCtx(ctx: CanvasDataViewReducerCtx, rctx: CanvasDataViewProcessReduceCtx, pid: number): void {
    ctx.pGt.reset(pid);
    rctx.pGt = ctx.pGt;
    rctx.trades = ctx.core.getTradesForProcess(ctx.pGt, ctx.trGt);
    rctx.cards = ctx.core.gatherActivitiesForTask(ctx.pGt).cards;
    if (ctx.baseline && ctx.bpGt.reset(pid).id >= 0) {
        rctx.bpGt = ctx.bpGt;
        rctx.bcards = ctx.baseline.gatherActivitiesForTask(ctx.bpGt).cards;
    }
}

function postprocessReduceCtx(ctx: CanvasDataViewReducerCtx, rctx: CanvasDataViewProcessReduceCtx, pid: number): void {}

export const dataViewReducer: CanvasDataViewReducer<CanvasDataView, CanvasDataViewProcessReduceCtx> = {
    preprocessReduceCtx,
    postprocessReduceCtx,
    views: {
        "canvas.kappa": {
            init: _initKappaDataView,
            invalidate: _invalidateKappaDataView,
            reduce: _reduceKappaDataView,
            postprocess: _postprocessKappaDataView,
            update: _updateKappaDataView,
        },
        "canvas.milestones": {
            init: _initMilestonesDataView,
            invalidate: _invalidateMilestonesDataView,
            reduce: _reduceMilestonesDataView,
            postprocess: _postprocessMilestonesDataView,
            update: _updateMilestonesDataView,
        },
        "dashboard.kappa": {
            init: _initKappaDataView,
            invalidate: _invalidateKappaDataView,
            reduce: _reduceKappaDataView,
            postprocess: _postprocessKappaDataView,
            update: _updateKappaDataView,
        },
        "dashboard.actual": {
            init: _initActualDataView,
            invalidate: _invalidateActualDataView,
            reduce: _reduceActualDataView,
            postprocess: _postprocessActualDataView,
            update: _updateActualDataView,
        },
        "dashboard.progress": {
            init: _initProgressDataView,
            invalidate: _invalidateProgressDataView,
            reduce: _reduceProgressDataView,
            postprocess: _postprocessProgressDataView,
            update: _updateProgressDataView,
        },
        "dashboard.status": {
            init: _initStatusDataView,
            invalidate: _invalidateStatusDataView,
            reduce: _reduceStatusDataView,
            postprocess: _postprocessStatusDataView,
            update: _updateStatusDataView,
        },
        "dashboard.stability": {
            init: _initStabilityDataView,
            invalidate: _invalidateStabilityDataView,
            reduce: _reduceStabilityDataView,
            postprocess: _postprocessStabilityDataView,
            update: _updateStabilityDataView,
        },
    },
};
