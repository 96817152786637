import { mergeStyleSets } from "@fluentui/react";

export const styleSets = mergeStyleSets({
    label: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 18,
        lineHeight: 24,
        letterSpacing: " -0.02em",
    },
    content: {
        margin: 8,
        padding: 10,
    },
    borderBottom: {
        borderBottom: "1px solid #E1E4E5",
    },
    underDateText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: 20,
    },
    field: {
        padding: "8px 0 8px 0",
    },
    footer: {
        padding: "12px 8px",
        bottom: "0",
        position: "absolute",
        backgroundColor: "white",
        width: "100%",
        height: "56px",
        borderTop: "1px solid #E1E4E5",
    },
});
