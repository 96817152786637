﻿import * as React from "react";
import { useState } from "react";
import { SecurityQueryModal } from "./SecurityQueryModal";
import { IconButton } from "../common/IconButton/IconButton";
import { Trash2, Download, Fullscreen, X } from "lucide-react";
import { LcmdModal2 } from "../common/LcmModal2";

type AttachmentThumbnailProps = {
    card: any;
    attachment: any;
};

export function AttachmentThumbnail({ card, attachment }: AttachmentThumbnailProps) {
    const [showSecurityQueryModal, setShowSecurityQueryModal] = useState(false);
    const [showFullscreenModal, setShowFullscreenModal] = useState(false);
    const [isAttachmentImage, setIsAttachmentImage] = useState(true);

    return (
        <div className="group relative block h-[120px] w-[120px]">
            {!isAttachmentImage ? (
                <div className="absolute left-0 top-0 flex h-full w-full items-center rounded-sm justify-center bg-gray-200">
                    <p className="text-xs text-gray-500">{attachment.fileName}</p>
                </div>
            ) : (
                <img
                    src={attachment.url}
                    alt={attachment.fileName || undefined}
                    className="h-full border border-solid object-contain py-4"
                    width={120}
                    onError={() => setIsAttachmentImage(false)}
                />
            )}
            <SecurityQueryModal
                show={showSecurityQueryModal}
                onClose={() => setShowSecurityQueryModal(false)}
                card={card}
                attachment={attachment}
            />
            <IconButton
                icon={Trash2}
                className="invisible absolute right-0 top-0 rounded-sm group-hover:visible"
                onClick={() => setShowSecurityQueryModal(true)}
                iconProps={{ size: 18, color: "#0078d4", strokeWidth: 1.5 }}
            />
            <IconButton
                icon={Download}
                className="invisible absolute right-7 top-0 rounded-sm group-hover:visible"
                onClick={() => window.open(attachment.url)}
                iconProps={{ size: 18, color: "#0078d4", strokeWidth: 1.5 }}
            />
            <IconButton
                icon={Fullscreen}
                className="invisible absolute right-14 top-0 rounded-sm group-hover:visible"
                disabled={!isAttachmentImage}
                onClick={() => setShowFullscreenModal(true)}
                iconProps={{ size: 18, color: "#0078d4", strokeWidth: 1.5 }}
            />
            <LcmdModal2 open={showFullscreenModal} size="full" onOpenChange={() => {}} closable={false}>
                <div className="relative">
                    <img className="h-full w-full" src={attachment.url} />
                    <div className="absolute right-1 top-0 ">
                        <IconButton
                            icon={Download}
                            onClick={() => window.open(attachment.url)}
                            iconProps={{ size: 18, color: "#0078d4", strokeWidth: 1.5 }}
                        />
                        <IconButton
                            icon={X}
                            onClick={() => setShowFullscreenModal(false)}
                            iconProps={{ size: 18, color: "#0078d4", strokeWidth: 1.5 }}
                        />
                    </div>
                </div>
            </LcmdModal2>
        </div>
    );
}
