import { PrimaryButton, IButtonProps } from "@fluentui/react";
import React, { Fragment, useRef } from "react";

type FileButtonProps = IButtonProps & {
    accept?: string;
};

export function FileButtonPrimary(props: FileButtonProps) {
    const inputRef = useRef<HTMLInputElement>();
    function clickInputFile() {
        inputRef.current.click();
    }

    return (
        <Fragment>
            <PrimaryButton {...props} type={"file"} onClick={clickInputFile} />
            <input
                accept={props.accept}
                ref={inputRef}
                type="file"
                id="file-selector"
                style={{ display: "none" }}
                onChange={props.onChange}
            />
        </Fragment>
    );
}
