import React, { useEffect, useState } from "react";
import {
    assert,
    DataModelFilterSavedAs,
    DigitalPlanningBoardTradeDetails,
    DigitalPlanningBoardTradesEvent,
    FrameworkError,
    jsonToError,
    ParticleContext,
    TableExportHelper,
} from "lcmd2framework";
import { TaktzoneCloneMainProps } from "../components/taktzone-clone-dialog";
import { getWorkerLink } from "./worker/workerLink";
import { HistoryMeta, Paginator } from "./interface";
import { WebAppTelemetryFactory } from "./services/WebAppTelemetry.service";
import { LCMDHookResult } from "./types/LCMDHookResult";
import {
    LCMDContextBaselineResponse,
    LCMDContextCardDetailsState,
    LCMDContextDependencyDetailsState,
    LCMDContextFindResult,
    LCMDContextProcessDetailsState,
    LCMDContextProjectDetails,
    LCMDContextTaskDetailsResult,
    LCMDContextTodoItemTarget,
    LCMDContextTodoResult,
    LCMDContextTradeDetailsState,
} from "./LCMDContextTypes";
import { useProcessPlanTimestamp } from "@/app/store/canvasStore";
import { Notification } from "@/core/Notification/NotificationService";
import { useUserJourneyStore } from "@/app/store/userJourneyStore";

/**
 * @public
 */
export class LCMDContextType extends ParticleContext {
    insertRow(this: LCMDContextType, tid: number, count?: number) {
        this.worker.postMessage([
            "processview",
            "insert",
            {
                id: this.wbs?.id,
                ts: ++this.wbs.ts,
                tid: tid,
            },
        ]);
    }

    indentRow(this: LCMDContextType, sel: number[]) {
        this.worker.postMessage([
            "processview",
            "indent",
            {
                id: this.wbs?.id,
                ts: ++this.wbs.ts,
                tids: sel,
            },
        ]);
    }

    outdentRow(this: LCMDContextType, sel: number[]) {
        this.worker.postMessage([
            "processview",
            "outdent",
            {
                id: this.wbs?.id,
                ts: ++this.wbs.ts,
                tids: sel,
            },
        ]);
    }

    deleteRow(this: LCMDContextType, tids: number[]) {
        this.worker.postMessage([
            "processview",
            "delete",
            {
                id: this.wbs?.id,
                ts: ++this.wbs.ts,
                tids: tids,
            },
        ]);
    }

    renameRow(this: LCMDContextType, tid: number, v: string) {
        this.worker.postMessage([
            "processview",
            "rename",
            {
                id: this.wbs?.id,
                ts: ++this.wbs.ts,
                tid: tid,
                v: v,
            },
        ]);
    }

    appendRow(this: LCMDContextType, v: string) {
        this.worker.postMessage([
            "processview",
            "append",
            {
                id: this.wbs?.id,
                ts: ++this.wbs.ts,
                v: v,
            },
        ]);
    }

    collapseRow(this: LCMDContextType, tids: number[], collapse: boolean) {
        if (1 === tids.length) {
            this.worker.postMessage([
                "processview",
                "collapse",
                {
                    id: this.wbs?.id,
                    ts: ++this.wbs.ts,
                    tid: tids[0],
                    v: collapse,
                },
            ]);
        }
    }

    selectionChanged(this: LCMDContextType, tids: number[]) {
        this.worker.postMessage([
            "processview",
            "selchg",
            {
                id: this.wbs?.id,
                ts: ++this.wbs.ts,
                tids: tids,
            },
        ]);
    }

    copyRow(this: LCMDContextType, tids: number[], cut?: boolean) {
        this.worker.postMessage([
            "processview",
            "copy",
            {
                id: this.wbs?.id,
                ts: ++this.wbs.ts,
                tids: tids,
                cut: cut,
            },
        ]);
    }

    pasteRow(this: LCMDContextType, tid: number) {
        this.worker.postMessage([
            "processview",
            "paste",
            {
                id: this.wbs?.id,
                ts: ++this.wbs.ts,
                tid: tid,
            },
        ]);
    }

    moveRow(this: LCMDContextType, tid: number, moveToId: number) {
        this.worker.postMessage([
            "processview",
            "move",
            {
                id: this.wbs?.id,
                ts: ++this.wbs.ts,
                tid: tid,
                moveToId: moveToId,
            },
        ]);
    }

    duplicateRow(this: LCMDContextType, taktZoneId: number, opt: { count: number; gpa?: number }) {
        this.worker.postMessage([
            "processview",
            "clone",
            {
                id: this.wbs?.id,
                ts: ++this.wbs.ts,
                taktZoneId,
                opt,
            },
        ]);
    }

    setProcess(
        this: LCMDContextType,
        tid: number,
        ops: { name: string; value: any; unit?: any }[],
        removeTrades?: number[],
        addTrades?: number[],
    ) {
        this.worker.postMessage([
            "process",
            "setProcess",
            {
                tid: tid,
                ops,
                removeTrades,
                addTrades,
            },
        ]);
    }

    updateSingleCard(
        this: LCMDContextType,
        card: { tid: number; aid: number; i: number; isWhiteboard: boolean },
        update: { s?: number; n?: string; m?: string; wf?: number; lcmx?: { key: string; value: any } },
    ) {
        const _id = ["C", card.tid.toString(16), card.aid.toString(16), card.i.toString(16)].join("_");
        this.worker.postMessage(["activity", "update", { ...update, id: _id, isWhiteboard: card.isWhiteboard }]);
    }

    canUndo = true;
    canRedo = true;

    undo(this: LCMDContextType) {
        this.worker.postMessage(["undo", {}]);
    }

    redo(this: LCMDContextType) {
        this.worker.postMessage(["redo", {}]);
    }

    getProjectDetails(cb: (error: FrameworkError, data: LCMDContextProjectDetails) => void) {
        if (this.worker) {
            this.worker.postMessage([
                "project",
                "info",
                {
                    cb: this.worker.registerCallback((data) => {
                        cb(null, data);
                    }),
                },
            ]);
        } else {
            cb(null, null);
        }
    }

    setProjectDetails(
        props: { name?: string; startDate?: Date; endDate?: Date },
        cb: (error: FrameworkError, data: LCMDContextProjectDetails) => void,
    ) {
        if (this.worker) {
            this.worker.postMessage([
                "project",
                "props",
                Object.assign(
                    {
                        cb: this.worker.registerCallback((data) => {
                            this.worker.dispatchMessage(["framework", "reload", { pid: data?.pid }]);
                            cb(null, data);
                        }),
                    },
                    {
                        ...props,
                        startDate: props.startDate ? props.startDate.getTime() : undefined,
                        endDate: props.endDate ? props.endDate.getTime() : undefined,
                    },
                ),
            ]);
        } else {
            cb(null, null);
        }
    }
    public showDialog(dialog: "dialog.taktzone.clone", props: TaktzoneCloneMainProps | false);
    public showDialog(dialog: "fw.trade.edit", props: DigitalPlanningBoardTradeDetails | false);
    public showDialog(dialog: string, props?: any);
    public showDialog(dialog: string, props?: any) {
        if (this.worker) {
            switch (dialog) {
                case "fw.alert":
                    {
                        this.worker.dispatchMessage(["toggle", dialog, props]);
                    }
                    break;
                case "fw.project.clone":
                    this.worker.postMessage([
                        "export",
                        {
                            target: "clone",
                        },
                    ]);
                    break;
                case "fw.project.share":
                    this.worker.dispatchMessage(["toggle", "user", true]);
                    break;
                case "fw.project.unlink":
                    if (this.worker?.auth?.params?.sub) {
                        this.worker.dispatchMessage([
                            "framework",
                            "unlink",
                            {
                                subs: this.worker.auth.params.sub,
                                cb: (error, result) => {
                                    if (!error) {
                                        window.location.reload();
                                    }
                                },
                            },
                        ]);
                    }
                    break;
                case "fw.task.edit":
                    {
                        const card = props;
                        if ("string" === typeof card?.tid) {
                            const helper = card.tid.split("_");
                            if (4 === helper.length && "C" === helper[0]) {
                                card.tid = Number.parseInt(helper[1], 16);
                                card.aid = Number.parseInt(helper[2], 16);
                                card.i = Number.parseInt(helper[3], 16);
                            }
                        }
                        if (
                            "number" === typeof card.tid &&
                            "number" === typeof card.aid &&
                            "number" === typeof card.i
                        ) {
                            this.worker.dispatchMessage(["framework", "card", props]);
                        }
                    }
                    break;
                case "fw.trade.create":
                    {
                        this.worker.dispatchMessage(["toggle", "trade.create"]);
                    }
                    break;
                case "fw.trade.edit":
                    {
                        this.worker.dispatchMessage(["toggle", "trade.edit", props]);
                    }
                    break;
                case "fw.trade.delete":
                    {
                        this.worker.postMessage(["trade", "delete.dialog", props]);
                    }
                    break;
                case "fw.trade.select":
                    {
                        const opt = {
                            ...(props || {}),
                            tid: -1,
                            trades: props?.trades || [],
                            cb: props?.cb,
                            multiselect: props?.multiselect,
                        };
                        this.worker.dispatchMessage(["framework", "trade", opt]);
                    }
                    break;
                default:
                    this.worker.dispatchMessage(["toggle", dialog, props]);
                    break;
            }
        }
    }

    setStatusView(
        view: string,
        options?: {
            taktZoneImages?: boolean;
            showTaktzones?: boolean;
            showProjectWeeks?: boolean;
            showStatusBar?: boolean;
        },
    ) {
        if (this.worker) {
            this.worker.postMessage(["view", "status", { view, options }]);
            //Hack: This code should be in core
            if (window.top != window.self) {
                const messageObj = {
                    action: "ppViewChanged",
                    view: view,
                };
                parent.postMessage(messageObj, "*");
            }
        }
    }

    getBaselineIds(cb: (error: FrameworkError, resp: LCMDContextBaselineResponse) => void) {
        if (this.worker) {
            this.worker.postMessage([
                "baseline",
                "ids",
                {
                    cb: this.worker.registerCallback((data) => {
                        cb(null, data);
                    }),
                },
            ]);
        } else {
            cb(null, null);
        }
    }

    setBaselineId(revId: number) {
        if (this.worker) {
            this.worker.postMessage([
                "baseline",
                "set",
                {
                    revId: revId,
                },
            ]);
        }
    }

    getCurrentPP(cb: (error: FrameworkError, data: any) => void) {
        if (this.worker) {
            this.worker.postMessage([
                "export",
                "pp",
                {
                    cb: this.worker.registerCallback((data) => {
                        cb(null, data);
                    }),
                },
            ]);
        }
    }

    getExtensions(cb: (error: FrameworkError, data: any) => void) {
        if (this.worker) {
            this.worker.postMessage([
                "export",
                "extensions",
                {
                    cb: this.worker.registerCallback((data) => {
                        cb(null, data);
                    }),
                },
            ]);
        }
    }

    getProjectExtension(id: string, cb: (error: FrameworkError, data: any) => void) {
        if (this.worker) {
            this.worker.postMessage([
                "export",
                "extension",
                {
                    id: id,
                    cb: this.worker.registerCallback((data) => {
                        cb(null, data);
                    }),
                },
            ]);
        }
    }

    putExtension(ext: any | any[]) {
        if (this.worker) {
            this.worker.postMessage(["lcmdx", ext]);
        }
    }

    getProcessDetails(
        tid: number | number[],
        cb: (error: FrameworkError, data: LCMDContextTaskDetailsResult) => void,
        isWhiteboard: boolean = false,
    ) {
        if (this.worker) {
            this.worker.postMessage([
                "details",
                {
                    taskId: tid,
                    isWhiteboard,
                    cb: this.worker.registerCallback((data) => {
                        if (cb) {
                            cb(null, data);
                        }
                    }),
                },
            ]);
        }
    }

    /**
     * @deprecated
     * @see useProcessDetails
     * @param taskId
     * @param cb
     * @param deps
     * @param ts
     * @param patch
     * @param isWhiteboard
     */
    useProcessDetailsEffect(
        taskId: number | number[],
        isWhiteboard: boolean,
        cb: (error: FrameworkError, data: LCMDContextTaskDetailsResult) => void,
        deps: React.DependencyList,
        ts?: number,
        patch?: LCMDContextTaskDetailsResult,
    ) {
        const _cb = React.useCallback(cb, deps);
        React.useEffect(() => {
            if (this.worker) {
                const wrapper = {
                    cb,
                };
                this.worker.postMessage([
                    "details",
                    {
                        taskId,
                        isWhiteboard,
                        cb: this.worker.registerCallback((data) => {
                            if (wrapper.cb) {
                                wrapper.cb(null, data);
                            }
                        }),
                    },
                ]);
                return () => {
                    wrapper.cb = null;
                };
            }
        }, [_cb, isWhiteboard, taskId, ts, patch]);
    }

    /**
     * Get information for a process. Automatically updates the state on changes.
     *
     * @param processId The id of the process.
     */
    useProcessDetails(processId: number) {
        const [hookState, setHookState] = useState<LCMDHookResult<LCMDContextTaskDetailsResult>>({
            isLoading: true,
            isError: false,
            error: undefined,
            data: undefined,
        });

        const processPlanTimestamp = useProcessPlanTimestamp();

        useEffect(() => {
            if (this.worker) {
                this.worker.postMessage([
                    "details",
                    {
                        taskId: processId,
                        cb: this.worker.registerCallback((data) => {
                            setHookState({ ...hookState, data, isLoading: false });
                        }),
                    },
                ]);
            } else {
                setHookState({
                    ...hookState,
                    isLoading: false,
                    isError: true,
                    error: new Error("Worker not set Properly"),
                });
            }
        }, [processId, processPlanTimestamp]);

        return hookState;
    }

    useAreaPaths = (processIds) => {
        const [hookState, setHookState] = useState(processIds.map((id) => ({ id, path: "" })));

        useEffect(() => {
            let isMounted = true;

            const fetchAreaPaths = async () => {
                const workerLink = await getWorkerLink(); // Assuming you have a function to get the worker link
                const promises = processIds.map((id) => workerLink.getAreaPath(id));

                try {
                    const results = await Promise.all(promises);

                    if (isMounted) {
                        const updatedHookState = results.map((path, index) => ({
                            id: processIds[index],
                            path,
                        }));
                        setHookState(updatedHookState);
                    }
                } catch (error) {
                    if (isMounted) {
                        // Handle error if needed
                    }
                }
            };

            fetchAreaPaths();

            return () => {
                isMounted = false;
            };
        }, []);

        return hookState;
    };

    useActionItemName(processId: number, id: number) {
        const [hookState, setHookState] = useState<LCMDHookResult<{ actionItemName: string }>>({
            isLoading: true,
            isError: false,
            error: undefined,
            data: { actionItemName: "" },
        });

        useEffect(() => {
            let isMounted = true;
            (async () => {
                if (isMounted) {
                    const { getActionItem } = await getWorkerLink();
                    const actionItemName = await getActionItem(processId, id);
                    setHookState({ ...hookState, isLoading: false, data: { actionItemName } });
                }
            })();
            return () => {
                isMounted = false;
            };
        }, [processId, id]);

        return hookState;
    }

    useCardName({ processId, cardId }: { processId: number; cardId: { aid: number; cid: number } }) {
        const [hookState, setHookState] = useState<LCMDHookResult<{ cardName: string | undefined }>>({
            isLoading: true,
            isError: false,
            error: undefined,
            data: { cardName: undefined },
        });

        useEffect(() => {
            let isMounted = true;
            (async () => {
                const { getCardName } = await getWorkerLink();
                const cardName = await getCardName(processId, cardId);
                if (isMounted) {
                    setHookState({ ...hookState, isLoading: false, data: { cardName } });
                }
            })();
            return () => {
                isMounted = false;
            };
        }, [processId, cardId]);
        return hookState;
    }

    setProcessDetails(tid: number | number[], state: LCMDContextProcessDetailsState, isWhiteboard: boolean = false) {
        this.worker.postMessage([
            "details",
            {
                isWhiteboard,
                set: {
                    tids: Array.isArray(tid) ? tid : [tid],
                    state,
                },
            },
        ]);
    }

    setTradeDetails(tradeId: number, state: LCMDContextTradeDetailsState) {
        this.worker.postMessage([
            "trade",
            "update",
            {
                id: tradeId,
                color: state.color ? state.color.value : undefined,
                name: state.name ? state.name.value : undefined,
                trade: state.trade ? state.trade.value : undefined,
                icon: state.icon ? state.icon.value : undefined,
                //subs: (-1===trade.id || trade.subs!==props.trade.subs)?trade.subs:undefined
            },
        ]);
    }

    setDependencyDetails(
        srcPid: number,
        dstPid: number,
        state: LCMDContextDependencyDetailsState | null,
        isWhiteboard: boolean = false,
    ) {
        this.worker.postMessage([
            "details",
            {
                isWhiteboard,
                dep: {
                    srcPid,
                    dstPid,
                    state,
                },
            },
        ]);
    }

    setCardsDetails(id: string | string[], state: LCMDContextCardDetailsState | LCMDContextCardDetailsState[] | null) {
        this.worker.postMessage([
            "details",
            {
                cards: {
                    id: Array.isArray(id) ? id : [id],
                    state: state,
                },
            },
        ]);
    }

    useProcessHistoryEffect(
        taskId: number | number[],
        cb: (error: FrameworkError, data: HistoryMeta[]) => void,
        deps: React.DependencyList,
        ts?: number,
    ) {
        const _cb = React.useCallback(cb, deps);
        React.useEffect(() => {
            if (this.worker) {
                const wrapper = {
                    cb,
                };
                this.worker.postMessage([
                    "thistory",
                    {
                        taskId: taskId,
                        cb: this.worker.registerCallback(async (data) => {
                            if (!data) {
                                return false;
                            }

                            if (wrapper.cb) {
                                wrapper.cb(null, data);
                            }
                        }),
                    },
                ]);
                return () => {
                    wrapper.cb = null;
                };
            }
        }, [_cb, taskId, ts]);
    }

    setProcessComment(
        pid: number,
        text: string,
        notificationWrapperData?: {
            fromUserId: string;
            users: string[];
            notification: Notification;
            auth_token?: string;
        },
    ) {
        if ("string" === typeof text && this.worker) {
            notificationWrapperData = { ...notificationWrapperData, auth_token: this.worker.auth.auth_token };
            this.worker.postMessage([
                "comment",
                "new",
                {
                    tid: pid,
                    text: text,
                    notificationWrapperData: notificationWrapperData ? notificationWrapperData : null,
                },
            ]);
        }
    }

    setCalendarView(showNonWorkingDays: boolean) {
        if (this.worker) {
            this.worker.postMessage(["calendar", showNonWorkingDays ? 7 : 5]);
        }
    }

    useTodoEffect(
        cb: (error: FrameworkError, data: LCMDContextTodoResult) => void,
        deps: React.DependencyList,
        ts?: number,
    ) {
        const _cb = React.useCallback(cb, deps);
        React.useEffect(() => {
            if (this.worker) {
                const wrapper = {
                    cb,
                };
                const date0 = Date.now();
                this.worker.postMessage([
                    "todo",
                    "fetch",
                    {
                        cb: this.worker.registerCallback((data) => {
                            if (wrapper.cb) {
                                const date1 = Date.now();
                                console.log("useTodoEffect " + (date1 - date0) + "ms");
                                wrapper.cb(null, data);
                            }
                        }),
                    },
                ]);
                return () => {
                    wrapper.cb = null;
                };
            }
        }, [_cb, ts]);
    }

    useTradesEffect(
        cb: (error: FrameworkError, data: DigitalPlanningBoardTradesEvent) => void,
        deps: React.DependencyList,
        ts?: number,
    ) {
        const _cb = React.useCallback(cb, deps);
        React.useEffect(() => {
            if (this.worker) {
                const wrapper = {
                    cb,
                };
                this.worker.postMessage([
                    "details",
                    {
                        trades: true,
                        cb: this.worker.registerCallback((data) => {
                            if (wrapper.cb) {
                                wrapper.cb(null, data);
                            }
                        }),
                    },
                ]);
                return () => {
                    wrapper.cb = null;
                };
            }
        }, [_cb, ts]);
    }

    useFindEffect(findText: string, cb: (data: LCMDContextFindResult) => void, deps: React.DependencyList) {
        const _cb = React.useCallback(cb, deps);
        const _cache = React.useMemo(
            () => ({
                findText: undefined,
                findCB: null,
                cb: null,
                ref: 0,
                fct: undefined,
            }),
            [],
        );
        React.useLayoutEffect(() => {
            _cache.fct = (findText: string, cb) => {
                assert(0 === _cache.ref);
                _cache.ref++;
                _cache.findText = undefined;
                _cache.findCB = null;
                _cache.cb = cb;
                this.worker.postMessage([
                    "find",
                    "spotlight",
                    {
                        findText,
                        cb: this.worker.registerCallback((data) => {
                            assert(1 === _cache.ref);
                            _cache.ref--;
                            if (undefined === _cache.findText) {
                                assert(!_cache.findCB);
                                if (_cache.cb) {
                                    _cache.cb(data);
                                    _cache.cb = null;
                                }
                            } else {
                                assert(_cache.findCB);
                                _cache.fct(_cache.findText, _cache.findCB);
                            }
                        }),
                    },
                ]);
            };
        }, [_cache]);
        React.useEffect(() => {
            if (this.worker) {
                if (0 === _cache.ref) {
                    _cache.fct(findText, cb);
                } else {
                    _cache.findText = findText;
                    _cache.findCB = cb;
                }
            }
            return () => {
                _cache.cb = null;
            };
        }, [_cache.fct, _cb, findText]);
    }

    useTextSearchEffect(findText: string, cb: (data: LCMDContextFindResult) => void, deps: React.DependencyList) {
        const _cb = React.useCallback(cb, deps);
        const _cache = React.useMemo(
            () => ({
                findText: undefined,
                findCB: null,
                cb: null,
                ref: 0,
                fct: undefined,
            }),
            [],
        );
        React.useLayoutEffect(() => {
            _cache.fct = (findText: string, cb) => {
                assert(0 === _cache.ref);
                _cache.ref++;
                _cache.findText = undefined;
                _cache.findCB = null;
                _cache.cb = cb;
                this.worker.postMessage([
                    "find",
                    "spotlight",
                    {
                        findText,
                        cb: this.worker.registerCallback((data) => {
                            assert(1 === _cache.ref);
                            _cache.ref--;
                            if (undefined === _cache.findText) {
                                assert(!_cache.findCB);
                                if (_cache.cb) {
                                    _cache.cb(data);
                                    _cache.cb = null;
                                }
                            } else {
                                assert(_cache.findCB);
                                _cache.fct(_cache.findText, _cache.findCB);
                            }
                        }),
                    },
                ]);
            };
        }, [_cache]);
        React.useEffect(() => {
            if (this.worker) {
                if (0 === _cache.ref) {
                    _cache.fct(findText, cb);
                } else {
                    _cache.findText = findText;
                    _cache.findCB = cb;
                }
            }
            return () => {
                _cache.cb = null;
            };
        }, [_cache.fct, _cb, findText]);
    }

    setOrCreateTodoItemState(
        target: LCMDContextTodoItemTarget | LCMDContextTodoItemTarget[],
        state: { value: number | string | boolean | null } | null,
        meta: { [name: string]: { value: number | string | boolean } & any } | null,
        cb: (error: FrameworkError, data: { id: number }) => void,
    ) {
        if (this.worker) {
            const wrapper = {
                cb,
            };
            this.worker.postMessage([
                "todo",
                "set",
                {
                    target: Array.isArray(target) ? target : [target],
                    state,
                    meta,
                    cb: this.worker.registerCallback((data) => {
                        if (wrapper.cb) {
                            wrapper.cb(null, data);
                        }
                    }),
                },
            ]);
            return () => {
                wrapper.cb = null;
            };
        }
    }

    executeCommand(
        op: {
            cmd: "link" | "marquee" | "libAdd" | "takting" | "sync";
            pid?: number[];
            value?: any;
        } & any,
    ) {
        switch (op.cmd) {
            case "link":
            case "whiteboard-link":
                if (this.worker) {
                    this.worker.postMessage([
                        "dependency",
                        op.cmd === "link" ? "tasks" : "whiteboard-tasks",
                        {
                            tasks: op.pid,
                        },
                    ]);
                }
                break;
            case "marquee":
                if (this.worker) {
                    this.worker.dispatchMessage(["toggle", "forceShiftKey", op.value]);
                }
                break;
            case "libAdd":
                if (this.worker) {
                    this.worker.postMessage(["wb", "libAdd", { ...(op?.value || {}), selected: op.pid }]);
                }
                break;
            case "takting":
                if (this.worker) {
                    this.worker.postMessage(["wb", "takting", op]);
                }
                break;
            case "sync":
                if (this.worker) {
                    this.worker.postMessage(["wb", "sync", op]);
                }
                break;
        }
    }

    generateXLSX(
        table: TableExportHelper,
        opt?: { name?: string; preventDefault?: boolean; type?: string },
        cb?: (error: FrameworkError, buffer: Buffer) => void,
    ) {
        if (this.worker?.auth?.auth_token) {
            table
                .fetchXSLX(this.worker.auth.auth_token)
                .then((buffer) => {
                    if (cb) {
                        cb(null, buffer);
                    }
                    if (!opt?.preventDefault) {
                        this.worker.dispatchMessage(
                            [
                                "export",
                                {
                                    data: buffer,
                                    type: opt?.type || "xlsx",
                                    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                    name: opt?.name || "export.xlsx",
                                },
                            ],
                            [buffer],
                        );
                    }
                })
                .catch((e) => {
                    console.warn(e);
                    if (cb) {
                        cb(e, null);
                    }
                });
        }
    }

    resolveConflict(
        conflict: {
            tid: number;
            start: number;
            delta: number;
        }[],
    ) {
        this.worker.postMessage([
            "details",
            {
                conflict: conflict,
            },
        ]);
    }

    resolveConflictByProcessId(processId: number) {
        this.worker.postMessage([
            "resolve-conflict",
            {
                processId,
            },
        ]);
    }

    public setFilter(
        filter: DataModelFilterSavedAs | string,
        opt?: { modeDailyBoard?: boolean },
        cb?: (error?: FrameworkError) => void,
    ) {
        if (typeof filter === "object" && filter?.status) {
            const statusToStore = [...(filter as DataModelFilterSavedAs).status.values()];
            useUserJourneyStore.getState().actions.setFilter({ ...filter, status: statusToStore });
        } else {
            useUserJourneyStore.getState().actions.setFilter(filter);
        }
        this.worker.postMessage([
            "setFilter",
            {
                data: filter,
                opt: opt,
                cb: cb
                    ? this.worker.registerCallback((ret) => {
                          cb(ret?.error ? (jsonToError(ret.error) as FrameworkError) : undefined);
                      })
                    : undefined,
            },
        ]);
    }

    public isFilterActive(cb: (data: boolean, error?: FrameworkError) => void) {
        this.worker.postMessage([
            "filter",
            "isFilterActive",
            {
                cb: this.worker.registerCallback((data, error) => {
                    cb(data, error?.error ? (jsonToError(error.error) as FrameworkError) : undefined);
                }),
            },
        ]);
    }

    //@nikhil checklist_api
    public attachCheckListToProcess(templateId: string, pid: number) {
        this.worker.postMessage(["attachChecklist", { pid: pid, templateId: templateId }]);
    }

    public updateAttachedChecklist(pid: number, checklistId: number, states: number[]) {
        this.worker.postMessage(["updateAttachedChecklist", { pid, checklistId, states }]);
    }

    public async getAttachedChecklists(pid: number) {
        const { checkLists } = await getWorkerLink();
        return checkLists.getAttachedChecklists(pid);
    }

    public async getAllAttachedChecklists() {
        try {
            const { checkLists } = await getWorkerLink();
            return checkLists.getAllAttachedChecklists();
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    public async getAttachedChecklistsById(pid, checklistId) {
        const { checkLists } = await getWorkerLink();
        return checkLists.getAttachedChecklistsById(pid, checklistId);
    }

    public deleteAttachedChecklist(pid: number, checklistId: number) {
        this.worker.postMessage(["deleteAttachedChecklist", { pid, checklistId }]);
    }

    // @todo: make all reasonCode API´s availble under reasonCode prop
    // get reasonCode(){
    //     async function attachReasonCodeToProcess({pid, reasonCodeId, opId}){
    //         const {reasonCodes} = await getWorkerLink();
    //         reasonCodes.create({pid, reasonCodeId, opId})
    //     }
    //
    //     async function updateAttachedReasonCode({attachedReasonCodeId, pid, reasonCodeId, opId}) {
    //         const {reasonCodes} = await getWorkerLink();
    //         console.log("trying to update attachReasonCode", {attachedReasonCodeId, pid, reasonCodeId, opId})
    //         reasonCodes.update({attachedReasonCodeId, pid, reasonCodeId, opId})
    //     }
    //
    //     return {
    //         attachReasonCodeToProcess,
    //         updateAttachedReasonCode
    //     }
    // }

    public async getOldHistoryValue(pid: number, propName: string, ts: number, offset = 1): Promise<any> {
        const { getOldHistoryValue } = await getWorkerLink();
        return getOldHistoryValue(pid, propName, ts, offset);
    }

    public async attachReasonCodeToProcess({ pid, reasonCodeId, opId }) {
        const { reasonCodes } = await getWorkerLink();
        console.log("trying to attachReasonCode", { pid, reasonCodeId, opId });
        await reasonCodes.create({ pid, reasonCodeId, opId });
        WebAppTelemetryFactory.trackEvent("reasoncode-create", { pid, opId, reasonCodeId });
    }

    public async updateAttachedReasonCode({ attachedReasonCodeId, pid, reasonCodeId, opId }) {
        const { reasonCodes } = await getWorkerLink();
        console.log("trying to update attachReasonCode", { attachedReasonCodeId, pid, reasonCodeId, opId });
        await reasonCodes.update({ attachedReasonCodeId, pid, reasonCodeId, opId });
    }

    public async deleteAttachedReasonCode({ attachedReasonCodeId, processId }) {
        const { reasonCodes } = await getWorkerLink();
        await reasonCodes.delete({ attachedReasonCodeId, pid: processId });
    }

    public async getAttachedReasonCode(processId: number);
    public async getAttachedReasonCode(processId: number, opId: number);
    public async getAttachedReasonCode(processId: number, opId?: number) {
        const { reasonCodes } = await getWorkerLink();
        // const procressesWithReasonCodes = await reasonCodes.getByProcessId(processId);
        const procressesWithReasonCodes = Number.isInteger(opId)
            ? await reasonCodes.getByOpId(processId, opId)
            : await reasonCodes.getByProcessId(processId);
        return procressesWithReasonCodes;
    }

    public async getAllAttachedReasonCodes(applyGlobalFilter = false) {
        const { reasonCodes } = await getWorkerLink();
        const results = await reasonCodes.getAll(applyGlobalFilter);
        return results;
    }

    public async getAvailableReasonCodes({ deleted = false, callback }: { deleted?: boolean; callback?: Function }) {
        const { reasonCodes } = await getWorkerLink();
        const results = await reasonCodes.getAvailableReasonCodes({ deleted });

        if (!callback) {
            return results;
        }

        callback(results);
    }

    public async getHistory(page: number = 0): Promise<Paginator<HistoryMeta[]>> {
        const wl = await getWorkerLink();
        return wl.getHistory(page);
    }
}

const instance: LCMDContextType = new LCMDContextType();
const ctx = {
    instance: instance,
    ctx: React.createContext(instance),
};

export function getLCMDContext(): {
    instance: LCMDContextType;
    ctx: React.Context<LCMDContextType>;
} {
    return ctx;
}

export function useLCMD(): LCMDContextType {
    return React.useContext(ctx.ctx);
}

export function getLCMD(): LCMDContextType {
    return ctx.instance;
}
