import React, { useContext } from "react";
import { DefaultButton, Icon, Stack } from "@fluentui/react";
import { Icons } from "../../../RegisteredIcons";
import { useLCMD } from "../../../../app/LCMContext";
import { CPContext } from "../../Shared/CPContext";
import { intl } from "@/legacy/GlobalHelperReact";
import { useUserRole } from "../../../hooks/useUserRole.hook";

export function HistoryReasonsEmpty() {
    const LCMD = useLCMD();
    const { onDismiss } = useContext(CPContext).state;
    const userRole = useUserRole();

    function click() {
        LCMD.worker.dispatchMessage(["framework", "toggle", "settings", "lcmd.settings.changes-settings"]);
        onDismiss();
    }

    return (
        <div style={{ background: "rgba(250, 65, 0, 0.2)", borderRadius: 4, padding: 16, marginTop: 10 }}>
            <Stack>
                <span style={{ fontWeight: 600, paddingBottom: "6px" }}>
                    {intl.get("HistoryView.HistoryReasonsEmpty.title")}
                </span>
            </Stack>
            <Stack horizontal style={{ marginTop: 16 }}>
                <Stack.Item>
                    <span style={{ color: "#D83B01", paddingRight: 8, fontSize: 20 }}>
                        <Icon iconName={Icons.Lcmd_Warning} />
                    </span>
                </Stack.Item>
                <Stack.Item>
                    {userRole === "admin" ? (
                        <>
                            <p style={{ marginTop: 0 }}>{intl.get("HistoryView.HistoryReasonsEmpty.content")}</p>
                            <DefaultButton
                                text={intl.get("HistoryView.HistoryReasonsEmpty.buttonText")}
                                onClick={click}
                            />
                        </>
                    ) : (
                        <p style={{ marginTop: 0 }}>{intl.get("HistoryView.HistoryReasonsEmpty.contentUser")}</p>
                    )}
                </Stack.Item>
            </Stack>
        </div>
    );
}
