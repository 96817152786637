﻿import { SubId, Persona } from "@/legacy/SubTypes";
import { getSub } from "@/model/ApiHelper";

export class SubCache {
    static cache: { [sub: SubId]: any } = {};

    static getPersonas(auth_token: string, subs: SubId[], cb: (personas: Persona[]) => void) {
        Promise.all(
            subs.map(
                (sub) =>
                    new Promise((resolve: (sub: { email: string }) => void, reject: (error) => void) => {
                        SubCache.getSub(auth_token, sub, (result) => {
                            resolve({
                                ...result,
                                sub: sub,
                            });
                        });
                    }),
            ),
        )
            .then((ret) => {
                const personas: Persona[] = ret.map((entry) => {
                    return {
                        data: entry,
                        personaName: entry.email,
                    };
                });
                cb(personas);
            })
            .catch(() => {
                cb([]);
            });
    }

    static getSub(auth_token: string, sub: SubId, _cb: (result) => void) {
        if (auth_token && sub) {
            const _cache = SubCache.cache[sub] || null;
            if (Array.isArray(_cache)) {
                _cache.push(_cb);
            } else if (null === _cache) {
                SubCache.cache[sub] = [_cb];
                getSub(auth_token, sub, (error, result) => {
                    if (error) {
                        _cb({
                            email: "?",
                        });
                    } else {
                        const cbs = SubCache.cache[sub];
                        const _result = result.result;
                        SubCache.cache[sub] = _result;
                        cbs.forEach((cb) => cb(_result));
                    }
                });
            } else {
                _cb(_cache);
            }
        } else {
            _cb({
                email: "?",
            });
        }
    }

    static invalidate(sub: string) {
        if (sub) {
            delete SubCache.cache[sub];
        }
    }
}
