﻿import { isStabilityCriteriaExtension } from "../../extensions/core.extensions";
import { Shimmer } from "@fluentui/react";
import * as React from "react";
import { ExtensionField } from "./ExtensionField";

type ExtensionFieldListProps = {
    fields;
    _;
    values;
    onChange;
    atm?;
    card: { tid: number; aid: number; i: number };
    safeTradeId: string;
};

export function ExtensionFieldList({ _, atm, card, fields, onChange, safeTradeId, values }: ExtensionFieldListProps) {
    const stabilityExtensions = (fields || []).filter((field) => isStabilityCriteriaExtension(field?.ext));

    if (stabilityExtensions.length > 0 && safeTradeId === null) {
        return <Shimmer width={"100%"} styles={{ root: { marginTop: "10px" } }} />;
    }

    const filteredFields = (fields || []).filter(
        (field) =>
            !isStabilityCriteriaExtension(field?.ext) ||
            (isStabilityCriteriaExtension(field?.ext) && field["stability.trades"]?.includes(safeTradeId)),
    );

    return filteredFields.map((field) => (
        <ExtensionField
            key={field.key}
            field={field}
            value={(values || {})[field.key]}
            _={_}
            onChange={onChange}
            values={values}
            atm={atm || []}
            card={card}
        />
    ));
}
