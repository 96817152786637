import { ChoiceGroup, IChoiceGroupOption, Stack, StackItem } from "@fluentui/react";
import { SettingsHeader } from "../../common/settingsHeader/settingsHeader";
import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { SettingsText } from "../../common/settingsText/SettingsText";

export type ScheduleSettingsOptionKey = "projectSetting" | "alwaysShow" | "alwaysHide";

type ScheduleSettingsOption = IChoiceGroupOption & {
    key: ScheduleSettingsOptionKey;
};

type ScheduleSettings = {
    onChange: (newValueKey: ScheduleSettingsOptionKey) => void;
    selected?: ScheduleSettingsOptionKey;
};

export function ScheduleSettings({ onChange, selected }: ScheduleSettings) {
    if (selected !== null && selected !== undefined && typeof selected !== "string") {
        throw new Error("settings param needs to be type of string");
    }

    if (typeof onChange !== "function") {
        throw new Error("onChange param needs to be type of function");
    }

    const options: ScheduleSettingsOption[] = [
        /* TODO: Wait for product team and check whether project setting is needed
        {
            key: 'projectSetting',
            text: intl.get('PreferenceSettings.Schedule.showNonWorkingDays.choiceGroupOptions.projectSetting')
        },
         */
        {
            key: "alwaysShow",
            text: intl.get("PreferenceSettings.Schedule.showNonWorkingDays.choiceGroupOptions.alwaysShow"),
        },
        {
            key: "alwaysHide",
            text: intl.get("PreferenceSettings.Schedule.showNonWorkingDays.choiceGroupOptions.alwaysHide"),
        },
    ];

    const handleChange = (ev: React.SyntheticEvent<HTMLElement>, option: ScheduleSettingsOption) => {
        const validKey = returnValidScheduleSettingsOptionKey(option.key);
        selected = validKey;
        onChange(validKey);
    };

    function isValidScheduleSettingsOptionKey(optionKey: ScheduleSettingsOptionKey): boolean {
        return ["projectSetting", "alwaysShow", "alwaysHide"].includes(optionKey);
    }

    function returnValidScheduleSettingsOptionKey(optionKey: ScheduleSettingsOptionKey): ScheduleSettingsOptionKey {
        if (isValidScheduleSettingsOptionKey(optionKey)) {
            return optionKey;
        } else {
            return "projectSetting";
        }
    }

    return (
        <Stack tokens={{ childrenGap: 20 }}>
            <StackItem>
                <SettingsHeader headerText={intl.get("PreferenceSettings.Schedule.header")} />
            </StackItem>
            <StackItem styles={{ root: { padding: "0 22px" } }}>
                <Stack tokens={{ childrenGap: 10 }}>
                    <StackItem>
                        <SettingsText
                            title={intl.get("PreferenceSettings.Schedule.showNonWorkingDays.header")}
                            description={intl.get("PreferenceSettings.Schedule.showNonWorkingDays.description")}
                        />
                    </StackItem>
                    <StackItem>
                        <ChoiceGroup
                            selectedKey={returnValidScheduleSettingsOptionKey(selected)}
                            options={options}
                            onChange={handleChange}
                        />
                    </StackItem>
                </Stack>
            </StackItem>
        </Stack>
    );
}
