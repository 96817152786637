import { NotificationService, Notification, NotificationTarget } from "./NotificationService";
import { SERVICES } from "@/model/services";


export class LCMDNotificationService implements NotificationService{

    async addNotification(fromUserId: string, users: string[], notification: Notification, auth_token: string) {}
    async sendNotification(fromUserId: string, users: string[], notification: Notification, auth_token: string) {
        if (notification.target.includes(NotificationTarget.Email)) {

            const body = {
                fromUserId: fromUserId,
                mailToUsers: users,
                notification: notification,
            }

            const headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': auth_token ? auth_token : ''
            });

            const response = await fetch(`${SERVICES.REST_API}/users/send-notification-email`,
                {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify(body)
                    }
            );

            if(response.ok){
                console.log(response);
            } else {
                console.error("LCMDNotificationService: Error on invite");
            }

        }
    }
    markNotificationAsRead(id: string) {}
    removeNotification(id: string) {}


}

