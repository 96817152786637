import { mergeStyleSets } from "@fluentui/react";

export const changesPanelClassNames = mergeStyleSets({
    panel: {
        overscrollBehavior: "none",
    },
    container: {
        overscrollBehavior: "none",
        width: "420px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
    },
    search: {
        paddingTop: 5,
        paddingBottom: 7,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: "white",
    },
    taskText: {
        fontWeight: "bold",
    },
    footer: {
        padding: "12px 8px",
        left: 0,
        bottom: 0,
        position: "absolute",
        width: "100%",
        height: 56,
        borderTop: "1px solid #E1E4E5",
        backgroundColor: "#ffffff",
    },
    ppWarningFooter: {
        left: 0,
        bottom: 0,
        position: "absolute",
        width: "100%",
        height: 40,
    },
    line: {
        marginLeft: -30,
        borderLeft: "1px solid #000",
        width: 10,
        height: "calc(100% + 23px)",
        marginTop: -11,
    },
});

export const changesPanelClassList = mergeStyleSets({
    legend: {
        display: "flex",
        borderRadius: 8,
        flexDirection: "row",
        padding: 5,
        pointerEvents: "none",
        userSelect: "none",
        fontFamily: "Inter",
    },
    legendText: {
        fontSize: 12,
        lineHeight: 16,
        fontWeight: 400,
        letterSpacing: "-0.01em",
        color: "#2C3032",
        marginRight: "12px",
    },
    legendIcon: { transform: "translateY(-2px)", width: 16, height: 16, fontSize: 17, marginRight: "6px" },
});

export const changesPanelStyles = {
    ActivityItem: {
        root: {
            marginTop: 5,
            marginBottom: 15,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 14,
        },
    },
};
