import React, { useEffect, useState } from "react";
import { IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { useLCMD } from "../../../app/LCMContext";
import { LCMDTag, TagTheme } from "../../common/LCMDTag";

type HistoryReasons = {
    processId: number;
    opId: number;
};

export function ReasonCode(props: HistoryReasons) {
    const LCMD = useLCMD();
    const [selectedKey, setSelectedKey] = useState<string | undefined>();
    const [reasonCodes, setReasonCodes] = useState<IChoiceGroupOption[]>([]);

    useEffect(() => {
        LCMD.getAttachedReasonCode(props.processId, props.opId).then((attachedReasonCode) => {
            if (!attachedReasonCode) {
                setSelectedKey(undefined);
                return;
            }
            setSelectedKey(attachedReasonCode.rcid);
        });
    }, [props.opId, props.processId]);

    useEffect(() => {
        LCMD.getAvailableReasonCodes({
            callback: (reasonCodes) => {
                setReasonCodes(reasonCodes.map((reasonCode) => ({ key: reasonCode.id, text: reasonCode.name })));
            },
        });
    }, []);

    return (
        <>
            {reasonCodes.map((o) =>
                o.key === selectedKey ? <LCMDTag value={o.text} theme={TagTheme.GREY} key={o.key} /> : "",
            )}
        </>
    );
}
