import * as React from "react";
import { useMemo } from "react";

import { Stack, mergeStyleSets, getTheme } from "@fluentui/react";

import { Header } from "./Header";
import { Body } from "./Body";
import {formatWorkforceOutput} from "@/model/GlobalHelper";

export type ColumnOverviewProps = {
    date: number;
    workforce: { trid: string; color: number; name: string; workforce: number }[];
    isWorkforceSet: boolean;
};

const theme = getTheme();

const classNames = mergeStyleSets({
    wrapper: {
        padding: "5px 8px 8px 8px",
        borderRadius: 4,
        border: "1px solid rgba(0, 0, 0, 0.05)",
        backgroundColor: theme.semanticColors.bodyStandoutBackground,
        boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.12), 0px 0px 6px rgba(0, 0, 0, 0.08)",
    },
});

function ColumnOverview({ date, isWorkforceSet, workforce }: ColumnOverviewProps) {
    const totalWorkforce = useMemo(() => {
        return formatWorkforceOutput(workforce.reduce((total, trade) => {
            return total + trade.workforce;
        }, 0));
    }, [workforce]);

    return (
        <Stack className={classNames.wrapper}>
            <Header date={date} totalWorkforce={totalWorkforce} isWorkforceSet={isWorkforceSet} />
            <Body workforce={workforce} />
        </Stack>
    );
}

export { ColumnOverview };
