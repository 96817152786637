import * as React from "react";
import { useEffect, useState } from "react";
import { Icon, ScrollablePane, ScrollbarVisibility, Shimmer, Stack, StackItem } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";
import { Icons } from "../../../RegisteredIcons";
import { useLCMD } from "../../../../app/LCMContext";
import { HistoryActionName, HistoryChangeEvent, HistoryMeta } from "../../../../app/interface";
import { decimalToRgb } from "../../../utils/color/decimal-to-rgb";
import {
    useOldValuesOfProcesses,
    useOldValuesWithIDOfProcesses,
    useTradeColorOfProcesses,
} from "../../../hooks/useProjectData.hook";
import { AreaList } from "../../../common/areaList/areaList";
import { calcWorkingEndDateInMs } from "../../../Utils";
import { getCurrentLanguage, getCurrentLocale } from "../../../utils/date/locale";
import { useCalendarSettings } from "../../../hooks/useCalendarSettings.hook";
import { useCanvasNavigation } from "@/components/hooks/useCanvasNavigation.hook";

export function MultipleChangesList({ changeEvent, uniquePath }: { changeEvent: HistoryMeta; uniquePath: boolean }) {
    const LCMD = useLCMD();
    const oldValues = useOldValuesOfProcesses(changeEvent);
    const oldValuesWithProcessIds = useOldValuesWithIDOfProcesses(changeEvent);
    const [eventsMap, setEventsMap] = useState(new Map());
    const [eventsKeys, setEventsKeys] = useState([]);
    const { isLoading, data: tradeMap, isError, error } = useTradeColorOfProcesses(changeEvent);
    const [showProcesses, setShowProcesses] = useState(true);
    const [deletedProcesses, setDeletedProcesses] = useState([]);
    const { goTo } = useCanvasNavigation();

    useEffect(() => {
        const eventTempMap = new Map();
        //@refactor when area is deleted then process should also be crossed
        changeEvent.events.forEach((event) => {
            LCMD.getProcessDetails(event.id, (err, data) => {
                if (data.ppid.value === -1) {
                    console.log("deleted", data);
                    setDeletedProcesses((deletedProcesses) => [...deletedProcesses, data.pid.value]);
                }
            });
            const eventTempData = eventTempMap.get(event.id);
            if (eventTempData) {
                eventTempData.push(event);
                eventTempMap.set(event.id, eventTempData);
            } else {
                eventTempMap.set(event.id, [event]);
            }
        });

        setEventsMap(eventTempMap);
        setEventsKeys(Array.from(eventTempMap.keys()));
    }, [changeEvent]);

    useEffect(() => {
        console.log("deletedProcesses", deletedProcesses);
    }, [deletedProcesses]);

    /* TODO:
     * Max length des Gewerkenamens beachten
     */

    const rotate = showProcesses ? 0 : -90;
    const iconStyles = {
        transition: "transform 0.25s",
        transform: `rotate(${rotate}deg)`,
    };

    function navigateToProcess(pid: number) {
        goTo(pid);
    }

    // if (deletedProcesses.length == changeEvent.events.length) {
    //     return <WarningMessage/>
    // }

    return (
        <Stack>
            <StackItem styles={{ root: { height: "40xp", padding: "10px 0" } }}>
                <Stack horizontal onClick={() => setShowProcesses(!showProcesses)}>
                    <StackItem>
                        <Icon iconName={"chevronDown"} styles={{ root: iconStyles }} />
                    </StackItem>
                    <StackItem styles={{ root: { paddingLeft: "18px", fontWeight: 600 } }}>
                        {intl.get("HistoryView.MultiChangesView.ChangedProcesses")}
                    </StackItem>
                </Stack>
            </StackItem>
            {showProcesses && (
                <StackItem styles={{ root: { paddingLeft: "30px", maxHeight: "200px" } }}>
                    <ScrollablePane
                        scrollbarVisibility={ScrollbarVisibility.auto}
                        styles={{
                            root: {
                                position: "relative",
                                width: "100%",
                                height: changeEvent.events.length * 60 + "px",
                                maxHeight: "200px",
                            },
                        }}
                    >
                        <Stack tokens={{ childrenGap: 12 }}>
                            {eventsKeys.map((id, i) => {
                                const event = eventsMap.get(id)[0];
                                return (
                                    // if action is of type MULTI_TRADE_EDIT then only even items from array should be rendered as they reflect the current value
                                    ((changeEvent.action === HistoryActionName.MULTI_TRADE_EDIT && i % 2 === 0) ||
                                        changeEvent.action !== HistoryActionName.MULTI_TRADE_EDIT) && (
                                        <StackItem
                                            key={`${event.processName}-${event.id}${Math.floor(Math.random() * 1000)}`}
                                        >
                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => navigateToProcess(event.id)}
                                            >
                                                <Stack horizontal>
                                                    <StackItem>
                                                        {!isLoading ? (
                                                            <Icon
                                                                iconName={Icons.Lcmd_Trade}
                                                                styles={{
                                                                    root: {
                                                                        fontSize: 22,
                                                                        color: decimalToRgb(
                                                                            tradeMap.get(event.id) || 0,
                                                                        ),
                                                                    },
                                                                }}
                                                            />
                                                        ) : (
                                                            <Shimmer />
                                                        )}
                                                    </StackItem>
                                                    <StackItem styles={{ root: { paddingLeft: "8px" } }}>
                                                        <Stack tokens={{ childrenGap: 6 }}>
                                                            <StackItem
                                                                styles={{
                                                                    root: deletedProcesses.includes(event.id)
                                                                        ? { textDecoration: "line-through" }
                                                                        : {},
                                                                }}
                                                            >
                                                                {event.processName}
                                                            </StackItem>
                                                            <StackItem styles={{ root: { paddingLeft: "8px" } }}>
                                                                <Stack tokens={{ childrenGap: 6 }}>
                                                                    {/*<StackItem>*/}
                                                                    {/*    {event.processName}*/}
                                                                    {/*</StackItem>*/}
                                                                    <StackItem>
                                                                        {oldValues.length > 0 &&
                                                                            changeEvent.action ==
                                                                                HistoryActionName.MULTI_START_EDIT && (
                                                                                <>
                                                                                    <MultipleChangesMovedEntry
                                                                                        oldValue={
                                                                                            oldValuesWithProcessIds.has(
                                                                                                event.id,
                                                                                            )
                                                                                                ? oldValuesWithProcessIds.get(
                                                                                                      event.id,
                                                                                                  )[0]
                                                                                                : undefined
                                                                                        }
                                                                                        historyChangeEvents={eventsMap.get(
                                                                                            event.id,
                                                                                        )}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        {(oldValues.length > 0 &&
                                                                            changeEvent.action ==
                                                                                HistoryActionName.MULTI_NAME_EDIT) ||
                                                                            (changeEvent.action ==
                                                                                HistoryActionName.MULTI_PROCESS_WORKFORCE_EDIT && (
                                                                                <MultipleChangesEntry
                                                                                    entries={[
                                                                                        {
                                                                                            oldValue:
                                                                                                oldValues[i] !== null &&
                                                                                                oldValues[i] !==
                                                                                                    undefined
                                                                                                    ? oldValues[
                                                                                                          i
                                                                                                      ].toString()
                                                                                                    : `${intl.get(
                                                                                                          "HistoryView.NoData",
                                                                                                      )}`,
                                                                                            newValue: event.value,
                                                                                        },
                                                                                    ]}
                                                                                />
                                                                            ))}
                                                                        {changeEvent.action ==
                                                                            HistoryActionName.MULTI_DAYS_EDIT &&
                                                                            eventsMap.get(event.id) && (
                                                                                <MultipleChangesEntryEndDate
                                                                                    oldValue={
                                                                                        oldValuesWithProcessIds.has(
                                                                                            event.id,
                                                                                        )
                                                                                            ? oldValuesWithProcessIds.get(
                                                                                                  event.id,
                                                                                              )[0]
                                                                                            : undefined
                                                                                    }
                                                                                    historyChangeEvents={eventsMap.get(
                                                                                        event.id,
                                                                                    )}
                                                                                />
                                                                            )}
                                                                        {changeEvent.action ==
                                                                            HistoryActionName.MULTI_START_AND_DAYS_EDIT &&
                                                                            eventsMap.get(event.id) && (
                                                                                <MultipleChangesEntryStartDate
                                                                                    oldValue={
                                                                                        oldValuesWithProcessIds.has(
                                                                                            event.id,
                                                                                        )
                                                                                            ? oldValuesWithProcessIds.get(
                                                                                                  event.id,
                                                                                              )[0]
                                                                                            : undefined
                                                                                    }
                                                                                    oldValue1={
                                                                                        oldValuesWithProcessIds.has(
                                                                                            event.id,
                                                                                        )
                                                                                            ? oldValuesWithProcessIds.get(
                                                                                                  event.id,
                                                                                              )[1]
                                                                                            : undefined
                                                                                    }
                                                                                    historyChangeEvents={eventsMap.get(
                                                                                        event.id,
                                                                                    )}
                                                                                />
                                                                            )}
                                                                        {changeEvent.action ==
                                                                            HistoryActionName.MULTI_TRADE_EDIT &&
                                                                            i % 2 === 0 && (
                                                                                <Stack
                                                                                    horizontal
                                                                                    tokens={{ childrenGap: 6 }}
                                                                                >
                                                                                    {/* even items are current value odd items are old values */}
                                                                                    <StackItem
                                                                                        styles={{
                                                                                            root: { color: "#999EA1" },
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            changeEvent.events[i + 1]
                                                                                                .tradeName
                                                                                        }
                                                                                    </StackItem>
                                                                                    <StackItem
                                                                                        styles={{
                                                                                            root: {
                                                                                                color: "#565C60",
                                                                                                paddingTop: "2px",
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        <Icon
                                                                                            iconName={
                                                                                                Icons.Lcmd_Arrow_Right
                                                                                            }
                                                                                            styles={{
                                                                                                root: {
                                                                                                    transform:
                                                                                                        "translate(2, 2)",
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    </StackItem>
                                                                                    <StackItem
                                                                                        styles={{
                                                                                            root: { color: "#565C60" },
                                                                                        }}
                                                                                    >
                                                                                        {event.tradeName}
                                                                                    </StackItem>
                                                                                </Stack>
                                                                            )}
                                                                    </StackItem>
                                                                </Stack>
                                                            </StackItem>
                                                        </Stack>
                                                    </StackItem>
                                                </Stack>
                                            </div>
                                            {uniquePath ? null : <AreaList processId={event.id} />}
                                        </StackItem>
                                    )
                                );
                            })}
                        </Stack>
                    </ScrollablePane>
                </StackItem>
            )}
        </Stack>
    );
}

export function MultipleChangesEntryStartDate({
    oldValue,
    oldValue1,
    historyChangeEvents,
}: {
    oldValue: any;
    oldValue1: any;
    historyChangeEvents: HistoryChangeEvent[];
}) {
    const {
        isLoading: isLoadingCalenderSettings,
        data: calenderSettings,
        isError: isErrorCalenderSettings,
        error: errorCalenderSettings,
    } = useCalendarSettings();
    const event = historyChangeEvents[1];
    const event2 = historyChangeEvents[0];

    return (
        <MultipleChangesEntry
            entries={[
                {
                    oldValue: `${oldValue1} ${intl.get(`UnitTypes.d`, { value: oldValue1 })}`,
                    newValue: `${event.value} ${intl.get(`UnitTypes.d`, { value: event.value })}`,
                },
                {
                    oldValue: new Date(oldValue).toLocaleString(getCurrentLocale().code, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    }),
                    newValue: new Date(event2.value).toLocaleString(getCurrentLocale().code, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    }),
                },
            ]}
        />
    );
}

export function MultipleChangesEntryEndDate({
    oldValue,
    historyChangeEvents,
}: {
    oldValue: any;
    historyChangeEvents: HistoryChangeEvent[];
}) {
    const {
        isLoading: isLoadingCalenderSettings,
        data: calenderSettings,
        isError: isErrorCalenderSettings,
        error: errorCalenderSettings,
    } = useCalendarSettings();
    const event = historyChangeEvents[0];
    const event2 = historyChangeEvents[1];
    const data = calculateEndDates(event, event2, event2.value, oldValue);

    function calculateEndDates(event1, event2, startDateInTimestamp: number, durationDay: number) {
        const oneDay = 24 * 60 * 60 * 1000;
        const currentEndTimestamp = calcWorkingEndDateInMs(event2.value, event1.value, calenderSettings);
        const endDate = new Date(currentEndTimestamp - oneDay).toLocaleString(getCurrentLocale().code, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });

        const currentOldEndTimestamp = calcWorkingEndDateInMs(startDateInTimestamp, durationDay, calenderSettings);
        const oldEndDate = startDateInTimestamp
            ? new Date(currentOldEndTimestamp - oneDay).toLocaleString(getCurrentLocale().code, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
              })
            : null;

        return {
            endDate,
            oldEndDate,
        };
    }

    return (
        <MultipleChangesEntry
            entries={[
                {
                    oldValue: `${oldValue} ${intl.get(`UnitTypes.d`, { value: oldValue })}`,
                    newValue: `${event.value} ${intl.get(`UnitTypes.d`, { value: event.value })}`,
                },
                {
                    oldValue: data?.oldEndDate,
                    newValue: data?.endDate,
                },
            ]}
        />
    );
}

export function MultipleChangesMovedEntry({
    oldValue,
    historyChangeEvents,
}: {
    oldValue: any;
    historyChangeEvents: HistoryChangeEvent[];
}) {
    const { isLoading: isLoadingCalendarSettings, isError, data: calendarMeta } = useCalendarSettings();
    const currentEndTimestamp = calcWorkingEndDateInMs(
        historyChangeEvents[0]?.value,
        historyChangeEvents[1].value,
        calendarMeta,
    );

    const oneDay = 24 * 60 * 60 * 1000;
    const endDate = new Date(currentEndTimestamp - oneDay).toLocaleString(getCurrentLanguage(), {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
    const oldEndDate = oldValue
        ? new Date(
              calcWorkingEndDateInMs(oldValue, historyChangeEvents[1].value, calendarMeta) - oneDay,
          ).toLocaleString(getCurrentLanguage(), { day: "2-digit", month: "2-digit", year: "numeric" })
        : null;

    if (isLoadingCalendarSettings) {
        return <Shimmer />;
    }

    return (
        <MultipleChangesEntry
            entries={[
                {
                    oldValue: oldEndDate,
                    newValue: endDate,
                },
            ]}
        />
    );
}

export function MultipleChangesEntry({ entries }: { entries: { oldValue: string; newValue: string }[] }) {
    return (
        <>
            {entries.map((entry) => {
                return (
                    <Stack horizontal tokens={{ childrenGap: 6 }}>
                        <StackItem styles={{ root: { color: "#999EA1" } }}>{entry.oldValue}</StackItem>
                        <StackItem styles={{ root: { color: "#565C60", paddingTop: "2px" } }}>
                            <Icon
                                iconName={Icons.Lcmd_Arrow_Right}
                                styles={{ root: { transform: "translate(2, 2)" } }}
                            />
                        </StackItem>
                        <StackItem styles={{ root: { color: "#565C60" } }}>{entry.newValue}</StackItem>
                    </Stack>
                );
            })}
        </>
    );
}
