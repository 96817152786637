﻿import { create } from "zustand";

export type EmailStore = {
    email: string;
    setEmail: (email: string) => void;
};

export const useEmailStore = create<EmailStore>((set) => ({
    email: "",
    setEmail: (email) => set({ email: email.toLowerCase() }),
}));
