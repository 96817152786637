import * as React from "react";
import { useMemo, useCallback } from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import { Stack, Text, Dropdown, Icon } from "@fluentui/react";
import { ViewTypes } from "../../../constants";

export type ViewTypeOptionProps = {
    value: string;
    onChange: (value: string) => void;
};

function onRenderCaretDown() {
    return <Icon iconName="ChevronRightMed" aria-hidden="true" />;
}

function ViewTypeOption(props: ViewTypeOptionProps) {
    const { value } = props;

    const options = useMemo(() => {
        return [
            {
                key: ViewTypes.byTrade,
                text: intl.get("charts.resource.viewTypesNames.byTrade"),
            },
            {
                key: ViewTypes.byWorkforce,
                text: intl.get("charts.resource.viewTypesNames.byWorkforce"),
            },
        ];
    }, []);

    const onChange = useCallback(
        (ev, item) => {
            props.onChange(item.key);
        },
        [props.onChange],
    );

    return (
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <Text>{intl.get("charts.resource.viewType")}</Text>
            <Dropdown selectedKey={value} onChange={onChange} options={options} onRenderCaretDown={onRenderCaretDown} />
        </Stack>
    );
}

export { ViewTypeOption };
