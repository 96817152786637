import * as React from "react";
import { Icon } from "@fluentui/react";
import { FilterRowTop } from "./FilterRowTop";
import { Icons } from "../../../../RegisteredIcons";
import { decimalToRgb } from "../../../../utils/color/decimal-to-rgb";
import { filterRowStyles } from "./filterRowStyles";

type TradesFilterRow = {
    label: string;
    badgeCount: number;
    data: any[];
    onModifyButtonClick: () => void;
};

export function TradesFilterRow({ label, badgeCount, onModifyButtonClick, data }: TradesFilterRow) {
    return (
        <div className={filterRowStyles.body}>
            <FilterRowTop onModifyButtonClick={onModifyButtonClick} label={label} badgeCount={badgeCount} />
            <div>
                {data.map((t) => {
                    return (
                        <div className={filterRowStyles.button} key={t.id}>
                            <Icon
                                iconName={Icons.Lcmd_Trade}
                                styles={{ root: { color: decimalToRgb(t.color), marginRight: 3 } }}
                            />
                            <span>{t.name}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
