import * as React from "react";
import { ReactNode } from "react";

import { mergeStyleSets, Text } from "@fluentui/react";

export type TitleProps = {
    children: ReactNode;
};

const classNames = mergeStyleSets({
    title: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
});

function Title(props: TitleProps) {
    const { children } = props;

    return <Text className={classNames.title}>{children}</Text>;
}

export { Title };
