import * as React from "react";
import { CanvasCommonProps, CanvasMeta, CanvasViewPort } from "./Canvas";
import type { CalendarGrid, CanvasViewConst } from "../../../model/DataModel";
import { CONST } from "../../settings";
import {
    ContextualMenu,
    DocumentCard,
    FontWeights,
    getTheme,
    IconButton,
    IContextualMenuProps,
    IIconProps,
    mergeStyleSets,
} from "@fluentui/react";
import { intl } from "../../GlobalHelperReact";
import { gfu } from "../../../model/GlobalHelper";
import DigitalPlanningBoard from "../DigitalPlanningBoard";
import { MainWorkerMessage } from "../../MainWorkerPipe";

const theme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
    dbh: [
        // DashBoard Header
        theme.fonts.xxLarge,
        {
            color: theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
        },
    ],
    img: {
        selectors: {
            "&:hover": { outline: "2px solid #009BD4" },
        },
    },
    imggray: {
        backgroundColor: "lightgray",
        opacity: 0.5,
    },
});

type CanvasDashboardProps = CanvasCommonProps & {
    view: CanvasViewPort;
    meta: CanvasMeta;
};

type CanvasDashboardState = {
    const: CanvasViewConst;
    showProps: boolean;
    filter: any;
    grid: CalendarGrid;
};

export class CanvasDashboard extends React.Component<CanvasDashboardProps, CanvasDashboardState> {
    state = {
        const: this.props.worker.canvas.viewConst,
        showProps: false,
        filter: this.props.worker.canvas.filter,
        grid: this.props.worker.canvas.grid,
    };
    onWorkerMsg = function (this: CanvasDashboard, msg: MainWorkerMessage) {
        switch (msg[0]) {
            case "canvas":
                {
                    const canvas = msg[1];
                    const C = canvas?.viewConst || this.state.const || this.props.worker.canvas.viewConst;
                    const filter = gfu(canvas?.filter, this.state.filter, null);
                    const grid = gfu(canvas?.grid, this.state.grid, null);
                    if (C !== this.state.const || filter !== this.state.filter || grid != this.state.grid) {
                        this.setState({
                            const: C,
                            filter,
                            grid,
                        });
                    }
                }
                break;
            default:
                break;
        }
    }.bind(this);
    private onClone = function (this: CanvasDashboard) {
        this.props.worker.postMessage([
            "export",
            {
                target: "clone",
            },
        ]);
    }.bind(this);
    private onShare = function (this: CanvasDashboard) {
        this.props.worker.dispatchMessage(["toggle", "user", true]);
    }.bind(this);
    private onUnlink = function (this: CanvasDashboard) {
        if (this.props.worker.auth.params.sub) {
            this.props.worker.dispatchMessage([
                "framework",
                "unlink",
                {
                    subs: this.props.worker.auth.params.sub,
                    cb: (error, result) => {
                        if (!error) {
                            window.location.reload();
                        }
                    },
                },
            ]);
        }
    }.bind(this);
    private onProps = function (this: CanvasDashboard) {
        this.setState({
            showProps: true,
        });
    }.bind(this);
    private moreIcon: IIconProps = {
        iconName: "MoreVertical",
    };
    private contextMenu: IContextualMenuProps = {
        items: [
            {
                key: "clone",
                //disabled: true,
                iconProps: { iconName: "Copy" },
                onClick: this.onClone,
            },
            {
                key: "share",
                //disabled: true,
                iconProps: { iconName: "Relationship" },
                onClick: this.onShare,
            },
            {
                key: "props",
                disabled: true,
                iconProps: { iconName: "Settings" },
                onClick: this.onProps,
            },
            {
                key: "unlink",
                iconProps: { iconName: "delete" },
                onClick: this.onUnlink,
            },
        ],
    };
    private ProjectMoreButton = function (this: CanvasDashboard, props: { meta; readonly }) {
        const _contextMenu = React.useMemo(
            () => ({
                ...this.contextMenu,
                items: this.contextMenu.items.map((e) => ({
                    ...e,
                    text: intl.get(["overview.cmd", e.key, "text"].join(".")),
                    title: intl.get(["overview.cmd", e.key, "title"].join(".")),
                    disabled: props.readonly && "unlink" !== e.key,
                })),
            }),
            [props.meta],
        );
        return (
            <IconButton
                styles={{
                    root: {
                        //                height: "100%"
                    },
                }}
                menuProps={_contextMenu}
                iconProps={this.moreIcon}
                data-selection-disabled={true}
                data-is-focusable={false}
                split={false}
                onRenderMenuIcon={() => null}
                menuAs={(menuProps: IContextualMenuProps) => {
                    const menuPropsBound = Object.assign({}, menuProps, {
                        items: menuProps.items.map((entry) =>
                            Object.assign({}, entry, {
                                //onClick: onContextMenu.bind(this, selection, item)
                            }),
                        ),
                    });
                    return <ContextualMenu {...menuPropsBound} />;
                }}
            />
        );
    }.bind(this);

    componentDidMount(this: CanvasDashboard) {
        this.props.worker.registerHandler(this.onWorkerMsg);
    }

    componentWillUnmount(this: CanvasDashboard) {
        this.props.worker.unregisterHandler(this.onWorkerMsg);
    }

    render(this: CanvasDashboard) {
        return this.props.meta ? (
            "function" === typeof this.props.worker.config?.dashboard ? (
                <div
                    style={{
                        display: "flex",
                        boxSizing: "border-box",
                        width: "100%",
                        height: "100%",
                        paddingTop: CONST.titleHeight,
                    }}
                >
                    <this.props.worker.config.dashboard
                        filter={this.state.filter}
                        grid={this.state.grid}
                        error={this.props.meta.error}
                    />
                </div>
            ) : (
                <>
                    <div
                        style={{
                            display: "flex",
                            boxSizing: "border-box",
                            width: "100%",
                            height: "100%",
                            paddingTop: CONST.titleHeight,
                            overflow: "overlay",
                            alignItems: "start",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                //backgroundColor: "purple",
                                boxSizing: "border-box",
                                paddingTop: 40,
                                width: 1024,
                                flexGrow: 0,
                                flexShrink: 1,
                                flexDirection: "column",
                                gap: 10,
                            }}
                        >
                            <div className={classNames.dbh}>
                                {this.props.meta?.name || "..."}
                                <this.ProjectMoreButton meta={this.props.meta} readonly={this.state.const.readonly} />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 10,
                                    //flexGrow: 1,
                                    //flexShrink: 1
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    marginTop: 20,
                                }}
                            >
                                <DocumentCard
                                    styles={{
                                        root: {
                                            maxWidth: 452,
                                            width: 452,
                                            minWidth: 452,
                                            minHeight: 350,
                                            height: 350,
                                        },
                                    }}
                                >
                                    <img
                                        className={this.props.meta.error ? classNames.imggray : classNames.img}
                                        src={["img", intl.get("overview.pp.img")].join("/")}
                                        width="452"
                                        height="350"
                                        onClick={
                                            this.props.meta.error ? undefined : this.onCardClick.bind(this, "project")
                                        }
                                    />
                                </DocumentCard>
                                <DocumentCard
                                    styles={{
                                        root: {
                                            maxWidth: 452,
                                            width: 452,
                                            minWidth: 452,
                                            minHeight: 350,
                                            height: 350,
                                        },
                                    }}
                                >
                                    <img
                                        className={
                                            this.state.const.readonly || this.props.meta.error
                                                ? classNames.imggray
                                                : classNames.img
                                        }
                                        src={["img", intl.get("overview.db.img")].join("/")}
                                        width="452"
                                        height="350"
                                        onClick={
                                            this.state.const.readonly || this.props.meta.error
                                                ? undefined
                                                : this.onCardClick.bind(this, "dailyboard")
                                        }
                                    />
                                </DocumentCard>
                            </div>
                        </div>
                    </div>
                    {this.state.showProps ? null : null}
                </>
            )
        ) : null;
    }

    private onCardClick(this: CanvasDashboard, view: string) {
        if ("dailyboard" === view) {
            DigitalPlanningBoard.openProject(this.props.worker, this.props.meta.session, true);
        } else {
            this.props.worker.dispatchMessage(["framework", "toggle", view]);
        }
    }
}
