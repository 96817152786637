export function distance(x1: number, y1: number, x2: number, y2: number): number {
    return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
}

export function isPointNearLine(px, py, x1, y1, x2, y2, threshold?) {
    // const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const length = distance(x1, y1, x2, y2);
    const u = ((px - x1) * (x2 - x1) + (py - y1) * (y2 - y1)) / (length * length);

    if (u >= 0 && u <= 1) {
        const intersectionX = x1 + u * (x2 - x1);
        const intersectionY = y1 + u * (y2 - y1);

        const distance = Math.sqrt((px - intersectionX) ** 2 + (py - intersectionY) ** 2);
        return distance <= threshold;
    }

    return false;
}

export function drawLines(ctx: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number) {
    // ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = "black";

    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
}

export function lerp(a: number, b: number, t: number): number {
    return a + (b - a) * t;
}
