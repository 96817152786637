import { useLCMD } from "../../app/LCMContext";
import { useEffect, useState } from "react";

export function useUserRole(): string {
    const LCMD = useLCMD();
    const [roles, setRoles] = useState<any>(null);
    useEffect(() => {
        LCMD.getProjectDetails((error, data) => {
            setRoles(data.meta.role);
        });
    }, [LCMD, roles]);
    return roles;
}
