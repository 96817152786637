﻿import React, { useMemo } from "react";
import { AttachmentThumbnail } from "./AttachmentThumbnail";
import { Skeleton } from "../ui/skeleton";
import { LcmdModal2 } from "../common/LcmModal2";
import { Button } from "../ui/button";
import { intl } from "@/legacy/GlobalHelperReact";

type AttachmentThumbnailControlProps = {
    card: any;
    atm?: any[];
    status: any[];
    setStatus: any;
};

export function AttachmentThumbnailControl(props: AttachmentThumbnailControlProps) {
    const atm = props.atm || [];
    const status = props.status || [];

    return (
        <div className="mb-1 flex flex-row flex-wrap gap-2">
            {atm.map((a) => {
                return <AttachmentThumbnail key={a.blobId} card={props.card} attachment={a} />;
            })}
            {Array.isArray(status) ? (
                status.map((s) => {
                    return <AttachmentPlaceholder key={s.key} />;
                })
            ) : (
                <UploadErrorDialog setStatus={props.setStatus} />
            )}
        </div>
    );
}

function AttachmentPlaceholder() {
    return (
        <div className="h-[120px] w-[120px] border border-solid px-0.5 py-4">
            <Skeleton className="h-full w-full rounded-sm" />
        </div>
    );
}

type UploadErrorDialogProps = {
    setStatus: any;
};

function UploadErrorDialog({ setStatus }: UploadErrorDialogProps) {
    const onHide = useMemo(
        () => () => {
            setStatus(null);
        },
        [setStatus],
    );

    return (
        <LcmdModal2
            size="s"
            open={true}
            onOpenChange={onHide}
            buttons={[
                <Button key="ok" onClick={onHide}>
                    {intl.get("fw.ok")}
                </Button>,
            ]}
            header={{
                title: intl.get("tasks.attachments.errorTitle"),
            }}
        >
            {intl.get("tasks.attachments.errorText")}
        </LcmdModal2>
    );
}
