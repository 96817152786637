import { ItemPriority, ItemState, ItemType, StoreActionItem, ToDoProps } from "./interface";
import { LCMDContextTodoItem, LCMDContextTodoResult } from "../../app/LCMDContextTypes";

export function generateTodosFromRawDate(data: LCMDContextTodoResult): ToDoProps[] {
    return data.items.map((item, index): ToDoProps => {
        return {
            id: item.id as number,
            actionId: item.ai?.id,
            status: item.done ? ItemState.DONE : ItemState.OPEN,
            issue: item.ai?.issue || item.stab?.issue || data.stabs[item.stab?.key]?.label || "not set",
            action: item.ai?.action || item.stab?.action || "",
            process: { pid: item.process.pid, name: item.process.name, areaPath: (<any>item.process).areaPath },
            trade: (item.process?.trades || []).map((trade) => trade),
            area: "TODO",
            raisedBy: item.ai?.raisedBy || item.stab?.raisedBy || [],
            responsible: item.ai?.responsible || item.stab?.responsible || [],
            actionBy: item.ai?.actionBy || item.stab?.actionBy || "",
            type: item.ai ? ItemType.ACTION_ITEM : ItemType.STABILITY_CRITERIA,
            priority: item.ai?.priority ?? item.stab?.priority ?? ItemPriority.MEDIUM,
            deadline: item.due || null,
            stability: item.stab?.key,
            leadDays: calculateLeadDays(item, data),
            identified: item.ai?.identified || item.stab?.identified || null,
            needed: item.ai?.needed || item.stab?.needed || null,
            resolved: item.ai?.resolved || item.stab?.resolved || null,
        };
    });
}

export function calculateLeadDays(item: LCMDContextTodoItem, data: LCMDContextTodoResult) {
    if (item.stab?.deadline) {
        return item.stab.deadline < 0 ? Math.abs(item.stab.deadline) : null;
    }

    if (item.stab && !item.stab.deadline) {
        if (
            data.stabs &&
            item.stab.key != undefined &&
            data.stabs[item.stab.key] &&
            data.stabs[item.stab.key]["stability.lead"]
        ) {
            return data.stabs[item.stab.key]["stability.lead"];
        }
    }

    return null;
}

export function getEmptyToDo(): ToDoProps {
    const date = new Date();
    const UTC_Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + 1));
    return {
        id: null,
        actionId: null,
        process: { name: "", pid: null, areaPath: [] },
        issue: "",
        action: "",
        deadline: UTC_Date.getTime(),
        priority: ItemPriority.MEDIUM,
        trade: [],
        status: ItemState.OPEN,
        type: ItemType.ACTION_ITEM,
        responsible: [],
        raisedBy: [],
        actionBy: "",
        stability: null,
        area: "",
        leadDays: null,
        identified: null,
        needed: null,
        resolved: null,
    };
}

export function createStoreItem(item: ToDoProps): StoreActionItem {
    // @todo: only add props where values have changed!
    return {
        issue: {
            value: item.issue,
        },
        action: {
            value: item.action,
        },
        deadline: {
            value: item.deadline,
        },
        priority: {
            value: item.priority,
        },
        status: {
            value: item.status,
        },
        trade: {
            value: item.trade,
        },
        type: {
            value: item.type,
        },
        raisedBy: {
            value: item.raisedBy,
        },
        responsible: {
            value: item.responsible,
        },
        actionBy: {
            value: item.actionBy,
        },
        identified: {
            value: item.identified,
        },
        needed: {
            value: item.needed,
        },
        resolved: {
            value: item.resolved,
        },
    };
}
