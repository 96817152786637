import { useLCMD } from "../../app/LCMContext";
import * as React from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { DangerButton } from "../common/dangerButton";
import { DataModelFilterCollection, intl } from "lcmd2framework";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export const DeleteTradeDialog = (params) => {
    const LCMD = useLCMD();
    const [filterCollection, setFilterCollection] = React.useState([]);

    React.useEffect(() => {
        return LCMD.loadFilterData((data) => {
            const filterCollection: DataModelFilterCollection = data?.filterCollection || [];
            console.log(filterCollection);
            setFilterCollection(filterCollection);
        });
    }, [setFilterCollection]);

    const deleteSavedFilter = (filter, condition) => {
        const updatedFilter = {
            ...filter._filter,
            saveAs: {
                hid: filter._hid,
                pos: filter._pos,
                name: condition ? null : filter.name,
            },
        };

        LCMD.setFilter(updatedFilter);
    };

    const checkSavedFilter = (filterCollection) => {
        filterCollection.filter((filter) => {
            const tradeFilter = filter._filter.trade;

            if (
                Object.keys(filter._filter).length == 1 &&
                tradeFilter?.length === 1 &&
                tradeFilter[0] === params.tradeId
            ) {
                deleteSavedFilter(filter, true);
                return false;
            }

            return true;
        });
    };

    const onDismiss = React.useCallback(() => {
        LCMD.showDialog("dialog.trade.delete", false); // hide dialog
    }, [LCMD]);
    const onDelete = React.useCallback(() => {
        checkSavedFilter(filterCollection);
        LCMD.setTradeDetails(params.tradeId, {
            name: {
                value: null, // mark as deleted
            },
        });
        LCMD.showDialog("dialog.trade.delete", false); // hide dialog
        WebAppTelemetryFactory.trackEvent("delete-trade");
    }, [LCMD, filterCollection]);
    const modalProps = React.useMemo(() => ({}), []);
    const dialogContentProps = React.useMemo(
        () => ({
            type: DialogType.normal,
            title: intl.get("DeleteTradeModal.headline"),
        }),
        [params?.trade?.label, params?.used],
    );
    const tradeName = params?.trade?.label || "";
    const shortTradeName = tradeName.length > 20 ? tradeName.substring(0, 17) + "..." : tradeName;
    return (
        <Dialog hidden={false} onDismiss={onDismiss} dialogContentProps={dialogContentProps} modalProps={modalProps}>
            <div>
                <p>{intl.getHTML("DeleteTradeModal.deleteMessageSubtitle", { trade: shortTradeName })}</p>
                <p>{intl.get("DeleteTradeModal.deleteMessageBody")}</p>
            </div>
            <DialogFooter>
                <DangerButton
                    styles={{ root: { backgroundColor: "#f00", color: "#fff" } }}
                    onClick={onDelete}
                    text={intl.get("DeleteTradeModal.deleteButton")}
                />
                <DefaultButton onClick={onDismiss} text={intl.get("DeleteTradeModal.cancelButton")} />
            </DialogFooter>
        </Dialog>
    );
};
