import { useState } from "react";
import { useLCMD } from "../../app/LCMContext";
import { Chip } from "@/components/ToDo/interface";
export function usePersonaChips(): Chip[] {
    const LCMD = useLCMD();
    const [chips, setChips] = useState<Chip[]>([]);
    LCMD.useProjectCollaboratorsEffect(
        null,
        (error, data) => {
            if (error) {
                console.error(error);
            }

            const users: Chip[] = data.map((d) => {
                const checkName = () => {
                    const { firstName, lastName } = d.data.meta;
                    if (!firstName || !lastName) {
                        return firstName || lastName || d.data.email;
                    }
                    return `${firstName} ${lastName}`;
                };
                return {
                    key: d.data.sub,
                    name: checkName(),
                    email: d.data.email,
                    meta: d.data.meta,
                };
            });
            setChips(users);
        },
        [],
    );
    return chips;
}
