import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import { ContentTypes } from "../../constants";
import { Button } from "@/components/ui/button";

export type ContentOptionProps = {
    value: string;
    disabled?: boolean;
    onChange: (value: string) => void;
};

function ContentOption({ disabled, onChange: handleChange, value }: ContentOptionProps) {
    return (
        <div className={"flex items-center justify-between"}>
            <span className={"text-sm"}>{intl.get("canvas.cmd.view.content.label")}</span>
            <div className={"flex justify-end gap-2"}>
                <Button
                    disabled={disabled}
                    variant={value === ContentTypes.RESOURCE_CHART ? "secondary" : "outline"}
                    onClick={() => {
                        handleChange(ContentTypes.RESOURCE_CHART);
                    }}
                >
                    {intl.get("canvas.cmd.view.content.options.resourceChart")}
                </Button>
                <Button
                    disabled={disabled}
                    variant={value === ContentTypes.MILESTONES ? "secondary" : "outline"}
                    onClick={() => {
                        handleChange(ContentTypes.MILESTONES);
                    }}
                >
                    {intl.get("canvas.cmd.view.content.options.milestones")}
                </Button>
            </div>
        </div>
    );
}

ContentOption.defaultProps = {
    disabled: false,
};

export { ContentOption };
