import { LucideProps } from "lucide-react";
import React from "react";
import { Button } from "@/components/ui/button";

type IconButton = {
    icon: React.FC<LucideProps>;
    onClick?: () => void;
};

export function IconButton({ icon: Icon, onClick: handleClick }: IconButton) {
    return (
        <Button variant={"ghost"} size={"icon"} className={"w-8 h-8"} onClick={() => handleClick && handleClick()}>
            <Icon size={"20px"}></Icon>
        </Button>
    );
}
