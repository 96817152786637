import * as React from "react";

import { decimalToRgb } from "../../../utils/color/decimal-to-rgb";
import { FontWeights, mergeStyleSets, Stack, Text } from "@fluentui/react";

export type WorkforceProps = {
    color: number;
    workforce: number;
    name: string;
};

const classNames = mergeStyleSets({
    color: {
        width: 10,
        height: 10,
        borderRadius: 2,
    },
    workforce: {
        fontWeight: FontWeights.semibold,
    },
    name: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
});

const tokensProps = { childrenGap: 5 };

function Workforce({ color, name, workforce }: WorkforceProps) {
    return (
        <Stack horizontal verticalAlign="center" tokens={tokensProps}>
            <Stack.Item shrink={0}>
                <div className={classNames.color} style={{ backgroundColor: decimalToRgb(color) }} />
            </Stack.Item>
            <Text className={classNames.workforce}>{workforce}</Text>
            <Text className={classNames.name}>{name}</Text>
        </Stack>
    );
}

export { Workforce };
