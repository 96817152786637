import * as React from "react";
import { useEffect, useState } from "react";
import { LcmdModal } from "../../common/LcmModal";
import {
    CheckboxVisibility,
    DefaultButton,
    DetailsList,
    IColumn,
    PrimaryButton,
    ScrollablePane,
    SearchBox,
    Selection,
    SelectionMode,
    Stack,
    StackItem,
    Toggle,
} from "@fluentui/react";
import { createUserName } from "../../Utils";
import { intl } from "../../../legacy/GlobalHelperReact";
import { UserMap } from "../../hooks/UserMapTypes";

export function ReasonCodesRolesModal({
    open,
    onDismiss: handleOnDismiss,
    onOk: handleOnOk,
    initiallySelected = [],
    autoAddNewUsers = false,
    users,
}: {
    open: boolean;
    onDismiss: () => void;
    onOk: (selectedUsers) => void;
    initiallySelected?: string[];
    autoAddNewUsers?: boolean;
    users: UserMap;
}) {
    const allUsers = Object.entries(users).map((user) => Object.assign(user[1], { key: user[0] }));
    const columns: IColumn[] = [
        {
            key: "name",
            name: intl.get("users.share.name"),
            minWidth: 100,
            onRender: (item) => {
                return createUserName({ email: item.email, meta: item.meta });
            },
        },
        {
            key: "role",
            name: intl.get("users.share.role"),
            minWidth: 100,
            onRender: (item) => {
                return intl.get(`users.share.add.role.${item.role}`);
            },
        },
    ];
    const [userItems, setUserItems] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [selectedUsersTmp, setSelectedUsersTmp] = useState<any[]>([]);
    const [searchText, setSearchText] = useState("");
    const [initialLoaded, setInitialLoaded] = useState(false);
    const [checkAutoAdd, setAutoAdd] = useState<boolean>(autoAddNewUsers);
    const [selection] = useState(
        new Selection({
            onSelectionChanged: () => {
                const selected = selection.getSelection();
                setSelectedUsers(selected);
            },
        }),
    );

    useEffect(() => {
        if (!searchText.length) {
            setUserItems(() => {
                setSelectedUsersTmp(selectedUsers);
                return allUsers;
            });
            return;
        }
        setUserItems(
            allUsers.filter((p) => {
                setSelectedUsersTmp(selectedUsers);
                return p.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            }),
        );
    }, [searchText]);

    useEffect(() => {
        if (userItems.length <= 0) {
            return;
        }

        if (!initialLoaded) {
            userItems.forEach((user, index) => {
                if (initiallySelected.includes(user.key)) {
                    selection.setIndexSelected(index, true, false);
                }
            });
            setInitialLoaded(true);
        }

        if (selectedUsers.length <= 0) {
            return;
        }
        setSelectedUsers(selectedUsersTmp);

        userItems.forEach((user, index) => {
            if (selectedUsersTmp.includes(user)) {
                selection.setIndexSelected(index, true, false);
            }
        });
    }, [userItems]);

    function onSave() {
        const selectedUserIds = selectedUsers.map((user) => user.key);
        handleOnOk({ selectedUsers: selectedUserIds, autoAddNewUsers: checkAutoAdd });
        cleanUpModal();
    }

    function cleanUpModal() {
        setSelectedUsers([]);
        setSearchText("");
        setInitialLoaded(false);
        setAutoAdd(false);
    }

    const buttons = [
        <DefaultButton onClick={handleOnDismiss}>{intl.get("ProcessDependenciesModal.buttons.cancel")}</DefaultButton>,
        <PrimaryButton onClick={onSave}>{intl.get("ProcessDependenciesModal.buttons.apply")}</PrimaryButton>,
    ];

    return (
        <LcmdModal
            isOpen={open}
            header={{
                subtitle: intl.get("ReasonCodesSettings.defineRolesModal.subtitle"),
                title: intl.get("ReasonCodesSettings.defineRolesModal.title"),
            }}
            onDismiss={handleOnDismiss}
            styles={{ main: { width: "660px", height: "616px", overflow: "hidden" } }}
            buttons={buttons}
        >
            <Stack>
                <SearchBox
                    autoFocus={true}
                    onChange={(ev, newValue) => setSearchText(newValue)}
                    placeholder={intl.get("ReasonCodesSettings.defineRolesModal.searchUsers")}
                />
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    styles={{ root: { paddingTop: "20px", paddingLeft: "14px" } }}
                >
                    <StackItem>
                        <span style={{ fontWeight: 600 }}>
                            {intl.get("ReasonCodesSettings.defineRolesModal.invitedUsersToggleLabel")}
                        </span>
                    </StackItem>
                    <StackItem>
                        <Toggle
                            checked={checkAutoAdd}
                            onChange={(ev) => {
                                setAutoAdd(!checkAutoAdd);
                            }}
                        />
                    </StackItem>
                </Stack>
                <ScrollablePane styles={{ root: { position: "relative", height: "378px" } }}>
                    <Stack styles={{ root: { paddingBottom: "20px" } }}>
                        <DetailsList
                            items={userItems}
                            columns={columns}
                            selectionMode={SelectionMode.multiple}
                            selectionPreservedOnEmptyClick={true}
                            checkboxVisibility={CheckboxVisibility.always}
                            selection={selection}
                            setKey={"set"}
                        />
                    </Stack>
                </ScrollablePane>
            </Stack>
        </LcmdModal>
    );
}
