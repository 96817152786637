// todo: REFACTOR ME

import { ConstrainMode, DetailsList, DetailsRowCheck, IColumn, SelectionMode } from "@fluentui/react";
import { assert, intl } from "lcmd2framework";
import { onRenderProcessName, renderRow } from "../../../ProcessView";
import * as React from "react";

const detailsRowCheckStyles20x20 = {
    check: {
        width: 20,
        height: 20,
    },
};

function onTZSelect(setSelected, item) {
    setSelected((selected) => {
        const ret = Object.assign({}, selected);
        if (item.sel) {
            delete ret[item.tid];
        } else {
            ret[item.tid] = item;
        }
        return ret;
    });
}

function onChevronClick(this, selection, item: any, ev) {
    if (2 === item.s || 1 === item.s) {
        const collapse = 1 === item.s;
        this((collapsed) => {
            const ret = Object.assign({}, collapsed);
            if (collapse) {
                ret[item.tid] = true;
            } else {
                delete ret[item.tid];
            }
            return ret;
        });
    }
}

const generateTaktZoneColumns: (setCollapsed, setSelected) => IColumn[] = (setCollapsed, setSelected) => [
    {
        isGrouped: false,
        isMultiline: false,
        key: "column0",
        name: "",
        fieldName: "tid",
        minWidth: 20,
        maxWidth: 20,
        onRender: (item) => (
            <DetailsRowCheck
                styles={detailsRowCheckStyles20x20}
                canSelect={true}
                selected={item.sel}
                isVisible={true}
                compact={true}
                onClick={onTZSelect.bind(null, setSelected, item)}
            />
        ),
    },
    {
        isGrouped: false,
        isMultiline: false,
        key: "column1",
        name: intl.get("filter.tz.header"),
        fieldName: "n",
        minWidth: 100,
        onRender: onRenderProcessName.bind(setCollapsed, true, onChevronClick, null, true),
    },
];

function searchWBS(wbs: any[], search: string) {
    const terms = [search.trim().toLocaleLowerCase()];
    const n_terms = terms.length;
    const path = [];
    const n = wbs.length;
    for (let i = 0; i < n; i++) {
        const item = wbs[i];
        while (item.l < path.length) {
            path.pop();
        }
        assert(item.l === path.length);
        const name: string = (item.n || "").trim().toLocaleLowerCase();
        let hit = false;
        for (let i_term = 0; i_term < n_terms; i_term++) {
            hit = hit || name.indexOf(terms[i_term]) >= 0;
        }
        if (hit) {
            // mark path
            const n_path = path.length;
            for (let i_path = 0; i_path < n_path; i_path++) {
                path[i_path]._hit |= 0x1;
            }
            item._hit = 0x3;
        } else {
            item._hit = 0; // clear flag...
        }
        path.push(item);
    }
}

function filterWBS(wbs: any[], collapsed: any, selected: any, search: string) {
    const ret = [];
    if (search) {
        searchWBS(wbs, search);
    }
    const n = wbs.length;
    for (let i = 0; i < n; ) {
        const sel = wbs[i].tid in selected;
        if (wbs[i].tid in collapsed) {
            ret.push({ ...wbs[i], s: 2, sel: sel });
            let j = i + 1;
            while (j < n && wbs[j].l > wbs[i].l) j++;
            i = j;
        } else if (!search || wbs[i]._hit & 0x1) {
            const _hit = search ? wbs[i]._hit : 0;
            if (sel) {
                ret.push({ ...wbs[i++], sel: sel, hit: _hit });
            } else if (_hit) {
                ret.push({ ...wbs[i++], hit: _hit });
            } else {
                ret.push(wbs[i++]);
            }
        } else {
            i++; // skip...
        }
    }
    return ret;
}

function getTIDKey(item) {
    return item.tid;
}

function isAllSelected(items: any[], selected, trades = false): boolean {
    if (trades) {
        return items.every((i) => i.id === selected[i.id]?.id);
    } else {
        return items.every((i) => i.tid === selected[i.tid]?.tid);
    }
}

function selectAll(items: any[], selected, setSelected, trades = false) {
    const idProp = trades ? "id" : "tid";
    if (isAllSelected(items, selected, trades)) {
        // deselect all
        const tmpSelected = { ...selected };
        items.forEach((i) => {
            delete tmpSelected[i[idProp]];
        });
        setSelected(tmpSelected);
    } else {
        // select all
        const s = {};
        items.forEach((i) => {
            s[i[idProp]] = i;
        });
        setSelected(s);
    }
}

export function TaktzonesList({ tz, collapsed, selected, search, setCollapsed, setSelected }) {
    const columns = React.useMemo(() => generateTaktZoneColumns(setCollapsed, setSelected), []);
    const filteredTZ = filterWBS(tz, collapsed, selected, search);
    return (
        <DetailsList
            compact={true}
            columns={columns}
            items={filteredTZ}
            selectionMode={SelectionMode.none}
            constrainMode={ConstrainMode.unconstrained}
            useReducedRowRenderer={true}
            getKey={getTIDKey}
            onRenderDetailsHeader={() => (
                <DetailsRowCheck
                    styles={{ ...detailsRowCheckStyles20x20, check: { marginLeft: -2 } }}
                    canSelect={true}
                    selected={isAllSelected(filteredTZ, selected)}
                    isVisible={true}
                    compact={true}
                    onClick={() => {
                        selectAll(filteredTZ, selected, setSelected);
                    }}
                />
            )}
            onRenderRow={renderRow}
            styles={{
                root: {
                    maxHeight: 440,
                    overflow: "auto",
                },
            }}
        />
    );
}
