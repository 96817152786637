import { CanvasHostRenderNode, CanvasTaskData } from "@/legacy/api/lcmd2framework";
import { create } from "zustand";
// reasoncode tooltip over the settings tab
interface TooltipStore {
    showTooltip: boolean;
    setShowTooltip: (showTooltip: boolean) => void;
}

export const useTooltipStore = create<TooltipStore>((set) => ({
    showTooltip: false,
    setShowTooltip: (showTooltip: boolean) => set({ showTooltip }),
}));

// tooltip for hovering the processes in canvas
type TooltipState = {
    visible: boolean;
    task: CanvasTaskData & CanvasHostRenderNode;
    x: number;
    y: number;
};

type TooltipActions = {
    setVisible: (x: number, y: number, task: CanvasTaskData & CanvasHostRenderNode) => void;
    setInvisible: () => void;
};

type FilterDataStore = TooltipState & TooltipActions;

export const useCanvasTooltipStore = create<FilterDataStore>((set) => ({
    // State
    visible: false,
    task: null,
    x: null,
    y: null,

    // Actions
    setVisible: (x, y, task) => {
        set({ visible: true, x, y, task });
    },
    setInvisible: () => {
        set({ visible: false, x: null, y: null });
    },
}));
