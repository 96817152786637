import * as React from "react";
import { mergeStyleSets, Stack, StackItem } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";
import { CounterBubble, StyleTheme } from "../common/counter/CounterBubble";

export type NewChanges = {
    show: boolean;
    changesCounter: number;
    onRefresh: () => void;
};

export function NewChangesView(props: NewChanges) {
    const show = !props.show ? { display: "none" } : {};
    const styles = mergeStyleSets({
        outerDiv: {
            backgroundColor: "#EFF8FC",
            paddingLeft: "48px",
            height: "56px",
            ...show,
        },
        refresh: {
            color: "#009BD4",
            selectors: {
                ":hover": {
                    cursor: "pointer",
                },
            },
        },
    });
    return (
        <Stack horizontal verticalAlign={"center"} tokens={{ childrenGap: 20 }} className={styles.outerDiv}>
            <StackItem>
                <CounterBubble value={props.changesCounter} maxValue={99} styleTheme={StyleTheme.BLUE}></CounterBubble>
            </StackItem>
            <StackItem>{intl.get("newChangesCounter.newChanges")}</StackItem>
            <StackItem className={styles.refresh} onClick={props.onRefresh}>
                {intl.get("newChangesCounter.refresh")}
            </StackItem>
        </Stack>
    );
}
