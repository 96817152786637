import React, { useState, useEffect, useMemo, useRef } from "react";
import { intl } from "lcmd2framework";
import { useLCMD } from "../../app/LCMContext";
import { SearchBar } from "../common/SearchBar/SearchBar";
import { Button } from "../ui/button";
import { Settings2 } from "lucide-react";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { useVirtual } from "react-virtual";
import { LcmdModal2 } from "../common/LcmModal2";
import { BaselinesDialogCard } from "./baselines-dialog-card";
import { Separator } from "@/components/ui/separator";
import { useCurrentBaseline } from "@/components/hooks/useBaseline.hook";
import { useUserRole } from "@/components/hooks/useUserRole.hook";

const dropdownKeys = {
    all: "all",
    withNaming: "withNaming",
};

function MainScreen() {
    const LCMD = useLCMD();
    const parentRef = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [dropdownKey, setDropdownKey] = useState(dropdownKeys.all);
    const [items, setItems] = useState([]);
    const [namedItems, setNamedItems] = useState([]);
    const [namedItemsMap, setNamedItemsMap] = useState(new Map());
    const [filteredItems, setFilteredItems] = useState(items);
    const [currentBaselineId, setCurrentBaselineId] = useState<number>(0);
    const [activeBaseline, setActiveBaseline] = useState<number>(0);
    const role = useUserRole();

    const rowVirtualizer = useVirtual({
        size: filteredItems?.length,
        parentRef,
        overscan: 15,
    });

    const { isLoading: isCurrentBaselineLoading, data: currentBaseline, setBaseline } = useCurrentBaseline();

    const {
        isLoading: isBaselineListLoading,
        data,
        saveNamedBaseline,
        editBaselineName,
        deleteBaselineName,
    } = LCMD.useBaselineSettings();

    //Get stored named changes
    useEffect(() => {
        if (isBaselineListLoading) {
            return;
        }

        if (!data?.baseline) {
            return;
        }

        setNamedItems(data.baseline);
        setNamedItemsMap(new Map(data.baseline.map((i) => [i.id, i.name])));
    }, [data, isBaselineListLoading]);

    useEffect(() => {
        if (isCurrentBaselineLoading) {
            return;
        }

        if (!currentBaseline) {
            return;
        }

        setCurrentBaselineId(currentBaseline?.activebaseline);
    }, [currentBaseline, isCurrentBaselineLoading]);

    function saveName(name, { i: id, u: user, d: date }) {
        const foundEntry = namedItems.find((entry) => entry.id == id);

        if (!foundEntry && name == "") {
            return;
        }

        if (name == "") {
            deleteBaselineName(id);
            return;
        }

        if (foundEntry) {
            editBaselineName({
                name: name,
                id,
                user,
                date,
            });
        } else {
            saveNamedBaseline({
                name: name,
                id,
                user,
                date,
            });
        }
    }

    function onNameChange(name, item) {
        saveName(name, item);
    }

    const currentBaselineItem = useMemo(() => {
        if (currentBaselineId == 0) {
            return false;
        }
        const activeBaseline = items.find((item) => item?.i === currentBaselineId);
        if (activeBaseline) {
            const name = namedItemsMap.get(activeBaseline.i) || "";
            activeBaseline.name = name;
            return activeBaseline;
        }

        return false;
    }, [currentBaseline, items]);

    useEffect(() => {
        LCMD.getBaselineIds((error, resp) => {
            if (error) {
                console.error(error);
            } else {
                setItems(resp.data);
                setActiveBaseline(resp.revId);
            }
        });
    }, [LCMD, currentBaseline, data]);

    //Filter
    useEffect(() => {
        if (items.length) {
            let updatedItems = items;

            if (dropdownKey === dropdownKeys.withNaming) {
                updatedItems = namedItems;
                updatedItems = namedItems.map((item) => {
                    return { name: item.name, i: item.id, u: item.user, d: item.date };
                });
            }
            if (searchText) {
                updatedItems = items.filter((item) => item.name?.toLowerCase().includes(searchText.toLowerCase()));
            }

            setFilteredItems(updatedItems);
        }
    }, [items, namedItems, dropdownKey, searchText]);

    function setAsBaseline(item) {
        setBaseline(item.i);
    }
    function activateBaseline(item) {}

    return (
        <div
            style={{
                height: "calc(100vh - 300px)",
                position: "relative",
            }}
        >
            <div className="h-full">
                <SearchBar
                    className="w-100 relative"
                    value={searchText}
                    onSearch={(value) => setSearchText(value || "")}
                    onChange={(event, newValue) => setSearchText(newValue)}
                    placeholder={intl.get("StabilityView.SearchFieldPlaceholderText")}
                />
                <div className="relative my-4 flex items-center justify-between">
                    <div className="text-base font-semibold text-zinc-950">{intl.get("baselines.subtitle")}</div>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" className="flex h-8 items-center rounded px-3">
                                <Settings2 size={16} className="mr-2" />
                                <div className="text-zinc-950">{intl.get("canvas.cmd.filter.text")}</div>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="bg-white">
                            <DropdownMenuLabel>
                                <div className="flex items-center justify-between text-sm font-semibold leading-5 text-black">
                                    {intl.get("baselines.filter.title")}
                                </div>
                            </DropdownMenuLabel>
                            <DropdownMenuGroup>
                                <DropdownMenuCheckboxItem
                                    checked={dropdownKey === dropdownKeys.all}
                                    onCheckedChange={() => setDropdownKey(dropdownKeys.all)}
                                >
                                    {intl.get("baselines.filter.option1")}
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem
                                    checked={dropdownKey === dropdownKeys.withNaming}
                                    onCheckedChange={() => setDropdownKey(dropdownKeys.withNaming)}
                                >
                                    {intl.get("baselines.filter.option2")}
                                </DropdownMenuCheckboxItem>
                            </DropdownMenuGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                <div className="absolute w-full" style={{ height: "calc(100% - 80px)" }}>
                    <div ref={parentRef} style={{ height: "calc(100% - 24px)" }} className="w-full overflow-auto">
                        {currentBaselineItem && (
                            <>
                                <BaselinesDialogCard
                                    item={currentBaselineItem}
                                    isActive={activeBaseline != 0}
                                    onActivateBaseline={activateBaseline}
                                    onNameChange={onNameChange}
                                />
                                <Separator orientation="horizontal" className="my-4" />
                            </>
                        )}
                        {!rowVirtualizer.virtualItems.length ? (
                            <div className="flex justify-center text-gray-500">No item found</div>
                        ) : (
                            <div
                                style={{ height: `${rowVirtualizer.totalSize}px` }}
                                className="relative flex w-full gap-2.5"
                            >
                                {rowVirtualizer.virtualItems.map((virtualRow) => {
                                    const item = filteredItems[virtualRow.index];
                                    const name = namedItemsMap.get(item.i) || "";

                                    item.name = name;

                                    const distance = virtualRow.start;
                                    return (
                                        <div
                                            key={`${virtualRow.index}-${item.i}-${name}`}
                                            ref={virtualRow.measureRef}
                                            style={{ transform: `translateY(${distance}px)` }}
                                            className="absolute left-0 top-0 mx-0 my-1 h-[105px] w-full"
                                        >
                                            <BaselinesDialogCard
                                                item={item}
                                                onNameChange={onNameChange}
                                                onSetAsBaseline={setAsBaseline}
                                                showSetAsBaselineButton={role === "admin"}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function BaselinesDialog({ onShow }: { onShow: (value: boolean) => void }) {
    return (
        <LcmdModal2
            size="l"
            open
            onOpenChange={() => onShow(false)}
            header={{
                title: intl.get("baselines.title"),
            }}
        >
            <MainScreen />
        </LcmdModal2>
    );
}
