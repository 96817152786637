import { EpochMSToLocaleDateStr, HelperDaysUnitToDurationStr, assert } from "particlecore";
import { Core } from "./lcmd2core";
import { LCMDContextTaskDetailsResultDependencyDetails, LCMDContextUnitType } from "../app/LCMDContextTypes";
import { ProcessGetter } from "../model/api/processGetter";
import { ParticleGetter } from "../model/api/particleGetter";
import { ComplexProp } from "../model/api/complexProp";
import { DependencyList } from "../model/DataModel";

function getNumberOfWorkDaysBetweenProcesses(
    type: number,
    processGetter: ProcessGetter,
    dependentGetter: ProcessGetter,
    duration: number,
    unit: number,
    pre: boolean = false,
) {
    const core = processGetter.core;
    assert("development" !== process.env.NODE_ENV || dependentGetter.core === core);

    // if we go backwards (pre=true) the first process is the dependent process
    const firstProcessGetter = pre ? dependentGetter : processGetter;
    const secondProcessGetter = pre ? processGetter : dependentGetter;

    let process1Value: string;
    let process2Value: string;
    switch (type) {
        case 1: // EE
            process1Value = "_x2";
            process2Value = "_x2";
            break;
        case 2: // EA
            process1Value = "_x2";
            process2Value = "_x1";
            break;
        case 3: // AE
            process1Value = "_x1";
            process2Value = "_x2";
            break;
        case 4: // AA
            process1Value = "_x1";
            process2Value = "_x1";
            break;
        default:
            throw new Error(`Unknown dependency type '${type}'`);
    }

    const process1Milliseconds = core.CalcEpochStartDayMS(firstProcessGetter.aux(process1Value), duration, unit);
    const process2Milliseconds = secondProcessGetter.aux<number>(process2Value);

    if (null === process1Milliseconds || null === process2Milliseconds) {
        return 0;
    }

    if (process1Milliseconds <= process2Milliseconds) {
        return core.CalcWorkDays(process1Milliseconds, process2Milliseconds);
    } else {
        return -core.CalcWorkDays(process2Milliseconds, process1Milliseconds);
    }
}

function getConflictingPredecessors(
    pGt: ProcessGetter,
    conflictingTasks: { [taskId: number]: number },
    preGt: ProcessGetter,
    _Gt: ParticleGetter,
) {
    pGt.setAux("_dc", undefined);
    pGt.setAux("_df", undefined);
    const pres = Object.getOwnPropertyNames(pGt.aux<DependencyList>("_rp") || {});
    const _pres = pres.reduce(
        (_, tid) => {
            preGt.reset(Number.parseInt(tid));
            return Math.max(_, preGt.aux("_", preGt.aux("__")) as number);
        },
        pGt.aux("_", pGt.aux("__")) as number,
    );

    if (pGt.isDeleted()) {
        return;
    }

    pGt.setAux("_dt", _pres);

    // check predecessors
    let delta_pre = null;
    for (let i_pre = 0; i_pre < pres.length; i_pre++) {
        const preId = Number.parseInt(pres[i_pre]);
        preGt.reset(preId);
        if (preGt.value("p", -1) >= 0 && !preGt.isDeleted()) {
            _Gt.reset(pGt.aux<DependencyList>("_rp")[pres[i_pre]].depTs);
            const delta = getNumberOfWorkDaysBetweenProcesses(_Gt.type, preGt, pGt, _Gt.value(), _Gt.unit(3 /*days*/));
            if (null == delta_pre || delta < delta_pre) {
                delta_pre = delta;
            }
            if (delta < 0) {
                // conflict
                const _pre = pGt.aux<DependencyList>("_rp")[preId].depTs;
                let _trigger_pre = preGt._("start", -1);
                let _trigger_t = pGt._("start", -1);
                switch (_Gt.type) {
                    case 1:
                        {
                            // EE
                            _trigger_pre = Math.max(_trigger_pre, preGt._("days", -1));
                            _trigger_t = Math.max(_trigger_t, pGt._("days", -1));
                        }
                        break;
                    case 2:
                        {
                            // EA
                            _trigger_pre = Math.max(_trigger_pre, preGt._("days", -1));
                        }
                        break;
                    case 3:
                        {
                            // AE
                            _trigger_t = Math.max(_trigger_t, pGt._("days", -1));
                        }
                        break;
                    case 4:
                        {
                            // AA
                        }
                        break;
                    default:
                        assert(false); //handle me!
                        break;
                }

                const taskId = pGt.id;
                conflictingTasks[preId] = Math.max(conflictingTasks[preId] || 0, 0);
                conflictingTasks[taskId] = Math.max(conflictingTasks[taskId] || 0, -delta);
            }
        }
    }

    pGt.setAux("_dd", delta_pre);
}

function _dfsVisit(this: { pGt: ProcessGetter; stack: number[] }, taskId: number) {
    const pGt = this.pGt;
    pGt.reset(taskId);
    assert(undefined === pGt.aux("_df"));
    pGt.setAux("_df", null);
    const succ = Object.getOwnPropertyNames(pGt.aux<DependencyList>("_rs", {}));
    for (let i_succ = 0; i_succ < succ.length; i_succ++) {
        const succId = Number.parseInt(succ[i_succ]);
        pGt.reset(succId);
        if (pGt.value("p", -1) >= 0) {
            if (undefined === pGt.aux("_df")) {
                _dfsVisit.call(this, succId);
            } else {
                console.log("Circle: " + taskId + " ---> " + succId);
            }
        }
    }
    pGt.reset(taskId);
    pGt.setAux("_df", 0);
    this.stack.push(taskId);
}

export function checkCircular(core, stack: Set<string>, taskId: number, {srcPid, dstPid}: {srcPid: number, dstPid: number}): boolean {
    let isCircular = false;
    const pGt = new ProcessGetter(core, taskId);
    const succ = Object.getOwnPropertyNames(pGt.aux<DependencyList>("_rs", {}));
    if(taskId === srcPid && !succ.includes(dstPid.toString())){
        succ.push(`${dstPid}`);
    }
    for (let i_succ = 0; i_succ < succ.length; i_succ++) {
        const succId = Number.parseInt(succ[i_succ]);
        pGt.reset(succId);
        if (pGt.value("p", -1) >= 0) {
            if (!stack.has(`${taskId}:${succId}`)) {
                stack.add(`${taskId}:${succId}`);
                isCircular = checkCircular(core, stack, succId, {srcPid, dstPid});
                if(isCircular){
                    return true;
                }
                stack.delete(`${taskId}:${succId}`);
            } else {
                return true;
            }
        }
    }
    return isCircular;
}

let _lastDepCheckTS = -1;
export function checkDependencies(core: Core) {
    const ts = core.getUncommitedTS();
    if (_lastDepCheckTS >= ts) {
        return;
    }

    const pGt = new ProcessGetter(core);
    const preGt = new ProcessGetter(core);
    const sucGt = new ProcessGetter(core);
    const _gt = new ParticleGetter(core);
    _lastDepCheckTS = ts;
    const time0 = Date.now();
    const conflictingTasks: { [taskId: number]: number } = {};
    const taskIds = core.getAllProcessesIds();
    const n = taskIds.length;
    for (let i = 0; i < n; i++) {
        pGt.reset(taskIds[i]);
        getConflictingPredecessors(pGt, conflictingTasks, preGt, _gt);
    }
    const stack = [];
    const redA = Object.getOwnPropertyNames(conflictingTasks).map((_taskId) => Number.parseInt(_taskId));
    const n_redA = redA.length;
    for (let i_redA = 0; i_redA < n_redA; i_redA++) {
        const taskId = redA[i_redA];
        pGt.reset(taskId);
        assert(pGt.id >= 0 && undefined === pGt.aux("_dc") && !pGt.isDeleted());
        //dc property tells if there is conflict
        pGt.setAux("_dc", conflictingTasks[taskId]);
        assert("number" === typeof pGt.aux("_dc") && pGt.aux("_dc") >= 0);
        if (undefined === pGt.aux("_df")) {
            _dfsVisit.call(
                {
                    stack,
                    pGt,
                },
                taskId,
            );
        }
    }
    const n_stack = stack.length;
    for (let i_stack = n_stack; i_stack > 0; ) {
        i_stack--;
        const taskId = stack[i_stack];
        pGt.reset(taskId);
        if (!pGt.isDeleted()) {
            const t_dc = Math.abs(pGt.aux("_dc") || 0);
            const _rs = pGt.aux<DependencyList>("_rs") || {};
            const succ = Object.getOwnPropertyNames(_rs);
            for (let i_succ = 0; i_succ < succ.length; i_succ++) {
                const succId = Number.parseInt(succ[i_succ]);
                sucGt.reset(succId);
                if (!sucGt.isDeleted()) {
                    if (sucGt.value("p", -1) >= 0) {
                        _gt.reset(_rs[succId].depTs);
                        const _delta = -getNumberOfWorkDaysBetweenProcesses(
                            _gt.type,
                            pGt,
                            sucGt,
                            _gt.value(),
                            _gt.unit(3 /*days*/),
                        );
                        const suc_dc0: number = sucGt.aux("_dc");
                        let suc_dc = suc_dc0;
                        if (undefined === suc_dc) {
                            const delta = t_dc + _delta;
                            if (delta > 0) {
                                sucGt.setAux("_dc", -delta); // <0 yellow
                            }
                        } else if (suc_dc < 0) {
                            // succ yellow
                            const delta = t_dc + _delta;
                            if (delta > 0) {
                                sucGt.setAux("_dc", -Math.max(Math.abs(suc_dc || 0), delta)); // <0 yellow
                            }
                        } else {
                            // suc is "red" too
                            assert("number" == typeof suc_dc && suc_dc >= 0);
                            const delta = t_dc + _delta;
                            if (delta > 0) {
                                sucGt.setAux("_dc", Math.max(suc_dc, delta)); // >=0 red
                            }
                        }
                    }
                }
            }
        }
    }
    const time1 = Date.now();
    console.log("checkDedendencies=" + (time1 - time0) + "ms");
}

export function _getTaskHistory(pGt: ProcessGetter) {
    const core = pGt.core;
    const _Gt = new ParticleGetter(core);
    const taskId = pGt.id;
    let th = null;
    if (taskId >= 0) {
        const ops = [];
        th = {
            taskId: taskId,
            _: 0,
            events: [],
        };

        ops.push(...pGt.history("name")); // gather name changes
        ops.push(...pGt.history("start")); // gather start changes
        ops.push(...pGt.history("days")); // gather duration changes
        const pIt = pGt.getAllProps();
        let p = new ComplexProp();
        for (pIt.first(p); pIt.next(p); ) {
            if (p.isCOMMENTID()) {
                ops.push(...pGt.history(p)); // gather comments
            }
        }
        ops.sort((a, b) => a - b);
        // iterate ops and generate events
        const n = ops.length;
        const import_ts = core.getImportedTS();
        for (let i = 0; i < n; i++) {
            _Gt.reset(ops[i]);
            if (_Gt._ >= import_ts) {
                let e = undefined;
                _Gt.getProp(p);
                let prop;
                if ((prop = p.isNAME())) {
                    switch (prop) {
                        case "name":
                            e = {
                                _: ops[i],
                                type: 0,
                                name: prop,
                                u: _Gt.userId,
                                c: "changed (" + _Gt.value() + ")",
                            };
                            break;
                        case "start":
                        case "days":
                            e = {
                                _: ops[i],
                                type: 0,
                                name: "date",
                                u: _Gt.userId,
                                date: {},
                            };
                            e.date[prop] = ops[i];
                            break;
                        default:
                            assert(false); // handle me...
                            break;
                    }
                } else if ((prop = p.isCOMMENTID())) {
                    e = {
                        _: ops[i],
                        type: 1,
                        name: ["Comment #", prop.commentId].join(""),
                        u: _Gt.userId,
                        c: _Gt.value(),
                    };
                } else {
                    assert(false); // handle me...
                }
                if (e) {
                    if (
                        th.events.length > 0 &&
                        e.name === th.events[th.events.length - 1].name &&
                        e.u === th.events[th.events.length - 1].u
                    ) {
                        const _e = th.events[th.events.length - 1];
                        if (_e.date) {
                            e.date = Object.assign({}, _e.date, e.date);
                        }
                        th.events[th.events.length - 1] = e;
                    } else {
                        th.events.push(e);
                    }
                }
            }
        }
        for (let i = 0; i < th.events.length; i++) {
            const e = th.events[i];
            if (e.date) {
                const helper = [];
                const changedProperties: {
                    action: "startDate" | "duration";
                    value: string | { value: string; unit: LCMDContextUnitType };
                }[] = [];
                if (_Gt.reset(e.date.start)._ >= 0) {
                    helper.push("Start:" + EpochMSToLocaleDateStr(_Gt.value()));
                    changedProperties.push({
                        action: "startDate",
                        value: new Date(EpochMSToLocaleDateStr(_Gt.value())).toLocaleDateString(),
                    });
                }
                if (_Gt.reset(e.date.days)._ >= 0) {
                    helper.push(
                        "Duration:" +
                            HelperDaysUnitToDurationStr({
                                value: _Gt.value(),
                                unit: _Gt.unit(),
                            }),
                    );

                    changedProperties.push({
                        action: "duration",
                        value: {
                            value: _Gt.value(),
                            unit: _Gt.unit(LCMDContextUnitType.DAYS),
                        },
                    });
                }
                e.c = "changed (" + helper.join(",") + ")";
                e.changedProperties = changedProperties;
                delete e.date;
            }
        }
    }
    return th;
}

type DependencyChain = {
    pGt: ProcessGetter;
    _pGt: ProcessGetter;
    _Gt: ParticleGetter;
    pre: boolean;
    rel: {
        [id: string]: {
            tid: number;
            level: number;
            dep: number;
            dd: number;
            hasConflict: boolean;
        };
    };
    level: number;
    count: number;
};

function _getDependencyChain(ret: DependencyChain, tid: number, level: number, dd: number) {
    const processGetter = ret.pGt.reset(tid);
    const dependentProcesses = ret.pre
        ? processGetter.aux<DependencyList>("_rp")
        : processGetter.aux<DependencyList>("_rs");
    const dependentKeys = Object.getOwnPropertyNames(dependentProcesses || {});
    let minNumberOfWorkdaysBetweenProcesses: number | null = null;
    for (let i = 0; dependentKeys.length > i; i++) {
        const dependentKey = dependentKeys[i];
        const dependentId = Number.parseInt(dependentKey);
        if (dependentId in ret.rel) {
            continue;
        }

        const dependentGetter = ret._pGt.reset(dependentId);
        const _Gt = ret._Gt.reset(dependentProcesses[dependentId].depTs);
        const workdaysBetweenProcesses = getNumberOfWorkDaysBetweenProcesses(
            _Gt.type,
            processGetter,
            dependentGetter,
            _Gt.value(),
            _Gt.unit(),
            ret.pre,
        );
        minNumberOfWorkdaysBetweenProcesses =
            null === minNumberOfWorkdaysBetweenProcesses
                ? workdaysBetweenProcesses
                : Math.min(minNumberOfWorkdaysBetweenProcesses, workdaysBetweenProcesses);
        const hasConflict = minNumberOfWorkdaysBetweenProcesses < 0;

        ret.rel[dependentId] = {
            tid: dependentId,
            level: level,
            dep: minNumberOfWorkdaysBetweenProcesses,
            dd: dd,
            hasConflict,
        };
        ret.level = Math.max(ret.level, level);
        ret.count++;

        if (hasConflict) {
            // set conflict for current process also
            ret.rel[tid].hasConflict = true;
        }

        _getDependencyChain(ret, dependentId, level + 1, dd);
    }

    if (minNumberOfWorkdaysBetweenProcesses > 0) {
        dd -= Math.min(dd, minNumberOfWorkdaysBetweenProcesses);
    } else if (minNumberOfWorkdaysBetweenProcesses < 0) {
        dd += -minNumberOfWorkdaysBetweenProcesses;
    }

    return dd;
}

// Gets a complete list of dep chains
// should avoid the call stack issue with T3 but could be slow on a fully connected plan!
export function getCompleteDependencyChain(processGetter: ProcessGetter, chain?: Set<number>): Set<number> {
    const deps: string[] = Object.keys({ ...processGetter.aux("_rs"), ...processGetter.aux("_rp") });
    if (!chain) {
        chain = new Set<number>();
    }
    if (deps.length > 0) {
        const intDeps = deps.map((k) => parseInt(k));

        for (let i = 0; intDeps.length > i; i++) {
            if (chain.has(intDeps[i]) || new ProcessGetter(processGetter.core, intDeps[i]).isDeleted()) {
                continue;
            }
            chain.add(intDeps[i]);
            chain = getCompleteDependencyChain(processGetter.reset(intDeps[i]), chain);
        }
    }
    return chain;
}

export function getDependencyChain(pGt: ProcessGetter, deltaDays?: number, pre?: boolean): DependencyChain {
    const ret = {
        pGt,
        _pGt: new ProcessGetter(pGt.core),
        _Gt: new ParticleGetter(pGt.core),
        pre: pre || false,
        rel: {
            [pGt.id]: {
                tid: pGt.id,
                level: 0,
                dep: 0,
                dd: 0,
                hasConflict: false,
            },
        },
        level: 0,
        count: 0,
    };
    _getDependencyChain(ret, pGt.id, 1, deltaDays || 0);

    return ret;
}

export function _calcConflictChain(pGt: ProcessGetter) {
    const processGetter = new ProcessGetter(pGt.core, pGt.id);
    const dependencyChain = getDependencyChain(processGetter);

    const chain = Object.values(dependencyChain.rel).map((rel) => {
        processGetter.reset(rel.tid);
        const _t_dc: number = processGetter.aux("_dc");
        return {
            tid: processGetter.id,
            level: rel.level,
            delta: Math.abs(_t_dc || 0),
            color: 0 === _t_dc || _t_dc > 0 ? 1 : _t_dc < 0 ? 2 : 0,
            name: processGetter.value<string>("name", processGetter.aux("_name", "")),
            start: processGetter.aux<number>("_x1"),
            end: processGetter.aux<number>("_x2"),
        };
    });

    chain.sort((a, b) => a.level - b.level);

    return chain;
}

export function fetchProcessesChanges(
    core: Core,
    opt?: {
        withConflicts?: boolean;
    },
) {
    const pGt = new ProcessGetter(core);
    if (opt?.withConflicts) {
        checkDependencies(core);
    }
    const ret = [];
    const tasks = core.getAllProcessesIds();
    const n = tasks.length;
    const import_ts = core.getImportedTS();
    for (let i = 0; i < n; i++) {
        const tid = tasks[i];
        pGt.reset(tid);
        const t_ = pGt.aux("_", pGt.aux("__"));
        const dc = pGt.aux("_dc");
        const isConflict = opt?.withConflicts && (0 === dc || dc > 0);
        if (t_ >= import_ts || isConflict) {
            // potential changes
            const h = _getTaskHistory(pGt);
            if ((Array.isArray(h?.events) && h.events.length > 0) || isConflict) {
                const _ret: any = {
                    _: t_,
                    tid: tid,
                    name: pGt.value("name", ""),
                    events: h.events,
                };
                if (isConflict) {
                    const conflict = _calcConflictChain(pGt);
                    _ret.conflict = conflict;
                }
                ret.push(_ret);
            }
        }
    }
    return ret;
}

function _calcEarliestStart(
    s: number,
    type: number,
    pGt1: ProcessGetter,
    pGt2: ProcessGetter,
    duration: number,
    unit: number,
) {
    const core = pGt1.core;
    assert("development" !== process.env.NODE_ENV || pGt2.core === core);
    let ret: number | undefined = undefined;
    switch (type) {
        case 1:
            {
                // EE
                ret = core.CalcEpochStartDayMS(s, pGt1.value<number>("days", 0), pGt1.unit("days", 3 /* days */));
                ret = core.CalcEpochStartDayMS(ret, duration, unit);
                ret = core.CalcEpochStartDayMS(ret, -pGt2.value<number>("days", 0), pGt2.unit("days", 3 /* days */));
            }
            break;
        case 2:
            {
                // EA
                ret = core.CalcEpochStartDayMS(s, pGt1.value<number>("days", 0), pGt1.unit("days", 3 /* days */));
                ret = core.CalcEpochStartDayMS(ret, duration, unit);
            }
            break;
        case 3:
            {
                // AE
                ret = core.CalcEpochStartDayMS(s, duration, unit);
                ret = core.CalcEpochStartDayMS(ret, -pGt2.value<number>("days", 0), pGt2.unit("days", 3 /* days */));
            }
            break;
        case 4:
            {
                // AA
                ret = core.CalcEpochStartDayMS(s, duration, unit);
            }
            break;
        default:
            assert(false); //handle me!
            break;
    }
    assert(undefined !== ret);
    return ret;
}

function _calcLatestStart(
    s: number,
    type: number,
    pGt1: ProcessGetter,
    pGt2: ProcessGetter,
    duration: number,
    unit: number,
) {
    const core = pGt1.core;
    assert("development" !== process.env.NODE_ENV || pGt2.core === core);
    let ret: number = undefined;
    switch (type) {
        case 1:
            {
                // EE
                //@TODO
            }
            break;
        case 2:
            {
                // EA
                ret = core.CalcEpochStartDayMS(s, -duration, unit);
                ret = core.CalcEpochStartDayMS(ret, -pGt2.value<number>("days", 0), pGt2.unit("days", 3 /* days */));
            }
            break;
        case 3:
            {
                // AE
                //@TODO
            }
            break;
        case 4:
            {
                // AA
                //@TODO
            }
            break;
        default:
            assert(false); //handle me!
            break;
    }
    assert(undefined !== ret);
    return ret;
}

let _lastCPCheckTS = -1;
export function calcCriticalPath(core: Core) {
    const ts = core.getUncommitedTS();
    if (_lastCPCheckTS < ts) {
        const pGt = new ProcessGetter(core);
        const pGtHelper = new ProcessGetter(core);
        const _Gt = new ParticleGetter(core);
        const tasks = core.getAllProcessesIds();
        const n = tasks.length;
        const Q = [];
        const W = [];
        let cps: number = undefined;
        let cpe: number = undefined;
        for (let i = 0; i < n; i++) {
            const tid = tasks[i];
            pGt.reset(tid);
            let _cps = undefined;
            if (!pGt.isDeleted() && !Array.isArray(pGt.aux("_c"))) {
                // only consider real processes?
                const x1 = pGt.aux<number>("_x1", 0);
                const _rp = pGt.aux<DependencyList>("_rp") || {};
                const rp = Object.getOwnPropertyNames(_rp);
                const _rs = pGt.aux<DependencyList>("_rs") || {};
                const rs = Object.getOwnPropertyNames(_rs);
                if (0 === rp.length) {
                    if (rs.length > 0) {
                        Q.push(tid);
                        _cps = x1;
                        assert(_cps);
                    } else {
                        const x2 = pGt.aux<number>("_x2", 0);
                        assert(0 === rs.length);
                        if (undefined === cpe || x2 > cpe) {
                            cpe = x2;
                        }
                        _cps = x1;
                        assert(_cps);
                        W.push(tid);
                    }
                }
                if (undefined === cps || cps > x1) {
                    cps = x1;
                }
            }
            pGt.setAux("_cps", _cps);
            pGt.setAux("_cpl", undefined);
            pGt.setAux("_cpp", undefined);
        }
        while (Q.length > 0) {
            pGt.reset(Q.pop());
            const _cps = pGt.aux<number>("_cps");
            assert(_cps);
            const _rs = pGt.aux<DependencyList>("_rs") || {};
            const rs = Object.getOwnPropertyNames(_rs);
            const n_rs = rs.length;
            assert(n_rs > 0);
            for (let i_rs = 0; i_rs < n_rs; i_rs++) {
                pGtHelper.reset(Number.parseInt(rs[i_rs], 10));
                assert(pGtHelper.id >= 0);
                if (!pGtHelper.isDeleted()) {
                    let __cps = pGtHelper.aux<number>("_cps", undefined);
                    _Gt.reset(_rs[rs[i_rs]].depTs);
                    const s_cps = _calcEarliestStart(_cps, _Gt.type, pGt, pGtHelper, _Gt.value(), _Gt.unit(3 /*days*/));
                    if (undefined === __cps || __cps < s_cps) {
                        pGtHelper.setAux("_cps", s_cps);
                        pGtHelper.setAux("_cpp", pGt.id);
                        __cps = s_cps;
                    }
                    const __rs = Object.getOwnPropertyNames(pGtHelper.aux<DependencyList>("_rs") || {});
                    if (0 === __rs.length) {
                        const __cpe = core.CalcEpochStartDayMS(
                            __cps,
                            pGtHelper.value<number>("days", 0),
                            pGtHelper.unit("days", 3 /* days */),
                        );
                        if (undefined === cpe || __cpe > cpe) {
                            cpe = __cpe;
                        }
                        if (W.indexOf(pGtHelper.id) < 0) {
                            W.push(pGtHelper.id);
                        }
                    } else {
                        if (Q.indexOf(pGtHelper.id) < 0) {
                            Q.push(pGtHelper.id);
                        }
                    }
                }
            }
        }
        {
            const n_W = W.length;
            for (let i_w = 0; i_w < n_W; i_w++) {
                pGt.reset(W[i_w]);
                const __cpl = core.CalcEpochStartDayMS(
                    cpe,
                    -pGtHelper.value<number>("days", 0),
                    pGtHelper.unit("days", 3 /* days */),
                );
                pGt.setAux("_cpl", __cpl);
            }
        }
        while (W.length > 0) {
            pGt.reset(W.pop());
            const _cpl = <number>pGt.aux("_cpl");
            assert(_cpl);
            const _rp = pGt.aux<DependencyList>("_rp") || {};
            const rp = Object.getOwnPropertyNames(_rp);
            const n_rp = rp.length;
            assert(n_rp >= 0);
            for (let i_rp = 0; i_rp < n_rp; i_rp++) {
                pGtHelper.reset(Number.parseInt(rp[i_rp], 10));
                assert(pGtHelper.id >= 0);
                if (!pGtHelper.isDeleted()) {
                    const __cpl = pGtHelper.aux<number>("_cpl", undefined);
                    _Gt.reset(_rp[rp[i_rp]].depTs);
                    const s_cpl = _calcLatestStart(_cpl, _Gt.type, pGt, pGtHelper, _Gt.value(), _Gt.unit(3 /*days*/));
                    if (undefined === __cpl || s_cpl < __cpl) {
                        pGtHelper.setAux("_cpl", s_cpl);
                    }
                    if (W.indexOf(pGtHelper.id) < 0) {
                        W.push(pGtHelper.id);
                    }
                }
            }
        }
        pGt.reset(0);
        pGt.setAux("_cps", cps);
        pGt.setAux("_cpe", cpe);
        _lastCPCheckTS = ts;
    }
}

export function getDirectDependencies(core: Core, processId: number, dependency: "predecessor" | "successor") {
    const dependencyKeyMap = new Map<"predecessor" | "successor", "_rp" | "_rs">([
        ["predecessor", "_rp"],
        ["successor", "_rs"],
    ]);
    const processGetter = new ProcessGetter(core, processId);
    const dependencyProcessGetter = new ProcessGetter(core);
    const dependencyParticle = new ParticleGetter(core);

    const dependencyList = processGetter.aux<DependencyList>(dependencyKeyMap.get(dependency));
    const dependecies = Object.getOwnPropertyNames(dependencyList || {}).reduce(
        (ret, _target) => {
            const target = Number.parseInt(_target);
            dependencyProcessGetter.reset(Number.parseInt(_target));
            if (!dependencyProcessGetter.isDeleted()) {
                dependencyParticle.reset(dependencyList[_target].depTs);
                const lag = dependencyParticle.value(0);
                const type = dependencyParticle.type || null;
                const unit = dependencyParticle.unit(3 /* days */);
                const dep = getNumberOfWorkDaysBetweenProcesses(
                    type,
                    processGetter,
                    dependencyProcessGetter,
                    lag,
                    unit,
                    dependency === "predecessor",
                );
                ret.push({
                    targetId: target,
                    targetName: dependencyProcessGetter.value<string>("name"),
                    lag,
                    type,
                    unit,
                    valid: dep >= 0,
                    conflictOffset: dep < 0 ? Math.abs(dep) : undefined,
                });
            }
            return ret;
        },
        [] as Omit<LCMDContextTaskDetailsResultDependencyDetails, "pid">[],
    );

    return dependecies;
}
