import * as React from "react";
import {useEffect, useState} from "react";
import { useLCMD } from "../../../app/LCMContext";
import { useLoadingStatus } from "../../hooks/use-loading-status";

// TODO: check types before merge to master
export type FilterItem = {
    _hid: string;
    name: string;
};

export type SelectedFilterItem = {
    hid: string;
};

export type Data = {
    filterCollection?: FilterItem[];
    filter?: { trade: { [key: string]: unknown }; saveAs?: SelectedFilterItem };
    trades?: unknown[];
};

export function useFilterData() {
    const [data, setData] = React.useState<Data>({});
    const [refreshToken, setRefreshToken] = useState<symbol>();
    const { status, observe } = useLoadingStatus();
    function refresh(){
        setRefreshToken(Symbol());
    }

    const LCMD = useLCMD();

    useEffect(() => {
        return LCMD.loadFilterData((data) => {
            observe(Promise.resolve(data)).then(setData);
        });
    }, [refreshToken]);

    return { data, status, refresh };
}
