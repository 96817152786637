import { createLucideIcon } from "lucide-react";

const Adopt = createLucideIcon("Adapt", [
    [
        "path",
        {
            d: "M8.49992 3.00036C8.49989 3.55265 8.05215 4.00034 7.49987 4.00031L1.99994 4C1.44766 3.99997 0.999969 3.55223 1 2.99994C1.00003 2.44766 1.44777 1.99997 2.00006 2L7.49998 2.00031C8.05226 2.00034 8.49995 2.44808 8.49992 3.00036ZM19.1019 16.0001C19.1019 16.5524 18.6542 17.0001 18.1019 17.0001L14.3986 16.9999C13.8463 16.9998 13.3986 16.5521 13.3987 15.9998C13.3987 15.4475 13.8464 14.9998 14.3987 14.9999L18.102 15.0001C18.6543 15.0001 19.102 15.4478 19.1019 16.0001ZM12.8965 3.9C14.0575 3.90006 14.9987 4.84125 14.9987 6.00224C14.9987 7.43701 13.8356 8.60013 12.4008 8.60014L7.04196 8.6002C4.58863 8.60022 2.59983 10.589 2.59983 13.0424C2.59983 15.1729 4.32699 16.9001 6.45755 16.9001H8.37939L7.67952 17.8304C7.38069 18.2276 7.46044 18.7918 7.85765 19.0906C8.25485 19.3895 8.81909 19.3097 9.11791 18.9125L10.7192 16.7841C10.8671 16.5875 10.9222 16.35 10.8921 16.124C10.8977 16.0835 10.9005 16.0421 10.9005 16.0001C10.9005 15.788 10.8272 15.5931 10.7045 15.4393L9.11791 13.3304C8.81909 12.9332 8.25485 12.8534 7.85765 13.1522C7.46044 13.4511 7.38069 14.0153 7.67952 14.4125L8.1968 15.1001H6.45755C5.3211 15.1001 4.39983 14.1788 4.39983 13.0424C4.39983 11.5832 5.58276 10.4002 7.04198 10.4002L12.4008 10.4001C14.8297 10.4001 16.7987 8.43112 16.7987 6.00224C16.7987 3.84717 15.0517 2.10011 12.8966 2.1L11.5005 2.09993C11.0035 2.0999 10.6005 2.50282 10.6005 2.99988C10.6005 3.49694 11.0034 3.8999 11.5004 3.89993L12.8965 3.9Z",
            key: "p1my2r",
            strokeWidth: "0",
            fill: "currentColor",
        },
    ],
]);

export default Adopt;
