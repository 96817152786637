import { ChangeEvent } from "react";
import { IColumn } from "@fluentui/react";
import { FilterData } from "../Modal/ModalToDoFilter/filter.interface";
import { LCMDContextTaskDetailsResultTradeDetails } from "../../app/LCMDContextTypes";

export enum ItemState {
    OPEN = "open",
    DONE = "done",
}

export enum ItemType {
    ACTION_ITEM = "action",
    STABILITY_CRITERIA = "stability",
}

export enum ItemPriority {
    HIGHER = 0,
    HIGH = 1,
    MEDIUM = 2,
    LOW = 3,
}

export type StoreActionItem = {
    action: {
        value: string;
    };
    issue: {
        value: string;
    };
    status: {
        value: ItemState;
    };
    trade: {
        value: LCMDContextTaskDetailsResultTradeDetails[];
    };
    type: {
        value: ItemType;
    };
    priority: {
        value: ItemPriority;
    };
    deadline: {
        value: number;
    };
    raisedBy: {
        value: string[];
    };
    responsible: {
        value: string[];
    };
    actionBy: {
        value: string;
    };
    identified: {
        value: number;
    };
    needed: {
        value: number;
    };
    resolved: {
        value: number;
    };
};

export type ToDoView = {
    id: number[];
    status: ItemState[];
    issue: string[];
    action: string[];
    process: string[];
    trade: Array<LCMDContextTaskDetailsResultTradeDetails[]>;
    type: ItemType[];
    priority: ItemPriority[];
    deadline: number[];
    raisedBy: Array<Chip[]>;
    responsible: Array<Chip[]>;
    actionBy: string[];
    area: string[];
    identified: number[];
    needed: number[];
    resolved: number[];
};

export type ToDoProps = {
    id: number;
    status: ItemState;
    issue: string;
    action: string;
    actionId?: number;
    process: { pid: number; name: string; areaPath: { id: number; name: string }[] };
    trade: LCMDContextTaskDetailsResultTradeDetails[];
    area?: string;
    raisedBy: string[];
    responsible: string[];
    actionBy: string;
    type: ItemType;
    priority: ItemPriority;
    deadline: number;
    identified: number;
    needed: number;
    resolved: number;
    stability?: string;
    leadDays?: number;
};

export interface HTMLElementEvent<T extends HTMLElement, U extends HTMLElement> extends ChangeEvent {
    target: T;
    currentTarget: U;
}

export type FilterState = {
    searchText: string;
    filterColumns: Array<keyof ToDoProps>;
    filterData: FilterData | any;
    flattFilterData: any;
    sortByColumn: IColumn;
};

export type Chip = {
    email: string;
    key: string | number;
    name: string;
    fullName?: string;
};
