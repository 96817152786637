import { intl } from "../../legacy/GlobalHelperReact";
import { Button } from "../ui/button";
import { Card, CardContent } from "../ui/card";
import { cn } from "../../lib/utils";
import { Check } from "lucide-react";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";
import { LcmdModal2 } from "../common/LcmModal2";
import * as React from "react";
import { useEffect, useReducer } from "react";
import { useLCMD } from "@/app/LCMContext";
import { useCurrentBaseline } from "@/components/hooks/useBaseline.hook";

export function MPPExportDialog() {
    const LCMD = useLCMD();
    const { isLoading, data: currentBaseline } = useCurrentBaseline();
    const [mppExportState, setMppExportState] = useReducer(
        (current, update) => ({
            ...current,
            ...update,
        }),
        {
            auto: true,
            excludeProcessesNotInBaseline: false,
        },
    );

    useEffect(() => {
        if (!currentBaseline) {
            return;
        }

        LCMD.setBaselineId(currentBaseline.activebaseline);
    }, [currentBaseline]);

    if (isLoading) {
        return null;
    }

    return (
        <LcmdModal2
            size="l"
            open
            titleTop
            header={{ title: intl.get("canvas.cmd.mpp_xml_export.text") }}
            onOpenChange={() => LCMD.showDialog("dialog.export.mpp", false)}
            buttons={[
                <Button key="cancel" variant="outline" onClick={() => LCMD.showDialog("dialog.export.mpp", false)}>
                    {intl.get("fw.cancel")}
                </Button>,
                <Button
                    key="export"
                    onClick={() => {
                        LCMD.worker.postMessage([
                            "export",
                            {
                                target: "mpp.xml",
                                opt: mppExportState,
                            },
                        ]);
                    }}
                >
                    {intl.get("overview.cmd.export.text")}
                </Button>,
            ]}
        >
            <div className="flex gap-3">
                <Card
                    className={cn(mppExportState?.auto && "bg-slate-100", "w-full hover:cursor-pointer")}
                    onClick={() => {
                        setMppExportState({
                            auto: true,
                        });
                    }}
                >
                    <CardContent className="p-4">
                        <div className={"flex items-center justify-between gap-4 text-lg font-medium"}>
                            {intl.get("canvas.cmd.mpp_xml_auto.text")}
                            {mppExportState?.auto && <Check size={18} />}
                        </div>
                    </CardContent>
                </Card>
                <Card
                    className={cn(!mppExportState?.auto && "bg-slate-100", "w-full hover:cursor-pointer")}
                    onClick={() => {
                        setMppExportState({
                            auto: false,
                        });
                    }}
                >
                    <CardContent className={"p-4"}>
                        <div className={"flex items-center justify-between gap-4 text-lg font-medium"}>
                            {intl.get("canvas.cmd.mpp_xml.text")}
                            {!mppExportState?.auto && <Check size={18} />}
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div className="flex h-full items-center gap-2">
                <Checkbox
                    onCheckedChange={() => {
                        setMppExportState({
                            excludeProcessesNotInBaseline: !mppExportState?.excludeProcessesNotInBaseline,
                        });
                    }}
                />
                <Label>{intl.get("canvas.cmd.ignore_processes.text")}</Label>
            </div>
        </LcmdModal2>
    );
}
