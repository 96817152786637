import * as React from "react";
import { FilterRowTop } from "./FilterRowTop";
import { filterRowStyles } from "./filterRowStyles";

type AreasFilterRow = {
    label: string;
    badgeCount: number;
    data: any[];
    onModifyButtonClick: () => void;
};

export function AreasFilterRow({ label, badgeCount, onModifyButtonClick, data }: AreasFilterRow) {
    return (
        <div className={filterRowStyles.body}>
            <FilterRowTop onModifyButtonClick={onModifyButtonClick} label={label} badgeCount={badgeCount} />
            <div>
                {data.map((area) => {
                    return (
                        <div className={filterRowStyles.button} key={area.tid}>
                            <span>{area.n}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
