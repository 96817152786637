import { useLCMD } from "../../../app/LCMContext";
import { useEffect, useState } from "react";
import { FrameworkError, intl } from "lcmd2framework";
import { mergeStyleSets, TextField } from "@fluentui/react";
import * as React from "react";

const classNames = mergeStyleSets({
    main: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flexShrink: 1,
        paddingLeft: 20,
        paddingRight: 20,
    },
});

export function ProfileSettings() {
    const LCMD = useLCMD();
    const [meta, setMeta] = useState<any>(null);
    const [firstName, setFirstName] = useState(null as string);
    const [lastName, setLastName] = useState(null as string);
    const [email, setEmail] = useState(null as string);

    function saveProfileInformation() {
        const change = (meta?.firstName || "") !== (firstName || "") || (meta?.lastName || "") !== (lastName || "");
        if (!change) {
            return;
        }

        const newMeta = {
            firstName: (meta?.firstName || "") !== (firstName || "") ? firstName : undefined,
            lastName: (meta?.lastName || "") !== (lastName || "") ? lastName : undefined,
        };

        LCMD.updateUserMeta(newMeta, (error: FrameworkError, result: { meta: { [name: string]: any } }) => {
            if (error) {
                //@TODO show error
                console.log(error);
            }
        });
    }

    useEffect(() => {
        LCMD.getAuthUser(
            (error, user) => {
                if (!error) {
                    console.log("user: ", user);
                    const meta = user?.meta || {};
                    setMeta(meta);
                    setFirstName(meta?.firstName);
                    setLastName(meta?.lastName);
                    setEmail(user?.email);
                }
            },
            { forceSync: true },
        );
    }, [setMeta, setFirstName, setLastName]);

    return meta ? (
        <div className={classNames.main}>
            <TextField
                label={intl.get("profileSettings.firstName")}
                value={firstName || ""}
                onChange={(ev, value) => {
                    setFirstName(value);
                }}
                onBlur={saveProfileInformation}
            />
            <TextField
                label={intl.get("profileSettings.lastName")}
                value={lastName || ""}
                onChange={(ev, value) => {
                    setLastName(value);
                }}
                onBlur={saveProfileInformation}
            />
            <TextField label={intl.get("profileSettings.email")} value={email || ""} disabled={true} />
        </div>
    ) : null;
}
