import { useLCMD } from "../../../app/LCMContext";

type AreaPath = {
    processId: number;
    refTimestamp?: number;
};

export function AreaPath({ processId, refTimestamp }: AreaPath) {
    const LCMD = useLCMD();
    const hookResult = LCMD.useAreaPath(processId, refTimestamp);
    const areaPath = hookResult.data.areaPath.map((path) => path.name);

    return <>{areaPath.map((path) => path).join(" > ")}</>;
}
