import * as React from "react";
import { useMemo } from "react";

import { IconButton, IContextualMenuProps, Stack, mergeStyleSets } from "@fluentui/react";

import { ViewTypeOption } from "./ViewTypeOption";
import { DynamicScaleOption } from "./DynamicScaleOption";

export type OptionsProps = {
    viewType: string;
    onViewTypeChange: (value: string) => void;

    dynamicScale: boolean;
    onDynamicScaleChange: (checked: boolean) => void;
};

const classNames = mergeStyleSets({
    icon: {
        color: "#1b2126", // regards to comment in the issue LCM2-627
    },
});

const moreIconProps = {
    iconName: "MoreVertical",
};

const tokenProps = {
    padding: 10,
    childrenGap: 15,
};

function Options({ dynamicScale, onDynamicScaleChange, onViewTypeChange, viewType }: OptionsProps) {
    const menuProps: IContextualMenuProps = useMemo(() => {
        return {
            items: [
                {
                    key: "customMenu",
                    onRender: () => {
                        return (
                            <Stack tokens={tokenProps}>
                                <DynamicScaleOption checked={dynamicScale} onChange={onDynamicScaleChange} />
                                <ViewTypeOption value={viewType} onChange={onViewTypeChange} />
                            </Stack>
                        );
                    },
                },
            ],
        };
    }, [dynamicScale, onDynamicScaleChange, viewType, onViewTypeChange]);

    return <IconButton className={classNames.icon} menuProps={menuProps} menuIconProps={moreIconProps} />;
}

export { Options };
