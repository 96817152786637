/* eslint-disable react/display-name */
import { FormEvent, useEffect, useState } from "react";
import {
    mergeStyleSets,
    Text,
    Stack,
    IDropdownOption,
    SearchBox,
    DetailsList,
    IColumn,
    IButtonProps,
    PrimaryButton,
    SelectionMode,
    IconButton,
    IContextualMenuItem,
    CheckboxVisibility,
    StackItem,
} from "@fluentui/react";
import * as React from "react";
import {
    ActiveWeekdaySelector,
    ActiveWeekdays,
} from "../../common/active-weekday-selector/ActiveWeekdaySelector.component";
import { CalendarExceptionDialog } from "./CalendarException.dialog";
import { CalendarExceptionDeleteDialog } from "./CalenderExceptionDelete.dialog";
import { intl } from "@/legacy/GlobalHelperReact";
import { CoreExtensions } from "../../../extensions/core.extensions";
import { useLCMD } from "../../../app/LCMContext";
import { useT3RolesHook } from "../../hooks/useT3Roles.hook";
import { FileButtonPrimary } from "../../common/FileButton/FileButtonPrimary";
import parse from "csv-parse/lib/browser";
import { getCurrentLanguage } from "../../utils/date/locale";
import { CalendarException, CalendarExceptionData } from "./calendarSettings.interfaces";
import { useCalendarStore } from "../../../app/store/saveCalendarSettings";

const tokensProps = { maxWidth: "100%" };
const classNames = mergeStyleSets({
    calendarExceptionColumn: {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "-1,5%",
        fontWeight: 400,
    },
    settingsTitle: {
        fontSize: 16,
        fontWeight: 600,
    },
    settingsSubtitle: {
        fontSize: 14,
        color: "#565C60",
    },
});

type LCMDExtensionVersion = 1.0;

type LCMDExtension = {
    lcmdx: LCMDExtensionVersion;
    id: string;
};

type WeekStartDayExtensionFields = {
    calendar: {
        weekStartDay: number;
    };
};

type CalendarWorkingDaysExtensionFields = {
    calendar: {
        wd?: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
    };
};

type CalendarExceptionExtensionFields = {
    calendar: {
        exceptions?: CalendarException[];
    };
};

export type WeekStartDayExtension = LCMDExtension & WeekStartDayExtensionFields;
export type CalendarSettingsExtension = LCMDExtension &
    CalendarWorkingDaysExtensionFields &
    CalendarExceptionExtensionFields;

export const CalendarSettingsView = (props) => {
    const weekstartOptions: IDropdownOption[] = [
        { key: 1, text: intl.get("calendar.strings.days.mon") },
        { key: 2, text: intl.get("calendar.strings.days.tue") },
        { key: 3, text: intl.get("calendar.strings.days.wed") },
        { key: 4, text: intl.get("calendar.strings.days.thu") },
        { key: 5, text: intl.get("calendar.strings.days.fri") },
        { key: 6, text: intl.get("calendar.strings.days.sat") },
        { key: 0, text: intl.get("calendar.strings.days.sun") },
    ];

    const [calendarExceptions, setCalendarExceptions] = useState<CalendarException[]>([
        {
            name: "Christmas",
            value: ["2021-12-24", "2021-12-26"],
            repeat: "yearly",
        },
        {
            name: "New Years Eve",
            value: "2021-12-31",
            repeat: "yearly",
        },
    ]);
    const LCMD = useLCMD();
    const [searchValue, setSearchValue] = useState("");
    const initalWeekStartKey = weekstartOptions.find((weekstartOption) => weekstartOption.key === 0);
    const [initialCalendarSettings, setInitalCalendarSettings] = useState<{
        firstDayInWeek: IDropdownOption;
        activeWeekDays: ActiveWeekdays;
        calendarExceptions: CalendarException[];
    }>({
        firstDayInWeek: initalWeekStartKey,
        activeWeekDays: [false, true, true, true, true, true, false],
        calendarExceptions: calendarExceptions,
    });
    const [firstDayInWeek, setFirstDayInWeek] = useState(initialCalendarSettings.firstDayInWeek);
    const [activeWeekDays, setActiveWeekDays] = useState<ActiveWeekdays>(initialCalendarSettings.activeWeekDays);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [calendarExceptionToEdit, setCalendarExceptionToEdit] = useState<CalendarException>(null);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [invalidCalendarSettings, setInvalidCalendarSettings] = useState(false);
    const isAllowed = useT3RolesHook();
    const calendarSettingsStore = useCalendarStore();

    useEffect(() => {
        calendarSettingsStore.setCalendarSettings({
            firstDayInWeek: firstDayInWeek.key,
            activeWeekDays,
            calendarExceptions,
        });
    }, [activeWeekDays, firstDayInWeek, calendarExceptions]);

    const calendarExceptionColumns: IColumn[] = [
        {
            key: "name",
            fieldName: "name",
            name: intl.get("CalendarExceptionMainScreen.Columns.Name"),
            minWidth: 400,
            isResizable: true,
        },
        {
            key: "functions",
            name: "",
            fieldName: "functions",
            minWidth: 20,
            maxWidth: 20,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item, index, column) => {
                return (
                    <span className={classNames.calendarExceptionColumn}>
                        <IconButton
                            styles={{
                                root: {
                                    color: "#999EA1",
                                    height: "20px",
                                },
                                rootDisabled: {
                                    backgroundColor: "transparent",
                                },
                            }}
                            menuProps={{
                                items: [
                                    {
                                        key: "editMessage",
                                        disabled: !isAllowed,
                                        text: intl.get("CalendarExceptionMainScreen.Columns.Actions.Edit"),
                                        iconProps: { iconName: "Edit" },
                                        onClick: (
                                            ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
                                            menuItem?: IContextualMenuItem,
                                        ) => {
                                            setCalendarExceptionToEdit(item);
                                            setShowEditDialog(true);
                                        },
                                    },
                                    {
                                        key: "deleteEvent",
                                        disabled: !isAllowed,
                                        text: intl.get("CalendarExceptionMainScreen.Columns.Actions.Delete"),
                                        iconProps: { iconName: "Delete" },
                                        onClick: () => {
                                            setCalendarExceptionToEdit(item);
                                            setShowDeleteModal(true);
                                        },
                                    },
                                ],
                            }}
                            iconProps={{ iconName: "MoreVertical" }}
                            onMenuClick={() => {
                                //selection.setIndexSelected(index, true, true);
                            }}
                            data-selection-disabled={true}
                            data-is-focusable={false}
                            split={false}
                            onRenderMenuIcon={() => null}
                        />
                    </span>
                );
            },
        },
        {
            key: "startDate",
            fieldName: "startDate",
            name: intl.get("CalendarExceptionMainScreen.Columns.StartDate"),
            isResizable: true,
            minWidth: 150,
            onRender: (item) => {
                const date = typeof item.value === "string" ? new Date(item.value) : new Date(item.value[0]);
                const timezoneUnawareDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1_000);

                return <span>{formattedDate(timezoneUnawareDate)}</span>;
            },
        },
        {
            key: "endDate",
            fieldName: "endDate",
            name: intl.get("CalendarExceptionMainScreen.Columns.EndDate"),
            isResizable: true,
            minWidth: 150,
            onRender: (item) => {
                const date = typeof item.value === "string" ? new Date(item.value) : new Date(item.value[1]);
                const timezoneUnawareDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1_000);
                return <span>{formattedDate(timezoneUnawareDate)}</span>;
            },
        },
        {
            key: "repeat",
            fieldName: "repeat",
            name: intl.get("CalendarExceptionMainScreen.Columns.Repeat"),
            onRender: (item) => {
                return (
                    <span>
                        {item.repeat
                            ? intl.get(`CalendarExceptionMainScreen.choiceGroupRepeat.options.${item.repeat}`)
                            : intl.get(`CalendarExceptionMainScreen.choiceGroupRepeat.options.none`)}
                    </span>
                );
            },
            isResizable: true,
            minWidth: 300,
        },
    ];

    const formattedDate = (date: Date) =>
        date.toLocaleDateString(getCurrentLanguage(), {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

    useEffect(() => {
        LCMD.getProjectExtension(CoreExtensions.CALENDAR_SETTINGS, (error, data: WeekStartDayExtension) => {
            if (!(data?.calendar?.weekStartDay >= 0)) {
                return;
            }

            setInitalCalendarSettings({
                ...initialCalendarSettings,
                firstDayInWeek: weekstartOptions.find(
                    (weekstartOption) => weekstartOption.key === data.calendar.weekStartDay,
                ),
            });
            setFirstDayInWeek(
                weekstartOptions.find((weekstartOption) => weekstartOption.key === data.calendar.weekStartDay),
            );
        });

        loadDefaultCalenderSettings();
    }, []);

    useEffect(() => {
        setInvalidCalendarSettings(activeWeekDays.every((activeWeekday) => !activeWeekday));
    }, [activeWeekDays]);

    const addExceptionButtonProps: IButtonProps = {
        iconProps: { iconName: "Add" },
    };

    const filterCalendarExceptions = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        setSearchValue(newValue);
    };

    const weekdayActiveStatusChanged = (newActiveWeekdays) => {
        setUnsavedChanges(true);
        setActiveWeekDays(newActiveWeekdays);
    };

    const weekSelectionChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option) {
            setFirstDayInWeek(option);
            setUnsavedChanges(true);
        }
    };

    const handleCreateNewCalendarException = (calendarExceptionData: CalendarExceptionData) => {
        setShowAddDialog(false);

        const newException: CalendarException =
            transformCalendarExceptionDataToCalendarException(calendarExceptionData);

        const newCalendarExceptions: CalendarException[] = [...calendarExceptions, newException];

        setCalendarExceptions(newCalendarExceptions);
        setUnsavedChanges(true);
    };

    const handleEditCalendarException = (calendarExceptionData: CalendarExceptionData) => {
        setShowEditDialog(false);
        const editedExceptionData: CalendarException =
            transformCalendarExceptionDataToCalendarException(calendarExceptionData);

        Object.keys(calendarExceptionToEdit).forEach((propertyName) => {
            calendarExceptionToEdit[propertyName] = editedExceptionData[propertyName];
        });

        setCalendarExceptions([...calendarExceptions]);
        setUnsavedChanges(true);
    };

    function addCalendarException() {
        setShowAddDialog(true);
    }

    /**
     *  @todo: check if this method can be replaced with the new hook
     *  @see useCalendarSettings
     */
    function loadDefaultCalenderSettings() {
        LCMD.getProjectExtension(CoreExtensions.CALENDAR_DEFAULT_SETTINGS, (error, data: CalendarSettingsExtension) => {
            setInitalCalendarSettings({
                ...initialCalendarSettings,
                activeWeekDays: data?.calendar?.wd || [false, true, true, true, true, true, false],
                calendarExceptions: data?.calendar?.exceptions || [],
            });
            setActiveWeekDays(data?.calendar?.wd || [false, true, true, true, true, true, false]);
            setCalendarExceptions(data?.calendar.exceptions || []);
            setUnsavedChanges(false);
        });
    }

    function transformGermanDate(germanDateString: string): { month: number; day: number; year: number } {
        const germanArray = germanDateString.split(".");
        return { day: +germanArray[0], month: +germanArray[1], year: +germanArray[2] };
    }

    function importCalendar(event: FormEvent<HTMLButtonElement>) {
        const files = (event.target as HTMLInputElement).files;

        if (files[0].type !== "text/csv") {
            console.warn("File is not csv");
            alert("File is not csv");
            return;
        }
        files
            .item(0)
            .text()
            .then((data) => {
                parse(data, { delimiter: ";" }, function (err, records: []) {
                    const exceptions: CalendarException[] = [];
                    for (let i = 1; i < records.length; i++) {
                        const record = records[i];
                        if (record[0] && record[1] && record[2] && record[3]) {
                            const sDate = transformGermanDate(record[1]);
                            const eDate = transformGermanDate(record[2]);
                            const calData: CalendarExceptionData = {
                                name: record[0],
                                startDate: new Date(Date.UTC(sDate.year, sDate.month - 1, sDate.day)),
                                endDate: new Date(Date.UTC(eDate.year, eDate.month - 1, eDate.day)),
                                repeat: record[3] as any,
                            };

                            exceptions.push(transformCalendarExceptionDataToCalendarException(calData));
                        } else {
                            console.warn(`CSV record ${i} is not valid`, record);
                        }
                    }

                    if (exceptions.length > 0) {
                        setCalendarExceptions(exceptions);
                        setUnsavedChanges(true);
                    }
                });
            });
    }

    function DateToFormattedString(d: Date) {
        const yyyy = d.getUTCFullYear().toString();
        const mm = (d.getUTCMonth() + 1).toString(); // getMonth() is zero-based
        const dd = d.getUTCDate().toString();

        return yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0]);
    }

    function transformCalendarExceptionDataToCalendarException({
        name,
        startDate,
        endDate,
        repeat,
    }: CalendarExceptionData): CalendarException {
        return {
            name,
            value:
                startDate.toLocaleDateString() === endDate.toLocaleDateString()
                    ? DateToFormattedString(startDate)
                    : [DateToFormattedString(startDate), DateToFormattedString(endDate)],
            repeat,
        };
    }

    function handleDiscardCalendarSettingsChanges() {
        loadDefaultCalenderSettings();
    }

    function handleCloseAddModal() {
        setShowAddDialog(false);
        setCalendarExceptionToEdit(null);
    }

    function handleCloseEditModal() {
        setShowEditDialog(false);
        setCalendarExceptionToEdit(null);
    }

    return (
        <Stack styles={{ root: { padding: "0 22px" } }}>
            <Stack tokens={tokensProps} styles={{ root: { borderBottom: "1px solid #E1E4E5", padding: "10px 0" } }}>
                <Stack tokens={{ childrenGap: 16 }}>
                    <StackItem>
                        <Stack>
                            <Text className={classNames.settingsTitle}>
                                {intl.get("CalendarExceptionMainScreen.WorkingDaysHeaderText")}
                            </Text>
                            <Text className={classNames.settingsSubtitle}>
                                {intl.get("CalendarExceptionMainScreen.WorkingDaysDescriptionText")}
                            </Text>
                        </Stack>
                    </StackItem>
                    <StackItem>
                        <ActiveWeekdaySelector
                            activeWeekdays={activeWeekDays as ActiveWeekdays}
                            onActiveWeekdaysChange={weekdayActiveStatusChanged}
                            disabled={!isAllowed}
                        />
                    </StackItem>
                </Stack>
            </Stack>
            <Stack tokens={tokensProps} styles={{ root: { padding: "10px 0" } }}>
                <Stack horizontalAlign="space-between" horizontal styles={{ root: { marginBottom: -2 } }}>
                    <Text className={classNames.settingsTitle}>
                        {intl.get("CalendarExceptionMainScreen.ExceptionsHeaderText")}
                    </Text>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <FileButtonPrimary
                            accept={".csv"}
                            disabled={!isAllowed}
                            iconProps={addExceptionButtonProps.iconProps}
                            text={intl.get("CalendarExceptionMainScreen.Import")}
                            onChange={importCalendar}
                        />
                        <PrimaryButton
                            disabled={!isAllowed}
                            iconProps={addExceptionButtonProps.iconProps}
                            onClick={addCalendarException}
                        >
                            {intl.get("CalendarExceptionMainScreen.NewButtonText")}
                        </PrimaryButton>
                    </Stack>
                </Stack>
                <SearchBox
                    onChange={filterCalendarExceptions}
                    placeholder={intl.get("CalendarExceptionMainScreen.SearchTextFieldText")}
                    styles={{ root: { width: "240px" } }}
                />
                <DetailsList
                    checkboxVisibility={CheckboxVisibility.always}
                    selectionMode={SelectionMode.single}
                    items={
                        !searchValue
                            ? [...calendarExceptions].sort((a, b) => a.value > b.value ? 1 : -1)
                            : [...calendarExceptions].sort((a, b) => a.value > b.value ? 1 : -1).filter(
                                  (calendarException) =>
                                      calendarException.name.toLowerCase().indexOf(searchValue?.toLowerCase()) >= 0,
                              )
                    }
                    columns={calendarExceptionColumns}
                />
            </Stack>
            {showAddDialog && (
                <CalendarExceptionDialog onDismiss={handleCloseAddModal} onClose={handleCreateNewCalendarException} />
            )}
            {showEditDialog && (
                <CalendarExceptionDialog
                    onDismiss={handleCloseEditModal}
                    onClose={handleEditCalendarException}
                    calendarException={calendarExceptionToEdit}
                />
            )}
            {showDeleteModal && (
                <CalendarExceptionDeleteDialog
                    onCancel={() => {
                        setShowDeleteModal(false);
                    }}
                    onClose={() => {
                        const exceptionsAfterDelete = calendarExceptions.filter(
                            (calendarException, index) => calendarException !== calendarExceptionToEdit,
                        );
                        setCalendarExceptions(exceptionsAfterDelete);
                        setUnsavedChanges(true);
                        setCalendarExceptionToEdit(null);
                        setShowDeleteModal(false);
                    }}
                    exceptionTitle={calendarExceptionToEdit.name}
                />
            )}
        </Stack>
    );
};
