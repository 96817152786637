import * as React from "react";
import {
    IconButton,
    IIconProps,
    mergeStyleSets,
    Modal,
    getTheme,
    FontWeights,
    PrimaryButton,
    IStyleFunctionOrObject,
    IModalStyleProps,
    IModalStyles,
    concatStyleSets,
    IStyle,
    Pivot,
    PivotItem,
    Separator,
    OverflowSet,
    IOverflowSetItemProps,
    CommandBarButton,
    CommandBar,
    DefaultButton,
} from "@fluentui/react";
import { Dialog as FluentUIDialog, DialogFooter as FluentUIDialogFooter } from "@fluentui/react";
import { intl } from "../GlobalHelperReact";
import { gfu, gfu4 } from "../../model/GlobalHelper";

const theme = getTheme();

const cancelIcon: IIconProps = { iconName: "Cancel" };
const chevronLeftIcon: IIconProps = { iconName: "ChevronLeft" };

export function DialogScreen(props: { name: string; component?: any; options?: any; children?: any; style?: IStyle }) {
    const dialog = (props as any)._dialog;
    const left = (props as any)._left;
    const prevOptions = (props as any)._prev;
    const styles = (props as any)._styles;
    const params = (props as any)._params;
    const overflowItems = props.options?.overflowItems || [];
    const commandBarItems = props.options?.commandBarItems || [];
    const commandBarFarItems = props.options?.commandBarFarItems || [];
    const classNames = React.useMemo(
        () =>
            mergeStyleSets({
                dialogHeader: [
                    theme.fonts.large,
                    {
                        position: "absolute",
                        left: 0,
                        top: 0,
                        right: 0,
                        height: styles.dialog.headerHeight,
                        borderBottom: "1px solid #E1E4E5", //@TODO use themeColor
                        color: theme.palette.neutralPrimary,
                        fontWeight: FontWeights.semibold,
                    },
                ],
                dialogScreen: [styles.dialogScreen, props.style],
                dialogScreenCnt: {
                    position: "absolute",
                    top: styles.dialog.headerHeight,
                    left: 0,
                    right: 0,
                    bottom: styles.dialog.footerHeight,
                    /*
            paddingLeft: styles.dialog.paddingLeft,
            paddingRight: styles.dialog.paddingRight,
            paddingTop: styles.dialog.paddingTop,
            paddingBottom: styles.dialog.paddingBottom,
            */
                    overflow: "auto",
                },
                dialogFooter: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    right: 0,
                    height: styles.dialog.footerHeight,
                    borderTop: "1px solid #E1E4E5", //@TODO use themeColor
                    paddingLeft: styles.dialog.footerLeft,
                    paddingRight: styles.dialog.footerRight,
                },
            }),
        [styles.dialogScreen, props.style, styles.dialog],
    );
    const onCancel = gfu(props.options?.onCancel, props.options?.onClose, undefined);
    const isOnCancel = "function" === typeof onCancel;
    const onBack = gfu4(props.options?.onBack, props.options?.onCancel, props.options?.onClose, undefined);
    const isOnBack = "function" === typeof onBack;
    return (
        <div
            className={classNames.dialogScreen}
            style={{ left: ["calc( ", left, " * 100% )"].join("") }}
            data-userflow-id="dialog-modal-container"
        >
            <div className={classNames.dialogHeader}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        position: "absolute",
                        lineHeight: [styles.dialog.headerHeight, "px"].join(""),
                        height: styles.dialog.headerHeight,
                        left: styles.dialog.headerLeft,
                        top: 0,
                        bottom: 0,
                    }}
                >
                    {prevOptions?.title && false !== onBack ? (
                        <IconButton
                            styles={{
                                root: {
                                    color: theme.palette.neutralPrimary,
                                },
                                rootHovered: {
                                    color: theme.palette.neutralDark,
                                },
                            }}
                            iconProps={chevronLeftIcon}
                            disabled={!isOnBack}
                            onClick={onBack}
                        />
                    ) : null}
                    {prevOptions?.title ? (
                        <div
                            style={{
                                lineHeight: "inherit",
                                height: "inherit",
                            }}
                        >
                            {prevOptions.title}
                        </div>
                    ) : null}
                    {prevOptions?.title ? (
                        <div
                            style={{
                                lineHeight: "16px",
                                height: 16,
                                paddingLeft: 10,
                                paddingRight: 10,
                                color: "#E1E4E5", //@TODO use themeColor
                                //color: theme.palette.neutralPrimary,
                                fontWeight: 300,
                            }}
                        >
                            /
                        </div>
                    ) : null}
                    {false ? (
                        <Pivot headersOnly={true} selectedKey={"A"}>
                            <PivotItem headerText="Takt Zones" itemKey="A" />
                            <PivotItem headerText="Panel 2" itemKey="B" />
                        </Pivot>
                    ) : (
                        <div
                            style={{
                                lineHeight: "inherit",
                                height: "inherit",
                            }}
                        >
                            {props.options?.title || intl.get("fw.waiting")}
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        position: "absolute",
                        lineHeight: [styles.dialog.headerHeight, "px"].join(""),
                        height: styles.dialog.headerHeight,
                        right: styles.dialog.headerRight,
                        top: 0,
                        bottom: 0,
                        //backgroundColor: "green"
                    }}
                >
                    {overflowItems.length > 0 ? (
                        <OverflowSet
                            role="menubar"
                            items={overflowItems}
                            onRenderItem={(item: IOverflowSetItemProps) => {
                                return (
                                    <CommandBarButton
                                        role="menuitem"
                                        iconProps={{ iconName: item.icon }}
                                        title={item.title}
                                        name={item.name}
                                        disabled={item.disabled}
                                        styles={{
                                            icon: {
                                                color: theme.palette.neutralPrimary,
                                            },
                                            iconHovered: {
                                                color: theme.palette.neutralDark,
                                            },
                                            root: {
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                color: theme.palette.neutralPrimary,
                                            },
                                            rootHovered: {
                                                color: theme.palette.neutralDark,
                                            },
                                        }}
                                        onClick={item.onClick}
                                        data-useflow-id={
                                            intl.get("filter.main.save.title") === item.title
                                                ? "pp-filter-speichern"
                                                : null
                                        }
                                    />
                                );
                            }}
                            onRenderOverflowButton={(overflowItems: any[] | undefined) => {
                                return null;
                            }}
                        />
                    ) : null}
                    {overflowItems.length > 0 ? (
                        <Separator
                            vertical
                            styles={{
                                root: {
                                    height: theme.fonts.large.fontSize,
                                },
                            }}
                        />
                    ) : null}
                    <IconButton
                        disabled={!props.options?.onClose}
                        styles={{
                            root: {
                                color: theme.palette.neutralPrimary,
                            },
                            rootHovered: {
                                color: theme.palette.neutralDark,
                            },
                        }}
                        iconProps={cancelIcon}
                        onClick={props.options?.onClose}
                        data-userflow-id="dialog-modal-kreuz"
                    />
                </div>
            </div>
            <div className={classNames.dialogScreenCnt}>
                {props.component ? (
                    <props.component
                        dialog={dialog}
                        route={{
                            params: params,
                        }}
                    />
                ) : null}
            </div>
            <div className={classNames.dialogFooter + " dialogFooter"}>
                <div
                    style={{
                        flexGrow: 1,
                    }}
                >
                    {commandBarItems || commandBarFarItems ? (
                        <CommandBar
                            styles={{
                                root: {
                                    paddingLeft: 0,
                                },
                            }}
                            items={commandBarItems || []}
                            farItems={commandBarFarItems || []}
                        />
                    ) : null}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexGrow: 0,
                        flexDirection: "row",
                        columnGap: 10,
                    }}
                >
                    <DefaultButton
                        disabled={!isOnCancel}
                        text={intl.get("fw.cancel")}
                        onClick={onCancel}
                        data-userflow-id="dialog-modal-abbrechen"
                    />
                    <PrimaryButton
                        data-userpilot-id="dailyboardFilterDialog-okButton"
                        disabled={!props.options?.onOK}
                        text={props.options?.ok || intl.get("fw.ok")}
                        onClick={props.options?.onOK || undefined}
                        data-userflow-id="dialog-modal-ok"
                    />
                </div>
            </div>
        </div>
    );
}

function createDialogCtx(children, setScreens, styles, setPopup) {
    const dialog = {
        styles: styles,
        pushScreen: (name, params?) => {
            let _screens = children.filter((screen) => {
                return name === screen.props.name;
            });
            if (params) {
                _screens = _screens.map((screen) =>
                    React.cloneElement(screen, {
                        _params: params,
                    }),
                );
            }
            if (_screens.length > 0) {
                setScreens((screens) => {
                    const ret = screens.concat(_screens);
                    return ret;
                });
            }
        },
        replaceScreen: (name, params?) => {
            let _screens = children.filter((screen) => {
                return name === screen.props.name;
            });
            if (params) {
                _screens = _screens.map((screen) =>
                    React.cloneElement(screen, {
                        _params: params,
                    }),
                );
            }
            if (_screens.length > 0) {
                setScreens((screens) => {
                    if (screens.length > 0) {
                        screens = screens.slice();
                        screens.splice(screens.length - 1, 1, ..._screens);
                    }
                    return screens;
                });
            }
        },
        popScreen: () => {
            setScreens((screens) => {
                if (screens.length > 0) {
                    screens = screens.slice();
                    screens.splice(screens.length - 1, 1);
                }
                return screens;
            });
        },
        setOptions: (options) => {
            setScreens((screens) => {
                if (screens.length > 0) {
                    screens = screens.slice();
                    const _options = { ...(screens[screens.length - 1].props.options || {}), ...options };
                    screens[screens.length - 1] = React.cloneElement(screens[screens.length - 1], {
                        options: _options,
                    });
                }
                return screens;
            });
        },
        showPopup: (options) => {
            setPopup(options);
        },
    };
    return dialog;
}

export interface IDialogStyles extends IModalStyles {
    dialog?: {
        headerHeight?: number;
        footerHeight?: number;
        headerLeft?: number;
        headerRight?: number;
        footerLeft?: number;
        footerRight?: number;
        paddingLeft?: number;
        paddingRight?: number;
    };
    dialogScreen?: IStyle;
}

export function Dialog(props: {
    initialScreen: string | ((dialog) => void);
    children: React.ReactElement | React.ReactElement[];
    isOpen: boolean;
    width?: number;
    heigth?: number;
    styles?: IStyleFunctionOrObject<IModalStyleProps, IDialogStyles>;
}) {
    const styles = React.useMemo(
        () =>
            concatStyleSets(
                {
                    main: {
                        width: props.width || 800,
                        height: props.heigth || 600,
                    },
                    scrollableContent: {
                        overflow: "hidden",
                        position: "relative",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                    },
                    dialog: {
                        headerHeight: 60,
                        footerHeight: 60,
                        headerLeft: 24,
                        headerRight: 24,
                        footerLeft: 24,
                        footerRight: 24,
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingTop: 24,
                        paddingBottom: 24,
                    },
                    dialogScreen: {
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: 0,
                        backgroundColor: "white",
                    },
                } as IStyleFunctionOrObject<IModalStyleProps, IDialogStyles>,
                props.styles,
            ) as IStyleFunctionOrObject<IModalStyleProps, IDialogStyles>,
        [props.width, props.heigth, props.styles],
    );
    const classNames = React.useMemo(
        () =>
            mergeStyleSets({
                dialogScreenConainer: {
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                    transition: "left 200ms linear",
                },
            }),
        [],
    );
    const [screens, setScreens] = React.useState([]);
    const [popup, setPopup] = React.useState(null);
    const dialog = React.useMemo(
        () =>
            createDialogCtx(
                Array.isArray(props.children) ? props.children : [props.children],
                setScreens,
                styles,
                setPopup,
            ),
        [props.children, setScreens, styles, setPopup],
    );
    React.useEffect(() => {
        if ("function" === typeof props.initialScreen) {
            props.initialScreen(dialog);
        } else {
            dialog.pushScreen(props.initialScreen);
        }
    }, []);
    const _screens = React.useMemo(
        () =>
            screens.map((screen, i_screen, a_screens) => {
                return React.cloneElement(screen, {
                    key: i_screen,
                    _dialog: dialog,
                    _left: i_screen,
                    _prev: i_screen > 0 ? a_screens[i_screen - 1].props.options : null,
                    _styles: styles,
                });
            }),
        [screens],
    );
    return props.isOpen ? (
        <>
            <Modal isOpen={props.isOpen} styles={styles}>
                <div
                    className={classNames.dialogScreenConainer}
                    style={{
                        left: ["calc( ", -Math.max(_screens.length - 1, 0), " * 100% )"].join(""),
                    }}
                >
                    {_screens}
                </div>
            </Modal>
            {popup ? (
                <FluentUIDialog
                    hidden={false}
                    dialogContentProps={popup.dialogContentProps}
                    modalProps={popup.modalProps || undefined}
                >
                    <FluentUIDialogFooter>
                        {popup?.cancel ? (
                            <DefaultButton
                                onClick={popup.cancel.onClick}
                                text={popup.cancel.text || intl.get("fw.cancel")}
                                title={popup.cancel.title}
                                styles={popup.cancel.styles}
                            />
                        ) : null}
                        {popup?.ok ? (
                            <PrimaryButton
                                onClick={popup.ok.onClick}
                                text={popup.ok.text || intl.get("fw.ok")}
                                title={popup.ok.title}
                                styles={popup.ok.styles}
                            />
                        ) : null}
                    </FluentUIDialogFooter>
                </FluentUIDialog>
            ) : null}
        </>
    ) : null;
}
