import * as React from "react";
import { DefaultButton, mergeStyleSets } from "@fluentui/react";
import { intl } from "../../../legacy/GlobalHelperReact";

const styles = mergeStyleSets({
    bold: {
        fontWeight: 600,
    },
});

export function DeleteFilterButton({ onClick: handleFilterResetButtonClick }: { onClick: () => void }) {
    return (
        <DefaultButton
            styles={{ root: { cursor: "pointer", backgroundColor: "#ffffff", border: "none", fontWeight: 600 } }}
            onClick={handleFilterResetButtonClick}
            iconProps={{ iconName: "trash", styles: { root: { fontWeight: 600, color: "black" } } }}
            data-userflow-id="pp-filter-loeschen"
        >
            {intl.get("HistoryView.HistoryFilter.Buttons.DeleteFilters")}
        </DefaultButton>
    );
}
