import * as React from "react";
import { ReactNode } from "react";

import { TimescaleOptionI, SelectedTimescaleValueI, SpecifiedTradesI, SpecifiedTaktZonesI } from "./types";
import { IContextualMenuItem } from "@fluentui/react";

import { StackItem, mergeStyleSets } from "@fluentui/react";

import { Layout } from "./layout";
import { Options } from "./layout/options";

export type FilterBarProps<T> = {
    specifiedAllLabel: string;

    tradesLabel: string;
    specifiedTrades: SpecifiedTradesI[];

    taktZonesLabel: string;
    specifiedTaktZones: SpecifiedTaktZonesI[];

    timescaleLabel: string;
    timescaleOptions: TimescaleOptionI<T>[];
    onChangeTimescale: (value: SelectedTimescaleValueI) => void;

    filter?: ReactNode;
    additionActions?: IContextualMenuItem[];
    onDownloadClick?: () => void;
};

const GAP_BETWEEN_SPECIFIED_ITEMS = 20;

const classNames = mergeStyleSets({
    leftColumn: {
        minWidth: 0,
    },
});

function FilterBar<T>(props: FilterBarProps<T>) {
    const { specifiedAllLabel } = props;
    const { timescaleLabel, onChangeTimescale, timescaleOptions } = props;
    const { taktZonesLabel, specifiedTaktZones } = props;
    const { tradesLabel, specifiedTrades } = props;
    const { filter, additionActions } = props;
    const { onDownloadClick } = props;

    return (
        <Layout>
            <StackItem className={classNames.leftColumn}>
                <Options gap={GAP_BETWEEN_SPECIFIED_ITEMS}>
                    {/*
          <TaktZones
            title={taktZonesLabel}
            specifiedAllLabel={specifiedAllLabel}
            taktZones={specifiedTaktZones}
          />
          <Trades
            title={tradesLabel}
            specifiedAllLabel={specifiedAllLabel}
            trades={specifiedTrades}
          />
          <Timeframe
            title={timescaleLabel}
            options={timescaleOptions}
            onChange={onChangeTimescale}
          />
          */}
                </Options>
            </StackItem>
            <StackItem shrink={false}>
                <Options>
                    {filter}
                    {/*onDownloadClick && <DownloadButton onClick={onDownloadClick} />*/}
                    {/*additionActions.length ? (
            <AdditionActionsButton items={additionActions} />
          ) : null*/}
                </Options>
            </StackItem>
        </Layout>
    );
}

export { FilterBar };
