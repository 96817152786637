import { BadgeStatuses } from "@/model/GlobalHelper";
import {
    Core,
    HiveGetter,
    ParticleGetter,
    ProcessGetter,
    ProcessId,
    TableExportHelper,
    TradesGetter,
} from "../lcmd2core";
import { depenbrockTemplates } from "@/app/depenbrockStaticTemplate";

export type ChecklistId = string;
export type AttachedChecklistId = number;

// Base interface with common properties
export interface BaseChecklist {
    name: string;
    tradeId: number;
    leadTime: number; //days before process
    trailTime: number; //days after process
    label?: string;
    status?: ChecklistStatus;
    checkpoints: {
        id: number;
        text: string;
        groupId: string;
    }[];
    groups: {
        id: string;
        name: string;
        leadTime: number;
    }[];
}

// Checklist interface
export interface Checklist extends BaseChecklist {
    id: string;
}

// AttachedChecklist interface
export interface AttachedChecklist extends BaseChecklist {
    id: number;
    templateId: string;
    pid: number;
    createdAt?: string;
    data?: number[];
    trade?: {
        id: number;
        name: string;
        trade: any; // Replace with appropriate type if available
        color: number;
        completed: number;
        readonly: boolean;
        icon: string;
    };
    processName?: string;
    startDate?: Date;
    endDate?: Date;
}

export enum ChecklistItemStatus {
    YES = 2,
    NO = 1,
    OPEN = 0,
    NA = 3,
}

export enum ChecklistStatus {
    OPEN = "open",
    INPROGRESS = "inProgress",
    INRELEASE = "inRelease",
    DONE = "done",
    OVERDUE = "overdue",
}

module CheckListParameter {
    type DefaultCommandParameter = {
        pid: ProcessId;
        checklistId: ChecklistId;
        opId: number;
        attachedChecklistId: AttachedChecklistId;
    };
    export type Create = Omit<DefaultCommandParameter, "attachedChecklistId">;
    export type Update = DefaultCommandParameter;
    export type Delete = Pick<DefaultCommandParameter, "attachedChecklistId" | "pid">;
}

export class ChecklistService {
    constructor(private core: Core) {}

    getAllAttachedChecklists(): AttachedChecklist[] {
        const pids: number[] = this.core.getAllProcessesIds();
        let allChecklists: AttachedChecklist[] = [];
        for (const pid of pids) {
            let checklists: AttachedChecklist[] = this.getAttachedChecklists(pid);
            if (checklists) {
                allChecklists = allChecklists.concat(checklists);
            }
        }
        return allChecklists;
    }

    getAttachedChecklists(pid: number): AttachedChecklist[] {
        let processGetter: ProcessGetter = new ProcessGetter(this.core, pid);
        const map = processGetter.getChecklists();
        const attachedChecklists: AttachedChecklist[] = map ? Array.from(map.values()) : [];
        return attachedChecklists.map((checklist) => this.populateAttachedChecklist(checklist));
    }

    getAttachedChecklistsById(pid: number, checklistId: number) {
        let processGetter: ProcessGetter = new ProcessGetter(this.core, pid);
        let checklists: Map<number, AttachedChecklist> = processGetter.getChecklists();
        if (!checklists) return null;
        for (let [key, value] of checklists) {
            if (value.id === checklistId) {
                return this.populateAttachedChecklist(value);
            }
        }
        return null;
    }

    getChecklistTemplate(templateId) {
        return depenbrockTemplates.find((template) => template.id === templateId);
    }

    //helper functions
    private populateAttachedChecklist(attachedChecklist: AttachedChecklist): AttachedChecklist {
        const processGetter = new ProcessGetter(this.core, attachedChecklist.pid);
        const trade = this.core.getTradeForProcess(processGetter);
        const processName: string = processGetter.value("name");
        const startDateTimestamp: number = processGetter.value("start");
        const processDays: number = processGetter.value("days");

        // Adjust the start date by subtracting the lead time (weekdays only)
        const adjustedStartDate = this.calculateAdjustedDate(
            startDateTimestamp,
            attachedChecklist.leadTime,
            "backward",
        );

        // Calculate the end date and adjust by adding the trail time (weekdays only)
        const endDate = this.calculateEndDate(startDateTimestamp, processDays);
        const adjustedEndDate = this.calculateAdjustedDate(endDate.getTime(), attachedChecklist.trailTime, "forward");

        const status = this.determineChecklistStatus(attachedChecklist.data);

        return {
            ...attachedChecklist,
            trade,
            processName,
            startDate: adjustedStartDate,
            endDate: adjustedEndDate,
            status,
        };
    }

    private calculateEndDate(startDateTimestamp: number, workingDays: number): Date {
        const startDate = new Date(startDateTimestamp);
        let daysAdded = 0;

        while (daysAdded < workingDays) {
            startDate.setDate(startDate.getDate() + 1);
            const dayOfWeek = startDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                // 0 for Sunday and 6 for Saturday
                daysAdded++;
            }
        }

        return startDate;
    }

    private calculateAdjustedDate(dateTimestamp: number, weekdays: number, direction: "forward" | "backward"): Date {
        const adjustedDate = new Date(dateTimestamp);
        let daysAdded = 0;

        while (daysAdded < Math.abs(weekdays)) {
            if (direction === "forward") {
                adjustedDate.setDate(adjustedDate.getDate() + 1);
            } else {
                adjustedDate.setDate(adjustedDate.getDate() - 1);
            }
            const dayOfWeek = adjustedDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                // Skip weekends
                daysAdded++;
            }
        }

        return adjustedDate;
    }

    private determineChecklistStatus(data: number[]): ChecklistStatus {
        if (data.length === 0) {
            return ChecklistStatus.INRELEASE;
        } else if (data.every((value) => value === 0)) {
            return ChecklistStatus.OPEN;
        } else if (data.every((value) => value === 2)) {
            return ChecklistStatus.DONE;
        } else {
            return ChecklistStatus.INPROGRESS;
        }
    }
}
