import { FrameworkHttpError } from "../model/GlobalHelper";


export function fetch_pdf(SERVICES, token:string, cb:(error:FrameworkHttpError | null, result:any)=>void, data:Buffer) {
    const req = new XMLHttpRequest();
    req.responseType="json";
    req.open("POST", SERVICES.PDF_SERVICE);
    req.setRequestHeader("content-type", "application/octet-stream");
    if (token) {
        req.setRequestHeader("Authorization", ["token", token].join(' '));
    }
    req.onreadystatechange=()=>{
        if (req.readyState===XMLHttpRequest.DONE) {
            if (200===req.status && req.response) {
                cb(null, req.response);
            } else {
                cb(new FrameworkHttpError(req.status), null);
            }
        }
    }
    req.responseType="arraybuffer";
    req.send(data);
}

export function getParsedProject(SERVICES, resource:{
    token: string,
    customFields?: {
        taktZone?: number
    },
    activityCode?: string
}, cb:(err:Error, done:{project:any & { props: { projectTitle?: string; customTextFields: string[] }, token: { resource_id: string } }})=>void, options?:{
    type?:string
}) {
    const req = new XMLHttpRequest();
    req.responseType="json";
    req.open("POST", SERVICES.PARSE_SERVICE);
    req.setRequestHeader("content-type", "application/json");
    req.setRequestHeader("Authorization", ["token", resource?.token].join(' '));
    req.onreadystatechange=()=>{
        if (req.readyState===XMLHttpRequest.DONE) {
            if (200===req.status && req.response) {
                cb(null, req.response);
            } else {
                cb(new FrameworkHttpError(req.status), null);
            }
        }
    }
    req.send(JSON.stringify({
        build: SERVICES.BUILD,
        customFields: resource?.customFields||null,
        mimeType: options?.type,
        activityCode: resource.activityCode || null
    }));
}

/**
 * @deprecated legacy, for fetching domain list, which are enabled to do single sign on
 * @param isCanary
 * @param email
 * @param cb
 */
export function getDomainInfo(isCanary:boolean, email:string, cb:(err:Error, done:{ sso?: { domain:string, redirectURL:string} })=>void) {
    const url=isCanary?"https://staging-lcmd2-auth.azurewebsites.net/api/sso" //TODO use staging link...
                      :"http://localhost:7079/api/SSO";
    const req = new XMLHttpRequest();
    req.responseType="json";
    req.open("POST", url);
    req.setRequestHeader("content-type", "application/json");
    req.onreadystatechange=()=>{
        if (req.readyState===XMLHttpRequest.DONE) {
            if (200===req.status && req.response) {
                cb(null, req.response);
            } else {
                cb(new FrameworkHttpError(req.status), null);
            }
        }
    }
    req.send(JSON.stringify({
        op: "getDomainInfo",
        email
    }));
}
