import * as React from "react";
import { useCallback } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { ContentTypeValueI, SizeValueI } from "../constants";
import { Text } from "@fluentui/react";
import { ViewTypeOption } from "./dropdown-options/ViewTypeOption/ViewTypeOptionV2";
import { ContentOption } from "./dropdown-options/ContentOptionV2";
import { Switch } from "@/components/ui/switch";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Eye } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import {
    useUserView,
    useAreaAttachments,
    useShowNonWorkingDays,
    useShowTaktZones,
    useShowProjectWeeks,
    useSplitView, useShowStatusBar,
} from "@/app/store/userJourneyStore";
import { useCanvasStore } from "@/app/store/canvasStore";

type ViewButtonProps = {
    content: ContentTypeValueI;
    size: SizeValueI;
    onTypeSelect: (value: string) => void;
    onSplitViewChange: (value: boolean) => void;
    onContentSelect: (value: string) => void;
    onSizeSelect: (value: string) => void;
    onTaktZoneImageChange: (showAreaAttachments: boolean) => void;
    onShowStatusBarChange: (showStatusBar: boolean) => void;
    onShowNonWorkingDaysChange: (showNonWorkingDays: boolean) => void;
    onShowTaktZonesChange: (showTaktZones: boolean) => void;
    onshowProjectWeeksChange: (showProjectWeeks: boolean) => void;
    onshowDependenciesChange: (showDependencies: boolean) => void;
};

export type SplitViewOptionProps = {
    checked: boolean;
    onChange: (value: boolean) => void;
    toggleLabel: string;
    name: string;
    userflowId?: string;
};

function SplitViewOption(props: SplitViewOptionProps) {
    const { checked, name, toggleLabel, onChange, userflowId } = props;

    const handleChange = useCallback(
        (value: boolean) => {
            onChange(value);
        },
        [onChange],
    );

    return (
        <div className="flex justify-between">
            <Text className={"font-semibold"}>{toggleLabel}</Text>
            <Switch checked={checked} onCheckedChange={handleChange} name={name} data-userflowid={userflowId}></Switch>
        </div>
    );
}
function ViewButton({
    content,
    onContentSelect,
    onShowNonWorkingDaysChange,
    onShowTaktZonesChange,
    onSplitViewChange,
    onShowStatusBarChange,
    onTaktZoneImageChange,
    onTypeSelect,
    onshowProjectWeeksChange,
    onshowDependenciesChange,
}: ViewButtonProps) {
    const { showDependencies } = useCanvasStore();
    const type = useUserView();
    const taktZoneImages = useAreaAttachments();
    const showNonWorkingDays = useShowNonWorkingDays();
    const showTaktzones = useShowTaktZones();
    const showProjectWeeks = useShowProjectWeeks();
    const splitView = useSplitView();
    const showStatusBar = useShowStatusBar()

    return (
        <Popover>
            <PopoverTrigger>
                <LCMDTooltip text={intl.get("canvas.cmd.view.text")} key={"viewTooltip"} side="bottom">
                    <IconButton icon={Eye} onClick={() => {}} key={"viewBtn"}></IconButton>
                </LCMDTooltip>
            </PopoverTrigger>
            <PopoverContent className="w-[25rem]">
                <div>
                    <div className="space-y-2">
                        <h4 className="font-medium leading-none">{intl.get("canvas.cmd.view.menuHeadline")}</h4>
                        <p className="text-sm text-muted-foreground">{intl.get("canvas.cmd.view.menuSubheadline")}</p>
                    </div>
                    <div className={"mb-8 mt-4"}>
                        <ViewTypeOption selectedType={type} onSelect={onTypeSelect}></ViewTypeOption>
                    </div>
                    <div className={"flex flex-col gap-6"}>
                        <div>
                            <SplitViewOption
                                key="areaAttachmentsOption"
                                checked={taktZoneImages}
                                onChange={onTaktZoneImageChange}
                                name={"area-attachments-option-toggle"}
                                toggleLabel={intl.get("canvas.cmd.view.ViewButton.areaAttachmentsOption.label")}
                            ></SplitViewOption>
                        </div>
                        <div>
                            <SplitViewOption
                                key="showStatusBarOption"
                                checked={showStatusBar}
                                onChange={onShowStatusBarChange}
                                name="show-status-bar-option"
                                toggleLabel={intl.get("canvas.cmd.view.ViewButton.showStatusBarOption.label")}
                                userflowId="pp-fortschritt-anzeigen"
                            />
                        </div>
                        <div>
                            <SplitViewOption
                                key="showNonWorkingDaysOptions"
                                checked={showNonWorkingDays}
                                onChange={onShowNonWorkingDaysChange}
                                name={"show-non-working-days-options-toggle"}
                                toggleLabel={intl.get("canvas.cmd.view.ViewButton.showNonWorkingDaysOptions.label")}
                            ></SplitViewOption>
                        </div>
                        <div>
                            <SplitViewOption
                                key="showTaktzones"
                                checked={showTaktzones}
                                onChange={onShowTaktZonesChange}
                                name={"sort-by-taktzones-options-toggle"}
                                toggleLabel={intl.get("canvas.cmd.view.ViewButton.sortByTaktzonesOptions.label")}
                            ></SplitViewOption>
                        </div>
                        <Separator />
                        <div>
                            <SplitViewOption
                                key="showProjectWeeks"
                                checked={showProjectWeeks}
                                onChange={onshowProjectWeeksChange}
                                name={"show-project-weeks-toggle"}
                                toggleLabel={intl.get("canvas.cmd.view.ViewButton.showProjectWeeks.label")}
                            ></SplitViewOption>
                        </div>
                        <div>
                            <SplitViewOption
                                key="showDependencies"
                                checked={showDependencies}
                                onChange={onshowDependenciesChange}
                                toggleLabel={intl.get("canvas.cmd.view.ViewButton.showDependencies.label")}
                                name={"show-dependencies-toggle"}
                            ></SplitViewOption>
                        </div>
                        <Separator />
                        <div>
                            <SplitViewOption
                                key="splitViewOption"
                                name={"bottom-split-view-toggle"}
                                checked={!!splitView}
                                onChange={onSplitViewChange}
                                toggleLabel={intl.get("canvas.cmd.view.bottomSplitView.label")}
                            ></SplitViewOption>
                        </div>
                        <ContentOption key="contentOption" value={content} onChange={onContentSelect} />
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    );
}

export { ViewButton };
