import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import { Separator, Stack, Text, mergeStyleSets, FontWeights } from "@fluentui/react";
import { TruncatedText } from "../../common/truncated-text";
import { format } from "date-fns";

export type HeaderProps = {
    milestoneName: string;
    baselineDate?: number;
};

const classNames = mergeStyleSets({
    milestoneName: { fontWeight: FontWeights.bold },
});

function Header(props: HeaderProps) {
    const { milestoneName, baselineDate } = props;
    const DEFAULT_LABEL_FORMAT = "EEEE, dd/MM/yyyy 'CW('II')'";
    return (
        <Stack>
            <TruncatedText text={milestoneName} variant="mediumPlus" className={classNames.milestoneName} />
            {baselineDate && (
                <Stack horizontal tokens={{ childrenGap: 7 }} verticalAlign={"center"}>
                    <div
                        style={{
                            width: "8px",
                            height: "8px",
                            borderRadius: "100%",
                            border: "solid 1px",
                            borderColor: "#00000014",
                            backgroundColor: "#999EA1",
                        }}
                    />
                    <Text>
                        {format(
                            baselineDate,
                            intl.get("charts.milestones.overview.dateFormat").defaultMessage(DEFAULT_LABEL_FORMAT),
                        )}
                    </Text>
                </Stack>
            )}
            <Separator color={"#E1E4E5"} />
        </Stack>
    );
}

export { Header };
