import * as React from "react";
import { ReactNode } from "react";

import { IStackTokens, mergeStyleSets, Stack } from "@fluentui/react";

export type WidgetProps = {
    minWidth?: number;
    basicWidth?: number;
    children: ReactNode;
    userflowId?: string;
};

const DEFAULT_MIN_WIDTH = 300;

const classNames = mergeStyleSets({
    widget: {
        backgroundColor: "#ffffff",
        border: "1px solid #E1E4E5",
        borderRadius: 8,
        minWidth: DEFAULT_MIN_WIDTH,
        flexBasis: DEFAULT_MIN_WIDTH,
    },
});

const tokenProps: IStackTokens = {
    padding: 10,
    childrenGap: 10,
};

function Widget({ basicWidth, children, minWidth, userflowId }: WidgetProps) {
    const style = {
        minWidth,
        flexBasis: basicWidth,
    };

    return (
        <Stack grow tokens={tokenProps} className={classNames.widget} style={style} data-userflow-id={userflowId}>
            {children}
        </Stack>
    );
}

Widget.defaultProps = {
    minWidth: DEFAULT_MIN_WIDTH,
    basicWidth: DEFAULT_MIN_WIDTH,
};

export { Widget };
