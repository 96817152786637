import * as React from "react";
import { mergeStyleSets, Stack, StackItem } from "@fluentui/react";

export type HeaderWithSubtitle = {
    title: string;
    subtitle: string;
};

export function HeaderWithSubtitle(props: HeaderWithSubtitle) {
    const styles = mergeStyleSets({
        title: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        subtitle: {
            fontFamily: "Inter",
            color: "#565C60",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "20px",
            textAlign: "left",
        },
    });
    return (
        <Stack styles={{ root: { height: "80px" } }}>
            <StackItem className={styles.subtitle}>{props.subtitle}</StackItem>
            <StackItem className={styles.title}>{props.title}</StackItem>
        </Stack>
    );
}
