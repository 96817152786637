import * as React from "react";
import { intl } from "lcmd2framework";

import { useOnInputChange } from "../../../hooks/use-on-input-change";
import { useCallback } from "react";

import { SearchBox } from "@fluentui/react";

export type SearchProps = {
    value: string;
    onChange: (value: string) => void;
};

function Search(props: SearchProps) {
    const { value } = props;

    // When inside SearchBox click on clear clear button that action set value as undefined
    // To fix it add default value empty string ''
    const onSearchBoxInputChange = useCallback(
        (value: string = "") => {
            return props.onChange(value);
        },
        [props.onChange],
    );

    const onChange = useOnInputChange(onSearchBoxInputChange);

    return (
        <SearchBox
            autoComplete="off"
            placeholder={intl.get("canvas.cmd.filter.cmd.search.placeholder")}
            value={value}
            onChange={onChange}
        />
    );
}

export { Search };
