import * as React from "react";
import { useContext, useMemo, useReducer, useState } from "react";
import { DigitalPlanningBoardSelectionEvent, intl } from "lcmd2framework";
import { IconButton, Label, mergeStyleSets, Stack } from "@fluentui/react";
import { useLCMD } from "../../../app/LCMContext";
import { createMemoryRouter, RouteObject, RouterProvider, useNavigate } from "react-router-dom";
import { HistoryDetails } from "../../ChangesPanel/Routes/HistoryDetails/HistoryDetails";
import { ChangesPanelInitialState, ChangesPanelReducer, CPContext } from "../../ChangesPanel/Shared/CPContext";
import { SidebarContext } from "../../../app/components/SidebarHost";
import { WebAppTelemetryFactory } from "../../../app/services/WebAppTelemetry.service";
import { Notification, NotificationTarget } from "../../../core/Notification/NotificationService";
import { SidebarHistoryView } from "./SidebarHistoryView";

const styles = mergeStyleSets({
    topContent: {
        borderBottom: "1px solid #E1E4E5",
    },
    label: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "24px",
        /* identical to box height, or 133% */
        letterSpacing: "-0.02em",
    },
});

/*

    todo: change API to use useProcessHistoryEffect instead of worker
    todo: show shimmer when loading data (on selection change)
    todo: implement comment section

 */

type HistoryNavigationHeader = {
    label: string;
    withBackButton?: boolean;
};

export function HistoryNavigationHeader({ label, withBackButton = false }: HistoryNavigationHeader) {
    const navigate = useNavigate();
    return (
        <>
            <Stack className={styles.topContent} horizontal={true}>
                {withBackButton ? (
                    <IconButton
                        styles={{ root: { color: "#565C60" } }}
                        iconProps={{ iconName: "ChevronLeft" }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    />
                ) : null}
                <Stack tokens={{ padding: "0 0 17px 20px" }}>
                    <Label className={styles.label}>{label}</Label>
                </Stack>
            </Stack>
        </>
    );
}

export function HistoryView() {
    const [historyEvents, setHistoryEvents] = useState([]);
    const [commentDisabled, setCommentDisable] = useState(true);
    const [cpState, dispatch] = useReducer(ChangesPanelReducer, ChangesPanelInitialState());

    const propsContext = useContext(SidebarContext);
    const [collaboratorsUsers, setCollaboratorsUsers] = useState([]);

    const LCMD = useLCMD();
    const [selectedProcesses, setSelectedProcesses] = React.useState<DigitalPlanningBoardSelectionEvent>(null);

    LCMD.useProjectCollaboratorsEffect(
        null,
        (error, facepilePersonas, project) => {
            if (error) {
                console.log("error", error);
            } else {
                setCollaboratorsUsers(facepilePersonas);
            }
        },
        [],
    );

    const routes: RouteObject[] = [
        {
            path: "/sidebar-history-view",
            element: (
                <SidebarHistoryView
                    historyEvents={historyEvents}
                    setCommentDisable={setCommentDisable}
                    selectedProcesses={selectedProcesses}
                    createNotification={createNotification}
                    commentDisabled={commentDisabled}
                />
            ),
            loader: () => {
                WebAppTelemetryFactory.trackEvent("process-history-view");
                return true;
            },
        },
        {
            path: "/history-details",
            element: (
                <>
                    <HistoryNavigationHeader label={intl.get("HistoryView.HistoryLabelText")} withBackButton={true} />
                    <HistoryDetails showHeader={false} height={"71vh"} />
                </>
            ),
            loader: () => {
                WebAppTelemetryFactory.trackEvent("detail-view-of-process-history");
                return true;
            },
        },
    ];

    const router = useMemo(() => {
        return createMemoryRouter(routes, {
            initialEntries: ["/sidebar-history-view"],
            initialIndex: 1,
        });
    }, [historyEvents, collaboratorsUsers]);

    propsContext.sidebarCtx.useSelectionEffect(
        (ev: DigitalPlanningBoardSelectionEvent) => {
            const currentValues = selectedProcesses ? [...selectedProcesses.pid] : [];
            const newValues = [...ev.pid];
            if (currentValues.sort().toString() !== newValues.sort().toString()) {
                setSelectedProcesses(ev);
            }
            setCommentDisable(ev?.pid?.length !== 1);
        },
        [setSelectedProcesses, setCommentDisable, commentDisabled, selectedProcesses],
    );

    LCMD.useProcessHistoryEffect(
        selectedProcesses?.pid,
        (error, data) => {
            if (error) {
                console.log("Error loading process history", error);
            }
            const events = data || [];
            setHistoryEvents(events);
        },
        [setHistoryEvents],
        propsContext.ppTS,
    );

    async function createNotification(
        comment: string,
    ): Promise<{ fromUserId: string; users: string[]; notification: Notification }> {
        const getProcessNamePromise: Promise<string> = new Promise((resolve, reject) => {
            LCMD.getProcessDetails(selectedProcesses.pid[0], (error, data) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(data?.name?.value);
                }
            });
        });

        const getProjectPromise = (): Promise<{ name: string; id: string }> => {
            return new Promise((resolve, reject) => {
                LCMD.getProjectDetails((error, data) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve({
                            name: data.meta?.name,
                            id: data.pid,
                        });
                    }
                });
            });
        };

        const authUserIdPromise: Promise<string> = new Promise((resolve, reject) => {
            LCMD.getAuthUser((error, user) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(user.sub);
                }
            });
        });

        const fromUserId = await authUserIdPromise;
        const users = extractIdsFromCommment(comment);
        const projectData = await getProjectPromise();
        const projectId = projectData.id;

        const notification: Notification = {
            type: "info",
            target: [NotificationTarget.Email],
            meta: {
                processid: selectedProcesses.pid[0].toString(),
                processname: await getProcessNamePromise,
                projectname: projectData.name,
                confirmationUrl: projectId,
            },
        };
        return {
            fromUserId,
            users,
            notification,
        };
    }

    function extractIdsFromCommment(text: string) {
        const regex = /\${(\d+)}/g;
        const ids = [];
        let match;

        while ((match = regex.exec(text)) !== null) {
            ids.push(parseInt(match[1]));
        }

        return ids;
    }

    return (
        <div className="h-full">
            <CPContext.Provider value={{ state: cpState, dispatch }}>
                <React.StrictMode>
                    <RouterProvider router={router} />
                </React.StrictMode>
            </CPContext.Provider>
        </div>
    );
}
