import { getCurrentLocale } from "@/components/utils/date/locale";

/**
 * Check if a Date is older than given days
 *
 * @param then
 * @param days
 * @todo write test
 */
function isOlderThanXDays(then: Date, days: number) {
    const daysInMilliseconds = convertDaysToMilliseconds(days);
    const now = Date.now();
    return now - then.getTime() > daysInMilliseconds;
}

/**
 * Returns time difference in Days
 * Does not recognise weekdays nor weekend
 * @param date1
 * @param date2
 */
export function getTimeDifferenceInDays(date1: Date, date2: Date): number {
    const millisecondsPerDay = 86400000;
    const timeDifferenceInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    const daysDifference = Math.floor(timeDifferenceInMilliseconds / millisecondsPerDay);
    return daysDifference;
}

/**
 * Converts Days into Milliseconds
 *
 * @param days (Epoch Days)
 * @todo write test
 */
function convertDaysToMilliseconds(days: number) {
    return days * (86_400 * 1_000);
}

/**
 * Converts Milliseconds into Days
 *
 * @param milliseconds (Epoch Milliseconds)
 */
function convertMillisecondsToDays(milliseconds: number) {
    return Math.floor(milliseconds / (86_400 * 1_000));
}

let weekStart = 1;
getWeekStart();
if (globalThis.addEventListener) {
    addEventListener("languagechange", () => {
        getWeekStart();
    });
}
function getWeekStart() {
    if (weekStart == null || typeof navigator === "undefined") {
        return;
    }

    const currentLocal = new Intl.Locale(navigator?.language || getCurrentLocale().code);
    if (!("weekinfo" in currentLocal)) {
        weekStart = 1;
        return;
    }

    // @ts-ignore
    weekStart = new Intl.Locale(navigator?.language || getCurrentLocale().code)?.weekInfo?.firstDay;
}

export { isOlderThanXDays, convertDaysToMilliseconds, convertMillisecondsToDays, weekStart };
