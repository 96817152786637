export function decimalToHex(decimal) {
    // Convert decimal to hexadecimal
    let hex = decimal.toString(16);

    // Pad with zeros to ensure the hexadecimal value has 6 digits
    while (hex.length < 6) {
        hex = "0" + hex;
    }

    // Add a # symbol
    hex = "#" + hex;

    return hex;
}
