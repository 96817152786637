import { useFilterData } from "./use-filter-data";

export function useFilterCollection() {
    const { data, status, refresh } = useFilterData();

    return {
        items: data?.filterCollection || [],
        selectedFilterId: data?.filter?.saveAs?.hid,
        status,
        refresh
    };
}
