import * as React from "react";

import { Stack } from "@fluentui/react";

import { decimalToRgb } from "../../../../utils/color/decimal-to-rgb";
import { TradeLabel } from "../../../../common/trade/TradeLabel";

type TradesListProps = {
    trades: { trid: string; color: number; name: string }[];
};

export function TradesList({ trades }: TradesListProps) {
    return (
        <Stack>
            {trades.map((trade) => {
                return <TradeLabel key={trade.trid} color={decimalToRgb(trade.color)} name={trade.name} />;
            })}
        </Stack>
    );
}
