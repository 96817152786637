﻿import { JSX } from "react";
import logo from "../../../assets/logo.svg";
import video from "../../../assets/background-video.mp4";
import "./MainLayout.css";
import { intl } from "lcmd2framework";

export type MainLayoutProps = {
    children: JSX.Element | JSX.Element[];
};

export function MainLayout({ children }: MainLayoutProps) {
    return (
        <div className="flex h-screen items-stretch justify-center">
            <div className="flex grow justify-center p-16">
                <div className="flex flex-col gap-16" style={{ width: "393px" }}>
                    <div className="flex flex-col">
                        <img width="232" src={logo} alt="LCMD logo" />
                    </div>

                    <div className="flex grow flex-col justify-center px-8 py-4">{children}</div>
                </div>
            </div>
            <div className="relative hidden lg:block" style={{ width: "65%" }}>
                <video src={video} autoPlay muted loop style={{ height: "100%", objectFit: "cover" }}></video>
                <div className="video-overlay"></div>
                <div className="video-text text-white">{intl.get("LcmdLogin.MainLayout.VideoText")}</div>
            </div>
        </div>
    );
}
