import { Loader2, RefreshCw } from "lucide-react";
import { Button, ButtonProps } from "@/components/ui/button";

interface SpinnerButtonProps extends ButtonProps {
    isLoading: boolean;
    onClick: () => void;
}

export const SyncedSpinnerButton = ({ isLoading, onClick: handleClick }: SpinnerButtonProps) => {
    if (isLoading) {
        return (
            <Button onClick={handleClick} className="p-0 w-10 self-center">
                <RefreshCw className="h-6 w-6" strokeWidth="1" />
            </Button>
        );
    }
    return (
        <div className="p-0 w-10 flex justify-center items-center">
            <Loader2 strokeWidth="1" className="h-6 w-6 animate-spin" />
        </div>
    );
};
