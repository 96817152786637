import * as React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Eye, EyeOff, Settings2 } from "lucide-react";
import { SearchBar } from "@/components/common/SearchBar/SearchBar";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";
import { IconButton } from "@/components/common/IconButton/IconButton";

type HeadActionPanel = {
    exportToDo: () => void;
    exportOnProcess: boolean;
    filterDataIsEmpty: boolean;
    initSearchText: string;
    onChangeSearchText: (value: string) => void;
    onOpenModalFilter: () => void;
    onChangeAdaptTableToContent: (value: boolean) => void;
};

export function ActionPanel({
    exportToDo,
    exportOnProcess,
    filterDataIsEmpty,
    initSearchText,
    onChangeSearchText,
    onOpenModalFilter,
    onChangeAdaptTableToContent,
}: HeadActionPanel) {
    const [adaptTableToContent, setAdaptTableToContent] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState(initSearchText);

    useEffect(() => {
        setSearchValue(initSearchText);
    }, [initSearchText]);

    function onFilter(searchText?: string): void {
        onChangeSearchText(searchText);
    }

    const viewButton = adaptTableToContent ? EyeOff : Eye;

    return (
        <div className={"TH2"} style={{ padding: "10px 0", left: 0, top: 44, height: 44 }}>
            <div className={"TH2L"}>
                <div className={"searchaction"} style={{ position: "relative" }}>
                    <SearchBar
                        onSearch={onFilter}
                        value={initSearchText}
                        onChange={(event, newValue) => {
                            setSearchValue(newValue);
                        }}
                    />
                </div>
            </div>
            <div className={"TH2R"}>
                <Button
                    variant={"secondary"}
                    disabled={exportOnProcess}
                    onClick={exportToDo}
                    userflowId="td-excel-export"
                >
                    {exportOnProcess ? (
                        <>
                            <span>{intl.get("ToDo.exportButton.text") + " ..."}</span>
                            <Spinner size={SpinnerSize.small} />
                        </>
                    ) : (
                        <span>{intl.get("ToDo.exportButton.text")}</span>
                    )}
                </Button>
                <LCMDTooltip text={intl.get("ToDo.buttonAdaptTableToContent")} className={"z-50"}>
                    <IconButton
                        icon={viewButton}
                        onClick={() => {
                            setAdaptTableToContent((adaptTableToContent) => {
                                onChangeAdaptTableToContent(!adaptTableToContent);
                                return !adaptTableToContent;
                            });
                            //setColumns(getDefaultColumns(checked))
                        }}
                    />
                </LCMDTooltip>

                <Button
                    onClick={onOpenModalFilter}
                    variant={"secondary"}
                    className={!filterDataIsEmpty ? "bg-gray-300 hover:bg-gray-300" : ""}
                >
                    <Settings2 className={"mr-2"} />
                    Filter
                </Button>
            </div>
        </div>
    );
}
