import * as React from "react";
import { intl } from "../../legacy/GlobalHelperReact";
import { LCMDContextType } from "../../app/LCMContext";
import { Button } from "../ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

type TradeFieldProps = {
    LCMD;
    data;
    onChangeTrade;
    disabled;
};

export function TradeField({ LCMD, data, onChangeTrade, disabled }: TradeFieldProps) {
    const _trades = data.td._trades || [];
    const trades = _trades.map((t) => t.name || t.trade).join(", ");

    function handleTradeFieldClick(this: LCMDContextType, data, onChangeTrade) {
        const opt = {
            tid: data.td.id,
            trades: (data.td._trades || []).map((trade) => trade.id),
        };
        if (onChangeTrade) {
            onChangeTrade(opt);
        } else {
            this.worker.dispatchMessage(["framework", "trade", opt]);
        }
    }

    return (
        <div className="w-[120px]">
            <div className="py-1 leading-none">{intl.get("tasks.process.trade.label")}</div>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            variant="outline"
                            disabled={disabled}
                            className="w-full justify-start overflow-hidden text-ellipsis"
                            onClick={handleTradeFieldClick.bind(LCMD, data, onChangeTrade)}
                        >
                            {trades}
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>{trades}</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
}
