import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    suffix?: JSX.Element | string;
    dropdown?: JSX.Element;
    suffixClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, type, suffix, style, dropdown, suffixClassName, ...props }, ref) => {
        const hasSuffix = dropdown ? dropdown : suffix;

        return (
            <>
                {hasSuffix ? (
                    <div
                        className="flex h-10 items-center justify-center rounded-md border border-input bg-transparent ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-2"
                        data-disabled={props.disabled}
                        style={style}
                    >
                        <input
                            type={type}
                            className={cn(
                                "flex h-full w-full rounded-md border-none bg-transparent p-2 text-sm shadow-none outline-none file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:border-none focus-visible:shadow-none focus-visible:outline-none disabled:bg-[#efefef4d]",
                                className,
                            )}
                            ref={ref}
                            {...props}
                        />
                        {suffix ? (
                            <div
                                className={cn(
                                    "h-full rounded-r-md bg-zinc-100 px-3 py-2 text-muted-foreground",
                                    suffixClassName,
                                )}
                            >
                                {suffix}
                            </div>
                        ) : (
                            <div className={cn("flex h-full items-center rounded-r-md px-3 py-2")}>{dropdown}</div>
                        )}
                    </div>
                ) : (
                    <input
                        type={type}
                        className={cn(
                            "flex h-10 w-full rounded-md border border-input bg-transparent px-4 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:bg-[#efefef4d]",
                            className,
                        )}
                        ref={ref}
                        style={style}
                        {...props}
                    />
                )}
            </>
        );
    },
);
Input.displayName = "Input";

export { Input };
