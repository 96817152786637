﻿import { SERVICES } from "@/model/services";
import { RestApiClient } from "./RestApiClient.service";

export class AuthClient {
    public static async createUser(data: {
        email: string;
        password: string;
        firstName: string;
        lastName: string;
    }): Promise<void> {
        const stagingHash = AuthClient.getStagingHash();
        const response = await RestApiClient.post(`${SERVICES.REST_API}/users`, {
            ...data,
            stagingHash,
        });

        if (response.status !== 201) {
            throw new HttpError(response.status);
        }
    }

    public static async confirmUser(token: string): Promise<AuthResult> {
        const response = await RestApiClient.post<AuthResult>(`${SERVICES.REST_API}/users/confirm`, {
            token,
        });

        if (response.status === 200) {
            return response.data;
        }

        if (response.status === 409) {
            throw new UserAlreadyConfirmedError();
        }

        throw new HttpError(response.status);
    }

    public static async isUserRegistered(email: string): Promise<boolean> {
        const response = await RestApiClient.post<{ userExists: boolean }>(`${SERVICES.REST_API}/users/exists`, {
            email,
        });

        if (response.status === 200) {
            return response.data.userExists;
        }

        throw new HttpError(response.status);
    }

    public static async login(email: string, password: string): Promise<AuthResult> {
        const response = await RestApiClient.post<AuthResult>(`${SERVICES.REST_API}/users/login`, {
            email,
            password,
        });

        if (response.status !== 200) {
            throw new HttpError(response.status);
        }

        switch (response.data.loginResult) {
            case "success":
                return response.data;
            case "invalidCredentials":
                throw new InvalidCredentialsError();
            case "notConfirmed":
                throw new UserNotConfirmedError();
            default:
                throw new Error(`Unknown login result '${response.data.loginResult}'`);
        }
    }

    public static async loginWithSSO(accessToken: string): Promise<SsoAuthResult> {
        const response = await RestApiClient.post<SsoAuthResult>(`${SERVICES.REST_API}/users/loginWithSSO`, {
            accessToken,
        });

        if (response.status !== 200) {
            throw new HttpError(response.status);
        }

        switch (response.data.loginResult) {
            case "success":
                return response.data;
            case "invalidToken":
                throw new InvalidTokenError();
            default:
                throw new Error(`Unknown login result '${response.data.loginResult}'`);
        }
    }

    public static async requestPasswordReset(email: string): Promise<void> {
        const stagingHash = AuthClient.getStagingHash();
        await RestApiClient.post(`${SERVICES.REST_API}/users/password/requestReset`, {
            email,
            stagingHash,
        });
    }

    public static async resetPassword(token: string, newPassword: string): Promise<AuthResult> {
        const response = await RestApiClient.post<AuthResult & { error?: string }>(
            `${SERVICES.REST_API}/users/password/reset`,
            {
                token,
                newPassword,
            },
        );

        if (response.status === 401 && response.data.error === "TokenExpired") {
            throw new TokenExpiredError();
        }

        if (response.status !== 200) {
            throw new HttpError(response.status);
        }

        return response.data;
    }

    public static async resendWelcomeEmail(email: string): Promise<void> {
        const stagingHash = AuthClient.getStagingHash();
        await RestApiClient.post(`${SERVICES.REST_API}/users/resendWelcomeEmail`, {
            email,
            stagingHash,
        });
    }

    private static getStagingHash(): string | null {
        if (!location.pathname) {
            return null;
        }

        const match = location.pathname.match(/^\/index_([a-z0-9]{8}).html$/);
        return match != null ? match[1] : null;
    }
}

type AuthResult = {
    loginResult: "success" | "invalidCredentials" | "notConfirmed";
    sub: string;
    authToken: string;
};

type SsoAuthResult = {
    loginResult: "success" | "invalidToken";
    sub: string;
    authToken: string;
};

export class HttpError extends Error {
    constructor(public status: number) {
        super();
        this.name = "HttpError";
    }
}

export class InvalidCredentialsError extends Error {}

export class UserNotConfirmedError extends Error {}

export class UserAlreadyConfirmedError extends Error {}

export class TokenExpiredError extends Error {}

export class InvalidTokenError extends Error {}
