﻿import { useLCMD } from "../LCMContext";
import {
    ContextualMenu,
    DialogType,
    IconButton,
    IContextualMenuProps,
    IIconProps,
    mergeStyleSets,
} from "@fluentui/react";
import { useMemo, useState } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { AlertDialog } from "@/components/common/AlertDialog/AlertDialogV2";

export interface IHeaderMetaComponentProps {
    metaData?: {
        readonly?: boolean;
        name?: string;
    };
}

export function HeaderMetaComponent({ metaData }: IHeaderMetaComponentProps) {
    const LCMD = useLCMD();
    const readonly = !!metaData?.readonly;
    const [showLeaveProjectDialog, setShowLeaveProjectDialog] = useState(false);

    function iframeActions(evt) {
        //self postMessage triggers event too
        let message;

        // if (evt.origin !== "http://localhost:63342") {
        //     message = "You are not worthy";
        // } else {

        switch (evt?.data.action) {
            case "goToProcess": {
                LCMD.executeCommand({
                    cmd: "goto",
                    pid: [evt?.data?.processId],
                });
            }
            default:
                return;
        }

        // }
    }

    window.addEventListener("message", iframeActions, false);

    const moreIcon: IIconProps = {
        iconName: "MoreVertical",
    };

    const classNames = mergeStyleSets({
        label: {
            fontFamily: "Inter, sans-serif",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 15,
        },
    });

    const contextMenu = useMemo(
        () =>
            ({
                items: [
                    {
                        key: "clone",
                        //disabled: isCanary()?false:true, // https://lcmexecute.atlassian.net/browse/LCM2-2024: Deactivate cloning of projects
                        iconProps: { iconName: "Copy" },
                        onClick: () => {
                            LCMD.showDialog("fw.project.clone");
                        },
                    },
                    {
                        key: "share",
                        iconProps: { iconName: "Relationship" },
                        onClick: () => {
                            LCMD.showDialog("fw.project.share");
                        },
                    },
                    {
                        key: "props",
                        iconProps: { iconName: "Settings" },
                        onClick: () => {
                            LCMD.showDialog("dialog.project.properties", true);
                        },
                    },
                    {
                        key: "unlink",
                        iconProps: { iconName: "delete" },
                        // for new redesigned modal
                        // onClick: () => setShowLeaveProjectDialog(true),
                        onClick: () => {
                            LCMD.showDialog("fw.alert", {
                                dialogContentProps: {
                                    type: DialogType.normal,
                                    title: intl.get("overview.cmd.unlink.alert.title"),
                                    subText: intl.get("overview.cmd.unlink.alert.subText"),
                                },
                                onOK: () => {
                                    LCMD.showDialog("fw.alert", null);
                                    LCMD.showDialog("fw.project.unlink"); // fix me...
                                },
                                onCancel: () => {
                                    LCMD.showDialog("fw.alert", null);
                                },
                            });
                        },
                    },
                ],
            }) as IContextualMenuProps,
        [LCMD],
    );

    const _contextMenu = useMemo(
        () => ({
            ...contextMenu,
            items: contextMenu.items.map((e) => ({
                ...e,
                text: intl.get(["overview.cmd", e.key, "text"].join(".")),
                title: intl.get(["overview.cmd", e.key, "title"].join(".")),
                disabled: e.disabled || (readonly && "unlink" !== e.key),
            })),
        }),
        [metaData],
    );

    return metaData ? (
        <>
            <div className={classNames.label}>{metaData?.name || ""}</div>
            <IconButton
                styles={{
                    root: {
                        color: "white",
                    },
                    rootDisabled: {
                        backgroundColor: "transparent",
                    },
                }}
                menuProps={_contextMenu}
                iconProps={moreIcon}
                data-selection-disabled={true}
                data-is-focusable={false}
                split={false}
                onRenderMenuIcon={() => null}
                menuAs={(menuProps: IContextualMenuProps) => {
                    const menuPropsBound = Object.assign({}, menuProps, {
                        items: menuProps.items.map((entry) =>
                            Object.assign({}, entry, {
                                //onClick: onContextMenu.bind(this, selection, item)
                            }),
                        ),
                    });
                    return <ContextualMenu {...menuPropsBound} />;
                }}
            />
            {showLeaveProjectDialog && (
                <AlertDialog
                    open={showLeaveProjectDialog}
                    onOpenChange={() => setShowLeaveProjectDialog(false)}
                    title={intl.get("overview.cmd.unlink.alert.title")}
                    subText={intl.get("overview.cmd.unlink.alert.subText")}
                    onOk={() => {
                        LCMD.showDialog("fw.alert", null);
                        LCMD.showDialog("fw.project.unlink");
                    }}
                />
            )}
        </>
    ) : null;
}
