import * as React from "react";
import { Dialog, DialogScreen } from "lcmd2framework";
import { mergeStyleSets, TextField } from "@fluentui/react";
import { LCMDContextType, useLCMD } from "../../app/LCMContext";

/*
 *
 * THIS IS FOR DEBUGGING AT THE MOMENT
 *
 */

const classNames = mergeStyleSets({
    main: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flexShrink: 1,
        paddingLeft: 20,
        paddingRight: 20,
        height: "100%",
    },
});

function MySpan(props: { text: string; em?: boolean; default?: string; suffix?: string }) {
    const span = props.text || !props.default ? <span>{props.text}</span> : <i>{props.default}</i>;
    return props.suffix ? (
        <>
            {span}
            {props.suffix}
        </>
    ) : (
        span
    );
}

function MyDate(props: { d: number }) {
    return <span>{props.d ? new Date(props.d).toUTCString() : "?"}</span>;
}

function ProcessSpan(props: {
    data: {
        searchPid?: number;
    };
    pid: number;
    name: string;
    suffix?: string;
}) {
    const span = (
        <span style={{ fontWeight: props.data.searchPid === props.pid ? "bold" : undefined }}>
            #{props.pid.toString(10)} <MySpan text={props.name} default="name not set" />
        </span>
    );
    return props.suffix ? (
        <>
            {span}
            {props.suffix}
        </>
    ) : (
        span
    );
}

function ProcessResult(props: {
    data: {
        searchPid?: number;
    };
    item: {
        pid: number;
        isDeleted?: boolean;
        p?: {
            v: number;
            u: string;
            d: number;
        };
        name?: {
            v: string;
            u: string;
            d: number;
        };
        parents?: {
            id: number;
            name: string;
        }[];
        children?: {
            pid: number;
            name: string;
        }[];
    };
}) {
    return (
        <div
            style={{
                marginTop: 10,
                textIndent: "-1em",
                marginLeft: "1em",
            }}
        >
            <span
                style={{
                    fontWeight: props.data.searchPid === props.item.pid ? "bold" : undefined,
                    textDecoration: props.item.isDeleted ? "line-through" : undefined,
                }}
            >
                Process #<span>{props.item.pid}</span>: <MySpan text={props.item.name?.v} default={"name not set"} />
            </span>
            <br />
            {props.item.isDeleted && props.item.p?.v < 0 ? (
                <>
                    <span>
                        Deleted by <LCMDContextType.Sub sub={props.item.p.u} /> at <MyDate d={props.item.p.d} />
                    </span>
                    <br />
                </>
            ) : undefined}
            <span>
                Areas:{" "}
                {(props.item.parents || []).map((item, i, a) => (
                    <ProcessSpan
                        pid={item.id}
                        name={item.name}
                        suffix={i + 1 < a.length ? ", " : undefined}
                        data={props.data}
                    />
                ))}
            </span>
            <br />
            {Array.isArray(props.item.children) ? (
                <span>
                    Children:{" "}
                    {(props.item.children || []).map((item, i, a) => (
                        <ProcessSpan
                            pid={item.pid}
                            name={item.name}
                            suffix={i + 1 < a.length ? ", " : undefined}
                            data={props.data}
                        />
                    ))}
                </span>
            ) : undefined}
        </div>
    );
}

function MainScreen({
    dialog,
    route: {
        params: {},
    },
}) {
    const LCMD = useLCMD();
    const [findValue, setFindValue] = React.useState<string>("");
    const [data, setData] = React.useState<any>({});
    React.useEffect(() => {
        const onCancel = () => {
            LCMD.showDialog("dialog.find", false);
        };
        dialog.setOptions({
            title: "Find",
            onOK: undefined,
            onCancel: undefined,
            onClose: onCancel,
        });
    }, [LCMD]);
    LCMD.useFindEffect(
        findValue,
        (data) => {
            console.log("FOUND: " + data.findText);
            setData(data);
        },
        [LCMD, setData],
    );
    return (
        <div className={classNames.main}>
            <TextField
                placeholder="Find..."
                value={findValue}
                onChange={(ev, value) => {
                    setFindValue(value);
                }}
            />
            <div style={{ flexGrow: 1, flexShrink: 1, flexBasis: "auto", overflow: "overlay" }}>
                {(data?.processes || []).map((item) => (
                    <div style={{ whiteSpace: "pre" }}>
                        <ProcessResult key={item.pid} data={data} item={item} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export function ProjectFindDialog(props: {}) {
    return (
        <Dialog
            initialScreen={(dialog) => {
                dialog.pushScreen("main", {});
            }}
            isOpen={true}
        >
            <DialogScreen name="main" component={MainScreen} />
        </Dialog>
    );
}
