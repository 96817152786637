import { useEffect, useState } from "react";
import { useLCMD } from "@/app/LCMContext";
import { models } from "powerbi-client";
import { LCMDHookResult } from "@/app/types/LCMDHookResult";

type IReportEmbedConfiguration = models.IReportEmbedConfiguration;

type ReportConfigResult = {
    accessToken: string;
    embedUrl: EmbedUrl[];
    expiry: string;
    status: number;
};

type EmbedUrl = {
    reportId: string;
    reportName: string;
    embedUrl: string;
};

function getReportConfig(reportConfigResult: ReportConfigResult): IReportEmbedConfiguration {
    return {
        type: "report",
        embedUrl: reportConfigResult.embedUrl[0].embedUrl,
        tokenType: models.TokenType.Embed,
        accessToken: reportConfigResult.accessToken,
        settings: undefined,
    };
}

export function usePowerBiDashboard() {
    const LCMD = useLCMD();
    const [projectId, setProjectId] = useState<string | undefined>(undefined);

    const [hookResult, setHookResult] = useState<
        LCMDHookResult<{ isPowerBIEnabled: boolean; reportConfig: IReportEmbedConfiguration }>
    >({
        isLoading: true,
        isError: false,
        error: undefined,
        data: null,
    });

    useEffect(() => {
        LCMD.getProjectDetails((error, data) => {
            setProjectId(data.pid);
        });
    }, []);

    useEffect(() => {
        if (!projectId) {
            return;
        }
        let canceled = false;
        const abortController = new AbortController();

        async function fetchConfig() {
            try {
                const fetchResult = await fetch(
                    `https://lcmd-rest-api.azurewebsites.net/api/dashboards/${projectId}/config`,
                    {
                        headers: {
                            Authorization: `Bearer ${LCMD.worker.auth.auth_token}`,
                        },
                        signal: abortController.signal,
                    },
                );
                const config = fetchResult.status === 200 ? getReportConfig(await fetchResult.json()) : null;

                if (!canceled) {
                    setHookResult({
                        isLoading: false,
                        isError: false,
                        error: null,
                        data: { isPowerBIEnabled: fetchResult.status === 200, reportConfig: config },
                    });
                }
            } catch (e) {
                setHookResult({ isLoading: false, isError: true, data: null, error: e });
            }
        }

        fetchConfig();

        return () => {
            canceled = true;
            abortController.abort();
        };
    }, [projectId]);

    return hookResult;
}
