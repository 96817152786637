import { ComboBox, Stack, StackItem } from "@fluentui/react";
import { useEffect, useState } from "react";
import * as React from "react";
import { paperSizes, paperSizeOptions, getPaperAlignmentOptions } from "./paperSize";
import { intl } from "@/legacy/GlobalHelperReact";

export const PaperSizeSelector = ({ onChange }: { onChange: (pdfExportSize: any) => void }) => {
    const [paperSize, setPaperSize] = useState("A4");
    const [alignment, setAlignment] = useState("P");

    useEffect(() => {
        const label = `${paperSize}${alignment}`;
        const paperSizeObj = paperSizes.find((obj) => {
            return obj.label === label;
        });
        let pdfExportSize = {};
        if (paperSizeObj) {
            pdfExportSize = {
                width: paperSizeObj.width,
                height: paperSizeObj.height,
                colsPerPageSize: paperSizeObj.colsPerPageSize,
            };
        }
        onChange(pdfExportSize);
    }, [paperSize, alignment]);

    return (
        <Stack tokens={{ childrenGap: "25px" }}>
            <StackItem>
                <ComboBox
                    label={intl.get("pdf.export.paperSizeSelector.size")}
                    defaultSelectedKey={paperSize}
                    onChange={(ev, newValue) => {
                        setPaperSize(newValue.key.toString());
                    }}
                    options={paperSizeOptions}
                />
            </StackItem>
            <StackItem>
                <ComboBox
                    label={intl.get("pdf.export.paperSizeSelector.alignment")}
                    defaultSelectedKey={alignment}
                    onChange={(ev, newValue) => {
                        setAlignment(newValue.key.toString());
                    }}
                    options={getPaperAlignmentOptions()}
                />
            </StackItem>
        </Stack>
    );
};
