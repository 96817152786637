import * as React from "react";
import { mergeStyleSets, Text, ITextProps } from "@fluentui/react";

export type TruncatedTextProps = ITextProps & {
    text: string;
};

const classNames = mergeStyleSets({
    truncatedText: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
});

function TruncatedText(props: TruncatedTextProps) {
    const { text, className, ...restProps } = props;

    const componentClassNames = [classNames.truncatedText];

    if (className) {
        componentClassNames.push(className);
    }
    return (
        <Text className={componentClassNames.join(" ")} {...restProps}>
            {text}
        </Text>
    );
}

export { TruncatedText };
