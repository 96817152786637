import React from "react";

function Achtung() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" fill="none" viewBox="0 0 18 15">
            <path
                fill="#D83B01"
                d="M7.686.852L1.127 12.777A1.5 1.5 0 002.442 15h13.117a1.5 1.5 0 001.314-2.223L10.315.852a1.5 1.5 0 00-2.629 0zM9 4.75a.75.75 0 01.75.75v4a.75.75 0 11-1.5 0v-4A.75.75 0 019 4.75zm.75 7a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            ></path>
        </svg>
    );
}

export default Achtung;
