import {
    AlertDialog as AlertModal,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { intl } from "@/legacy/GlobalHelperReact";
import { AlertDialogProps } from "@radix-ui/react-alert-dialog";

type AlertModalProps = AlertDialogProps & {
    title?: string;
    subText?: string;
    open: boolean;
    onOpenChange: () => void;
    cancelButtonText?: string;
    okButtonText?: string;
    onOk?: () => void;
};

export function AlertDialog({
    title,
    subText,
    okButtonText,
    cancelButtonText,
    onOk,
    onOpenChange,
    ...other
}: AlertModalProps) {
    return (
        <AlertModal onOpenChange={onOpenChange} {...other}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                    <AlertDialogDescription>{subText}</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>{cancelButtonText || intl.get("fw.cancel")}</AlertDialogCancel>
                    <AlertDialogAction
                        variant="destructive"
                        onClick={() => {
                            onOk();
                            onOpenChange();
                        }}
                    >
                        {okButtonText || intl.get("fw.ok")}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertModal>
    );
}
