import { useSearchParams } from "react-router-dom";
import { ForgotPasswordEmailForm } from "./ForgotPasswordEmailForm";
import { ForgotPasswordNewPasswordForm } from "./ForgotPasswordNewPasswordForm";

export type ForgotPasswordProps = {
    onLogin: (sub: string, authToken: string) => void;
};

export function ForgotPassword({ onLogin }: ForgotPasswordProps) {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const showEmailForm = token == null;

    return (
        <>
            {showEmailForm ? (
                <ForgotPasswordEmailForm />
            ) : (
                <ForgotPasswordNewPasswordForm token={token} onLogin={onLogin} />
            )}
        </>
    );
}
