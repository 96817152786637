﻿import { assert, ComplexProp, ComplexPropIter, Core, gfu2 } from "../../core/lcmd2core";

export class HiveGetter {
    private readonly _core: Core;
    private _path: string = null;
    private _r: any;

    constructor(core: Core | HiveGetter, path?: string | ComplexProp) {
        const _path = (path as ComplexProp)?._value || (path as string);
        if (core instanceof HiveGetter) {
            this._core = core._core;
            this.reset([core._path, _path].join("."));
        } else {
            this._core = core;
            this.reset(_path);
        }
    }

    public get path(): string {
        return this._path;
    }

    public set path(path: string) {
        this.reset(path);
    }

    public reset(path: string): HiveGetter {
        const m = this._core._model;
        const hive = m?.hive;
        const _path = (path || "").split(".");
        let r = hive;
        for (let i = 0; r && i < _path.length; i++) {
            r = r[_path[i]];
        }
        if (r) {
            this._path = path;
            this._r = r;
        } else {
            this._path = null;
            this._r = {};
        }
        return this;
    }

    public _(name: string | ComplexProp, v0?: number) {
        const _name = "string" === typeof name ? name : name._value;
        return this._core._model.OP_I(this._r[_name], v0);
    }

    public history(name: string | ComplexProp): number[] {
        const _name = "string" === typeof name ? name : name._value;
        return this._core._model.OPS_I(this._r[_name]);
    }

    public value<T>(name: string | ComplexProp, v0?: T): T {
        const m = this._core._model;
        const _name = "string" === typeof name ? name : name._value;
        assert("development" !== process.env.NODE_ENV || !_name.startsWith("_"));
        return m.VALUE<T>(this._r[_name], v0);
    }

    public aux<T>(name: string, v0?: T): T {
        assert("development" !== process.env.NODE_ENV || name.startsWith("_"));
        return gfu2(this._r[name], v0);
    }

    public getAllProps(): ComplexPropIter {
        return new ComplexPropIter(Object.getOwnPropertyNames(this._r));
    }
}
