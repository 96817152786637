import * as React from "react";

import { Link, Text } from "@fluentui/react";

import { ColorPoint } from "./color-point";

export type TitleTextProps = {
    text: string;
    pointColor?: string;
    onClick?: () => void;
    userflowId?: string;
};

function TitleText(props: TitleTextProps) {
    const { text, pointColor, userflowId } = props;

    return (
        <Text variant="mediumPlus">
            {pointColor && <ColorPoint color={pointColor} />}
            {props.onClick ? (
                <Link onClick={props.onClick} data-userflow-id={userflowId}>
                    {text}
                </Link>
            ) : (
                text
            )}
        </Text>
    );
}

export { TitleText };
