import * as React from "react";

import { ReactNode } from "react";

import { Stack, IStackTokens, mergeStyleSets } from "@fluentui/react";

export type OptionsProps = {
    gap?: number;
    children: ReactNode;
};

const MIN_WIDTH = 100;

const classNames = mergeStyleSets({
    options: {
        minWidth: MIN_WIDTH,
        overflow: "hidden",
    },
});

function Options(props: OptionsProps) {
    const { children, gap } = props;

    const tokensProps: IStackTokens = {
        childrenGap: gap,
    };

    return (
        <Stack horizontal verticalAlign="center" tokens={tokensProps} className={classNames.options}>
            {children}
        </Stack>
    );
}

Options.defaultProps = {
    gap: 10,
};

export { Options };
