import { mergeStyleSets } from "@fluentui/react";

export const styles = mergeStyleSets({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        width: "90%",
        position: "relative",
        minHeight: 600,
        maxWidth: 920,
        marginBottom: 60,
    },
    footer: {
        display: "flex",
        padding: "12px 24px",
        overflowY: "hidden",
        justifyContent: "space-between",
    },
    footer_left: {
        width: "50%",
    },
    footer_right: {
        width: "50%",
        display: "flex",
        justifyContent: "flex-end",
        alignContent: "center",
        alignItems: "center",
    },
    button_clear: {
        border: "none",
    },
    //trades, priority, status, responsible, date, todotype, raisedby
    body: {
        flex: "4 4 auto",
        overflowY: "hidden",
    },
    searchField: {
        padding: "8px 8px 0 8px",
    },
    defaultButton: {
        borderRadius: 7,
        margin: "0 8px 8px 0",
    },
    //date, todotype
    bodyPadding: {
        padding: "10px 24px 0 24px",
    },
    //overview
    emptyWrapper: {
        textAlign: "center",
    },
    emptyTitle: {
        fontSize: 16,
        fontWeight: 600,
        marginBottom: 15,
    },
    emptySVG: {
        margin: "20px 0",
    },
    bodyOverview: {
        flex: "4 4 auto",
        padding: 24,
        overflow: "auto",
        height: 438,
    },
    overviewDefaultButton: {
        margin: "0 8px 8px 0",
    },
});
