
import { useLCMD } from "@/app/LCMContext";
import { useState } from "react";

export type CanvasNavigation = ReturnType<typeof useCanvasNavigation>;

export function useCanvasNavigation() {
    const { worker } = useLCMD();
    const [navFunctions] = useState({
        goTo,
        goToAndScale,
        zoomIn,
        zoomOut
    });
    function goTo(pid: number){
        if (!worker) {
            return;
        }
        worker.dispatchMessage([
            "goto",
            {
                pid: pid,
                scale: undefined
            }
        ]);
    }
    function goToAndScale(pid: number, scale: number){
        if (!worker) {
            return;
        }
        worker.dispatchMessage([
            "goto",
            {
                pid: pid,
                scale: scale
            }
        ]);
    }
    function zoomIn (){
        if (!worker) {
            return;
        }
        worker.dispatchMessage(["toggle", "zoom", +100]);
    }
    function zoomOut () {
        if (!worker) {
            return;
        }
        worker.dispatchMessage(["toggle", "zoom", -100]);
    }

    return navFunctions;
}
