import * as React from "react";
import { useEffect, useState } from "react";
import { LcmdModal } from "../LcmModal";
import { useLCMD } from "../../../app/LCMContext";
import { intl } from "../../../legacy/GlobalHelperReact";
import { PrimaryButton } from "@fluentui/react";

export function DailyNews() {
    const LCMD = useLCMD();
    const [userId, setUserId] = useState<string>("");
    useEffect(() => {
        LCMD.getAuthUser((error, user) => {
            if (error) {
                return;
            }
            setUserId(user.sub);
        });
    }, []);

    const messageId = "2023-01-25";
    const endDate = new Date(2023, 0, 27);
    const currentDate = new Date();
    const isMessageOver = endDate < currentDate;

    const [isModalOpen, setModalOpen] = useState(true);

    const localStorageItem = localStorage.getItem(messageId) || false;
    const isMessageDisabled = localStorageItem && localStorageItem == userId;

    function hideModal() {
        setModalOpen(false);
    }

    function disablePopup() {
        if (userId == "") {
            return;
        }
        localStorage.setItem(messageId, userId);
    }

    const buttons = [
        <PrimaryButton
            style={{ marginRight: "17px" }}
            onClick={() => {
                disablePopup();
                hideModal();
            }}
        >
            {intl.get("DailyNews.okButton")}
        </PrimaryButton>,
    ];

    return (
        userId != "" &&
        !isMessageDisabled &&
        !isMessageOver && (
            <>
                <LcmdModal
                    isOpen={isModalOpen}
                    header={{ title: intl.get("DailyNews.modalHeadline") }}
                    isBlocking={true}
                    onDismiss={() => {
                        disablePopup();
                        hideModal();
                    }}
                    buttons={buttons}
                >
                    <div
                        style={{
                            padding: "25px",
                            paddingBottom: "95px",
                            maxWidth: "800px",
                            backgroundColor: "#F8FAFA",
                        }}
                    >
                        <div style={{ backgroundColor: "white", padding: "18px 16px 26px 16px", borderRadius: "4px" }}>
                            <h1 style={{ fontSize: "18px" }}>{intl.get("DailyNews.2023-01-25.headline")}</h1>
                            <p>{intl.getHTML("DailyNews.2023-01-25.newsText")}</p>

                            <p style={{ color: "#82878A" }}>{intl.get("DailyNews.2023-01-25.dateText")}</p>
                        </div>
                    </div>
                </LcmdModal>
            </>
        )
    );
}
