import * as React from "react";
import { useRef } from "react";

import { mergeStyleSets } from "@fluentui/react";

import { MilestonesChartCanvasProps, MilestonesChartCanvasState } from "./types";

import { MilestoneOverview } from "./MilestoneOverview";
import { headerHeight, lineHeight } from "./Utils";

const classNames = mergeStyleSets({
    overlay: {
        width: 170,
        position: "absolute",
        pointerEvents: "none",
        zIndex: 999,
    },
});

function isInArea(pointerPosition: number, elementSize: number, chartSize: number) {
    return chartSize - pointerPosition >= elementSize;
}

const PADDING_OVER_CURSOR = 5;

export function Overlay(this: MilestonesChartCanvasState, props: MilestonesChartCanvasProps) {
    const overlayRef = useRef<HTMLDivElement>(null);

    const _positions = props.overlayState.hover
        ? {
              left: props.overlayState.hover.canvasX * props.scale + PADDING_OVER_CURSOR,
              top: props.overlayState.hover.canvasY + PADDING_OVER_CURSOR,
          }
        : null;

    if (_positions && overlayRef.current) {
        const inAreaByX = isInArea(
            props.overlayState.hover.canvasX + PADDING_OVER_CURSOR,
            overlayRef.current.clientWidth,
            // QUESTION: how to get the chart dimensions more correctly?
            this.chart.clientWidth,
        );

        const inAreaByY = isInArea(
            props.overlayState.hover.canvasY + PADDING_OVER_CURSOR,
            overlayRef.current.clientHeight,
            // QUESTION: how to get the chart dimensions more correctly?
            this.chart.clientHeight,
        );

        if (!inAreaByX) {
            // PADDING_OVER_CURSOR + PADDING_OVER_CURSOR needs to compensate initial padding
            _positions.left -= overlayRef.current.clientWidth + PADDING_OVER_CURSOR + PADDING_OVER_CURSOR;
        }

        if (!inAreaByY) {
            // PADDING_OVER_CURSOR + PADDING_OVER_CURSOR needs to compensate initial padding
            _positions.top -= overlayRef.current.clientHeight + PADDING_OVER_CURSOR + PADDING_OVER_CURSOR;
        }
    }

    const _hoveredRow = props.overlayState.hover
        ? Math.floor((props.overlayState.hover.canvasY + props.canvasTop - headerHeight) / lineHeight)
        : null;
    const hoveredMilestone =
        null !== _hoveredRow && 0 <= _hoveredRow && _hoveredRow < props.data.milestones.length
            ? props.data.milestones[_hoveredRow]
            : null;

    if (false) {
        //console.log({...(props?.overlayState?.hover||{}), left: props.canvasLeft, ..._positions});
        //return <div style={{position: "absolute", width: 10, height: 10, backgroundColor: "yellow", zIndex: 200, left: overlayState.hover.canvasX*props.scale, top: overlayState.hover.canvasY, pointerEvents: "none" }}/>
    } else if (hoveredMilestone && hoveredMilestone.x >= 0) {
        const milestoneDate = props.grid.gridToDate(hoveredMilestone.x);
        const baseDate = hoveredMilestone.b >= 0 ? props.grid.gridToDate(hoveredMilestone.b) : null;
        return (
            <div ref={overlayRef} className={classNames.overlay} style={_positions}>
                <MilestoneOverview milestoneDate={milestoneDate} baselineDate={baseDate} milestone={hoveredMilestone} />
            </div>
        );
    } else {
        return null;
    }
}
