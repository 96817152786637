import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import { ViewTypeValueI } from "../../../constants";

import { Text, Stack, mergeStyleSets, FontWeights } from "@fluentui/react";

import { Options } from "./Options";

type HeaderProps = {
    viewType: ViewTypeValueI;
    onViewTypeChange: (value: ViewTypeValueI) => void;
    dynamicScale: boolean;
    onDynamicScaleChange: (value: boolean) => void;
};

const classNames = mergeStyleSets({
    title: {
        fontSize: 16, // regards to comment in the issue LCM2-627
        fontWeight: FontWeights.semibold,
    },
});

export function Header({ dynamicScale, onDynamicScaleChange, onViewTypeChange, viewType }: HeaderProps) {
    return (
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <Text className={classNames.title}>{intl.get("charts.resource.title")}</Text>
            <Options
                viewType={viewType}
                dynamicScale={dynamicScale}
                onViewTypeChange={onViewTypeChange}
                onDynamicScaleChange={onDynamicScaleChange}
            />
        </Stack>
    );
}
