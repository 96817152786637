import * as DepenbrockChecklist from "./ToDo";
import * as ToDoList from "./ToDoCopy";
import posthog from "posthog-js";

export function ToDo() {
    // eslint-disable-next-line no-constant-condition
    if (false && posthog.getFeatureFlag("ab-checklist") === "test") {
        return <DepenbrockChecklist.ToDo />;
    } else {
        return <ToDoList.ToDo />;
    }
}
