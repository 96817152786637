import * as React from "react";
import { cn } from "@/lib/utils";

export enum StyleTheme {
    BLUE = "blue",
    GREY = "grey",
    NONE = "none",
    WHITE = "white",
}

export function CounterBubbleV2({
    value,
    maxValue = 0,
    styleTheme = StyleTheme.BLUE,
}: {
    value: number;
    maxValue?: number;
    styleTheme?: StyleTheme;
}) {
    const counter: string = maxValue !== 0 && value > maxValue ? `${maxValue}+` : value.toString();
    const padding: number = counter.length === 1 ? 1.5 : 1;
    let size: string = "24px";
    let style: string;

    switch (styleTheme) {
        case StyleTheme.BLUE: {
            style = "bg-[#009BD4] text-white";
            break;
        }
        case StyleTheme.GREY: {
            style = "bg-[#F8FAFA] text-[#565C60] border border-[#D0D4D6]";
            break;
        }
        case StyleTheme.WHITE: {
            style = "bg-white text-black border-none text-black font-semibold text-sm leading-4 tracking-tighter";
            size = "24px";
            break;
        }
        case StyleTheme.NONE:
        default: {
            style = "text-[#565C60]";
        }
    }

    return (
        <div
            className={cn(style, "flex items-center justify-center rounded-full text-xs")}
            style={{ padding, height: size, width: size }}
        >
            <span>{counter}</span>
        </div>
    );
}
