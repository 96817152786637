import { useState } from "react";
import { LcmdModal2 } from "@/components/common/LcmModal2";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { intl } from "@/legacy/GlobalHelperReact";

type WhiteboardEditNameModalProps = {
    isOpen: boolean;
    onOk: (name: string) => void;
    onCancel: () => void;
    name?: string;
};

export function WhiteboardEditNameModal({ isOpen, onOk, onCancel, name }: WhiteboardEditNameModalProps) {
    const [_name, setName] = useState(name || "");

    const buttons = [
        <Button key="cancel" variant="outline" onClick={onCancel}>
            {intl.get("fw.cancel")}
        </Button>,
        <Button key="createWhiteboard" onClick={() => onOk(_name)}>
            {intl.get("fw.ok")}
        </Button>,
    ];

    return (
        <LcmdModal2
            buttons={buttons}
            header={{ title: intl.get("whiteboard.properties.renameWhiteboard") }}
            size="m"
            open={isOpen}
            onOpenChange={onCancel}
        >
            <div className="flex flex-shrink flex-grow flex-col">
                <Input
                    placeholder={intl.get("whiteboard.properties.namePlaceholder")}
                    value={_name || ""}
                    onChange={(event) => setName(event.target.value)}
                    onKeyUp={(event) => {
                        const value = (event.target as HTMLInputElement).value;
                        if (event.key === "Escape") {
                            onCancel();
                        }
                        if (event.key === "Enter") {
                            onOk(value);
                        }
                    }}
                />
            </div>
        </LcmdModal2>
    );
}
