﻿import { ComplexProp, Core, gfu2 } from "../../core/lcmd2core";
import { DataOperation, DataOperationTarget, DataOperationType } from "../DataModel";

export class ParticleGetter {
    private readonly _core: Core;
    private _id: number = -1;
    private _r: DataOperation;

    constructor(core: Core, id?: number) {
        this._core = core;
        this.reset(id);
    }

    public reset(id: number | number[]): ParticleGetter {
        const m = this._core._model;
        if ("number" === typeof id && 0 <= id && id < m.ops.length) {
            this._id = id;
            this._r = this._core._model.ops[id] || ({} as DataOperation);
        } else if (
            Array.isArray(id) &&
            id.length > 0 &&
            "number" === typeof id[0] &&
            0 <= id[0] &&
            id[0] < m.ops.length
        ) {
            this._id = id[0];
            this._r = this._core._model.ops[id[0]] || ({} as DataOperation);
        } else {
            this._id = Number.MIN_SAFE_INTEGER;
            this._r = {} as DataOperation;
        }
        return this;
    }

    /*
    public get $():({
        value?: any,
        r_id?: number,
        r_sid?: string
    }|undefined) {
        return this._r;
    }
    */

    public get _(): number {
        return this._id;
    }

    public value<T>(v0?: T): T | undefined {
        return gfu2(this._r.value, v0) as any as T;
    }

    public get id(): number {
        return this._r.id;
    }

    public get r_id(): number | undefined {
        return this._r.r_id;
    }

    public get createdAt(): number | undefined {
        return this._r.createdAt;
    }

    public get target(): DataOperationTarget {
        return this._r.target;
    }

    public get group(): boolean | undefined {
        return this._r.group;
    }

    public get operation(): DataOperationType | undefined {
        return this._r.op;
    }

    public get r_sid(): string | undefined {
        return this._r.r_sid;
    }

    public unit(v0?: number): number | undefined {
        return "number" === typeof this._r.unit ? this._r.unit : v0;
    }

    public getProp(prop: ComplexProp) {
        prop._reset(this._r.name);
    }

    public get type(): any | undefined {
        return this._r.type;
    }

    public get userId(): string | undefined {
        return this._r._u || undefined;
    }

    public get date(): Date | undefined {
        return "number" === typeof this._r._d ? new Date(this._r._d * 60 * 1000) : undefined;
    }

    public get isHiveTarget(): boolean {
        return DataOperationTarget.HIVE === this._r.target;
    }

    public get isTradeTarget(): boolean {
        return DataOperationTarget.TRADE === this._r.target;
    }

    public get isProcessTarget(): boolean {
        return DataOperationTarget.TASKS === this._r.target;
    }
}
