﻿import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import rocket from "../../../assets/rocket.png";
import { Loader2 } from "lucide-react";

export type MessageDialogProps = {
    open: boolean;
    title: string;
    message: string;
    buttonText: string;
    onButtonClick: () => void;
    onClose?: () => void;
    buttonLoading?: boolean;
    buttonDisabled?: boolean;
};

export function MessageDialog({
    open,
    title,
    message,
    buttonText,
    onButtonClick,
    buttonLoading,
    buttonDisabled,
    onClose,
}: MessageDialogProps) {
    return (
        <Dialog
            open={open}
            onOpenChange={(isOpen) => {
                if (!isOpen) {
                    onClose && onClose();
                }
            }}
        >
            <DialogContent>
                <div className="w-[820px] py-16 bg-white justify-center items-start inline-flex">
                    <div className="flex-col justify-start items-center gap-4 inline-flex">
                        <img width="270" src={rocket} />
                        <div className="justify-start items-center gap-4 inline-flex">
                            <div className="grow shrink basis-0 text-center text-zinc-900 text-2xl font-semibold h-8 leading-9">
                                {title}
                            </div>
                        </div>
                        <div className="text-center text-gray-500 text-xs font-medium h-10 leading-tight w-[350px]">
                            {message}
                        </div>
                        <Button
                            type="submit"
                            size="lg"
                            onClick={onButtonClick}
                            disabled={buttonLoading || buttonDisabled}
                        >
                            {buttonLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
