import { fetch_xslx } from "./ApiHelper";
import * as pako from "pako";
import { Buffer } from "buffer";
import { v4 as uuidv4 } from "uuid";
import { UUID5 } from "./UUID5";

export function _applyPatch<T>(
    _a: T[],
    p: (T | number)[],
    callback: (a: T[], i: number, del: number, ins: T | null) => void,
): T[] {
    let a = _a || [];
    const n_a = a.length;
    const n_p = p.length;
    let i_a = 0;
    for (let i_p = 0; i_p < n_p; i_p++) {
        const d = p[i_p];
        if (d >= 0) {
            i_a += d as number;
        } else {
            if (a === _a) {
                a = _a.slice();
            }
            if (d < 0) {
                callback(a, i_a, -(d as number), null);
            } else {
                if (i_p + 1 < n_p && p[i_p + 1] < 0) {
                    const next_del = -(p[++i_p] as number);
                    callback(a, i_a, 1, d as T);
                    i_a++;
                    if (next_del > 1) {
                        callback(a, i_a, next_del - 1, null);
                    }
                } else {
                    callback(a, i_a, 0, d as T);
                    i_a++;
                }
            }
        }
    }
    if (i_a < n_a) {
        if (a === _a) {
            a = _a.slice();
        }
        callback(a, i_a, n_a - i_a, null);
    }
    return a;
}

export function applyPatch<T>(a: T[], p: (T | number)[], post?: (ins: T) => void): T[] {
    return _applyPatch(a, p, (a, i, del, ins) => {
        if (ins) {
            assert(0 === del || 1 === del);
            if (post) {
                post(ins);
            }
            if (1 === del) {
                a[i] = ins;
            } else {
                a.splice(i, del, ins);
            }
        } else {
            a.splice(i, del);
        }
    });
}

/**
 * @todo Documentation
 * @public
 */
export function assert<T>(cond: T, msg?: string): asserts cond is NonNullable<T> {
    if (!cond) {
        const errorText = `Assertion Failed: ${msg}` || "Assertion Failed";
        const e = new Error(errorText);
        console.error(e.stack);
        throw e;
    }
}

export function assert_dev(cond: boolean, msg?: string) {
    if ("release" !== process.env.NODE_ENV) {
        assert(cond, msg);
    }
}

export function EpochDaysToStartOfWeekDays(d: number) {
    const _m = Math.floor((d - 4) / 7) * 7 + 4;
    const _d = new Date(EpochDaystoEpochMS(_m));
    assert(1 == _d.getUTCDay() && _m <= d && d - _m < 7);
    return _m;
}

/**
 *
 * @param d
 * @constructor
 * @deprecated
 * @see convertMillisecondsToDays
 */
export function EpochMStoEpochDays(d: number) {
    return Math.floor(d / (86400 * 1000));
}

/**
 *
 * @param days
 * @param ms
 * @constructor
 * @deprecated
 * @see convertDaysToMilliseconds
 */
export function EpochDaystoEpochMS(days: number, ms?: number) {
    return days * (86400 * 1000) + (ms || 0);
}

export function EpochMSGetTimeOfsMS(d: number) {
    return d - EpochDaystoEpochMS(EpochMStoEpochDays(d));
}

export function DateToDateStr(date: Date) {
    return date.toISOString().substr(0, 10);
}

export function EpochMSToDateStr(d: number) {
    return DateToDateStr(new Date(d));
}

export function DateToLocaleDateStr(date: Date) {
    return date.toISOString().substr(0, 10);
}

export function EpochMSToLocaleDateStr(d: number) {
    return DateToLocaleDateStr(new Date(d));
}

export function HelperEpochMSToLocalDate(ms: number) {
    const date = new Date(ms);
    const ret = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds(),
    );
    return ret;
}
export function HelperDateToUTCEpochMS(date: Date) {
    return Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds(),
    );
}

export function DateToMonday(date: Date, convertToUTC?: boolean) {
    if (convertToUTC) {
        date = new Date(HelperDateToUTCEpochMS(date));
    }
    const helper = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    const day = helper.getUTCDay();
    const _date = helper.getUTCDate();
    const diff = _date - day + (0 === day ? -6 : 1);
    helper.setUTCDate(diff);
    return helper;
}

export function HelperDateToTimeStr(date: Date, force?: boolean) {
    if (date && (force || !(0 === date.getHours() && 0 === date.getMinutes()))) {
        const df = new Intl.DateTimeFormat(undefined, {
            hour: "numeric",
            minute: "numeric",
        });
        //return date.toISOString().substring(11, 16);
        //return date.toISOString().substring(11);
        const ret = df.format(date);
        return ret;
    } else {
        return null;
    }
}

export function HelperDateTo24TimeStr(date_ms: number, force?: boolean) {
    const startOfs = EpochMSGetTimeOfsMS(date_ms);
    if (force || startOfs > 0) {
        const v = Math.round(startOfs / (1000 * 60));
        const v_h = Math.floor(v / 60)
            .toFixed()
            .padStart(2, "0");
        const v_m = (v % 60).toFixed().padStart(2, "0");
        return [v_h, v_m].join(":");
    } else {
        return null;
    }
}

export const REL_TYPES = [null, "EE", "EA", "AE", "AA"];

// MS see https://docs.microsoft.com/en-us/office-project/xml-data-interchange/durationformat-element?view=project-client-2016
export const UNIT_TYPES = [
    null, // px
    "m", // 1. Minutes
    "h", // 2. Hours
    "d", // 3. Days
    "w", // 4. Weeks
    "mo", // 5. Months
    "%", // 6. Percent
    "y", // 7. Years
    "em", // 8. Elapsed Minutes
    "eh", // 9. Elapsed Hours
    "ed", // 10. Elapsed Days
    "ew", // 11. Elapsed Weeks
    "emo", // 12. Elapsed Months
    "ey", // 13. Elapsed Years
    "e%", // 14. Elapsed Percent
] as const;

export const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function HelperDaysUnitToDurationStr(op: { value: number; unit?: number }) {
    const value = op.value;
    const unit = op.unit || 3;
    const duration = value + " " + UNIT_TYPES[unit];
    return duration;
}

export function errorToJSON(error?: Error) {
    return {
        name: error?.name || undefined,
        message: error?.message || undefined,
        stack: error?.stack || undefined,
        details: (error as any)?.details || undefined,
    };
}

/**
 * @todo Documentation
 * @public
 */
export class FrameworkError extends Error {
    private intl_id: string;
    public details: any;
    constructor(intl_id: string, details?: any) {
        super(intl_id);
        this.name = "FrameworkError";
        this.details = details;
    }
}

/**
 * @todo Documentation
 * @public
 */
export class FrameworkHttpError extends FrameworkError {
    constructor(status: number, intl_id?: string) {
        super(intl_id || ["error", status].join("."));
        this.name = "FrameworkHttpError";
    }
}

/**
 * @todo Documentation
 * @public
 */
export class FrameworCacheError extends FrameworkError {
    constructor(intl_id?: string) {
        super(intl_id || "error.unkonwn");
        this.name = "FrameworCacheError";
    }
}

/**
 * @todo Documentation
 * @public
 */
export class FrameworkUpdateNeededError extends FrameworkError {
    constructor(intl_id?: string) {
        super(intl_id || "error.updateNeeded");
        this.name = "FrameworkUpdateNeededError";
    }
}

export function jsonToError(error?: { name: string; message: string; stack: string; details?: any }) {
    if (error?.name) {
        const e = new Error();
        e.name = error.name;
        if (error.message) {
            e.message = error.message;
        }
        if (error.stack) {
            e.stack = error.stack;
        }
        if (error.details) {
            (e as any).details = error.details;
        }
        return e;
    } else {
        const e = new FrameworkError("error.unkown");
        return e;
    }
}

export function gfu2<T extends number | boolean>(a: T, b: T): T {
    // get fist undefined
    return undefined !== a ? a : b;
}

export function gfu<T extends number | boolean>(a: T, b: T, c: T): T {
    // get fist undefined
    return undefined !== a ? a : undefined !== b ? b : c;
}

export function gfu4<T extends number | boolean>(a: T, b: T, c: T, d: T): T {
    // get fist undefined
    return undefined !== a ? a : gfu(b, c, d);
}

export function gfu5<T extends number | boolean>(a: T, b: T, c: T, d: T, e: T): T {
    // get fist undefined
    return undefined !== a ? a : gfu4(b, c, d, e);
}

export function needsWhiteColor(color: number) {
    const r = (color >> 16) & 0xff;
    const g = (color >> 8) & 0xff;
    const b = (color >> 0) & 0xff;
    const luma = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luma <= 0.5;
}

export function unsafeParseJWT(token) {
    let _token = null;
    try {
        if ("undefined" !== typeof self) {
            _token = JSON.parse(atob(token.split(".")[1]));
        } else {
            _token = JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
        }
    } catch (e) {}
    return _token;
}

function toHexString(byteArray: Uint8Array) {
    return Array.from(byteArray, (b) => {
        return ("0" + (b & 0xff).toString(16)).slice(-2);
    }).join("");
}

export function stringToHex(s: string) {
    const bytes = new TextEncoder().encode(s);
    return toHexString(bytes);
}

export class TableExportHelper {
    _data = [[]];

    pushHeader(header: any) {
        this._data[0].push(header);
    }
    pushRow(row: any[]) {
        this._data.push(row);
        if (Array.isArray(row)) {
            this._data.push();
        }
    }

    fetchXSLX(token: string, opt?: { json?: boolean }) {
        return new Promise((resolve: (buffer: Buffer) => void, reject: (e: FrameworkError) => void) => {
            const deflator = new pako.Deflate();
            const wrapper = JSON.stringify({
                xslx: {
                    "Sheet 1": {
                        rows: ["ROWS"],
                    },
                },
            }).split('"ROWS"');

            deflator.push(wrapper[0]);
            this._data.reduce((deflator, row, i_row) => {
                const _row = JSON.stringify(
                    row.map((item) => {
                        if ("string" === typeof item) {
                            return item;
                        } else if ("number" === typeof item) {
                            return item;
                        } else if (item instanceof Date) {
                            return {
                                value: item.getTime(),
                                type: "date-time",
                            };
                        } else {
                            return null;
                            //return "";
                        }
                    }),
                );
                if (i_row > 0) {
                    deflator.push(",");
                }
                deflator.push(_row);
                return deflator;
            }, deflator);
            deflator.push(wrapper[1], true);
            if (opt?.json) {
                resolve(deflator.result);
            } else {
                fetch_xslx(
                    token,
                    (error, result) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(result);
                        }
                    },
                    deflator.result,
                    true,
                );
            }
        });
    }

    getAsJSON() {
        const ret = this._data.reduce((ret, row, i_row, rows_a) => {
            if (i_row > 0) {
                let item = {};
                const h = rows_a[0];
                item = row.reduce((item, col, i_col) => {
                    item[h[i_col]] = col;
                    return item;
                }, item);
                ret.push(item);
            }
            return ret;
        }, []);
        return ret;
    }
}

function topSortVisit(
    this: {
        nodes: { _color: number }[];
        edges: (node) => any[];
        ret: any[];
        circle: boolean;
    },
    node,
) {
    assert(0 === node._color);
    node._color = 1;
    const edges = this.edges(node);
    const n_edges = edges.length;
    for (let i_edge = 0; i_edge < n_edges; i_edge++) {
        const target = edges[i_edge];
        if (0 === target._color) {
            topSortVisit.call(this, target);
        } else if (1 === target._color) {
            this.circle = true;
        }
    }
    node._color = 2;
    this.ret.push(node);
}

export function topSort(nodes: { _color: number }[], edges: (node) => any[]) {
    const n_nodes = nodes.length;
    for (let i_node = 0; i_node < n_nodes; i_node++) {
        const node = nodes[i_node];
        node._color = 0;
    }
    const ctx = {
        nodes,
        edges,
        circle: false,
        ret: [],
    };
    for (let i_node = 0; i_node < n_nodes; i_node++) {
        const node = nodes[i_node];
        if (0 === node._color) {
            topSortVisit.call(ctx, node);
        }
    }
    return ctx.circle ? null : ctx.ret;
}

/**
 * @public
 */
export enum LCMDContextCardStatus {
    OPEN = 0,
    IN_PROGRESS = 1,
    DONE = 2,
    IN_APPROVAL = 3,
}

export enum StabilityOverallStatus {
    ON_TIME,
    LATE,
    DONE,
}

export enum ProcessStatuses {
    open = 0,
    done = 1,
    inProgress = 2,
    late = 3,
    overdue = 4,
}

export enum BadgeStatuses {
    open = "open",
    inProgress = "inProgress",
    done = "done",
    overdue = "overdue",
}

/**
 *
 * @public
 */
export function generateId(): string {
    const uuid = uuidv4();
    return UUID5.fromUUID(uuid).toUUID5().toLowerCase();
}

export function generateWarmupId() {
    const array = new Uint8Array(12);
    if ("function" === typeof crypto.getRandomValues) {
        crypto.getRandomValues(array);
    } else {
        for (let i = 0; i < array.length; i++) {
            array[i] = Math.floor(Math.random() * 256);
        }
    }
    const hex = array.reduce((ret, v, i) => {
        const _v = v.toString(16);
        return [ret, 2 === i || 4 == i || 6 == i ? "-" : "", _v.length < 2 ? "0" : "", _v].join("");
    }, "");
    const ret = ["UNINITLZ", hex].join("-");
    return ret;
}

export function formatWorkforceOutput(workforce: number) {
    return Math.round(workforce * 100) / 100;
}
