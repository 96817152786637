import * as React from "react";

import { IButtonProps, IconButton, IContextualMenuItem, IContextualMenuProps } from "@fluentui/react";

import { classNames } from "./common";

export type AdditionActionsProps = IButtonProps & {
    items?: IContextualMenuItem[];
};

const moreIconProps = {
    iconName: "MoreVertical",
    className: classNames.iconColor,
};

function AdditionActions(props: AdditionActionsProps) {
    const { items, ...restProps } = props;

    const menuProps: IContextualMenuProps = {
        items,
    };

    return <IconButton menuProps={menuProps} menuIconProps={moreIconProps} {...restProps} />;
}

AdditionActions.defaultProps = {
    items: [],
};

export { AdditionActions };
