﻿import { ComplexProp } from "./complexProp";

export class ComplexPropIter {
    private _keys: string[];
    private _i = 0;

    constructor(keys: string[]) {
        this._keys = keys;
    }

    private _seek() {
        while (this._i < this._keys.length) {
            const key = this._keys[this._i];
            if (key.startsWith("_")) {
                this._i++;
            } else {
                return;
            }
        }
    }

    public first(prop: ComplexProp) {
        this._i = 0;
        this._seek();
        if (this._i < this._keys.length) {
            prop._reset(this._keys[this._i]);
            this._seek();
        }
    }

    public next(prop: ComplexProp) {
        const ret = this._i < this._keys.length;
        if (ret) {
            prop._reset(this._keys[this._i++]);
            this._seek();
        }
        return ret;
    }
}
