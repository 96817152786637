﻿import { intl } from "@/legacy/GlobalHelperReact";
import { Button } from "@/components/ui/button";
import { useMemo, useState } from "react";
import { Loader2 } from "lucide-react";
import { PasswordField } from "../common/PasswordComponent/PasswordField";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../types/routes";
import { AuthClient, TokenExpiredError } from "@/app/services/AuthClient.service";
import { unsafeParseJWT } from "@/model/GlobalHelper";

export type ForgotPasswordNewPasswordFormProps = {
    token: string;
    onLogin: (sub: string, authToken: string) => void;
};

export function ForgotPasswordNewPasswordForm({ token, onLogin }: ForgotPasswordNewPasswordFormProps) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [passwordMessage, setPasswordMessage] = useState("");
    const [passwordConfirmationMessage, setPasswordConfirmationMessage] = useState("");
    const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);

    const email = useMemo(() => unsafeParseJWT(token)?.email ?? "", [token]);

    function handleBack() {
        navigate(Paths.HOME);
    }

    async function handleSubmit() {
        setPasswordMessage("");
        setPasswordConfirmationMessage("");

        if (isPasswordInvalid || !password) {
            setPasswordMessage(intl.get("LcmdLogin.ForgotPassword.PasswordInvalidError"));
            return;
        }

        if (password !== passwordConfirmation) {
            setPasswordConfirmationMessage(intl.get("LcmdLogin.ForgotPassword.PasswordsDoNotMatchError"));
            return;
        }

        try {
            setIsLoading(true);
            const result = await AuthClient.resetPassword(token, password);
            onLogin(result.sub, result.authToken);
        } catch (error) {
            console.log(error);

            if (error instanceof TokenExpiredError) {
                setPasswordConfirmationMessage(intl.get("LcmdLogin.ForgotPassword.TokenExpiredError"));
                return;
            }

            setPasswordConfirmationMessage(intl.get("LcmdLogin.ForgotPassword.PasswordSaveError"));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="text-xl font-semibold">{intl.get("LcmdLogin.ForgotPassword.NewPassword")}</div>
            <div className="mt-1 body-medium">
                <span className="text-zinc-400">{intl.get("LcmdLogin.ForgotPassword.ForEmail")} </span>
                <span className="text-zinc-900">{email}</span>
            </div>

            <div>
                <input
                    style={{
                        opacity: 0,
                        position: "absolute",
                    }}
                />
                <input
                    type="password"
                    style={{
                        opacity: 0,
                        position: "absolute",
                    }}
                />
                <div className="mt-4">
                    <PasswordField
                        value={password}
                        withValidator={true}
                        message={passwordMessage}
                        onChange={setPassword}
                        onValidChanged={(valid) => {
                            setIsPasswordInvalid(!valid);
                            if (valid) {
                                setPasswordMessage("");
                            }
                        }}
                        autoComplete="new-password"
                    ></PasswordField>
                </div>
                <div className="mt-4">
                    <PasswordField
                        value={passwordConfirmation}
                        onChange={(value) => {
                            setPasswordConfirmation(value);
                            setPasswordConfirmationMessage("");
                        }}
                        withValidator={false}
                        message={passwordConfirmationMessage}
                        autoComplete="new-password"
                    ></PasswordField>
                </div>
                <Button variant="link" className="mt-2 pl-0 text-blue-700 justify-start" onClick={handleBack}>
                    {intl.get("LcmdLogin.ForgotPassword.BackToLogin")}
                </Button>
                <Button type="button" size="lg" disabled={isLoading} className="mt-8" onClick={handleSubmit}>
                    {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                    {intl.get("LcmdLogin.ForgotPassword.SubmitPasswordButton")}
                </Button>
            </div>
        </>
    );
}
