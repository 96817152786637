import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import { WidgetHeader } from "../../layout/widget-header";

import { Actions } from "../common/actions";
import { TitleText } from "../../common/title-text";
import { Title } from "../../common/title";

const ACTUAL_PLANNING_COLOR = "#009BD4";
const BASELINE_COLOR = "#999EA1";

function Header(props: { onShowBaseline: (boolean) => void }) {
    const onClick = React.useCallback(() => {
        props.onShowBaseline(true);
    }, [props.onShowBaseline]);
    return (
        <WidgetHeader
            actions={<Actions />}
            title={
                <Title>
                    <TitleText
                        text={intl.get("charts.actualPlanningVsBaseline.labels.actualPlanningVs")}
                        pointColor={ACTUAL_PLANNING_COLOR}
                    />
                    <TitleText
                        text={intl.get("charts.actualPlanningVsBaseline.labels.baseline")}
                        pointColor={BASELINE_COLOR}
                        onClick={onClick}
                        userflowId="basisdaten"
                    />
                </Title>
            }
        />
    );
}

export { Header };
