import { ChevronRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import { StyleTheme } from "../counter/CounterBubbleV2";
import { CounterBubbleV2 } from "../counter/CounterBubbleV2";
import { intl } from "lcmd2framework";
import { useCanvasStore } from "@/app/store/canvasStore";
import { useConflictStore } from "@/app/store/conflictsStore";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { useFocusMode } from '../../../app/hooks/useFocusMode'

export function ConflictsButton() {
    const { setShowConflictMode, showConflictMode } = useCanvasStore();
    const { disableFocusMode } = useFocusMode();
    const conflictCount = useConflictStore().conflictCount;

    function onClick() {
        setShowConflictMode(!showConflictMode);
        WebAppTelemetryFactory.trackEvent("conflicts-detected-button-clicked", {
            conflictCount: conflictCount
        });

        if (showConflictMode) {
            disableFocusMode();
        }
    }

    return (
        <Button
            variant="destructive"
            className="flex h-8 items-center rounded-full px-1 py-1"
            onClick={onClick}
            userflowId="pp-konflikt-entdeckt"
        >
            <CounterBubbleV2 value={conflictCount} styleTheme={StyleTheme.WHITE} maxValue={99} />
            <p className="font-inter ml-2 text-xs font-medium leading-5 text-white">
                {intl.get("canvas.conflictsButton")}
            </p>
            <ChevronRight className="mx-1 h-[18px] w-[18px]" />
        </Button>
    );
}
