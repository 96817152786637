import * as React from "react";
import { intl } from "lcmd2framework";

import { IStackTokens, Stack, Text, IconButton, IIconProps, mergeStyleSets } from "@fluentui/react";

type TitleProps = {
    onChangeClick?: () => void;
};

const CHANGE_BUTTON_SIZE = 20;
const CHANGE_BUTTON_ICON_SIZE = 10;

const classNames = mergeStyleSets({
    button: { width: CHANGE_BUTTON_SIZE, height: CHANGE_BUTTON_SIZE },
    title: { fontWeight: 600 }, // How can I set medium font weight more correctly?
});

const tokensProps: IStackTokens = { childrenGap: 5 };

const editIconProps: IIconProps = {
    iconName: "EditSolid12",
    // can not find how to change size of icon instead this way
    style: { fontSize: CHANGE_BUTTON_ICON_SIZE },
};

function Title(props: TitleProps) {
    const { onChangeClick } = props;

    return (
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between" tokens={tokensProps}>
            <Text className={classNames.title}>{intl.get("canvas.cmd.filter.title")}</Text>
            {onChangeClick && (
                <IconButton
                    iconProps={editIconProps}
                    className={classNames.button}
                    title={intl.get("canvas.cmd.filter.cmd.manage.title")}
                    onClick={onChangeClick}
                />
            )}
        </Stack>
    );
}

export { Title };
