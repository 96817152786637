import {
    CheckboxVisibility,
    ContextualMenuItemType,
    DefaultButton,
    DetailsList,
    DetailsRow,
    GroupHeader,
    IColumn,
    Icon,
    IconButton,
    IContextualMenuItem,
    IGroup,
    IGroupHeaderProps,
    Selection,
    Stack,
    StackItem,
    Sticky,
    StickyPositionType,
} from "@fluentui/react";
import { SelectionMode } from "@fluentui/utilities";
import React, { useContext, useEffect, useState } from "react";
import { HistoryMeta } from "../../../../app/interface";
import { HistoryEntry } from "./HistoryEntry";
import { epochDayToDate } from "../../../Utils";
import { ChangesPanelActionTypes, CPContext } from "../../Shared/CPContext";
import { useLCMD } from "../../../../app/LCMContext";
import { getCurrentLanguage } from "../../../utils/date/locale";
import { Icons } from "../../../RegisteredIcons";
import { CounterBubble, StyleTheme } from "../../../common/counter/CounterBubble";
import { intl } from "@/legacy/GlobalHelperReact";
import { useNavigate } from "react-router-dom";
import { CHANGES_PANEL_ROUTES } from "../../Shared/routers.enum";

type HistoryListProps = {
    historyChanges: HistoryMeta[];
    selectionMode: SelectionMode;
    onSelect: (selectedIndices: number[]) => void;
    source: Source;
    showFilter?: boolean;
};

export enum Source {
    SINGLE_PROCESS,
    ALL_PROCESSES,
}

//TODO: historyChanges should not be empty on first rendering => real empty History shows shimmer
export function HistoryList({ historyChanges, selectionMode, onSelect, source, showFilter }: HistoryListProps) {
    const LCMD = useLCMD();
    const [groups, setGroups] = useState<symbol>();
    const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
    const [selection] = useState(
        new Selection({
            onSelectionChanged: () => {
                const selected = selection.getSelectedIndices();
                setSelectedIndices(selected);
                onSelect(selected);
            },
        }),
    );
    const { state, dispatch } = useContext(CPContext);

    const columns: IColumn[] = [
        {
            key: "date",
            name:
                state.selectedCondition === "epochDay"
                    ? intl.get("HistoryView.GrouppingSettings.GroupBy.Options.Date")
                    : intl.get("HistoryView.GrouppingSettings.GroupBy.Options.Person"),
            fieldName: "date",
            minWidth: 100,
            isResizable: false,
        },
        { key: "action", name: "", fieldName: "action", minWidth: 50, isResizable: false },
    ];

    useEffect(() => {
        if (!historyChanges || !Array.isArray(historyChanges) || historyChanges.length == 0) {
            setGroups(undefined);
            return;
        }

        setGroups(Symbol());
    }, [historyChanges]);

    const [id, setId] = useState("");

    useEffect(() => {
        LCMD.getProjectDetails((error, data) => {
            if (error) {
                console.warn(error.message);
                setId("");
                return;
            }
            setId(data.pid.toString());

            if (sessionStorage.getItem(`lcmd.sortDirection_${data.pid.toString()}`)) {
                dispatch({
                    type: ChangesPanelActionTypes.SET_SORT_DIRECTION,
                    sortDirection: sessionStorage.getItem(`lcmd.sortDirection_${data.pid.toString()}`),
                });
            }

            if (sessionStorage.getItem(`lcmd.selectedCondition_${data.pid.toString()}`)) {
                dispatch({
                    type: ChangesPanelActionTypes.SET_SELECTED_CONDITION,
                    selectedCondition: sessionStorage.getItem(`lcmd.selectedCondition_${data.pid.toString()}`),
                });
            }
        });
    }, []);

    function onToggleGroup(group: IGroup, props: IGroupHeaderProps) {
        const tmpCollapsed = [...state.notCollapsed];
        if (tmpCollapsed.indexOf(group.key) === -1) {
            tmpCollapsed.push(group.key);
        } else {
            tmpCollapsed.splice(tmpCollapsed.indexOf(group.key), 1);
        }
        dispatch({ type: ChangesPanelActionTypes.SET_COLLAPSED, notCollapsed: tmpCollapsed });

        // call default function, so group will collapse or close
        props.onToggleCollapse(group);
    }

    function _onRenderGroupHeader(props, defaultRender): JSX.Element {
        if (props) {
            const renderGroupName = (group: IGroup) => {
                let name = group.name;
                if (state.selectedCondition === "epochDay") {
                    name = epochDayToDate(+name).toUTCFormatString(getCurrentLanguage(), {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    });
                }
                return name;
            };
            return (
                <GroupHeader
                    {...props}
                    onToggleCollapse={(group) => {
                        onToggleGroup(group, props);
                    }}
                    styles={{
                        headerCount: {
                            display: "none",
                        },
                        check: { opacity: "1" },
                        root: { backgroundColor: "#fff" },
                    }}
                    selectAllButtonProps={() => {
                        console.log("selected!!!!!");
                    }}
                    selectionMode={selectionMode}
                    checkboxVisibility={CheckboxVisibility.always}
                    alwaysShowCheck={true}
                    onRenderTitle={(titleProps, titleDefaultRender) => {
                        return (
                            <Stack horizontal verticalAlign={"center"} tokens={{ childrenGap: 8 }}>
                                <StackItem>
                                    {titleDefaultRender({
                                        ...titleProps,
                                        group: { ...titleProps.group, name: renderGroupName(props.group) },
                                    })}
                                </StackItem>
                                <StackItem>
                                    <CounterBubble value={titleProps.group.count} styleTheme={StyleTheme.GREY} />
                                </StackItem>
                            </Stack>
                        );
                    }}
                />
            );
        }

        return null;
    }

    function _onRenderDetailsHeader(props, defaultRender): JSX.Element {
        return (
            <>
                <Sticky stickyPosition={StickyPositionType.Header}>
                    <div>
                        {defaultRender({
                            ...props,
                            onRenderColumnHeaderTooltip: (tooltipHostProps, tooltipDefaultRender) => {
                                if (tooltipHostProps.column?.key === "action") {
                                    return (
                                        <IconButton
                                            iconProps={{ iconName: "Equalizer" }}
                                            menuProps={{
                                                items: [
                                                    /* {
                                                                key: 'Show-reviewed-changes',
                                                                text: 'Show reviewed changes',
                                                                canCheck: true,
                                                                checked: false,
                                                                onRender: () => {
                                                                    return <Toggle styles={{root: {padding: 10}}} inlineLabel label="Show reviewed changes" checked={true} onChange={()=> {}} />
                                                                },
                                                            }, */
                                                    {
                                                        key: "group-by",
                                                        text: intl.get("HistoryView.GrouppingSettings.GroupBy.Label"),
                                                        itemType: ContextualMenuItemType.Header,
                                                        style: {
                                                            color: "#565C60",
                                                            fontWeight: "600",
                                                        },
                                                        iconProps: { styles: { root: { display: "none" } } },
                                                        submenuIconProps: { styles: { root: { display: "none" } } },
                                                    },
                                                    {
                                                        key: "epochDay",
                                                        text: intl.get(
                                                            "HistoryView.GrouppingSettings.GroupBy.Options.Date",
                                                        ),
                                                        canCheck: true,
                                                        checked: state.selectedCondition == "epochDay" ? true : false,
                                                        onClick: (
                                                            ev?:
                                                                | React.MouseEvent<HTMLElement>
                                                                | React.KeyboardEvent<HTMLElement>,
                                                            item?: IContextualMenuItem,
                                                        ): void => {
                                                            item.checked = !item.checked;
                                                            dispatch({
                                                                type: ChangesPanelActionTypes.SET_SELECTED_CONDITION,
                                                                selectedCondition: "epochDay",
                                                            });
                                                            sessionStorage.setItem(
                                                                `lcmd.selectedCondition_${id}`,
                                                                "epochDay",
                                                            );
                                                            // setGroups(groupsGenerator((historyChanges || []), 'epochDay', sortDirection, source))
                                                        },
                                                    },
                                                    {
                                                        key: "userId",
                                                        text: intl.get(
                                                            "HistoryView.GrouppingSettings.GroupBy.Options.Person",
                                                        ),
                                                        canCheck: true,
                                                        checked: state.selectedCondition == "userId" ? true : false,
                                                        onClick: (
                                                            ev?:
                                                                | React.MouseEvent<HTMLElement>
                                                                | React.KeyboardEvent<HTMLElement>,
                                                            item?: IContextualMenuItem,
                                                        ): void => {
                                                            item.checked = !item.checked;
                                                            dispatch({
                                                                type: ChangesPanelActionTypes.SET_SELECTED_CONDITION,
                                                                selectedCondition: "userId",
                                                            });
                                                            sessionStorage.setItem(
                                                                `lcmd.selectedCondition_${id}`,
                                                                "userId",
                                                            );
                                                            // setGroups(groupsGenerator((historyChanges || []), 'userId', sortDirection, source))
                                                        },
                                                    },
                                                    {
                                                        key: "order-by",
                                                        text: intl.get("HistoryView.GrouppingSettings.OrderBy.Label"),
                                                        itemType: ContextualMenuItemType.Header,
                                                        style: {
                                                            color: "#565C60",
                                                            fontWeight: "600",
                                                        },
                                                        iconProps: { styles: { root: { display: "none" } } },
                                                        submenuIconProps: { styles: { root: { display: "none" } } },
                                                    },
                                                    {
                                                        key: "ascending",
                                                        text: `${
                                                            state.selectedCondition === "epochDay"
                                                                ? intl.get(
                                                                      "HistoryView.GrouppingSettings.OrderBy.Options.DateAscending",
                                                                  )
                                                                : intl.get(
                                                                      "HistoryView.GrouppingSettings.OrderBy.Options.PersonAscending",
                                                                  )
                                                        }`,
                                                        canCheck: true,
                                                        checked: state.sortDirection === "ascending" ? true : false,
                                                        onClick: (
                                                            ev?:
                                                                | React.MouseEvent<HTMLElement>
                                                                | React.KeyboardEvent<HTMLElement>,
                                                            item?: IContextualMenuItem,
                                                        ): void => {
                                                            item.checked = !item.checked;
                                                            // setGroups(groupsGenerator((historyChanges || []), `${selectedCondition}`, 'ascending', source));
                                                            dispatch({
                                                                type: ChangesPanelActionTypes.SET_SORT_DIRECTION,
                                                                sortDirection: "ascending",
                                                            });
                                                            sessionStorage.setItem(
                                                                `lcmd.sortDirection_${id}`,
                                                                "ascending",
                                                            );
                                                        },
                                                    },
                                                    {
                                                        key: "descending",
                                                        text: `${
                                                            state.selectedCondition === "epochDay"
                                                                ? intl.get(
                                                                      "HistoryView.GrouppingSettings.OrderBy.Options.DateDescending",
                                                                  )
                                                                : intl.get(
                                                                      "HistoryView.GrouppingSettings.OrderBy.Options.PersonDescending",
                                                                  )
                                                        }`,
                                                        canCheck: true,
                                                        checked: state.sortDirection === "descending" ? true : false,
                                                        onClick: (
                                                            ev?:
                                                                | React.MouseEvent<HTMLElement>
                                                                | React.KeyboardEvent<HTMLElement>,
                                                            item?: IContextualMenuItem,
                                                        ): void => {
                                                            item.checked = !item.checked;
                                                            // setGroups(groupsGenerator((historyChanges || []), `${selectedCondition}`, 'descending', source));
                                                            dispatch({
                                                                type: ChangesPanelActionTypes.SET_SORT_DIRECTION,
                                                                sortDirection: "descending",
                                                            });
                                                            sessionStorage.setItem(
                                                                `lcmd.sortDirection_${id}`,
                                                                "descending",
                                                            );
                                                        },
                                                    },
                                                ],
                                            }}
                                            onRenderMenuIcon={() => <div />}
                                        />
                                    );
                                } else {
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                height: "100%",
                                            }}
                                        >
                                            <div>{tooltipDefaultRender({ ...tooltipHostProps })}</div>
                                            {showFilter && <HistoryFilterButton />}
                                        </div>
                                    );
                                }
                            },
                        })}
                    </div>
                </Sticky>
            </>
        );
    }

    function _onRenderRow(props, defaultRender): JSX.Element {
        let stylingRow = {};

        switch (props.item?.fail) {
            case 1:
                stylingRow = {
                    root: { backgroundColor: "rgba(245, 135, 145, 0.2)" },
                };
                break;
            case 2:
                stylingRow = {
                    root: { backgroundColor: "rgba(95, 210, 85, 0.2)" },
                };
                break;
        }

        if (props.item?.conflict) {
            const conflictProps = {
                ...props,
                item: {
                    change: "CONFLICT: " + props.item.conflict[0],
                    date: "",
                    item: "",
                    user: "",
                    hasAction: ["ChevronRight"],
                },
            };
            return (
                <div>
                    <DetailsRow {...props} styles={stylingRow} />
                    <DetailsRow {...conflictProps} styles={stylingRow} />
                </div>
            );
        }
        return <DetailsRow {...props} styles={stylingRow} />;
    }

    function sortByEpochDay(a: HistoryMeta, b: HistoryMeta) {
        if (a.epochDay < b.epochDay) {
            return -1;
        }
        if (a.epochDay > b.epochDay) {
            return 1;
        }
        return 0;
    }

    function sortByUserId(a: HistoryMeta, b: HistoryMeta) {
        if (a.events[0].userId < b.events[0].userId) {
            return -1;
        }
        if (a.events[0].userId > b.events[0].userId) {
            return 1;
        }
        return 0;
    }

    function sortHistoryChanges(
        historyChanges: HistoryMeta[],
        compareFunction: (a: HistoryMeta, b: HistoryMeta) => -1 | 0 | 1,
    ) {
        historyChanges.sort(compareFunction);
    }

    function groupsGenerator(items: HistoryMeta[], fieldName: string, sortCondition: string, source: Source): IGroup[] {
        const groupObjArr: IGroup[] = [];
        /* let groupObjArr: IGroup[] = [];
        for tests */
        if (fieldName === "epochDay") {
            const groupNames = [...new Set(items.map((item) => item[fieldName as keyof HistoryMeta]))];
            groupNames.forEach((gn: number) => {
                const groupLength = items.filter((item) => item[fieldName as keyof HistoryMeta] === gn).length;
                const groupIndex = items.map((item) => item[fieldName as keyof HistoryMeta]).indexOf(gn);
                const notCollapsed =
                    (source == Source.SINGLE_PROCESS && state.notCollapsed.length == 0) ||
                    state.notCollapsed.indexOf(gn.toString()) >= 0;
                groupObjArr.push({
                    key: gn.toString(),
                    name: gn.toString(),
                    count: groupLength,
                    startIndex: groupIndex,
                    isCollapsed: !notCollapsed,
                });
            });
            sortCondition === "descending" &&
                groupObjArr.sort((a, b) => {
                    return Number(b.key) - Number(a.key);
                });
            sortCondition === "ascending" &&
                groupObjArr.sort((a, b) => {
                    return Number(a.key) - Number(b.key);
                });
        }

        if (fieldName === "userId") {
            const groupNames = [...new Set(items.map((item) => item.events[0][fieldName as keyof HistoryMeta]))];
            groupNames.forEach((gn: string) => {
                const groupLength = items.filter(
                    (item) => item.events[0][fieldName as keyof HistoryMeta] === gn,
                ).length;
                const groupIndex = items.map((item) => item.events[0][fieldName as keyof HistoryMeta]).indexOf(gn);
                const notCollapsed =
                    (source == Source.SINGLE_PROCESS && state.notCollapsed.length == 0) ||
                    state.notCollapsed.indexOf(gn.toString()) >= 0;
                let userName = "";
                const getUserName = new Promise((resolve, reject) => {
                    LCMD.getUser(gn, (result) => {
                        if (result.meta.firstName && result.meta.lastName) {
                            userName = `${result.meta.firstName}  ${result.meta.lastName}`;
                            resolve(result);
                        } else if (!result.meta.firstName && result.meta.lastName) {
                            userName = `${result.meta.lastName}`;
                            resolve(result);
                        } else if (result.meta.firstName && !result.meta.lastName) {
                            userName = `${result.meta.firstName}`;
                            resolve(result);
                        } else if (!result.meta.firstName && !result.meta.lastName) {
                            userName = `${result.email}`;
                            resolve(result);
                        }
                    });
                });
                Promise.all([getUserName]).then(() => {
                    groupObjArr.push({
                        key: gn.toString(),
                        name: userName,
                        count: groupLength,
                        startIndex: groupIndex,
                        isCollapsed: !notCollapsed,
                    });
                    if (groupObjArr.length === groupNames.length) {
                        sortCondition === "ascending"
                            ? groupObjArr.sort((a, b) => {
                                  return a.name.localeCompare(b.name);
                              })
                            : groupObjArr.sort((a, b) => {
                                  return b.name.localeCompare(a.name);
                              });
                    }
                });
            });
        }
        return groupObjArr;
    }

    sortHistoryChanges(historyChanges, state.selectedCondition == "epochDay" ? sortByEpochDay : sortByUserId);

    return (
        <DetailsList
            compact={true}
            items={historyChanges}
            groups={groupsGenerator(historyChanges, state.selectedCondition, state.sortDirection, source)}
            columns={columns}
            setKey="set"
            selectionMode={selectionMode}
            selectionPreservedOnEmptyClick={true}
            enterModalSelectionOnTouch={true}
            selection={selection}
            checkboxVisibility={CheckboxVisibility.always}
            onRenderDetailsHeader={_onRenderDetailsHeader}
            groupProps={{ onRenderHeader: _onRenderGroupHeader }}
            onRenderItemColumn={(item?: HistoryMeta, index?: number, column?: IColumn) => (
                <>
                    {column.key === "date" ? (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: 12,
                                color: "#999EA1",
                                marginBottom: 2,
                                cursor: "pointer",
                            }}
                        >
                            <Icon iconName={Icons.LCMD_Clock} style={{ height: 14, marginRight: 5 }} />
                            <span style={{ lineHeight: "16px" }}>
                                {new Date(item.events[0].date).toLocaleString(getCurrentLanguage(), {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                })}
                            </span>
                        </div>
                    ) : null}
                    <HistoryEntry
                        historyEntry={item}
                        index={index}
                        columnType={column.key}
                        userId={item.events[0].userId}
                        key={index}
                    />
                </>
            )}
            onRenderRow={_onRenderRow}
        />
    );
}

function HistoryFilterButton() {
    const navigate = useNavigate();
    const { state } = useContext(CPContext);

    return (
        <DefaultButton
            className="overwriteFilterButtonStyles"
            styles={{
                root: {
                    backgroundColor: "#F1F3F3",
                    border: 0,
                    borderRadius: "4px",
                },
            }}
            onClick={() => {
                navigate(CHANGES_PANEL_ROUTES.HISTORY_FILTER, {
                    state: { filterData: state.historyFilter },
                });
            }}
        >
            <Stack
                horizontal
                tokens={{ childrenGap: 8 }}
                styles={{ root: { display: "flex", justifyContent: "center", alignItems: "center" } }}
            >
                <StackItem>{intl.get("changesPanel.Filter")}</StackItem>
                {state.historyFilter.size > 0 && (
                    <StackItem>
                        <CounterBubble value={state.historyFilter.size} styleTheme={StyleTheme.BLUE} />
                    </StackItem>
                )}
            </Stack>
        </DefaultButton>
    );
}
