import * as React from "react";
import ResizeObserver from "resize-observer-polyfill";
import { useParticleContext } from "./ParticleContext";

export type ChartCanvas = {
    width: number;
    height: number;
    chart: HTMLCanvasElement;
    yAxis: HTMLCanvasElement;
    canvasState: any;
    overlayState: any;
    detailsProps: any;
    yAxisProps: any;
    //_pendingWidth: number|null,
    //_pendingHeight: number|null
};

export type ChartInstance = {
    setCanvasState: (state) => void;
    setOverlayState: (state, cb?: (state) => void) => void;
};

export type ChartCallbacks = {
    onRenderCanvas: (chart: ChartInstance, canvas: ChartCanvas) => void;
    onRenderOverlay: ({ chart, canvas }: { chart: ChartInstance; canvas: ChartCanvas }) => any;
    onRenderDetails?: ({
        chart,
        canvas,
        style,
    }: {
        chart: ChartInstance;
        canvas: ChartCanvas;
        style: React.CSSProperties;
    }) => any;
    onData: (chart: ChartInstance, data: any) => void;
    onRenderChartJS?: (chart: ChartInstance, canvas: ChartCanvas) => void;
};

type _ChartInstance = ChartInstance & {
    _cb: ChartCallbacks;
};

export type ChartFactory = (chart: ChartInstance) => ChartCallbacks;

export type ChartDataView = {
    name: string;
};

export function Chart(props: { legendContainerId?: string; dataView: ChartDataView; factory: ChartFactory }) {
    const LCMD = useParticleContext();
    const [canvas, _setCanvas] = React.useState({
        width: 0,
        height: 0,
        chart: null,
        yAxis: null,
        canvasState: null,
        overlayState: null,
        detailsProps: null,
        yAxisProps: null,
        //_pendingWidth: null,
        //_pendingHeight: null
    } as ChartCanvas);
    const [instance, _setInstance] = React.useState({
        setCanvasState: null,
        setOverlayState: null,
        _cb: null,
    } as _ChartInstance);
    /*
    const _renderCanvas=React.useCallback(()=>{
        if (instance?._cb?.onRenderCanvas && canvas) {
            instance._cb.onRenderCanvas(instance, canvas);
        }
    }, [instance, canvas]);
    */
    React.useEffect(() => {
        if (props.factory) {
            _setInstance((instance) => {
                const ret = {
                    ...instance,
                    setCanvasState: (state) => {
                        _setCanvas((canvas) => {
                            canvas.canvasState = { ...(canvas.canvasState || {}), ...state };
                            if (instance?._cb?.onRenderCanvas) {
                                requestAnimationFrame(() => {
                                    if (instance._cb.onRenderCanvas) {
                                        instance._cb.onRenderCanvas(instance, canvas);
                                    }
                                });
                            }
                            return canvas; // no update
                        });
                    },
                    setOverlayState: (state, cb?: (state) => void) => {
                        _setCanvas((canvas) => {
                            const ret = { ...canvas, overlayState: { ...(canvas.overlayState || {}), ...state } };
                            if (cb) {
                                cb(ret.overlayState);
                            }
                            // update will be triggered by ref/mount...
                            return ret;
                        });
                    },
                    setDetails: (options) => {
                        _setCanvas((canvas) => {
                            const ret = { ...canvas, detailsProps: options };
                            return ret;
                        });
                    },
                    setYAxis: (options) => {
                        _setCanvas((canvas) => {
                            const ret = { ...canvas, yAxisProps: options };
                            return ret;
                        });
                    },
                };
                ret._cb = props.factory(ret);
                LCMD.registerDataView(props.dataView.name, (data) => {
                    ret._cb.onData(ret, data);
                });
                return ret;
            });
            return () => {
                // cleanup
                LCMD.registerDataView(props.dataView.name, null);
            };
        }
    }, [props.dataView, props.factory, _setInstance, _setCanvas]);
    //const [dim, setDim]=React.useState({width:0, height: 0});
    const observer = React.useMemo(() => {
        const ret = new ResizeObserver((a) => {
            if (1 === a.length) {
                const width = Math.floor(a[0].contentRect.width * 0.4 || 0);
                const height = Math.floor(a[0].contentRect.height || 0);
                _setCanvas((canvas) => ({ ...canvas, width, height }));
            }
        });
        return ret;
    }, [_setCanvas]);
    const containerRef = React.useCallback(
        (r) => {
            if (r) {
                observer.observe(r);
                const contentRect = r.getBoundingClientRect();
                const width = Math.floor(contentRect.width || 0);
                const height = Math.floor(contentRect.height || 0);
                _setCanvas((canvas) => ({ ...canvas, width, height }));
            } else {
                observer.disconnect();
            }
        },
        [observer, _setCanvas],
    );
    const canvasRef = React.useCallback(
        (r: HTMLCanvasElement) => {
            if (r) {
                canvas.chart = r;
                if (instance?._cb?.onRenderChartJS) {
                    instance._cb.onRenderChartJS(instance, canvas);
                }
                if (instance?._cb?.onRenderCanvas) {
                    instance._cb.onRenderCanvas(instance, canvas);
                }
            }
        },
        [canvas, instance],
    );
    const yAxisCanvasRef = React.useCallback(
        (r: HTMLCanvasElement) => {
            if (r) {
                canvas.yAxis = r;
                if (instance?._cb?.onRenderCanvas) {
                    instance._cb.onRenderCanvas(instance, canvas);
                }
            }
        },
        [canvas, instance],
    );
    const canvasStyle = React.useMemo(
        () =>
            ({
                backgroundColor: "white",
                left: canvas?.detailsProps?.width || 0,
                top: 0,
                width: Math.max(0, canvas.width - (canvas?.detailsProps?.width || 0)),
                height: canvas.height,
            }) as React.CSSProperties,
        [instance, canvas.width, canvas.height, canvas?.detailsProps?.width || 0],
    );
    const detailsStyle = React.useMemo(
        () =>
            canvas?.detailsProps?.width > 0
                ? ({
                      backgroundColor: "white",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: canvas?.detailsProps?.width || 0,
                      height: canvas.height,
                  } as React.CSSProperties)
                : null,
        [instance, canvas.width, canvas.height, canvas?.detailsProps?.width || 0],
    );
    const yAxisStyle = React.useMemo(
        () =>
            canvas?.yAxisProps?.width > 0
                ? ({
                      backgroundColor: "transparent",
                      position: "absolute",
                      right: 0,
                      top: 0,
                      width: canvas?.yAxisProps?.width || 0,
                      height: canvas.height,
                  } as React.CSSProperties)
                : null,
        [instance, canvas.width, canvas.height, canvas?.yAxisProps?.width || 0],
    );
    React.useEffect(() => {
        const grid = LCMD.worker.canvas.grid;
        const col0 = grid?.view?.f ? grid.view.f.g0 : 0;
        const col1 = grid?.view?.f ? grid.view.f.g1 : grid?.view?.cols || 0;
        LCMD.worker.postMessage([
            "stats",
            "view",
            {
                name: props.dataView.name,
                grid_ts: grid?.ts || 0,
                col0,
                col1,
                scale: 1.0,
            },
        ]);
    }, [canvas.width, canvas.height, LCMD.worker.canvas.grid?.ts || 0, LCMD.worker.canvas.grid?.view?.cols || 0]);
    return (
        <div
            ref={containerRef}
            style={{
                width: "100%",
                height: "100%",
                overflow: "overlay",
                position: "relative",
                display: "flex",
                alignItems: "center",
                left: 0,
                top: 0,
            }}
        >
            <div
                style={{
                    width: props.legendContainerId?.length > 0 ? "40%" : "100%",
                }}
            >
                {instance &&
                instance._cb &&
                canvas &&
                canvas.width > 0 &&
                canvas.height > 0 &&
                instance._cb.onRenderDetails &&
                canvas.overlayState ? (
                    <instance._cb.onRenderDetails canvas={canvas} chart={instance} style={detailsStyle} />
                ) : null}
                {instance && instance._cb && canvas && canvas.width > 0 && canvas.height > 0 ? (
                    <canvas width={canvas.width} height={canvas.height} ref={canvasRef} style={canvasStyle} />
                ) : null}
                {instance && instance._cb && canvas && canvas.width > 0 && canvas.height > 0 && yAxisStyle ? (
                    <canvas
                        width={canvas?.yAxisProps?.width || 0}
                        height={canvas.height}
                        ref={yAxisCanvasRef}
                        style={yAxisStyle}
                    />
                ) : null}
                {instance &&
                instance._cb &&
                canvas &&
                canvas.width > 0 &&
                canvas.height > 0 &&
                instance._cb.onRenderOverlay &&
                canvas.overlayState ? (
                    <instance._cb.onRenderOverlay canvas={canvas} chart={instance} />
                ) : null}
            </div>
            {props.legendContainerId?.length > 0 ? (
                <div
                    id={"legend-container-" + props.legendContainerId}
                    style={{
                        width: "60%",
                    }}
                ></div>
            ) : (
                ""
            )}
        </div>
    );
}
