import * as React from "react";
import { Persona } from "@fluentui/react";
import { FilterRowTop } from "./FilterRowTop";
import { filterRowStyles } from "./filterRowStyles";

type CreatedByFilterRow = {
    label: string;
    badgeCount: number;
    data: any[];
    onModifyButtonClick: () => void;
};

export function RaisedByFilterRow({ label, badgeCount, onModifyButtonClick, data }: CreatedByFilterRow) {
    return (
        <div className={filterRowStyles.body}>
            <FilterRowTop onModifyButtonClick={onModifyButtonClick} label={label} badgeCount={badgeCount} />
            <div>
                {data.map((p) => {
                    return (
                        <div className={filterRowStyles.button} key={p.email}>
                            <Persona text={p.fullName ? p.fullName : p.email} size={10} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
