export type TradeImageType = {
    id: string;
    title: string;
    img: string;
};

export const tradeImages: TradeImageType[] = [
    {
        id: "1",
        title: "2lassfaser2",
        img: "../../img/lcmd_trade_icons/2lassfaser2.svg",
    },
    {
        id: "2",
        title: "arrows",
        img: "../../img/lcmd_trade_icons/arrows.svg",
    },
    {
        id: "3",
        title: "attachment",
        img: "../../img/lcmd_trade_icons/attachment.svg",
    },
    {
        id: "4",
        title: "axt",
        img: "../../img/lcmd_trade_icons/axt.svg",
    },
    {
        id: "5",
        title: "beton",
        img: "../../img/lcmd_trade_icons/beton.svg",
    },
    {
        id: "6",
        title: "bohrunternehmen",
        img: "../../img/lcmd_trade_icons/bohrunternehmen.svg",
    },
    {
        id: "7",
        title: "brik",
        img: "../../img/lcmd_trade_icons/brik.svg",
    },
    {
        id: "8",
        title: "briks",
        img: "../../img/lcmd_trade_icons/briks.svg",
    },
    {
        id: "9",
        title: "brush",
        img: "../../img/lcmd_trade_icons/brush.svg",
    },
    {
        id: "10",
        title: "car",
        img: "../../img/lcmd_trade_icons/car.svg",
    },
    {
        id: "11",
        title: "clamp",
        img: "../../img/lcmd_trade_icons/clamp.svg",
    },
    {
        id: "12",
        title: "doc",
        img: "../../img/lcmd_trade_icons/doc.svg",
    },
    {
        id: "13",
        title: "drop",
        img: "../../img/lcmd_trade_icons/drop.svg",
    },
    {
        id: "14",
        title: "Electric-Saw",
        img: "../../img/lcmd_trade_icons/Electric-Saw.svg",
    },
    {
        id: "15",
        title: "electro",
        img: "../../img/lcmd_trade_icons/electro.svg",
    },
    {
        id: "16",
        title: "elevator",
        img: "../../img/lcmd_trade_icons/elevator.svg",
    },
    {
        id: "17",
        title: "erde",
        img: "../../img/lcmd_trade_icons/erde.svg",
    },
    {
        id: "18",
        title: "flame",
        img: "../../img/lcmd_trade_icons/flame.svg",
    },
    {
        id: "19",
        title: "floor",
        img: "../../img/lcmd_trade_icons/floor.svg",
    },
    {
        id: "20",
        title: "glasfaser1",
        img: "../../img/lcmd_trade_icons/glasfaser1.svg",
    },
    {
        id: "21",
        title: "hammer",
        img: "../../img/lcmd_trade_icons/hammer.svg",
    },
    {
        id: "22",
        title: "hammer2",
        img: "../../img/lcmd_trade_icons/hammer2.svg",
    },
    {
        id: "23",
        title: "heater",
        img: "../../img/lcmd_trade_icons/heater.svg",
    },
    {
        id: "24",
        title: "helmet",
        img: "../../img/lcmd_trade_icons/helmet.svg",
    },
    {
        id: "25",
        title: "ICON_Placeholder_28_flat",
        img: "../../img/lcmd_trade_icons/ICON_Placeholder_28_flat.svg",
    },
    {
        id: "26",
        title: "kabel",
        img: "../../img/lcmd_trade_icons/kabel.svg",
    },
    {
        id: "27",
        title: "luftung",
        img: "../../img/lcmd_trade_icons/luftung.svg",
    },
    {
        id: "28",
        title: "ph_fan-fill",
        img: "../../img/lcmd_trade_icons/ph_fan-fill.svg",
    },
    {
        id: "29",
        title: "pipe",
        img: "../../img/lcmd_trade_icons/pipe.svg",
    },
    {
        id: "30",
        title: "plan",
        img: "../../img/lcmd_trade_icons/plan.svg",
    },
    {
        id: "31",
        title: "roof",
        img: "../../img/lcmd_trade_icons/roof.svg",
    },
    {
        id: "32",
        title: "saw",
        img: "../../img/lcmd_trade_icons/saw.svg",
    },
    {
        id: "33",
        title: "spatula",
        img: "../../img/lcmd_trade_icons/spatula.svg",
    },
    {
        id: "34",
        title: "spere",
        img: "../../img/lcmd_trade_icons/spere.svg",
    },
    {
        id: "35",
        title: "spere2",
        img: "../../img/lcmd_trade_icons/spere2.svg",
    },
    {
        id: "36",
        title: "tief",
        img: "../../img/lcmd_trade_icons/tief.svg",
    },
    {
        id: "37",
        title: "traktor",
        img: "../../img/lcmd_trade_icons/traktor.svg",
    },
    {
        id: "38",
        title: "treppe",
        img: "../../img/lcmd_trade_icons/treppe.svg",
    },
    {
        id: "39",
        title: "welder",
        img: "../../img/lcmd_trade_icons/welder.svg",
    },
    {
        id: "40",
        title: "window",
        img: "../../img/lcmd_trade_icons/window.svg",
    },
    {
        id: "41",
        title: "wood",
        img: "../../img/lcmd_trade_icons/wood.svg",
    },
];

export const defaultTradeImage = {
    id: "24",
    title: "helmet",
    img: "../../img/lcmd_trade_icons/helmet.svg",
};

export const tradeImagesMap = new Map<string, TradeImageType>();
tradeImages.forEach((image) => {
    tradeImagesMap.set(image.title, image);
});
