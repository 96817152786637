import * as React from "react";
import { useCanvasTooltipStore } from "../store/tooltipStore";
import { toast, Id, ToastContainer } from "react-toastify";
import { ToastOptions } from "react-toastify/dist/types";
import "react-toastify/dist/ReactToastify.css";
import { ProcessTooltipWrapper } from "@/components/common/ProcessTooltip/Wrapper";

// this is a component container for components we want to inject on very high DOM level. U can use this to inject modals or tooltips if you need a high dom lvl
const Msg = ({ title, text, link }: { title: string; text: string; link?: string }) => {
    return (
        <div className="msg-container">
            <p style={{ fontWeight: "bold" }} className="msg-title">
                {title}
            </p>
            <p className="msg-description">{text}</p>
            {/*<a href={link} target="_blank" rel="noreferrer">{intl.get("ClipboardToast.linkText")}</a>*/}
        </div>
    );
};

export const CopyToaster = (myProps, toastProps: ToastOptions): Id => toast(<Msg {...myProps} />, { ...toastProps });

// CopyToaster.success = (myProps, toastProps): Id =>
//     toast.success(<Msg {...myProps} />, { ...toastProps });

export function UtilsContainer() {
    const { task, visible, x, y } = useCanvasTooltipStore();

    return (
        <div className={"invisibleUtilsComponent"}>
            {/* redesigned shadcn Process Tooltip  */}
            {visible ? <ProcessTooltipWrapper canvasData={task} x={x} y={y} /> : null}
            <ToastContainer className={"toastify"} />
        </div>
    );
}
