import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip";

type LCMDTooltipProps = {
    text?: string;
    children?: React.ReactNode;
    userflowId?: string;
    className?: string;
    side?: "top" | "right" | "bottom" | "left";
    asChild?: boolean
};

export function LCMDTooltip({ text, children, userflowId, side, className, asChild = false }: LCMDTooltipProps) {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild={asChild} className={`leading-none ${className}`} data-userflow-id={userflowId}>
                    {children}
                </TooltipTrigger>
                <TooltipContent side={side}>
                    <p>{text}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
}
