import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IDialogContentProps,
    mergeStyleSets,
    PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import { useMemo } from "react";
import { intl } from "@/legacy/GlobalHelperReact";

const classNames = mergeStyleSets({
    modal: {
        width: "800px",
        height: "600px",
    },
});

export function CalendarExceptionDeleteDialog({
    exceptionTitle,
    onClose,
    onCancel,
}: {
    exceptionTitle: string;
    onCancel: () => void;
    onClose: () => void;
}) {
    const dialogContentProps: IDialogContentProps = useMemo<IDialogContentProps>(() => {
        return {
            type: DialogType.close,
            subText: `${intl.get("CalendarExceptionMainScreen.DeleteDialog.DeleteText")} '${exceptionTitle}'`,
            titleProps: {
                color: "#565C60",
            },
            styles: {
                header: {
                    borderBottom: "1px solid #E1E4E5",
                },
            },
        };
    }, [exceptionTitle]);

    return (
        // @ts-ignore
        <Dialog
            onDismiss={onCancel}
            isOpen={true}
            title={intl.get("CalendarExceptionMainScreen.DeleteDialog.DeleteTitle")}
            dialogContentProps={dialogContentProps}
        >
            <DialogFooter>
                <DefaultButton onClick={onCancel} styles={{ root: { borderRadius: "4px" } }}>
                    {intl.get("CalendarExceptionMainScreen.DeleteDialog.CancelButtonText")}
                </DefaultButton>
                <PrimaryButton styles={{ root: { borderRadius: "4px", backgroundColor: "#A4262C" } }} onClick={onClose}>
                    {intl.get("CalendarExceptionMainScreen.DeleteDialog.DeleteButtonText")}
                </PrimaryButton>
            </DialogFooter>
        </Dialog>
    );

    // return (<Modal styles={{
    //     main: {
    //         width: '800px',
    //         height: '600px'
    //     },
    //     scrollableContent: {
    //         height: '100%'
    //     }
    // }} isOpen={true}>
    //     <Stack horizontalAlign={"space-between"}>
    //         <Stack data-role={'header'} verticalAlign="center"
    //                                styles={{root: {borderBottom: '1px solid #E1E4E5', height: '60px'}}}>
    //             <Text variant='xxLarge' styles={{root: {fontSize: '20px', lineHeight: '28px'}}}>{'Delete exception'}</Text>
    //         </Stack>
    //         <Stack data-role={'body'} styles={{root: {height: '100%'}}} grow={true} verticalFill={true}>
    //
    //         </Stack>
    //         <Stack data-role={'footer'} horizontal horizontalAlign={'end'} gap={'15px'} styles={{root: {borderTop: '1px solid #E1E4E5', height: '60px'}}}>
    //             <DefaultButton>Abbrechen</DefaultButton>
    //             <PrimaryButton>Ok</PrimaryButton>
    //         </Stack>
    //     </Stack>
    // </Modal>)
}
