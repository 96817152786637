import { DataModelCacheProvider, DataOperation } from "./DataModel";
import { assert } from "./GlobalHelper";

const _indexedDB =
    "undefined" !== typeof self
        ? self.indexedDB ||
          (self as any).webkitIndexedDB ||
          (self as any).mozIndexedDB ||
          (self as any).OIndexedDB ||
          (self as any).msIndexedDB
        : undefined;
//const _IDBTransaction = self.IDBTransaction || (self as any).webkitIDBTransaction || (self as any).OIDBTransaction || (self as any).msIDBTransaction;
const _dbVersion = 1.0;

type DataStorageDB = {
    _db: IDBDatabase;
    _max: number;
};

export class DataStorage implements DataModelCacheProvider {
    private static _current: DataStorageDB = null;
    private static DB_VERSION_PREFIX = "V1";
    public static ensureOpenDB(storageUUID: string) {
        return new Promise((resolve: (ret: DataStorageDB) => void, reject: (err) => void) => {
            if (_indexedDB) {
                const storageID = [storageUUID, DataStorage.DB_VERSION_PREFIX /*SERVICES.MAJOR*/].join(":");
                if (DataStorage._current?._db?.name !== storageID) {
                    if (DataStorage._current?._db) {
                        DataStorage._current._db.close();
                        DataStorage._current = null;
                    }
                    if (storageID) {
                        const req: IDBOpenDBRequest = _indexedDB.open(storageID, _dbVersion);
                        req.onerror = () => {
                            reject(req.error);
                        };
                        req.onsuccess = () => {
                            DataStorage._current = {
                                _db: req.result,
                                _max: null,
                            };
                            resolve(DataStorage._current);
                        };
                        req.onupgradeneeded = (e: IDBVersionChangeEvent) => {
                            const _db: IDBDatabase = (e.target as any).result;
                            _db.createObjectStore("OPS", {
                                /*keyPath: 'ts', autoIncrement:true*/
                            });
                        };
                    } else {
                        resolve(null);
                    }
                } else {
                    assert(DataStorage._current?._db?.name === storageID);
                    resolve(DataStorage._current);
                }
            } else {
                resolve(null);
            }
        });
    }

    public static async dropAllDatabase() {
        const dbs = await window.indexedDB.databases();
        dbs.forEach((db) => {
            window.indexedDB.deleteDatabase(db.name);
        });
    }

    /*
    public appendOps(ts:number, ops:DataOperation[]) {
        return new Promise((resolve:(ret:boolean)=>void, reject:(err)=>void)=>{
            const transaction = this._db.transaction(['OPS'], 'readwrite');
            let _ops = transaction.objectStore('OPS');
            for(let i=0;i<ops.length;i++) {
                const req=_ops.add(ops[i], i);
            }
            transaction.onerror=()=>{
                reject(transaction.error);
            }
            transaction.oncomplete=()=>{
                resolve(true);
            }
        });
    }

    public fetchOps(ts:number) {
        return new Promise((resolve:(ret:DataOperation[])=>void, reject:(err)=>void)=>{
            let _ops = this._db.transaction('OPS').objectStore('OPS');
            const _cursor=_ops.openCursor();
            const ret=[];
            _cursor.onsuccess=(e:any)=>{
                const cursor=e.target.result;
                if (cursor) {
                    assert(ret.length===cursor.key);
                    ret.push(cursor.value);
                    cursor.continue();
                } else {
                    resolve(ret);
                }
            }
            _cursor.onerror=()=>{
                reject(_cursor.error);
            }
        });
    }

    public async loadModel(ts:number) {
        let m:DataModel=null;
        try {
            const ops=await this.fetchOps(0);
            if (ops && ops.length>0) {
                m=DataModel.loadOps(ops, true);
            }
        } catch(e) {
            m=null;
        }
        return m;
    }
    */

    fetchStream(sid: string, start: number, end?: number): Promise<DataOperation[] | undefined> {
        return new Promise<DataOperation[] | undefined>(async (resolve, reject) => {
            const clearCacheAndResolve = (_current, key) => {
                assert(null === _current._max); // cache disabled
                resolve(undefined);
            };
            const _current = await DataStorage.ensureOpenDB(sid);
            if (start===end) {
                resolve([]);
            } else if (_current) {
                const db = _current._db;
                const ret = [];
                assert(db.name.split(":")[0] === sid);
                const store = db.transaction("OPS", "readonly").objectStore("OPS");
                const range = end ? IDBKeyRange.bound(start, end, false, true) : IDBKeyRange.lowerBound(start);
                const c = store.openCursor(range);
                c.onsuccess = (event) => {
                    const cursor = (event.target as any).result;
                    if (cursor) {
                        if (start + ret.length === cursor.key) {
                            const value = cursor.value;
                            if (Array.isArray(value)) {
                                const ofs = ret.length;
                                value.forEach(function (v, i) {
                                    ret.splice(ofs + i, 0, v);
                                }, this);
                            } else {
                                assert("object" === typeof value);
                                ret.push(value);
                            }
                            cursor.continue();
                        } else {
                            clearCacheAndResolve(_current, cursor.key); // cache failed
                        }
                    } else {
                        if (end) {
                            assert(start + ret.length <= end);
                        }
                        if (ret.length > 0 && undefined === ret[0]._sid) {
                            // mark start of stream with storage id
                            console.warn("legacy sid fix"); // should no longer happen, see (1)....
                            ret[0]._sid = sid;
                        }
                        if (null === _current._max) {
                            _current._max = start;
                        }
                        if (ret.length > 0) {
                            _current._max = Math.max(_current._max, start + ret.length);
                        }
                        resolve(ret.length > 0 ? ret : undefined);
                    }
                };
                c.onerror = (event) => {
                    clearCacheAndResolve(_current, 0); // cache failed
                };
            } else {
                resolve(undefined);
            }
        });
    }

    cacheStream(sid: string, start: number, end: number | undefined, ops: DataOperation[]): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            if (ops.length > 0) {
                const _current = await DataStorage.ensureOpenDB(sid);
                if (_current?._db && null !== _current._max) {
                    if (start === _current._max) {
                        const store = _current._db.transaction("OPS", "readwrite").objectStore("OPS");
                        if (ops.length > 0 && undefined === ops[0]._sid) {
                            // (1) fix: mark start of stream with storage id
                            ops[0] = { ...ops[0], _sid: sid };
                        }
                        const r = store.add(ops, start);
                        r.onsuccess = (event) => {
                            _current._max += ops.length;
                            resolve(true);
                        };
                        r.onerror = (event) => {
                            console.warn(event);
                            console.warn("disable caching (two browser sessions?)");
                            _current._max = null; // disable cache!!!
                            resolve(false); // cache error!!!!
                        };
                    } else {
                        console.warn("disable caching (" + JSON.stringify({ start, _max: _current._max }) + ")");
                        _current._max = null; // disable cache!!!
                        resolve(false); // cache error!!!!
                    }
                } else {
                    resolve(false);
                }
            } else {
                resolve(true);
            }
        });
    }

    private _getAll(db: IDBDatabase) {
        return new Promise((resolve: (ret: any) => void, reject: (err) => void) => {
            const ret = [];
            const store = db.transaction("OPS", "readonly").objectStore("OPS");
            const c = store.openCursor();
            c.onsuccess = (event) => {
                const cursor = (event.target as any).result;
                if (cursor) {
                    const key = cursor.primaryKey;
                    const value = cursor.value;
                    ret.push({ key, value });
                    cursor.continue();
                } else {
                    resolve(ret);
                }
            };
            c.onerror = (event) => {
                resolve(null);
            };
        });
    }

    async _exportCache() {
        const current = DataStorage._current?._db;
        return current
            ? {
                  db_name: current.name,
                  OPS: await this._getAll(current),
              }
            : null;
    }
}
