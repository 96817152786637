import * as React from "react";
import { ReactNode } from "react";

import { Stack, IStackTokens } from "@fluentui/react";

export type RowProps = {
    children: ReactNode;
};

const tokenProps: IStackTokens = {
    childrenGap: 20,
};

function Row({ children }: RowProps) {
    return (
        <Stack wrap horizontal horizontalAlign="center" tokens={tokenProps}>
            {children}
        </Stack>
    );
}

export { Row };
