﻿import { LCMDContextCardStatus } from "../model/GlobalHelper";

/**
 * @public
 */
export enum LCMDContextUnitType {
    NONE = 0,
    MINUTES = 1, // 1. Minutes
    HOURS = 2, // 2. Hours
    DAYS = 3, // 3. Days
    WEEKS = 4, // 4. Weeks
    MONTH = 5, // 5. Months
    PERCENT = 6, // 6. Percent
    YEARS = 7, // 7. Years
    ELLAPSED_MINUTES = 8, // 8. Elapsed Minutes
    ELLAPSED_HOURS = 9, // 9. Elapsed Hours
    ELLPASED_DAYS = 10, // 10. Elapsed Days
    ELLAPSED_WEEKS = 11, // 11. Elapsed Weeks
    ELLAPSED_MONTH = 12, // 12. Elapsed Months
    ELLAPSED_YEARS = 13, // 13. Elapsed Years
    ELLAPSED_PERCENT = 14, // 14. Elapsed Percent
}

/**
 * @public
 */
export enum LCMDContextDependencyType {
    INVALID, // INVALID/DELETED
    EE, //"EE",
    EA, //"EA",
    AE, //"AE",
    AA, //"AA"
}

/**
 * @public
 */
export type LCMDContextProjectDetails =
    | (any & {
          meta: {
              name: string;
          };
      })
    | null;
/**
 * @public
 */
export type LCMDContextBaselineDetails = any & {
    /** timestamp id */
    i: number;
    /** user id */
    u: string;
    /** date in epoch milliseconds */
    d: number;
};
/**
 * @public
 */
export type LCMDContextBaselineResponse = {
    revId: number;
    data: LCMDContextBaselineDetails[];
};
/**
 * @public
 */
export type LCMDContextTradeDetails = {
    projectId: string;
    trid: number;
};
/**
 * @public
 */
export type LCMDContextTradesResult = {
    projectId: string;
    trades: LCMDContextTradeDetails[];
};
/**
 * @public
 */
export type LCMDContextTaskDetailsResultTradeDetails = {
    /** trade id */
    trid: number;
    /** trade name */
    name: string;
    /** "extra" trade name (legacy from T3) */
    trade: string;
    /** trade color  */
    color: number;
};
/**
 * @public
 */
export type LCMDContextTaskDetailsResultDependencyDetails = {
    /** dependency source process pid */
    pid: number;
    /** dependency target process pid */
    targetId: number;
    /** dependency target process name */
    targetName: string;
    /** dependency lag value */
    lag: number;
    /** dependency type */
    type: LCMDContextDependencyType;
    /** dependency lag unit */
    unit: LCMDContextUnitType;
    /** true, iff the dependency is valid (i.e. not violated) */
    valid: boolean;
    /** dependency lag needed to resolve conflict */
    conflictOffset?: number;
};
/**
 * @public
 */
export type LCMDContextFindResult = {
    findText: string;
    processes: {
        pid: number;
    }[];
};
/**
 * @public
 */
export type LCMDCOntextAddPredecessorState = {
    /** dependency source process pid */
    src: number;
    /** dependency target process pid */
    dst: number;
    /** dependency type */
    type: LCMDContextDependencyType;
    lag: [number /** dependency lag value */, LCMDContextUnitType /** dependency type */];
};
/**
 * @public
 */
export type LCMDContextTaskDetailsResultCardDetails = {
    /** "timestamp" of the card */
    _: number;
    /** card/task ID */
    tid: string;
    /* card name */
    name: string;
    /* card status */
    s: LCMDContextCardStatus;
    /** true if the card has attachments */
    atm: boolean;
    /** workforce for the card */
    wf: number;
    /* card date */
    date?: number;
};
/**
 * @public
 */
export type LCMDContextTaskDetailsResultSingleOrArrayItem<T> = null | {
    _: number;
    value: null | T | T[];
    readonly?: boolean;
    indeterminate?: boolean;
};
/**
 * @public
 */
export type LCMDContextTaskDetailsResultSingleItem<T> = null | {
    _: number;
    value: null | T;
    readonly?: boolean;
    indeterminate?: boolean;
};
/**
 * @public
 */
export type LCMDContextTaskDetailsResultSingleItemWithDetails<T, D> =
    | null
    | ({
          _: number;
          value: null | T;
          readonly?: boolean;
          indeterminate?: boolean;
      } & D);
/**
 * @public
 */
export type LCMDContextTaskDetailsResultSingleItemUnit<T> = LCMDContextTaskDetailsResultSingleItemWithDetails<
    T,
    { unit?: LCMDContextUnitType }
>;
/**
 * @public
 */
export type LCMDContextTaskDetailsResultArrayItem<T> = null | {
    _: number;
    value: null | T[];
    readonly?: boolean;
    indeterminate?: boolean;
};
/**
 * @public
 */
export type LCMDContextProcessDetailsState = {
    /** process start date, set either date in local time or value in epoch milliseconds */
    start?: { value?: number } & { date?: Date };
    /** process duration, unit is DAYS if not net */
    duration?: { value: number; unit?: LCMDContextUnitType };
    /** y index */
    yindex?: { value: number };
    /** process name */
    name?: { value: string };
    /** process workforce */
    workforce?: { value: number };
    /** process trades, deleted the ones not present in the array */
    trades?: { value: (number | { value: number; completed?: number })[] };
    /** sets the status for all cards of the process */
    cards?: { s?: LCMDContextCardStatus };
    /** ppid (parent process id), -1 deleted it */
    ppid?: { value: number; index?: number };
    /** teams */
    teams?: { value: number };
    /** train */
    train?: { value: number | string };
    /** trains */
    trains?: {
        [train: string]: {
            takt: {
                value: number;
            };
        };
    };
    /** image */
    image?: { value: { blobId: string; fileName?: string; contentType?: string } };
};
/**
 * @public
 */
export type LCMDContextTradeDetailsState = {
    /** name, set "null" for delete flag */
    name?: { value: string | null };
    /** trade */
    trade?: { value: string };
    /** color */
    color?: { value: number };
    /** icon */
    icon?: { value: string };
};
/**
 * @public
 */
export type LCMDContextDependencyDetailsState = {
    /** dependency lag value */
    lag?: number;
    /** dependency lag unit */
    unit?: LCMDContextUnitType;
    /** dependency type */
    type: LCMDContextDependencyType;
};
/**
 * @public
 */
export type LCMDContextCardDetailsState = {
    /** card name */
    name?: string;
    /** card status */
    s?: LCMDContextCardStatus;
    /** card workforce */
    wf?: number;
    /** y-pos */
    y?: number;
    /** comment */
    comment?: string;
};
/**
 * @public
 */
export type LCMDContextTaskHistoryEvent = {
    /** timestamp */
    _: number;
    /** change */
    c: string;
    /** value changed */
    name: string;
    /** change type: 0===process, 1===card, 2===comment  */
    type: number;
    /** user id */
    u: string;
    /** change date*/
    date: Date;

    imported: boolean;
};
/**
 * @public
 */
export type LCMDContextTaskHistoryResult = {
    _: number;
    taskId: number;
    events: LCMDContextTaskHistoryEvent[];
};
/**
 * @public
 */
export type LCMDContextTaskDetailsResult = {
    /** current "timestamp" */
    _: number;
    /** curent projectId */
    projectId: string;
    /** project info */
    project: {
        /** gpa preview is enabled */
        gpaPreview?: {
            trainInfos: {
                [id: string]: {
                    id: number;
                    /** start date in EpochMS */
                    date?: number;
                    dep?: string;
                };
            };
        };
    };
    /** process id (pid) */
    pid: LCMDContextTaskDetailsResultSingleOrArrayItem<number>;
    /** parent process id (ppid) */
    ppid: LCMDContextTaskDetailsResultSingleOrArrayItem<number>;
    /** parent paths */
    ppaths: LCMDContextTaskDetailsResultArrayItem<{ id: number; name: string }[]>;
    /** true if process  starts and end  on whole day boundaries, false if duration has sub-day precision */
    isAllDay: LCMDContextTaskDetailsResultSingleItem<boolean>;
    /** true if the process is fixed due to a dailyboard card set on a non-working day  */
    isFixed: LCMDContextTaskDetailsResultSingleItem<boolean>;
    /** convenient method, true if the duration is 0 */
    isMilestone: LCMDContextTaskDetailsResultSingleItem<boolean>;
    isVirtual: LCMDContextTaskDetailsResultSingleItem<boolean>;

    milestoneState: LCMDContextTaskDetailsResultSingleItem<LCMDContextCardStatus>;
    /** process start in epoch milliseconds, Date object in local time for convenience, calendar week (cw) for convenience */
    start: LCMDContextTaskDetailsResultSingleItemWithDetails<number, { date?: Date; day?: number; cw?: number }>;
    /** process end in epoch milliseconds, Date object in local time for convenience, calendar week (cw) for convenience */
    end: LCMDContextTaskDetailsResultSingleItemWithDetails<number, { date?: Date; day?: number; cw?: number }>;
    /** duation, unit is LCMDContextUnitType */
    duration: LCMDContextTaskDetailsResultSingleItemUnit<number> & {
        wd: number | null /* week days, or null if unit cannot be converted into whole days */;
        ed: number | null /* ellapsed days, or null if unit cannot be converted into whole days */;
    };
    /** process name */
    name: LCMDContextTaskDetailsResultSingleItem<string>;
    /** workforce */
    workforce: LCMDContextTaskDetailsResultSingleItem<number>;
    /** image */
    image: LCMDContextTaskDetailsResultSingleItemWithDetails<
        { blobId?: string; contentType?: string; fileName?: string },
        { url?: string }
    >;
    /** trades */
    trades: LCMDContextTaskDetailsResultArrayItem<LCMDContextTaskDetailsResultTradeDetails>;
    /** predecessors dependencies */
    predecessors: LCMDContextTaskDetailsResultArrayItem<LCMDContextTaskDetailsResultDependencyDetails>;
    /** successors dependencies */
    successors: LCMDContextTaskDetailsResultArrayItem<LCMDContextTaskDetailsResultDependencyDetails>;
    /** cards */
    cards: LCMDContextTaskDetailsResultArrayItem<LCMDContextTaskDetailsResultCardDetails>;
    /** todos */
    todos: LCMDContextTodoResult;
    /** teams */
    teams: LCMDContextTaskDetailsResultSingleItem<number>;
    /** train */
    train: LCMDContextTaskDetailsResultSingleItemWithDetails<string | number, { takt: number }>;
    /** ref sid */
    rsid?: string | string[];
    /** ref id */
    rid?: number | number[];
    /** children */
    children?: number[];
    areaPath?: { id: number; name: string }[];
};
/**
 * @public
 */
export type LCMDContextTodoItemProcessDetails = {
    _: number;
    pid: number;
    name: string;
    trades: LCMDContextTaskDetailsResultTradeDetails[];
    color: number;
};
/**
 * @public
 */
export type LCMDContextTodoItem = {
    id: number | string;
    process: LCMDContextTodoItemProcessDetails;
    stab?: {
        key: string;
    } & any;
    ai?: {
        id: number;
    } & any;
    done: boolean;
    due: number;
    createdAt?: number | undefined;
};
/**
 * @public
 */
export type LCMDContextTodoItemTarget = {
    pid: number;
    id?: number | "action" | "stability";
    stab?: string;
};
/**
 * @public
 */
export type LCMDContextLibraryItemTarget = {
    id?: number;
    name?: string;
    p?: -1;
};
/**
 * @public
 */
export type LCMDUploadToken = any;
/**
 * @public
 */
export type LCMDContextTodoResult = {
    today: {
        value: number;
    };
    stabs: any;
    items: LCMDContextTodoItem[];
};
/**
 * @public
 */
export type LCMDContextWhiteboardState = {
    name?: {
        value: string;
    };
};
/**
 * @public
 */
export type LCMDChangesPanelChangesTaskEvent = {
    changedProperties: Array<{
        action: "startDate" | "duration";
        value: string | { value: string; unit: LCMDContextUnitType };
    }>;
    /** change timestamp */
    _: number;
    /** chagne type */
    type: number;
    /** change name */
    name: string;
    /** user sub/uuid */
    u: string;
    /** comment */
    c: string;
};
/**
 * @public
 */
export type LCMDChangesPanelChangesTaskConflict = {
    /** process id */
    tid: number;
    /** process name */
    name: string;
    /** 0===green, 1===red, 2===yellow */
    color: number;
    /** delta=changes needed in days to resolve conflict */
    delta: number;
    /** level */
    level: number;
    /** start date in epoch ms */
    start: number;
    /** end date in epoch ms */
    end: number;
};
/**
 * @public
 */
export type LCMDChangesPanelChangesTask = {
    _: number;
    tid: number;
    name: string;
    events: LCMDChangesPanelChangesTaskEvent[];
    /** if exist conflict[0].tid===this.tid, array is sorted by level */
    conflict?: LCMDChangesPanelChangesTaskConflict[];
};
/**
 * @public
 */
export type LCMDChangesPanelChanges = {
    _: number;
    role?: string;
    sub?: string;
    tasks?: LCMDChangesPanelChangesTask[];
    history: any[];
    showConflicts?: boolean;
};

export interface DialogRouteParams<T> {
    route: {
        params: T;
    };
}

/**
 * @public
 */
export type LCMDProjectInfo = any;
