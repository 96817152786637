import React, { useState, useEffect } from "react";
import { XCircle } from "lucide-react";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { Chip } from "../ToDo/interface";

interface MultiSelectFormFieldProps {
    options: Chip[];
    onValueChange: (value: Chip[]) => void;
    values?: Chip[];
    disabled?: boolean;
    placeholder?: string;
}

export const TagsMultiSelect = ({
    values,
    onValueChange,
    options,
    disabled,
    placeholder,
}: MultiSelectFormFieldProps) => {
    const [selectedValues, setSelectedValues] = useState<Chip[]>(values);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>("");

    useEffect(() => {
        setSelectedValues(values);
    }, [values]);

    useEffect(() => {
        inputValue ? setIsDropdownOpen(true) : setIsDropdownOpen(false);
    }, [inputValue]);

    const toggleOption = (value: any) => {
        const isSelected = selectedValues.find((el) => el.key === value.key);
        const updatedValues = isSelected
            ? selectedValues.filter((selectedValue) => selectedValue.key !== value.key)
            : [...selectedValues, value];

        setSelectedValues(updatedValues);
        onValueChange(updatedValues);
    };

    const filteredOptions = options.filter(
        (option) =>
            option.name.toLowerCase().includes(inputValue.toLowerCase()) && !values.find((el) => el.key === option.key),
    );

    return (
        <div className="relative w-full">
            <div className="flex h-10 w-full flex-wrap items-center rounded-md border border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:bg-zinc-200 disabled:opacity-50">
                {!disabled &&
                    selectedValues?.map((tag, index) => (
                        <Badge key={index} className="ml-1 h-8 rounded-full">
                            {tag.name}
                            <XCircle
                                className="ml-2 h-4 w-4 cursor-pointer"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    toggleOption(tag);
                                }}
                            />
                        </Badge>
                    ))}
                <input
                    type="text"
                    className="h-full flex-grow rounded-md p-2 outline-none disabled:cursor-not-allowed"
                    value={inputValue}
                    disabled={disabled}
                    placeholder={!options.length && placeholder}
                    onChange={(event) => setInputValue(event.target.value)}
                />
            </div>
            <div
                className={cn(
                    "absolute top-11 z-[10000100] w-full min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                    !isDropdownOpen && "hidden",
                )}
            >
                <ul>
                    {filteredOptions.length ? (
                        filteredOptions.map((item) => (
                            <li key={item.key}>
                                <div
                                    className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-accent hover:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
                                    onClick={() => {
                                        toggleOption(item);
                                        setInputValue("");
                                    }}
                                >
                                    <p className="mx-1 w-full break-words text-base text-sm font-normal leading-5 tracking-tighter text-[#565C60]">
                                        {item.name}
                                    </p>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li>
                            <div className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
                                <p className="mx-1 w-full break-words text-base text-sm font-normal leading-5 tracking-tighter text-[#565C60]">
                                    No Item Found
                                </p>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};
