import { PointerEvent } from "react";

export type MilestonesChartCanvasState = {
    canvasState: any;
    setCanvasState: (state: any) => void;
    setOverlayState: (state: any) => void;
    setOptions: (options: any) => void;

    chart: HTMLCanvasElement;
};

export type MilestoneI = {
    tid: number; // unique id of milestone
    n: string; // name
    x: number; // colX of milestone, -1 otherwhise
    b: number; // colX of baseline, -1 otherwhise
    c: number; // color
};

export type MilestonesChartCanvasProps = {
    data: {
        view: {
            col0: number;
            col1: number;
        };
        milestones: MilestoneI[];
    };
    scale: number;
    const: {
        rowPx: number;
        colPx: number;
        milestoneCol: number;
    };
    overlayState: any;
    canvasLeft: number;
    canvasTop: number;
    options: any;
    grid: {
        gridToDate: (column: number) => number;
    };
    start: number;
};

export enum MilestoneState {
    Early,
    OnTime,
    Delayed,
}

export type MilestonesChartCanvasPointerEvent = PointerEvent<HTMLCanvasElement> & { canvasX: number; canvasY: number };
