import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { HistoryHeaderButtons, onConflictButtonClick } from "../../Shared/Shared";
import {
    ActivityItem,
    Checkbox,
    Icon,
    ScrollablePane,
    Stack,
    StackItem,
    Sticky,
    StickyPositionType,
} from "@fluentui/react";
import { changesPanelClassList, changesPanelClassNames, changesPanelStyles } from "../../Shared/styles";
import { useLCMD } from "../../../../app/LCMContext";
import { useUser } from "../../../hooks/userMap.hook";
import { createUserName } from "../../../Utils";
import { intl } from "@/legacy/GlobalHelperReact";
import { assert, UNIT_TYPES } from "../../../../model/GlobalHelper";
import { Icons } from "../../../RegisteredIcons";
import { useVirtual } from "react-virtual";
import { ChangesHeader } from "../../Shared/ChangesHeader";
import { ChangesPanelActionTypes, CPContext } from "../../Shared/CPContext";
import {
    LCMDChangesPanelChangesTask,
    LCMDChangesPanelChangesTaskEvent,
    LCMDContextUnitType,
} from "../../../../app/LCMDContextTypes";
import { useCanvasNavigation } from "@/components/hooks/useCanvasNavigation.hook";
import { useSelectedProcessIds } from "@/app/store/canvasStore";
import { Button } from "@/components/ui/button";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export function ConflictList() {
    const LCMD = useLCMD();
    const selectedTasksFromCanvas = useSelectedProcessIds();
    const { onDismiss, changes, showConflicts } = useContext(CPContext).state;

    const parentRef = useRef();
    const [tasks, setTasks] = useState(changes.tasks || []);
    const [selectedTask, setSelectedTask] = useState<LCMDChangesPanelChangesTask>(null);

    const rowVirtualizer = useVirtual({
        size: tasks.length,
        parentRef,
        overscan: 15,
    });

    const isChecked = (task): boolean => {
        return selectedTask && task.tid == selectedTask.tid;
    };

    useEffect(() => {
        if (selectedTasksFromCanvas.length > 0) {
            const index = tasks.findIndex((task) => task.tid == selectedTasksFromCanvas.at(-1));
            rowVirtualizer.scrollToIndex(index);
            if (selectedTask != tasks[index]) {
                setSelectedTask(tasks[index]);
            }
        }
    }, [selectedTasksFromCanvas]);

    useEffect(() => {
        if (!changes.tasks) {
            setTasks([]);
            return;
        }

        const filteredChanges = changes.tasks.filter((task) => Array.isArray(task.conflict));
        setTasks(filteredChanges);
    }, [changes]);

    useEffect(() => {
        rowVirtualizer.scrollToIndex(0);
        rowVirtualizer.measure();
    }, [tasks]);

    const ctx = useMemo(() => {
        const ret = {
            LCMD,
            changes: changes,
            onConflictButtonClick: null,
        };
        ret.onConflictButtonClick = onConflictButtonClick.bind(ret);
        return ret;
    }, [LCMD, changes]);

    return (
        <>
            <ScrollablePane
                className={changesPanelClassNames.container}
                styles={{ contentContainer: { paddingBottom: 50 } }}
            >
                <Sticky stickyPosition={StickyPositionType.Header}>
                    <ChangesHeader
                        title={<>{intl.get("ChangesPanel.headers.conflicts")}</>}
                        onCloseButtonChange={onDismiss}
                    />
                    <HistoryHeaderButtons
                        showConflictsOnly={true}
                        conflictCount={tasks.length}
                        showConflictsButton={showConflicts}
                    />
                </Sticky>
                <div
                    ref={parentRef}
                    style={{
                        height: "calc(100% - 42px)",
                        overflow: "auto",
                    }}
                >
                    <div
                        className="ListInner"
                        style={{
                            height: `${rowVirtualizer.totalSize}px`,
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        {tasks.length > 0 &&
                            rowVirtualizer.virtualItems.map((virtualRow) => {
                                const task = tasks[virtualRow.index];
                                const distance = virtualRow.start + 20;
                                return (
                                    Boolean(task) && (
                                        <div
                                            key={virtualRow.index}
                                            ref={virtualRow.measureRef}
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                transform: `translateY(${distance}px)`,
                                            }}
                                        >
                                            <ConflictsItem
                                                key={task.tid}
                                                ctx={ctx}
                                                task={task}
                                                conflictMode={true}
                                                //onChange={setSelectedTask}
                                                onClick={setSelectedTask}
                                                checked={isChecked(task)}
                                            />
                                        </div>
                                    )
                                );
                            })}
                    </div>
                </div>
            </ScrollablePane>

            <Stack horizontalAlign={"end"} className={changesPanelClassNames.footer}>
                <ConflictButton
                    onClick={() => {
                        setSelectedTask(null);
                        WebAppTelemetryFactory.trackEvent("resolve-conflict-history-button");
                    }}
                    ctx={ctx}
                    task={selectedTask}
                />
            </Stack>
        </>
    );
}

function ConflictButton(props: { task: LCMDChangesPanelChangesTask; ctx; onClick }) {
    const { state, dispatch } = useContext(CPContext);

    if (props.task) {
        assert(props.task.conflict[0].tid === props.task.tid); // first item in conflict chain is --- by definition --- the task itself
    }

    return (
        <Button
            onClick={() => {
                props.ctx.onConflictButtonClick(props.task);
                props.onClick();
                dispatch({
                    type: ChangesPanelActionTypes.SET_HISTORY,
                    history: [],
                    currentPage: 0,
                    nextPage: 1,
                    _: state.changes._,
                });
            }}
            disabled={!props.task}
        >
            {intl.get("ChangesPanel.resolveConflictButton.content")}
        </Button>
    );
}

function ConflictEvent(props: { event: LCMDChangesPanelChangesTaskEvent; taskName?: string }) {
    const currentUser = useUser(props.event.u);
    const userName = createUserName(currentUser);
    const changeAction =
        props.event.changedProperties?.length > 0 && props.event.changedProperties[0]?.action
            ? props.event.changedProperties[0].action
            : "";
    function getValue(
        changedProps: Array<{
            action: "startDate" | "duration";
            value: string | { value: string; unit: LCMDContextUnitType };
        }>,
    ) {
        if (!(changedProps?.length > 0)) {
            return "";
        }
        if (typeof changedProps[0].value === "string") {
            return changedProps[0].value;
        } else {
            return `${changedProps[0].value.value} ${intl.get(
                `UnitTypes.${UNIT_TYPES[changedProps[0].value.unit || 3]}`,
                { value: Number.parseInt(changedProps[0].value.value) },
            )}`;
        }
    }

    return (
        <Stack tokens={{ childrenGap: "6px" }} styles={{ root: { marginBottom: "10px" } }}>
            <StackItem>
                {intl.getHTML("changesPanel.ChangeMessage", {
                    name: userName,
                    changedAction: intl.get(`changesPanel.ChangeActions.${changeAction}`),
                    task: props.taskName || "",
                })}
            </StackItem>
            <StackItem styles={{ root: { display: "flex" } }}>
                <Icon
                    iconName={Icons.Lcmd_Arrow_Right}
                    styles={{ root: { width: 16, height: 16, fontSize: 17, marginRight: "6px" } }}
                    style={{ color: "#565C60" }}
                />
                <span>{getValue(props.event.changedProperties)}</span>
            </StackItem>
        </Stack>
    );
}

function ConflictsItem(props: {
    task: LCMDChangesPanelChangesTask;
    ctx;
    conflictMode: boolean;
    onChange?: any;
    onClick?: any;
    checked?: boolean;
}) {
    const { goToAndScale } = useCanvasNavigation();
    // todo: history die keinen Event eintrag aber Konflikte haben abfangen
    const commentsContent = props.task.events.map((event) => {
        const actionName =
            event.changedProperties?.length > 0 && event.changedProperties[0]?.action
                ? event.changedProperties[0]?.action
                : "";
        if (actionName == "startDate" || actionName == "duration") {
            return <ConflictEvent taskName={props.task.name} key={event._} event={event} />;
        }
    });

    if (props.conflictMode && props.task.conflict) {
        commentsContent.push(
            <div className={changesPanelClassList.legend} key={"affected-" + props.task._}>
                <Icon
                    iconName={Icons.Lcmd_Add_Circle}
                    className={changesPanelClassList.legendIcon}
                    style={{ color: "#FFC000" }}
                />
                <div className={changesPanelClassList.legendText}>
                    {props.task.conflict.filter((conflict) => conflict.delta > 0).length} {intl.get("legend.affected")}
                    <br />
                    <span style={{ fontWeight: "bold" }}>{props.task.name}</span> {intl.get("legend.imported")}
                </div>
            </div>,
        );
    }

    return (
        <Stack
            onClick={(event) => {
                goToAndScale(props.task.tid, 2.2);
                props.onClick(!props.checked ? props.task : false);
            }}
            horizontal
            tokens={{
                childrenGap: 10,
                padding: "10px 45px 10px 15px",
            }}
            styles={{
                root: {
                    backgroundColor: props.checked ? "#E9ECED" : "transparent",
                },
            }}
        >
            <Stack styles={{ root: { marginTop: 10 } }}>
                <Checkbox
                    styles={{ checkbox: { borderRadius: "50px" }, root: { pointerEvents: "none" } }}
                    checked={props.checked}
                />
            </Stack>
            <Stack styles={{ root: { marginTop: 10 } }}>
                <Icon
                    iconName={"Asterisk"}
                    styles={{
                        root: {
                            backgroundColor: "rgb(216, 59, 1)",
                            borderRadius: "100%",
                            color: "#ffffff",
                            height: 20,
                            width: 20,
                            textAlign: "center",
                            fontSize: 13,
                            fontWeight: "bold",
                            lineHeight: "20px",
                        },
                    }}
                />
            </Stack>
            <Stack>
                <ActivityItem styles={changesPanelStyles.ActivityItem} comments={commentsContent} />
            </Stack>
        </Stack>
    );
}
