import * as React from "react";
import { ReactElement, ReactNode } from "react";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { DialogProps } from "@radix-ui/react-alert-dialog";
import { cn } from "@/lib/utils";

export const ModalSizeTypes = {
    s: "s",
    m: "m",
    l: "l",
    full: "full",
};

export const modalSizeClasses = {
    [ModalSizeTypes.s]: "max-w-[436px]",
    [ModalSizeTypes.m]: "max-w-[660px]",
    [ModalSizeTypes.l]: "max-w-[920px]",
    [ModalSizeTypes.full]: "max-w-[1200px] max-h-[800px] z-[1000001111]",
};

type LcmdModalHeader = {
    title: string;
    subtitle?: string;
    height?: number;
};

export interface LcmdModal2Props extends DialogProps {
    buttons?: ReactElement[];
    header?: LcmdModalHeader;
    size?: (typeof ModalSizeTypes)[keyof typeof ModalSizeTypes];
    open: boolean;
    onOpenChange: () => void;
    children?: ReactNode;
    titleTop?: boolean;
    className?: string;
    closable?: boolean;
    contentClassName?: string;
    headerStyles?: React.CSSProperties;
}

export function LcmdModal2({
    buttons,
    header,
    children,
    size = ModalSizeTypes.s,
    titleTop = false,
    className,
    closable = true,
    contentClassName,
    headerStyles,
    ...other
}: LcmdModal2Props) {
    return (
        <Dialog {...other}>
            <DialogContent closable={closable} className={cn(modalSizeClasses[size], "p-0", className)}>
                {!!header && (
                    <DialogHeader
                        className={cn(
                            "px-6 py-4 text-xl font-normal text-[#323130]",
                            children && "border-b border-solid border-gray-200",
                        )}
                        style={headerStyles}
                    >
                        {titleTop && (
                            <DialogTitle className="overflow-hidden overflow-ellipsis whitespace-nowrap pb-2">
                                {header.title || ""}
                            </DialogTitle>
                        )}
                        <DialogDescription className="text-left text-sm font-normal text-[#565C60]">
                            {header.subtitle || ""}
                        </DialogDescription>
                        {!titleTop && (
                            <DialogTitle className="overflow-hidden overflow-ellipsis whitespace-nowrap pb-2">
                                {header.title || ""}
                            </DialogTitle>
                        )}
                    </DialogHeader>
                )}
                {children && <div className={cn("grid gap-4 px-6 py-4", contentClassName)}>{children}</div>}
                {buttons && buttons.length > 0 && (
                    <DialogFooter className="border-t border-solid border-gray-200 px-6 py-4">
                        {buttons.map((btn, i) => (
                            <div key={i}>{btn}</div>
                        ))}
                    </DialogFooter>
                )}
            </DialogContent>
        </Dialog>
    );
}
