import * as React from "react";

import { format } from "date-fns";
import { de, enUS } from "date-fns/locale";
import { intl } from "@/legacy/GlobalHelperReact";
import { getTheme, mergeStyleSets, Text } from "@fluentui/react";
import { getCurrentLanguage } from "../../../../../utils/date/locale";

export type DateItemProps = {
    timestamp: number;
};

const theme = getTheme();

const DEFAULT_LABEL_FORMAT = "EEEE, dd/MM/yyyy 'CW('II')'";

const classNames = mergeStyleSets({
    text: {
        padding: 5,
        backgroundColor: theme.semanticColors.bodyStandoutBackground,
        borderRadius: 5,
    },
});

function formatDate(date: Date) {
    const dateLocales = { "de-DE": de, "en-US": enUS };
    const formatString = intl.get("filter.date.labelFormat").defaultMessage(DEFAULT_LABEL_FORMAT);

    return format(date, formatString, { locale: dateLocales[intl.getInitOptions().currentLocale] });
}
function replaceDateInString(input: string, date: Date): string {
    const locale = getCurrentLanguage();
    const formattedDate = date.toLocaleDateString(locale, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
    const inputArray = input.split(" ");
    inputArray.splice(1, 1, formattedDate);
    return inputArray.join(" ");
}

export function DateItem(props: DateItemProps) {
    const { timestamp } = props;

    const date = new Date(timestamp);
    return (
        <Text variant="medium" className={classNames.text}>
            {replaceDateInString(formatDate(date), date)}
        </Text>
    );
}
