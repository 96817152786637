import { create } from "zustand";

type changesPanel = {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const useChangesPanelOpenStore = create<changesPanel>((set) => ({
    open: false,
    setOpen: (open) => set({ open })
}));


