import * as React from "react";
import { useMemo } from "react";

import { composeTimeFrameType } from "../../../../../utils/timeframe";

import { Stack, StackItem, Icon } from "@fluentui/react";
import { FilterHeader, FilterItemsRow } from "../FilterComponent";
import { TimeframeType } from "./TimeframeType";
import { DateItem } from "./DateItem";
import { filterToStartEndDate } from "../../../../../../core/lcmd2core";

type DatesFilterComponent = {
    dates: [number, number] | null; // TODO: refactor parent component and remove null
    onChangeClick: () => void;
    onClearClick: () => void;
    userflowId?: string;
};

const PREFIX = "filter.main.date";

const tokensProps = {
    childrenGap: 5,
};

export function DatesFilterComponent({ dates, onChangeClick, onClearClick, userflowId }: DatesFilterComponent) {
    const timeFrameType = useMemo(() => {
        return composeTimeFrameType(dates);
    }, [dates]);

    const { startDate, endDate } = useMemo(() => {
        const startEnd = dates ? filterToStartEndDate(dates) : null;
        return {
            startDate: startEnd?.start ? startEnd.start.getTime() : null,
            endDate: startEnd?.end ? startEnd.end.getTime() : null,
        };
    }, [dates]);

    const isModify = Boolean(startDate && endDate);

    return (
        <StackItem>
            <FilterHeader prefix={PREFIX} onClick={onChangeClick} isModify={isModify} userflowId={userflowId} />
            <FilterItemsRow>
                {isModify && (
                    <Stack horizontal verticalAlign="center" tokens={tokensProps}>
                        <TimeframeType type={timeFrameType} dates={dates} />
                        <DateItem timestamp={startDate} />
                        <Icon iconName="ChevronRight" />
                        <DateItem timestamp={endDate} />
                    </Stack>
                )}
            </FilterItemsRow>
        </StackItem>
    );
}
