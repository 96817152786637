import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { intl } from "@/legacy/GlobalHelperReact";
import { PaintBucket, X } from "lucide-react";
import { DigitalPlanningBoardTradeDetails, needsWhiteColor } from "@/legacy/api/lcmd2framework";
import { TradeImageType, tradeImages, tradeImagesMap } from "../../TradeImages";
import { ColorPickerModal } from "../ColorPickerModal";
import { decimalToHex } from "@/components/utils/color/decimal-to-hex";
import { cn } from "@/lib/utils";
import { SidebarContext } from "@/app/components/SidebarHost";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

type TradePanelsContent = {
    trade: Partial<DigitalPlanningBoardTradeDetails>;
    setTrade: Dispatch<SetStateAction<Partial<DigitalPlanningBoardTradeDetails>>>;
    selectedTrade: Partial<DigitalPlanningBoardTradeDetails>;
    colorPickerPosition: "left" | "right";
};

export const TradePanelsContent = ({ trade, setTrade, selectedTrade, colorPickerPosition }: TradePanelsContent) => {
    const propsContext = useContext(SidebarContext);
    const tradeIcon = tradeImagesMap.get(trade?.icon);
    const [isColorPickerOpened, setIsColorPickerOpened] = useState<boolean>(false);
    const [selectedIcon, setSelectedIcon] = useState<TradeImageType>(tradeIcon);

    useEffect(() => {
        if (!trade && !selectedTrade) {
            setIsColorPickerOpened(false);
            setTrade({ ...trade, color: null });
            setTrade({ ...trade, icon: null });
            setSelectedIcon(null);
        }
        if (tradeIcon) {
            setSelectedIcon(tradeIcon);
        } else {
            setSelectedIcon(null);
        }
    }, [trade, selectedTrade]);

    return (
        <>
            <div className="mx-4 my-5">
                <div className="flex items-center">
                    <p className="text-left text-lg font-semibold leading-7 tracking-normal text-[#1B2126]">
                        {selectedTrade?.name
                            ? intl.get("EditTradeModal.title")
                            : intl.get("TradesView.CreateNewTradeTitle")}
                    </p>
                    {selectedTrade?.name ? (
                        <Button
                            size={"icon"}
                            variant={"ghost"}
                            className={"ml-auto h-4 w-4"}
                            onClick={() => {
                                propsContext.sidebarCtx.setTradeData(null);
                            }}
                        >
                            <X color="#A1A1AA" className="h-4 w-4" />
                        </Button>
                    ) : (
                        <></>
                    )}
                </div>
                <p className="text-left text-sm font-normal leading-5 tracking-normal text-[#82878A]">
                    {intl.get("TradesView.EditTradeDescription")}
                </p>
            </div>
            <div className="mx-4 my-5 flex">
                <Input
                    placeholder={intl.get("AreaLibrary.newAreaDialog.textFieldName.placeholder")}
                    value={trade?.name}
                    onChange={(ev) => {
                        const name = (ev.target as HTMLInputElement).value;
                        setTrade((trade) => ({ ...trade, name: name }));
                    }}
                />
                <Button
                    variant="secondary"
                    size="icon"
                    className="ml-2 h-10 w-12"
                    style={{
                        backgroundColor: trade?.color && decimalToHex(trade?.color),
                        color: trade?.color && "#FFFFFF",
                    }}
                    onClick={() => setIsColorPickerOpened(!isColorPickerOpened)}
                >
                    <PaintBucket size={16} color={trade?.color && needsWhiteColor(trade?.color) ? "#fff" : "#000"} />
                </Button>
            </div>
            <div className="mx-4 my-5 flex h-[calc(100%-190px)] flex-col">
                <div className="mb-5">
                    <p className="text-left text-sm font-semibold leading-5 tracking-normal text-[#1B2126]">
                        {intl.get("TradesView.SelectTradeIcon")}
                    </p>
                    <p className="text-left text-xs font-medium leading-5 tracking-normal text-[#565C60]">
                        {intl.get("TradesView.SelectTradeIconDescription")}
                    </p>
                </div>
                <div className="grid h-[300px] grid-cols-7 gap-3 overflow-y-auto">
                    {tradeImages.map((el) => (
                        <TooltipProvider key={el.id}>
                            <Tooltip>
                                <TooltipTrigger>
                                    <Button
                                        variant="outline"
                                        size="icon"
                                        onClick={() => {
                                            setSelectedIcon(el);
                                            setTrade((trade) => ({ ...trade, icon: el.title }));
                                        }}
                                        className={cn(
                                            el.id === selectedIcon?.id && "border-2 border-[#1B2126] bg-accent",
                                            "h-[38px] w-[38px] rounded-full",
                                        )}
                                    >
                                        <img src={el.img} className="h-[22px] w-[22px] brightness-0" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>{intl.get(`TradesView.TradeIcons.${el.title}`)}</TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    ))}
                </div>
            </div>
            <ColorPickerModal
                isColorPickerOpened={isColorPickerOpened}
                setIsColorPickerOpened={setIsColorPickerOpened}
                position={colorPickerPosition}
                trade={trade}
                setTrade={setTrade}
            />
        </>
    );
};
