import { Stack, StackItem, TooltipHost } from "@fluentui/react";
import { useLCMD } from "../../../app/LCMContext";
import { useState, useEffect } from "react";

/*
TODO: Tooltip! Styles
 */
export enum ShowArea {
    FIRST,
    LAST,
    FIRST_AND_LAST,
}

export function AreaList({
    processId,
    showArea = ShowArea.FIRST,
    separator = " > ",
    refTimestamp,
}: {
    processId: number;
    showArea?: ShowArea;
    separator?: string;
    refTimestamp?: number;
}) {
    const [areaPath, setAreaPath] = useState([]); // [{id: 1, name: "test"}, {id: 2, name: "test2"}
    const LCMD = useLCMD();
    const hookResult = LCMD.useAreaPath(processId, refTimestamp);

    useEffect(() => {
        console.log(hookResult);
        setAreaPath(hookResult.data.areaPath);
    }, [hookResult]);

    if (areaPath.length < 1) {
        return <Stack></Stack>;
    }
    return (
        <TooltipHost
            styles={{ root: { display: "inline-block" } }}
            content={areaPath.map((area) => area.name).join(separator)}
        >
            {areaPath.length == 1 ? (
                <AreaListShowOne areaPath={areaPath} />
            ) : showArea === ShowArea.LAST ? (
                <AreaListShowLast areaPath={areaPath} separator={separator} />
            ) : (
                <AreaListFirstAndLast areaPath={areaPath} separator={separator} showArea={showArea} />
            )}
        </TooltipHost>
    );
}

function AreaListShowLast({ areaPath, separator }) {
    const lastArea = areaPath.pop();
    const firstAreas = areaPath;

    return (
        <Stack horizontal>
            <StackItem styles={{ root: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } }}>
                {firstAreas.map((area) => area.name).join(separator)}
            </StackItem>
            <StackItem styles={{ root: { whiteSpace: "nowrap" } }}>
                {separator}
                {lastArea.name}
            </StackItem>
        </Stack>
    );
}

function AreaListShowOne({ areaPath }) {
    return (
        <Stack>
            <StackItem styles={{ root: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } }}>
                {areaPath[0].name}
            </StackItem>
        </Stack>
    );
}

function AreaListFirstAndLast({
    areaPath,
    showArea = ShowArea.FIRST,
    separator = " > ",
}: {
    areaPath: Array<{ id: number; name: string }>;
    showArea?: ShowArea;
    separator?: string;
}) {
    /** Also works for case 'showFirst'
     *
     */

    const tmpAreaPath = [...areaPath];
    const firstArea = tmpAreaPath.shift();

    const lastArea = tmpAreaPath.pop();

    const middleAreas = tmpAreaPath;

    let fullTextArea, shortTextAreas;
    fullTextArea = firstArea;
    shortTextAreas = middleAreas;
    if (showArea == ShowArea.FIRST) {
        shortTextAreas = [...middleAreas, lastArea];
    }

    return (
        <Stack styles={{ root: { overflow: "hidden", columnGap: "10px" } }} horizontal>
            <StackItem grow={0}>{fullTextArea.name}</StackItem>
            <StackItem grow={0}>{separator}</StackItem>
            <StackItem
                grow={0}
                styles={{ root: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } }}
            >
                {shortTextAreas.map((area) => area.name).join(separator)}
            </StackItem>
            {showArea == ShowArea.FIRST_AND_LAST && (
                <>
                    <StackItem grow={0} styles={{ root: { whiteSpace: "nowrap" } }}>
                        {separator}
                        {lastArea.name}
                    </StackItem>
                </>
            )}
        </Stack>
    );
}
