// file for initialing global environment
// do not import document based javascript here as this module is imported in the worker thread

declare global {
    interface Date {
        toUTCFormatString: (locales?: Intl.LocalesArgument, options?: Intl.DateTimeFormatOptions) => string;
    }
}

Date.prototype.toUTCFormatString = function (locales?: Intl.LocalesArgument, options?: Intl.DateTimeFormatOptions) {
    // copy date obj and set "wrong" date for displaying time
    const d = new Date(this.getTime() + this.getTimezoneOffset() * 60 * 1000);
    return d.toLocaleString(locales, options);
};

export {};
