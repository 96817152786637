import * as React from "react";
import { mergeStyleSets, DelayedRender, Callout, DirectionalHint } from "@fluentui/react";
import { useEffect } from "react";
import { useTooltipStore } from "../../../../app/store/tooltipStore";
import { intl } from "@/legacy/GlobalHelperReact";

const classNames = mergeStyleSets({
    callout: {
        width: "189px",
    },
    content: {
        color: "white",
        padding: "8px 12px",
        fontSize: "12px",
        fontWeight: 400,
        textAlign: "center",
    },
});

export function CalloutMessage() {
    const showTooltip = useTooltipStore((state) => state.showTooltip);
    const setShowTooltip = useTooltipStore((state) => state.setShowTooltip);

    useEffect(() => {
        if (showTooltip) {
            setTimeout(() => {
                setShowTooltip(false);
            }, 5000);
        }
    }, [showTooltip]);

    if (showTooltip) {
        return (
            <Callout
                target={`#titleHeaderSettings`}
                role="alert"
                className={classNames.callout}
                backgroundColor="#1B2126"
                directionalHint={DirectionalHint.bottomCenter}
            >
                <DelayedRender>
                    <div className={classNames.content}>{intl.get("ReasonCodes.CalloutMessage")}</div>
                </DelayedRender>
            </Callout>
        );
    } else {
        return null;
    }
}
