import * as React from "react";
import { Icon } from "@fluentui/react";
import { FilterRowTop } from "./FilterRowTop";
import { Icons } from "../../../../RegisteredIcons";
import { ItemType } from "../../../../ToDo/interface";
import { filterRowStyles } from "./filterRowStyles";

type ToDoTypeFilterRow = {
    label: string;
    badgeCount: number;
    data: any[];
    onModifyButtonClick: () => void;
};

export function ToDoTypeFilterRow({ label, badgeCount, onModifyButtonClick, data }: ToDoTypeFilterRow) {
    const options = {
        [ItemType.ACTION_ITEM]: {
            key: ItemType.ACTION_ITEM,
            text: "Action items",
            iconName: Icons.Lcmd_Warning,
        },
        [ItemType.STABILITY_CRITERIA]: {
            key: ItemType.STABILITY_CRITERIA,
            text: "Stability criteria",
            iconName: Icons.Lcmd_Scales,
        },
    };

    return (
        <div className={filterRowStyles.body}>
            <FilterRowTop onModifyButtonClick={onModifyButtonClick} label={label} badgeCount={badgeCount} />
            <div>
                {data.map((t) => {
                    return (
                        <div className={filterRowStyles.button} key={t.key}>
                            <Icon iconName={options[t.key].iconName} styles={{ root: { marginRight: 3 } }} />
                            <span>{t.name}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
