import React from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { useNavigate } from "react-router-dom";
import { CHANGES_PANEL_ROUTES } from "./routers.enum";
import { LCMDContextType } from "../../../app/LCMContext";
import { assert } from "../../../model/GlobalHelper";
import { LCMDChangesPanelChanges } from "../../../app/LCMDContextTypes";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Asterisk, Clock3 } from "lucide-react";

export function onConflictButtonClick(this: { LCMD: LCMDContextType; changes: LCMDChangesPanelChanges }, process) {
    const conflict = process.conflict;
    assert(Array.isArray(conflict) && process.tid === conflict[0].tid);
    this.LCMD.resolveConflict(conflict);
}

export function HistoryHeaderButtons(props: { showConflictsOnly; conflictCount; showConflictsButton }) {
    const navigate = useNavigate();

    return (
        <div className="flex justify-start gap-4 border-0 border-b border-[#E1E4E5] bg-white px-4 pb-2 pt-1">
            <Button
                className={cn(
                    "h-8 bg-[#00698f] text-white",
                    "flex items-center gap-2 rounded border-0 font-semibold",
                    !props.showConflictsOnly
                        ? "bg-[#009BD4] text-white hover:bg-[#00698F]"
                        : "bg-[#DEF0F9] text-black hover:bg-[#00698F] hover:text-white",
                )}
                onClick={() => {
                    navigate(CHANGES_PANEL_ROUTES.HISTORY_VIEW);
                    //setShowConflictsOnly(false);
                }}
            >
                <Clock3 size={16} />
                {intl.get("ChangesPanel.historyButton.content")}
            </Button>
            {!!props.showConflictsButton && (
                <Button
                    className={cn(
                        "group",
                        "h-8 bg-destructive-dark text-white duration-100",
                        "flex items-center gap-1 rounded border-0 font-semibold",
                        props.showConflictsOnly
                            ? "bg-destructive text-white hover:bg-destructive-dark"
                            : "bg-[#FCE7E9] text-black hover:bg-destructive-dark hover:text-white",
                    )}
                    onClick={() => {
                        navigate(CHANGES_PANEL_ROUTES.CONFLICT_LIST);
                    }}
                >
                    <Asterisk
                        size={20}
                        strokeWidth={1.5}
                        className={cn(
                            "rounded-full",
                            props.showConflictsOnly
                                ? "bg-white text-destructive group-hover:text-destructive-dark"
                                : "bg-destructive text-white duration-100 group-hover:bg-white group-hover:text-destructive-dark",
                        )}
                    />
                    <div>{props.conflictCount}</div>
                    {intl.get("ChangesPanel.conflictsButton.content", { value: props.conflictCount })}
                </Button>
            )}
        </div>
    );
}
