import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Input } from "@/components/ui/input";
import microsoftLogo from "../../assets/MicrosoftLogo.svg";
import { useMicrosoftSSO } from "../../hooks/useMicrosoftSSO";
import { BrowserAuthError } from "@azure/msal-browser";
import { intl } from "lcmd2framework";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Paths } from "../../types/routes";
import { useEmailStore } from "../../store/emailStore";
import { AuthClient } from "@/app/services/AuthClient.service";
import { showScreenOfDeath } from "@/legacy/ScreenOfDeath";
import { InteractionStatus } from "@azure/msal-browser/dist/utils/BrowserConstants";

type HomeViewProps = {
    onLogin: (sub: string, authToken: string) => void;
};

export function HomeView({ onLogin }: HomeViewProps) {
    const { setEmail } = useEmailStore();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { isAuth, result, inProgress, instance } = useMicrosoftSSO();

    useEffect(() => {
        setIsLoading(inProgress !== InteractionStatus.None);
    }, [inProgress]);

    useEffect(() => {
        async function handleSsoLogin() {
            try {
                setIsLoading(true);
                const loginResult = await AuthClient.loginWithSSO(result.accessToken);
                onLogin(loginResult.sub, loginResult.authToken);
            } catch (error) {
                showScreenOfDeath(error);
                setIsLoading(false);
            }
        }
        if (result) {
            handleSsoLogin();
        }
    }, [result]);

    //TODO: make hook
    async function handleLogin(email: string) {
        setEmail(email);
        // check always with trimmed and lowercase emails. In backend this should also be handled!!!
        const isRegistered = await AuthClient.isUserRegistered(email);

        if (isRegistered) {
            navigate(Paths.LOGIN);
        } else {
            navigate(Paths.REGISTER);
        }
    }

    const handleSubmit = async (values: z.infer<typeof formSchema>) => {
        setIsLoading(true);
        try {
            await handleLogin(values.email);
        } catch (error) {
            showScreenOfDeath(error);
        }
        setIsLoading(false);
    };

    const formSchema = z.object({
        email: z.string().email(intl.get("LcmdLogin.ForgotPassword.EmailInputErrorMessage")).trim().toLowerCase(),
    });
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: "",
        },
    });

    function handleLoginWithSSO() {
        instance.loginPopup().catch((err) => {
            if (!(err instanceof BrowserAuthError)) {
                showScreenOfDeath(err);
            }
        });
    }

    return (
        <>
            <div className={"text-zinc-900 text-xl font-semibold leading-7"}>{intl.get("LcmdLogin.Home.Headline")}</div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <div className={"mb-8 mt-4 space-y-1"}>
                        <FormField
                            control={form.control}
                            name="email"
                            disabled={isLoading}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>{intl.get("LcmdLogin.Home.EmailLabel")}</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder={intl.get("LcmdLogin.Home.EmailPlaceholder")} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <Button type="submit" disabled={isLoading} className={"h-12 w-full mb-4"}>
                        {intl.get("LcmdLogin.Home.ContinueButton")}
                    </Button>

                    <div className={"mb-4 flex justify-between align-middle items-center"}>
                        <div className={"w-[132px] h-px bg-gray-300"}></div> {intl.get("LcmdLogin.Home.Or")}
                        <div className={"w-[132px] h-px bg-gray-300"}></div>
                    </div>
                    <Button variant={"outline"} type={"button"} className={"h-12 w-full"} onClick={handleLoginWithSSO}>
                        <img src={microsoftLogo} className={"mr-4"} />
                        {intl.get("LcmdLogin.Home.SSOButton")}
                    </Button>
                </form>
            </Form>
        </>
    );
}
