import * as React from "react";
import { useMemo, useRef, useState } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    Modal,
    Stack,
    IconButton,
    IIconProps,
    PrimaryButton,
} from "@fluentui/react";
import { useLCMD } from "../../app/LCMContext";
import { LCMDContextTaskDetailsResultSingleItemWithDetails } from "../../app/LCMDContextTypes";
import { LcmdModal2 } from "../common/LcmModal2";

export type GroundLayoutModalProps = {
    projectId: string;
    dialogId?: string;
    tid?: number;
};

const cancelIcon: IIconProps = { iconName: "Cancel" };
const editIcon: IIconProps = { iconName: "Edit" };
const deleteIcon: IIconProps = { iconName: "Delete" };
const downloadIcon: IIconProps = { iconName: "Download" };
const viewIcon: IIconProps = { iconName: "Zoom" };
const closeIcon: IIconProps = { iconName: "Cancel" };

export const GroundLayoutModal = ({ projectId, tid, dialogId }: GroundLayoutModalProps) => {
    const LCMD = useLCMD();
    const defaultModalState: {
        image: LCMDContextTaskDetailsResultSingleItemWithDetails<
            {
                blobId?: string;
                contentType?: string;
                fileName?: string;
            },
            {
                url?: string;
            }
        >;
        fileSrc: string;
    } = {
        image: null,
        fileSrc: "",
    };

    const fileInput = useRef<HTMLInputElement>();
    const [modalState, setModalState] = useState(defaultModalState);
    const [fullscreenOpen, setFullscreenOpen] = useState(false);

    React.useEffect(() => {
        LCMD.getProcessDetails(tid, (error, data) => {
            if (error) {
                console.warn(error);
                setModalState(defaultModalState);
            } else {
                setModalState({
                    image: data?.image,
                    fileSrc: data?.image?.url ? data.image.url : "",
                });
            }
        });
    }, []);

    const uploadHandler = useMemo(
        () => (ev) => {
            const fileInput: HTMLInputElement = ev.target;
            const cb = LCMD.worker.registerCallback((data) => {
                if (data?.blobId) {
                    LCMD.setProcess(tid, [
                        {
                            name: "image",
                            value: {
                                blobId: data.blobId,
                                fileName: fileInput.files[0].name,
                                contentType: fileInput.files[0].type,
                            },
                        },
                    ]);
                    handleCloseModal();
                }
            });
            LCMD.uploadAttachments({}, fileInput.files, undefined, cb);
        },
        [LCMD],
    );

    function handleDeleteAttachment() {
        if (!modalState.image?.value?.blobId) {
            return;
        }
        LCMD.setProcess(tid, [
            {
                name: "image",
                value: null,
            },
        ]);
        LCMD.deleteAttachments({}, [modalState.image.value.blobId]);
        handleCloseModal();
    }

    function handleCloseModal() {
        LCMD.showDialog(dialogId, false);
    }

    return (
        <div>
            <Modal isOpen={true} isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span>{intl.get("GroundLayoutModal.headline") + " (#" + tid + ")"}</span>
                    <div>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={editIcon}
                            ariaLabel={intl.get("GroundLayoutModal.editAreaLabel")}
                            onClick={() => {
                                fileInput?.current.click();
                            }}
                        />
                        <IconButton
                            disabled={!modalState.fileSrc}
                            styles={iconButtonStyles}
                            iconProps={deleteIcon}
                            ariaLabel={intl.get("GroundLayoutModal.deleteAreaLabel")}
                            onClick={handleDeleteAttachment}
                        />
                        <IconButton
                            disabled={!modalState.fileSrc}
                            styles={iconButtonStyles}
                            iconProps={downloadIcon}
                            ariaLabel={"Download"}
                            onClick={() => {
                                window.open(modalState.fileSrc);
                            }}
                        />
                        <IconButton
                            styles={iconButtonStyles}
                            className={contentStyles.closeButton}
                            iconProps={cancelIcon}
                            ariaLabel={intl.get("GroundLayoutModal.closeAreaLabel")}
                            onClick={handleCloseModal}
                        />
                    </div>
                </div>
                <Stack className={contentStyles.body} horizontalAlign={"center"} verticalAlign={"center"}>
                    <input type={"file"} ref={fileInput} hidden onChange={uploadHandler} accept={"image/*"} />
                    {modalState.fileSrc ? (
                        <>
                            <div className="relative">
                                <img className={contentStyles.image} src={modalState.fileSrc} />
                                <div className="absolute right-1 top-0 ">
                                    <IconButton
                                        disabled={!modalState.fileSrc}
                                        styles={iconButtonStyles}
                                        className={contentStyles.viewButton}
                                        iconProps={viewIcon}
                                        ariaLabel={"View"}
                                        onClick={() => {
                                            setFullscreenOpen(true);
                                        }}
                                    />
                                </div>
                            </div>
                            <LcmdModal2 open={fullscreenOpen} size="full" onOpenChange={() => {}} closable={false}>
                                <div className={contentStyles.bigImageCanvas}>
                                    <img className={contentStyles.bigImage} src={modalState.fileSrc} />

                                    <div className="absolute right-1 top-0 ">
                                        <IconButton
                                            disabled={!modalState.fileSrc}
                                            styles={iconButtonStyles}
                                            iconProps={downloadIcon}
                                            ariaLabel={"Download"}
                                            onClick={() => {
                                                window.open(modalState.fileSrc);
                                            }}
                                        />
                                        <IconButton
                                            disabled={!modalState.fileSrc}
                                            styles={iconButtonStyles}
                                            iconProps={closeIcon}
                                            ariaLabel={"Close"}
                                            onClick={() => {
                                                setFullscreenOpen(false);
                                            }}
                                        />
                                    </div>
                                </div>
                            </LcmdModal2>
                        </>
                    ) : (
                        <div>
                            <PrimaryButton
                                onClick={() => {
                                    fileInput?.current.click();
                                }}
                            >
                                {intl.get("GroundLayoutModal.addButton")}
                            </PrimaryButton>
                        </div>
                    )}
                </Stack>
            </Modal>
        </div>
    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    bigImageCanvas: {
        width: "calc(100swh - 200px)",
        maxWidth: "1200px",
        height: "calc(100svh - 200px)",
        maxHeight: "800px",
        position: "relative",
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
    },
    closeButton: {
        borderLeft: "1px solid #E1E4E5",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    iconsLastItem: {
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    viewButton: {
        backgroundColor: "white !important",
        border: "1px solid gray",
        outline: "white",
    },
    image: {
        maxWidth: "700px",
        maxHeight: "450px",
        border: "1px solid",
    },
    bigImage: {
        width: "calc(100swh - 250px)",
        height: "calc(100svh - 250px)",
        maxWidth: "90%",
        maxHeight: "90%",
        display: "block",
        margin: "auto",
    },
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        width: "800px",
        height: "600px",
    },
    headerIcons: {
        display: "flex",
    },
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
            flex: "1 1 auto",
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px",
            justifyContent: "space-between",
        },
    ],
    body: {
        flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        overflowY: "hidden",
        height: "500px",
        width: "800px",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
