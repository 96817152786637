import * as React from "react";
import { intl, Dialog, DialogScreen } from "lcmd2framework";
import { mergeStyleSets, TextField } from "@fluentui/react";
import { StartEndDateControl } from "../utils/start-end-date-control";
import { useLCMD } from "../../app/LCMContext";
import { LCMDContextProjectDetails } from "../../app/LCMDContextTypes";
import { DataSecurityLink } from "./DataSecurityLink";
import { isValidHttpUrl } from "../Utils";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

const classNames = mergeStyleSets({
    main: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flexShrink: 1,
        paddingLeft: 20,
        paddingRight: 20,
    },
});

function extractValues(data) {
    const startDate = data?.startDate ? new Date(data.startDate) : undefined;
    const endDate = data?.endDate ? new Date(data.endDate) : undefined;
    return {
        name: data?.meta?.name || "",
        startDate,
        endDate,
    };
}

function MainScreen({
    dialog,
    route: {
        params: {},
    },
}) {
    const LCMD = useLCMD();
    const { data: dataSecurityLink, setDataSecurityLink } = LCMD.useDataSecurityLink();
    const [data, setData] = React.useState(null as LCMDContextProjectDetails);
    const [name, setName] = React.useState(null as string);
    const [inputState, setInputState] = React.useState<string>(dataSecurityLink.link);
    const [startDate, setStartDate] = React.useState<Date>(undefined);
    const [endDate, setEndDate] = React.useState<Date>(undefined);

    React.useEffect(() => {
        LCMD.getProjectDetails((error, data) => {
            const onCancel = () => {
                LCMD.showDialog("dialog.project.properties", false);
            };
            const onOK = undefined;
            if (error) {
                console.warn(error);
                setData(null);
                setName(null);
                setStartDate(undefined);
                setEndDate(undefined);
                setInputState(null);
            } else {
                const initialValues = extractValues(data);
                setName(initialValues.name);
                setStartDate(initialValues.startDate);
                setEndDate(initialValues.endDate);
                setInputState(dataSecurityLink.link);
                setData(data);
            }
            dialog.setOptions({
                title: intl.get("overview.cmd.props.text"),
                onOK: undefined,
                onCancel,
                onClose: onCancel,
            });
        });
    }, [setData, setName, setStartDate, setEndDate, setInputState, dataSecurityLink]);

    React.useLayoutEffect(() => {
        const initialValues = extractValues(data);
        const values = {
            name: name || "",
            link: inputState || "",
            startDate: startDate ? startDate : undefined,
            endDate: endDate ? endDate : undefined,
        };
        const nameValidAndChanged =
            "string" === typeof values.name && values.name.length > 0 && initialValues.name !== (name || "");
        const dateValidAndChanged =
            values.startDate &&
            values.endDate &&
            values.startDate.getTime() < values.endDate.getTime() &&
            (initialValues.startDate !== values.startDate || initialValues.endDate !== values.endDate);

        const linkValidAndChanged = !values.link
            ? true
            : isValidHttpUrl(values.link) && dataSecurityLink.link !== values.link;

        const onOK =
            nameValidAndChanged || dateValidAndChanged || linkValidAndChanged
                ? () => {
                    if (nameValidAndChanged) {
                        WebAppTelemetryFactory.trackEvent("processplan-renamed", {
                            place: 'dropdown'
                        });
                    }
                      //name && startDate && endDate && startDate.getTime()<endDate.getTime()
                      if (linkValidAndChanged) setDataSecurityLink(values.link);
                      LCMD.setProjectDetails(
                          {
                              name: nameValidAndChanged ? values.name : undefined,
                              //   link: values.link,
                              startDate: dateValidAndChanged ? values.startDate : undefined,
                              endDate: dateValidAndChanged ? values.endDate : undefined,
                          },
                          (error, data) => {
                              if (error) {
                                  console.warn(error);
                                  setData(null);
                                  setName(null);
                                  setInputState(null);
                                  dialog.setOptions({
                                      onOK: undefined,
                                  });
                              } else {
                                  LCMD.showDialog("dialog.project.properties", false);
                              }
                          },
                      );
                  }
                : undefined;
        dialog.setOptions({
            onOK: onOK,
        });
    }, [inputState, name, startDate, endDate]);
    const onDateChange = React.useCallback(
        (start, end) => {
            setStartDate(start || undefined);
            setEndDate(end || undefined);
            if (start && end && start.getTime() < end.getTime()) {
                console.log("TODO");
            }
        },
        [setStartDate, setEndDate],
    );
    return data ? (
        <div className={classNames.main}>
            <TextField
                disabled={null === name}
                label={intl.get("fw.projectName")}
                value={name || ""}
                onChange={(ev, value) => {
                    setName(value);
                }}
            />
            <StartEndDateControl defaultStart={startDate} defaultEnd={endDate} onChange={onDateChange} utc={false} />
            {/* new shadcn input for a project link */}
            {<DataSecurityLink onChange={(value) => setInputState(value)} value={inputState} />}
        </div>
    ) : null;
}

export function ProjectPropertiesDialog(props: {}) {
    return (
        <Dialog
            initialScreen={(dialog) => {
                dialog.pushScreen("main", {});
            }}
            isOpen={true}
        >
            <DialogScreen name="main" component={MainScreen} />
        </Dialog>
    );
}
