import {
    FILTER_SPECIAL_UNIT_MASK,
    FILTER_SPECIAL_UNIT_MONTH,
    FILTER_SPECIAL_UNIT_WEEKS,
    FILTER_SPECIAL_VALUE,
} from "lcmd2framework";

import { TimeframePeriodTypes } from "./timeframe-period-types";

export function composeTimeFrameType(dates) {
    const [, endDate] = dates || [];

    if (endDate === 0 || endDate === undefined || endDate === null) {
        return TimeframePeriodTypes.EVERY;
    }

    if (endDate < FILTER_SPECIAL_VALUE) {
        const unit = endDate & FILTER_SPECIAL_UNIT_MASK;

        if (FILTER_SPECIAL_UNIT_MONTH === unit) {
            return TimeframePeriodTypes.MONTHS;
        }

        if (FILTER_SPECIAL_UNIT_WEEKS === unit) {
            return TimeframePeriodTypes.WEEKS;
        }
    }

    return TimeframePeriodTypes.CUSTOM;
}
