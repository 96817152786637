import { useCallback } from "react";

export function useOnInputChange<V, E>(onChange: (value: V, event?: E) => void) {
    return useCallback(
        (event: E, value: V) => {
            return onChange(value, event);
        },
        [onChange],
    );
}
