export enum NotificationTarget {
    InApp = 'InApp',
    Email = 'Email',
    SMS = 'SMS',
    Chat = 'Chat',
    Push = 'Push'
}

export type Notification = {
    id?: string;
    title?: string;
    message?: string;
    icon?: string;
    type?: 'info' | 'success' | 'warning' | 'error';
    target?: NotificationTarget[];
    timestamp?: Date;
    read?: string[];
    actions?: NotificationAction[];
    meta?: {
        [key: string]: string | undefined;
    }
}

type NotificationAction = {
    label: string;
    callback: () => void;
}

export interface NotificationService {
    addNotification(fromUserId: string, users: string[], notification: Notification, auth_token: string): void;
    sendNotification(fromUserId: string, users: string[], notification: Notification, auth_token: string): void;
    markNotificationAsRead(id: string): void;
    removeNotification(id: string): void;
}
