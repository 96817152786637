import { create } from "zustand";
import { SettingsData } from "../../utils/SettingsUtils";

interface calendarStore {
    calendarSettings: SettingsData;
    setCalendarSettings: (calendarSettings: SettingsData) => void;
    changed: boolean;
    setChanged: (changed: boolean) => void;
}

export const useCalendarStore = create<calendarStore>((set) => ({
    calendarSettings: {
        firstDayInWeek: 0,
        activeWeekDays: [true, true, true, true, true, true, true],
        calendarExceptions: [],
    },
    setCalendarSettings: (calendarSettings) => set({ calendarSettings, changed: true }),
    changed: false,
    setChanged: (changed) => set({ changed }),
}));
