const API_VERSION = 15;
export const BUNDLE_HASH = "###BUNDLE_HASH###";
export const WORKER_HASH = "###WORKER_HASH###";
const SDK_HASH = "000000";
const BRANCH = undefined; // "7_day_hack";
const MAJOR = 3;

export let SERVICES = null;

export function initServices(props, servicesOverride?) {
    if (servicesOverride) {
        SERVICES = servicesOverride;
    } else {
        let FORCE_MAJOR = MAJOR;
        if (props?.FORCE_MAJOR) {
            try {
                switch (typeof props.FORCE_MAJOR) {
                    case "string":
                        FORCE_MAJOR = Number.parseInt(props.FORCE_MAJOR);
                        break;
                    case "number":
                        FORCE_MAJOR = props.FORCE_MAJOR;
                        break;
                }
            } catch (e) {
                FORCE_MAJOR = MAJOR;
            }
        }
        const location = typeof window !== "undefined" ? window.location : null;

        const isRC = "/releasecandidate" === location?.pathname || location?.pathname.startsWith("/index_rc_");
        console.log({ pathname: location?.pathname, isRC });

        SERVICES = {
            API_VERSION: API_VERSION,
            MAJOR: FORCE_MAJOR,
            BUILD: {
                BUNDLE_HASH: BUNDLE_HASH,
                WORKER_HASH: WORKER_HASH,
                BRANCH: BRANCH,
            },
            REST_API: "https://lcmd-rest-api.azurewebsites.net/api",
            AUTH_SERVICE: "https://live.lcmdigital.com/api/live",
            LIVEBLOCKS_AUTH_SERVICE: "https://live.lcmdigital.com/api/liveblocks-auth",
            NPS_SERVICE: "https://lcmd-nps.azurewebsites.net/api/nps",
            PARSE_SERVICE: "https://parse.lcmdigital.com/api/parse",
            UPLOAD_SERVICE: "https://live.lcmdigital.com/api/upload",
            UPLOAD_SERVICE_MAX: 1 * 4 * 1024 * 1024, // 4KB
            ATTACHMENT_SERVICE: "https://live.lcmdigital.com/api/attachment",
            STORAGE_SERVICE: "https://live.lcmdigital.com/api/live",
            PDF_SERVICE: "https://pdf.lcmdigital.com/api/pdf",
            LIVEBLOCKS_KEY: "pk_prod_79H_cfoOGY1UG44rdXXST8d4AJw9_mq-FjMbFIzaSAl6Q0IhMBi_DGI9HD04CF5d",

            PROCORE_SERVICE: null, // disabled

            PROCORE_LOGIN:
                "https://login.procore.com/oauth/authorize?client_id=f4939ad9330f716e02a23b59945c239a18362dc0476b26722cca5d42962af7a8&response_type=token",
            OAUTH_REDIRECT_URL: "https://share.lcmdigital.com/oauth.html",
            //OAUTH_REDIRECT_URL: "http://127.0.0.1:3000/oauth.html",
            IS_RC: isRC,
            USERFLOW_TOKEN: "ct_fxh4sxslojcylmssp62ufq5psy",
        };

        if (props.FORCE_PROD) {
            // do nothing
        } else {
            let lcmdServiceToken = props.lcmdServiceToken || props.serviceToken;
            //const lcmdAuthToken=props.lcmdAuthToken || props.authToken;
            if (lcmdServiceToken && "{" !== lcmdServiceToken[0]) {
                try {
                    lcmdServiceToken =
                        "undefined" !== typeof self
                            ? self.atob(lcmdServiceToken)
                            : Buffer.from(lcmdServiceToken, "base64").toString();
                } catch (e) {
                    lcmdServiceToken = null;
                }
            }
            if (lcmdServiceToken && "{" === lcmdServiceToken[0]) {
                try {
                    lcmdServiceToken = JSON.parse(lcmdServiceToken);
                } catch (e) {
                    lcmdServiceToken = null;
                }
            }
            const self_origin = "undefined" !== typeof self ? self.origin : undefined;
            const isCanary =
                props.FORCE_CANARY ||
                "https://staginglcmd2share-deckaddmh7hgbwh0.z01.azurefd.net" === self_origin ||
                "https://staging.lcmdigital.com" === self_origin ||
                "https://share.development.lcmdigital.com" === self_origin ||
                "https://lcmdigital.infra-bau.com" === self_origin ||
                (lcmdServiceToken ? lcmdServiceToken.isCanary : false);

            if (isCanary) {
                console.log("CANARY!!!!!!!");
                console.log({
                    "props.FORCE_CANARY": props.FORCE_CANARY,
                    "self.origin": self_origin,
                    lcmdServiceToken: lcmdServiceToken,
                });
                Object.assign(SERVICES, {
                    REST_API: "https://lcmd-rest-api-staging.azurewebsites.net/api",
                    AUTH_SERVICE: "https://staging-liveblocksapi2.azurewebsites.net/api/live",
                    LIVEBLOCKS_AUTH_SERVICE: "https://staging-liveblocksapi2.azurewebsites.net/api/liveblocks-auth",
                    NPS_SERVICE: "https://lcmd-nps.azurewebsites.net/api/nps",
                    PARSE_SERVICE: "https://staging-parseapi2.azurewebsites.net/api/parse",
                    UPLOAD_SERVICE: "https://staging-liveblocksapi2.azurewebsites.net/api/upload",
                    ATTACHMENT_SERVICE: "https://staging-liveblocksapi2.azurewebsites.net/api/attachment",
                    STORAGE_SERVICE: "https://staging-liveblocksapi2.azurewebsites.net/api/live",
                    PDF_SERVICE: "https://staging-pdfapi2.azurewebsites.net/api/pdf",
                    PROCORE_SERVICE: null, // disabled
                    OAUTH_REDIRECT_URL: "https://share.development.lcmdigital.com/oauth.html",
                    LIVEBLOCKS_KEY: "pk_dev_f-jl37VNiL_XmuUjnNFeQPu6e_3It8AWTCI25IBg2TMcPILPD64cvaBPNECRuCA3",
                    LICENSE_OVERRIDE: 1,
                    IS_CANARY: true,
                    USERFLOW_TOKEN: "ct_ulxnhmgq4nhu5ao5q2n4kmsgja",
                });
            }

            if (location?.host === "amprion.lcmdigital.com" || props.LOAD_CUSTOM_ENVIRONMENT === "amprion") {
                Object.assign(SERVICES, {
                    API_VERSION: API_VERSION,
                    MAJOR: FORCE_MAJOR,
                    BUILD: {
                        BUNDLE_HASH: BUNDLE_HASH,
                        WORKER_HASH: WORKER_HASH,
                        BRANCH: BRANCH,
                    },
                    REST_API: "https://amprion-rest-api.azurewebsites.net/api",
                    AUTH_SERVICE: "https://amprion-liveblocksapi.azurewebsites.net/api/live",
                    LIVEBLOCKS_AUTH_SERVICE: "https://amprion-liveblocksapi.azurewebsites.net/api/liveblocks-auth",
                    NPS_SERVICE: "https://lcmd-nps.azurewebsites.net/api/nps",

                    PARSE_SERVICE: "https://amprion-parseapi2.azurewebsites.net/api/parse",
                    UPLOAD_SERVICE: "https://amprion-liveblocksapi.azurewebsites.net/api/upload",
                    UPLOAD_SERVICE_MAX: 1 * 4 * 1024 * 1024, // 4KB
                    ATTACHMENT_SERVICE: "https://amprion-liveblocksapi.azurewebsites.net/api/attachment",
                    STORAGE_SERVICE: "https://amprion-liveblocksapi.azurewebsites.net/api/live",
                    PDF_SERVICE: "https://amprion-pdfapi.azurewebsites.net/api/pdf",
                    LIVEBLOCKS_KEY: "pk_prod_79H_cfoOGY1UG44rdXXST8d4AJw9_mq-FjMbFIzaSAl6Q0IhMBi_DGI9HD04CF5d",
                    PROCORE_SERVICE: null, // disabled
                    PROCORE_LOGIN:
                        "https://login.procore.com/oauth/authorize?client_id=f4939ad9330f716e02a23b59945c239a18362dc0476b26722cca5d42962af7a8&response_type=token",
                    OAUTH_REDIRECT_URL: "https://amprion.lcmdigital.com/oauth.html",
                    //OAUTH_REDIRECT_URL: "http://127.0.0.1:3000/oauth.html",
                    IS_RC: isRC,
                    USERFLOW_TOKEN: "ct_fxh4sxslojcylmssp62ufq5psy",
                });
            }
        }
    }
    if (null === (globalThis as any)?.lcmdigital?.services) {
        (globalThis as any).lcmdigital.services = SERVICES;
    }
}

export function getServicesToken() {
    return encodeURIComponent(window.btoa(JSON.stringify({ isCanary: SERVICES.IS_CANARY })));
}

export function isCanary() {
    return SERVICES.IS_CANARY || SERVICES.IS_DEV ? BUNDLE_HASH : null;
}

export function isReleaseCandidate() {
    return SERVICES.IS_RC ? true : false;
}

export function getServices() {
    return SERVICES;
}
