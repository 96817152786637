import {
    onRenderCanvas,
    onPointerDown,
    onPointerUp,
    onPointerMove,
    onPointerEnter,
    onPointerLeave,
    onPointerCancel,
    onClick,
} from "./handlers";

import { LeftColumn } from "./LeftColumn";
import { Overlay } from "./Overlay";

export const ResourceChart = {
    Component: LeftColumn, // TODO: probably should rename Component name when it uses.
    Overlay,
    onRenderCanvas,
    onPointerDown,
    onPointerUp,
    onPointerMove,
    onPointerEnter,
    onPointerLeave,
    onPointerCancel,
    onClick,
};
