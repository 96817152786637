import * as React from "react";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Minus, Plus } from "lucide-react";

type CounterProps = {
    onChange?: (num: number) => void;
    min?: number;
    value?: number;
    label?: string;
    step?: number;
    unit?: string;
};

const validatePattern = "^(d+(.d+)?)$";
const buttonClasses = "border border-solid border-gray-800 rounded-full text-gray-800 w-8 h-8 p-0";

export function Counter({ onChange, min = 0, value = 0, label = "", step = 1, unit = "" }: CounterProps) {
    const [num, setNum] = useState<number>(value);
    const labelValue = unit && unit != "" ? `${label} (${unit})` : label;

    useEffect(() => {
        if (num < min) {
            setNum(min);
        } else {
            setNum(value);
        }
    }, [value]);

    const validate = (value: string, event?: React.SyntheticEvent<HTMLElement>) => {
        if (isNaN(+value) || +value < min) {
            return;
        }
        {
            return value;
        }
    };

    const changeNum = (newValue: number = 0) => {
        setNum(+newValue);
        if (onChange) {
            onChange(+newValue);
        }
    };
    const increment = () => {
        const validation = validate((num + step).toString());
        if (validation) {
            changeNum(num + step);
        }
    };
    const decrement = () => {
        const validation = validate((num - step).toString());
        if (validation) {
            changeNum(num - step);
        }
    };

    if (typeof value !== "number") {
        throw new Error("value param needs to be of type number");
    }

    if (typeof min !== "number") {
        throw new Error("min param needs to be of type number");
    }

    if (typeof label !== "string") {
        throw new Error("label param needs to be of type string");
    }

    if (typeof step !== "number") {
        throw new Error("step param needs to be of type number");
    }

    if (typeof unit !== "string") {
        throw new Error("unit param needs to be of type string");
    }

    return (
        <div className="">
            <p className="text-[#323130] text-sm mb-1">{labelValue}</p>
            <div className="flex items-center relative">
                <Button variant="ghost" onClick={decrement} className={buttonClasses}>
                    <Minus strokeWidth="1" />
                </Button>
                <Input
                    type="number"
                    min={min}
                    value={num.toString()}
                    style={{ boxShadow: "none" }}
                    className="w-16 h-8 text-center border-gray-800 rounded-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none mx-1"
                    pattern={validatePattern}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        changeNum(+newValue);
                    }}
                />
                <Button variant="ghost" onClick={increment} className={buttonClasses}>
                    <Plus strokeWidth="1" />
                </Button>
            </div>
        </div>
    );
}
