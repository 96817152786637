import * as React from "react";

import { ReactNode } from "react";

import { IStackTokens, mergeStyleSets, Stack } from "@fluentui/react";

export type LayoutProps = {
    children: ReactNode;
};

const classNames = mergeStyleSets({
    layout: {
        backgroundColor: "#fff",
    },
});

const tokensProps: IStackTokens = {
    padding: "10px 15px",
    childrenGap: 20,
};

function Layout(props: LayoutProps) {
    const { children } = props;

    return (
        <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="end"
            tokens={tokensProps}
            className={classNames.layout}
        >
            {children}
        </Stack>
    );
}

export { Layout };
