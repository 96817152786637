import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalAssetHeader } from "../../ModalAsset";
import { DefaultButton, CommandButton, PrimaryButton } from "@fluentui/react";
import { ModalAssetFooter } from "../../ModalAsset/ModalAssetFooter";
import { Icons } from "../../../RegisteredIcons";
import { FilterType } from "../filter.interface";
import { AddFilterMenu } from "./AddFilterMenu";
import {
    AreasFilterRow,
    ResponsibleFilterRow,
    StatusFilterRow,
    ToDoTypeFilterRow,
    TradesFilterRow,
    PriorityFilterRow,
    DateFilterRow,
    RaisedOnRow,
} from "./FilterRows";
import { intl } from "@/legacy/GlobalHelperReact";
import { RaisedByFilterRow } from "./FilterRows/RaisedByFilterRow";
import { DeleteFilterButton } from "../../../common/deleteFilter/DeleteFilterButton";
import { useToDoModalFilterStore } from "../../../../app/store/toDoModalFilterStore";
import { styles } from "../filterModalStyles";

export function Overview() {
    const navigate = useNavigate();
    const location = useLocation();
    const { filterData, setFilterData, setIsModalVisible } = useToDoModalFilterStore();

    const iconsProps = { iconName: Icons.Lcmd_Filter, styles: { root: { color: "#000" } } };

    function headerTitle() {
        return <span style={{ fontSize: 20, fontWeight: 600 }}>{intl.get("ModalToDoFilter.Overview.filter")}</span>;
    }

    function rightTitle() {
        return <></>;
    }

    function filterEmpty() {
        return (
            <div className={styles.emptyWrapper}>
                <div className={styles.emptySVG}>
                    <svg width="171" height="156" viewBox="0 0 171 156" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M106.32 44.2942C105.144 50.4232 101.568 54.0592 95.1929 54.1942C95.6758 56.6757 96.0228 59.1818 96.2324 61.7011C96.2567 62.4211 95.2163 62.4058 95.0435 61.7911C94.4054 59.2846 93.9672 56.7314 93.7331 54.1555C90.07 53.7708 86.5182 52.67 83.2796 50.9155C83.1371 50.8498 83.026 50.7308 82.9702 50.5841C82.9145 50.4374 82.9185 50.2746 82.9814 50.1308C83.0444 49.9871 83.1612 49.8737 83.3068 49.8151C83.4524 49.7565 83.6152 49.7574 83.7602 49.8175C87.3395 50.9632 90.8702 52.576 94.7087 52.5454C102.033 52.4878 104.429 48.0976 105.239 41.5195C106.177 33.9199 105.599 28.0834 104.642 20.59C104.633 20.5117 104.639 20.4324 104.66 20.3565C104.682 20.2806 104.718 20.2096 104.767 20.1477C104.815 20.0858 104.876 20.0341 104.945 19.9955C105.014 19.957 105.089 19.9324 105.168 19.9231C105.246 19.9138 105.325 19.9201 105.401 19.9415C105.477 19.9629 105.548 19.999 105.61 20.0478C105.672 20.0966 105.724 20.1571 105.762 20.2259C105.801 20.2947 105.825 20.3704 105.835 20.4487C107.461 28.2998 107.625 36.3835 106.32 44.2942Z"
                            fill="#37555D"
                        />
                        <path
                            d="M77.13 48.1209C77.125 47.9697 77.0622 47.8261 76.9545 47.7199C76.8468 47.6136 76.7024 47.5528 76.5512 47.5499C76.3999 47.547 76.2533 47.6023 76.1416 47.7044C76.03 47.8064 75.9617 47.9475 75.951 48.0984C75.7356 50.0405 75.6784 51.997 75.78 53.9484C75.8268 55.365 75.8925 58.4799 76.455 59.6607C76.5176 59.7677 76.6101 59.8542 76.7211 59.9095C76.8321 59.9648 76.9568 59.9866 77.0799 59.9722C77.2031 59.9577 77.3194 59.9078 77.4146 59.8283C77.5098 59.7488 77.5798 59.6434 77.616 59.5248C77.8302 58.3548 77.3523 55.2858 77.2641 53.8674C77.1462 51.9468 77.193 50.0343 77.13 48.1209Z"
                            fill="#37555D"
                        />
                        <path
                            d="M77.0697 18.7515C67.3173 19.4949 69.4665 37.5129 77.0697 37.0998C84.6729 36.6867 82.6722 18.3249 77.0697 18.7515ZM73.3338 30.1077C72.5013 29.0745 72.3636 26.2764 73.0386 25.0731C73.4535 24.3351 74.5758 24.1191 74.3931 25.3953C74.1209 26.8071 74.1374 28.2595 74.4417 29.6649C74.4564 29.7994 74.4267 29.935 74.357 30.051C74.2874 30.167 74.1816 30.257 74.056 30.3072C73.9304 30.3574 73.7917 30.3651 73.6613 30.329C73.5309 30.293 73.4159 30.2152 73.3338 30.1077ZM75.5838 22.8636L75.0366 23.2173C74.9026 23.2748 74.7543 23.2904 74.6112 23.2617C74.4682 23.2331 74.3372 23.1618 74.2356 23.0571C73.5966 22.4091 74.8908 21.0276 75.6909 21.7215C75.7728 21.7966 75.8361 21.8896 75.876 21.9933C75.9159 22.097 75.9313 22.2085 75.9209 22.3191C75.9105 22.4297 75.8747 22.5364 75.8162 22.6309C75.7577 22.7253 75.6782 22.805 75.5838 22.8636Z"
                            fill="#37555D"
                        />
                        <path
                            d="M100.919 28.8361C99.486 28.9072 99.8541 32.2471 100.91 32.1724C102.747 32.0428 102.351 28.765 100.919 28.8361Z"
                            fill="#37555D"
                        />
                        <path
                            d="M93.2186 18.1862C102.475 20.087 109.914 17.0288 111.566 15.2936C113.218 13.5584 112.31 3.22733 105.864 1.07813C99.4169 -1.07107 81.1487 5.53133 75.7388 9.51023C84.7892 9.51023 90.4916 12.7313 93.2186 18.1862Z"
                            fill="#37555D"
                        />
                        <path
                            d="M38.2123 51.5527C35.8435 53.2006 34.0966 54.4246 33.5476 54.826C34.2043 56.7038 35.4633 58.3119 37.1287 59.3998C37.7029 59.1298 39.3481 58.0651 41.563 56.5801C41.4674 55.5294 41.1108 54.5192 40.5257 53.6412C39.9405 52.7632 39.1453 52.0454 38.2123 51.5527Z"
                            fill="#37555D"
                        />
                        <path
                            d="M89.5979 16.4794C83.6876 9.41262 70.3577 8.95542 63.9839 15.7918C59.06 21.0748 58.1177 29.3818 61.0481 35.8717C60.0581 36.5215 58.2373 37.7617 55.9792 39.3007C57.3062 40.8708 58.1838 42.7706 58.5191 44.7988C60.7529 43.222 63.199 41.512 64.306 40.7245C67.2417 43.8091 71.2335 45.6719 75.4833 45.9404C79.7331 46.2089 83.9277 44.8633 87.2281 42.1726C94.5208 36.4027 95.4128 23.431 89.5979 16.4794ZM84.9412 39.8839C78.3289 44.5639 69.2525 43.2364 64.8713 36.2047C61.4081 30.6445 61.8805 22.4653 66.529 17.6827C71.7049 12.3556 82.54 13.0288 87.1309 18.7519C91.7101 24.4615 91.2709 35.4073 84.9412 39.8839Z"
                            fill="#37555D"
                        />
                        <path
                            d="M96.6808 43.2753C95.5958 43.7515 94.3956 43.9019 93.2266 43.7082C92.0617 43.5246 90.9528 43.0824 89.9812 42.414C89.5177 42.1638 88.9111 42.7839 89.2675 43.224C90.2511 44.2644 91.5797 44.9112 93.0052 45.0438C94.204 45.2031 96.1948 45.0438 97.1452 44.2338C97.2493 44.1599 97.3239 44.0514 97.3556 43.9277C97.3873 43.804 97.3742 43.673 97.3185 43.5581C97.2628 43.4432 97.1682 43.3517 97.0515 43.2999C96.9347 43.2482 96.8034 43.2394 96.6808 43.2753Z"
                            fill="#37555D"
                        />
                        <path
                            d="M99.2699 35.146C98.9124 34.2855 98.4853 33.4557 97.9928 32.6647C97.9132 32.5368 97.7869 32.445 97.6407 32.4087C97.4945 32.3724 97.3399 32.3944 97.2097 32.4701C97.0794 32.5458 96.9838 32.6693 96.943 32.8143C96.9022 32.9593 96.9195 33.1145 96.9911 33.247C97.4357 34.3063 97.9604 35.317 98.3942 36.397C98.6363 36.9982 98.981 37.3924 98.2475 37.7182C97.1958 38.1217 96.0981 38.3937 94.9796 38.5282C94.3406 38.6335 94.5179 39.6226 95.1317 39.6244C96.0706 39.6053 97.0023 39.456 97.9001 39.1807C100.59 38.4094 100.218 37.2835 99.2699 35.146Z"
                            fill="#37555D"
                        />
                        <path
                            d="M103.216 23.9221C102.555 22.114 99.1892 21.2842 97.5692 22.4398C97.4781 22.5254 97.4173 22.6384 97.3962 22.7616C97.3751 22.8849 97.3947 23.0116 97.4521 23.1227C97.5094 23.2338 97.6015 23.3232 97.7142 23.3773C97.8269 23.4314 97.9542 23.4473 98.0768 23.4226C100.191 22.96 100.643 23.2534 102.18 24.6385C102.283 24.7522 102.425 24.8246 102.578 24.842C102.731 24.8594 102.885 24.8208 103.011 24.7333C103.138 24.6458 103.228 24.5153 103.266 24.3661C103.303 24.2169 103.286 24.0591 103.216 23.9221Z"
                            fill="#37555D"
                        />
                        <path
                            d="M34.29 59.9516C31.9869 62.4833 30.5856 66.0158 29.52 69.2216C28.3117 72.5507 27.7523 76.0805 27.8721 79.6202C27.9171 80.2799 28.9521 80.4212 29.0637 79.7237C29.498 76.4086 30.1822 73.1311 31.1103 69.9191C32.1366 66.6876 33.5698 63.5999 35.3754 60.7301C35.775 60.1073 34.8174 59.3702 34.29 59.9516Z"
                            fill="#37555D"
                        />
                        <path
                            d="M58.0113 48.2004C57.0942 44.1945 53.5734 35.7444 48.6873 35.3241C46.2267 35.1126 42.8859 36.5184 40.3983 37.449C27.81 42.1578 16.47 51.3891 8.73903 62.3583C3.43983 69.8787 -1.95117 80.658 0.69573 90.0783C2.14293 95.2281 5.46573 98.4672 10.89 99.7776C16.7238 101.189 23.0238 99.7866 28.8828 99.2259C29.026 99.212 29.1582 99.1433 29.2518 99.034C29.3453 98.9247 29.3929 98.7835 29.3845 98.6399C29.3761 98.4963 29.3124 98.3615 29.2068 98.2639C29.1012 98.1662 28.9618 98.1133 28.818 98.1162C23.328 98.136 17.8515 99.5211 12.3912 98.3259C7.53123 97.2639 3.35703 94.4928 2.19603 89.4393C0.162031 80.5788 4.54953 71.3691 9.61383 63.9198C17.3493 52.6952 28.2302 44.0077 40.8888 38.9493C43.0083 38.0898 46.5318 36.4959 48.6531 36.8973C52.6176 37.6452 55.7991 45.2124 56.5956 48.6153C56.8116 49.5405 58.2219 49.1229 58.0113 48.2004Z"
                            fill="#37555D"
                        />
                        <path
                            d="M52.7528 46.0975C52.0022 44.8195 50.5793 42.9772 49.2041 42.3868C49.0515 42.3348 48.886 42.3346 48.7332 42.3861C48.5804 42.4377 48.4489 42.5381 48.3589 42.6719C48.2689 42.8057 48.2256 42.9655 48.2355 43.1264C48.2455 43.2873 48.3082 43.4405 48.4139 43.5622C49.8593 45.6169 51.6611 46.2136 53.1974 51.0574C53.2622 51.186 53.3672 51.29 53.4963 51.3537C53.6255 51.4174 53.7718 51.4374 53.9133 51.4106C54.0548 51.3837 54.1837 51.3116 54.2806 51.205C54.3774 51.0984 54.437 50.9632 54.4502 50.8198C54.2189 49.1448 53.6408 47.5365 52.7528 46.0975Z"
                            fill="#37555D"
                        />
                        <path
                            d="M44.4114 45.294C44.2495 45.2772 44.0865 45.3143 43.9477 45.3994C43.8089 45.4846 43.7019 45.613 43.6434 45.7649C43.5848 45.9169 43.5779 46.0838 43.6237 46.2401C43.6694 46.3964 43.7654 46.5332 43.8966 46.6296C45.0666 47.7375 45.8199 47.8815 47.0349 49.4556C47.926 50.5977 48.646 51.8635 49.1724 53.2131C49.5882 54.3525 50.4324 53.5893 50.517 52.8846C50.6556 51.693 49.1112 49.1352 48.4245 48.2748C47.5443 47.1714 45.81 45.5856 44.4114 45.294Z"
                            fill="#37555D"
                        />
                        <path
                            d="M39.2834 49.7579C40.52 50.5967 41.3534 50.7479 42.4415 52.0178C43.1788 52.8947 43.6498 53.9643 43.7987 55.1003C44.0516 56.435 45.6086 56.1524 45.5087 54.9014C45.3287 53.4058 44.7106 51.9967 43.7321 50.8514C41.0681 47.7122 37.8281 48.7697 39.2834 49.7579Z"
                            fill="#37555D"
                        />
                        <path
                            d="M144.708 104.419C142.838 108.021 140.549 111.391 137.889 114.458C136.566 115.946 131.961 120.681 129.367 121.972C128.64 122.332 126.121 121.925 125.518 121.845C125.045 121.783 124.756 122.664 125.559 123.01C126.919 123.471 128.357 123.655 129.789 123.55C131.534 123.28 136.399 118.493 139.067 115.469C141.85 112.38 144.126 108.87 145.812 105.069C146.125 104.338 145.109 103.671 144.708 104.419Z"
                            fill="#37555D"
                        />
                        <path
                            d="M107.916 131.679C113.611 132.207 119.325 130.915 124.239 127.989C124.885 127.555 124.114 126.639 123.476 126.925C117.32 129.729 111.997 130.627 107.963 130.516C107.37 130.498 106.883 131.57 107.916 131.679Z"
                            fill="#37555D"
                        />
                        <path
                            d="M115.837 135.729C117.861 135.449 122.425 134.572 124.004 133.463C124.544 133.081 124.211 132.052 123.554 132.238C120.931 133.072 118.253 133.721 115.539 134.179C112.806 134.522 110.049 134.636 107.297 134.521C106.62 134.5 106.614 135.584 107.257 135.691C110.099 136.133 112.991 136.146 115.837 135.729Z"
                            fill="#37555D"
                        />
                        <path
                            d="M124.674 138.625C126.526 137.642 125.754 136.653 123.823 137.185C119.209 138.465 115.959 139.819 108.067 139.124C107.393 139.064 107.337 140.049 107.948 140.259C111.17 141.369 121.187 140.477 124.674 138.625Z"
                            fill="#37555D"
                        />
                        <path
                            d="M167.968 99.3845C166.006 96.7593 163.733 94.3808 161.201 92.3006C160.64 91.8074 159.786 92.5706 160.339 93.1106C162.618 95.1709 164.695 97.4431 166.543 99.8966C170.118 105.066 170.673 110.273 166.121 115.177C160.955 120.978 155.212 126.239 148.982 130.879C136.126 140.176 121.731 144.355 109.534 143.09C108.775 143.011 108.751 144.113 109.465 144.243C117.733 145.751 128.443 143.186 136.083 139.916C147.543 135.01 159.016 125.66 167.285 116.246C172.178 110.67 172.062 105.306 167.968 99.3845Z"
                            fill="#37555D"
                        />
                        <path
                            d="M67.9049 21.5625C67.9477 21.4111 67.9405 21.25 67.8846 21.1031C67.8286 20.9561 67.7267 20.8311 67.5941 20.7465C67.4615 20.662 67.3052 20.6224 67.1483 20.6337C66.9914 20.645 66.8424 20.7065 66.7232 20.8092C65.5604 22.0071 64.7927 24.7989 64.6136 26.4486C64.313 28.4582 64.6422 30.5116 65.5559 32.3265C65.6217 32.4463 65.7278 32.5388 65.8555 32.5875C65.9832 32.6362 66.124 32.638 66.2529 32.5924C66.3817 32.5469 66.4901 32.457 66.5588 32.3389C66.6275 32.2208 66.652 32.0821 66.6278 31.9476C66.1568 30.2088 65.9882 28.402 66.1292 26.6061C66.3947 24.2202 67.1156 23.5596 67.9049 21.5625Z"
                            fill="#B4C85D"
                        />
                        <path
                            d="M69.6923 19.0498C70.0091 18.8437 70.2971 18.6106 70.5923 18.4198C70.7391 18.3241 70.8427 18.1748 70.8807 18.0038C70.9188 17.8327 70.8885 17.6536 70.7961 17.5047C70.7038 17.3557 70.5568 17.2489 70.3867 17.207C70.2166 17.1651 70.0368 17.1914 69.8858 17.2804C69.5028 17.4735 69.1653 17.7459 68.8958 18.0796C68.5016 18.6142 69.0299 19.4863 69.6923 19.0498Z"
                            fill="#B4C85D"
                        />
                        <path
                            d="M101.317 61.4941C96.1388 72.2941 75.5315 69.3178 72.3374 61.4941C62.2799 63.5605 43.5446 69.6391 32.8859 74.941C31.454 82.321 31.5638 99.9556 32.8859 106.788C36.1619 106.577 46.8233 104.948 54.2042 103.648C54.8035 99.1343 55.7574 94.6745 57.0572 90.3103C57.2831 89.5777 58.5719 89.764 58.4072 90.5461C54.9413 107.339 53.7272 130.039 54.0404 149.544C75.6404 159.681 116.025 154.448 127.156 149.544C126.769 148.387 126.219 146.097 125.888 145.192C122.802 145.633 115.747 146.872 110.348 145.991C104.949 145.11 103.792 129.765 106.988 128.993C110.184 128.222 117.284 128.772 123.458 124.474C123.613 117.352 120.638 97.1305 119.069 91.6738C118.863 90.9592 120.084 90.3985 120.384 91.1932C121.206 93.3685 122.74 101.19 123.032 102.818C127.72 104.726 130.697 105.608 137.462 108.331C142.2 101.715 153 93.2299 160.162 89.0431C148.702 75.2686 124.128 62.5957 101.317 61.4941Z"
                            fill="#B4C85D"
                        />
                    </svg>
                </div>
                <br />
                <div className={styles.emptyTitle}>{intl.get("ModalToDoFilter.Overview.title")}</div>
                <div style={{ marginBottom: 20 }}>{intl.getHTML("ModalToDoFilter.Overview.subTitle")}</div>
                <br />

                <div>
                    <DefaultButton
                        iconProps={{ iconName: Icons.Lcmd_Filter }}
                        text={intl.get("ModalToDoFilter.Overview.buttonPriority")}
                        className={styles.overviewDefaultButton}
                        onClick={() => {
                            navigate("priority-filter");
                        }}
                    />
                    <DefaultButton
                        iconProps={{ iconName: Icons.Lcmd_Filter }}
                        text={intl.get("ModalToDoFilter.Overview.buttonStatus")}
                        className={styles.overviewDefaultButton}
                        onClick={() => {
                            navigate("status-filter");
                        }}
                    />
                    <DefaultButton
                        iconProps={{ iconName: Icons.Lcmd_Filter }}
                        text={intl.get("ModalToDoFilter.Overview.buttonTrades")}
                        className={styles.overviewDefaultButton}
                        onClick={() => {
                            navigate("trades-filter");
                        }}
                    />
                </div>

                <div style={{ marginBottom: 20 }}>
                    <CommandButton
                        text={intl.get("ModalToDoFilter.Overview.buttonMore")}
                        menuProps={{
                            items: [
                                {
                                    key: "responsibleFilter",
                                    text: intl.get("ModalToDoFilter.Overview.buttonResponsible"),
                                    iconProps: iconsProps,
                                    onClick: () => {
                                        navigate("/responsible-filter");
                                    },
                                },
                                {
                                    key: "dateFilter",
                                    text: intl.get("ModalToDoFilter.Overview.buttonDeadline"),
                                    iconProps: iconsProps,
                                    onClick: () => {
                                        navigate("date-filter");
                                    },
                                },
                                /*
                            {
                                key: 'raisedOnFilter',
                                text: intl.get("ModalToDoFilter.Overview.buttonRaisedOn"),
                                iconProps: iconsProps,
                                onClick: () => {
                                    navigate('raised-on-filter')
                                }
                            },
                             */
                                {
                                    key: "toDoTypeFilter",
                                    text: intl.get("ModalToDoFilter.Overview.buttonToDoType"),
                                    iconProps: iconsProps,
                                    onClick: () => {
                                        navigate("todo-type-filter");
                                    },
                                },
                                {
                                    key: "areasFilter",
                                    text: intl.get("ModalToDoFilter.Overview.buttonAreas"),
                                    iconProps: iconsProps,
                                    onClick: () => {
                                        navigate("areas-filter");
                                    },
                                },
                                {
                                    key: "raisedByfilter",
                                    text: intl.get("ModalToDoFilter.Overview.buttonRaisedBy"),
                                    iconProps: iconsProps,
                                    onClick: () => {
                                        navigate("raised-by-filter");
                                    },
                                },
                            ],
                        }}
                    />
                </div>
            </div>
        );
    }

    function isFilterEmpty() {
        const filteredData = Object.keys(filterData || {}).filter((key) => filterData[key]?.length > 0);
        return !(filteredData.length && Boolean(filterData));
    }

    function footerLeftContent() {
        return <DeleteFilterButton onClick={() => setFilterData(null)} />;
    }

    function footerRightContent() {
        return (
            <>
                <DefaultButton
                    styles={{ root: { marginRight: 15 } }}
                    text={intl.get("ModalToDoFilter.Overview.buttonCancel")}
                    onClick={() => setIsModalVisible(false)}
                />
                <PrimaryButton
                    text={intl.get("ModalToDoFilter.Overview.buttonApply")}
                    onClick={() => {
                        setFilterData(filterData);
                        setIsModalVisible(false);
                    }}
                />
            </>
        );
    }

    function generateFilterRow() {
        if (!filterData) {
            return null;
        }
        return Object.keys(filterData).map((key) => {
            if (!filterData[key] || filterData[key]?.length <= 0) {
                return null;
            }
            switch (key) {
                case FilterType.TYPE:
                    return (
                        <ToDoTypeFilterRow
                            label={intl.get("ModalToDoFilter.Overview.titleToDoTypeRow")}
                            key={FilterType.TYPE}
                            onModifyButtonClick={() => {
                                navigate("todo-type-filter");
                            }}
                            badgeCount={filterData.typeFilterData.length}
                            data={filterData.typeFilterData}
                        />
                    );
                case FilterType.RESPONSIBLE:
                    return (
                        <ResponsibleFilterRow
                            label={intl.get("ModalToDoFilter.Overview.titleResponsible")}
                            key={FilterType.RESPONSIBLE}
                            onModifyButtonClick={() => {
                                navigate("responsible-filter");
                            }}
                            badgeCount={filterData.responsibleFilterData.length}
                            data={filterData.responsibleFilterData}
                        />
                    );
                case FilterType.DATE:
                    return (
                        <DateFilterRow
                            label={intl.get("ModalToDoFilter.Overview.titleDeadline")}
                            key={FilterType.DATE}
                            onModifyButtonClick={() => {
                                navigate("date-filter");
                            }}
                            data={filterData.dateFilterData}
                        />
                    );
                case FilterType.RAISEDON:
                    return (
                        <RaisedOnRow
                            label={intl.get("ModalToDoFilter.Overview.titleRaisedOn")}
                            key={FilterType.RAISEDON}
                            onModifyButtonClick={() => {
                                navigate("raised-on-filter");
                            }}
                            data={filterData.raisedOnFilterData}
                        />
                    );
                case FilterType.TRADES:
                    return (
                        <TradesFilterRow
                            label={intl.get("ModalToDoFilter.Overview.titleTrades")}
                            key={FilterType.TRADES}
                            onModifyButtonClick={() => {
                                navigate("trades-filter");
                            }}
                            badgeCount={filterData.tradesFilterData.length}
                            data={filterData.tradesFilterData}
                        />
                    );
                case FilterType.AREAS:
                    return (
                        <AreasFilterRow
                            label={intl.get("ModalToDoFilter.Overview.titleAreas")}
                            key={FilterType.AREAS}
                            onModifyButtonClick={() => {
                                navigate("areas-filter");
                            }}
                            badgeCount={filterData.areasFilterData.length}
                            data={filterData.areasFilterData}
                        />
                    );
                case FilterType.PRIORITY:
                    return (
                        <PriorityFilterRow
                            label={intl.get("ModalToDoFilter.Overview.titlePriority")}
                            key={FilterType.PRIORITY}
                            onModifyButtonClick={() => {
                                navigate("priority-filter");
                            }}
                            badgeCount={filterData.priorityFilterData.length}
                            data={filterData.priorityFilterData}
                        />
                    );
                case FilterType.STATUS:
                    return (
                        <StatusFilterRow
                            label={intl.get("ModalToDoFilter.Overview.titleStatus")}
                            key={FilterType.STATUS}
                            onModifyButtonClick={() => {
                                navigate("status-filter");
                            }}
                            badgeCount={filterData.statusFilterData.length}
                            data={filterData.statusFilterData}
                        />
                    );
                case FilterType.RAISEDBY:
                    return (
                        <RaisedByFilterRow
                            label={intl.get("ModalToDoFilter.Overview.titleRaisedBy")}
                            key={FilterType.RAISEDBY}
                            onModifyButtonClick={() => {
                                navigate("raised-by-filter");
                            }}
                            badgeCount={filterData.raisedByFilterData.length}
                            data={filterData.raisedByFilterData}
                        />
                    );
                default:
                    return null;
            }
        });
    }

    function filterContent() {
        return (
            <>
                {generateFilterRow()}
                <AddFilterMenu />
            </>
        );
    }

    return (
        <>
            <ModalAssetHeader
                title={headerTitle()}
                rightSide={rightTitle()}
                onCloseButtonChange={() => setIsModalVisible(false)}
            />

            <div className={styles.bodyOverview}>{isFilterEmpty() ? filterEmpty() : filterContent()}</div>
            <ModalAssetFooter leftContent={footerLeftContent()} rightContent={footerRightContent()} />
        </>
    );
}
