import { create } from "zustand";

type ConflictStore = ConflictState & ConflictActions;

type ConflictState = {
    conflictCount: number;
}

type ConflictActions = {
    actions: {
        setConflictCount: (conflictCount: number) => void;
        reset: () => void;
    };
}

const initalState: ConflictState = {conflictCount: 0};

export const useConflictStore = create<ConflictStore>((set) => ({
    ...initalState,
    actions: {
        setConflictCount: (conflictCount: number) => {
            if (conflictCount === 0) {
                set({ conflictCount });
            } else {
                set({ conflictCount });
            }
        },
        reset: () => {set({...initalState})}
    },
}));

export function useConflictActions() {
    return useConflictStore((state) => state.actions);
}

export function useConflictCount() {
    /*
    const selectedProcessIds = useSelectedProcessIds();
    const conflictActions = useConflictActions();

    useEffect(() => {
        if (selectedProcessIds.length !== 1) {
            conflictActions.setConflictCount(0);
            conflictActions.setShowConflictMode(false);
            return;
        }

        conflictActions.setConflictCount(4);
    }, [selectedProcessIds]);
*/
    return useConflictStore((state) => state.conflictCount);
}
