import { DefaultButton, PrimaryButton, StackItem, Stack } from "@fluentui/react";
import { intl } from "../../../legacy/GlobalHelperReact";
import * as React from "react";
import { ReasonCodeItem } from "./reason-codes";
import { LcmdModal } from "../../common/LcmModal";

export function ReasonCodeDeleteDialog({
    reasonCode,
    onClose,
    onCancel,
}: {
    reasonCode: ReasonCodeItem;
    onCancel: () => void;
    onClose: (itemToDelete: ReasonCodeItem) => void;
}) {
    const cancelButton = (
        <DefaultButton onClick={onCancel} styles={{ root: { borderRadius: "4px" } }}>
            {intl.get("ReasonCodesSettings.delete.cancelButton")}
        </DefaultButton>
    );

    const deleteButton = (
        <PrimaryButton
            styles={{ root: { borderRadius: "4px", backgroundColor: "#A4262C" } }}
            onClick={() => onClose(reasonCode)}
        >
            {intl.get("ReasonCodesSettings.delete.deleteButton")}
        </PrimaryButton>
    );

    const buttons = [cancelButton, deleteButton];

    return (
        <LcmdModal
            buttons={buttons}
            onDismiss={onCancel}
            isOpen={true}
            header={{ title: intl.get("ReasonCodesSettings.delete.title") }}
        >
            <Stack
                styles={{ root: { width: "600px", height: "226px" } }}
                padding={"32px 24px"}
                tokens={{ childrenGap: 32 }}
            >
                <StackItem>
                    {intl.getHTML("ReasonCodesSettings.delete.confirmQuestion", { name: reasonCode.name })}
                </StackItem>
                <StackItem>{intl.get("ReasonCodesSettings.delete.confirmText")}</StackItem>
            </Stack>
        </LcmdModal>
    );
}
