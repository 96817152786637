import * as React from "react";

import { Widget } from "../../layout/widget";

import { Header } from "./header";
import { Content } from "./content";
import { DashboardCtxPropI } from "../..";

type ProgressPlanningProps = DashboardCtxPropI & {
    minWidth?: number;
    basicWidth?: number;
};

function ProgressPlanning(props: ProgressPlanningProps) {
    return (
        <Widget basicWidth={props.basicWidth} minWidth={props.minWidth} userflowId="chart-fortschritt">
            <Header />
            <Content {...props} />
        </Widget>
    );
}

export { ProgressPlanning };
