import { useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import { useEmailStore } from "../../store/emailStore";
import { Input } from "@/components/ui/input";
import { IconButton } from "../common/iconButton/IconButton";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { PasswordField } from "../common/PasswordComponent/PasswordField";
import { Paths } from "../../types/routes";
import { intl } from "@/legacy/GlobalHelperReact";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Checkbox } from "@/components/ui/checkbox";
import { AuthClient, HttpError } from "@/app/services/AuthClient.service";
import { Label } from "@/components/ui/label";
import { showScreenOfDeath } from "@/legacy/ScreenOfDeath";
import { MessageDialog } from "@/lcmd2loginV2/src/components/common/MessageDialog/MessageDialog";

export function Register() {
    const navigate = useNavigate();
    const { email } = useEmailStore();
    const [password, setPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showValidateDialog, setShowValidateDialog] = useState(false);

    const handleRegister = async (values: z.infer<typeof formSchema>) => {
        try {
            await AuthClient.createUser({
                email,
                password: password,
                firstName: values.firstName,
                lastName: values.lastName,
            });

            setShowValidateDialog(true);
        } catch (error) {
            // maybe keep this in case of doing separate actions
            if (error instanceof HttpError && error.status === 409) {
                showScreenOfDeath("user already exists");
                return;
            }

            showScreenOfDeath(`network error ${error.status}`);
            return;
        }
    };

    useEffect(() => {
        if (email === "") {
            navigate(Paths.HOME);
        }
    }, [email]);

    function handleBack() {
        navigate(Paths.HOME);
    }

    function handleCloseVerification() {
        navigate(Paths.LOGIN);
    }

    async function sendVerifyEmail() {
        setIsLoading(true);
        await AuthClient.requestPasswordReset(email);
        setIsLoading(false);
    }

    const handleSubmit = async (values: z.infer<typeof formSchema>) => {
        setIsLoading(true);
        try {
            await handleRegister(values);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const formSchema = z.object({
        firstName: z.string().min(1, {
            message: intl.get("LcmdLogin.Register.FirstNameRequired"),
        }).trim(),
        lastName: z.string().min(2, {
            message: intl.get("LcmdLogin.Register.LastNameRequired"),
        }).trim(),
        isPasswordValid: z
            .literal<boolean>(true, {
                errorMap: () => ({ message: intl.get("LcmdLogin.Register.PasswordInvalid") }),
            })
            .default(false),
        termsAndConditionsAccepted: z
            .literal<boolean>(true, {
                errorMap: () => ({ message: intl.get("LcmdLogin.Register.TCRequired") }),
            })
            .default(false),
    });

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            termsAndConditionsAccepted: false,
        },
        values: { isPasswordValid: isPasswordValid },
    });

    return (
        <>
            <MessageDialog
                onClose={() => {
                    handleCloseVerification();
                }}
                buttonText={intl.get("LcmdLogin.ConfirmEmail.ResendLink")}
                message={intl.get("LcmdLogin.ConfirmEmail.ConfirmFirstTime")}
                onButtonClick={() => {
                    sendVerifyEmail();
                }}
                open={showValidateDialog}
                title={intl.get("LcmdLogin.ConfirmEmail.Welcome")}
                buttonDisabled={isLoading}
                buttonLoading={isLoading}
            ></MessageDialog>
            <div className={"flex gap-1 mb-2"}>
                <IconButton icon={ArrowLeft} onClick={handleBack} />
                <div className={"text-zinc-900 text-xl font-semibold"}>{intl.get("LcmdLogin.Register.Headline")}</div>
            </div>
            <div className={"text-zinc-400 text-sm font-medium mb-8"}>
                {intl.get("LcmdLogin.Register.Subtitle")} <span className={"text-black"}>{email}</span>
            </div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)} autoComplete="off" spellCheck="false">
                    <input type="text" style={{ display: "none" }} />
                    <div className={"flex gap-4 mb-4"}>
                        <div className={"space-y-1"}>
                            <FormField
                                control={form.control}
                                name="firstName"
                                disabled={isLoading}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{intl.get("LcmdLogin.Register.FirstName")}</FormLabel>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                type="text"
                                                placeholder={intl.get("LcmdLogin.Register.FirstNamePlaceholder")}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className={"space-y-1"}>
                            <FormField
                                control={form.control}
                                name="lastName"
                                disabled={isLoading}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{intl.get("LcmdLogin.Register.LastName")}</FormLabel>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                type="text"
                                                placeholder={intl.get("LcmdLogin.Register.LastNamePlaceholder")}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <FormField
                            name="isPasswordValid"
                            control={form.control}
                            disabled={isLoading}
                            render={(form) => (
                                <FormItem>
                                    <PasswordField
                                        autoComplete={"new-password"}
                                        value={password}
                                        onChange={setPassword}
                                        withValidator={true}
                                        onValidChanged={(valid) => {
                                            if (form.field.value !== valid) {
                                                const fakeEvent = {
                                                    target: {
                                                        value: valid,
                                                    },
                                                };
                                                form.field.onChange(fakeEvent);
                                            }
                                        }}
                                    />
                                    <FormMessage />
                                </FormItem>
                            )}
                        ></FormField>
                        {/* <div className={"mt-2"}> */}
                        {/*     <FormField */}
                        {/*         name={"isPasswordValid"} */}
                        {/*         control={form.control} */}
                        {/*         disabled={isLoading} */}
                        {/*         render={(form) => ( */}
                        {/*             <FormItem> */}
                        {/*                 <FormMessage /> */}
                        {/*             </FormItem> */}
                        {/*         )} */}
                        {/*     ></FormField> */}
                        {/* </div> */}
                    </div>
                    <div>
                        <FormField
                            control={form.control}
                            name={"termsAndConditionsAccepted"}
                            disabled={isLoading}
                            render={({ field }) => (
                                <FormItem>
                                    <div className={"flex gap-2 mt-8"}>
                                        <FormControl>
                                            <Checkbox
                                                checked={field.value}
                                                onCheckedChange={(a) => {
                                                    field.onChange(a);
                                                }}
                                                className={"h-5 w-5"}
                                            />
                                        </FormControl>
                                        <div className={"flex flex-col gap-2"}>
                                            <Label
                                                htmlFor="terms"
                                                className="text-sm text-muted-foreground peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                            >
                                                {intl.get("LcmdLogin.Register.TCSubtitle")}{" "}
                                                <a
                                                    className={"text-blue-600 hover:underline"}
                                                    href={intl.get("LcmdLogin.Register.TCLink")}
                                                    target={"_blank"}
                                                    rel="noreferrer"
                                                >
                                                    {intl.get("LcmdLogin.Register.TCLinkContent")}
                                                </a>
                                            </Label>
                                        </div>
                                    </div>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <Button type="submit" className={"h-12 w-full mt-8"} disabled={isLoading}>
                        {intl.get("LcmdLogin.Register.SubmitButton")}
                    </Button>
                </form>
            </Form>
        </>
    );
}
