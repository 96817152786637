import { mergeStyleSets, Shimmer, Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { LcmdModal } from "../../common/LcmModal";
import { useParticleContext } from "../../../legacy/api/ParticleContext";

const classNames = mergeStyleSets({
    even: {
        backgroundColor: "#f1f3f3",
    },
    odd: {
        backgroundColor: "#ffffff",
    },
});

const boxes = mergeStyleSets({
    root: {
        overflowY: "auto",
        minWidth: 50,
        minHeight: 14,
        maxHeight: 150,
        fontSize: 14,
        border: "1px solid #e1e4e6",
        padding: 5,
    },
});

export function AreaListPopup({
    isOpen,
    taktzone,
    onClose,
}: {
    isOpen: boolean;
    taktzone: number;
    onClose: () => void;
}) {
    const LCMD = useParticleContext();
    const { isLoading, isError, error, data } = LCMD.useAreaPath(taktzone, undefined, true);
    if ((isLoading === false || isLoading == null) && isError) {
        console.warn(error.message);
    }
    if (isLoading || taktzone === 0) {
        return <Shimmer />;
    }

    return (
        <LcmdModal header={{ title: "AreaList" }} isOpen={isOpen} onDismiss={onClose}>
            <Stack tokens={{ childrenGap: 8 }}>
                {data.areaPath.map((path, index) => (
                    <StackItem
                        className={index % 2 == 0 ? classNames.even : classNames.odd}
                        styles={boxes}
                        key={path.id}
                    >
                        {path.name}
                    </StackItem>
                ))}
            </Stack>
        </LcmdModal>
    );
}
