import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import {
    IconButton,
    IContextualMenuItem,
    IIconProps,
    mergeStyleSets,
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    IColumn,
    ITextFieldStyles,
    IContextualMenuProps,
} from "@fluentui/react";
import { StabilityCriteriaDeleteDialog } from "./DeleteStabilityCriteriaDialog";
import { SettingsModal } from "./SettingsModal";
import { intl } from "@/legacy/GlobalHelperReact";
import { CoreExtensions } from "../../../extensions/core.extensions";
import { copyAndSort } from "../../Utils";
import { useLCMD } from "../../../app/LCMContext";
import { useT3RolesHook } from "../../hooks/useT3Roles.hook";
import { useCanCreateStabilityCriteria } from "../../hooks/useCanCreateStabilityCriteria.hook";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { SearchBar } from "@/components/common/SearchBar/SearchBar";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";

type StabilityCriteriaItem = {
    key: string;
    name: string;
    leadTimeValue: number;
    trades: any[];
};

type StabilityCriteriaExtensionFields = {
    criteria: {
        label: string;
        dropdown: string[];
        "stability.stable": string[];
        "stability.lead": number;
        width: number;
        "ui.index": number;
        "stability.trades": string[];
    };
};

type DeleteLCMDExtensionCommand = Pick<LCMDExtension, "lcmdx" | "id">;

interface LCMDExtension<T = void> {
    lcmdx: number;
    id: string;
    patch: {
        prio?: number;
        process?: T;
    };
}

interface LCMDProcessExtension<T> {
    fields: T;
}

type ProcessExtensionFields = {
    label: string;
    dropdown: string[];
    "stability.stable": string[];
    "stability.lead": number;
    width: number;
    "ui.index": number;
    id: string;
    ext: string;
    prio: number;
    key: string;
};

type LCMDStabilityCriteriaExtension = LCMDExtension<LCMDProcessExtension<StabilityCriteriaExtensionFields>>;

function putExtensionHelper(LCMD, ext) {
    const criteria = ext.patch.process.fields.criteria;

    if (criteria["stability.trades"]) {
        (LCMD as any).getSafeIDs({ trids: criteria["stability.trades"] }, (error, safeIDs) => {
            if (error) {
                console.warn(error);
            } else {
                criteria["stability.trades"] = safeIDs.trids;
                LCMD.putExtension(ext);
            }
        });
    } else {
        delete criteria["stability.trades"];
        LCMD.putExtension(ext);
    }
}

type FilterState = {
    searchText: string;
    filterColumns: string[];
};
type SortState = {
    sortByColumn: IColumn;
};

let allItems: StabilityCriteriaItem[] = [];

export function SettingsList() {
    const LCMD = useLCMD();
    const [items, setItems] = useState<StabilityCriteriaItem[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showStabilityCriteriaDeleteDialog, setShowStabilityCriteriaDeleteDialog] = useState(false);
    const [stabilityCriteriaToChange, setStabilityCriteriaToChange] = useState<StabilityCriteriaItem>(null);
    const [columns, setColumns] = useState<IColumn[]>(getDefaultColumns());
    const [allTrades, setAllTrades] = useState(new Map());
    const [filterState, setFilterState] = useState<FilterState>({
        filterColumns: ["name", "leadTime", "invokesTo"],
        searchText: "",
    });
    const [sortState, setSortState] = useState<SortState>({ sortByColumn: null });
    const canT3CreateOrEditStability = useT3RolesHook();
    const canCreateOrEditStability = useCanCreateStabilityCriteria();
    const isAllowed = canT3CreateOrEditStability && canCreateOrEditStability;

    const stabilityCriteriaExtensionId = CoreExtensions.STABILITY_CRITERIA_SETTINGS;
    const textFieldStyles: Partial<ITextFieldStyles> = { root: { maxWidth: "300px" } };
    const addIcon: IIconProps = { iconName: "Add" };

    const idRegExp = new RegExp(`^${stabilityCriteriaExtensionId}`);
    const selection = new Selection();

    const generateExtensionId = () => {
        return `${stabilityCriteriaExtensionId}.${LCMD.generateId()}`;
    };

    const deleteStabilityCriteria = (extensionKey: string) => {
        const deleteStabilityCriteriaCommand: DeleteLCMDExtensionCommand = {
            lcmdx: 1.0,
            id: extensionKey,
        };
        LCMD.putExtension(deleteStabilityCriteriaCommand);
    };

    useEffect(() => {
        WebAppTelemetryFactory.trackEvent("settings-stability-criteria-opened");
    }, []);
    LCMD.useTradesEffect((error, data) => {
        const tradeCache = new Map();
        if (error) {
            console.error(error);
        }
        let t = [];
        Object.keys(data).forEach((projectId) => {
            t = t.concat(data[projectId]);
        });
        t.forEach((trade) => {
            tradeCache.set(trade.id, trade);
        });

        setAllTrades(tradeCache);
    }, []);

    async function getUnsafeIds(trades: string[]): Promise<string[]> {
        return new Promise((resolve, reject) => {
            (LCMD as any).getUnsafeIDs({ trids: trades }, (error, data) => {
                if (error) {
                    reject();
                    return;
                }
                resolve(data.trids);
            });
        });
    }

    function getAllItemsFromApi() {
        LCMD.getExtensions(async (error, extensions: { process: ProcessExtensionFields[] }) => {
            if (error) {
                console.error(error);
            } else {
                allItems = [];
                if (extensions?.process) {
                    for (const processExtension of extensions.process) {
                        if (idRegExp.test(processExtension.ext)) {
                            const stabilityCriteriaItem: StabilityCriteriaItem = {
                                key: processExtension.ext,
                                name: processExtension.label ? processExtension.label : "",
                                leadTimeValue: processExtension["stability.lead"],
                                trades:
                                    allTrades.size > 0
                                        ? ((await getUnsafeIds(processExtension["stability.trades"])) || []).map((t) =>
                                              allTrades.get(t),
                                          )
                                        : [],
                            };
                            const checkUndefined = stabilityCriteriaItem.trades.indexOf(undefined);
                            if (checkUndefined == -1) {
                                allItems.push(stabilityCriteriaItem);
                            } else {
                                stabilityCriteriaItem.trades.splice(checkUndefined, 1);
                                allItems.push(stabilityCriteriaItem);
                            }
                        }
                    }
                }
                setItems(allItems);
            }
        });
    }

    useEffect(() => {
        getAllItemsFromApi();
    }, [LCMD, allTrades]);

    function onColumnClick(ev: React.MouseEvent<HTMLElement>, column: IColumn): void {
        setSortState({ sortByColumn: column });
    }

    useEffect(() => {
        if (sortState.sortByColumn) {
            const newColumns: IColumn[] = [...columns];
            const currColumn: IColumn = newColumns.filter((currCol) => sortState.sortByColumn.key === currCol.key)[0];
            newColumns.forEach((newCol: IColumn) => {
                if (newCol === currColumn) {
                    currColumn.isSortedDescending = !currColumn.isSortedDescending;
                    currColumn.isSorted = true;
                } else {
                    newCol.isSorted = false;
                    newCol.isSortedDescending = true;
                }
            });

            setColumns(newColumns);
        }
    }, [sortState]);

    useEffect(() => {
        setColumns(getDefaultColumns());
    }, [isAllowed]);

    useEffect(() => {
        if (!columns || columns.length == 0) {
            return;
        }
        if (filterState.searchText || sortState.sortByColumn) {
            let res = [...allItems];
            res = filterState.searchText
                ? allItems.filter((i) => {
                      return stringToLowerCase(i.name).indexOf(stringToLowerCase(filterState.searchText)) > -1;
                  })
                : allItems;

            if (sortState.sortByColumn) {
                res = copyAndSort(res, sortState.sortByColumn.key, sortState.sortByColumn.isSortedDescending);
            }
            setItems(res);
        } else {
            setItems([...allItems]);
        }
    }, [allItems, filterState, columns]);

    function stringToLowerCase(string: string) {
        return string ? string.toString().toLowerCase() : "";
    }

    const classNames = mergeStyleSets({
        calendarExceptionColumn: {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "-1,5%",
            fontWeight: 400,
        },
        selectionDetails: {
            display: "block",
            marginBottom: "10px",
        },
    });

    const styles = mergeStyleSets({
        root: {
            padding: "0 22px",
            width: "calc(100% - 50px)",
        },
        header: {
            padding: "10px 25px 10px 0",
            borderBottom: "1px solid #c3c3c3",
        },
        settingsList: {
            padding: "10px 25px 10px 0",
        },
    });

    function getDefaultColumns(): IColumn[] {
        return [
            {
                key: "name",
                name: intl.get("StabilityCriteria.SettingsList.NameColumn"),
                fieldName: "name",
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: onColumnClick,
            },
            {
                key: "functions",
                name: "",
                fieldName: "functions",
                minWidth: 20,
                maxWidth: 20,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                onRender: (item, index, column) => {
                    return (
                        <span className={classNames.calendarExceptionColumn}>
                            <IconButton
                                styles={{
                                    root: {
                                        color: "#999EA1",
                                        height: "20px",
                                    },
                                    rootDisabled: {
                                        backgroundColor: "transparent",
                                    },
                                }}
                                menuProps={createMenuProps(item, index, column)}
                                iconProps={{ iconName: "MoreVertical" }}
                                onMenuClick={() => {}}
                                data-selection-disabled={true}
                                data-is-focusable={false}
                                split={false}
                                onRenderMenuIcon={() => null}
                            />
                        </span>
                    );
                },
            },

            {
                key: "leadTimeValue",
                name: intl.get("StabilityCriteria.SettingsList.LeadTimeColumn"),
                fieldName: "leadTimeValue",
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: onColumnClick,
                onRender: (item: StabilityCriteriaItem) => {
                    return <span>{item.leadTimeValue} working days</span>;
                },
            },
            {
                key: "invokesTo",
                name: intl.get("StabilityCriteria.SettingsList.InvokesToColumn"),
                fieldName: "invokesTo",
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                onRender: (item: StabilityCriteriaItem) => {
                    return (
                        <span>
                            {item.trades.length} {intl.get("StabilityCriteria.SettingsList.Trades")}
                        </span>
                    );
                },
            },
        ];
    }

    function createMenuProps(item, index, column): IContextualMenuProps {
        return {
            items: [
                {
                    disabled: !isAllowed,
                    key: "editMessage",
                    text: intl.get("StabilityCriteria.SettingsList.Actions.Edit"),
                    iconProps: { iconName: "Edit" },
                    onClick: (
                        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
                        menuItem?: IContextualMenuItem,
                    ) => {
                        setStabilityCriteriaToChange(item);
                        setShowEditModal(true);
                    },
                },
                {
                    disabled: !isAllowed,
                    key: "deleteEvent",
                    text: intl.get("StabilityCriteria.SettingsList.Actions.Delete"),
                    iconProps: { iconName: "Delete" },
                    onClick: () => {
                        setStabilityCriteriaToChange(item);
                        setShowStabilityCriteriaDeleteDialog(true);
                    },
                },
            ],
        };
    }

    const editModal = useMemo(() => {
        const onClose = (editedStabilityCriteria) => {
            if (editedStabilityCriteria.key) {
                const originalStabilityCriteria = allItems.find((item) => {
                    return item.key === editedStabilityCriteria.key;
                });
                if (originalStabilityCriteria) {
                    originalStabilityCriteria.name = editedStabilityCriteria.name;
                    originalStabilityCriteria.trades = editedStabilityCriteria.trades;
                    originalStabilityCriteria.leadTimeValue = editedStabilityCriteria.leadTimeValue;

                    const patchedExtension: LCMDStabilityCriteriaExtension = {
                        lcmdx: 1.0,
                        id: originalStabilityCriteria.key,
                        patch: {
                            prio: 0,
                            process: {
                                fields: {
                                    criteria: {
                                        label: originalStabilityCriteria.name,
                                        "stability.lead": originalStabilityCriteria.leadTimeValue,
                                        "stability.stable": ["done"],
                                        "stability.trades": (originalStabilityCriteria.trades || []).map(
                                            (trade) => trade.id,
                                        ),
                                        width: 250,
                                        "ui.index": 0,
                                        dropdown: ["open", "done"],
                                    },
                                },
                            },
                        },
                    };
                    putExtensionHelper(LCMD, patchedExtension);
                }
                setItems([...allItems]);
                setStabilityCriteriaToChange(null);
            }
            hideModal();
        };

        const hideModal = () => {
            setShowEditModal(false);
        };

        return showEditModal ? (
            <SettingsModal
                allTrades={[...allTrades.values()]}
                onCancel={hideModal}
                showDialog={showEditModal}
                onClose={onClose}
                stabilityCriteriaToEdit={stabilityCriteriaToChange}
            />
        ) : undefined;
    }, [showEditModal, stabilityCriteriaToChange]);

    const createModal = useMemo(() => {
        return showCreateModal ? (
            <SettingsModal
                allTrades={[...allTrades.values()]}
                showDialog={showCreateModal}
                onCancel={() => {
                    setShowCreateModal(false);
                }}
                onClose={(newStabilityCriteria: StabilityCriteriaItem) => {
                    const extensionId = generateExtensionId();
                    const newExtension: LCMDStabilityCriteriaExtension = {
                        lcmdx: 1.0,
                        id: extensionId,
                        patch: {
                            prio: 0,
                            process: {
                                fields: {
                                    criteria: {
                                        label: newStabilityCriteria.name,
                                        "stability.lead": newStabilityCriteria.leadTimeValue,
                                        "stability.stable": ["done"],
                                        "stability.trades": (newStabilityCriteria.trades || []).map(
                                            (trade) => trade.id,
                                        ),
                                        width: 250,
                                        "ui.index": 0,
                                        dropdown: ["open", "done"],
                                    },
                                },
                            },
                        },
                    };
                    putExtensionHelper(LCMD, newExtension);
                    allItems.push(newStabilityCriteria);

                    if (!newStabilityCriteria.key) {
                        newStabilityCriteria.key = extensionId;
                        setItems([...allItems]);
                        setShowCreateModal(false);
                    }
                }}
            />
        ) : undefined;
    }, [showCreateModal]);

    const _onFilter = (text: string): void => {
        setFilterState({ ...filterState, searchText: text });
    };

    return (
        <div className={styles.root}>
            <div className={styles.settingsList}>
                <div className="mb-1 flex justify-between">
                    <SearchBar
                        className="w-100 relative"
                        value={filterState.searchText}
                        onSearch={(value) => _onFilter(value || "")}
                        onChange={(event, newValue) => _onFilter(newValue)}
                        placeholder={intl.get("StabilityCriteria.SettingsList.SearchFieldText")}
                    />
                    <Button
                        disabled={!isAllowed}
                        onClick={() => {
                            setShowCreateModal(true);
                            WebAppTelemetryFactory.trackEvent("settings-new-stability-criteria-clicked");
                        }}
                    >
                        <Plus size={16} className="mr-2" />
                        {intl.get("StabilityCriteria.SettingsList.AddStabilityCriteriaButtonText")}
                    </Button>
                    {createModal}
                </div>

                <DetailsList
                    items={items}
                    columns={columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selection={selection}
                    selectionPreservedOnEmptyClick={true}
                />
                {editModal}
            </div>
            {showStabilityCriteriaDeleteDialog && stabilityCriteriaToChange && (
                <StabilityCriteriaDeleteDialog
                    onCancel={() => {
                        setShowStabilityCriteriaDeleteDialog(false);
                    }}
                    onClose={() => {
                        deleteStabilityCriteria(stabilityCriteriaToChange.key);
                        allItems = allItems.filter((currentElement) => {
                            return stabilityCriteriaToChange.key != currentElement.key;
                        });
                        setItems([...allItems]);
                        getAllItemsFromApi();
                        setShowStabilityCriteriaDeleteDialog(false);
                        setStabilityCriteriaToChange(null);
                    }}
                    stabilityTitle={stabilityCriteriaToChange?.name}
                />
            )}
        </div>
    );
}
