import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import { WidgetHeader } from "../../layout/widget-header";

import { TitleText } from "../../common/title-text";
import { Title } from "../../common/title";
import { Actions } from "../common/actions";

function Header() {
    return (
        <WidgetHeader
            actions={<Actions />}
            title={
                <Title>
                    <TitleText text={intl.get("charts.statusTracking.labels.statusTracking")} />
                </Title>
            }
        />
    );
}

export { Header };
