import * as React from "react";
import { useCallback } from "react";

import { intl } from "@/legacy/GlobalHelperReact";

import { Stack, Text, Toggle } from "@fluentui/react";

export type DynamicScaleOptionProps = {
    checked: boolean;
    onChange: (value: boolean) => void;
};

function DynamicScaleOption(props: DynamicScaleOptionProps) {
    const { checked } = props;

    const onChange = useCallback((ev, checked: boolean) => props.onChange(checked), [props.onChange]);

    if (typeof checked !== "boolean") {
        throw new Error("DynamicScaleOption: checked param needs to be type of boolean");
    }

    return (
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <Text>{intl.get("charts.resource.dynamicScale")}</Text>
            <Toggle
                checked={checked}
                onChange={onChange}
                styles={{ root: { marginBottom: 0 } }} // need to reset indent
            />
        </Stack>
    );
}

export { DynamicScaleOption };
