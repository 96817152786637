import * as React from "react";

import { IconButton, IIconProps, IButtonProps } from "@fluentui/react";

import { classNames } from "./common";

export type ShareProps = IButtonProps & {};

const shareIconProps: IIconProps = {
    className: classNames.iconColor,
    iconName: "Share",
};

function Share(props: ShareProps) {
    return <IconButton iconProps={shareIconProps} {...props} />;
}

export { Share };
