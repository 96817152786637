import * as React from "react";
export function WarningIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={15} fill="none">
            <path
                fill="#D83B01"
                d="M7.686.851 1.127 12.776A1.5 1.5 0 0 0 2.442 15h13.117a1.5 1.5 0 0 0 1.314-2.223L10.315.851a1.5 1.5 0 0 0-2.629 0ZM9 4.75a.75.75 0 0 1 .75.75v4a.75.75 0 1 1-1.5 0v-4A.75.75 0 0 1 9 4.75Zm.75 7a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
            />
        </svg>
    );
}
