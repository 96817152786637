import * as React from "react";
import { Dialog, DialogScreen, intl, NonProgressPopup } from "lcmd2framework";
import { Link, Stack, mergeStyleSets, TextField, DefaultButton, DialogType } from "@fluentui/react";
import { ReactElement, useState } from "react";
import { useLCMD } from "../../app/LCMContext";
import { Button } from "../ui/button";
import { ArrowDown, Eye, PlusCircle, Save } from "lucide-react";
import { WhiteboardNameModal } from "./WhiteboardNameModal";
import { Separator } from "../ui/separator";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu";

const classNames = mergeStyleSets({
    main: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flexShrink: 1,
        padding: "0 20px",
    },
});

const showWelcomeStyle = mergeStyleSets({
    header: {
        height: 65,
        padding: "18px 22px",
        borderBottom: "1px solid #E1E4E5",
    },
    headerTitle: {
        fontWeight: 600,
        fontSize: 20,
        color: "#2C3032",
    },
    subHeader: {
        height: 65,
        padding: "18px 22px 0px 22px",
    },
    whiteboardList: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        padding: "18px 22px",
    },
});

export class Whiteboard {
    onRenderCanvas(
        this: Whiteboard,
        ctx: CanvasRenderingContext2D,
        state: {
            canvas: {
                width: number;
                height: number;
            };
            reactState: any;
            canvasState: any;
        },
    ) {}

    static createInstance() {
        return new Whiteboard();
    }

    static whiteboardItem({ wb }): ReactElement {
        const style = mergeStyleSets({
            teaser: {
                border: "1px solid #E1E4E5",
                borderRadius: 7,
                width: 332,
                margin: "0 20px 20px 0",
            },
            teaserImage: {
                width: 330,
            },
            teaserContent: {
                borderTop: "1px solid #E1E4E5",
                padding: "8px 16px",
            },
            teaserHeader: {
                fontWeight: 600,
                fontSize: 14,
            },
        });

        return (
            <div className={style.teaser}>
                <div className={style.teaserImage}>
                    <svg width="330" height="123" viewBox="0 0 330 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 7C0 3.13401 3.13401 0 7 0H325C328.866 0 330 3.13401 330 7V124H0V7Z"
                            fill="#F8FAFA"
                        />
                        <rect x="108" y="45" width="32" height="32" rx="2" fill="#E1E4E6" />
                        <path
                            d="M163.268 47C164.038 45.6667 165.962 45.6667 166.732 47L182.321 74C183.09 75.3333 182.128 77 180.588 77H149.412C147.872 77 146.91 75.3333 147.679 74L163.268 47Z"
                            fill="#E1E4E6"
                        />
                        <circle cx="207" cy="61" r="17" fill="#E1E4E6" />
                    </svg>
                </div>
                <div className={style.teaserContent}>
                    <div className={style.teaserHeader}>
                        {intl.get("whiteboard.list.item.title")} {wb.name || intl.get("whiteboard.list.item.unnamed")}
                    </div>
                </div>
            </div>
        );
    }

    static emptyWhiteboard(): ReactElement {
        const [openNameModal, setOpenNameModal] = useState(false);
        const onCancel = () => {
            setOpenNameModal(false);
        };
        const onOk = (name) => {
            setOpenNameModal(openNameModal);
            LCMD.createWhiteboard({
                open: true,
                name: {
                    value: name != "" ? name : undefined,
                },
            });
        };
        const LCMD = useLCMD();

        function onOpenWhiteboard(lcmdId) {
            if (lcmdId) {
                LCMD.openWhiteboard({ id: lcmdId });
            } else {
                setOpenNameModal(true);
            }
        }

        return (
            <>
                <div className="flex h-full min-h-[500px] flex-col items-center justify-center gap-3 text-center">
                    <img src="/img/no-whiteboard.svg" />
                    <h2 className="text-center text-3xl font-semibold leading-9 tracking-tight">
                        {intl.get("whiteboard.emptyWhiteboard.title")}
                    </h2>
                    <p
                        className="text-center text-xs font-medium leading-5"
                        dangerouslySetInnerHTML={{ __html: intl.get("whiteboard.emptyWhiteboard.text") }}
                    ></p>
                    <Button onClick={() => onOpenWhiteboard(null)} className="text-left text-sm font-medium leading-6">
                        {intl.get("whiteboard.emptyWhiteboard.newButton.label")}
                    </Button>
                </div>
                {openNameModal && <WhiteboardNameModal isOpen={openNameModal} onOk={onOk} onCancel={onCancel} />}
            </>
        );
    }

    static showWelcome(props: { whiteboards: any[] }) {
        const LCMD = useLCMD();
        const [pending, setPending] = React.useState(0 as number);
        const [openNameModal, setOpenNameModal] = useState(false);
        const onCancel = () => {
            setOpenNameModal(false);
        };
        const onOk = (name) => {
            setOpenNameModal(false);
            setPending(Date.now());
            LCMD.createWhiteboard({
                open: true,
                name: {
                    value: name != "" ? name : undefined,
                },
            });
        };

        const onOpenWhiteboard = React.useCallback(
            (lcmdId) => {
                if (lcmdId) {
                    setPending(Date.now());
                    LCMD.openWhiteboard({ id: lcmdId });
                } else {
                    setOpenNameModal(true);
                }
            },
            [LCMD, setPending, setOpenNameModal],
        );

        return (
            <div style={{ width: "100%" }}>
                {pending ? (
                    <NonProgressPopup startTime={pending} label={intl.get("fw.loading")} />
                ) : (
                    <>
                        {props.whiteboards.length ? (
                            <div>
                                {openNameModal && (
                                    <WhiteboardNameModal isOpen={openNameModal} onCancel={onCancel} onOk={onOk} />
                                )}
                                <Stack
                                    className={showWelcomeStyle.header}
                                    horizontal
                                    horizontalAlign={"space-between"}
                                    verticalAlign={"center"}
                                >
                                    <div>{intl.get("whiteboard.header.title")}</div>
                                    <div>
                                        <Button onClick={() => onOpenWhiteboard(null)} userflowId="wb-neues-wb-anlegen">
                                            <PlusCircle className="mr-2 h-4 w-4" />
                                            {intl.get("whiteboard.showWelcome.newButton.label")}
                                        </Button>
                                    </div>
                                </Stack>

                                <Stack
                                    className={showWelcomeStyle.subHeader}
                                    horizontal
                                    horizontalAlign={"space-between"}
                                    verticalAlign={"center"}
                                >
                                    {
                                        //todo: kann später rein
                                        /*
                                <SearchBox placeholder="Search" onSearch={newValue => console.log('value is ' + newValue)}/>
                                <Dropdown
                                options={[
                            {key: 'Last_viewed', text: 'Last viewed'},
                            {key: 'Last_viewed2', text: 'Last_viewed'}
                                ]}
                                />
                                 */
                                    }
                                </Stack>

                                <div className={showWelcomeStyle.whiteboardList}>
                                    {props.whiteboards.map((wb) => {
                                        return (
                                            <Link
                                                key={wb.id}
                                                data-lcmd-id={wb.id}
                                                onClick={() => {
                                                    onOpenWhiteboard(wb.id);
                                                }}
                                            >
                                                <Whiteboard.whiteboardItem wb={wb} />
                                            </Link>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <Whiteboard.emptyWhiteboard />
                        )}
                    </>
                )}
            </div>
        );
    }
}

function WhiteboardPropertiesMainScreen({
    dialog,
    route: {
        params: { id, name },
    },
}) {
    const LCMD = useLCMD();
    const [_name, setName] = React.useState(name);
    /*
    const libs=React.useMemo(()=>{
        const ret=LCMD.getActiveLibrariesSync();
        if (Array.isArray(ret) && ret.length>0) {
            return ret.map((item)=>({
                key: item.id,
                text: item.name
            }));
        } else {
            return null;
        }
    }, [LCMD]);
    */
    React.useLayoutEffect(() => {
        const onCancel = () => {
            LCMD.showDialog("dialog.whiteboard.properties", false);
        };
        dialog.setOptions({
            title: intl.get("whiteboard.properties.title"),
            onOK: undefined,
            onCancel,
            onClose: onCancel,
        });
    }, [LCMD]);
    React.useLayoutEffect(() => {
        const onOK = () => {
            LCMD.setWhiteboardState(id, {
                name: {
                    value: _name,
                },
            });
            LCMD.showDialog("dialog.whiteboard.properties", false);
        };
        dialog.setOptions({
            onOK: onOK,
        });
    }, [LCMD, id, _name]);
    return (
        <div className={classNames.main}>
            <TextField
                label={intl.get("whiteboard.properties.nameLabel")}
                placeholder={intl.get("whiteboard.properties.namePlaceholder")}
                value={_name || ""}
                onChange={(ev, value) => {
                    setName(value);
                }}
            />
            <DefaultButton
                text={intl.get("whiteboard.properties.deleteButton")}
                onClick={() => {
                    LCMD.showDialog("fw.alert", {
                        dialogContentProps: {
                            // see dialogContentProps from https://developer.microsoft.com/en-us/fluentui#/controls/web/dialog
                            type: DialogType.normal,
                            title: intl.get("whiteboard.properties.deleteConfirm.headline"),
                            subText: intl.get("whiteboard.properties.deleteConfirm.deleteText"),
                        },
                        onOK: () => {
                            LCMD.closeWhiteboard(id, { delete: true });
                            LCMD.showDialog("fw.alert", null);
                            LCMD.showDialog("dialog.whiteboard.properties", false);
                        },
                        onCancel: () => {
                            LCMD.showDialog("fw.alert", null);
                        },
                    });
                }}
            />
        </div>
    );
}

export function WhiteboardPropertiesDialog(props: {}) {
    return (
        <Dialog
            width={640}
            heigth={400}
            initialScreen={(dialog) => {
                dialog.pushScreen("main", props);
            }}
            isOpen={true}
        >
            <DialogScreen name="main" component={WhiteboardPropertiesMainScreen} />
        </Dialog>
    );
}

export function PreviewButton() {
    const LCMD = useLCMD();
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <div className="flex items-center rounded-md bg-zinc-950 text-secondary-foreground">
                    <Button className="flex h-8 items-center rounded-r-none px-3">
                        <Eye size={16} className="mr-2" />
                        {intl.get("canvas.previewButton.title")}
                    </Button>
                    <Separator orientation="vertical" decorative className="h-4 bg-zinc-700" />
                    <Button className="h-8 rounded-l-none px-2">
                        <ArrowDown size={16} />
                    </Button>
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="fixed -left-16 h-11 w-[196px]">
                <DropdownMenuGroup>
                    <DropdownMenuItem
                        className="flex cursor-pointer items-center gap-2 text-zinc-900"
                        onClick={() =>
                            LCMD.executeCommand({
                                cmd: "takting",
                                value: true,
                                preview: false,
                            })
                        }
                    >
                        <Save size={16} />
                        <p className="text-left text-sm font-medium leading-5">
                            {intl.get("canvas.previewButton.button_text")}
                        </p>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
