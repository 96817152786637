import * as React from "react";
import { ReactNode } from "react";

import { Stack, DefaultButton, mergeStyleSets, getTheme, FontWeights, TagItem } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";

const theme = getTheme();

const classNames = mergeStyleSets({
    filterComponentHeaderSpan: [
        theme.fonts.large,
        {
            fontWeight: FontWeights.semibold,
        },
    ],
    filterComponentHeaderDiv: {},
    filterComponentHeaderRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    filterComponentHeaderCol: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignContent: "start",
        rowGap: 5,
    },
});

export function FilterTag({ children, onRemoveItem, item }) {
    return (
        <TagItem
            item={item}
            index={0}
            styles={{
                root: {
                    padding: 5,
                },
            }}
            onRemoveItem={onRemoveItem}
        >
            {children}
        </TagItem>
    );
}

function TaktzoneTagItem({ item, onRemoveItem }) {
    return (
        <FilterTag item={item} onRemoveItem={onRemoveItem}>
            <span>{item.name}</span>
        </FilterTag>
    );
}

type FilterHeaderProps = {
    prefix: string;
    isModify: boolean;
    onClick: () => void;
    userflowId?: string;
};

export function FilterHeader({ prefix, isModify, onClick, userflowId }: FilterHeaderProps) {
    return (
        <div className={isModify ? classNames.filterComponentHeaderRow : classNames.filterComponentHeaderCol}>
            <div className={classNames.filterComponentHeaderDiv}>
                <span className={classNames.filterComponentHeaderSpan}>{intl.get([prefix, "label"].join("."))}</span>
            </div>
            <div>
                <DefaultButton
                    text={intl.get([prefix, isModify ? "modify.text" : "add.text"].join("."))}
                    onClick={onClick}
                    iconProps={{
                        iconName: isModify ? "Filter" : "Add",
                    }}
                    data-userflow-id={userflowId}
                />
            </div>
        </div>
    );
}

export type FilterItemsRowProps = {
    children: ReactNode;
};

export function FilterItemsRow(props: FilterItemsRowProps) {
    const { children } = props;

    return (
        <div
            style={{
                marginTop: 8,
                display: "flex",
                flexWrap: "wrap",
                flexGrow: 0,
            }}
        >
            {children}
        </div>
    );
}

function FilterComponent({ prefix, items, onClick, onRemoveItem, Item = TaktzoneTagItem, userflowId = null }) {
    return (
        <Stack.Item>
            <FilterHeader prefix={prefix} onClick={onClick} isModify={items.length > 0} userflowId={userflowId} />
            {items.length > 0 && (
                <FilterItemsRow>
                    {items.map((item) => (
                        <Item key={item.key} item={item} onRemoveItem={onRemoveItem.bind(null, item)} />
                    ))}
                </FilterItemsRow>
            )}
        </Stack.Item>
    );
}

export { FilterComponent };
