import { DigitalPlanningBoardSelectionEvent, intl } from "lcmd2framework";
import { Comment } from "./Comment";
import { MentionsComponent } from "@/components/common/MentionsComponent";
import { useContext, useState } from "react";
import { SidebarContext } from "@/app/components/SidebarHost";
import { useLCMD } from "@/app/LCMContext";
import { Notification, NotificationTarget } from "@/core/Notification/NotificationService";

export const CommentsView = () => {
    const LCMD = useLCMD();
    const propsContext = useContext(SidebarContext);

    const [commentDisabled, setCommentDisabled] = useState(true);
    const [comments, setComments] = useState([]);
    const [selectedProcesses, setSelectedProcesses] = useState<DigitalPlanningBoardSelectionEvent>(null);

    propsContext.sidebarCtx.useSelectionEffect(
        (ev: DigitalPlanningBoardSelectionEvent) => {
            const currentValues = selectedProcesses ? [...selectedProcesses.pid] : [];
            const newValues = [...ev.pid];
            if (currentValues.sort().toString() !== newValues.sort().toString()) {
                setSelectedProcesses(ev);
            }
            setCommentDisabled(ev?.pid?.length !== 1);
        },
        [setSelectedProcesses, setCommentDisabled, commentDisabled, selectedProcesses],
    );

    LCMD.useProcessHistoryEffect(
        selectedProcesses?.pid,
        (error, data) => {
            if (error) {
                console.log("Error loading process history", error);
            }
            const comments = data.filter((el) => el.target === "comment") || [];
            const sortedComments = comments.sort((a, b) => {
                const dateA = a.events[0].date;
                const dateB = b.events[0].date;
                return dateB - dateA;
            });
            setComments(sortedComments);
        },
        [setComments],
        propsContext.ppTS,
    );

    async function createNotification(
        comment: string,
    ): Promise<{ fromUserId: string; users: string[]; notification: Notification }> {
        const getProcessNamePromise: Promise<string> = new Promise((resolve, reject) => {
            LCMD.getProcessDetails(selectedProcesses.pid[0], (error, data) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(data?.name?.value);
                }
            });
        });

        const getProjectPromise = (): Promise<{ name: string; id: string }> => {
            return new Promise((resolve, reject) => {
                LCMD.getProjectDetails((error, data) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve({
                            name: data.meta?.name,
                            id: data.pid,
                        });
                    }
                });
            });
        };

        const authUserIdPromise: Promise<string> = new Promise((resolve, reject) => {
            LCMD.getAuthUser((error, user) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(user.sub);
                }
            });
        });

        const fromUserId = await authUserIdPromise;
        const users = extractIdsFromCommment(comment);
        const projectData = await getProjectPromise();
        const projectId = projectData.id;

        const notification: Notification = {
            type: "info",
            target: [NotificationTarget.Email],
            meta: {
                processid: selectedProcesses.pid[0].toString(),
                processname: await getProcessNamePromise,
                projectname: projectData.name,
                confirmationUrl: projectId,
            },
        };
        return {
            fromUserId,
            users,
            notification,
        };
    }

    function extractIdsFromCommment(text: string) {
        const regex = /\$\{"([^"]+)"\}/g;
        const ids = [];
        let match;

        while ((match = regex.exec(text)) !== null) {
            ids.push(match[1]);
        }

        return ids;
    }

    async function handleSendComment(event: any, newValue: string) {
        if (newValue && Array.isArray(selectedProcesses?.pid) && 1 === selectedProcesses.pid.length) {
            setCommentDisabled(true);
            const notification = await createNotification(newValue);
            LCMD.setProcessComment(selectedProcesses.pid[0], newValue, notification);
        }
    }

    return (
        <div className="h-full overflow-y-hidden">
            <div
                className="flex flex-col"
                style={{
                    height: "calc(100% - 94px)",
                }}
            >
                <div className="border-b border-solid border-gray-300">
                    <div className="px-5 pb-2 pt-0">
                        <p className="text-lg font-semibold not-italic leading-6 tracking-tight">
                            {intl.get("CommentsView.ActionsLabelText")}
                        </p>
                    </div>
                </div>
                <div
                    className=" bg-slate-100 p-4"
                    style={{
                        height: "calc(100% - 33px)",
                    }}
                >
                    {!!comments?.length && (
                        <div className="flex h-auto max-h-full flex-col flex-col-reverse gap-2 overflow-y-auto rounded-2xl bg-white p-3">
                            {comments.map((comment) => (
                                <Comment key={comment.events[0].prop} comment={comment.events[0]} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="min-h-10 w-full rounded-b-lg border-t border-solid border-gray-300 bg-slate-100 px-4 py-3">
                <MentionsComponent
                    handleSendComment={handleSendComment}
                    mentionStyles={{ backgroundColor: "#ede9fe" }}
                    styles={{
                        "&multiLine": {
                            input: {
                                padding: 9,
                                overflow: "auto",
                                height: 70,
                            },
                            highlighter: {
                                padding: 9,
                                boxSizing: "border-box",
                                overflow: "hidden",
                                height: 70,
                            },
                        },
                        background: "white",
                    }}
                />
            </div>
        </div>
    );
};
