import * as React from "react";

import { Stack, Icon, IStackTokens, Text } from "@fluentui/react";

export type TradeLabelProps = {
    name: string;
    color: string;
}

const ICON_NAME = "PageSolid";

const tokensProps: IStackTokens = {
    childrenGap: 5,
};

function TradeLabel(props: TradeLabelProps) {
    const { name, color } = props;

    const iconStyle = {
        color,
    };

    const style = {
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        wordBreak: "break-word",
        width: "100%",
    };

    return (
        <Stack horizontal tokens={tokensProps}>
            <Icon iconName={ICON_NAME} style={iconStyle} data-testid="iconElement" />
            <Text styles={{ root: { style } }}>{name}</Text>
        </Stack>
    );
}

export { TradeLabel };
