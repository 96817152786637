import * as React from "react";

import { getCurrentLocale } from "../../../utils/date/locale";

import { WidgetContent } from "../../layout/widget-content";
import { ChartJSFactory } from "../../common/chartjs";
import { Chart, intl } from "lcmd2framework";
import { DashboardCtxPropI } from "../..";

const dataView = {
    name: "dashboard.actual",
};

function onRenderChartData(ctx: any, chart: any, rawData: any) {
    const timezoneOffset = new Date().getTimezoneOffset();
    const grid = rawData?.grid ? rawData.grid.createEpochArray(timezoneOffset) : [];
    const actual = rawData?.actual || [];
    const baseline = rawData?.baseline || [];
    const datasets = [
        {
            data: actual,
            label: intl.get("charts.actualPlanningVsBaseline.labels.actual"),
            pointColor: "#009BD4",
            backgroundColor: "#009BD4",
            fill: false,
        },
    ];
    if (baseline.length > 0) {
        datasets.push({
            data: baseline,
            label: intl.get("charts.actualPlanningVsBaseline.labels.baseline"),
            pointColor: "#999EA1",
            backgroundColor: "#999EA1",
            fill: false,
        });
    }
    return {
        labels: grid,
        datasets: datasets,
    };
}

function Content(props: DashboardCtxPropI) {
    const ActualBaselineChartFactory = React.useMemo(
        () =>
            ChartJSFactory.bind(null, {
                onRenderChartData,
                onOutdatedChartData: props.dashboardCtx.onOutdatedChartData,
                options: {
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                displayFormats: {
                                    quarter: "MMM YYYY",
                                },
                            },
                            adapters: {
                                date: {
                                    locale: getCurrentLocale(),
                                },
                            },
                        },
                        y: {
                            ticks: {
                                min: 0,
                                max: 100,
                                callback: function (value) {
                                    return Math.round(value * 100) + "%";
                                },
                            },
                        },
                    },
                },
            }),
        [props.dashboardCtx],
    );
    return (
        <WidgetContent height={400}>
            <Chart dataView={dataView} factory={ActualBaselineChartFactory} />
        </WidgetContent>
    );
}

export { Content };
