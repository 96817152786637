// @ts-nocheck
import { ReactIntlUniversal } from "react-intl-universal";
export const intl = new ReactIntlUniversal();

export function errorToMessage(error: Error) {
    if ("string" === typeof error) {
        return error;
    } else if ("FrameworkError" === error.name) {
        return intl.get(error.message, (error as any)?.details);
    } else if ("FrameworkHttpError" === error.name) {
        return intl.get(error.message);
    } else {
        return error.message;
    }
}

export function localizeIfNeeded(s: string, fallback?: string) {
    const _s = s || fallback;
    if ((_s || "").startsWith("lcmd.")) {
        if ("lcmd.lic.reg" === _s || "lcmd.lic.mob" === _s) {
            const _s_type = [_s, "type"].join(".");
            s = intl.get(_s_type) || _s_type;
        } else {
            s = intl.get(_s) || s;
        }
    }
    return s;
}

export function CARD_STATUS_TEXT(intl) {
    return ["0", "1", "2", "3"].map((e) => intl.get(["lcmd", "card", "status", e].join(".")));
}
