import { LabelToggleType } from "@/legacy/components/Canvas/CanvasHostContainer";
import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs";
import { intl } from "@/legacy/GlobalHelperReact";

type LabelToggle = {
    value: LabelToggleType;
    onChange: () => void;
};

export function LabelToggle({ value, onChange }: LabelToggle) {
    return (
        <Tabs value={value} defaultValue="workshop" onValueChange={onChange}>
            <TabsList>
                <TabsTrigger value="workshop">{intl.get("whiteboard.toggle.workshop")}</TabsTrigger>
                <TabsTrigger value="plan">{intl.get("whiteboard.toggle.plan")}</TabsTrigger>
            </TabsList>
        </Tabs>
    );
}
