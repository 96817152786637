import {
    DefaultButton,
    Dialog,
    DialogContent,
    DialogFooter,
    DialogType,
    IDialogContentProps,
    mergeStyleSets,
    PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import { useMemo } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
const classNames = mergeStyleSets({
    modal: {
        width: "800px",
        height: "600px",
    },
});

export function StabilityCriteriaDeleteDialog({
    stabilityTitle,
    onClose,
    onCancel,
}: {
    stabilityTitle: string;
    onCancel: () => void;
    onClose: () => void;
}) {
    const dialogContentProps: IDialogContentProps = useMemo<IDialogContentProps>(() => {
        return {
            type: DialogType.close,
            isMultiline: true,
            titleProps: {
                color: "#565C60",
            },
            styles: {
                header: {
                    borderBottom: "1px solid #E1E4E5",
                },
            },
        };
    }, [stabilityTitle]);
    return (
        // @ts-ignore
        <Dialog
            onDismiss={onCancel}
            isOpen={true}
            title={intl.get("StabilityCriteria.DeleteDialog.DeleteTitle")}
            dialogContentProps={dialogContentProps}
        >
            <DialogContent>
                <div>{intl.getHTML("StabilityCriteria.DeleteDialog.DeleteText", { name: stabilityTitle })}</div>
            </DialogContent>
            <DialogFooter>
                <DefaultButton onClick={onCancel} styles={{ root: { borderRadius: "4px" } }}>
                    {intl.get("StabilityCriteria.DeleteDialog.CancelButtonText")}
                </DefaultButton>
                <PrimaryButton styles={{ root: { borderRadius: "4px", backgroundColor: "#A4262C" } }} onClick={onClose}>
                    {intl.get("StabilityCriteria.DeleteDialog.DeleteButtonText")}
                </PrimaryButton>
            </DialogFooter>
        </Dialog>
    );
}
