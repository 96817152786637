import React, { Fragment, useEffect, useRef, useState } from "react";
import {
    Stack,
    StackItem,
    TextField,
    ITextFieldStyleProps,
    ITextFieldStyles,
    IStyleFunctionOrObject,
    IIconProps,
    ITextField,
    ITextFieldProps,
    PrimaryButton,
    DefaultButton,
    IconButton,
} from "@fluentui/react";
import { Icons } from "../../RegisteredIcons";
import { intl } from "@/legacy/GlobalHelperReact";

/*

    todo: rebuild editState with useEffect

 */

type EditNameField = ITextFieldProps & {
    maxLength?: number;
};

export function EditNameField(props: EditNameField) {
    const initialTextStyles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = {
        subComponentStyles: {
            label: {
                root: {
                    fontFamily: "Inter",
                    fontSize: 14,
                },
            },
        },
        field: {
            cursor: "default",
            fontSize: 18,
            fontWeight: 600,
            width: "280px",
            height: "30px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        fieldGroup: {},
        icon: { pointerEvents: "initial" },
    };
    const [styling, setStyling] = useState(initialTextStyles);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [suffix, setSuffix] = useState<{ suffix: string } | { iconProps: IIconProps }>(null);
    const [currentValue, setCurrentValue] = useState("");
    const textRef = useRef<ITextField>();

    useEffect(() => {
        setCurrentValue(props.value);
        setEditMode(false);
        setSuffix(null);
    }, [props.value]);

    useEffect(() => {
        if (!editMode) {
            setStyling({
                subComponentStyles: {
                    label: {
                        root: {
                            fontFamily: "Inter",
                            fontSize: 14,
                        },
                    },
                },
            });
            setSuffix(null);
        } else {
            props.maxLength && setSuffix({ suffix: props.value?.length + "/" + props.maxLength });
            textRef.current.focus();
            setStyling(initialTextStyles);
        }
    }, [editMode]);

    const validation = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): boolean => {
        return !props.maxLength || newValue.length <= props.maxLength;
    };

    const handleProcessDetailNameChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ) => {
        if (!validation(event, newValue)) {
            return;
        }
        props.maxLength && setSuffix({ suffix: newValue.length + "/" + props.maxLength });
        setCurrentValue(newValue);
    };

    function handleEditModeChange() {
        setCurrentValue(props.value);
        setEditMode((oldValue) => {
            return !oldValue;
        });
    }

    function handleProcessNameSave() {
        setEditMode(false);
        props.onChange && props.onChange(null, currentValue);
    }

    const textFieldOutput = (
        <Fragment>
            <TextField
                {...props}
                {...suffix}
                value={currentValue}
                componentRef={textRef}
                styles={styling}
                borderless={!editMode}
                readOnly={!editMode}
                title={currentValue}
                onClick={() => {
                    if (!editMode) {
                        handleEditModeChange();
                    }
                }}
                onChange={handleProcessDetailNameChange}
            />
        </Fragment>
    );

    return (
        <Stack tokens={{ childrenGap: "10px" }}>
            {!editMode && (
                <Stack horizontal horizontalAlign={"space-between"}>
                    <StackItem styles={{ root: { width: "90%", fontFamily: "Inter" } }}>{textFieldOutput}</StackItem>
                    <StackItem>
                        <IconButton
                            iconProps={{
                                iconName: Icons.Lcmd_Edit_Filled,
                                onClick: handleEditModeChange,
                                styles: { root: { cursor: "pointer", color: "#565C60" } },
                            }}
                        />
                    </StackItem>
                </Stack>
            )}
            {editMode && (
                <Fragment>
                    {textFieldOutput}
                    <Stack
                        horizontal
                        horizontalAlign={"space-between"}
                        verticalAlign={"center"}
                        tokens={{ childrenGap: "15px" }}
                    >
                        <DefaultButton
                            iconProps={{ iconName: "Cancel" }}
                            styles={{ flexContainer: { flexDirection: "row-reverse" } }}
                            onClick={handleEditModeChange}
                        >
                            {intl.get("EditNameField.cancelButton.text")}
                        </DefaultButton>
                        <PrimaryButton
                            iconProps={{ iconName: "Save" }}
                            styles={{ flexContainer: { flexDirection: "row-reverse" } }}
                            onClick={handleProcessNameSave}
                        >
                            {intl.get("EditNameField.applyButton.text")}
                        </PrimaryButton>
                    </Stack>
                </Fragment>
            )}
        </Stack>
    );
}
