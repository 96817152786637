import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import {
    Modal,
    FontWeights,
    IconButton,
    IIconProps,
    mergeStyleSets,
    PrimaryButton,
    Stack,
    getTheme,
    CommandBar,
    ICommandBarItemProps,
} from "@fluentui/react";
import ProcessView from "./ProcessView";
import { useLCMD } from "../app/LCMContext";

const theme = getTheme();

//initializeIcons(/* optional base url */);
const classNames = mergeStyleSets({
    container: {
        width: "640px",
        height: "600px",
        display: "flex",
        flexDirection: "column",
    },
    header: [
        theme.fonts.large,
        {
            flex: "0 0 auto",
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px",
        },
    ],
    body: {
        flex: "4 4 auto",
        overflowY: "hidden",
        overflow: "overlay",
        display: "flex",
    },
    footer: {
        flex: "0 0 auto",
        padding: "12px 12px 14px 24px",
    },
    rel: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    cmdBar: {
        marginLeft: "auto",
        marginRight: 2,
    },
});

const cancelIcon: IIconProps = { iconName: "Cancel" };
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "2px",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const commandBarStyles = {
    root: {
        marginLeft: "2px",
        marginRight: "2px",
        right: "2px",
    },
};

export function ProcessViewDialog(props: { readonly: boolean; isOpen: boolean; onClose: () => void }) {
    const LCMD = useLCMD();
    const [canUndo, setCanUndo] = React.useState(LCMD.canUndo);
    const [canRedo, setCanRedo] = React.useState(LCMD.canRedo);
    const commandBarItems: ICommandBarItemProps[] = React.useMemo(
        () => [
            {
                key: "undo",
                title: intl.get("canvas.cmd.undo.title"),
                iconProps: { iconName: "undo" },
                disabled: !canUndo || props.readonly,
                onClick: () => {
                    LCMD.undo();
                    setCanUndo(LCMD.canUndo);
                    setCanRedo(LCMD.canRedo);
                },
            },
            {
                key: "redo",
                title: intl.get("canvas.cmd.redo.title"),
                iconProps: { iconName: "redo" },
                disabled: !canRedo || props.readonly,
                onClick: () => {
                    LCMD.redo();
                    setCanUndo(LCMD.canUndo);
                    setCanRedo(LCMD.canRedo);
                },
            },
        ],
        [LCMD, canUndo, setCanUndo, canRedo, setCanRedo, props.readonly],
    );
    //                <DefaultButton text="Cancel" onClick={props.onClose}/>
    return (
        <Modal isOpen={props.isOpen} isBlocking={true} onDismiss={props.onClose}>
            <div className={classNames.container}>
                <div className={classNames.header}>
                    <span>{intl.get("legacyTaktZones.title")}</span>
                    <CommandBar className={classNames.cmdBar} /*styles={commandBarStyles}*/ items={commandBarItems} />
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={props.onClose}
                    />
                </div>
                <div className={classNames.body}>
                    <ProcessView className={classNames.rel} />
                </div>
                <Stack
                    className={classNames.footer}
                    horizontal
                    tokens={{
                        childrenGap: 10,
                    }}
                    horizontalAlign="end"
                >
                    <PrimaryButton
                        disabled={false}
                        text={intl.get("legacyTaktZones.ok")}
                        onClick={props.onClose}
                        data-userpilot-id="processViewDialog-okButton"
                    />
                </Stack>
            </div>
        </Modal>
    );
}
