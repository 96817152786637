import { IconButton, SpinButton, Stack, IIconProps, mergeStyleSets } from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";

type CounterProps = {
    onChange?: (num: number) => void;
    min?: number;
    value?: number;
    label?: string;
    step?: number;
    unit?: string;
};

const addIcon: IIconProps = { iconName: "Add" };
const subtractIcon: IIconProps = { iconName: "ChromeMinimize" };
const arrowButtonStyles = { root: { display: "none" } };

export function Counter({ onChange, min = 0, value = 0, label = "", step = 1, unit = "" }: CounterProps) {
    const [num, setNum] = useState<number>(value);
    const labelValue = unit && unit != "" ? `${label} (${unit})` : label;

    const styles = mergeStyleSets({
        content: {
            padding: "0 32px",
        },
        container: {
            minWidth: 600,
            minHeight: 800,
        },
        leadTimeContent: {
            display: "flex",
            alignItems: "end",
            position: "relative",
        },
        leadTimeIcons: {
            border: "1px solid #323130",
            borderRadius: "30px",
            color: "#323130",
        },
        leadTimeInput: {
            width: 10,
            margin: "0 5px 0 5px",
            minWidth: "60px",
        },
    });
    useEffect(() => {
        if (num < min) {
            setNum(min);
        } else {
            setNum(value);
        }
    }, [value]);

    const validate = (value: string, event?: React.SyntheticEvent<HTMLElement>) => {
        if (isNaN(+value) || +value < min) {
            return;
        }
        {
            return value;
        }
    };

    const changeNum = (newValue: number = 0) => {
        setNum(+newValue);
        if (onChange) {
            onChange(+newValue);
        }
    };
    const increment = () => {
        const validation = validate((num + step).toString());
        if (validation) {
            changeNum(num + step);
        }
    };
    const decrement = () => {
        const validation = validate((num - step).toString());
        if (validation) {
            changeNum(num - step);
        }
    };

    if (typeof value !== "number") {
        throw new Error("value param needs to be of type number");
    }

    if (typeof min !== "number") {
        throw new Error("min param needs to be of type number");
    }

    if (typeof label !== "string") {
        throw new Error("label param needs to be of type string");
    }

    if (typeof step !== "number") {
        throw new Error("step param needs to be of type number");
    }

    if (typeof unit !== "string") {
        throw new Error("unit param needs to be of type string");
    }

    return (
        <Stack>
            <div className={styles.leadTimeContent}>
                <IconButton className={styles.leadTimeIcons} iconProps={subtractIcon} onClick={decrement} />
                <SpinButton
                    downArrowButtonStyles={arrowButtonStyles}
                    upArrowButtonStyles={arrowButtonStyles}
                    className={styles.leadTimeInput}
                    value={num.toString()}
                    onChange={(e, n) => {
                        changeNum(+n);
                    }}
                    min={min}
                    onValidate={validate}
                    label={labelValue}
                    styles={{
                        label: { position: "absolute", left: 0, width: 200 },
                        spinButtonWrapper: { width: 60, minWidth: 50 },
                        input: { textAlign: "center" },
                    }}
                />
                <IconButton className={styles.leadTimeIcons} iconProps={addIcon} onClick={increment} />
            </div>
        </Stack>
    );
}
