import * as React from "react";

import { useLCMD } from "../../app/LCMContext";
import { StackedDialog } from "../../legacy/components/StackedDialog/StackedDialog";
import { CardDialogView } from "./CardDialogView";

type CardDialogProps = {
    card: any;
    onClose: () => void;
    onChangeTrade?;
};

export function CardDialog({ card, onChangeTrade, onClose }: CardDialogProps) {
    const LCMD = useLCMD();
    return card ? (
        <StackedDialog
            key={["C", card.tid.toString(16), card.aid.toString(16), card.i.toString(16)].join("_")}
            isOpen={true}
            onClose={onClose}
            width={920}
            height={LCMD.worker.nav?.view === "workshop" ? 500 : 680}
            onInitialView={(dialogCtx) => {
                dialogCtx.pushView(
                    <CardDialogView
                        dialogCtx={dialogCtx}
                        onCancel={onClose}
                        initialCard={card}
                        onChangeTrade={onChangeTrade}
                    />,
                );
            }}
        />
    ) : null;
}
