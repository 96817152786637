import { useRef, useState } from "react";

export const useFileUploadState = (initialState) => {
    const [view, setView] = useState(initialState.view);
    const [data, setData] = useState(initialState.data);
    const [timer, setTimer] = useState(0);
    const [timer0, setTimer0] = useState(0);
    const onTimerRef = useRef(null);

    const ensureTimer = (ensure) => {
        if (ensure) {
            if (onTimerRef.current === null) {
                const now = new Date().getTime();
                setTimer0(now);
                setTimer(now);
                onTimerRef.current = setInterval(() => {
                    const now = new Date().getTime();
                    setTimer(now);
                }, 1000);
            }
        } else {
            if (onTimerRef.current !== null) {
                clearInterval(onTimerRef.current);
                onTimerRef.current = null;
                setTimer0(0);
                setTimer(0);
            }
        }
    };

    return {
        view,
        data,
        timer,
        timer0,
        setView,
        setData,
        ensureTimer,
    };
};
