import React from "react";

export function ClockIcon() {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.37315 0.024577C11.7778 -0.322077 15.6295 2.96758 15.9762 7.37224C16.3228 11.7769 13.0332 15.6286 8.6285 15.9753C4.22384 16.3219 0.372139 13.0322 0.0254847 8.62759C0.00317567 8.34413 -0.00309798 8.03956 0.00592822 7.73844C0.0183387 7.32441 0.364035 6.99883 0.778063 7.01124C1.19209 7.02365 1.51767 7.36935 1.50525 7.78338C1.4978 8.03197 1.50296 8.2825 1.52086 8.5099C1.80252 12.0887 4.93202 14.7615 8.51081 14.4799C12.0896 14.1982 14.7624 11.0687 14.4808 7.48993C14.1991 3.91115 11.0696 1.2383 7.49084 1.51995C5.75457 1.6566 4.18511 2.47041 3.0785 3.75463L2.87495 4.00251L5.25375 4.00338L5.35552 4.01023C5.7216 4.05989 6.00375 4.37368 6.00375 4.75338C6.00375 5.13308 5.7216 5.44687 5.35552 5.49653L5.25375 5.50338H1.25381L1.15204 5.49653C0.819246 5.45139 0.555807 5.18795 0.510659 4.85515L0.503812 4.75338V0.75338L0.510659 0.65161C0.555807 0.318814 0.819246 0.0553747 1.15204 0.010227L1.25381 0.00338036L1.35558 0.010227C1.68838 0.0553747 1.95182 0.318814 1.99697 0.65161L2.00381 0.75338L2.00261 2.70613C3.36051 1.16623 5.26851 0.190215 7.37315 0.024577ZM7.75082 4.00338L7.85259 4.01023C8.18539 4.05537 8.44883 4.31881 8.49398 4.65161L8.50082 4.75338V7.99892L10.2508 7.99992L10.3526 8.00676C10.7187 8.05643 11.0008 8.37022 11.0008 8.74992C11.0008 9.12961 10.7187 9.44341 10.3526 9.49307L10.2508 9.49992H7.75082L7.64905 9.49307C7.31626 9.44792 7.05282 9.18448 7.00767 8.85169L7.00082 8.74992V4.75338L7.00767 4.65161C7.05282 4.31881 7.31626 4.05537 7.64905 4.01023L7.75082 4.00338Z"
                fill="#565C60"
            />
        </svg>
    );
}
