import { useEffect, useState } from "react";
import { useParticleContext } from "../../legacy/api/ParticleContext";
import { User, UserMap } from "@/components/hooks/UserMapTypes";

export function useUserMap(): UserMap {
    const LCMD = useParticleContext();
    const [user, setUser] = useState<UserMap>({});
    LCMD.useProjectCollaboratorsEffect(
        null,
        (error, data, project) => {
            if (error) {
                console.error(error);
            }
            const tmpUsers: UserMap = {};
            data.forEach((d) => {
                const userId = d?.data?.sub;
                tmpUsers[userId] = {
                    email: d.personaName,
                    meta: {
                        firstName: d.data?.meta?.firstName,
                        lastName: d.data?.meta?.lastName,
                    },
                    role: project.project.shared[userId].role,
                };
            });
            setUser(tmpUsers);
        },
        [],
    );
    return user;
}
export function useUser(userId: string): User {
    const LCMD = useParticleContext();
    const [user, setUser] = useState<User>(null);
    useEffect(() => {
        LCMD.getUser(userId, (result) => {
            setUser(result.email === "?" ? null : result);
        });
    }, [LCMD, userId]);
    return user;
}
