import * as React from "react";
import { LcmFacepile } from "@/components/common/LcmFacepile";
import { HistoryChangeEvent } from "@/app/interface";
import { format } from "date-fns";
import { useUser } from "@/components/hooks/userMap.hook";

type CommentProps = {
    comment: HistoryChangeEvent;
};

export const Comment = ({ comment }: CommentProps) => {
    const date: string = format(new Date(comment.date), "dd.MM.yyyy HH:mm");
    const author = useUser(comment.userId);

    const formatCommentWithMentions = (comment) => {
        const parts = comment.split(/(@\{"[^}]+\"\})/g);

        return parts.map((part, index) => {
            const mentionMatch = part && part.match(/@\{"([^}]+)\"\}/);
            if (mentionMatch) {
                return (
                    <span
                        key={index}
                        className="text-nowrap rounded-full bg-[#ede9fe] px-1 py-0.5 text-xs font-medium leading-5 text-black"
                    >
                        @{mentionMatch[1]}
                    </span>
                );
            }

            return part.split("<br>").map((line, i) => (
                <span key={i}>
                    {i > 0 && <br />}
                    {line}
                </span>
            ));
        });
    };

    return (
        <div className="flex gap-3">
            {author && <LcmFacepile persona={[author]} />}
            <div className="flex flex-col justify-center gap-1">
                <div className="flex items-center gap-2">
                    <p className=" text-sm font-semibold leading-5 text-black">
                        {author?.meta.firstName} {author?.meta.lastName}
                    </p>
                    <p className="text-xs font-normal leading-5 text-slate-400">{date}</p>
                </div>
                <p className="text-sm font-normal leading-5 text-black">{formatCommentWithMentions(comment.value)}</p>
            </div>
        </div>
    );
};
