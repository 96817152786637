import { create } from "zustand";
import { CoreEventNames, CoreEventsCallbackData } from "../types/CoreEvents";
import { useConflictStore } from "@/app/store/conflictsStore";
import { useUserJourneyStore } from "@/app/store/userJourneyStore";

export type CanvasStore = CanvasState & CanvasActions;

type CanvasState = {
    canvasEvent: CanvasEvent;
    selectedProcessIds: number[];
    dependencyChain: number[];
    showConflictMode: boolean;
    showDependencies: boolean;
    depsSettingsBeforeConflict: boolean;
    processPlanTimestamp: number;
    whiteboardTimestamp: number;
    isFocusMode: boolean;
    initiallyRendered: boolean;
    processId: number | undefined;
    initialSidebarView: string | undefined;
    isPresentingMode: boolean;
};

type CanvasActions = {
    setCanvasEvent: (canvasEvent: object) => void;
    setSelectedProcessIds: (selectedProcessId: number[]) => void;
    setDependencyChain: (chain: number[]) => void;
    setShowConflictMode: (showConflictMode: boolean) => void;
    setShowDependencies: (show: boolean) => void;
    setProcessPlanTimestamp: (timestamp: number) => void;
    setWhiteboardTimestamp: (timestamp: number) => void;
    reset: () => void;
    setFocusMode: (isEnabled: boolean) => void;
    setInitiallyRendered: () => void;
    setProcessId: (processId: number) => void;
    setInitialSidebarView: (menuKey: string) => void;
    setPresentingMode: (presentingMode: boolean) => void;
};

type CanvasEvent = {
    eventData: CoreEventsCallbackData;
    coreEventName: CoreEventNames;
};

type EventData = {
    currentOp: {
        id: number;
    };
    opId: number;
    value: object;
};

const initialState: CanvasState = {
    canvasEvent: {
        eventData: [],
        coreEventName: undefined,
    },
    dependencyChain: [],
    showConflictMode: false,
    selectedProcessIds: [],
    showDependencies: useUserJourneyStore.getState().showDependencies,
    depsSettingsBeforeConflict: false,
    processPlanTimestamp: -1,
    whiteboardTimestamp: -1,
    isFocusMode: false,
    initiallyRendered: false,
    processId: null,
    initialSidebarView: null,
    isPresentingMode: false,
};

export const useCanvasStore = create<CanvasStore>((set, getState) => ({
    ...initialState,
    setCanvasEvent: (canvasEvent: CanvasEvent) => set({ canvasEvent }),
    setShowConflictMode: (showConflictMode: boolean) => {
        if (getState().selectedProcessIds.length === 0) {
            useConflictStore.getState().actions.setConflictCount(0);
            set({ showConflictMode });
        } else {
            set({ showConflictMode });
        }
        if (showConflictMode === true) {
            set({ showDependencies: showConflictMode });
        } else {
            set((state) => {
                return {
                    showDependencies: state.depsSettingsBeforeConflict,
                };
            });
        }
    },
    setDependencyChain: (chain) => set({ dependencyChain: chain }),
    setSelectedProcessIds: (selectedProcessIds: number[] = []) => set({ selectedProcessIds }),
    setShowDependencies: (show) => {
        set({ showDependencies: show, depsSettingsBeforeConflict: show });
        useUserJourneyStore.getState().actions.setShowDependencies(show);
        // localStorage.setItem("showDependencies", String(show));
    },
    setProcessPlanTimestamp: (timestamp) => {
        set({ processPlanTimestamp: timestamp });
    },
    setWhiteboardTimestamp: (timestamp) => {
        set({ whiteboardTimestamp: timestamp });
    },
    setFocusMode: (isEnabled) => set({ isFocusMode: isEnabled }),
    setInitiallyRendered: () => set({ initiallyRendered: true }),
    setProcessId: (processId) => {
        set({ processId: processId });
    },
    setInitialSidebarView: (menuKey: string) => set({ initialSidebarView: menuKey }),
    setPresentingMode: (presentingMode: boolean) => set({ isPresentingMode: presentingMode }),
    reset: () => {
        set({ ...initialState, showDependencies: useUserJourneyStore.getState().showDependencies });
    },
}));

function useSelectedProcessIds() {
    return useCanvasStore((state) => state.selectedProcessIds);
}

function useCanvasEvent() {
    return useCanvasStore((state) => state.canvasEvent);
}

function useSetCanvasEvent() {
    return useCanvasStore((state) => state.setCanvasEvent);
}

function useSetSelectedProcessIds() {
    return useCanvasStore((state) => state.setSelectedProcessIds);
}

export function useConflictMode() {
    return useCanvasStore((state) => state.showConflictMode);
}

export function useDependencyChain() {
    return useCanvasStore((state) => state.dependencyChain);
}

export function useProcessPlanTimestamp() {
    return useCanvasStore((state) => state.processPlanTimestamp);
}

export function useWhiteboardTimestamp() {
    return useCanvasStore((state) => state.whiteboardTimestamp);
}

export function useCanvasIsInitiallyRendered() {
    return useCanvasStore((state) => state.initiallyRendered);
}

export function useInitialSidebarView() {
    return useCanvasStore((state) => state.initialSidebarView);
}

export function usePresentingMode() {
    return useCanvasStore((state) => state.isPresentingMode);
}

export function useSetPresentingMode() {
    return useCanvasStore((state) => state.setPresentingMode);
}

export { useCanvasEvent, useSetCanvasEvent, useSelectedProcessIds, useSetSelectedProcessIds };
