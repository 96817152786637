import * as React from "react";
import { useRef } from "react";

import { mergeStyleSets } from "@fluentui/react";

import { ResourceChartsCanvasState, ResourceChartsCanvasProps } from "./types";

import { ColumnOverview } from "./ColumnOverview";
import { useWorkforceColumns } from "./hooks/use-workforce-columns";

const classNames = mergeStyleSets({
    overlay: {
        width: 170,
        position: "absolute",
        pointerEvents: "none",
        zIndex: 999,
    },
});

function isInArea(pointerPosition: number, elementSize: number, chartSize: number) {
    return chartSize - pointerPosition >= elementSize;
}

const PADDING_OVER_CURSOR = 5;

export function Overlay(this: ResourceChartsCanvasState, props: ResourceChartsCanvasProps) {
    /*console.log(
    'Overlay ' +
      JSON.stringify({
        state: this.canvasState,
        overlayState: props.overlayState,
      })
  )*/

    const overlayRef = useRef<HTMLDivElement>(null);
    const workforceColumns = useWorkforceColumns(props.kappa?.trade);

    const _hoveredColumn = props.overlayState.hover
        ? props.kappa.view.col0 + Math.floor(props.overlayState.hover.canvasX / props.const.colPx)
        : null;

    const _hoveredWorkforceColumn =
        props.kappa.view.col0 <= _hoveredColumn && _hoveredColumn < props.kappa.view.col1
            ? workforceColumns[_hoveredColumn - props.kappa.view.col0] || []
            : null;
    const _date =
        props.kappa.view.col0 <= _hoveredColumn && _hoveredColumn < props.kappa.view.col1
            ? props.grid.gridToDate(_hoveredColumn)
            : null;
    const _isWorkforceSet = props.kappa.view?.options?.workforce ?? false;

    const positions = props.overlayState.hover
        ? {
              left: props.overlayState.hover.canvasX * props.scale + PADDING_OVER_CURSOR,
              top: props.overlayState.hover.canvasY + PADDING_OVER_CURSOR,
          }
        : null;

    if (positions && overlayRef.current) {
        const inAreaByX = isInArea(
            positions.left,
            overlayRef.current.clientWidth,
            // QUESTION: how to get the chart dimensions more correctly?
            this.chart.clientWidth,
        );

        const inAreaByY = isInArea(
            positions.top,
            overlayRef.current.clientHeight,
            // QUESTION: how to get the chart dimensions more correctly?
            this.chart.clientHeight,
        );

        if (!inAreaByX) {
            // PADDING_OVER_CURSOR + PADDING_OVER_CURSOR needs to compensate initial padding
            positions.left -= overlayRef.current.clientWidth + PADDING_OVER_CURSOR + PADDING_OVER_CURSOR;
        }

        if (!inAreaByY) {
            // PADDING_OVER_CURSOR + PADDING_OVER_CURSOR needs to compensate initial padding
            positions.top -= overlayRef.current.clientHeight + PADDING_OVER_CURSOR + PADDING_OVER_CURSOR;
        }
    }

    if (null === positions || null === _date || null === _hoveredWorkforceColumn) {
        return null;
    } else {
        return (
            <div ref={overlayRef} className={classNames.overlay} style={positions}>
                <ColumnOverview date={_date} workforce={_hoveredWorkforceColumn} isWorkforceSet={_isWorkforceSet} />
            </div>
        );
    }
}
