﻿import { assert, ComplexProp, ComplexPropIter, Core, gfu2 } from "../../core/lcmd2core";

export class TradesGetter {
    public readonly _core: Core;
    private _id: number = -1;
    private _r: any;

    constructor(core: Core, id?: number) {
        this._core = core;
        this._id = id;
        this.reset(id);
    }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this.reset(id);
    }

    public get icon(): string | null {
        return this.value<string>("icon", null);
    }

    public get name() {
        return this.value<string>("name", null);
    }

    public reset(id: number): TradesGetter {
        const _r = undefined !== id && this._core._model.trades[id];
        this._id = _r ? id : -1;
        this._r = _r || {};
        return this;
    }

    public _(name: string | ComplexProp, v0?: number) {
        const _name = "string" === typeof name ? name : name._value;
        return this._core._model.OP_I(this._r[_name], v0);
    }

    public value<T>(name: string | ComplexProp, v0?: T | null): T {
        const m = this._core._model;
        const _name = "string" === typeof name ? name : name._value;
        assert("development" !== process.env.NODE_ENV || !_name.startsWith("_"));
        return m.VALUE<T>(this._r[_name], v0);
    }

    public aux<T>(name: string, v0?: T): T {
        assert("development" !== process.env.NODE_ENV || name.startsWith("_"));
        return gfu2(this._r[name], v0);
    }

    public getAllProps(): ComplexPropIter {
        return new ComplexPropIter(Object.getOwnPropertyNames(this._r));
    }

    public syncPropsFrom(core: Core, props: string[]): boolean {
        const from = core._model;
        assert(this._r === this._core._model.trades[this._id]);
        return this._core._model.syncPropsFrom(this._id, from, props);
    }

    public getRef(trGt: TradesGetter): boolean {
        let ret = false;
        if (0 === this._r?.__ || this._r?.__ > 0) {
            const op = this._core._model.ops[this._r.__];
            if (op && op.r_sid in trGt._core._model._storageNames && "number" === typeof op.r_id) {
                trGt.reset(op.r_id);
                ret = true;
            }
        }
        if (!ret) {
            trGt.reset(undefined);
        }
        return ret;
    }
}
