import { Widget } from "../../layout/widget";
import { ReasonCodeChartHeader } from "./ReasonCodeChartHeader";
import { ReasonCodeChart } from "../../../../legacy/api/ReasonCodeChart";
import * as React from "react";
import { ChartJSFactory } from "../../common/chartjs";
import { useLCMD } from "../../../../app/LCMContext";
import { intl } from "../../../../legacy/GlobalHelperReact";

export function AttachedReasonCodesWidget(dashboardCtx) {
    const LCMD = useLCMD();

    function onRenderChartData(ctx: any, chart: any, data: any) {
        const labels = data?.labels || [];
        const values = data?.values || [];

        return {
            labels: labels,
            datasets: [
                {
                    data: values,
                    label: intl.get("ReasonCodesSettings.list.title"),
                    backgroundColor: [
                        "#C1C5C7",
                        "#107C10",
                        "#009BD4",
                        "#E47829",
                        "#D83B01",
                        "#FFE100",
                        "#FFDCBB",
                        "#579C98",
                        "#00B050",
                        "#BDDD7A",
                        "#64D8B5",
                        "#7030A0",
                        "#FFE100",
                        "#748088",
                        "#84A1B7",
                        "#37555D",
                        "#DD81FD",
                        "#4C5DF1",
                        "#DF32A4",
                        "#2400FF",
                        "#FFC8C8",
                        "#D13501",
                    ],
                },
            ],
        };
    }

    const ReasonCodeChartFactory = React.useMemo(() => {
        return ChartJSFactory.bind(null, {
            legendContainerId: "reasons-code",
            type: "doughnut",
            externalTooltip: true,
            onRenderChartData,
            onOutdatedChartData: dashboardCtx.onOutdatedChartData,
            onData: async (chart, data) => {
                const availableReasonCodes = await LCMD.getAvailableReasonCodes({ deleted: true });
                if (!availableReasonCodes) {
                    return;
                }

                const reasonCodes = new Map(availableReasonCodes.map((obj) => [obj["id"], obj["name"]]));
                const processesWithAttachedReasonCodes = await LCMD.getAllAttachedReasonCodes(true);
                let reasonCodeStatistics = null;
                const statisticsData = new Map<string, number>();

                if (processesWithAttachedReasonCodes) {
                    processesWithAttachedReasonCodes.forEach((process: Map<number, any>) => {
                        process.forEach((attached) => {
                            const reasonCodeName = reasonCodes.get(attached.rcid) as string;
                            if (!statisticsData.has(reasonCodeName)) {
                                statisticsData.set(reasonCodeName, 1);
                            } else {
                                const currentValue = statisticsData.get(reasonCodeName) + 1;
                                statisticsData.set(reasonCodeName, currentValue);
                            }
                        });
                    });
                    reasonCodeStatistics = statisticsData.size > 0 ? [...statisticsData.entries()] : null;
                }

                const labels = [];
                const values = [];

                if (reasonCodeStatistics && reasonCodeStatistics.length > 0) {
                    const sortedReasonCodes = [...reasonCodeStatistics].sort((a, b) => b[1] - a[1]);
                    sortedReasonCodes?.forEach(([name, amount]) => {
                        labels.push(name);
                        values.push(amount);
                    });
                }
                chart.setOverlayState({
                    data: { labels, values },
                });
            },
        });
    }, []);

    return (
        <Widget basicWidth={dashboardCtx.basicWidth} minWidth={dashboardCtx.minWidth} userflowId="chart-stoergruende">
            <ReasonCodeChartHeader />
            <ReasonCodeChart legendContainerId={"reasons-code"} factory={ReasonCodeChartFactory} />
        </Widget>
    );
}
