import * as React from "react";
import { RotateCw, X } from "lucide-react";

type StackedDialogViewProps = {
    title: string;
    actions: any[];
    children?: any;
    onRefresh?: () => void;
    onCancel: () => void;
    onOverride?: any;
};

export function StackedDialogView({
    actions,
    children,
    onCancel,
    onOverride,
    onRefresh,
    title,
}: StackedDialogViewProps) {
    return (
        <div className="flex h-full w-full flex-col flex-nowrap items-stretch">
            <div className="flex flex-none items-center px-5 py-3 font-semibold">
                {title && <span>{title}</span>}
                <span className="ml-auto flex items-center">
                    {onOverride && <span className="mt-1 inline-flex content-center justify-center">{onOverride}</span>}
                    {onRefresh && (
                        <RotateCw strokeWidth={1} size={20} className="mt-1 cursor-pointer" onClick={onRefresh} />
                    )}
                    <X strokeWidth={1} size={20} className="mt-1 cursor-pointer" onClick={onCancel} />
                </span>
            </div>
            <div className="grow shrink flex basis-auto overflow-y-auto px-5 pr-6 pt-0">
                {children}
            </div>
            <div className="flex flex-none justify-end gap-2.5 pb-3.5 pl-6 pr-3 pt-3">{actions}</div>
        </div>
    );
}
