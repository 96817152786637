import { useLCMD } from "../../app/LCMContext";
import { RBAC } from "../../model/DataModel";
import { useEffect, useState } from "react";
import { FrameworkError } from "../../model/GlobalHelper";
export function useRBACHook() {
    const LCMD = useLCMD();
    const [rbac, setRbac] = useState<{
        isLoading: boolean;
        isError: boolean;
        data: { rbac?: RBAC | undefined; role: "admin" | "user" | "readonly" };
        error: Error | FrameworkError;
    }>({ isLoading: true, data: null, isError: false, error: null });
    useEffect(() => {
        LCMD.getProjectDetails((error, data) => {
            if (error || !data || !data.sub) {
                // do not allow editing
                setRbac({ error: error, data: null, isError: true, isLoading: false });
            }
            if (data.rbac) {
                setRbac({
                    ...rbac,
                    data: { rbac: data.rbac[data.sub], role: data.meta.role } || null,
                    isLoading: false,
                });
            } else {
                setRbac({ data: { role: data.meta.role }, isError: false, isLoading: false, error: null });
            }
        });
    }, []);
    return rbac;
}
