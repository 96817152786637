import { Checklist } from "@/core/services/Checklist.service";

export const depenbrockTemplates: Checklist[] = [
    {
        tradeId: 1,
        name: "Malerarbeiten KICKOFF (2-3 Wochen vor Ausführungsbeginn)",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-5-group-1",
        checkpoints: [
            { id: 0, text: "Sind alle aktuellen Ausführungspläne übergeben worden?", groupId: "group-1" },
            { id: 1, text: "Wurde ein Termin für ein NU-Startgespräch abgestimmt? (für 1-2 Wochen vor Ausführung)", groupId: "group-1" },
            { id: 2, text: "Ist das Leistungssoll gemäß LV genau abgestimmt? (EP und / oder AP)", groupId: "group-1" },
            { id: 3, text: "Sind die Farbtöne und Qualitäten der Tapeten, Wandfarben und Lackierungen durch den NU schriftlich freigegeben?", groupId: "group-1" },
            { id: 4, text: "Ist die Qualitätsstufe (Q1-Q4) der Spachtelarbeiten abgestimmt?", groupId: "group-1" },
            { id: 5, text: "Sind die genauen Anforderungen an den Farbauftrag abgestimmt? (z.B. Dispersionsanstrich (Standard) oder Latexc Anstrich (wasserabweisend, sehr strapazierfähig), Nassabriebfestigkeit nach DIN EN 13300, etc.)", groupId: "group-1" },
            { id: 6, text: "Sind gegebenenfalls erforderliche Haftgrundierungen abgestimmt? (z.B. bei verzinkten Bauteilen, Tapezieren auf Betonoberflächen, etc.)", groupId: "group-1" },
            { id: 7, text: "Ist die Art des Beschichtungsverfahrens definiert? (z.B. Hand- oder Maschinenbeschichtung)", groupId: "group-1" },
            { id: 8, text: "Sind sämtliche durch den NU Maler zu versiegelnde Bauteile definiert? (z.B. Fensterbänke, Türzargen, etc.)", groupId: "group-1" },
            { id: 9, text: "Ist abgestimmt, ob die Feinarbeiten (Nachpinseln um Steckdosen, Thermostate, Beseitigen von Flecken durch andere NUs, etc.) vertraglich inbegriffen sind oder über Stundenrapporte abgerechnet werden?", groupId: "group-1" },
            { id: 10, text: "Sind sämtliche abzuklebende Flächen mit dem NU abgestimmt?", groupId: "group-1" },
            { id: 11, text: "Ist die Anfertigung von Farbmusterflächen vertraglich festgehalten?", groupId: "group-1" },
            { id: 12, text: "Sind sämtliche zu lackierende Bauteile definiert und vertraglich festgehalten? (z.B. Stahlzargen, Treppengeländer, Heizkörper, etc.)", groupId: "group-1" },
            { id: 13, text: "Sind die Positionen und Ausführungsarten von Bewegungsfugen definiert und festgelegt? (Fugen offen oder versiegelt?) HINWEIS: Diese dürfen nicht übertapeziert werden! → Rissgefahr", groupId: "group-1" },
            { id: 14, text: "Sind die allgemeinen Fragen der BE geklärt? (Lager- & Bewegungsflächen, Wasser- & Stromentnahmestellen,…)", groupId: "group-1" },
            { id: 15, text: "Wurden die Messpunkte abgeklärt und übergeben? (Meterrisse OKFF, Geschosshöhen, Deckenhöhen, Achsen, etc.)", groupId: "group-1" },
            { id: 16, text: "Liegt die prognostizierte Mindesttemperatur während der Malerarbeiten bei ≥ +8°C und bei Tapezierarbeiten bei ≥ +15°C?", groupId: "group-1" },
            { id: 17, text: "HINWEIS: Checkliste 4_00 Unterlagen NU/SUB beachten!", groupId: "group-1" },
            { id: 18, text: "Ist mit den Gewerken ELT & HLS abgestimmt, dass die Feinmontage (Schalter, Steckdosen, Thermostate, etc.) erst nach den Malerarbeiten erfolgt? (Alternativ hat der NU Maler die Abklebe-/ Demontagearbeiten als Nebenleistung abzurechnen → Mehrkosten!)", groupId: "group-1" },
            { id: 19, text: "Ist die zeitliche Abfolge zwischen dem Einbau von Türzargen, Fensterbänken, etc. und deren Versiegelung durch den Maler abgestimmt?", groupId: "group-1" },
            { id: 20, text: "Ist die Baufreiheit des Malers mit den übrigen Gewerken dahingehend abgestimmt, dass uneingeschränktes Arbeiten kann und die Flächen ordnungsgemäß trocknen können?", groupId: "group-1" }
        ],
        groups: [
            { id: "group-1", name: "KICKOFF (2-3 Wochen vor Ausführungsbeginn)", leadTime: 2 }
        ]
    },
    {
        tradeId: 1,
        name: "Malerarbeiten VORLEISTUNGSCHECK (0-2 Wochen vor Ausführungsbeginn)",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-5-group-2",
        checkpoints: [
            { id: 21, text: "Sind alle Trockenbauwände vollständig geschlossen, gespachtelt und geschliffen? Liegt die geforderte Qualitätsstufe vor? HINWEIS: Im Idealfall wird der Maler mit dem letzten Spachtelgang beauftragt.", groupId: "group-2" },
            { id: 22, text: "Sind alle Wände und Oberflächen (insb. der Putzuntergrund) ebenmäßig und vollständig getrocknet? (vgl. Feuchtemessung durch NU Innenputz) ACHTUNG: Visuelle Prüfung auf Sinterschichten durchführen!", groupId: "group-2" },
            { id: 23, text: "Sind sämtliche ELT-Zuleitungen und Dosen verdrahtet und durch einen Deckel verschlossen? (vgl. Punkt 19)", groupId: "group-2" },
            { id: 24, text: "Sind die Vorleistungen durch den NU begutachtet und als mängelfrei bekundet worden? (Zustand der Oberflächen, Ebenheit, Tragfähigkeit, etc.) → schriftliche Bestätigung einholen!", groupId: "group-2" }
        ],
        groups: [
            { id: "group-2", name: "VORLEISTUNGSCHECK (0-2 Wochen vor Ausführungsbeginn)", leadTime: 2 }
        ]
    },
    {
        tradeId: 1,
        name: "Malerarbeiten AUSFÜHRUNGSPRÜFUNG (Ab Ausführungsbeginn)",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-5-group-3",
        checkpoints: [
            { id: 25, text: "Liegt die tatsächliche Mindesttemperatur während der Malerarbeiten bei ≥ +8°C und bei Tapezierarbeiten bei ≥ +15°C?", groupId: "group-3" },
            { id: 26, text: "Sind alle erforderlichen Schutzmaßnahmen gegenüber anderen Bauteilen getroffen worden? (z.B. Abkleben / Abdecken)", groupId: "group-3" },
            { id: 27, text: "Wurden gegebenenfalls erforderliche Haftgrundierungen aufgebracht? (z.B. bei verzinkten Bauteilen, Tapezieren auf Beton, etc.)", groupId: "group-3" },
            { id: 28, text: "Sind Risse im Putz- oder Betonuntergrund durch ein Armierungsgewebe überbrückt worden?", groupId: "group-3" },
            { id: 29, text: "Ist die Untergrundbeschaffenheit der zu lackierenden Bauteile geprüft worden? Sind die Grundierungen fachgerecht aufgetragen? HINWEIS: Stahloberflächen sind stets in zwei Schichten zu lackieren (Grund- & Schlussbeschichtung)", groupId: "group-3" },
            { id: 30, text: "Wurden zwei Anstriche durchgeführt? HINWEIS: Ggf. werden diese in einem Durchgang durchgeführt. Aufgrund der dadurch schwierigen Überprüfung empfiehlt sich das stichprobenhafte Begutachten der Malerarbeiten über längere Zeiträume hinweg.", groupId: "group-3" },
            { id: 31, text: "Entsprechen die Farbtöne der Wandfarben und der Lackierungen sowie die Tapeten den vertraglichen Vorgaben?", groupId: "group-3" },
            { id: 32, text: "Sind sämtliche Werkstoffreste entsorgt worden?", groupId: "group-3" }
        ],
        groups: [
            { id: "group-3", name: "AUSFÜHRUNGSPRÜFUNG (Ab Ausführungsbeginn)", leadTime: 2 }
        ]
    },
    {
        tradeId: 1,
        name: "Malerarbeiten QUALITÄTSPRÜFUNG (Ab 1 Woche vor Abschluss der Arbeiten)",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-5-group-4",
        checkpoints: [
            { id: 33, text: "Entsprechen die gespachtelten Oberflächen den vertraglichen Vorgaben? (Qualitätsstufe, vgl. Punkt 5)", groupId: "group-4" },
            { id: 34, text: "Sind die Oberflächen sauber ausgeführt? (keine Farbtropfen, alle Anstriche deckend, keine losen Tapetenenden, bündige Tapetenstöße, etc.) → Sichtprüfung", groupId: "group-4" },
            { id: 35, text: "Sind die Decken- und Wandanschlüsse fachgerecht ausgebildet worden? (waagerechte und scharfkantige Übergänge, ggf. erforderliche Kellenschnitte) → Sichtprüfung", groupId: "group-4" },
            { id: 36, text: "Sind die Wandübergänge von Mauerwerk / Stahlbeton zu Trockenbauwänden ohne sichtbaren Versprung ausgeführt und dauerelastisch versiegelt?", groupId: "group-4" },
            { id: 37, text: "Sind sämtliche Bewegungsfugen fachgerecht übernommen und ausgeführt worden? (vgl. Punkt 14)", groupId: "group-4" },
            { id: 38, text: "Sind alle Acrylnähte durchgängig und sauber ausgeführt? → Sichtprüfung", groupId: "group-4" },
            { id: 39, text: "Sind die umgebenen Bauteile frei von Farb- und Lackspritzern?", groupId: "group-4" },
            { id: 40, text: "AUFGABE: Gemeinsames Begehen mit dem NU im Rahmen der Nacharbeiten zur Beseitigung von Beschädigungen (wie Flecken) vor der AG-Abnahme", groupId: "group-4" },
            { id: 41, text: "AUFGABE: Einholen der Materialnachweise als Rev.-Unterlagen (Farben, Kleber, Lacke, etc.), Frist gemäß NU-Vertrag", groupId: "group-4" }
        ],
        groups: [
            { id: "group-4", name: "QUALITÄTSPRÜFUNG (Ab 1 Woche vor Abschluss der Arbeiten)", leadTime: 2 }
        ]
    },
    {
        tradeId: 2,
        name: "Dachabdichtungsarbeiten Bitumen KICKOFF",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-6-group-1",
        checkpoints: [
            {
                id: 0,
                text: "Sind alle aktuellen Ausführungspläne übergeben worden? Sind diese geprüft, vollständig und eindeutig? (Aufbau, Details, Werkstoffe, Anforderungen, Befestigungsteile, etc.)",
                groupId: "group-1",
            },
            {
                id: 1,
                text: "Liegen freigegebene Ausführungsdetails vor? (Dichtungsaufbau, Attika, Wandanschlüsse, Dehnungsfugen, Andichtung Lichtkuppeln und Lüftungshauben, Kabeldurchdringungen, Beläge, etc.)",
                groupId: "group-1",
            },
            {
                id: 2,
                text: "Wurde ein Termin für ein NU-Startgespräch abgestimmt? (für 1-2 Wochen vor Ausführung)",
                groupId: "group-1",
            },
            { id: 3, text: "Ist das Leistungssoll gemäß LV genau abgestimmt? (EP und/oder AP)", groupId: "group-1" },
            {
                id: 4,
                text: "Ist die Art der Dachentwässerung (innen-/außenliegend) die Position und das entsprechende System (inkl. Notüberlauf) abgestimmt? HINWEIS: Innenliegende Abläufe sind min. 30 cm von der Attika / aufgehenden Bauteilen entfernt anzuordnen.",
                groupId: "group-1",
            },
            {
                id: 5,
                text: "Liegt eine vollständige Gefälleplanung (mit min. 2 %) vor und stimmt diese mit der Position der geplanten Dachabläufen überein?",
                groupId: "group-1",
            },
            {
                id: 6,
                text: "Ist die Eignung, Zulässigkeit und Vertragskonformität der zu verwendenden Werkstoffe geprüft und mit entsprechenden Werkszeugnissen dokumentiert? (Kriterien z.B. Brandschutz, Materialverträglichkeit, Wärmeschutz)",
                groupId: "group-1",
            },
            {
                id: 7,
                text: "Sind für begehbare Dächer (auch zur Wartung) Absturzsicherungen berücksichtigt worden? (Sekuranten-Seilsystem / Geländer)",
                groupId: "group-1",
            },
            {
                id: 8,
                text: "Sind sämtliche Dachdurchdringungen festgelegt? HINWEIS: Der Abstand zwischen einzelnen Durchdringungen sollte zur Wahrung eines ordnungsgemäßen Abdichtens 30 cm nicht unterschreiten!",
                groupId: "group-1",
            },
            {
                id: 9,
                text: "Sind sonstige Zusatzlasten bei der Planung der Dämmung berücksichtigt worden? (Plattenbelag, Klimageräte, PV-Anlage, etc.) → Druckfestigkeit der Dämmung entscheidend!",
                groupId: "group-1",
            },
            {
                id: 10,
                text: "Wurden aufgrund der erhöhten Brandgefahr bei der Verlegung von Schweißbahnen die Grundsätze der Brandverhütung geklärt? (Vorhalten von Feuerlöschern, Rettungswege, etc.)",
                groupId: "group-1",
            },
            {
                id: 11,
                text: "Sind die allgemeinen Fragen der BE geklärt? (Lager- & Bewegungsflächen, Wasser- & Stromentnahmestellen,...)",
                groupId: "group-1",
            },
            {
                id: 12,
                text: "Wurden die Messpunkte abgeklärt und übergeben? (Meterrisse OKFF, Geschosshöhen, Deckenhöhen, Achsen etc.)",
                groupId: "group-1",
            },
            {
                id: 13,
                text: "Liegt die prognostizierte Mindesttemperatur während der Dacheindichtung bei ≥ + 5°C? → Ebenfalls keine Nässe, Schnee und Eis oder starker Wind!",
                groupId: "group-1",
            },
            { id: 14, text: "HINWEIS: Checkliste 4_00 Unterlagen NU/SUB beachten!", groupId: "group-1" },
            {
                id: 15,
                text: "Ist die Position und Größe von Dachdurchdringungen mit den entsprechenden Gewerken (i.d.R. HLS, ELT) abgestimmt?",
                groupId: "group-1",
            },
            {
                id: 16,
                text: "Ist die Montage der Sekuranten (falls erforderlich) vor dem Beginn der Dachabdichtungsarbeiten terminiert?",
                groupId: "group-1",
            },
            {
                id: 17,
                text: "Ist das Zusammenspiel zwischen Dachdecker und ELT / HLS hinsichtlich des Anschlusses der durch das Dach zu führenden Leitungen abgestimmt?",
                groupId: "group-1",
            },
            { id: 18, text: "Sind erforderliche Kernbohrungen geplant und terminiert?", groupId: "group-1" }
        ],
        groups: [
            { id: "group-1", name: "KICKOFF", leadTime: 3 }
        ]
    },
    {
        tradeId: 2,
        name: "Dachabdichtungsarbeiten Bitumen VORLEISTUNGSCHECK",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-6-group-2",
        checkpoints: [
            {
                id: 19,
                text: "Wurden die Rohdachflächen vom Vorgewerk vollständig von Materialien befreit und staubfrei übergeben?",
                groupId: "group-2",
            },
            {
                id: 20,
                text: "Wurde (falls erforderlich) die Montage der Sekuranten auf der Rohdecke abgeschlossen und diese vollständig dokumentiert?",
                groupId: "group-2",
            }
        ],
        groups: [
            { id: "group-2", name: "VORLEISTUNGSCHECK", leadTime: 2 }
        ]
    },
    {
        tradeId: 2,
        name: "Dachabdichtungsarbeiten Bitumen AUSFÜHRUNGSPRÜFUNG",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-6-group-3",
        checkpoints: [
            {
                id: 21,
                text: "Lässt das Wetter die Dachabdichtungsarbeiten zu? (Außentemperatur ≥ + 5°C, keine Nässe, Schnee und Eis oder starker Wind)",
                groupId: "group-3",
            },
            {
                id: 22,
                text: "Befinden sich die in Punkt 11 festgelegten Brandbekämpfungsmittel vor Ort? HINWEIS: Wartungssiegel prüfen!",
                groupId: "group-3",
            },
            {
                id: 23,
                text: "Wurde der Bitumenvoranstrich deckend und vollständig aufgebracht? HINWEIS: Dieser ist auch an den aufgehenden Bauteilen (z.B. Attika) hochzuführen!",
                groupId: "group-3",
            },
            {
                id: 24,
                text: "Werden Schwanenhälse & sonstige Dachdurchführungen nach dem Deckendurchbruch schnellstmöglich montiert und gegen Regen geschützt? – Kein Eindringen von Wasser in darunterliegende Geschosse. HINWEIS: Für Rohrdurchführungen sind Klebe- / Klemmflansche auf der Dampfsperre zu montieren! Spalt zwischen Rohr und Rohdecke ist auszudämmen, ebenso das Innere der Schwanenhälse!",
                groupId: "group-3",
            },
            { id: 25, text: "Entspricht die verwendete Dampfsperre den vertraglichen Vorgaben?", groupId: "group-3" },
            {
                id: 26,
                text: "Wurde die Dampfsperre überlappend verlegt und an aufgehenden Bauteilen hochgeführt (Ausnahme: Brandwände)? – stichprobenhafte Kontrolle der Nähte. ACHTUNG: Die Kopfnähte der Bahnen sind versetzt anzuordnen; keine Kreuzfugen!",
                groupId: "group-3",
            },
            {
                id: 27,
                text: "Wurden sämtliche Einbauteile (Sekuranten, Lüftungsrohre, Lichtkuppeln, etc.) sauber angearbeitet?",
                groupId: "group-3",
            },
            {
                id: 28,
                text: "Erfolgt eine saubere Ausbildung der Attika mittels OSB-Platten? ACHTUNG: Überstand vor der Fassade gem. Maßangaben des Attikableches. Zu weites Überstehen begünstigt das Eindringen von Feuchte und somit derVerwitterung!",
                groupId: "group-3",
            },
            { id: 29, text: "Entsprechen die verwendeten Dämmstoffe den vertraglichen Vorgaben?", groupId: "group-3" },
            { id: 30, text: "Wurde die Dämmung fachgerecht verklebt und im Verband angeordnet?", groupId: "group-3" },
            {
                id: 31,
                    text: "Entsprechen die ausgeführten Gefälle der Planungsgrundlage? (Sichtprüfung)",
                groupId: "group-3",
            },
            {
                id: 32,
                    text: "Wurden die Abläufe fachgerecht in die Dämmschicht integriert? Stimmt die Position dieser mit der Planungsgrundlage überein?",
                groupId: "group-3",
            },
            {
                id: 33,
                    text: "Erfolgt die Dehnfugenausbildung gemäß detaillierter Vorgabe? (s. DIN 18531-1, Punkt 6.9)",
                groupId: "group-3",
            },
            {
                id: 34,
                    text: "Entsprechen die verwendeten Abdichtungsbahnen den vertraglichen Vorgaben?",
                groupId: "group-3",
            },
            {
                id: 35,
                    text: "Ist die Bitumenabdichtung vollflächig, sauber und mit herstellerspezifischen Verlege- und Nahtvorgaben verklebt (z.B. Heiß-/Kaltklebeverfahren)? Wurden die Abläufe dabei dicht angeschlossen? – Anfordern sämtlicher Produktdatenblätter & Montageanleitungen! HINWEIS: Überlappung min. 8 cm (mehrlagige Abdichtung) bzw. 10 cm (einlagige Abdichtung)",
                groupId: "group-3",
            },
            {
                id: 36,
                    text: "Werdennoch nicht geschlossene Stellen / Nähte übergangsweise mit entsprechendem Dichtungs-Klebeband gegen direktes Eindringen von Feuchtigkeit geschützt?",
                groupId: "group-3",
            },
            {
                id: 37,
                    text: "Sind alle Eckbereiche fachgerecht und sauber ausgeführt? (zusätzlicher Streifen zur Nahtüberbrückung und Vermeidung von Rissen durch Dehnung)",
                groupId: "group-3",
            },
            {
                id: 38,
                    text: "Wird die Abdichtungsbahn an sämtlichen Durchdringungen (z.B. Lichtkuppeln) mindestens 15 cm über die höchste wasserführende Schicht hochgezogen? (Bei Dachneigungen ungenutzter Dächer > 5° reichen 10 cm)",
                groupId: "group-3",
            },
            {
                id: 39,
                    text: "Wurden die zuvor genannten Abdichtungen an den Durchdringungen zusätzlich mit Edelstahlbändern gesichert und mit einem Regenkranz versehen?",
                groupId: "group-3",
            },
            {
                id: 40,
                    text: "Wird die Abdichtungsbahn an sämtlichen aufgehenden Bauteilen (z.B. Attika) mindestens 10 cm über die oberste Belagsschicht hochgezogen? HINWEIS: Bei Loggien / Balkonen beträgt diese 15 cm. Bei Dachneigungen ungenutzter Dächer > 5° reichen 5 cm.",
                groupId: "group-3",
            },
            {
                id: 41,
                    text: "Wird die Mindestrückstauhöhe von 15 cm über der Entwässerungsebene an allen Bauteilen eingehalten? (Türschwellen, Fensterbrüstungen, Sperrschichten im aufgehenden Mauerwerk, Wandanschlüsse, etc.) HINWEIS: Die Abdichtung ist bis zu derselben Höhe zu führen! Ist dies technisch nicht möglich, beachte Punkte 43 - 44!",
                groupId: "group-3",
            },
            {
                id: 42,
                    text: "Bei Anschlusshöhen zwischen 5…15 cm: Wurden Entwässerungsrinnen unmittelbar vor der gesamten Tür- / Fensterbreite angeordnet? (Sofern kein Dachüberstand vorhanden, müssen diese min. 15 cm breit sein) HINWEIS: Diese müssen unmittelbar an eine Entwässerungsleitung angeschlossen sein!",
                groupId: "group-3",
            },
            {
                id: 43,
                    text: "Bei Anschlusshöhen < 5 cm: Wurde eine gesonderte Fachplanung nach Abschnitt 6.7.3 der DIN 18531-1 durchgeführt?",
                groupId: "group-3",
            },
            {
                id: 44,
                    text: "Bei Plattenbelag: Sind Bodenrinnen und Abdeckroste bündig eingebaut?",
                groupId: "group-3",
            },
            {
                id: 45,
                    text: "Bei genutzten Dächern: Wurden die An- & Abschlüsse der Abdichtungsschichten an den aufgehenden Bauteilen vor mechanischen Einwirkungen geschützt? (z.B. durch Bleche)",
                groupId: "group-3",
            },
            {
                id: 46,
                    text: "Wurden die An- & Abschlüsse von Abdichtungen an aufgehenden Bauteilen durch Klemmprofile / -schienen fixiert?",
                groupId: "group-3",
            },
            {
                id: 47,
                    text: "Wurden die fertig eingedichteten Flächen auf Dichtigkeit geprüft? (z.B. mit dem Elektroimpulsverfahren) – Protokoll einfordern!",
                groupId: "group-3",
            },
            {
                id: 48,
                    text: "Wurden die Attikableche lagestabil befestigt? Sind die Stöße anliegend? ACHTUNG: Auf Materialverträglichkeit und Blitzschutz achten!",
                groupId: "group-3",
            },
            {
                id: 49,
                    text: "Wurden für Folgegewerke ggf. erforderliche Schutzmaßnahmen fachgerecht durchgeführt? (Wurzelschutzbahnen, Schutzschichten gegen mechanische Schäden, etc.)",
                groupId: "group-3",
            },
            {
                id: 50,
                    text: "Bei aufgeständerten Anlagen: Wurden die Standfüße mit lastverteilenden Sockelplatten versehen, welche die Dachabdichtung nicht beschädigen?",
                groupId: "group-3",
            }
        ],
        groups: [
            { id: "group-3", name: "AUSFÜHRUNGSPRÜFUNG", leadTime: 1 }
        ]
    },
    {
        tradeId: 2,
        name: "Dachabdichtungsarbeiten Bitumen QUALITÄTSPRÜFUNG",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-6-group-4",
        checkpoints: [
            {
                id: 51,
                text: "AUFGABE: Regelmäßiges Prüfen der Dichtigkeit der Durchdringungen in dem obersten Geschoss (bei Regen) – insbesondere bei Dachdurchdringungen über späteren Abkofferungen / Wänden!",
                groupId: "group-4",
            },
            {
                id: 52,
                text: "Sind die sichtbaren Nähte fest verklebt? (Keine Löcher und kein austretendes Wasser beim Draufdrücken)",
                groupId: "group-4",
            },
            { id: 53, text: "Ist die Deckenfläche beim Begehen frei von spürbaren Hohlstellen?", groupId: "group-4" },
            {
                id: 54,
                text: "Wurden alle Verunreinigungen durch Bitumenspritzer von den Bauteilen entfernt?",
                groupId: "group-4",
            },
            {
                id: 55,
                text: "Wurden die Arbeitsbereiche von allen Werkstoffresten befreit und gereinigt?",
                groupId: "group-4",
            },
            {
                id: 56,
                text: "AUFGABE: Einholen der Materialnachweise als Rev.-Unterlagen (Abdichtung, Dämmstoffe, Abläufe, Zulassungen etc.), Frist gemäß NU-Vertrag",
                groupId: "group-4",
            }
        ],
        groups: [
            { id: "group-4", name: "QUALITÄTSPRÜFUNG", leadTime: 1 }
        ]
    },
    {
        tradeId: 3,
        name: "Dachabdichtungsarbeiten Folie KICKOFF",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-7-group-1",
        checkpoints: [
            {
                id: 0,
                text: "Sind alle aktuellen Ausführungspläne übergeben worden? Sind diese geprüft, vollständig und eindeutig? (Aufbau, Details, Werkstoffe, Anforderungen, Befestigungsteile, etc.)",
                groupId: "group-1",
            },
            {
                id: 1,
                text: "Liegen freigegebene Ausführungsdetails vor? (Dichtungsaufbau, Attika, Wandanschlüsse, Dehnungsfugen, Andichtung Lichtkuppeln und Lüftungshauben, Kabeldurchdringungen, Beläge, Sickenfüller, etc.)",
                groupId: "group-1",
            },
            {
                id: 2,
                text: "Wurde ein Termin für ein NU-Startgespräch abgestimmt? (für 1-2 Wochen vor Ausführung)",
                groupId: "group-1",
            },
            { id: 3, text: "Ist das Leistungssoll gemäß LV genau abgestimmt? (EP und/oder AP)", groupId: "group-1" },
            {
                id: 4,
                text: "Ist die Art der Dachentwässerung (innen-/außenliegend/Rinnen), die Position und das entsprechende System (inkl. Notüberlauf) abgestimmt? HINWEIS: Innenliegende Abläufe sind min. 30 cm von der Attika / aufgehenden Bauteilen entfernt anzuordnen.",
                groupId: "group-1",
            },
            {
                id: 5,
                text: "Liegt eine vollständige Gefälleplanung (mit min. 2 %) vor und stimmt diese mit der Position der geplanten Dachabläufen überein?",
                groupId: "group-1",
            },
            {
                id: 6,
                text: "Ist die Eignung, Zulässigkeit und Vertragskonformität der zu verwendenden Werkstoffe geprüft und mit entsprechenden Werkszeugnissen dokumentiert? (Kriterien z.B. Brandschutz, Materialverträglichkeit, Wärmeschutz)",
                groupId: "group-1",
            },
            {
                id: 7,
                text: "Sind für begehbare Dächer (auch zur Wartung) Absturzsicherungen berücksichtigt worden? (Sekuranten-Seilsystem / Geländer)",
                groupId: "group-1",
            },
            {
                id: 8,
                text: "Sind sämtliche Dachdurchdringungen festgelegt? HINWEIS: Der Abstand zwischen einzelnen Durchdringungen sollte zur Wahrung eines ordnungsgemäßen Abdichtens 30 cm nicht unterschreiten!",
                groupId: "group-1",
            },
            {
                id: 9,
                text: "Sind sonstige Zusatzlasten bei der Planung der Dämmung berücksichtigt worden? (Plattenbelag, Klimageräte, PV-Anlage, etc.) → Druckfestigkeit der Dämmung entscheidend!",
                groupId: "group-1",
            },
            {
                id: 10,
                text: "Sind die allgemeinen Fragen der BE geklärt? (Lager- & Bewegungsflächen, Wasser- & Stromentnahmestellen,...)",
                groupId: "group-1",
            },
            {
                id: 11,
                text: "Wurden die Messpunkte abgeklärt und übergeben? (Meterrisse OKFF, Geschosshöhen, Deckenhöhen, Achsen etc.)",
                groupId: "group-1",
            },
            {
                id: 12,
                text: "Liegt die prognostizierte Mindesttemperatur während der Dacheindichtung bei ≥ + 5°C? → Ebenfalls keine Nässe, Schnee und Eis oder starker Wind!",
                groupId: "group-1",
            },
            { id: 13, text: "HINWEIS: Checkliste 4_00 Unterlagen NU/SUB beachten!", groupId: "group-1" },
            {
                id: 14,
                text: "Ist die Position und Größe von Dachdurchdringungen mit den entsprechenden Gewerken (i.d.R. HLS, ELT) abgestimmt?",
                groupId: "group-1",
            },
            {
                id: 15,
                text: "Ist die Montage der Sekuranten (falls erforderlich) vor dem Beginn der Dachabdichtungsarbeiten terminiert?",
                groupId: "group-1",
            },
            {
                id: 16,
                text: "Ist das Zusammenspiel zwischen Dachdecker und ELT / HLS hinsichtlich des Anschlusses der durch das Dach zu führenden Leitungen abgestimmt?",
                groupId: "group-1",
            },
            { id: 17, text: "Sind erforderliche Kernbohrungen geplant und terminiert?", groupId: "group-1" }
        ],
        groups: [
            { id: "group-1", name: "KICKOFF", leadTime: 3 }
        ]
    },
    {
        tradeId: 3,
        name: "Dachabdichtungsarbeiten Folie VORLEISTUNGSCHECK",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-7-group-2",
        checkpoints: [
            {
                id: 18,
                text: "Wurden die Rohdachflächen vom Vorgewerk vollständig von Materialien befreit und staubfrei übergeben?",
                groupId: "group-2",
            },
            {
                id: 19,
                text: "Ist (falls erforderlich) die Montage der Sekuranten auf der Rohdecke abgeschlossen und wurde diese vollständig dokumentiert?",
                groupId: "group-2",
            }
        ],
        groups: [
            { id: "group-2", name: "VORLEISTUNGSCHECK", leadTime: 2 }
        ]
    },
    {
        tradeId: 3,
        name: "Dachabdichtungsarbeiten Folie AUSFÜHRUNGSPRÜFUNG",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-7-group-3",
        checkpoints: [
            {
                id: 20,
                text: "Lässt das Wetter die Dachabdichtungsarbeiten zu? (Außentemperatur ≥ + 5°C, keine Nässe, Schnee und Eis oder starker Wind)",
                groupId: "group-3",
            },
            {
                id: 21,
                text: "Bei Trapezblech-Dächern: Wurden die Sicken der Bleche mit Sickenfüllern gem. Wärme- & Schallschutznachweis ausgedämmt?",
                groupId: "group-3",
            },
            {
                id: 22,
                text: "Werden Schwanenhälse & sonstige Dachdurchführungen nach dem Deckendurchbruch schnellstmöglich montiert und gegen Regen geschützt? – Kein Eindringen von Wasser in darunterliegende Geschosse. HINWEIS: Für Rohrdurchführungen sind Klebe- / Klemmflansche auf der Dampfsperre zu montieren! Spalt zwischen Rohr und Rohdecke ist auszudämmen, ebenso das Innere der Schwanenhälse!",
                groupId: "group-3",
            },
            { id: 23, text: "Entspricht die verwendete Dampfbremse den vertraglichen Vorgaben?", groupId: "group-3" },
            {
                id: 24,
                text: "Wurde die Dampfbremse überlappend verlegt, miteinander verklebt und an aufgehenden Bauteilen hochgeführt (Ausnahme: Brandwände)? – stichprobenhafte Kontrolle der Nähte. ACHTUNG: Die Kopfnähte der Bahnen sind versetzt anzuordnen; keine Kreuzfugen; Überlappung i.d.R. min. 4 cm!",
                groupId: "group-3",
            },
            {
                id: 25,
                text: "Wurden sämtliche Einbauteile (Sekuranten, Lüftungsrohre, Lichtkuppeln, etc.) sauber angearbeitet?",
                groupId: "group-3",
            },
            {
                id: 26,
                text: "Erfolgt eine saubere Ausbildung der Attika mittels OSB-Platten? ACHTUNG: Überstand vor der Fassade gem. Maßangaben des Attikableches. Zu weites Überstehen begünstigt das Eindringen von Feuchte und somit der Verwitterung!",
                groupId: "group-3",
            },
            { id: 27, text: "Entsprechen die verwendeten Dämmstoffe den vertraglichen Vorgaben?", groupId: "group-3" },
            {
                id: 28,
                text: "Wurde die Dämmung fachgerecht verdübelt und im Verband angeordnet? (min. zwei Dübel pro Dämmplatte)",
                groupId: "group-3",
            },
            {
                id: 29,
                text: "Ist die Dämmung sauber an Einbauteile und Durchdringungen angearbeitet worden? (Hohlräume sind auszuschäumen!)",
                groupId: "group-3",
            },
            {
                id: 30,
                text: "Entsprechen die ausgeführten Gefälle der Planungsgrundlage? (Sichtprüfung)",
                groupId: "group-3",
            },
            {
                id: 31,
                text: "Wurden die Abläufe fachgerecht in die Dämmschicht integriert? Stimmt die Position dieser mit der Planungsgrundlage überein? (sofern keine Entwässerung über Regenrinnen geplant ist)",
                groupId: "group-3",
            },
            {
                id: 32,
                text: "Erfolgt die Dehnfugenausbildung gemäß detaillierter Vorgabe? (s. DIN 18531-1, Punkt 6.9)",
                groupId: "group-3",
            },
            {
                id: 33,
                text: "Entsprechen die verwendeten Abdichtungsfolien den vertraglichen Vorgaben?",
                groupId: "group-3",
            },
            {
                id: 34,
                text: "Wurde die Folienabdichtung in den Randbereichen fachgerecht mit Dübeln befestigt? (ca. alle 20 cm) ACHTUNG: Auch um Lichtkuppeln herum ist zu dübeln!",
                groupId: "group-3",
            },
            {
                id: 35,
                text: "Sind die Folienbahnen fachgerecht und mit herstellerspezifischen Verlege- und Nahtvorgaben verklebt worden?",
                groupId: "group-3",
            },
            {
                id: 36,
                text: "Wurden die Abläufe (sofern keine Rinnen geplant) dabei dicht angeschlossen? – Anfordern sämtlicher Produktdatenblätter & Montageanleitungen! HINWEIS: Überlappung min. 4 cm",
                groupId: "group-3",
            },
            {
                id: 37,
                text: "Werden noch nicht geschlossene Stellen / Nähte übergangsweise mit entsprechendem Dichtungs-Klebeband gegen direktes Eindringen von Feuchtigkeit geschützt?",
                groupId: "group-3",
            },
            {
                id: 38,
                text: "Sind alle Eckbereiche fachgerecht und sauber ausgeführt? (zusätzlicher Streifen zur Nahtüberbrückung und Vermeidung von Rissen durch Dehnung / Überkleben der Rondelle der Dübel)",
                groupId: "group-3",
            },
            {
                id: 39,
                text: "Wird die Abdichtungsfolie an sämtlichen Durchdringungen (z.B. Lichtkuppeln) mindestens 15 cm über die höchste wasserführende Schicht hochgezogen? (Bei Dachneigungen ungenutzter Dächer > 5° reichen 10 cm)",
                groupId: "group-3",
            },
            {
                id: 40,
                text: "Wird die Abdichtungsfolie an sämtlichen aufgehenden Bauteilen (z.B. Attika) mindestens 10 cm über die oberste Belagsschicht hochgezogen? HINWEIS: Bei Loggien / Balkonen beträgt diese 15 cm. Bei Dachneigungen ungenutzter Dächer > 5° reichen 5 cm.",
                groupId: "group-3",
            },
            {
                id: 41,
                text: "Wird die Mindestrückstauhöhe von 15 cm über der Entwässerungsebene an allen Bauteilen eingehalten? (Türschwellen, Fensterbrüstungen, Sperrschichten im aufgehenden Mauerwerk, Wandanschlüsse, etc.) HINWEIS: Die Abdichtung ist bis zu derselben Höhe zu führen! Ist dies technisch nicht möglich, beachte Punkte 43 - 44!",
                groupId: "group-3",
            },
            {
                id: 42,
                text: "Bei Anschlusshöhen zwischen 5…15 cm: Wurden Entwässerungsrinnen unmittelbar vor der gesamten Tür- / Fensterbreite angeordnet? (Sofern kein Dachüberstand vorhanden, müssen diese min. 15 cm breit sein) HINWEIS: Diese müssen unmittelbar an eine Entwässerungsleitung angeschlossen sein!",
                groupId: "group-3",
            },
            {
                id: 43,
                text: "Bei Anschlusshöhen < 5 cm: Wurde eine gesonderte Fachplanung nach Abschnitt 6.7.3 der DIN 18531-1 durchgeführt?",
                groupId: "group-3",
            },
            {
                id: 44,
                text: "Bei Plattenbelag: Sind Bodenrinnen und Abdeckroste bündig eingebaut?",
                groupId: "group-3",
            },
            {
                id: 45,
                text: "Bei genutzten Dächern: Wurden die An- & Abschlüsse der Abdichtungsschichten an den aufgehenden Bauteilen vor mechanischen Einwirkungen geschützt? (z.B. durch Bleche)",
                groupId: "group-3",
            },
            {
                id: 46,
                text: "Wurden die An- & Abschlüsse von Abdichtungen an aufgehenden Bauteilen durch Klemmprofile / -schienen fixiert?",
                groupId: "group-3",
            },
            {
                id: 47,
                text: "Wurden die fertig eingedichteten Flächen auf Dichtigkeit geprüft? (z.B. mit dem Elektroimpulsverfahren) – Protokoll einfordern!",
                groupId: "group-3",
            },
            {
                id: 48,
                text: "Wurden die Attikableche lagestabil befestigt? Sind die Stöße anliegend? ACHTUNG: Auf Materialverträglichkeit und Blitzschutz achten!",
                groupId: "group-3",
            },
            {
                id: 49,
                text: "Wurden für Folgegewerke ggf. erforderliche Schutzmaßnahmen fachgerecht durchgeführt? (Wurzelschutzbahnen, Schutzschichten gegen mechanische Schäden, etc.)",
                groupId: "group-3",
            },
            {
                id: 50,
                text: "Bei aufgeständerten Anlagen: Wurden die Standfüße mit lastverteilenden Sockelplatten versehen, welche die Dachabdichtung nicht beschädigen? ACHTUNG: Zwischen Platte und Abdichtung ist zwingend eine Trennlage einzubringen, um den Angriff der Abdichtung durch Weichmacher zu verhindern!",
                groupId: "group-3",
            }
        ],
        groups: [
            { id: "group-3", name: "AUSFÜHRUNGSPRÜFUNG", leadTime: 1 }
        ]
    },
    {
        tradeId: 3,
        name: "Dachabdichtungsarbeiten Folie QUALITÄTSPRÜFUNG",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-7-group-4",
        checkpoints: [
            {
                id: 51,
                text: "AUFGABE: Regelmäßiges Prüfen der Dichtigkeit der Durchdringungen in dem obersten Geschoss (bei Regen) – insbesondere bei Dachdurchdringungen über späteren Abkofferungen / Wänden!",
                groupId: "group-4",
            },
            {
                id: 52,
                text: "Sind die sichtbaren Nähte fest verklebt? (Keine Löcher und kein austretendes Wasser beim Draufdrücken)",
                groupId: "group-4",
            },
            { id: 53, text: "Ist die Deckenfläche beim Begehen frei von spürbaren Hohlstellen?", groupId: "group-4" },
            { id: 54, text: "Wurde ein Nahtprüfprotokoll erstellt und übergeben?", groupId: "group-4" },
            {
                id: 55,
                text: "Wurden die Arbeitsbereiche von allen Werkstoffresten befreit und gereinigt?",
                groupId: "group-4",
            },
            {
                id: 56,
                text: "AUFGABE: Einholen der Materialnachweise als Rev.-Unterlagen (Folien, Dämmstoffe, Abläufe, Zulassungen etc.), Frist gemäß NU-Vertrag",
                groupId: "group-4",
            }
        ],
        groups: [
            { id: "group-4", name: "QUALITÄTSPRÜFUNG", leadTime: 1 }
        ]
    },
    {
        tradeId: 3,
        name: "Gewerkeprüfung Trockenbau-Decken GK KICKOFF",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-8-group-1",
        checkpoints: [
            {
                id: 1,
                text: "Sind alle aktuellen Ausführungspläne übergeben worden?",
                groupId: "group-1"
            },
            {
                id: 2,
                text: "Existieren ausreichende Planvorgaben zur Verlegung? (Deckenspiegel, Details, etc.) → Freigabe durch AG",
                groupId: "group-1"
            },
            {
                id: 3,
                text: "Wurde ein Termin für ein NU-Startgespräch abgestimmt? (für 1-2 Wochen vor Ausführung)",
                groupId: "group-1"
            },
            {
                id: 4,
                text: "Ist das Leistungssoll gemäß LV genau abgestimmt? (EP und / oder AP)",
                groupId: "group-1"
            },
            {
                id: 5,
                text: "Sind die einzubauenden Abhänge-Systeme und Dübelarten festgelegt? (z.B. Direkt-, Schnellabhänger, Nonius-System; s. auch Knauf Broschüre VT02.de) ACHTUNG: Je nach Art des Untergrundes müssen unterschiedliche Dübel verwendet werden!",
                groupId: "group-1"
            },
            {
                id: 6,
                text: "Ist die Eignung, Zulässigkeit und Vertragskonformität der zu verwendenden Baustoffe geprüft und mit entsprechenden Werkszeugnissen dokumentiert? (Kriterien z.B.: Brandschutz, Schallschutz, Feuchtbereiche, etc.)",
                groupId: "group-1"
            },
            {
                id: 7,
                text: "Stimmt die geplante Position der Unterkonstruktion mit der Anordnung der Lampen, Lüftungsgeräte und Revisionsöffnungen überein?",
                groupId: "group-1"
            },
            {
                id: 8,
                text: "Sind Zwischenschichten wie Dämmungen, Rieselschutz, Dampfsperren, etc. in Art und Form abgestimmt und festgelegt?",
                groupId: "group-1"
            },
            {
                id: 9,
                text: "Sind gegebenenfalls erforderliche Brandabschnitte definiert und festgelegt?",
                groupId: "group-1"
            },
            {
                id: 10,
                text: "Sind ggf. erforderliche Dehnfugen hinsichtlich Lage und Ausführung abgestimmt? (Anordnung alle 15 m, zzgl. Übernahme der Gebäudetrenn- & Dehnfugen!)",
                groupId: "group-1"
            },
            {
                id: 11,
                text: "Wurden die Anschlüsse an aufgehenden Bauteilen abgestimmt und deren Ausführungsweise definiert? (z.B. Montage von Trennstreifen (Trenn-Fix), Brandschutzanschlüsse, starrer / gleitender Anschluss, etc.)",
                groupId: "group-1"
            },
            {
                id: 12,
                text: "Ist die erforderliche Qualitätsstufe der Spachtelung abgestimmt?",
                groupId: "group-1"
            },
            {
                id: 13,
                text: "Ist sichergestellt, dass während der Spachtelarbeiten der GK-Platten eine Mindesttemperatur von ≥ + 10°C eingehalten wird?",
                groupId: "group-1"
            },
            {
                id: 14,
                text: "Sind die allgemeinen Fragen der BE geklärt? (Lager- & Bewegungsflächen, Wasser- & Stromentnahmestellen, …)",
                groupId: "group-1"
            },
            {
                id: 15,
                text: "Wurden die Messpunkte abgeklärt und übergeben? (Meterrisse OKFF, Geschosshöhen, Deckenhöhen, Achsen etc.)",
                groupId: "group-1"
            }
        ],
        groups: [
            {
                id: "group-1",
                name: "KICKOFF",
                leadTime: 2
            }
        ]
    },
    {
        tradeId: 3,
        name: "Gewerkeprüfung Trockenbau-Decken GK VORLEISTUNGSCHECK",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-8-group-2",
        checkpoints: [
            {
                id: 16,
                text: "Sind die Arbeitstakte mit den beteiligten Folgegewerken besprochen, gemeinsam festgelegt und dokumentiert? (z.B. Reihenfolge der Deckenmontage, Installationen von Elektro, Sanitär, Lüftung, Heizung; Schließen der Decken)",
                groupId: "group-2"
            },
            {
                id: 17,
                text: "HINWEIS: Aufgrund der steigenden Raumfeuchte bei der Einbringung des Estriches empfiehlt sich die Terminierung dessen vor der Erstellung der GK-Decken.",
                groupId: "group-2"
            },
            {
                id: 18,
                text: "Sind vor den Brandschutzklappen vorzusehende Revisions-Öffnungen ausreichend groß bemessen? → Freigabe mit NU HLS",
                groupId: "group-2"
            },
            {
                id: 19,
                text: "Ist der Estrich in dem betroffenen Bereich vollständig eingebracht und ausgehärtet (sofern zuvor terminiert, vgl. Punkt 17)?",
                groupId: "group-2"
            },
            {
                id: 20,
                text: "Ist die Grobinstallation der Elektro- und HLS-Leitungen in den Deckenbereichen abgeschlossen? → Freigabe der NUs",
                groupId: "group-2"
            },
            {
                id: 21,
                text: "Sind die Arbeitsbereiche sicher gegen Feuchtigkeit geschützt? (Tagwasser, Baufeuchte, Luftfeuchtigkeit)",
                groupId: "group-2"
            },
            {
                id: 22,
                text: "Sind die Vorleistungen durch den NU begutachtet und als mängelfrei bekundet worden? (Zustand der Oberflächen, Haftzugfestigkeit, etc.)",
                groupId: "group-2"
            },
            {
                id: 23,
                text: "Wurde die Luftfeuchtigkeit in den Arbeitsbereichen gemessen und dokumentiert? (Stichproben)",
                groupId: "group-2"
            },
            {
                id: 24,
                text: "Entspricht die Ausführung und Höhenlage der Unterkonstruktion den Vorgaben? (Höhenlage, Material, Profilstärken, Korrosionsschutz)",
                groupId: "group-2"
            },
            {
                id: 25,
                text: "Sind alle Abhängungen und Dübel ausreichend, sicher und fachgerecht ausgeführt? (Begutachtung der Befestigungsart, Befestigungsabstände (1-lagige Beplankung: 17 cm; bei 2-lagig: 50 cm (1.Lage) & 17 cm (2. Lage)), Sicherung gegen Aushängen; vgl. Punkt 5)",
                groupId: "group-2"
            },
            {
                id: 26,
                text: "Sind alle speziellen Abhängungen für Leuchten und andere Einbauteile maß- und fachgerecht eingebaut?",
                groupId: "group-2"
            },
            {
                id: 27,
                text: "Wurden die Randprofile (UD-Profile) ordnungsgemäß mit Schlagdübeln an den Wänden befestigt? (Abstände: bei tragender Funktion max. 1 m, nichttragend max. 62,5 cm) ACHTUNG: Bei Schallschutzanforderungen auf die Montage von Trennwandkitt o.ä. achten! (Schallentkopplung)",
                groupId: "group-2"
            },
            {
                id: 28,
                text: "Haben die Folgegewerke die Fertigstellung und den Abschluss ihrer Einbauleistungen erklärt? (Montagefreigabe, vgl. Punkt 16)",
                groupId: "group-2"
            },
            {
                id: 29,
                text: "Sind die Deckenhohlräume frei von Unrat und Materialresten? (Stichprobenhafte Sichtprüfung)",
                groupId: "group-2"
            }
        ],
        groups: [
            {
                id: "group-2",
                name: "VORLEISTUNGSCHECK",
                leadTime: 2
            }
        ]
    },
    {
        tradeId: 3,
        name: "Gewerkeprüfung Trockenbau-Decken GK AUSFÜHRUNGSPRÜFUNG",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-8-group-3",
        checkpoints: [
            {
                id: 30,
                text: "Wurden die Brandlasten (E-Leitung) nochmals kontrolliert? (ggf. Sichtprüfung mit ELT-Bauleitung)",
                groupId: "group-3"
            },
            {
                id: 31,
                    text: "Wurden die GK-Platten fachgerecht auf der Unterkonstruktion verschraubt und bei mehrlagigen Beplankungen die Lagen versetzt angeordnet? ACHTUNG: Keine Verschraubung in die Randwinkel, außer bei tragenden Anforderungen (z.B. aufgrund des Brandschutzes) (Versatz der Stirnkantenten: min. 40 cm; Längskanten: 62,5 cm)",
                groupId: "group-3"
            },
            {
                id: 32,
                    text: "Wurden sämtliche Dehn- & Bewegungsfugen fachgerecht ausgebildet? (vgl. Punkt 10)",
                groupId: "group-3"
            },
            {
                id: 33,
                    text: "Sind alle Anschlüsse gemäß den definierten Anforderungen aus Punkt 11 ausgeführt worden? HINWEIS: Zu Massivwänden ist zwingend die Montage von Trennstreifen (Trenn-Fix) zur unauffälligen Rissbildung notwendig! (vgl. Checkliste Trockenbau-Wände)",
                groupId: "group-3"
            },
            {
                id: 34,
                    text: "Wurden Werkstoffreste, Bauschutt, Unrat, etc. entsorgt und die Arbeitsbereiche gereinigt?",
                groupId: "group-3"
            }
        ],
        groups: [
            {
                id: "group-3",
                name: "AUSFÜHRUNGSPRÜFUNG",
                leadTime: 2
            }
        ]
    },
    {
        tradeId: 3,
        name: "Gewerkeprüfung Trockenbau-Decken GK QUALITÄTSPRÜFUNG",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-8-group-4",
        checkpoints: [
            {
                id: 35,
                text: "Sind Schürzen, Deckenversprünge, Leuchtengräben und dergleichen maß- und fachgerecht berücksichtigt bzw. eingebaut?",
                groupId: "group-4"
            },
            {
                id: 36,
                text: "Sind Anschnitte, Auschnitte und Aussparungen sauber und passgenau erstellt worden? (z.B. keine sichtbaren Löcher in der Deckenoberfläche)",
                groupId: "group-4"
            },
            {
                id: 37,
                text: "Wurden die Spachtelarbeiten sauber ausgeführt und die geforderte Qualitätsstufe erreicht?",
                groupId: "group-4"
            },
            {
                id: 38,
                text: "AUFGABE: Einholen der Materialnachweise als Rev.-Unterlagen (Dübel, Unterkonstruktion, GK-Platten, etc.), Frist gemäß NU-Vertrag",
                groupId: "group-4"
            }
        ],
        groups: [
            {
                id: "group-4",
                name: "QUALITÄTSPRÜFUNG",
                leadTime: 2
            }
        ]
    },
    {
        tradeId: 3,
        name: "Gewerkeprüfung Trockenbau-Decken MF/B KICKOFF",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-9-group-1",
        checkpoints: [
            {
                id: 1,
                text: "Sind alle aktuellen Ausführungspläne übergeben worden?",
                groupId: "group-1"
            },
            {
                id: 2,
                text: "Existieren ausreichende Planvorgaben zur Verlegung? (Deckenspiegel, Verlegerichtung Lamellen, Details, etc.) → Freigabe durch AG",
                groupId: "group-1"
            },
            {
                id: 3,
                text: "Wurde ein Termin für ein NU-Startgespräch abgestimmt? (für 1-2 Wochen vor Ausführung)",
                groupId: "group-1"
            },
            {
                id: 4,
                text: "Sind sämtliche Komponenten im Rahmen der Bemusterung durch den AG schriftlich zur Ausführung freigegeben? (MF-Platten, Blechlamellen, etc.)",
                groupId: "group-1"
            },
            {
                id: 5,
                text: "Ist das Leistungssoll gemäß LV genau abgestimmt? (EP und / oder AP)",
                groupId: "group-1"
            },
            {
                id: 6,
                text: "Sind die einzubauenden Abhänge-Systeme und Dübelarten festgelegt? (z.B. Direkt-, Schnellabhänger, Nonius-System; s. auch Broschüre Knauf Ceiling Solutions: Systemanwendungen ) ACHTUNG: Je nach Art des Untergrundes müssen unterschiedliche Dübel verwendet werden!",
                groupId: "group-1"
            },
            {
                id: 7,
                text: "Ist die Eignung, Zulässigkeit und Vertragskonformität der zu verwendenden Baustoffe geprüft und mit entsprechenden Werkszeugnissen dokumentiert? (Kriterien z.B.: Brandschutz, Schallschutz, Feuchtbereiche, etc.)",
                groupId: "group-1"
            },
            {
                id: 8,
                text: "Stimmt die geplante Position der Unterkonstruktion / des Plattenrasters mit der Anordnung der Lampen, Lüftungsgeräte und Revisionsöffnungen überein?",
                groupId: "group-1"
            },
            {
                id: 9,
                text: "Sind gegebenenfalls erforderliche Brandabschnitte definiert und festgelegt?",
                groupId: "group-1"
            },
            {
                id: 10,
                text: "Wurden die Abschlüsse zu aufgehenden Bauteilen abgestimmt und deren Ausführungsweise definiert? (z.B. Randwinkel)",
                groupId: "group-1"
            },
            {
                id: 11,
                text: "Ist die Vorhaltung von Ersatzplatten / -lamellen mit dem Bauherren abgestimmt?",
                groupId: "group-1"
            },
            {
                id: 12,
                text: "Sind die allgemeinen Fragen der BE geklärt? (Lager- & Bewegungsflächen, Wasser- & Stromentnahmestellen, …)",
                groupId: "group-1"
            },
            {
                id: 13,
                text: "Wurden die Messpunkte abgeklärt und übergeben? (Meterrisse OKFF, Geschosshöhen, Deckenhöhen, Achsen etc.)",
                groupId: "group-1"
            }
        ],
        groups: [
            {
                id: "group-1",
                name: "KICKOFF",
                leadTime: 2
            }
        ]
    },
    {
        tradeId: 3,
        name: "Gewerkeprüfung Trockenbau-Decken MF/B VORLEISTUNGSCHECK",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-9-group-2",
        checkpoints: [
            {
                id: 14,
                text: "Sind die Arbeitstakte mit den beteiligten Folgegewerken besprochen, gemeinsam festgelegt und dokumentiert? (z.B. Reihenfolge der Deckenmontage, Installationen von Elektro, Sanitär, Lüftung, Heizung; Schließen der Decken)",
                groupId: "group-2"
            },
            {
                id: 15,
                text: "HINWEIS: Aufgrund der steigenden Raumfeuchte bei der Einbringung des Estriches empfiehlt sich die Terminierung dessen vor der Erstellung der MF-Decken.",
                groupId: "group-2"
            },
            {
                id: 16,
                text: "Sind vor den Brandschutzklappen vorzusehende Revisions-Öffnungen ausreichend groß bemessen? → Freigabe mit NU HLS",
                groupId: "group-2"
            },
            {
                id: 17,
                text: "Ist der Estrich in dem betroffenen Bereich vollständig eingebracht und ausgehärtet (sofern zuvor terminiert, vgl. Punkt 15)?",
                groupId: "group-2"
            },
            {
                id: 18,
                text: "Sind die Malerarbeiten in den betroffenen Bereichen abgeschlossen?",
                groupId: "group-2"
            },
            {
                id: 19,
                text: "Ist die Grobinstallation der Elektro- und HLS-Leitungen in den Deckenbereichen abgeschlossen? → Freigabe der NUs",
                groupId: "group-2"
            },
            {
                id: 20,
                text: "Sind die Arbeitsbereiche sicher gegen Feuchtigkeit geschützt? (Tagwasser, Baufeuchte, Luftfeuchtigkeit)",
                groupId: "group-2"
            },
            {
                id: 21,
                text: "Sind die Vorleistungen durch den NU begutachtet und als mängelfrei bekundet worden? (Zustand der Oberflächen, Haftzugfestigkeit, etc.) HINWEIS: Die angrenzenden Wände sind auf ihre Ebeneheit zu prüfen, sodass bei der Montage der Randwinkel keine Wellen entstehen!",
                groupId: "group-2"
            },
            {
                id: 22,
                text: "Wurde die Luftfeuchtigkeit in den Arbeitsbereichen gemessen und dokumentiert? (Stichproben)",
                groupId: "group-2"
            },
            {
                id: 23,
                text: "Entspricht die Ausführung und Höhenlage der Unterkonstruktion den Vorgaben? (Höhenlage, Material, Profilstärken, Korrosionsschutz)",
                groupId: "group-2"
            },
            {
                id: 24,
                text: "Sind alle Abhängungen und Dübel ausreichend, sicher und fachgerecht ausgeführt? (Begutachtung der Befestigungsart, Befestigungsabstände (max. 17 cm), Sicherung gegen Aushängen; vgl. Punkt 6)",
                groupId: "group-2"
            },
            {
                id: 25,
                text: "Sind alle speziellen Abhängungen für Leuchten und andere Einbauteile maß- und fachgerecht eingebaut?",
                groupId: "group-2"
            },
            {
                id: 26,
                text: "Entspricht die Unterkonstruktion hinsichtlich der Rasterteilung den abgestimmten und festgelegten Vorgaben?",
                groupId: "group-2"
            },
            {
                id: 27,
                text: "Haben die Folgegewerke die Fertigstellung und den Abschluss ihrer Einbauleistungen erklärt? (Montagefreigabe, vgl. Punkt 14)",
                groupId: "group-2"
            },
            {
                id: 28,
                text: "Entsprechen die zu verlegenden MF-Platten / Blechlamellen der Bemusterung?",
                groupId: "group-2"
            },
            {
                id: 29,
                text: "Sind die Deckenhohlräume frei von Unrat und Materialresten? (Stichprobenhafte Sichtprüfung)",
                groupId: "group-2"
            }
        ],
        groups: [
            {
                id: "group-2",
                name: "VORLEISTUNGSCHECK",
                leadTime: 2
            }
        ]
    },
    {
        tradeId: 3,
        name: "Gewerkeprüfung Trockenbau-Decken MF/B AUSFÜHRUNGSPRÜFUNG",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-9-group-3",
        checkpoints: [
            {
                id: 30,
                text: "Wurden die Brandlasten (E-Leitung) nochmals kontrolliert? (ggf. Sichtprüfung mit ELT-Bauleitung)",
                groupId: "group-3"
            },
            {
                id: 31,
                text: "Sind in allen erforderlichen Bereichen Schutzprofile wie Eckschutzwinkel und Aufsteckprofile fachgerecht eingebaut?",
                groupId: "group-3"
            },
            {
                id: 32,
                text: "Wurden Werkstoffreste, Bauschutt, Unrat, etc. entsorgt und die Arbeitsbereiche gereinigt?",
                groupId: "group-3"
            }
        ],
        groups: [
            {
                id: "group-3",
                name: "AUSFÜHRUNGSPRÜFUNG",
                leadTime: 2
            }
        ]
    },
    {
        tradeId: 3,
        name: "Gewerkeprüfung Trockenbau-Decken MF/B QUALITÄTSPRÜFUNG",
        leadTime: 4,
        trailTime: 7,
        id: "UUID-9-group-4",
        checkpoints: [
            {
                id: 33,
                text: "Sind Schürzen, Deckenversprünge, Leuchtengräben und dergleichen maß- und fachgerecht berücksichtigt bzw. eingebaut?",
                groupId: "group-4"
            },
            {
                id: 34,
                text: "Bei Blechlamellen: Entspricht die Verlegerichtung den vertraglichen Vorgaben?",
                groupId: "group-4"
            },
            {
                id: 35,
                text: "Sind Anschnitte, Auschnitte und Aussparungen sauber und passgenau erstellt worden? (z.B. keine sichtbaren Löcher in der Deckenoberfläche, gleichmäßig geschnittene Randplatten)",
                groupId: "group-4"
            },
            {
                id: 36,
                text: "Sind die Deckenoberflächen frei von Finger-, Handabdrücken und sonstigen Verunreinigungen?",
                groupId: "group-4"
            },
            {
                id: 37,
                text: "AUFGABE: Einholen der Materialnachweise als Rev.-Unterlagen (Dübel, Unterkonstruktion, MF-Platten, etc.), Frist gemäß NU-Vertrag",
                groupId: "group-4"
            }
        ],
        groups: [
            {
                id: "group-4",
                name: "QUALITÄTSPRÜFUNG",
                leadTime: 2
            }
        ]
    },
];

/* export const attachedChecklists: AttachedChecklist[] = [
    {
        id: 1,
        pid: 101,
        templateId: "template_001",
        name: "Site Inspection",
        tradeId: 10,
        leadTime: 5,
         trailTime: 7,
        label: "Safety",
        status: BadgeStatuses.open,
        checkpoints: [
            { id: 1, text: "Inspect safety gear", groupId: "group_1" },
            { id: 2, text: "Check scaffolding", groupId: "group_1" },
            { id: 3, text: "Review site hazards", groupId: "group_2" },
        ],
        groups: [
            { id: "group_1", name: "Initial Safety Checks", leadTime: 3 },
            { id: "group_2", name: "Site Conditions", leadTime: 4 },
        ],
    },
    {
        id: 2,
        pid: 102,
        templateId: "template_002",
        name: "Quality Assurance",
        tradeId: 20,
        leadTime: 3,
        trailTime: 3,
        label: "QA",
        status: BadgeStatuses.inProgress,
        checkpoints: [
            { id: 4, text: "Verify material quality", groupId: "group_3" },
            { id: 5, text: "Check workmanship", groupId: "group_3" },
            { id: 6, text: "Review compliance", groupId: "group_4" },
        ],
        groups: [
            { id: "group_3", name: "Material Checks", leadTime: 2 },
            { id: "group_4", name: "Compliance Review", leadTime: 5 },
        ],
    },
    {
        id: 3,
        pid: 103,
        templateId: "template_003",
        name: "Project Closeout",
        tradeId: 30,
        leadTime: 7,
        trailTime: 1,
        label: "Closure",
        status: BadgeStatuses.done,
        checkpoints: [
            { id: 7, text: "Finalize documentation", groupId: "group_5" },
            { id: 8, text: "Conduct final inspection", groupId: "group_5" },
            { id: 9, text: "Obtain client sign-off", groupId: "group_6" },
        ],
        groups: [
            { id: "group_5", name: "Final Steps", leadTime: 4 },
            { id: "group_6", name: "Client Approval", leadTime: 6 },
        ],
    },
    {
        id: 4,
        pid: 104,
        templateId: "template_004",
        name: "Environmental Check",
        tradeId: 40,
        leadTime: 6,
        trailTime: 4,
        label: "Environment",
        status: BadgeStatuses.overdue,
        checkpoints: [
            { id: 10, text: "Assess impact on environment", groupId: "group_7" },
            { id: 11, text: "Review waste disposal", groupId: "group_7" },
            { id: 12, text: "Ensure regulatory compliance", groupId: "group_8" },
        ],
        groups: [
            { id: "group_7", name: "Environmental Assessment", leadTime: 5 },
            { id: "group_8", name: "Regulatory Compliance", leadTime: 3 },
        ],
    },
];
 */
