import * as React from "react";

import { ICommandBarItemProps } from "@fluentui/react";

import { classNames as headerButtonClassNames } from "../../buttons/common";

import { Actions as BaseActions } from "../../common/actions";

const actionsProps: ICommandBarItemProps[] = [
    {
        key: "add",
        text: "Add",
        iconProps: { iconName: "Add", className: headerButtonClassNames.iconColor },
        onClick: () => console.log("Click on add button"),
    },
    {
        key: "Open in new window",
        text: "Open in new window",
        iconProps: {
            iconName: "OpenInNewWindow",
            className: headerButtonClassNames.iconColor,
        },
        onClick: () => console.warn("TODO: should Implement"),
    },
];

function Actions() {
    return <BaseActions items={actionsProps} />;
}

export { Actions };
