import { useMemo } from "react";

import { Trade } from "../types";
import { sortTrades } from "../utils/sort-trades";

type WorkforceColumnItemI = {
    trid: Trade["trid"];
    name: Trade["name"];
    color: Trade["color"];
    workforce: number;
};

export function useWorkforceColumns(tradesMap: { [key: string]: Trade } = {}) {
    return useMemo(() => {
        const trades = sortTrades(Object.values(tradesMap));

        const result: { [key: string]: WorkforceColumnItemI[] } = {};

        trades.forEach((trade) => {
            trade.a.forEach((workforce, column) => {
                // In case when workforce === 0
                if (!workforce) {
                    return;
                }

                result[column] = result[column] || [];

                result[column].push({
                    trid: trade.trid,
                    name: trade.name,
                    color: trade.color,
                    workforce,
                });
            });
        });

        return result;
    }, [tradesMap]);
}
