import * as React from "react";
import {
    IconButton,
    IContextualMenuProps,
    Label,
    Stack,
    mergeStyleSets,
    Text,
    StackItem,
    Image,
} from "@fluentui/react";
import { Icons } from "../../../RegisteredIcons";
import { useEffect, useState } from "react";
import { intl, LCMDContextCardStatus as State } from "lcmd2framework";
import { getCurrentLanguage } from "../../../utils/date/locale";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

type StateSelectorProps = {
    state?: State;
    taskName: string;
    onCardStateChange: (newState: State) => void;
    onCardEdit: () => void;
    onCardDelete: () => void;
    hiddenStateMenu?: boolean;
    date: number;
    isHaveAttachment: boolean;
};

const iconFontSize = "20px";
const styles = mergeStyleSets({
    taskName: {
        wordBreak: "break-word",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "-0.015em",
        color: "#565C60",
    },
    date: {
        fontSize: "12px",
        lineHeight: "16px",
        color: "rgb(86, 92, 96)",
        marginLeft: "4px",
    },
    dateWithAttachment: {
        display: "flex",
        columnGap: "4px",
    },
    attachment: {
        background: "black",
        borderRadius: "100px",
        padding: "3px",
    },
});

export function StateSelector({
    state,
    taskName,
    onCardStateChange,
    onCardEdit,
    onCardDelete,
    hiddenStateMenu,
    date,
    isHaveAttachment,
}: StateSelectorProps) {
    const [selectedState, setSelectedState] = useState<State>(state || 0);

    useEffect(() => {
        setSelectedState(state);
    }, [state]);

    const dropdownValues = ["Open", "In Progress","Done","In Approval"];
    function handleCardStateChange(ev, item) {
        if ("number" === typeof item.value) {
            WebAppTelemetryFactory.trackEvent("processUpdate", {
                where: "sidebar-dropdown",
                status: dropdownValues[item.value],
            });
        }
        setSelectedState(item.value);
        onCardStateChange(item.value);
    }

    const menuStateProps: IContextualMenuProps = {
        onItemClick: handleCardStateChange,
        items: [
            {
                key: "state.open",
                text: intl.get("TasksView.StateOpen"),
                iconProps: { iconName: Icons.Lcmd_State_Open, style: { color: "#999EA1", fontSize: iconFontSize } },
                value: State.OPEN,
            },
            {
                key: "state.in_progress",
                text: intl.get("TasksView.StateInProgress"),
                iconProps: {
                    iconName: Icons.Lcmd_State_In_Progress,
                    style: { color: "#009BD4", fontSize: iconFontSize },
                },
                value: State.IN_PROGRESS,
            },
            {
                key: "state.done",
                text: intl.get("TasksView.StateDone"),
                iconProps: { iconName: Icons.Lcmd_State_Done, style: { color: "#107C10", fontSize: iconFontSize } },
                value: State.DONE,
            },
            {
                key: "state.in_approval",
                text: intl.get("TasksView.StateInApproval"),
                iconProps: {
                    iconName: Icons.Lcmd_State_In_Approval,
                    style: { color: "#8C28CA", fontSize: iconFontSize },
                },
                value: State.IN_APPROVAL,
            },
            // {
            //     key: 'state.denied',
            //     text: intl.get('TasksView.StateDenied'),,
            //     iconProps: {iconName: Icons.Lcmd_State_Denied, style: {color: "#F8E005"}},
            //     value: 4
            // }
        ],
        directionalHintFixed: true,
    };
    const iconStyle = { color: "#565C60" };
    const menuMoreProps: IContextualMenuProps = React.useMemo(
        () => ({
            items: [
                {
                    key: "more.edit",
                    text: intl.get("TasksView.StateEdit"),
                    iconProps: { iconName: Icons.Lcmd_Edit, style: iconStyle },
                    onClick: onCardEdit,
                },
                {
                    key: "more.delete",
                    text: intl.get("TasksView.StateDelete"),
                    iconProps: { iconName: Icons.Lcmd_Delete, style: iconStyle },
                    onClick: onCardDelete,
                },
            ],
        }),
        [onCardEdit, onCardDelete],
    );
    return (
        <Stack horizontal style={{ padding: "6px 0" }} horizontalAlign={"space-between"}>
            <StackItem>
                <Stack horizontal>
                    <StackItem>
                        {hiddenStateMenu ? (
                            <span style={{ paddingLeft: 15 }}></span>
                        ) : (
                            <IconButton
                                styles={{ root: { fontSize: "16px" } }}
                                menuProps={menuStateProps}
                                iconProps={menuStateProps.items[selectedState || 0].iconProps}
                            />
                        )}
                    </StackItem>
                    <StackItem>
                        <Stack>
                            <StackItem>
                                <Label className={styles.taskName}>{taskName}</Label>
                            </StackItem>
                            {!hiddenStateMenu && (
                                <StackItem className={styles.dateWithAttachment}>
                                    <Text className={styles.date}>
                                        {new Date(date).toUTCFormatString(getCurrentLanguage(), {
                                            day: "2-digit",
                                            month: "long",
                                            year: "numeric",
                                        })}
                                    </Text>
                                    {isHaveAttachment && (
                                        <Image
                                            width={16}
                                            height={16}
                                            className={styles.attachment}
                                            src={"../../img/lcmd_trade_icons/attachment.svg"}
                                        />
                                    )}
                                </StackItem>
                            )}
                        </Stack>
                    </StackItem>
                </Stack>
            </StackItem>

            <StackItem>
                <IconButton
                    styles={{ menuIcon: { display: "none" } }}
                    iconProps={{ iconName: Icons.Lcmd_More, style: iconStyle }}
                    menuProps={menuMoreProps}
                />
            </StackItem>
        </Stack>
    );
}
