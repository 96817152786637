import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";

import { cn } from "@/lib/utils";
import { Eye, LucideProps, Settings } from "lucide-react";
import { Switch } from "@/components/ui/switch";
import { useEffect, useState } from "react";
import { SwitchProps } from "@radix-ui/react-switch";

type IconSwitch = React.ForwardRefExoticComponent<
    SwitchProps &
        React.RefAttributes<HTMLButtonElement> & {
            icons: {
                checked: React.FC<LucideProps>;
                unchecked: React.FC<LucideProps>;
            };
        }
>;

export const IconSwitch = React.forwardRef<React.ElementRef<IconSwitch>, React.ComponentPropsWithoutRef<IconSwitch>>(
    ({ className = "", icons, ...props }, ref) => {
        const CheckedIcon = icons?.checked || null;
        const UncheckedIcon = icons?.unchecked || null;
        return (
            <div className={"relative"}>
                <Switch
                    {...props}
                    className={cn("h-8 w-[57px]", ...className)}
                    onCheckedChange={(val) => {
                        props.onCheckedChange && props.onCheckedChange(val);
                    }}
                    thumbClassName={"data-[state=checked]:translate-x-7 h-6 w-6"}
                />
                {CheckedIcon && (
                    <CheckedIcon
                        className={`pointer-events-none absolute left-0.5 top-2 h-4 ${props.checked ? "opacity-0" : ""}`}
                    />
                )}
                {UncheckedIcon && (
                    <UncheckedIcon
                        className={`pointer-events-none absolute right-0.5 top-2 h-4 ${props.checked ? "" : "opacity-0"}`}
                    />
                )}
            </div>
        );
    },
);
IconSwitch.displayName = SwitchPrimitives.Root.displayName;
