import { useEffect, useState } from "react";
import { intl } from "lcmd2framework";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";

export function DefaultReasonCodeList({ onChange }: { onChange: (selectedCode: string, isChecked: boolean) => void }) {
    const [defaultReasonCodesTranslations, setDefaultReasonCodesTranslations] = useState([]);

    useEffect(() => {
        setDefaultReasonCodesTranslations(
            intl.get("ReasonCodesSettings.modal.defaultReasonCodes.reasonCodes") as any as string[],
        );
    }, []);

    return (
        <div className="my-2 flex flex-col gap-3">
            <Label>{intl.get("ReasonCodesSettings.modal.defaultReasonCodes.label")}</Label>
            <div className="h-[336px] w-[612px] overflow-y-auto">
                <div className="my-1 flex flex-col gap-3">
                    {defaultReasonCodesTranslations.map((defaultReasonCode, index) => (
                        <div key={index} className="flex h-full items-center gap-2">
                            <Checkbox
                                key={index}
                                onCheckedChange={(isChecked) => onChange(defaultReasonCode, Boolean(isChecked))}
                            />
                            <Label>{defaultReasonCode}</Label>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
