import * as React from "react";
import { intl } from "lcmd2framework";
import { Copy, Pencil, Trash2 } from "lucide-react";
import { MoreButtonDropdown } from "@/components/MoreButtonDropdown/MoreButtonDropdown";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

type AreaWrapper = {
    id: string;
    name: string;
    onEdit?: () => void;
    onClone?: () => void;
    onDelete?: () => void;
};

export function AreaWrapper({ id, name, onEdit, onDelete, onClone }: AreaWrapper) {
    const menuMoreProps = [
        {
            key: "lib.edit",
            text: intl.get("AreaWrapper.editButton.text"),
            icon: <Pencil size={16} color="#666666" />,
            onClick: onEdit,
        },
        {
            key: "lib.clone",
            text: intl.get("AreaWrapper.cloneButton.text"),
            icon: <Copy size={16} color="#666666" />,
            onClick: onClone,
        },
        {
            key: "lib.delete",
            text: intl.get("AreaWrapper.deleteButton.text"),
            icon: <Trash2 size={16} color="#666666" />,
            onClick: onDelete,
        },
    ];

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div className="relative m-2.5 mt-0 rounded border-[1px] border-solid border-gray-200">
                        <div data-drop-id={id} className="flex items-center justify-between bg-gray-200 px-2.5 py-1.5">
                            <div className="pointer-events-none overflow-hidden text-ellipsis whitespace-nowrap">
                                {name}
                            </div>
                            {menuMoreProps?.length && (
                                <MoreButtonDropdown menuItems={menuMoreProps.filter((el) => !!el.onClick)} />
                            )}
                        </div>
                    </div>
                </TooltipTrigger>
                <TooltipContent>
                    <p>{name}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
}
