import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { PanelHeader } from "../PanelHeader";

type Panel = {
    children?: ReactNode;
    footer?: ReactNode;
    isOpen: boolean;
    onClose?: () => void;
    className?: string;
    isTradesView?: boolean;
    withoutHeader?: boolean;
    tradesTab?: string;
    setTradesTab?: Dispatch<SetStateAction<string>>;
};

export const Panel = ({
    isOpen,
    onClose,
    children,
    isTradesView,
    footer,
    className,
    withoutHeader,
    tradesTab,
    setTradesTab,
}: Panel) => {
    return (
        <Card
            className={cn(
                "fixed bottom-5 z-[9999] flex h-[79%] w-[382px] transform flex-col bg-white shadow-lg transition-all",
                isOpen
                    ? "right-14 translate-x-0 opacity-100 transition-all"
                    : "right-0 translate-x-full opacity-0 transition-all",
                className,
            )}
        >
            {!withoutHeader && (
                <PanelHeader
                    tradesTab={tradesTab}
                    setTradesTab={setTradesTab}
                    isTradesView={isTradesView}
                    onClose={onClose}
                />
            )}
            <CardContent className="h-full overflow-x-hidden overflow-y-auto">{children}</CardContent>
            {footer && <CardFooter className="mt-auto flex justify-end p-4">{footer}</CardFooter>}
        </Card>
    );
};
