import * as React from "react";
import { SearchBox } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";
import { TaktzonesList } from "./TaktzonesList";
import { useEffect } from "react";

export function TaktzonesMain({ tz, initiallySelected, onSelected }) {
    const [collapsed, setCollapsed] = React.useState({});
    const [selected, setSelected] = React.useState(initiallySelected);
    const [search, setSearch] = React.useState(null);
    const onSearch = React.useMemo(
        () => (v: string) => {
            setSearch("string" === typeof v && v.length > 0 ? v : null);
        },
        [setSearch],
    );
    const onSearchChange = React.useMemo(
        () => (ev: any, v: string) => {
            setSearch("string" === typeof v && v.length > 0 ? v : null);
        },
        [setSearch],
    );

    useEffect(() => {
        onSelected(selected);
    }, [selected]);

    useEffect(() => {
        setSelected(initiallySelected);
    }, [initiallySelected]);

    return (
        <div style={{ padding: 8 }}>
            <SearchBox
                placeholder={intl.get("filter.tz.search")}
                value={search || ""}
                onChange={onSearchChange}
                onSearch={onSearch}
            />
            <TaktzonesList
                tz={tz}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                selected={selected}
                setSelected={setSelected}
                search={search}
            />
        </div>
    );
}
