import { WidgetHeader } from "../../layout/widget-header";
import { Actions } from "../common/actions";
import { Title } from "../../common/title";
import { TitleText } from "../../common/title-text";
import * as React from "react";
import { intl } from "../../../../legacy/GlobalHelperReact";

export function ReasonCodeChartHeader() {
    return (
        <WidgetHeader
            actions={<Actions />}
            title={
                <Title>
                    <TitleText text={intl.get("ReasonCodesSettings.list.title")} />
                </Title>
            }
        />
    );
}
