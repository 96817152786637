import * as React from "react";
import { Icon } from "@fluentui/react";
import { FilterRowTop } from "./FilterRowTop";
import { ProjectDateRange } from "../../filter.interface";
import { filterRowStyles } from "./filterRowStyles";

type DateFilterRow = {
    label: string;
    data: ProjectDateRange[];
    onModifyButtonClick: () => void;
};

export function DateFilterRow({ label, onModifyButtonClick, data }: DateFilterRow) {
    return (
        <div className={filterRowStyles.body}>
            <FilterRowTop onModifyButtonClick={onModifyButtonClick} label={label} badgeCount={null} />
            <div>
                <div className={filterRowStyles.button}>{new Date(data[0].startDate).toLocaleDateString()}</div>
                <Icon iconName={"IncreaseIndentArrow"} />
                <div className={filterRowStyles.button}>{new Date(data[0].endDate).toLocaleDateString()}</div>
            </div>
        </div>
    );
}
