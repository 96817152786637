import * as React from "react";
import { Modal } from "@fluentui/react";
import { RouterProvider, createMemoryRouter } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useLCMD } from "../../../app/LCMContext";
import { FilterData } from "./filter.interface";
import { useToDoModalFilterStore } from "../../../app/store/toDoModalFilterStore";
import routes from "./filterModalRoutes";
import { styles } from "./filterModalStyles";

export type FilterModal = {
    initFilterData: FilterData;
    applyFilter: (filterData: FilterData) => void;
};

export function FilterModal({ initFilterData, applyFilter }: FilterModal) {
    const {
        filterData,
        setFilterData,
        isModalVisible,
        modalRoute,
        projectDateRange,
        setProjectDateRange,
        trades,
        setTrades,
        areas,
        setAreas,
    } = useToDoModalFilterStore();

    const LCMD = useLCMD();

    useEffect(() => {
        setFilterData(initFilterData);
    }, []);

    useEffect(() => {
        applyFilter(filterData);
    }, [filterData]);

    useEffect(() => {
        return LCMD.loadFilterData((data) => {
            setAreas(data.wbs);
            setTrades(data.trades);
            setProjectDateRange({
                selectedMode: "everything",
                startDate: data.startDate ? data.startDate : null,
                endDate: data.startDate ? data.endDate : null,
            });
        });
    }, [setTrades, setAreas]);

    const router = useMemo(() => {
        return createMemoryRouter(routes, {
            initialEntries: [modalRoute === "/" ? modalRoute : `/${modalRoute}`],
            initialIndex: 1,
        });
    }, [trades, filterData, areas, projectDateRange, modalRoute]);

    return (
        <Modal
            isOpen={isModalVisible}
            isBlocking={false}
            styles={{
                scrollableContent: {
                    position: "relative",
                },
            }}
            containerClassName={styles.container}
        >
            <React.StrictMode>
                <RouterProvider router={router} />
            </React.StrictMode>
        </Modal>
    );
}
