import * as React from "react";
import { mergeStyleSets, IconButton } from "@fluentui/react";

type ModalAssetHeader = {
    onBackButtonChange?: () => void;
    onCloseButtonChange?: () => void;
    title: JSX.Element;
    rightSide?: JSX.Element;
    backgroundColor?: string;
};

export function ChangesHeader({
    onBackButtonChange = null,
    onCloseButtonChange = null,
    title,
    rightSide = null,
    backgroundColor = "#fff",
}: ModalAssetHeader) {
    const styles = mergeStyleSets({
        header: [
            {
                flex: "1 1 auto",
                display: "flex",
                alignItems: "center",
                padding: "12px 16px",
                justifyContent: "space-between",
                backgroundColor,
            },
        ],
        leftSide: [{}],
        rightSide: [{}],
        rightSideContent: [
            {
                borderRight: "1px solid #E1E4E5",
                display: "inline-block",
                paddingRight: "15px",
            },
        ],
        leftSideContent: [
            {
                display: "inline-block",
                fontSize: 16,
                fontWeight: 600,
            },
        ],
    });

    return (
        <div className={styles.header}>
            <div className={styles.leftSide}>
                {onBackButtonChange ? (
                    <IconButton
                        styles={{ root: { color: "#565C60" } }}
                        iconProps={{ iconName: "ChevronLeft" }}
                        onClick={onBackButtonChange}
                    />
                ) : null}
                {title ? <div className={styles.leftSideContent}>{title}</div> : null}
            </div>
            <div className={styles.rightSide}>
                {rightSide ? <div className={styles.rightSideContent}>{rightSide}</div> : null}
                {onCloseButtonChange ? (
                    <IconButton
                        styles={{ root: { color: "#565C60" } }}
                        iconProps={{ iconName: "ChromeClose" }}
                        onClick={onCloseButtonChange}
                    />
                ) : null}
            </div>
        </div>
    );
}
