import { LCMDContextTaskDetailsResultCardDetails } from "@/app/LCMDContextTypes";
import { assert, LCMDContextCardStatus } from "@/model/GlobalHelper";
import { Optional } from "@/app/types/Optional";
import { convertDaysToMilliseconds, convertMillisecondsToDays } from "@/utils/DateUtils";

export const getStatusForCards = (
    referenceDay: number,
    endDate,
    cards: Optional<LCMDContextTaskDetailsResultCardDetails, "atm" | "tid" | "_" | "name">[],
) => {
    const endDateInDays = convertMillisecondsToDays(endDate);
    const hasAttachment: boolean = cards.some((card) => card.atm);
    const { doneCards, lateCards } = cards.reduce(
        (
            ret: {
                doneCards: number;
                lateCards: number;
            },
            card,
        ) => {
            if (LCMDContextCardStatus.DONE === card.s) {
                ret.doneCards++;
            }
            if (LCMDContextCardStatus.DONE !== card.s && card.date < referenceDay) {
                ret.lateCards++;
            }
            return ret;
        },
        {
            doneCards: 0,
            lateCards: 0,
        },
    );
    let status = -1;
    if (doneCards === cards.length) {
        // done
        status = 1; // DONE
    } else {
        if (lateCards > 0) {
            if (endDateInDays <= referenceDay) {
                status = 4; // OVERDUE
            } else {
                status = 3; // LATE
            }
        } else {
            if (doneCards > 0) {
                // in progress
                status = 2; // IN_PROGRESS
            } else {
                if (endDateInDays <= referenceDay) {
                    status = 4; // OVERDUE
                } else {
                    status = 0; // OPEN
                }
            }
        }
    }
    assert(-1 !== status);
    const p = cards.length > 0 ? Math.round((doneCards / cards.length) * 100) : 0;
    return { p, s: status, atm: hasAttachment };
};

export function getMilestoneStatus(status, today, endDate) {
    if (status === LCMDContextCardStatus.DONE) {
        return { p: 100, s: 1, atm: false };
    }

    return { p: 0, s: endDate <= today ? 4 : 0, atm: false };
}
