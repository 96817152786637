import {
    DefaultButton,
    DetailsList,
    IColumn,
    IconButton,
    IContextualMenuItem,
    IContextualMenuProps,
    mergeStyleSets,
    PrimaryButton,
    SelectionMode,
    Spinner,
    Stack,
    StackItem,
    Text,
    Toggle,
} from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { copyAndSort } from "../../Utils";
import { ReasonCodeDeleteDialog } from "./reason-code-delete";
import { ReasonCodesModal } from "./reason-codes-modal";
import { useLCMD } from "../../../app/LCMContext";
import { ReasonCodesRolesModal } from "./ReasonCodesRolesModal";
import { useUserMap } from "../../hooks/userMap.hook";
import { User, UserMap } from "../../hooks/UserMapTypes";
import { LcmFacepile } from "../../common/LcmFacepile";
import { useUserRole } from "../../hooks/useUserRole.hook";

type SortState = {
    sortByColumn: IColumn;
};
export type ReasonCodeItem = {
    id?: string;
    name: string;
    deleted: boolean;
};

export function ReasonCodes() {
    const LCMD = useLCMD();
    const {
        isLoading: areReasonCodeSettingsLoading,
        data: reasonCodeSettings,
        saveObligatoryReasonCodeSetting,
        saveReasonCodes,
        saveReasonCodeSettings,
    } = LCMD.useReasonCodeSettings();

    const [items, setItems] = useState<ReasonCodeItem[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showReasonCodesRolesModal, setShowReasonCodesRolesModal] = useState(false);
    const [showReasonCodeDeleteDialog, setShowReasonCodeDeleteDialog] = useState(false);
    const [reasonCodeToChange, setReasonCodeToChange] = useState<ReasonCodeItem>(null);
    const [facepileUsers, setFacepileUsers] = useState<User[]>([]);
    const [sortState, setSortState] = useState<SortState>({ sortByColumn: null });
    const [columns, setColumns] = useState<IColumn[]>(getDefaultColumns());

    const isAllowed = useUserRole() === "admin";
    const userMap: UserMap = useUserMap();
    function saveSettings(items) {
        saveReasonCodes(items);
    }

    const saveShowModal = (isReasonCodeObligatory) => {
        saveObligatoryReasonCodeSetting(isReasonCodeObligatory);
    };

    useEffect(() => {
        if (!reasonCodeSettings || Object.keys(userMap).length == 0) {
            setFacepileUsers([]);
            return;
        }
        const usersFromIds = reasonCodeSettings.userList.map((userId) => userMap[userId]); // Convert user IDs to users
        const validUsers = usersFromIds.filter((user) => user !== undefined); // Filter out any undefined users
        const usersForFacepile: User[] = validUsers.map((user) => ({
            email: user.email,
            meta: user.meta,
        }));
        setFacepileUsers(usersForFacepile);
    }, [userMap, reasonCodeSettings]);

    function createEntries(reasonCodesToSave: ReasonCodeItem[]) {
        reasonCodesToSave.map((reasonCode) => (reasonCode.id = LCMD.generateId()));
        const entries = reasonCodeSettings?.reasonCodes.concat(reasonCodesToSave);
        saveSettings(entries);
    }

    function editEntry(ret: ReasonCodeItem) {
        reasonCodeSettings?.reasonCodes?.map((item) => {
            if (item.id == ret.id) {
                item.name = ret.name;
            }
        });
        saveSettings(reasonCodeSettings?.reasonCodes);
    }

    function deleteEntry(ret: ReasonCodeItem) {
        reasonCodeSettings.reasonCodes.some((entry, index) => {
            if (entry.id == ret.id) {
                entry.deleted = true;
                return true;
            }
        });

        saveSettings(reasonCodeSettings?.reasonCodes);
    }

    useEffect(() => {
        if (sortState.sortByColumn) {
            const newColumns: IColumn[] = [...columns];
            const currColumn: IColumn = newColumns.filter((currCol) => sortState.sortByColumn.key === currCol.key)[0];
            newColumns.forEach((newCol: IColumn) => {
                if (newCol === currColumn) {
                    currColumn.isSortedDescending = !currColumn.isSortedDescending;
                    currColumn.isSorted = true;
                } else {
                    newCol.isSorted = false;
                    newCol.isSortedDescending = true;
                }
            });

            setColumns(newColumns);
        }
    }, [sortState]);

    useEffect(() => {
        setColumns(getDefaultColumns());
    }, [isAllowed]);

    useEffect(() => {
        if (!reasonCodeSettings || !reasonCodeSettings?.reasonCodes || !columns || columns.length == 0) {
            return;
        }

        if (sortState.sortByColumn) {
            let res = [...reasonCodeSettings.reasonCodes];
            if (sortState.sortByColumn) {
                res = copyAndSort(res, sortState.sortByColumn.key, sortState.sortByColumn.isSortedDescending);
            }
            setItems(res);
        } else {
            setItems([...reasonCodeSettings.reasonCodes]);
        }
    }, [reasonCodeSettings?.reasonCodes, columns]);

    const classNames = mergeStyleSets({
        menuPropFont: {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "-1,5%",
            fontWeight: 400,
        },
        settingsTitle: {
            fontSize: 16,
            fontWeight: 600,
        },
        settingsSubtitle: {
            fontSize: 14,
            color: "#565C60",
        },
    });

    async function saveRoles(users, autoAddNewUsers) {
        try {
            await saveReasonCodeSettings({
                forceNewUsersToSetReasonCodes: autoAddNewUsers,
                userList: users,
            });
        } catch (e) {}
    }

    function onColumnClick(ev: React.MouseEvent<HTMLElement>, column: IColumn): void {
        setSortState({ sortByColumn: column });
    }

    function getDefaultColumns(): IColumn[] {
        return [
            {
                key: "name",
                name: intl.get("StabilityCriteria.SettingsList.NameColumn"),
                fieldName: "name",
                minWidth: 90,
                isResizable: true,
                onColumnClick: onColumnClick,
            },
            {
                key: "functions",
                name: "",
                fieldName: "functions",
                minWidth: 20,
                maxWidth: 20,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                onRender: (item, index, column) => {
                    return (
                        <span className={classNames.menuPropFont}>
                            <IconButton
                                styles={{
                                    root: {
                                        color: "#999EA1",
                                        height: "20px",
                                    },
                                    rootDisabled: {
                                        backgroundColor: "transparent",
                                    },
                                }}
                                menuProps={createMenuProps(item, index, column)}
                                iconProps={{ iconName: "MoreVertical" }}
                                onMenuClick={() => {}}
                                data-selection-disabled={true}
                                data-is-focusable={false}
                                split={false}
                                onRenderMenuIcon={() => null}
                            />
                        </span>
                    );
                },
            },
        ];
    }

    function createMenuProps(item, index, column): IContextualMenuProps {
        return {
            items: [
                {
                    disabled: !isAllowed,
                    key: "editMessage",
                    text: intl.get("ReasonCodesSettings.list.edit"),
                    iconProps: { iconName: "Edit" },
                    onClick: (
                        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
                        menuItem?: IContextualMenuItem,
                    ) => {
                        setReasonCodeToChange(item);
                        setShowEditModal(true);
                    },
                },
                {
                    disabled: !isAllowed,
                    key: "deleteEvent",
                    text: intl.get("ReasonCodesSettings.list.delete"),
                    iconProps: { iconName: "Delete" },
                    onClick: () => {
                        setReasonCodeToChange(item);
                        setShowReasonCodeDeleteDialog(true);
                    },
                },
            ],
        };
    }

    if (areReasonCodeSettingsLoading && !reasonCodeSettings) {
        return <Spinner />;
    }

    return (
        <Stack>
            <Stack
                styles={{ root: { position: "absolute", top: 16, right: 24 } }}
                horizontal
                horizontalAlign={"center"}
                verticalAlign={"center"}
                tokens={{ childrenGap: 16 }}
            >
                <StackItem>
                    <LcmFacepile
                        persona={facepileUsers}
                        tooltipQuestionMark={intl.get("ReasonCodesSettings.questionMarkTooltip")}
                        onClickPersona={() => setShowReasonCodesRolesModal(true)}
                        onClickQuestionMark={() => setShowReasonCodesRolesModal(true)}
                    />
                </StackItem>
                <StackItem>
                    <DefaultButton
                        disabled={!isAllowed}
                        onClick={() => {
                            setShowReasonCodesRolesModal(true);
                        }}
                    >
                        {intl.get("ReasonCodesSettings.defineRolesButton")}
                    </DefaultButton>
                </StackItem>
            </Stack>
            <Stack horizontal horizontalAlign={"space-between"}>
                <StackItem>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <StackItem>
                            <Text className={classNames.settingsTitle}>
                                {intl.get("ReasonCodesSettings.list.title")}
                            </Text>
                        </StackItem>
                        <StackItem>
                            <Text className={classNames.settingsSubtitle}>
                                {intl.get("ReasonCodesSettings.list.subtitle")}
                            </Text>
                        </StackItem>
                    </Stack>
                </StackItem>
                <StackItem>
                    <PrimaryButton
                        disabled={!isAllowed}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => {
                            setShowCreateModal(true);
                        }}
                        text={intl.get("ReasonCodesSettings.newButton")}
                    />
                </StackItem>
            </Stack>
            {items.length > 0 && (
                <DetailsList
                    items={items.filter((item) => !item.deleted)}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                />
            )}
            <Stack>
                <StackItem style={{ margin: "20px 0" }}>
                    <Text className={classNames.settingsTitle}>{intl.get("ReasonCodesSettings.quickMode.title")}</Text>
                </StackItem>
                <Stack horizontal horizontalAlign={"space-between"}>
                    <StackItem align="center">
                        <Text className={classNames.settingsSubtitle}>
                            {intl.get("ReasonCodesSettings.quickMode.subtitle")}
                        </Text>
                    </StackItem>
                    <StackItem align="center">
                        <Toggle
                            checked={reasonCodeSettings?.isReasonCodeObligatory}
                            onChange={(ev, checked) => {
                                saveShowModal(checked);
                            }}
                        />
                    </StackItem>
                </Stack>
            </Stack>
            {showReasonCodeDeleteDialog && (
                <ReasonCodeDeleteDialog
                    reasonCode={reasonCodeToChange}
                    onClose={(itemToDelete) => {
                        deleteEntry(itemToDelete);
                        setShowReasonCodeDeleteDialog(false);
                    }}
                    onCancel={() => setShowReasonCodeDeleteDialog(false)}
                />
            )}
            <ReasonCodesModal
                onCancel={() => setShowCreateModal(false)}
                onClose={(itemsToSave: ReasonCodeItem[]) => {
                    createEntries(itemsToSave);
                    setShowCreateModal(false);
                }}
                showDialog={showCreateModal}
            />
            <ReasonCodesModal
                onCancel={() => {
                    setShowEditModal(false);
                }}
                onClose={(itemsToSave: ReasonCodeItem) => {
                    editEntry(itemsToSave);
                    setShowEditModal(false);
                }}
                showDialog={showEditModal}
                reasonCodeToEdit={reasonCodeToChange}
            />
            {showReasonCodesRolesModal && (
                <ReasonCodesRolesModal
                    open={showReasonCodesRolesModal}
                    initiallySelected={reasonCodeSettings.userList}
                    autoAddNewUsers={reasonCodeSettings.forceNewUsersToSetReasonCodes}
                    users={userMap}
                    onDismiss={() => setShowReasonCodesRolesModal(false)}
                    onOk={async ({ selectedUsers, autoAddNewUsers }) => {
                        await saveRoles(selectedUsers, autoAddNewUsers);
                        setShowReasonCodesRolesModal(false);
                    }}
                />
            )}
        </Stack>
    );
}
