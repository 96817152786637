import * as React from "react";

import { IStackTokens, Stack } from "@fluentui/react";
import { ReactNode } from "react";

export type WidgetHeaderProps = {
    title: ReactNode;
    actions?: ReactNode;
};

const tokenProps: IStackTokens = {
    padding: 0,
};

function WidgetHeader(props: WidgetHeaderProps) {
    const { actions, title } = props;

    return (
        <Stack tokens={tokenProps} horizontal verticalAlign="center" horizontalAlign="space-between">
            {title}
            {/*{actions}*/}
        </Stack>
    );
}

export { WidgetHeader };
