import { HashRouter as Router, Routes, Route } from "react-router-dom";

import { Paths } from "./types/routes";
import { HomeView } from "./components/home/Home";
import { Login } from "./components/login/Login";
import { Register } from "./components/register/Register";
import { ForgotPassword } from "./components/forgotPassword/ForgotPassword";
import { MsalProvider } from "@azure/msal-react";
import { BrowserCacheLocation, Configuration, PublicClientApplication } from "@azure/msal-browser";
import { ConfirmEmail } from "./components/confirmEmail/ConfirmEmail";
import { MainLayout } from "@/lcmd2loginV2/src/components/common/MainLayout/MainLayout";

export type LcmdLoginProps = {
    onLogin: (sub: string, authToken: string) => void;
};

// MSAL configuration
const configuration: Configuration = {
    auth: {
        // TODO: can this stay here or should it be a config value?
        clientId: "0ac8729c-5a18-4395-b4e9-a85d09e80daa",
        redirectUri: window.location.origin, // hack for issue https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4921
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
    },
};
// needs to be outside of a react component, so it won't re init
const pca = new PublicClientApplication(configuration);

export function LcmdLogin({ onLogin }: LcmdLoginProps) {
    return (
        <MsalProvider instance={pca}>
            <MainLayout>
                <Router>
                    <Routes>
                        <Route path={Paths.HOME} element={<HomeView onLogin={onLogin} />} />
                        <Route path={Paths.LOGIN} element={<Login onLogin={onLogin} />} />
                        <Route path={Paths.REGISTER} element={<Register />} />
                        <Route path={Paths.FORGOT_PASSWORD} element={<ForgotPassword onLogin={onLogin} />} />
                        <Route path={Paths.CONFIRM_EMAIL} element={<ConfirmEmail onLogin={onLogin} />} />
                    </Routes>
                </Router>
            </MainLayout>
        </MsalProvider>
    );
}
