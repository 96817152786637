import React from "react";
import { cn } from "@/lib/utils";
import { intl } from "@/legacy/GlobalHelperReact";
import { BadgeStatuses } from "@/model/GlobalHelper";
import { Badge } from "@/components/ui/badge";

const badgeProperties = {
    [BadgeStatuses.open]: {
        backgroundColor: "bg-purple-100",
        textColor: "text-purple-800",
    },
    [BadgeStatuses.inProgress]: {
        backgroundColor: "bg-blue-100",
        textColor: "text-blue-600",
    },
    [BadgeStatuses.done]: {
        backgroundColor: "bg-green-100",
        textColor: "text-green-600",
    },
    [BadgeStatuses.overdue]: {
        backgroundColor: "bg-red-100",
        textColor: "text-red-600",
    },
};

type StatusBadgeProps = {
    status: BadgeStatuses | string;
    styles?: any;
    icon?: React.ComponentType<{ className?: string }>;
};

export const StatusBadge = ({ status, icon, styles }: StatusBadgeProps) => {
    const { backgroundColor, textColor } = badgeProperties[status] || {};
    const Icon = icon;
    const statusText = badgeProperties[status] ? intl.get(`checklist.statuses.${status}`) : status;

    return (
        <Badge
            className={cn("pointer-events-none inline-flex items-center rounded py-0.5", backgroundColor, textColor)}
            style={styles}
        >
            {icon && <Icon className="mr-1 h-4 w-4" />}
            <span className="text-xs font-medium leading-6">{statusText}</span>
        </Badge>
    );
};
