import * as React from "react";
import { useCallback } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { LucideIcon } from "lucide-react";

type ItemProps = {
    type: string;
    iconName: LucideIcon;
    label: string;
    selected: boolean;
    onSelect: (type: string) => void;
    iconStyle?: React.CSSProperties;
    userflowId?: string;
};

function Item({ type, iconName: Icon, label, selected, onSelect, iconStyle, userflowId }: ItemProps) {
    const onClick = useCallback(() => {
        onSelect(type);
    }, [type]);

    const borderColor = selected ? "border-slate-950" : "border-gray-200";

    return (
        <Card
            className={`h-[88px] w-[88px] hover:cursor-pointer ${borderColor}`}
            onClick={onClick}
            data-userflow-id={userflowId}
        >
            <CardContent className={"px-3 pb-2 pt-4"}>
                <div className={"flex flex-col items-center gap-4"}>
                    <Icon />
                    <div className={"text-sm"}>{label}</div>
                </div>
            </CardContent>
        </Card>
    );
}

export { Item };
