import { mergeStyleSets, Text, Stack } from "@fluentui/react";
import * as React from "react";
type SettingsHeader = {
    headerText: string;
};

const styles = mergeStyleSets({
    headerDiv: {
        height: "56px",
        width: "100%",
        background: "#F8FAFA",
        border: "1px solid #E1E4E5",
    },
    headerText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "24px",
        paddingLeft: "22px",
    },
});

export function SettingsHeader({ headerText }: SettingsHeader) {
    if (typeof headerText !== "string") {
        throw new Error("headerText param needs to be type of string");
    }
    return (
        <Stack verticalAlign={"center"} className={styles.headerDiv}>
            <Text className={styles.headerText}>{headerText}</Text>
        </Stack>
    );
}
