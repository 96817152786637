import * as React from "react";
import { Dialog, DialogScreen } from "lcmd2framework";
import { useLCMD } from "../app/LCMContext";

function MainScreen({
    dialog,
    route: {
        params: { dialogId, personas },
    },
}) {
    const LCMD = useLCMD();
    React.useLayoutEffect(() => {
        dialog.setOptions({
            title: "Collaborators",
            onCancel: () => {
                LCMD.showDialog(dialogId, false);
            },
            onClose: () => {
                LCMD.showDialog(dialogId, false);
            },
        });
    }, []);
    return (
        <pre>
            {JSON.stringify(
                personas.map((persona) => ({ ...persona.data.session, email: persona.data.email })),
                null,
                4,
            )}
        </pre>
    );
}

export function CollaborationDialog(props: { dialogId: string }) {
    return (
        <Dialog
            initialScreen={(dialog) => {
                dialog.pushScreen("main", { ...props });
            }}
            isOpen={true}
        >
            <DialogScreen name="main" component={MainScreen} />
        </Dialog>
    );
}
