import * as React from "react";

import { Stack } from "@fluentui/react";

import { Workforce } from "./Workforce";
import {formatWorkforceOutput} from "@/model/GlobalHelper";

export type BodyProps = {
    workforce: { trid: string; color: number; name: string; workforce: number }[];
};

function Body({ workforce }: BodyProps) {
    return (
        <Stack>
            {workforce.map((trade) => {
                return <Workforce key={trade.trid} name={trade.name} workforce={formatWorkforceOutput(trade.workforce)} color={trade.color} />;
            })}
        </Stack>
    );
}

export { Body };
