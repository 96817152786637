export const today = Math.floor(Date.now() / (86400 * 1000)); // "epoch days"

const sidebarColImage = 200;

export const CONST = {
    titleHeight: 44,
    subtitleHeight: 44,
    rowHeaderWidth: 8 * 50,
    colHeaderFontSize: 12 * 2,
    rowHeaderFontSize: 12 * 2,
    colHeaderPx: 50 * 2,
    rowHeaderPx: 20,
    rowStripeHeight: 20 * 2,
    filesOverlayWidth: 360,
    //colPx: 250,
    //rowPx: 400,
    views: [
        /*
        {
            label: "year",
            colPx: 2,
            rowPx: 12,
            colHeader: 0,
            colHeaderHeight: 30+30,
            colHeaderHeight1: 30,
            colHeaderHeight2: 30,
        },
        {
            label: "6month",
            colPx: 4,
            rowPx: 22,
            colHeader: 0,
            colHeaderHeight: 30+30,
            colHeaderHeight1: 30,
            colHeaderHeight2: 30,
        },
        {
            label: "quarter",
            colPx: 10,
            rowPx: 10 * 5,
            colHeader: 0,
            colHeaderHeight: 30 + 30,
            colHeaderHeight1: 30,
            colHeaderHeight2: 30,
            today: today,
            status: false,
        },*/
        {
            label: "month",
            colPx: 20,
            milestoneCol: 20,
            rowPx: 10 * 5,
            colHeader: 0,
            colHeaderHeight: 30 + 30,
            colHeaderHeight1: 30,
            colHeaderHeight2: 30,
            today: today,
            status: false,
        },
        {
            label: "week",
            colPx: 56,
            milestoneCol: 20,
            rowPx: 56,
            colHeader: 1,
            colHeaderHeight: 30 + 30,
            colHeaderHeight1: 30,
            colHeaderHeight2: 30,
            today: today,
            status: false,
        } /*,
        {
            label: "day",
            colPx: 160,
            rowPx: 100,
            colHeaderHeight: 30+30,
            colHeaderHeight1: 30,
            colHeaderHeight2: 30,
            today: today,
            status: false,
            sidebarColImage: 400
        }*/,
    ],
    //colPx: 40, // 10*5
    //rowPx: 10*5,
    mobileWarningColor: "darkgoldenrod",
    thumbnailWidth: 200,
    thumbnailHeight: 200,
    sidebarColWidth: 25,
    sidebarColExtra: 150,
    menuCondensedWidth: 0, // 60,
    tabW: 30,
    filesSidebarWidth: 440,
    canvasZoomMin: 0.05,
    canvasZoomMax: 10,
    legacyCanvasSidebarWidth: 300,
    sidebarColImage,
    newSidebarWidth: 60,
};
