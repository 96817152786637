import { useEffect, useState, useMemo } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";

export function StackedDialog({ onInitialView, isOpen, onClose, width, height }: any) {
    const _width = width || 800;
    const _height = height || 480;
    const [_state, _setState] = useState({
        views: [],
    });
    const ctx = useMemo(
        () => ({
            pushView: (view: any) => {
                _setState((state) => {
                    const views = state.views.slice();
                    views.push(view);
                    return { ...state, views: views };
                });
            },
            popView: (cb?: (views) => void) => {
                _setState((state) => {
                    const views = state.views.slice();
                    views.pop();
                    if (cb) {
                        cb(views);
                    }
                    return { ...state, views: views };
                });
            },
            replaceView: (view: any) => {
                _setState((state) => {
                    const views = state.views.slice();
                    views[views.length - 1] = view;
                    return { ...state, views: views };
                });
            },
            close: (keepViews?: boolean) => {
                if (!keepViews) {
                    _setState((state) => {
                        return { ...state, views: [] };
                    });
                }
                onClose();
            },
            setState: (state) => {
                _setState((_state) => {
                    return { ..._state, ...state };
                });
            },
        }),
        [_setState],
    );

    useEffect(() => {
        if (0 === _state.views.length && onInitialView) {
            onInitialView(ctx, _state);
        }
    }, [onInitialView]);

    if (!_state.views.length) {
        return null;
    }

    return (
        <Dialog open={isOpen}>
            <DialogContent
                closable={false}
                className="max-w-full overflow-hidden pt-0"
                style={{
                    width: _width,
                    height: _height,
                }}
            >
                <div style={{ height: _height }} className="transition-left min-w-full duration-500 ease-linear">
                    {_state.views.map((view, i_view) => (
                        <div
                            key={i_view}
                            className="absolute flex max-w-full flex-col flex-nowrap items-stretch overflow-y-auto [overflow:overlay]"
                            style={{
                                width: _width,
                                height: _height,
                                left: "calc(100% * " + i_view + ")",
                            }}
                        >
                            {view}
                        </div>
                    ))}
                </div>
            </DialogContent>
        </Dialog>
    );
}
