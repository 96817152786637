import React, { useContext, useEffect, useState } from "react";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { IconButton, mergeStyleSets, Stack, StackItem } from "@fluentui/react";
import { Icons } from "../../../RegisteredIcons";
import { useLCMD } from "../../../../app/LCMContext";
import { CPContext } from "../../Shared/CPContext";
import { intl } from "@/legacy/GlobalHelperReact";
import { LCMDTag, TagTheme } from "../../../common/LCMDTag";
import { HistoryChangeEvent } from "../../../../app/interface";
import { WebAppTelemetryFactory } from "../../../../app/services/WebAppTelemetry.service";

type HistoryReasons = {
    options: IChoiceGroupOption[];
    processId: number;
    opId: number;
};

export function HistoryReasons({ opId, processId, options }: HistoryReasons) {
    const LCMD = useLCMD();
    const [selectedKey, setSelectedKey] = useState<string | undefined>();
    const [attachedReasonCode, setAttachedReasonCode] = useState<any | undefined>();
    const { changes } = useContext(CPContext).state;

    const styles = mergeStyleSets({
        iconButtons: { color: "#565C60", height: "20px", width: "20px" },
    });

    useEffect(() => {
        LCMD.getAttachedReasonCode(processId, opId).then((attachedReasonCode) => {
            console.log({ attachedReasonCode });
            if (!attachedReasonCode) {
                setAttachedReasonCode(null);
                setSelectedKey(undefined);
                return;
            }
            setSelectedKey(attachedReasonCode.rcid);
            setAttachedReasonCode(attachedReasonCode);
        });
    }, [changes._]);

    const handleReasonCodeChange = async (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ) => {
        if (!option.key) {
            console.warn("reasonCodeId missing !");
            return;
        }

        WebAppTelemetryFactory.trackEvent("reason-code-assigned-in-sidebar");

        if (attachedReasonCode && attachedReasonCode.rcid === option?.key) {
            setSelectedKey(option?.key);
            return;
        }

        if (attachedReasonCode && attachedReasonCode.rcid) {
            await LCMD.updateAttachedReasonCode({
                attachedReasonCodeId: attachedReasonCode.id,
                pid: processId,
                reasonCodeId: option?.key,
                opId: opId,
            });
            setAttachedReasonCode({ ...attachedReasonCode, rcid: option?.key });
        } else {
            await LCMD.attachReasonCodeToProcess({ pid: processId, reasonCodeId: option?.key, opId: opId });
            const newAttachedReasonCode = await LCMD.getAttachedReasonCode(processId, opId);
            setAttachedReasonCode({ ...newAttachedReasonCode });
        }

        setSelectedKey(option?.key);
    };

    const handleDeleteAttachedReasonCode = async () => {
        await LCMD.deleteAttachedReasonCode({
            attachedReasonCodeId: attachedReasonCode.id,
            processId: attachedReasonCode.pid,
        });
        setAttachedReasonCode(null);
        setSelectedKey(undefined);
    };

    return (
        <>
            {selectedKey ? (
                <div>
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                        style={{ margin: "22px 0 11px 0", height: "20px" }}
                    >
                        <Stack.Item style={{ fontWeight: 600 }}>{intl.get("ReasonCodes.ReasonHeadline")}</Stack.Item>
                        <Stack.Item>
                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                <StackItem>
                                    <IconButton
                                        onClick={() => {
                                            setSelectedKey(undefined);
                                        }}
                                        iconProps={{ iconName: Icons.Lcmd_Edit_Filled }}
                                        className={styles.iconButtons}
                                    />
                                </StackItem>
                                <StackItem>
                                    <IconButton
                                        onClick={handleDeleteAttachedReasonCode}
                                        iconProps={{ iconName: Icons.Lcmd_Delete }}
                                        className={styles.iconButtons}
                                    />
                                </StackItem>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal horizontalAlign="space-between">
                        {options.map((o) =>
                            o.key === selectedKey ? <LCMDTag value={o.text} theme={TagTheme.GREY} /> : "",
                        )}
                    </Stack>
                </div>
            ) : (
                <div style={{ background: "#F1F3F3", marginTop: 20, padding: 16, borderRadius: 4 }}>
                    <Stack>
                        <ChoiceGroup
                            selectedKey={attachedReasonCode?.rcid}
                            options={options}
                            onChange={handleReasonCodeChange}
                            label={intl.get("ReasonCodes.ReasonHeadline")}
                            styles={{ label: { paddingTop: 0 } }}
                        />
                    </Stack>
                </div>
            )}
        </>
    );
}

// @refactor: fetch attachedResoncodes should be only live once and not in every function
export function HistoryMultiReasons({
    changes,
    options,
}: {
    changes: HistoryChangeEvent[];
    options: IChoiceGroupOption[];
}) {
    const LCMD = useLCMD();
    const [selectedKey, setSelectedKey] = useState<string | undefined>();
    const [attachedReasonCodes, setAttachedReasonCodes] = useState<any[]>([]);
    const [allProcessesHasSameAttachedResonCode, setAllProcessesHasSameAttachedResonCode] = useState(true);
    const {
        changes: { _: currentTimestamp },
    } = useContext(CPContext).state;
    const validChanges = changes.filter((change) => !change.rejected);

    const styles = mergeStyleSets({
        iconButtons: { color: "#565C60", height: "20px", width: "20px" },
    });

    // @refactor: create own hook
    useEffect(() => {
        let mounted = true;

        (async () => {
            await fetchAttachedReasonCodes();
        })();

        async function fetchAttachedReasonCodes() {
            const reasonCodePromises = [];

            validChanges.forEach((validChange) => {
                const reasonCodePromise = LCMD.getAttachedReasonCode(validChange.id, validChange._);
                reasonCodePromises.push(reasonCodePromise);
            });

            try {
                const attachedReasonCodes = await Promise.all(reasonCodePromises);

                if (!mounted) {
                    return;
                }

                setAttachedReasonCodes([...attachedReasonCodes.filter(Boolean)]);
                setSelectedKey(attachedReasonCodes?.at(0)?.rcid);
            } catch (e) {
                // @todo: send message to telemetry service
            }
        }

        return () => {
            mounted = false;
        };
    }, [currentTimestamp, changes]);

    // useEffect(() => {
    //     if(attachedReasonCodes.length == 0){
    //         return;
    //     }
    //
    //
    //     const firstRcId = attachedReasonCodes.at(0).rcid;
    //     const allEquals = attachedReasonCodes.every((attachedReasonCode) => {
    //         return attachedReasonCode.rcid == firstRcId;
    //     })
    //
    //     setAllProcessesHasSameAttachedResonCode(allEquals);
    //
    // }, [attachedReasonCodes])

    const handleDeleteAttachedReasonCode = async () => {
        for (const attachedReasonCode of attachedReasonCodes) {
            await LCMD.deleteAttachedReasonCode({
                attachedReasonCodeId: attachedReasonCode.id,
                processId: attachedReasonCode.pid,
            });
        }

        setAttachedReasonCodes([]);
        setSelectedKey(undefined);
    };

    const handleReasonCodeChange = async (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ) => {
        if (!option.key) {
            console.warn("reasonCodeId missing !");
            return;
        }

        if (
            attachedReasonCodes.length > 0 &&
            attachedReasonCodes.at(-1).rcid === option?.key &&
            allProcessesHasSameAttachedResonCode
        ) {
            setSelectedKey(option?.key);
            return;
        }

        const reasonCodeToProcessIdCache = new Map<number, any>();

        attachedReasonCodes.forEach((attachedReasonCode) => {
            reasonCodeToProcessIdCache.set(attachedReasonCode.pid, attachedReasonCode);
        });

        for (const validChange of validChanges) {
            const processId = validChange.id;
            const opId = validChange._;
            if (reasonCodeToProcessIdCache.has(processId)) {
                // upadte reasonCode
                const attachedReasonCode = reasonCodeToProcessIdCache.get(processId);
                if (option?.key === attachedReasonCode.rcid) {
                    continue;
                }

                await LCMD.updateAttachedReasonCode({
                    attachedReasonCodeId: attachedReasonCode.id,
                    pid: processId,
                    reasonCodeId: option?.key,
                    opId,
                });
            } else {
                // attach a new one
                await LCMD.attachReasonCodeToProcess({ pid: processId, reasonCodeId: option?.key, opId: opId });
                const newAttachedReasonCode = await LCMD.getAttachedReasonCode(processId, opId);
            }
        }

        await fetchAttachedReasonCodes();

        async function fetchAttachedReasonCodes() {
            const reasonCodePromises = [];

            validChanges.forEach((validChange) => {
                const reasonCodePromise = LCMD.getAttachedReasonCode(validChange.id, validChange._);
                reasonCodePromises.push(reasonCodePromise);
            });

            try {
                const attachedReasonCodes = await Promise.all(reasonCodePromises);

                setAttachedReasonCodes([...attachedReasonCodes.filter(Boolean)]);
                setSelectedKey(attachedReasonCodes?.at(0)?.rcid);
            } catch (e) {
                // @todo: send message to telemetry service
            }
        }

        // if(attachedReasonCode && attachedReasonCode.rcid){
        //     await LCMD.updateAttachedReasonCode({attachedReasonCodeId: attachedReasonCode.id, pid: processId, reasonCodeId: option?.key,opId: opId});
        //     setAttachedReasonCode({...attachedReasonCode, rcid: option?.key})
        // } else {
        //     await LCMD.attachReasonCodeToProcess({pid: processId, reasonCodeId: option?.key,opId: opId});
        //     const newAttachedReasonCode = await LCMD.getAttachedReasonCode(processId, opId);
        //     setAttachedReasonCode({...newAttachedReasonCode});
        // }

        setSelectedKey(option?.key);
    };

    return (
        <>
            {selectedKey ? (
                <div>
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                        style={{ margin: "22px 0 11px 0", height: "20px" }}
                    >
                        <Stack.Item style={{ fontWeight: 600 }}>{intl.get("ReasonCodes.ReasonHeadline")}</Stack.Item>
                        <Stack.Item>
                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                <StackItem>
                                    <IconButton
                                        onClick={() => {
                                            setSelectedKey(undefined);
                                        }}
                                        iconProps={{ iconName: Icons.Lcmd_Edit_Filled }}
                                        className={styles.iconButtons}
                                    />
                                </StackItem>
                                <StackItem>
                                    <IconButton
                                        onClick={handleDeleteAttachedReasonCode}
                                        iconProps={{ iconName: Icons.Lcmd_Delete }}
                                        className={styles.iconButtons}
                                    />
                                </StackItem>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal horizontalAlign="space-between">
                        {!allProcessesHasSameAttachedResonCode ? (
                            <LCMDTag value={intl.get("DetailsView.mixed")} theme={TagTheme.GREY} />
                        ) : (
                            options.map((o) =>
                                o.key === selectedKey ? <LCMDTag value={o.text} theme={TagTheme.GREY} /> : "",
                            )
                        )}
                    </Stack>
                </div>
            ) : (
                <div style={{ background: "#F1F3F3", marginTop: 20, padding: 16, borderRadius: 4 }}>
                    <Stack>
                        <ChoiceGroup
                            selectedKey={attachedReasonCodes?.at(-1)?.rcid}
                            options={options}
                            onChange={handleReasonCodeChange}
                            label={intl.get("ReasonCodes.ReasonHeadline")}
                            styles={{ label: { paddingTop: 0 } }}
                        />
                    </Stack>
                </div>
            )}
        </>
    );
}
