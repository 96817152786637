﻿import * as React from "react";
import { Fragment } from "react";
import { CONST } from "../../settings";
import { CanvasTaktzoneHost } from "./CanvasTaktzoneHost";
import { CanvasCommonProps, CanvasMeta, CanvasViewPort } from "./Canvas";
import type { CanvasViewConst } from "../../../model/DataModel";
import { WhiteboardSubHeader } from "../../../components/whiteboard/WhiteboardSubHeaderV2";

export type LabelToggleType = (typeof LabelToggleValues)[keyof typeof LabelToggleValues];

export const LabelToggleValues = {
    WORKSHOP: "workshop",
    PLAN: "plan",
};

type CanvasHostContainerProps = CanvasCommonProps & {
    hidden?: boolean;
    viewConst: CanvasViewConst;
    mode: "canvas" | "wb" | "tz";
    sidebar: {
        selected: string;
    };
    wb: any;
    view: CanvasViewPort;
    meta: CanvasMeta;
    width: number;
    height: number;
    onCanvasTaktzoneClicked: (taktzone: number) => void;
    loaded: boolean;
};

type CanvasHostContainerState = {};

export class CanvasHostContainer extends React.Component<CanvasHostContainerProps, CanvasHostContainerState> {
    state = {};
    subHeaderCtx = {
        undo: function (this: CanvasHostContainer) {
            this.props.worker.postMessage(["undo", { target: this.props.sidebar.selected, wb: this.props.wb.active }]);
        }.bind(this),
        redo: function (this: CanvasHostContainer) {
            this.props.worker.postMessage(["redo", { target: this.props.sidebar.selected, wb: this.props.wb.active }]);
        }.bind(this),
        toggleGPAGrid: function (this: CanvasHostContainer, forceValue?: boolean) {
            this.props.worker.postMessage([
                "view",
                "grid",
                true === forceValue || false === forceValue ? forceValue : this.props.viewConst?.grid > 1 ? 1 : 5,
            ]);
        }.bind(this),
        closeWhiteboard: function (this: CanvasHostContainer) {
            const isActive = "string" === typeof this.props.wb?.active;
            if (isActive) {
                this.props.worker.postMessage(["wb", "close", { active: this.props.wb.active }]);
            }
        }.bind(this),
        showWhiteboardDialog: function (this: CanvasHostContainer) {
            const isActive = "string" === typeof this.props.wb?.active;
            if (isActive) {
                const wb = this.props.wb.items.find((item) => item.id === this.props.wb.active);
                if (wb) {
                    this.props.worker.dispatchMessage(["toggle", "dialog.whiteboard.properties", { ...wb }]);
                }
            }
        }.bind(this),
    };
    onDragover = function (this: CanvasHostContainer, event: any) {
        event.preventDefault();
    }.bind(this);
    onDrop = function (this: CanvasHostContainer, event: any) {
        try {
            const dt = event.dataTransfer;
            if (dt && dt.items && dt.items.length > 0) {
                if (dt.files && dt.files.length > 0) {
                    const a = [];
                    for (let i_file = 0; i_file < dt.files.length; i_file++) {
                        const file = dt.files[i_file];
                        a.push(
                            new Promise((resolve: (resp) => void, reject: (error) => void) => {
                                file.arrayBuffer()
                                    .then((buffer) => {
                                        resolve({
                                            name: file.name,
                                            type: file.type,
                                            lastModified: file.lastModified,
                                            buffer,
                                        });
                                    })
                                    .catch(reject);
                            }),
                        );
                    }
                    Promise.all(a)
                        .then((files) => {
                            const buffers = files.reduce((ret, file) => {
                                ret.push(file.buffer);
                                return ret;
                            }, []);
                            this.props.worker.postMessage(
                                [
                                    "wb",
                                    "import",
                                    {
                                        files,
                                    },
                                ],
                                buffers,
                            );
                        })
                        .catch((e) => {
                            throw e;
                        });
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            event.preventDefault();
        }
    }.bind(this);
    private _subHeaderProps = {
        active: false,
        activeId: null as string,
        wb: null as { id: string; name?: string },
        gpaMode: LabelToggleValues.WORKSHOP as LabelToggleType,
    };

    private onCanvasTaktzoneClicked = (taktzones: number[]) => {
        if (!this.props?.onCanvasTaktzoneClicked) {
            console.warn("function onCanvasTaktzoneClicked is not defined");
            return;
        }
        if (!Array.isArray(taktzones) || taktzones.length <= 0) {
            return;
        }

        this.props.onCanvasTaktzoneClicked(taktzones[taktzones.length - 1]);
    };

    componentDidMount(this: CanvasHostContainer) {
        this.props.worker.dispatchMessage(["framework", "worker", true]);
    }

    componentWillUnmount(this: CanvasHostContainer) {
        this.props.worker.dispatchMessage(["framework", "worker", false]);
    }

    render(this: CanvasHostContainer) {
        const isProject = "project" === this.props.sidebar.selected;
        const isActive = "string" == typeof this.props.wb?.active;
        const isTZ = "taktzone" === this.props.sidebar.selected;
        const isWelcome = this.props.worker.config?.whiteboard?.showWelcome;
        const activeId = isActive ? this.props.wb.active : null;
        const gpaMode = this.props.viewConst?.grid > 1 ? LabelToggleValues.WORKSHOP : LabelToggleValues.PLAN;

        if (
            // eslint-disable-next-line no-constant-condition
            activeId !== this._subHeaderProps.activeId ||
            gpaMode !== this._subHeaderProps.gpaMode ||
            true /* TODO add ts to WB for this cache to work */
        ) {
            this._subHeaderProps.active = isActive;
            this._subHeaderProps.activeId = activeId;
            this._subHeaderProps.wb = isActive
                ? this.props.wb.items.find((item) => item.id === this.props.wb.active)
                : undefined;
            this._subHeaderProps.gpaMode = gpaMode;
        }
        console.log('host container props', this.props.loaded, this.props)

        return this.props.meta /*&& this.props.worker.config.whiteboard*/ ? (
            <div
                onDrop={this.onDrop}
                onDragOver={this.onDragover}
                style={{
                    display: this.props.hidden ? "none" : "flex",
                    boxSizing: "border-box",
                    position: "fixed",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    paddingTop: CONST.titleHeight,
                }}
            >
                {"canvas" === this.props.mode && isProject && this.props.worker.config.CanvasProjectHost ? (
                    <Fragment>
                        <this.props.worker.config.CanvasProjectHost
                            {...this.props}
                            key="canvas"
                            onTaktzoneClicked={this.onCanvasTaktzoneClicked}
                            insetTop={CONST.titleHeight /*+CONST.subtitleHeight*/}
                            active={this.props.wb.active}
                            mode="canvas"
                        />
                        {/*this.state.showReasonCodePopUp ? <ReasonDisturbance undo={()=>this.props.worker.postMessage(["undo", {}])} close={() =>this.setState({showReasonCodePopUp: false})} show={state.showReasonCodePopUp}/> : null,*/}
                    </Fragment>
                ) : null}
                {"tz" === this.props.mode && isTZ ? (
                    <CanvasTaktzoneHost
                        {...this.props}
                        key="tz"
                        insetTop={CONST.titleHeight /*+CONST.subtitleHeight*/}
                        active={this.props.wb.active}
                        mode="tz"
                    />
                ) : null}
                {"wb" === this.props.mode && !isProject && !isActive && isWelcome ? (
                    <this.props.worker.config.whiteboard.showWelcome whiteboards={this.props.wb?.items || []} />
                ) : null}
                {"wb" === this.props.mode && !isProject && isActive && this.props.worker.config.CanvasWhiteboardHost ? (
                    <this.props.worker.config.CanvasWhiteboardHost
                        {...this.props}
                        key={this.props.wb.active}
                        insetTop={CONST.titleHeight /*+CONST.subtitleHeight*/}
                        active={this.props.wb.active}
                        mode="wb"
                    />
                ) : null}
                {"wb" === this.props.mode && !isProject && isActive ? (
                    <WhiteboardSubHeader {...this._subHeaderProps} ctx={this.subHeaderCtx}></WhiteboardSubHeader>
                ) : null}
            </div>
        ) : null;
    }
}
