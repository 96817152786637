import * as React from "react";

import { Stack, IContextualMenuItem } from "@fluentui/react";

import { AdditionActions } from "../buttons/addition-actions";
import { Share } from "../buttons/share";

export type ActionsProps = {
    items?: IContextualMenuItem[];
    onShareClick?: () => void;
};

function Actions(props: ActionsProps) {
    const { onShareClick, items } = props;

    return (
        <Stack horizontal>
            <Share onClick={onShareClick} />
            {items.length ? <AdditionActions items={items} /> : null}
        </Stack>
    );
}

Actions.defaultProps = {
    items: [],
};

export { Actions };
