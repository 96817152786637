import { useLCMD } from "@/app/LCMContext";
import { SERVICES } from "@/model/services";
import { useState } from "react";

export function useUpdateRole() {
    const LCMD = useLCMD();
    const [hookResult, setHookResult] = useState({
        isLoading: false,
        isError: false,
        error: null,
        data: null,
    });

    async function updateRole(projectId: string, userId: string, role: string) {
        setHookResult({ isLoading: true, isError: false, error: null, data: null });
        try {
            const response = await fetch(`${SERVICES.REST_API}/projects/${projectId}/subs/${userId}/role`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${LCMD.worker.auth.auth_token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ role }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setHookResult({ isLoading: false, isError: false, error: null, data: result });
        } catch (error) {
            setHookResult({ isLoading: false, isError: true, error, data: null });
        }
    }

    return { ...hookResult, updateRole };
}
