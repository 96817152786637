import { Stack } from "@fluentui/react";

export enum TagTheme {
    LIGHTBLUE = "light blue",
    DARKBLUE = "dark blue",
    GREY = "grey",
    LIGHTPURPLE = "light purple",
    DARKPURPLE = "dark purple",
}

const styleMap = new Map();

styleMap.set(TagTheme.LIGHTBLUE, {
    backgroundColor: "#DEF0F9",
    color: "#005A78",
});

styleMap.set(TagTheme.DARKBLUE, {
    backgroundColor: "#009BD4",
    color: "#FFFFFF",
});
styleMap.set(TagTheme.GREY, {
    backgroundColor: "#F1F3F3",
    color: "#565C60",
});

styleMap.set(TagTheme.LIGHTPURPLE, {
    backgroundColor: "#EEDFF7",
    color: "#520980",
});
styleMap.set(TagTheme.DARKPURPLE, {
    backgroundColor: "#8C28CA",
    color: "#FFFFFF",
});

export function LCMDTag({ value, theme }: { value: string; theme: TagTheme }) {
    return (
        <Stack
            styles={{
                root: { ...styleMap.get(theme), padding: 4, borderRadius: 2, fontSize: 10, display: "inline-flex" },
            }}
        >
            {value}
        </Stack>
    );
}
