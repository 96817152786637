import * as React from "react";

import { getCurrentLocale } from "../../../utils/date/locale";

import { WidgetContent } from "../../layout/widget-content";
import { ChartJSFactory } from "../../common/chartjs";
import { Chart, intl } from "lcmd2framework";
import { DashboardCtxPropI } from "../..";

const dataView = {
    name: "dashboard.progress",
};

function onRenderChartData(ctx: any, chart: any, rawData: any) {
    const timezoneOffset = new Date().getTimezoneOffset();
    const grid = rawData?.grid ? rawData.grid.createEpochArray(timezoneOffset) : [];
    const actual = rawData?.actual || [];
    const done = rawData?.done || [];
    return {
        labels: grid,
        datasets: [
            {
                data: done,
                label: intl.get("charts.progressVsPlanning.labels.progress"),
                pointColor: "#107C10",
                backgroundColor: "#107C10",
                fill: false,
            },
            {
                data: actual,
                label: intl.get("charts.progressVsPlanning.labels.planning"),
                pointColor: "#999EA1",
                backgroundColor: "#999EA1",
                fill: false,
            },
        ],
    };
}

function Content(props: DashboardCtxPropI) {
    const ProgressPlanningChartFactory = React.useMemo(
        () =>
            ChartJSFactory.bind(null, {
                onRenderChartData,
                onOutdatedChartData: props.dashboardCtx.onOutdatedChartData,
                options: {
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                displayFormats: {
                                    quarter: "MMM YYYY",
                                },
                            },

                            adapters: {
                                date: {
                                    locale: getCurrentLocale(),
                                },
                            },
                        },
                        y: {
                            ticks: {
                                min: 0,
                                max: 100,
                                callback: function (value) {
                                    return Math.round(value * 100) + "%";
                                },
                            },
                        },
                    },
                },
            }),
        [],
    );
    return (
        <WidgetContent height={400}>
            <Chart dataView={dataView} factory={ProgressPlanningChartFactory} />
        </WidgetContent>
    );
}

export { Content };
