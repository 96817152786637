import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";

import { cn } from "@/lib/utils";
import { SwitchProps } from "@radix-ui/react-switch";

type Switch = React.ForwardRefExoticComponent<
    SwitchProps &
        React.RefAttributes<HTMLButtonElement> & {
            thumbClassName?: string;
        }
>;

const Switch = React.forwardRef<React.ElementRef<Switch>, React.ComponentPropsWithoutRef<Switch>>(
    ({ className, thumbClassName = "", ...props }, ref) => (
        <SwitchPrimitives.Root
            className={cn(
                "peer inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
                className,
            )}
            {...props}
            ref={ref}
        >
            <SwitchPrimitives.Thumb
                className={cn(
                    "pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0",
                    thumbClassName,
                )}
            />
        </SwitchPrimitives.Root>
    ),
);
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
