import * as React from "react";

import { WidgetContent } from "../../layout/widget-content";
import { ChartJSFactory } from "../../common/chartjs";
import { Chart, intl } from "lcmd2framework";
import { DashboardCtxPropI } from "../..";

const dataView = {
    name: "dashboard.status",
};

const statusToLabelMap = ["open", "done", "inProgress", "late", "overdue"] as const;
type iFrameDashboardStatuses = (typeof statusToLabelMap)[number];
const selectedStatuses: Set<iFrameDashboardStatuses> = new Set();

function onRenderChartData(ctx: any, chart: any, rawData: any) {
    //console.log("onRenderChartData");
    //console.log(rawData)
    const status = rawData?.status || [];
    return {
        labels: statusToLabelMap.map((status) => {
            return intl.get(`charts.statusTracking.labels.${status}`);
        }),
        datasets: [
            {
                data: status,
                label: "Status",
                backgroundColor: [
                    "#C1C5C7", // 0===OPEN
                    "#107C10", // 1===DONE
                    "#009BD4", // 2===IN_PROGRESS,
                    "#E47829", // 3===LATE
                    "#D83B01", // 4===OVERDUE
                ],
            },
        ],
    };
}

function Content(props: DashboardCtxPropI) {
    const ActualBaselineChartFactory = React.useMemo(
        () =>
            ChartJSFactory.bind(null, {
                legendContainerId: "status",
                type: "doughnut",
                onRenderChartData,
                onOutdatedChartData: props.dashboardCtx.onOutdatedChartData,
                options: {
                    onClick: (e, activeEls) => {
                        const datasetIndex = activeEls[0].datasetIndex;
                        const dataIndex = activeEls[0].index;
                        const datasetLabel = e.chart.data.datasets[datasetIndex].label;
                        const value = e.chart.data.datasets[datasetIndex].data[dataIndex];
                        const label = e.chart.data.labels[dataIndex];

                        if (selectedStatuses.has(statusToLabelMap[dataIndex])) {
                            selectedStatuses.delete(statusToLabelMap[dataIndex]);
                        } else {
                            selectedStatuses.add(statusToLabelMap[dataIndex]);
                        }
                        if (window.top != window.self) {
                            const messageObj = {
                                action: "dashboardStatusSelected",
                                status: [...selectedStatuses.values()],
                            };
                            parent.postMessage(messageObj, "*");
                        }
                    },
                },
            }),
        [],
    );
    return (
        <WidgetContent height={200}>
            <Chart legendContainerId={"status"} dataView={dataView} factory={ActualBaselineChartFactory} />
        </WidgetContent>
    );
}

export { Content };
