﻿export class AuthTokenHandler {
    public static parseNotValidateAuthToken(auth_token: string) {
        let ret = null;
        try {
            const token = (auth_token || "").split(".");
            if (3 == token?.length) {
                const params = JSON.parse(atob(token[1]));
                ret = params;
            }
        } catch (e) {
            // do not handle
        }
        return ret;
    }
}
