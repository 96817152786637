import React, { useState } from "react";
import { Input } from "../ui/input";
import { intl } from "@/legacy/GlobalHelperReact";
import { IconButton } from "../common/IconButton/IconButton";
import { Pencil } from "lucide-react";
import { useOutsideClickHook } from "../hooks/useOutsideClick.hook";
import { getUrlLink } from "../Utils";
import { Label } from "@fluentui/react";

type DataSecurityLinkProps = {
    onChange: (value: string) => void;
    value: string;
};

export function DataSecurityLink({ onChange, value }: DataSecurityLinkProps) {
    const [editableMode, setEditableMode] = useState<boolean>(!!value);

    const handleClickOutside = () => {
        setEditableMode(false);
    };

    const ref = useOutsideClickHook(handleClickOutside);

    return (
        <>
            <Label className="mt-3 font-inter font-semibold text-sm leading-5 tracking-tighter text-left text-[#2C3032] p-0">
                {intl.get("fw.contact.terms")}
            </Label>
            {editableMode ? (
                <Input
                    ref={ref}
                    className="my-1.5"
                    placeholder={intl.get("fw.projectLink")}
                    value={value || ""}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        onChange((event.target as HTMLInputElement).value)
                    }
                    onKeyUp={(event) => {
                        if (event.key === "Enter") {
                            handleClickOutside();
                        }
                    }}
                />
            ) : (
                <div className="relative my-1.5">
                    <div className="flex h-10 w-full items-center rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                        {value === "" ? (
                            <span className="text-slate-500">{intl.get("fw.projectLink")}</span>
                        ) : (
                            <a
                                className="overflow-hidden whitespace-nowrap max-w-full max-w-[400px] text-[#009BD4] underline align-bottom"
                                href={getUrlLink(value)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {value}
                            </a>
                        )}
                    </div>

                    <IconButton
                        icon={Pencil}
                        onClick={() => setEditableMode(true)}
                        className="text-[#565C60] w-4 h-4 absolute top-1/2 transform -translate-y-1/2 right-3 hover:bg-transparent hover:text-none"
                    />
                </div>
            )}
        </>
    );
}
