import { useCallback } from "react";
import { uploadFileBlocks, getServices, createProject, assert, jsonToError, errorToMessage } from "lcmd2framework";
import { getParsedProject } from "@/core/RestHelper";

export const useFileUploadHandlers = ({ auth_token, project, viewOptions, resource_token, setView, LCMD }) => {
    const onFileUploadAsync = useCallback((file, cb, status) => {
        uploadFileBlocks(
            file,
            (error, result) => {
                if (error) {
                    cb(error ? errorToMessage(error) : null, null);
                } else {
                    if (status) {
                        status({
                            parsing: true,
                            ofs: file.size,
                            length: file.size,
                        });
                    }
                    getParsedProject(getServices(), { token: result.resource_token }, (error, project) => {
                        if (error) {
                            cb(error ? errorToMessage(error) : null, null);
                        } else {
                            Object.assign(project, {
                                file: {
                                    resource_token: result.resource_token,
                                    fileName: file.name,
                                    customTextFields: (project as any)?.props?.customTextFields || [],
                                },
                            });
                            cb(null, project);
                        }
                    });
                }
            },
            status,
        );
    }, []);

    const onCreateProject = useCallback(
        (data) => {
            const auth_token = data?.auth_token;
            const resource_id = data.upload_result?.token?.resource_id;
            const file = data.file;
            const details = data.details || {};

            if (auth_token && resource_id) {
                const customFields = {
                    ...(data.upload_result?.token?.resource_meta || {}),
                    ...(details?.customFields || {}),
                };
                const _file = {
                    id: resource_id,
                    lastModified: file?.lastModified,
                    name: file?.name,
                    size: file?.size,
                    type: file?.type,
                    customFields: customFields,
                    activityCode: details.activityCode
                };
                const _commit = {
                    name: details?.name || undefined,
                    message: details?.message || undefined,
                };
                if (!project) {
                    createProject(
                        auth_token,
                        (error, result) => {
                            if (error) {
                                setView((prevView) => ({
                                    ...prevView,
                                    nonVisualProps: { error: errorToMessage(error) },
                                }));
                            } else {
                                setView((prevView) => ({
                                    ...prevView,
                                    nonVisualProps: { done: result },
                                }));
                            }
                        },
                        _file,
                        _commit,
                    );
                }
            } else if (auth_token && viewOptions?.clone) {
                assert(data.clone);
                LCMD.worker.postMessage([
                    "project",
                    "clone",
                    {
                        auth_token,
                        details,
                        clone: data.clone,
                        ops: data.ops,
                        cb: LCMD.worker.registerCallback((data) => {
                            if (!data?.result || data.error) {
                                const error = jsonToError(data?.error || undefined);
                                setView((prevView) => ({
                                    ...prevView,
                                    nonVisualProps: { error: errorToMessage(error) },
                                }));
                            } else {
                                assert(data.result);
                                setView((prevView) => ({
                                    ...prevView,
                                    nonVisualProps: { done: data.result },
                                }));
                            }
                        }),
                    },
                ]);
            } else if (auth_token && details?.startDate && details?.endDate) {
                const _file = {
                    id: "00000000-0000-0000-0000-000000000000",
                    type: "application/vnd.lcmdigital.new",
                    customFields: {
                        ...(details?.customFields || {}),
                        startDate: details?.startDate.getTime(),
                        endDate: details?.endDate.getTime(),
                    },
                };
                const _commit = {
                    name: details?.name || undefined,
                    message: details?.message || undefined,
                };
                createProject(
                    auth_token,
                    (error, result) => {
                        if (error) {
                            setView((prevView) => ({
                                ...prevView,
                                nonVisualProps: { error: errorToMessage(error) },
                            }));
                        } else {
                            setView((prevView) => ({
                                ...prevView,
                                nonVisualProps: { done: result },
                            }));
                        }
                    },
                    _file,
                    _commit,
                );
            } else if (auth_token) {
                setView((prevView) => ({
                    ...prevView,
                    nonVisualProps: { done: { auth_token: auth_token } },
                }));
            } else {
                setView((prevView) => ({
                    ...prevView,
                    nonVisualProps: { error: "Internal Error" },
                }));
            }
        },
        [auth_token, project, viewOptions, resource_token, setView],
    );

    return {
        onFileUploadAsync,
        onCreateProject,
    };
};
