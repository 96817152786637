import * as React from "react";
import { CONST } from "../../settings";
import { CanvasCommonProps, CanvasMeta, CanvasViewPort } from "./Canvas";

export type CanvasTabHostProps = CanvasCommonProps & {
    view: CanvasViewPort;
    meta: CanvasMeta;
    width: number;
    height: number;
    visible: boolean;
    sidebar: { selected?: string; subView?: string };
};

export function CanvasTabHost(props: CanvasTabHostProps) {
    const selected = props.sidebar.selected;
    return (
        <div
            style={{
                display: props.visible ? "flex" : "none",
                boxSizing: "border-box",
                width: "100vw",
                height: "100vh",
                paddingTop: CONST.titleHeight,
                backgroundColor: "white",
            }}
        >
            {"todo" === selected && "function" === typeof props.worker.config?.Todo ? (
                <props.worker.config.Todo />
            ) : "settings" === selected && "function" === typeof props.worker.config?.Settings ? (
                <props.worker.config.Settings subView={props.sidebar.subView} />
            ) : null}
        </div>
    );
}
