import * as React from "react";
import { registerIcons } from "@fluentui/react";

type IconCollection = {
    [K in keyof typeof icons]?: string;
};

const icons = {
    Lcmd_Scales: <i className="filled icon-ic_fluent_scales_24_filled" />,
    LCMD_Clock: <i className="icon-ic_fluent_clock_24_regular" />,
    Lcmd_Circle: <i className="icon-ic_fluent_radio_button_20_regular" />,
    Lcmd_Undo: <i className="icon-ic_fluent_arrow_undo_16_filled filled" />,
    Lcmd_Redo: <i className="icon-ic_fluent_arrow_redo_16_filled filled" />,
    Lcmd_ClipboardPaste: <i className="icon-ic_fluent_clipboard_paste_20_filled filled" />,
    Lcmd_Search: <i className="icon-ic_fluent_clipboard_search_20_filled" />,
    Lcmd_Checkmark: <i className={"icon-ic_fluent_checkmark_16_filled filled"} />,
    Lcmd_Checkmark_Circle: <i className={"icon-ic_fluent_checkmark_circle_20_filled filled"} />,
    Lcmd_Comment: <i className="icon-ic_fluent_comment_16_filled filled" />,
    Lcmd_State_Open: <i className={"icon-ic_fluent_checkmark_circle_16_filled filled"} />,
    Lcmd_State_Done: <i className={"icon-ic_fluent_checkmark_circle_16_filled filled"} />,
    Lcmd_State_In_Progress: <i className={"icon-ic_fluent_checkmark_circle_16_filled filled"} />,
    Lcmd_State_In_Approval: <i className={"icon-ic_fluent_checkmark_circle_16_filled filled"} />,
    Lcmd_State_Denied: <i className={"icon-ic_fluent_checkmark_circle_16_filled filled"} />,
    Lcmd_Arrow_Counterclockwise: <i className={"icon-ic_fluent_arrow_counterclockwise_20_filled filled"} />,
    Lcmd_More: <i className={"icon-ic_fluent_more_vertical_20_filled filled"} />,
    Lcmd_Horizontal_More: <i className={"icon-ic_fluent_more_horizontal_20_filled filled"} />,
    Lcmd_Edit: <i className={"icon-ic_fluent_edit_16_regular"} />,
    Lcmd_Delete: <i className={"icon-ic_fluent_delete_16_regular"} />,
    Lcmd_Add: <i className={"icon-ic_fluent_add_16_regular"} />,
    Lcmd_Link: <i className={"icon-ic_fluent_link_16_filled filled"} />,
    Lcmd_Document: <i className={"icon-ic_fluent_document_arrow_left_24_filled filled"} />,
    Lcmd_Warning: <i className={"icon-ic_fluent_warning_20_filled filled"} />,
    Lcmd_Fluent_Border: <i className={"icon-ic_fluent_border_24_filled filled"} />,
    Lcmd_Bullet_List: <i className={"icon-ic_fluent_text_bullet_list_ltr_20_filled filled"} />,
    Lcmd_History: <i className={"icon-ic_fluent_history_20_filled filled"} />,
    Lcmd_Edit_Filled: <i className={"icon-ic_fluent_edit_16_filled filled"} />,
    Lcmd_Sort_UPDOWN: <i className={"icon-ic_fluent_arrow_sort_20_regular"} />,
    Lcmd_Trade: <i className={"icon-ic_fluent_document_header_24_filled filled"} />,
    Lcmd_Areas: <i className={"icon-ic_fluent_calendar_rtl_24_filled filled"} />,
    Lcmd_Areas_Clone: <i className={"icon-ic_fluent_copy_20_regular"} />,
    Lcmd_Add_Library: <i className={"icon-ic_fluent_receipt_add_24_filled filled"} />,
    Lcmd_Whitebaord_Details: <i className={"icon-ic_fluent_window_multiple_16_filled filled"} />,
    Lcmd_Settings: <i className={"icon-ic_fluent_settings_24_filled filled"} />,
    Lcmd_Vehicle_Subway: <i className={"icon-ic_fluent_vehicle_subway_16_filled filled"} />,
    Lcmd_Grid: <i className={"icon-ic_fluent_grid_28_regular"} />,
    Lcmd_Add_Circle: <i className={"icon-ic_fluent_add_circle_32_filled filled"} />,
    Lcmd_Circle_Filled: <i className={"icon-ic_fluent_circle_24_filled filled"} />,
    Lcmd_Arrow_Right: <i className={"icon-ic_fluent_arrow_right_24_regular"} />,
    Lcmd_Arrow_Up: <i className={"icon-ic_fluent_arrow_up_24_regular"} />,
    Lcmd_Arrow_Down: <i className={"icon-ic_fluent_arrow_down_24_regular"} />,
    Lcmd_Filter: <i className={"icon-ic_fluent_filter_20_regular"} />,
    Lcmd_Autofit: <i className={"icon-ic_fluent_arrow_autofit_width_24_regular"} />,
};

registerIcons({ icons: icons });
const I: IconCollection = {};
for (const [key, value] of Object.entries(icons)) {
    I[key] = key;
}

export const Icons = I;
