// TODO: group with the last resource chart changes into common components
//  and move to common/Overview/Layout.tsx
import * as React from "react";

import { Stack, mergeStyleSets, getTheme } from "@fluentui/react";

import { Header } from "./Header";
import { Body } from "./Body";
import { MilestoneI } from "../types";

export type MilestoneOverviewProps = {
    milestone: MilestoneI;
    milestoneDate: number;
    baselineDate: number;
};

const theme = getTheme();

const classNames = mergeStyleSets({
    wrapper: {
        padding: "5px 8px 8px 8px",
        borderRadius: 4,
        border: "1px solid rgba(0, 0, 0, 0.05)",
        backgroundColor: theme.semanticColors.bodyStandoutBackground,
        boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.12), 0px 0px 6px rgba(0, 0, 0, 0.08)",
    },
});

function MilestoneOverview(props: MilestoneOverviewProps) {
    const { milestone, milestoneDate, baselineDate } = props;

    return (
        <Stack className={classNames.wrapper}>
            <Header milestoneName={milestone.n} baselineDate={baselineDate} />
            <Body milestone={milestone} milestoneDate={milestoneDate} />
        </Stack>
    );
}

export { MilestoneOverview };
