import { create } from "zustand";
import { FilterData, ProjectDateRange } from "../../components/Modal/ModalToDoFilter/filter.interface";

type FilterDataState = {
    filterData: FilterData | null;
    isModalVisible: boolean;
    modalRoute: string;
    projectDateRange: ProjectDateRange;
    trades: any[];
    areas: any[];
};

type FilterDataActions = {
    setFilterData: (data: FilterData | null) => void;
    setIsModalVisible: (isVisible: boolean) => void;
    setModalRoute: (route: string) => void;
    setProjectDateRange: (data: ProjectDateRange) => void;
    setTrades: (trades: any[]) => void;
    setAreas: (areas: any[]) => void;
};

type FilterDataStore = FilterDataState & FilterDataActions;

export const useToDoModalFilterStore = create<FilterDataStore>((set) => ({
    // State
    filterData: null,
    isModalVisible: false,
    modalRoute: "/",
    projectDateRange: {
        selectedMode: "",
        startDate: 0,
        endDate: 0,
    },
    trades: [],
    areas: [],

    // Actions
    setFilterData: (data) => set({ filterData: data }),
    setIsModalVisible: (isVisible) => set({ isModalVisible: isVisible }),
    setModalRoute: (route) => set({ modalRoute: route }),
    setProjectDateRange: (data) => set({ projectDateRange: data }),
    setTrades: (trades) => set({ trades }),
    setAreas: (areas) => set({ areas }),
}));
