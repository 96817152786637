import * as React from "react";
import { mergeStyleSets } from "@fluentui/react";

import { TradesList } from "./TradesList";

const classNames = mergeStyleSets({
    body: {
        overflow: "auto",
    },
});

type BodyProps = {
    trades: { trid: string; color: number; name: string }[];
};

export function Body({ trades }: BodyProps) {
    return (
        <div className={classNames.body}>
            <TradesList trades={trades} />
        </div>
    );
}
