import { useEffect, useState } from "react";
import { intl } from "lcmd2framework";
import { CoreExtensions } from "../../../extensions/core.extensions";
import { useLCMD } from "../../../app/LCMContext";
import { useT3RolesHook } from "../../hooks/useT3Roles.hook";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Save, X } from "lucide-react";

enum PDFHeaderSide {
    LEFT = "left",
    RIGHT = "right",
}

type PdfSettings = {
    leftHeaderImage?: string;
    rightHeaderImage?: string;
    headerLineOne?: string;
    headerLineTwo?: string;
    headerLineThree?: string;
};

export function PdfSettingsView() {
    const baseImageProps = {
        src: null,
        width: 260,
        height: 220,
        // Show a border around the image (just for demonstration purposes)
        styles: { border: "1px solid #E1E4E5" },
    };
    const acceptedFileuploadTypes = "image/*";
    const [pdfSettings, setPDFSettings] = useState<PdfSettings>({});
    const [initialPdfSettings, setInitialPdfSettings] = useState<PdfSettings>({});
    const [leftImageProps, setLeftImageProps] = useState(baseImageProps);
    const [rightImageProps, setRightImageProps] = useState(baseImageProps);
    const [leftImageUploading, setLeftImageUploading] = useState(false);
    const [rightImageUploading, setRightImageUploading] = useState(false);
    const [unsavedChanges, setUnsavedChanged] = useState(false);
    const isAllowed = useT3RolesHook();
    const LCMD = useLCMD();

    useEffect(() => {
        LCMD.getProjectExtension(CoreExtensions.PDF_SETTINGS, (error, data) => {
            if (error) {
                console.warn(error);
            } else {
                if (data?.header) {
                    setInitialPdfSettings(data.header);
                    setPDFSettings(data.header);
                }
            }
        });
    }, []);

    useEffect(() => {
        if (pdfSettings.rightHeaderImage) {
            setRightImageProps({
                ...rightImageProps,
                src: LCMD.getAttachmentUrl({ blobId: pdfSettings.rightHeaderImage }),
            });
        }
        if (pdfSettings.leftHeaderImage) {
            setLeftImageProps({
                ...leftImageProps,
                src: LCMD.getAttachmentUrl({ blobId: pdfSettings.leftHeaderImage }),
            });
        }
    }, [pdfSettings]);

    const deleteLogo = (side: PDFHeaderSide) => {
        const stateAfterDelete = { ...pdfSettings };
        if (side === PDFHeaderSide.LEFT) {
            delete stateAfterDelete.leftHeaderImage;
            setLeftImageProps({
                ...leftImageProps,
                src: null,
            });
        }

        if (side === PDFHeaderSide.RIGHT) {
            delete stateAfterDelete.rightHeaderImage;
            setRightImageProps({
                ...rightImageProps,
                src: null,
            });
        }

        setPDFSettings(stateAfterDelete);
        setUnsavedChanged(true);
    };

    const uploadPdfHeaderLogo = (ev, side: PDFHeaderSide) => {
        if (side === PDFHeaderSide.RIGHT) {
            setRightImageUploading(true);
        }
        if (side === PDFHeaderSide.LEFT) {
            setLeftImageUploading(true);
        }

        const fileInput: HTMLInputElement = ev.target;
        if (1 === fileInput.files.length) {
            const cb = LCMD.worker.registerCallback((data) => {
                if (data?.blobId && LCMD) {
                    const newPdfSettings = { ...pdfSettings };
                    if (side === PDFHeaderSide.LEFT) {
                        setLeftImageUploading(false);
                        newPdfSettings.leftHeaderImage = data.blobId;
                    }
                    if (side === PDFHeaderSide.RIGHT) {
                        setRightImageUploading(false);
                        newPdfSettings.rightHeaderImage = data.blobId;
                    }
                    setPDFSettings(newPdfSettings);
                    setUnsavedChanged(true);
                }
            });
            LCMD.uploadAttachments({}, fileInput.files, undefined, cb);
        }
    };

    const clearFileUpload = (ev) => {
        const fileInput: HTMLInputElement = ev.target;
        fileInput.value = null;
    };

    function handleDiscardChanges() {
        setPDFSettings(initialPdfSettings);
        setUnsavedChanged(false);
    }

    function handleSaveChanges() {
        LCMD.putExtension({
            lcmdx: 1.0,
            id: CoreExtensions.PDF_SETTINGS,
            header: pdfSettings,
        });
        setUnsavedChanged(false);
    }

    return (
        <div className="px-5 py-0">
            <p className="text-base font-semibold">{intl.get("PdfSettings.HeaderLogosText")}</p>
            <div className="flex gap-4">
                <div className="flex w-[260px] flex-col gap-2.5">
                    <Label htmlFor="leftLogo">{intl.get("PdfSettings.LeftLogo")}</Label>
                    <Input
                        id="leftLogo"
                        disabled={!isAllowed || rightImageUploading}
                        accept={acceptedFileuploadTypes}
                        type="file"
                        onChange={(ev) => uploadPdfHeaderLogo(ev, PDFHeaderSide.LEFT)}
                        onClick={clearFileUpload}
                        className="cursor-pointer"
                    />
                    {leftImageUploading && <p>{intl.get("PdfSettings.UploadFeedback")}</p>}
                    {leftImageProps.src && (
                        <>
                            <a href={leftImageProps.src} target="_blank" rel="noreferrer">
                                <img
                                    src={leftImageProps.src}
                                    className="h-full border border-solid object-contain py-8"
                                    width={leftImageProps.width}
                                    height={leftImageProps.height}
                                />
                            </a>
                            <Button
                                variant="outline"
                                disabled={!isAllowed || rightImageUploading}
                                onClick={() => deleteLogo(PDFHeaderSide.LEFT)}
                            >
                                {intl.get("PdfSettings.LeftDeleteButtonText")}
                            </Button>
                        </>
                    )}
                </div>
                <div className="flex w-[260px] flex-col gap-2.5">
                    <Label htmlFor="rightLogo">{intl.get("PdfSettings.RightLogo")}</Label>
                    <Input
                        id="rightLogo"
                        disabled={!isAllowed || leftImageUploading}
                        accept={acceptedFileuploadTypes}
                        type="file"
                        onChange={(ev) => uploadPdfHeaderLogo(ev, PDFHeaderSide.RIGHT)}
                        onClick={clearFileUpload}
                        className="cursor-pointer"
                    />
                    {rightImageUploading && <p>{intl.get("PdfSettings.UploadFeedback")}</p>}
                    {rightImageProps.src && (
                        <>
                            <a href={rightImageProps.src} target="_blank" rel="noreferrer">
                                <img
                                    src={rightImageProps.src}
                                    className="h-full border border-solid object-contain py-8"
                                    width={rightImageProps.width}
                                    height={rightImageProps.height}
                                />
                            </a>
                            <Button
                                variant="outline"
                                disabled={!isAllowed || rightImageUploading}
                                onClick={() => deleteLogo(PDFHeaderSide.RIGHT)}
                            >
                                {intl.get("PdfSettings.RightDeleteButtonText")}
                            </Button>
                        </>
                    )}
                </div>
            </div>
            {unsavedChanges && (
                <div className="flex gap-4 pt-4">
                    <Button variant="outline" onClick={handleDiscardChanges} className="flex items-center gap-1">
                        {intl.get("PdfSettings.CancelButtonText")}
                        <X strokeWidth={1} size={20} />
                    </Button>
                    <Button onClick={handleSaveChanges} className="flex items-center gap-1">
                        {intl.get("PdfSettings.SaveButtonText")}
                        <Save strokeWidth={1} size={20} />
                    </Button>
                </div>
            )}
        </div>
    );
}
