import { Icon, mergeStyleSets } from "@fluentui/react";
import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

/**
 * Active Weekdays
 *
 * @description Starts like Javascript Weekdays (0 - Sunday, 1 - Monday ...)
 */
type ActiveWeekdays = [boolean, boolean, boolean, boolean, boolean, boolean, boolean];

type ActiveWeekdaySelectorProps = {
    activeWeekdays: ActiveWeekdays;
    onActiveWeekdaysChange: (newActiveWeekdays: ActiveWeekdays) => void;
    disabled?: boolean;
};

export function ActiveWeekdaySelector({
    activeWeekdays,
    onActiveWeekdaysChange,
    disabled,
}: ActiveWeekdaySelectorProps) {

    const weekdayIndexes: [number, number, number, number, number, number, number] = [7, 1, 2, 3, 4, 5, 6];
    const weekdayNames: string[] =
            intl.get("CalendarExceptionMainScreen.days") as any as string[]

    const classNames = mergeStyleSets({
        activeWeekdaysWrapper: {
            display: "flex",
            border: "1px solid #E1E4E5",
            borderRadius: "4px",
            width: "fit-content",
            opacity: disabled ? 0.4 : 1,
        },
        activeWeekday: { color: "#565C60", backgroundColor: "#FF" },
        inactiveWeekday: { color: "#C1C5C7", backgroundColor: "#F1F3F3" },
        weekDayElement: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "20px",
            borderRight: "1px solid #E1E4E5",
            boxSizing: "content-box",
            padding: "0 15px",
            margin: "10px 0",
        },
    });

    if (typeof activeWeekdays !== "object" || activeWeekdays === null) {
        throw new Error("activeWeekdays param needs to be type of ActiveWeekdays");
    }

    if (activeWeekdays.length !== 7) {
        throw new Error("activeWeekdays param needs to have 7 elements");
    }

    if (
        !activeWeekdays.every((weekday) => {
            return typeof weekday === "boolean";
        })
    ) {
        throw new Error("activeWeekdays param elements have to be type of boolean");
    }

    const currentWeekdayStatusChanged = (index: number) => {
        if (disabled) {
            return;
        }
        const newActiveWeekdays: ActiveWeekdays = [...activeWeekdays];
        newActiveWeekdays[index] = !newActiveWeekdays[index];
        onActiveWeekdaysChange(newActiveWeekdays);
    };

    return (
        <div className={classNames.activeWeekdaysWrapper}>
            {activeWeekdays.map((currentWeekdayStatus, index) => {
                return (
                    <div
                        key={`active-weekday-${weekdayIndexes[index]}`}
                        style={{ order: weekdayIndexes[index] }}
                        className={currentWeekdayStatus ? classNames.activeWeekday : classNames.inactiveWeekday}
                    >
                        <label
                            key={"label-key-" + weekdayNames[index]}
                            className={classNames.weekDayElement}
                            style={7 === weekdayIndexes[index] ? { border: "none" } : {}}
                        >
                            {weekdayNames[index]}
                            <input
                                type="checkbox"
                                defaultChecked={currentWeekdayStatus}
                                style={{ display: "none" }}
                                onChange={() => currentWeekdayStatusChanged(index)}
                            />
                            <Icon iconName={currentWeekdayStatus ? "CheckMark" : "LocationDot"} />
                        </label>
                    </div>
                );
            })}
        </div>
    );
}

export type { ActiveWeekdaySelectorProps, ActiveWeekdays };
