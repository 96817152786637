﻿import { CanvasCommonProps, CanvasViewProps } from "./Canvas";
import { CanvasViewConst } from "../../../model/DataModel";
import { MainWorkerMessage } from "../../MainWorkerPipe";
import { useEffect, useReducer } from "react";
import * as fluentui from "@fluentui/react";
import { intl } from "../../GlobalHelperReact";
import * as React from "react";
import { useCanvasNavigation } from "@/components/hooks/useCanvasNavigation.hook";

type CanvasOverlayProps = CanvasCommonProps &
    CanvasViewProps & {
        handleAddPanelOpen: () => void;
    };

type CanvasOverlayState = {
    const: CanvasViewConst;
    forceShiftKey: boolean;
};

export function CanvasOverlay({ worker, view, handleAddPanelOpen }: CanvasOverlayProps) {
    const [state, setState] = useReducer(
        (current: CanvasOverlayState, update: Partial<CanvasOverlayState>): CanvasOverlayState => ({
            ...current,
            ...update,
        }),
        {
            const: worker.canvas.viewConst,
            forceShiftKey: view.forceShiftKey,
        },
    );
    const {zoomIn , zoomOut } = useCanvasNavigation();

    useEffect(() => {
        worker.registerHandler(handleWorkerMsg);
        setState({
            forceShiftKey: view.forceShiftKey,
        });
        return () => {
            worker.unregisterHandler(handleWorkerMsg);
        };
    }, []);

    function handleWorkerMsg(msg: MainWorkerMessage) {
        switch (msg[0]) {
            case "canvas": {
                const canvas = msg[1];
                const C = canvas?.viewConst || state.const;
                if (C !== state.const) {
                    setState({
                        const: C,
                    });
                }

                break;
            }
            case "toggle": {
                if (msg[1] === "forceShiftKey") {
                    const forceShiftKey = msg[2] as boolean;
                    view.forceShiftKey = forceShiftKey;
                    setState({
                        forceShiftKey: view.forceShiftKey,
                    });
                }
            }
        }
    }

    function handleToggleForceShiftKey(forceShiftKey: boolean) {
        worker.dispatchMessage(["toggle", "forceShiftKey", !view.forceShiftKey]);
    }
    function handleZoomIn() {
        zoomIn()
    }
    function handleZoomOut() {
        zoomOut()
    }
    function handleLink() {
        if (!state.const.readonly) {
            worker.dispatchMessage([
                "cmd",
                {
                    action: "dependency",
                },
            ]);
        }
    }

    return (
        <div id="AO" className="AO">
            <fluentui.IconButton
                title={intl.get("canvas.toolbar.add.title")}
                styles={{
                    root: {
                        color: "#FFFFFF",
                    },
                }}
                iconProps={{ iconName: "BoxAdditionSolid" }}
                onClick={handleAddPanelOpen}
            />
            <fluentui.Separator
                vertical
                styles={{
                    root: {
                        color: "#FFFFFF",
                        opacity: 0.2,
                    },
                }}
            />
            <fluentui.IconButton
                title={intl.get("canvas.toolbar.link.title")}
                disabled={state.const.readonly}
                styles={{
                    root: {
                        color: "#FFFFFF",
                    },
                    rootDisabled: {
                        backgroundColor: "transparent",
                    },
                }}
                iconProps={{ iconName: "Link12" }}
                onClick={handleLink}
            />
            <fluentui.Separator
                vertical
                styles={{
                    root: {
                        color: "#FFFFFF",
                        opacity: 0.2,
                    },
                }}
            />
            <fluentui.IconButton
                title={intl.get("canvas.toolbar.select.title")}
                checked={state.forceShiftKey}
                disabled={state.const.readonly}
                onClick={() => handleToggleForceShiftKey(view.forceShiftKey)}
                styles={{
                    root: {
                        color: "#FFFFFF",
                    },
                    rootDisabled: {
                        backgroundColor: "transparent",
                    },
                }}
                iconProps={{ iconName: "BorderDash" }}
            />
            <fluentui.Separator
                vertical
                styles={{
                    root: {
                        color: "#FFFFFF",
                        opacity: 0.2,
                    },
                }}
            />
            <fluentui.IconButton
                title={intl.get("canvas.toolbar.select.zoomOut")}
                onClick={handleZoomOut}
                styles={{
                    root: {
                        color: "#FFFFFF",
                    },
                }}
                iconProps={{ iconName: "ZoomOut" }}
            />
            <fluentui.IconButton
                title={intl.get("canvas.toolbar.select.zoomIn")}
                onClick={handleZoomIn}
                styles={{
                    root: {
                        color: "#FFFFFF",
                    },
                }}
                iconProps={{ iconName: "ZoomIn" }}
            />
        </div>
    );
}
