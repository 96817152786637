import { Button } from "@/components/ui/button";
import { decimalToHex } from "@/components/utils/color/decimal-to-hex";
import { intl } from "@/legacy/GlobalHelperReact";
import { DigitalPlanningBoardTradeDetails } from "@/legacy/api/lcmd2framework";
import { cn } from "@/lib/utils";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ChromePicker } from "react-color";

type ColorPickerModal = {
    isColorPickerOpened: boolean;
    setIsColorPickerOpened: (value: boolean) => void;
    position: "left" | "right";
    trade: Partial<DigitalPlanningBoardTradeDetails>;
    setTrade: Dispatch<SetStateAction<Partial<DigitalPlanningBoardTradeDetails>>>;
};

export const ColorPickerModal = ({
    isColorPickerOpened,
    setIsColorPickerOpened,
    position,
    trade,
    setTrade,
}: ColorPickerModal) => {
    const [hex, setHex] = useState<string>("#FF0000");

    useEffect(() => {
        if (trade?.color) {
            setHex(decimalToHex(trade?.color));
        }
    }, [trade]);

    return (
        <div
            className={cn(
                `fixed top-20 z-[999999] flex-col overflow-hidden rounded-lg bg-white shadow-lg transition-opacity ease-in-out`,
                isColorPickerOpened ? "opacity-100" : "hidden opacity-0",
                position === "left" ? "-left-[265px]" : "-right-[265px]",
            )}
        >
            <ChromePicker
                className="px-4 pt-4 !shadow-none"
                color={hex}
                onChange={(color) => setHex(color.hex)}
                disableAlpha={true}
            />
            <div className="flex justify-end gap-2 px-4 pb-4">
                <Button variant="secondary" onClick={() => setIsColorPickerOpened(false)}>
                    {intl.get("EditTradeModal.cancelButton.text")}
                </Button>
                <Button
                    onClick={() => {
                        setIsColorPickerOpened(false);
                        const color = Number.parseInt(hex.slice(1), 16);
                        setTrade((trade) => ({ ...trade, color }));
                    }}
                >
                    {intl.get("EditTradeModal.applyButton.text")}
                </Button>
            </div>
        </div>
    );
};
