import * as React from "react";

import { IStackTokens, StackItem } from "@fluentui/react";
import { ReactNode } from "react";

export type WidgetContentProps = {
    children: ReactNode;
    minHeight?: number | string;
    height?: number | string;
};

const tokenProps: IStackTokens = {
    padding: 0,
};

function WidgetContent({ children, height, minHeight }: WidgetContentProps) {
    const style = {
        display: "flex",
        minHeight,
        height,
    };

    return (
        <StackItem grow tokens={tokenProps} style={style}>
            {children}
        </StackItem>
    );
}

WidgetContent.defaultProps = {
    minHeight: "auto",
};

export { WidgetContent };
