import { mergeStyleSets, Text, Stack } from "@fluentui/react";
import * as React from "react";

type SettingsText = {
    title: string;
    description?: string;
};

const styles = mergeStyleSets({
    title: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "22px",
        marginBottom: "6px",
    },
    description: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        color: "#565C60",
        maxWidth: "580px",
    },
});

export function SettingsText({ title, description = "" }: SettingsText) {
    if (typeof title !== "string") {
        throw new Error("title param needs to be type of string");
    }
    if (description !== undefined && typeof description !== "string") {
        throw new Error("description param needs to be type of string or undefined");
    }
    return (
        <Stack>
            <Text className={styles.title}>{title}</Text>
            {description?.length > 0 && <Text className={styles.description}>{description}</Text>}
        </Stack>
    );
}
