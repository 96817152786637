import * as React from "react";

import {
    Modal,
    IModalProps,
    Stack,
    IconButton,
    Label,
    IStyleFunctionOrObject,
    IModalStyleProps,
    IModalStyles,
    mergeStyleSets,
} from "@fluentui/react";
import { ReactElement } from "react";
import { HeaderWithSubtitle } from "./headerWithSubtitle";

type LcmdModalHeader = {
    title: string;
    subtitle?: string;
    height?: number;
};

export type LcmdModalProps = IModalProps & {
    buttons?: ReactElement[];
    header: LcmdModalHeader;
    styles?: IStyleFunctionOrObject<IModalStyleProps, IModalStyles>;
};

export function LcmdModal(props: LcmdModalProps) {
    const cancelIcon = { iconName: "Cancel", style: { color: "#565C60" } };
    const headerHeight = props.header.height || (props.header.subtitle ? 80 : 70);
    const footerHeight = 70;
    //todo: if props.scrollableContentClassName is set, log warning because value is overwritten
    const styles = mergeStyleSets({
        "disable-modal-scroll": {
            overflow: "hidden",
            height: "100%",
        },
        header: {
            minHeight: "60px",
            borderBottom: "1px solid #E1E4E5",
            padding: "16px 24px",
            overflow: "hidden",
        },
        headerLabel: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        footer: {
            position: "absolute",
            bottom: "0",
            width: "100%",
            height: "60px",
            padding: "24px",
            paddingLeft: "24px",
            borderTop: "1px solid #E1E4E5",
        },
    });

    if (props.header.title != undefined && props.header.title != "" && typeof props.header.title !== "string") {
        throw new Error("header title needs to be of type string");
    }

    if (props.buttons) {
        if (!(props.buttons instanceof Array)) {
            throw new Error("buttons param needs to be of type array");
        } else {
            const buttons = Array.from(props.buttons);
            for (let i = 0; i < buttons.length; i++) {
                if (!React.isValidElement(buttons[i])) {
                    throw new Error("button elements needs to be a react element");
                }
            }
        }
    }

    const headerWithSubtitle = <HeaderWithSubtitle subtitle={props.header.subtitle} title={props.header.title} />;

    const headerElement = props.header.subtitle ? (
        headerWithSubtitle
    ) : (
        <Label className={styles.headerLabel}>{props.header.title || ""}</Label>
    );

    return (
        <Modal {...props} scrollableContentClassName={styles["disable-modal-scroll"]}>
            <Stack
                horizontal
                horizontalAlign={"space-between"}
                className={styles.header}
                style={{ height: headerHeight + "px" }}
            >
                <Stack.Item styles={{ root: { overflow: "hidden" } }}>{headerElement}</Stack.Item>
                <Stack.Item>
                    <IconButton iconProps={cancelIcon} onClick={props.onDismiss} />
                </Stack.Item>
            </Stack>
            <div
                className={"modalBody"}
                style={{
                    position: "relative",
                    height: `calc(100% - ${headerHeight + footerHeight + 48}px)`,
                    padding: 24,
                }}
            >
                {props.children}
            </div>

            {props.buttons && props.buttons.length > 0 && (
                <Stack
                    tokens={{ childrenGap: 20 }}
                    horizontal
                    horizontalAlign={"end"}
                    verticalAlign={"center"}
                    className={styles.footer}
                    style={{ height: footerHeight + "px" }}
                >
                    {props.buttons.map((btn, i) => (
                        <div key={i}>{btn}</div>
                    ))}
                </Stack>
            )}
        </Modal>
    );
}
