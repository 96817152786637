import * as React from "react";
import { CommandButton, DefaultButton, mergeStyleSets } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../../RegisteredIcons";
import { intl } from "@/legacy/GlobalHelperReact";

export function AddFilterMenu() {
    const navigate = useNavigate();

    const styles = mergeStyleSets({
        body: {
            backgroundColor: "#F8FAFA",
            padding: "10px 12px",
        },
        label: {
            marginBottom: 8,
            display: "block",
            fontWeight: "600",
        },
        menu: {},
        defaultButton: {
            margin: "0 8px 8px 0",
        },
    });

    const iconsProps = { iconName: Icons.Lcmd_Filter, styles: { root: { color: "#000" } } };

    const iconStyle = {
        color: "#565C60",
    };

    return (
        <div className={styles.body}>
            <label className={styles.label}>{intl.get("ModalToDoFilter.Overview.labelAddFilter")}</label>
            <div className={styles.menu}>
                <DefaultButton
                    iconProps={{ iconName: Icons.Lcmd_Filter }}
                    text={intl.get("ModalToDoFilter.Overview.buttonPriority")}
                    className={styles.defaultButton}
                    onClick={() => {
                        navigate("priority-filter");
                    }}
                />
                <DefaultButton
                    iconProps={{ iconName: Icons.Lcmd_Filter }}
                    text={intl.get("ModalToDoFilter.Overview.buttonStatus")}
                    className={styles.defaultButton}
                    onClick={() => {
                        navigate("status-filter");
                    }}
                />
                <DefaultButton
                    iconProps={{ iconName: Icons.Lcmd_Filter }}
                    text={intl.get("ModalToDoFilter.Overview.buttonTrades")}
                    className={styles.defaultButton}
                    onClick={() => {
                        navigate("trades-filter");
                    }}
                />
                <CommandButton
                    text={intl.get("ModalToDoFilter.Overview.buttonMore")}
                    menuProps={{
                        items: [
                            {
                                key: "responsibleFilter",
                                text: intl.get("ModalToDoFilter.Overview.buttonResponsible"),
                                iconProps: iconsProps,
                                onClick: () => {
                                    navigate("/responsible-filter");
                                },
                            },
                            {
                                key: "dateFilter",
                                text: intl.get("ModalToDoFilter.Overview.buttonDeadline"),
                                iconProps: iconsProps,
                                onClick: () => {
                                    navigate("date-filter");
                                },
                            },
                            /*
                        {
                            key: 'raisedOnFilter',
                            text: intl.get("ModalToDoFilter.Overview.buttonRaisedOn"),
                            iconProps: iconsProps,
                            onClick: () => {
                                navigate('raised-on-filter')
                            }
                        },
                         */
                            {
                                key: "toDoTypeFilter",
                                text: intl.get("ModalToDoFilter.Overview.buttonToDoType"),
                                iconProps: iconsProps,
                                onClick: () => {
                                    navigate("todo-type-filter");
                                },
                            },
                            {
                                key: "areasFilter",
                                text: intl.get("ModalToDoFilter.Overview.buttonAreas"),
                                iconProps: iconsProps,
                                onClick: () => {
                                    navigate("areas-filter");
                                },
                            },
                            {
                                key: "raisedByfilter",
                                text: intl.get("ModalToDoFilter.Overview.buttonRaisedBy"),
                                iconProps: iconsProps,
                                onClick: () => {
                                    navigate("raised-by-filter");
                                },
                            },
                        ],
                    }}
                />
            </div>
        </div>
    );
}
