import * as React from "react";
import { CONST } from "../../settings";
import { SERVICES, getServicesToken } from "../../../model/services";
import { CanvasCommonProps, CanvasMeta, CanvasViewPort } from "./Canvas";

export type CanvasDailyBoardHostProps = CanvasCommonProps & {
    view: CanvasViewPort;
    meta: CanvasMeta;
    width: number;
    height: number;
    visible: boolean;
};

export function CanvasDailyBoardHost(props: CanvasDailyBoardHostProps) {
    const [url, setURL] = React.useState(null);
    React.useLayoutEffect(() => {
        const DAILYBOARD_URL_CB = props.worker.config?.DAILYBOARD_URL || null;
        const DAILYBOARD_URL = DAILYBOARD_URL_CB
            ? DAILYBOARD_URL_CB({
                  isReleaseCandidate: SERVICES.IS_RC,
                  isCanary: SERVICES.IS_CANARY,
                  isDev: SERVICES.IS_DEV,
              } as any)
            : null;
        const session = (props.meta as any)?.revId > 0 ? null : props.meta?.session;
        const _url =
            session?.sandbox && session?.master_token && DAILYBOARD_URL
                ? DAILYBOARD_URL +
                  [
                      "auth_token=" + session.master_token,
                      "api_ver=" + SERVICES.API_VERSION,
                      "lcmdServiceToken=" + getServicesToken(),
                  ].join("&")
                : null;
        if (_url) {
            if (props.visible) {
                setURL(_url);
            } else {
                // keep the url
            }
        } else {
            setURL(null);
        }
    }, [props.visible]);
    const onMessage = React.useCallback(
        (event) => {
            if (Array.isArray(event?.data) && event.data.length >= 2 && "lcmd" === event.data[0]) {
                if ("db" === event.data[1] && event.data.length >= 3) {
                    props.worker.dispatchMessage(["db", "sync", event.data[2]]);
                    props.worker.postMessage(["db", "sync", event.data[2]]);
                }
            }
        },
        [props.worker],
    );
    const ref = React.useCallback(
        (r) => {
            props.worker.dbRef = r;
        },
        [props.worker, onMessage],
    );
    React.useLayoutEffect(() => {
        window.addEventListener("message", onMessage);
        return () => {
            window.removeEventListener("message", onMessage);
        };
    }, [onMessage]);
    return (
        <div
            style={{
                display: props.visible ? "flex" : "none",
                boxSizing: "border-box",
                width: "100vw",
                height: "100vh",
                paddingTop: CONST.titleHeight,
                backgroundColor: "white",
            }}
        >
            <iframe
                ref={ref}
                key={url || "null"}
                src={url || undefined}
                style={{
                    position: "relative",
                    boxSizing: "border-box",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                    border: "none",
                    display: "block",
                }}
            ></iframe>
        </div>
    );
}
