import * as React from "react";

import { sortTrades } from "./utils/sort-trades";

import { ResourceChartsCanvasState, ResourceChartsCanvasProps } from "./types";

type ResourceChartsCanvasPointerEvent = React.PointerEvent<HTMLCanvasElement> & { canvasX: number; canvasY: number };

const HOVERED_COLUMN_BORDER_COLOR = "rgb(50, 49, 48)";

export function onRenderCanvas(
    canvas: ResourceChartsCanvasState & {
        chart: HTMLCanvasElement;
        legend: HTMLCanvasElement;
    },
    props: ResourceChartsCanvasProps,
) {
    if (canvas.chart) {
        const ctx = canvas.chart.getContext("2d");
        if (ctx) {
            const width = canvas.chart.width;
            const height = canvas.chart.height;
            //NO LONGER NEEDED ctx.clearRect(0, 0, width, height)
            const C = props.const;
            const scale = props.scale;
            const padding = 1;
            const kappa = props.kappa;
            const maxH = (kappa?.view?.options?.dynamicView ? kappa?.maxVisible : kappa?.max) || 0;
            if (maxH > 0) {
                const col0 = kappa.view.col0;
                const col1 = kappa.view.col1;
                const cols = col1 - col0;
                // TODO: should we to reverse the traded to keep order with the trades in legend section?
                const trades = sortTrades(Object.values(kappa.trade));
                const n_trades = trades.length;
                const sy = height / (maxH * C.rowPx);

                ctx.save();
                ctx.scale(scale, sy);
                for (let i_col = 0; i_col < cols; i_col++) {
                    let row = 0;
                    for (let i_trade = 0; i_trade < n_trades; i_trade++) {
                        const trade = trades[i_trade];
                        const v = trade.a[i_col];

                        if (v > 0) {
                            row += v;
                            ctx.fillStyle = "#" + trade.color.toString(16).padStart(6, "0");
                            ctx.fillRect(
                                i_col * C.colPx + padding,
                                (maxH - row) * C.rowPx + padding,
                                C.colPx - padding - padding,
                                C.rowPx * v - padding - padding,
                            );
                        }
                    }
                }
                ctx.restore();
            }
            if (props.overlayState.hover) {
                const i_col = Math.floor(props.overlayState.hover.canvasX / C.colPx);

                ctx.strokeStyle = HOVERED_COLUMN_BORDER_COLOR;
                ctx.strokeRect(i_col * C.colPx * scale, 0, C.colPx * scale, height);
            }
        }
        if (ctx && "number" === typeof canvas.legend) {
            const width = 30;
            const height = canvas.chart.height;
            ctx.save();
            ctx.translate(canvas.legend - width, 0);
            const C = props.const;
            const kappa = props.kappa;
            const maxH = (kappa?.view?.options?.dynamicView ? kappa?.maxVisible : kappa?.max) || 0;
            if (maxH > 0) {
                const sy = height / (maxH * C.rowPx);
                ctx.fillStyle = "#000000";
                ctx.font = "12px Inter, sans-serif";
                ctx.textAlign = "right";
                const rowPx = C.rowPx * sy;
                const d = Math.max(Math.round(40 / rowPx), 1);
                for (let i = 0; i < maxH; i += d) {
                    const y = i * C.rowPx * sy;
                    ctx.fillText(i.toString(), width - 5, height - y - 10);
                }
                ctx.fillText(maxH.toString(), width - 5, 10);
            }
            ctx.restore();
        }
    }
}

export function onPointerDown(
    canvas: ResourceChartsCanvasState,
    props: ResourceChartsCanvasProps,
    ev: ResourceChartsCanvasPointerEvent,
) {}

export function onPointerUp(
    canvas: ResourceChartsCanvasState,
    props: ResourceChartsCanvasProps,
    ev: ResourceChartsCanvasPointerEvent,
) {}

export function onPointerMove(
    canvas: ResourceChartsCanvasState,
    props: ResourceChartsCanvasProps,
    ev: ResourceChartsCanvasPointerEvent,
) {
    /*console.log(
    'onPointerMove ' +
      JSON.stringify({ canvasX: ev.canvasX, canvasY: ev.canvasY })
  )*/
    canvas.setOverlayState({
        hover:
            0 === ev.buttons
                ? {
                      canvasX: ev.canvasX / props.scale,
                      canvasY: ev.canvasY,
                  }
                : undefined,
    });
}

export function onPointerEnter(
    canvas: ResourceChartsCanvasState,
    props: ResourceChartsCanvasProps,
    ev: ResourceChartsCanvasPointerEvent,
) {
    /*console.log(
    'onPointerEnter ' +
      JSON.stringify({ canvasX: ev.canvasX, canvasY: ev.canvasY })
  )*/
}

export function onPointerLeave(
    canvas: ResourceChartsCanvasState,
    props: ResourceChartsCanvasProps,
    ev: ResourceChartsCanvasPointerEvent,
) {
    /*console.log(
    'onPointerLeave ' +
      JSON.stringify({ canvasX: ev.canvasX, canvasY: ev.canvasY })
  )*/
    canvas.setOverlayState({
        hover: undefined,
    });
}

export function onPointerCancel(
    canvas: ResourceChartsCanvasState,
    props: ResourceChartsCanvasProps,
    ev: ResourceChartsCanvasPointerEvent,
) {
    /*console.log(
    'onPointerCancel ' +
      JSON.stringify({ canvasX: ev.canvasX, canvasY: ev.canvasY })
  )*/
    canvas.setOverlayState({
        hover: undefined,
    });
}

export function onClick(
    canvas: ResourceChartsCanvasState,
    props: ResourceChartsCanvasProps,
    ev: ResourceChartsCanvasPointerEvent,
) {
    /*console.log(
    'onClick ' + JSON.stringify({ canvasX: ev.canvasX, canvasY: ev.canvasY })
  )*/
    canvas.setOverlayState({
        hover:
            0 === ev.buttons
                ? {
                      canvasX: ev.canvasX / props.scale,
                      canvasY: ev.canvasY,
                  }
                : undefined,
    });
}
