import { ReactElement, useEffect, useState } from "react";
import { IModalProps, Label, mergeStyleSets, Modal, PrimaryButton, Stack } from "@fluentui/react";
import { intl, CheckForUpdatesResult } from "lcmd2framework";

type GeneralError = IModalProps & {
    onReload: () => void;
    image?: string;
    headline?: string;
    text?: string;
    buttontext?: string;
    checkForUpdates?: () => Promise<CheckForUpdatesResult>;
    forcedUpdate: boolean;
    buildInfo: string;
    projectId: string;
};

export function GeneralError(props: GeneralError): ReactElement {
    const buttontext = props.buttontext ? props.buttontext : intl.get("GeneralError.buttontext");
    const image = props.image ? props.image : "/img/common/ErrorNotifications/GeneralError.svg";
    const headline = props.headline ? props.headline : intl.get("GeneralError.headline");
    const text = props.text ? props.text : intl.get("GeneralError.text");

    const contentStyles = mergeStyleSets({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
            padding: 24,
        },
        image: [
            {
                flex: "1 1 auto",
                display: "flex",
                justifyContent: "center",
            },
        ],
        headline: {
            fontSize: 20,
            fontWeight: 600,
            marginBottom: 10,
        },
        text: {
            fontSize: 14,
        },
        buildInfo: {
            position: "absolute",
            top: 0,
            right: 24,
            fontSize: 10,
        },
    });

    const [updateState, setUpdateState] = useState("GeneralError.update");
    useEffect(() => {
        if (props.checkForUpdates) {
            props
                .checkForUpdates()
                .then((ret) => {
                    console.log(JSON.stringify(ret));
                    if (ret?.updateAvailable) {
                        setUpdateState(null); // update available
                    } else {
                        setUpdateState(null); // no update needed
                    }
                })
                .catch((e) => {
                    console.error(e);
                    setUpdateState(null); // failed to get updates...
                });
        } else {
            setUpdateState(null);
        }
    }, [props.checkForUpdates, setUpdateState]);

    return (
        <Modal {...props}>
            <div className={contentStyles.container}>
                <div className={contentStyles.image}>
                    <img src={image} />
                </div>
                <div className={contentStyles.headline}>{headline}</div>
                <p className={contentStyles.text}>{text}</p>
                <div className={contentStyles.buildInfo}>
                    <p>{props.buildInfo}</p>
                    <p>{props.projectId}</p>
                </div>
                <Stack horizontal horizontalAlign={"end"}>
                    {updateState ? (
                        <Label>{intl.get(updateState)}</Label>
                    ) : (
                        <PrimaryButton onClick={props.onReload} text={buttontext} />
                    )}
                </Stack>
            </div>
        </Modal>
    );
}
