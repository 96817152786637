import * as React from "react";
import { intl } from "lcmd2framework";

import { useFilterCollection } from "../../data-access-hooks/use-filter-collection";
import { useMemo, useState } from "react";

import { IContextualMenuProps, IContextualMenuItem } from "@fluentui/react";

import { ContextualMenu as BaseContextualMenu, ContextualMenuItemType } from "@fluentui/react";

import { Layout } from "./Layout";
import { Title } from "./Title";
import { Search } from "./Search";
import { NotFoundFilters } from "./NotFoundFilters";

const dividerItem: IContextualMenuItem = {
    key: "divider",
    itemType: ContextualMenuItemType.Divider,
};

const noFilterFoundItem: IContextualMenuItem = {
    key: "noFiltersFound",
    onRender: NotFoundFilters,
};

export type ContextualMenuProps = {
    menuProps: IContextualMenuProps;
    onSelect: (filterId: string) => void;
    onClear: () => void;
    onManage: () => void;
};

function normalizeFilterText(text: string) {
    return text.trim().toLowerCase();
}

export function ContextualMenu(props: ContextualMenuProps) {
    const [searchInput, setSearchInput] = useState("");

    const { items: filterItems, selectedFilterId, status } = useFilterCollection();

    const clearFiltersItem: IContextualMenuItem = useMemo(
        () => ({
            key: "clear",
            text: intl.get("canvas.cmd.filter.cmd.clear.text"),
            title: intl.get("canvas.cmd.filter.cmd.clear.title"),
            canCheck: false,
            onClick: props.onClear,
        }),
        [props.onClear],
    );

    const loadingFiltersItem = useMemo(() => {
        return !status.loaded
            ? {
                  key: "loading",
                  text: intl.get("fw.loading"),
                  disabled: true,
              }
            : null;
    }, [status.loaded]);

    const filterOptions: IContextualMenuItem[] = useMemo(() => {
        return filterItems.map((filterItem) => {
            return {
                key: filterItem._hid.toString(),
                text: filterItem.name || "?",
                canCheck: true,
                checked: selectedFilterId === filterItem._hid,
                onClick: () => props.onSelect(filterItem._hid),
            };
        });
    }, [filterItems, selectedFilterId, props.onSelect]);

    const filteredOptions = useMemo(() => {
        const filterByText = normalizeFilterText(searchInput);

        // when search input empty
        if (!filterByText) {
            return filterOptions;
        }

        const filteredOptions = filterOptions.filter((option) =>
            normalizeFilterText(option.text).includes(filterByText),
        );

        return filteredOptions.length ? filteredOptions : [noFilterFoundItem];
    }, [filterOptions, searchInput]);

    const menuItems = useMemo(() => {
        return loadingFiltersItem
            ? [clearFiltersItem, dividerItem, loadingFiltersItem]
            : [clearFiltersItem, dividerItem, ...filteredOptions];
    }, [loadingFiltersItem, filteredOptions, clearFiltersItem]);

    return (
        <BaseContextualMenu
            {...props.menuProps}
            items={menuItems}
            onRenderMenuList={(menuListProps, defaultRender) => (
                <Layout
                    header={<Title onChangeClick={props.onManage} />}
                    search={<Search value={searchInput} onChange={setSearchInput} />}
                    items={defaultRender(menuListProps)}
                />
            )}
        />
    );
}
