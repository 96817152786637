import { mergeStyleSets } from "@fluentui/react";

export const filterRowStyles = mergeStyleSets({
    body: {
        paddingBottom: 15,
        marginBottom: 15,
        borderBottom: "1px solid #E1E4E5",
    },
    button: {
        margin: "0 10px 10px 0",
        border: "1px solid #E1E4E6",
        backgroundColor: "#F1F3F3",
        display: "inline-flex",
        padding: "5px 10px",
        borderRadius: 3,
        fontWeight: 600,
    },
    //filter row top
    top: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        height: 40,
    },
    left: {
        display: "flex",
    },
    label: {
        fontWeight: 600,
        fontSize: 16,
        marginRight: 5,
    },
    badge: {
        color: "#ffffff",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "center",
        alignContent: "center",
        gap: 10,
        width: 20,
        height: 20,
        background: "#009BD4",
        borderRadius: "100%",
        flex: "none",
        order: 1,
        flexGrow: 0,
    },
    content: {
        display: "block",
    },
});
