import * as React from "react";
import { LcmdModal } from "../../../common/LcmModal";
import { ComboBox, DefaultButton, IComboBoxOption, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { Counter } from "../../../common/counter";
import { useState } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import {
    LCMDContextDependencyType,
    LCMDContextTaskDetailsResultDependencyDetails,
} from "../../../../app/LCMDContextTypes";

export const ProcessDependenciesModal = ({
    show,
    processDependency,
    onCancel,
    onClose,
}: {
    show: boolean;
    processDependency: LCMDContextTaskDetailsResultDependencyDetails;
    onCancel: () => void;
    onClose: (processDependency: LCMDContextTaskDetailsResultDependencyDetails) => void;
}) => {
    const dependencyOptions: IComboBoxOption[] = [
        { key: 2, text: intl.get("ProcessDependenciesModal.dependenciesOptions.finishToStart") },
        { key: 1, text: intl.get("ProcessDependenciesModal.dependenciesOptions.finishToFinish") },
        { key: 4, text: intl.get("ProcessDependenciesModal.dependenciesOptions.startToStart") },
        { key: 3, text: intl.get("ProcessDependenciesModal.dependenciesOptions.startToFinish") },
    ];

    const [type, setType] = useState<LCMDContextDependencyType>(processDependency?.type ? processDependency.type : 0);
    const [offset, setOffset] = useState(processDependency?.lag ? processDependency.lag : 0);
    const processName = processDependency?.targetName ? processDependency.targetName : "";

    const saveModal = () => {
        const processDependencyToSave: LCMDContextTaskDetailsResultDependencyDetails = {
            ...processDependency,
            type: type,
            lag: offset,
        };
        onClose(processDependencyToSave);
    };

    const buttons = [
        <DefaultButton onClick={onCancel}>{intl.get("ProcessDependenciesModal.buttons.cancel")}</DefaultButton>,
        <PrimaryButton onClick={saveModal}>{intl.get("ProcessDependenciesModal.buttons.apply")}</PrimaryButton>,
    ];

    return (
        <LcmdModal
            buttons={buttons}
            header={{ title: intl.get("ProcessDependenciesModal.headline") }}
            styles={{ main: { width: "600px", height: "800px" } }}
            isOpen={show}
            onDismiss={onCancel}
        >
            <Stack styles={{ root: { height: "100%" } }} tokens={{ childrenGap: "10px" }}>
                <Stack tokens={{ childrenGap: "25px" }} styles={{ root: { padding: "20px 32px" } }}>
                    <Stack>
                        <Stack horizontal horizontalAlign={"space-between"}>
                            <ComboBox
                                label={intl.get("ProcessDependenciesModal.typeComboBox.label")}
                                defaultSelectedKey={type}
                                placeholder={intl.get("ProcessDependenciesModal.typeComboBox.placeholder")}
                                options={dependencyOptions}
                                styles={{ root: { width: "300px" } }}
                                onChange={(ev, newOption) => {
                                    setType(parseInt(`${newOption.key}`));
                                }}
                            />
                            <Stack horizontal>
                                <Counter
                                    label={intl.get("ProcessDependenciesModal.offsetCounter.label")}
                                    unit={intl.get("ProcessDependenciesModal.offsetCounter.unit")}
                                    value={offset}
                                    onChange={(newValue) => {
                                        setOffset(newValue);
                                    }}
                                    min={-100}
                                />
                                {/*<Text>working days</Text>*/}
                            </Stack>
                        </Stack>
                        <TextField
                            label={intl.get("ProcessDependenciesModal.processCounter.label")}
                            disabled
                            defaultValue={processName}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </LcmdModal>
    );
};
