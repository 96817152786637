import {useLCMD} from "@/app/LCMContext";
import { useCanvasStore } from '../store/canvasStore'

export function useFocusMode() {
    const {worker} = useLCMD();
    const { isFocusMode, setFocusMode } = useCanvasStore.getState();

    async function enableFocusMode(processIdsToFocus: number[]): Promise<void> {
        return new Promise((resolve) => {
            worker.postMessage([
                "processFilter",
                "set",
                {
                    processIds: processIdsToFocus,
                    callback: worker.registerCallback(() => {
                        setFocusMode(true);
                        resolve();
                    })
                }
            ]);
        })
    }

    async function disableFocusMode(): Promise<void> {
        return new Promise((resolve) => {
            worker.postMessage([
                "processFilter",
                "reset",
                {
                    callback: worker.registerCallback(() => {
                        setFocusMode(false);
                        resolve();
                    })
                }
            ]);
        })
    }

    return {
        isFocusModeEnabled: isFocusMode,
        enableFocusMode,
        disableFocusMode
    }

}
