import * as React from "react";
import { WorkerSession } from "../../../model/DataModel";
import { getLog, getProject, getSub } from "../../../model/ApiHelper";
import { DialogType, Dialog } from "@fluentui/react";
import { assert } from "../../../model/GlobalHelper";
import { intl } from "../../GlobalHelperReact";
import DigitalPlanningBoard from "../DigitalPlanningBoard";
import { MainWorkerMessage, MainWorkerPipe } from "../../MainWorkerPipe";
import { Card } from "@/components/ui/card";
import { CONST } from "@/legacy/settings";

function OpenDialog(props: { intl; parent: FilesOverlay }) {
    return (
        <Dialog
            hidden={false}
            onDismiss={() => {
                props.parent.setState({ open: null });
            }}
            dialogContentProps={{
                type: DialogType.normal,
                title: intl.get("projects.open.title"),
                subText: intl.get("projects.open.subText"),
            }}
            modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 450 } },
            }}
        />
    );
}

type FilesOverlayProps = {
    visible: boolean;
    hasCloseButton: boolean;
    worker: MainWorkerPipe;
};

type FilesOverlayState = {
    /*upload:{
    key?: number,
    view: "upload"|"details"|null,
    viewOptions?: any
},*/
    projects: string[];
    open: string;
};

export class FilesOverlay extends React.Component<FilesOverlayProps, FilesOverlayState> {
    state = {
        //upload: null,
        projects: null,
        open: null,
    };

    private setProjectState(item: any, i_item: number, projectState: any, cb?: () => void) {
        this.setState((state) => {
            let projects = state.projects || [];
            if (projects[i_item] === item) {
                projects = projects.slice();
                projects[i_item] = Object.assign({}, projects[i_item], projectState);
                return {
                    projects,
                };
            } else {
                // out-of-bound
                return null;
            }
        }, cb);
    }

    private _tsKey: number | null = null;
    private _getTSKey() {
        const now = Date.now();
        const ret = Math.max((this._tsKey || 0) + 1, now);
        assert((this._tsKey || 0) < ret);
        this._tsKey = ret;
        return ret;
    }

    private _fetchProjects(this: FilesOverlay, projects: any[]) {
        const resolved_ctx = {
            n: projects.length,
            i: 0,
            p: null,
        };
        const resolve = (p, i) => {
            if (this.state.open === p?.pid) {
                resolved_ctx.p = i;
            }
            resolved_ctx.i++;
            if (resolved_ctx.n === resolved_ctx.i) {
                if (this.state.open) {
                    const project = this.state.open;
                    this.setState({
                        open: null,
                    });
                    let _p = null;
                    if (null !== resolved_ctx.p) {
                        _p = this.state.projects[resolved_ctx.p];
                    }
                    if (_p) {
                        this.onCellClick(_p);
                    } else {
                        // not found
                    }
                }
            }
        };
        projects.forEach((item: any, i_item: number) => {
            if (!item.project) {
                item.project = {
                    loading: true,
                };
                getProject(this.props.worker.auth.auth_token, item.pid, (error, project) => {
                    if (error) {
                        this.setProjectState(
                            item,
                            i_item,
                            {
                                loading: false,
                                error: error,
                            },
                            resolve.bind(null, null, null),
                        );
                    } else {
                        const pid_ts = project.project.log[project.project.log.length - 1];
                        getLog(project.project_token, project.pid, pid_ts, (error, log) => {
                            if (error) {
                                this.setProjectState(
                                    item,
                                    i_item,
                                    {
                                        loading: false,
                                        error: error,
                                    },
                                    resolve.bind(null, project, null),
                                );
                            } else {
                                this.setProjectState(
                                    item,
                                    i_item,
                                    {
                                        loading: false,
                                        error: null,
                                        project: project,
                                        log: log,
                                        pid_ts: pid_ts,
                                        ts: this._getTSKey(),
                                    },
                                    resolve.bind(null, project, i_item),
                                );
                            }
                        });
                    }
                });
            }
        });
    }

    private fetchProjects(this: FilesOverlay) {
        if (this.props.worker.auth?.auth_token) {
            getSub(this.props.worker.auth.auth_token, null, (error, result) => {
                if (error) {
                    //TODO
                } else {
                    const ts = result?.result?.ts || 0;
                    const helper = (this.state.projects || []).reduce((ret, item) => {
                        ret[item.pid] = item;
                        return ret;
                    }, {});
                    const projects: string[] = (result?.result?.projects || []).map((pid: string) => {
                        const h = helper[pid];
                        if (h) {
                            return h;
                        } else {
                            return {
                                pid: pid,
                            };
                        }
                    });
                    this.setState(
                        {
                            projects: projects,
                        },
                        this._fetchProjects.bind(this, projects),
                    );
                }
            });
        }
    }

    componentDidMount(this: FilesOverlay) {
        if (this.props.worker.nav.project) {
            const project = this.props.worker.nav.project;
            delete this.props.worker.nav.project;
            this.setState({
                open: project,
            });
        }
        this.props.worker.registerHandler(this.onWorkerMsg);
        this.fetchProjects();
    }

    componentDidUnMount(this: FilesOverlay) {
        this.props.worker.unregisterHandler(this.onWorkerMsg);
    }

    private onWorkerMsg = function (this: FilesOverlay, msg: MainWorkerMessage) {
        switch (msg[0]) {
            case "framework":
                {
                    switch (msg[1]) {
                        case "clone":
                            {
                                const opt = msg[2];
                                const file = opt.data
                                    ? new File([opt.data], "", {
                                          type: opt.mime,
                                      })
                                    : null;
                                this.props.worker.dispatchMessage([
                                    "toggle",
                                    "dialog.project.clone",
                                    {
                                        key: this._getTSKey(),
                                        view: "upload",
                                        onDone: (data) => {
                                            this.onUploadDone(data);
                                            this.props.worker.dispatchMessage(["toggle", "dialog.project.clone", null]);
                                        },
                                        viewOptions: {
                                            clone: opt,
                                            files: file ? [file] : undefined,
                                        },
                                        auth_token: this.props.worker.auth?.auth_token || null,
                                        auth_warmup: this.props.worker.warmup_result?.req
                                            ? (this.props.worker.warmup_result as { req: string })
                                            : null,
                                        project: null,
                                    },
                                ]);
                            }
                            break;
                        case "reload": {
                            const opt = msg[2];
                            if ("string" === typeof opt?.pid) {
                                const i = this.state.projects.findIndex((p) => p.pid === opt.pid);
                                if (i >= 0) {
                                    const projects = this.state.projects.slice();
                                    projects[i] = { ...projects[i], project: null };
                                    this.setState(
                                        {
                                            projects: projects,
                                        },
                                        this.fetchProjects.bind(this),
                                    );
                                }
                            } else {
                                this.fetchProjects.bind(this);
                            }
                        }
                    }
                }
                break;
        }
    }.bind(this);

    private openProject = function (
        this: FilesOverlay,
        worker: MainWorkerPipe,
        session: WorkerSession,
        dailyBoard: boolean,
    ) {
        worker.dispatchMessage(["framework", "files", false]);
        DigitalPlanningBoard.openProject(worker, session, dailyBoard);
    }.bind(this);

    private onCellClick(this: FilesOverlay, item: any) {
        if ("number" === typeof item?.pid_ts) {
            const session: WorkerSession = {
                pid: item.project.pid,
                pid_ts: item.pid_ts,
                project_token: item.project.project_token,
                resource: item.log?.file,
                master_token: item.log.master,
                sandbox: item.ts,
            };
            this.openProject(this.props.worker, session, false);
        }
    }

    public onClose = function (this: FilesOverlay) {
        this.props.worker.dispatchMessage(["framework", "files", false]);
    }.bind(this);

    /* REMOVE ME
    public onUpload=function(this:FilesOverlay) {
        this.setState({
            upload: {
                view: "upload"
            }
        });
    }.bind(this);

    public onNew=function(this:FilesOverlay) {
        this.setState({
            upload: {
                view: "details"
            }
        });
    }.bind(this);
    */

    public makeCellClickCB = function (this: FilesOverlay, item: any): any {
        return this.onCellClick.bind(this, item);
    }.bind(this);

    public onUploadDone = function (this: FilesOverlay, data: any) {
        if (data) {
            this.fetchProjects();
        }
    }.bind(this);

    render() {
        const cmd = this.props.worker.config.projects?.cmd;
        return [
            <Card
                key="cap"
                style={{
                    position: "fixed",
                    top: 50,
                    left: 10,
                    bottom: 50,
                    width: CONST.filesOverlayWidth,
                    zIndex: 200,
                    backgroundColor: "white",
                    boxShadow: "rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px",
                    overflow: "hidden",
                    display: this.props.visible ? undefined : "none",
                }}
            >
                {"function" === typeof this.props.worker.config?.FilesOverlay ? (
                    <this.props.worker.config.FilesOverlay
                        {...this.props}
                        projects={this.state.projects || []}
                        cbs={this}
                        cmd={cmd}
                    />
                ) : null}
            </Card>,
            //       (this.state.upload?<FileUploadPopup key={this.state.upload.key||this.state.upload.view} view={this.state.upload.view} viewOptions={this.state.upload.viewOptions} worker={this.props.worker} auth_token={this.props.worker.auth?.auth_token||null} auth_warmup={this.props.worker.warmup_result?.req?this.props.worker.warmup_result as {req:string} :null} project={null} onDone={this.state.upload?this.onUploadDone:null}/>:null),
            this.state.open ? <OpenDialog key={"open"} intl={intl} parent={this} /> : null,
        ];
    }
}
