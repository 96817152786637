﻿import { CanvasViewConst } from "../../../../model/DataModel";
import { CONST } from "../../../settings";

export class CanvasSizeHelper {
    public static getHeaderHeight(C: CanvasViewConst) {
        return CONST.titleHeight + CONST.subtitleHeight + C.colHeaderHeight;
    }

    public static getHeaderWidth(C: CanvasViewConst, l_min: number, l_max: number) {
        return (
            (l_max - l_min) * CONST.sidebarColWidth +
            CONST.menuCondensedWidth +
            CONST.sidebarColExtra +
            C.sidebarColImage
        );
    }
}
