import React from "react";
import { useLCMD } from "../../app/LCMContext";
import { intl } from "../../legacy/GlobalHelperReact";
import { LcmdModal2 } from "@/components/common/LcmModal2";
import { Button } from "@/components/ui/button";

type SecurityQueryModalProps = {
    show: boolean;
    onClose: () => void;
    card: any;
    attachment: any;
};

export function SecurityQueryModal({ show, onClose: handleClose, card, attachment }: SecurityQueryModalProps) {
    if (!handleClose) {
        console.warn("Modal can not be closed");
    }

    const LCMD = useLCMD();

    const handleDelete = () => {
        LCMD.deleteAttachments(card, [attachment.blobId]);
        console.log("deleted");
        if (handleClose) {
            handleClose();
        }
    };

    const btns = [
        <Button key="cancel" variant="outline" onClick={handleClose}>
            {intl.get("fw.attachment.securityQuery.buttons.cancel")}
        </Button>,
        <Button key="delete" onClick={handleDelete} variant="destructive">
            {intl.get("fw.attachment.securityQuery.buttons.main")}
        </Button>,
    ];

    return (
        <LcmdModal2
            open={show}
            onOpenChange={handleClose}
            size="s"
            buttons={btns}
            header={{
                title: intl.get("fw.attachment.securityQuery.title"),
            }}
        >
            {intl.get("fw.attachment.securityQuery.message")}
        </LcmdModal2>
    );
}
