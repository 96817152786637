import React, { ReactElement } from "react";
import { ICheckedValues } from "./ICheckedValues";
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@/lib/utils";
import { MenuMoreProps, MoreButtonDropdown } from "@/components/MoreButtonDropdown/MoreButtonDropdown";

type ToDoCheckbox = {
    headline: string;
    subtext: string;
    state: ICheckedValues;
    menuProps: MenuMoreProps[];
    onCheckBoxChange: (checked?: boolean) => void;
};

export const ToDoCheckbox = ({ headline, subtext, state, menuProps, onCheckBoxChange }: ToDoCheckbox): ReactElement => (
    <div className="my-2 flex items-center justify-between">
        <div className="flex items-center justify-between">
            <Checkbox
                className={cn(
                    "mr-1 h-5 w-5 rounded-full",
                    state === ICheckedValues.LATE && "border-[#333333] bg-red-500",
                    state === ICheckedValues.CHECKED &&
                        "bg-[#107C10] data-[state=checked]:border-[#107C10] data-[state=checked]:bg-[#107C10]",
                )}
                checked={state === ICheckedValues.CHECKED}
                onCheckedChange={onCheckBoxChange}
            />
            <div>
                <span className="ml-1 text-sm leading-5 text-gray-800">{headline}</span>
                <br />
                <span className="ml-1 text-xs font-normal leading-4 tracking-tighter text-[#565C60]">{subtext}</span>
            </div>
        </div>
        <MoreButtonDropdown menuItems={menuProps} />
    </div>
);
