﻿import { Icon, mergeStyleSets } from "@fluentui/react";
import { Icons } from "../../components/RegisteredIcons";
import { intl } from "../../legacy/GlobalHelperReact";

export interface ILegendProps {
    mode: "canvas" | "wb";
    details: null | "status" | "stability" | "conflict";
}

export function Legend({ details, mode }: ILegendProps) {
    const classList = mergeStyleSets({
        legend: {
            display: "flex",
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: 8,
            flexDirection: "row",
            marginBottom: 10,
            padding: 5,
            justifyContent: "center",
            alignItems: "center",
            pointerEvents: "none",
            userSelect: "none",
            fontFamily: "Inter",
        },
        legendText: {
            fontSize: 12,
            lineHeight: 16,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            color: "#2C3032",
            marginRight: "12px",
        },
        legendIcon: {
            transform: "translateY(-2px)",
            width: 16,
            height: 16,
            fontSize: 17,
            marginRight: "6px",
        },
    });

    return "canvas" === mode && details === "conflict" ? (
        <div className={classList.legend}>
            <Icon iconName={Icons.Lcmd_Circle_Filled} className={classList.legendIcon} style={{ color: "#107C10" }} />
            <div className={classList.legendText}>{intl.get("legend.noConflict")}</div>

            <Icon iconName={Icons.Lcmd_Circle_Filled} className={classList.legendIcon} style={{ color: "#D83B01" }} />
            <div className={classList.legendText}>{intl.get("legend.causeOfConflict")}</div>

            <Icon iconName={Icons.Lcmd_Add_Circle} className={classList.legendIcon} style={{ color: "#FFC000" }} />
            <div className={classList.legendText}>{intl.get("legend.affected")}</div>
        </div>
    ) : null;
}
