import { Widget } from "@typeform/embed-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { LcmdModal } from "../LcmModal";
import { useLCMD } from "../../../app/LCMContext";
import { intl } from "../../../legacy/GlobalHelperReact";
import { useNPS } from "../../hooks/useNPS.hook";
import { isOlderThanXDays } from "@/utils/DateUtils";

export function EmbeddedNPSModal() {
    const blacklist = [
        "7d3bfd62-9736-43af-9620-3be228c57dad",
        "318c4ed5-361d-496c-b5cb-ffc8616cb48a",
        "bdb3c39c-c300-463b-966e-77f7f2a4f74c",
        "1470f3d0-b202-466d-8655-a3d58279e7a9",
        "5303f258-37c3-4c52-aee4-a03c6c0d7f87",
        "4c15a906-06d7-483d-9430-3bdad0bb8b49",
        "bcd080b4-b062-47d1-bb39-780849c3eaee",
        "7c5161cc-a784-4996-b579-8f68a6a71c82",
        "5cd517e7-8537-4352-bdc2-e7e51faa31a5",
        "cdc05396-4ffb-4657-86d4-da70697b12b4",
        "a6ec47bc-e066-4d95-a562-f35a4d238294",
        "5af795e1-f41a-43ae-9c92-aa6b7c0fda40",
        "84eb8d60-28a2-4443-941c-6b7e9f72759d",
        "4546cf95-aed8-4490-b011-1478cb4a9de8",
        "fa5f620f-1277-4846-aad3-a4a13689c680",
        "f039360a-5bb0-471c-99ff-f26837147915",
        "3d4cc30f-b5e3-4f94-8a83-6ca011bd26da",
        "bc73a84a-9ab0-4c35-8c04-2762dfece39e",
        "59fe09e5-2f24-4c65-9c15-3a7b3dc1b91f",
        "fa0caa2c-9b3f-4b1e-bb02-9661d53fc517",
        "412c8e79-a44d-46bf-a63c-b5d9b10593c9",
        "a93d9724-ea03-437c-9e4a-94913bc62610",
        "cde30f83-8e0b-4285-be5b-46a02114a166",
        "ac6dc57f-faed-4883-9443-2e159688de01",
        "300cca1a-e1f0-4d8e-8dad-35b2f6057ff1",
        "4bc379e0-d530-41e3-94da-c41a46a11ae6",
        "ee41f56c-3c9a-4515-a877-71fb967396d8",
        "525730cd-bf65-4bf8-96a2-003f064796bb",
        "f0083521-69a3-4d41-afcd-a95ac2489b4a",
        "18f3e21e-ee29-4830-b5d9-799b781c5e3b",
        "d67856c0-7211-44f2-a9f1-7ed47f07cdb9",
        "1f2faa06-da1a-4cc2-8c52-3ee13963774f",
        "3c9f8005-b2c3-4c14-bc61-98a3c9e4a3cd",
        "5d0198d7-daa0-4104-a924-2419b3e49f53",
        "e02ee319-1552-4b4d-897d-784507350f4d",
        "961fc267-eacc-4200-b61c-4ecdd1e0b236",
        "6bfb030b-6310-4639-b1b0-fbf50e83e1e7",
        "2c352d26-84ec-4cf0-9d9e-72c2ccce4ac6",
        "dac608ba-13ac-4874-b89c-4e66f93d8075",
        "7217a958-1d05-4447-8dd6-a929bf7622b1",
        "8e7cbac8-97e5-4bbe-b3bf-690f0b2b5914",
        "f4c6f564-bf15-49c3-ad57-7615e262bc59",
        "616d481d-d52e-43d9-83c3-d44cc981f438",
        "f6a1fea1-66b0-49f3-b63f-0dcdc5fbcd0a",
        "a293299a-7850-41e7-af40-822db32502cc",
        "b7ff8a9f-cb91-4881-ad24-5270aca3b8a2",
        "a23e65eb-22f8-46d5-9a3c-ff83dff3547f",
        "575e55c2-302f-4d55-9ee1-adf2184fde19",
        "32926f69-95a3-4498-970f-02c2f8afbe0c",
        "895e0758-ab24-42a4-9841-dcdcebfb1bbc",
        "43ec1465-d9a3-4591-9c55-cf496af45d9a",
        "dc1d960e-cf89-4d71-bf9d-eb2e7a5b639c",
        "43b32700-a566-4e8a-a0a6-f289351995f0",
        "0d7ef186-f295-4f40-aa26-3dddf2a12272",
        "8c1d622e-6736-4491-8286-8650403e529c",
        "fa72cae9-9ffe-4c17-96c2-32d7f64d500c",
        "ea989ae4-20ec-4dab-b023-4d36c17a84c0",
        "cec32b23-4b5e-4949-8056-4da7d82c76dd",
        "ea4ee13f-1b2f-49c0-9130-5677bc9bef0c",
        "cfd8fc41-3f18-461c-914e-34f44208f2bc",
        "63f206e2-4821-40c6-aab9-f505b89f64b5",
        "5d178487-4f5e-47e1-a1cd-364e869bdd56",
        "4dc20af3-3a48-45d3-bc70-2e41c62d62c2",
        "9a54255b-3c56-48a0-a11a-a853c5062d7b",
        "a916b526-170b-4f1f-8dda-4e446a59e299",
        "b3850526-4ba0-4deb-9517-5f174dc68d68",
        "bffbf82e-1044-4f3b-8a1b-4dc441293e6f",
        "9fdf6c3b-bed9-4666-bc06-cfa7a57448de",
        "f45d8902-3694-459e-88de-c6d19e96500a",
        "d57ab896-1d56-4156-9f27-dc80adcb3584",
        "960a12cd-5cfa-4a08-bad5-e5ad0d03ab96",
        "aac849e3-7ab1-4fca-9862-dbdd5b67b6fe",
        "4e2c9585-b5ff-4a83-acbb-488a78d46ae9",
        "3865decc-9ab6-4ca5-9543-614ff3079672",
        "95cf87e0-9261-47ca-8f46-9970f0c7f637",
        "ecb9c55e-da22-4b2f-8728-fb15b1a48ffa",
        "21d26908-b5dc-4ba0-876f-01c6c3eaa7bf",
        "fc6e2c3f-b23e-4c1e-ab3a-7c0e8bc3c4bb",
        "254e4615-740f-43c0-8feb-71a43ce2d39e",
        "009aa3ff-0539-475f-ae06-fda5a4a726f8",
        "987433fc-319d-4302-b0d7-f4d4a4c21ce0",
        "cd312535-8ec7-4fae-8fac-b65ef723ab7d",
        "8d5732f2-f7b0-4bfe-bdaa-8ab9d39c55ba",
        "69221326-b77d-4584-a8ec-30888cd50087",
        "3d89d523-bbbe-4d22-97fa-af8e4c0b95e6",
        "01cfc5e8-62dd-4f94-bf21-5d94314786e6",
        "c14aebf3-64ed-46ae-b5bf-132c41af5c1a",
        "cc2f1e55-51fb-4b78-8f6b-70f701b7282f",
        "02183861-f9fd-491f-a893-33186ddce03e",
        "b1fb8fc9-c454-43fd-8bb8-f5e8d109af9f",
        "9e0fe529-1a50-4386-9efe-09b44aec4fef",
        "376d5cb9-63c4-42b7-b7ff-d0f93dc95e3c",
        "5af2bea0-62d2-49ef-ad92-e6c803f320fc",
        "b61406da-c83b-4be3-948f-455997e615be",
        "ce0aaa89-137d-4c9c-9350-aa9f44f00d74",
        "fa34c523-2dbd-4eb7-9db9-a22274e7a7e9",
        "7c225f5b-31c6-4771-b975-de58b0f35765",
        "0733c835-c4f8-40fc-a7ec-271c91216e2e",
        "4c08241e-6af6-4edc-b1f7-f4ddf8aed217",
        "9c303eaa-9efe-49f4-9961-137c0a77955a",
        "f7c62220-ad1a-4f28-a92f-87faeccd337b",
        "60936184-954d-4a5f-8c14-da33561a4048",
        "52d07619-1b84-48a6-92b5-0147e578cbc5",
        "09a49a9d-1138-4f1c-9c06-7b668c435abe",
        "517f5302-6245-4ead-9c9a-21f96ff20703",
        "b93bad53-2488-4618-ae56-07fac49f62a5",
        "42ebc919-4611-480c-a70d-4ce07453f483",
        "e9609d7c-06f5-4df7-98f9-b890bdb1079b",
        "6f8ae817-5691-48aa-b732-c8fbd3ce5d6b",
        "fbeb67ff-9b67-4698-9c1d-69b8ccf63054",
        "571dae3c-4a21-4fd0-bd5b-e628f4c4c8f6",
        "696803c2-97db-45e7-b72f-40ebd1a4a4fa",
        "97615d7f-9574-46ca-9854-c90d7278576d",
        "f1fbb7f7-0f82-45ce-a5a0-66091419d194",
        "4cb672af-49f3-45b2-a87b-9534bfc1bb39",
        "db972897-f22a-414c-b198-eb8aee9b5431",
        "10d89f28-1efa-4de2-88b0-bb4ced9e5404",
        "8dfba39f-11b0-43e0-ad10-d0ce296d308e",
        "33bdff3b-6ccf-4068-a45f-0d4119ef9718",
        "078dcbfc-8737-4573-8def-36260d8cbbef",
        "be425137-8201-485c-b93b-e571ce530a6c",
        "c447e2ba-9b9a-432c-9e1f-ced4a0552805",
        "31dda65e-7a91-4cb0-bf55-ecfd1683017d",
        "ae93f16e-b596-4df2-8876-d2c38c3ed3e7",
        "0de51a55-fb96-4127-9d44-bc35aa8eeb8e",
        "4533af01-d68c-4d58-bf3e-9270f29c8e32",
        "e4d535c6-45ea-4282-adfa-481aa77d4dac",
        "df2913ad-402f-44f3-9509-ad1fa70e065a",
        "a2dd63d2-9f44-43a6-809d-8ba9b98e2536",
        "347bdac3-0a53-4754-9929-002e2e7f78c4",
        "d182ccb2-6e2f-4cef-96a6-47cadc156865",
        "a6ac0c84-d200-4d20-b5c5-237a272c5cad",
        "3e4c2913-ab50-413a-a4ff-17c7f778fb03",
        //"77e78c20-26ea-479d-b231-40dd721afb41", => Christian Reuter
        "957ac606-954a-45df-8221-60581ec216fc",
        "ba69b8b3-0069-4690-8fff-69eb53ab8ec4",
        "3309b9cc-a458-4bf1-955b-36a04e5ca7d1",
        "c924f81a-33e3-4e82-96d8-0fa329995f5b",
        "05960af1-1f6b-441f-937b-3a1dc02c19b1",
        "c55527c3-1c6c-4f92-b0e2-7651c3a3bf82",
        "e48dd758-45a0-4e4b-a5a4-b1e62c6b7e64",
        "a0af47d6-bcd8-4f84-a999-d6c2e9a8742d",
        "bbf6f589-6cf6-497d-9bbd-a8fe76b37198",
        "c6a415aa-17a8-478b-ae29-35a14a6e62c2",
        "6802dcef-77bb-4c88-922b-67ff0b4458e0",
        "00a67367-e890-4aff-b88c-da46e364f247",
        "f4942280-9891-43f3-b640-1e1e088b0eee",
        "70812e50-04e2-450c-b69a-61e4b7f1f02b",
        "581030e2-764f-4f46-8c5a-694dd9d5aba2",
        "09a839de-6fdc-46e9-a12f-3e54cc49c74a",
        "9bc77b08-8b14-409c-8088-2dd6876bd9dd",
        "39d0a744-29b1-413a-b147-6b4b256f2b4b",
        "d53b3664-ad57-4d08-93e4-a58a75f59bd2",
        "7772a71d-12bb-45b2-8aa9-548582b443dd",
        "162a5d04-7677-4469-825b-ef9dc5a21691",
        "a78cc948-667a-4e3a-a729-bb5decadc468",
        "5b5d3d82-c4b3-496e-ad8a-3f0b9e16ca3f",
        "30668890-0e76-43b0-bb03-b599aa9fd890",
        "82b96ccb-7801-4903-a7f4-1075960d8d87",
        "8de1710e-26e6-4a76-9af0-e11dca720dd1",
        "4972bad6-21f3-4d54-ba11-13a8fa16334e",
        "b0090119-10df-46f7-93f0-c44e2a6028b9",
        "1703e54c-e47f-4588-96b7-9a2d15a0870f",
        "24b45f41-f208-4e10-a0c8-8a2b3082190a",
        "f6d18cfe-7eef-4208-81b1-13eae63153a6",
        "90359418-6120-4d0c-bee0-5da67df9d7b7",
        "d98e6681-c0ea-4fae-a9ce-ebcf27386523",
        "f56b34f9-575f-412b-98e7-cff1796e4a57",
        "2cd59109-7b54-40f4-ba0b-5527d34ac50f",
        "d4c04113-8cb6-440c-8fb7-5e94cd1124a9",
        "7d538dfd-262b-46d1-8272-3fad47bd0d41",
        "7c4c134f-6eea-4c37-bff8-c554cd013c48",
        "9bbf8d1c-87af-417a-96cd-4d4c1ebc5272",
        "c092d4d9-3f70-4804-87ea-517efb74eb02",
        "2507a0e9-fb3e-4c38-b424-68b5b5034633",
        "e157c5b3-fc3e-4e60-8a61-1fcf49f1587e",
        "8154b719-5611-46b5-824f-2136fd412f0d",
        "f0d5bd29-da95-418b-8cfa-b9f27c8a3cbe",
        "b11a96a2-2d1d-4702-a164-7cef7a732f8d",
        "f7f493f4-e28f-420e-bfd9-badc914cfbf5",
        "caec2dd1-ef06-4a8a-9e00-35367e825f3a",
        "b08bed9b-1dd3-4452-9af1-158bae2ac7ce",
        "e522ca3b-c879-4f56-af16-d4b20114a0c2",
        "e03ebf78-f1bd-4dad-a121-c7df9eebc558",
        "6c7a76e7-3eac-42e1-9dac-9d21d5f94adc",
        "1eb9ce82-8117-451d-9182-2928484b0fd1",
        "54279dd8-3a72-4d3e-9c32-abdbd0ec1a92",
        "a6af7546-f4ee-4701-8234-7bfcb0f22818",
        "9d8b8454-6318-45a1-92ee-1a9344d44a4d",
        "10252a3e-41ba-4276-9605-3ed3960ee691",
        "0413fb8d-aab4-4aaf-a099-23a179d804e5",
        "e1fe11f7-5af6-4f6f-88f9-57ddf87633a1",
        "ca0e3175-db5c-4cde-a0ed-cc51b5776d34",
        "32834207-25a0-4494-824c-0df7c21891d6",
        "c9ab5ff3-3fb0-4cd2-9530-284fe1411fc3",
        "47c3fcb4-bb1e-4af2-a889-596fb2bccf12",
        "e4538ead-3192-48d9-8c89-1a7be2086dd0",
        "0361244a-5d7b-4cae-85ed-108d6da5c389",
        "9dc02a76-e68b-4ef4-9887-cc5883295b4b",
        "0e6ae27d-592b-439a-86b9-e598f3f05304",
        "b66b72e8-11c9-4aa7-8dc2-6bd516d489aa",
        "9c2d9abe-e70a-465a-9a67-01af28747707",
        "447bcc64-a543-4e12-a9ea-c796d62cda84",
        "7ecc7db7-5190-4892-8262-7937e0850b4d",
        "653db700-8506-439c-be42-d8b27f349b1a",
        "72115efb-8f77-48bd-b9fc-f0aa2ee70840",
        "b62195c9-a50e-40ef-9be6-ca740c43c847",
        "0ce6b3e3-4f5f-4fcb-900d-cf0c838f5004",
        "e14eac3c-87af-4009-b0f3-d7936cf38c91",
        "c77ec9ba-6cc5-417a-91f0-f4bf0a511ace",
        "9b8b6e55-d5d4-416c-9c84-4ab841b8f8eb",
        "670d4f13-9bbe-48cf-8878-c3ff047912c0",
        "ddeee933-0e43-4756-9799-22d6c8986648",
        "a712626a-dfd4-431f-abb4-759f2c50e10e",
        "f20f9987-8cd3-4e93-b807-49c440e4a262",
        "88681702-69ac-4683-87f2-52261d9de512",
        "db457ca5-9a1d-4817-8b45-5dd96c026f55",
        "3d966e0c-804b-4520-b6e3-a0fdd9120a09",
        "95404d07-e726-498f-85e3-3e087928cab7",
        "96cfdfb7-0f43-4ea5-be08-e18ea6322819",
        "2a6d046e-9ff0-4c4e-9913-fd62d3c1bdb9",
        "524dd190-b986-4ed6-a712-94acb7105e08",
        "1e9f5f74-50bd-43ad-83bf-83988743df02",
        "0f44f9cc-ccb3-4a32-8d52-6c9c5a31a1c1",
        "7a1ec87a-fdf6-4877-bcd6-db625f4cba10",
        "76e2b535-4718-4939-9e61-35709fa56dfd",
        "89a7f326-a81f-4f49-9737-bc0d7a48d2a0",
        "cd1f305e-eb21-436d-8496-6792b6fa61f6",
        "85911c96-5646-452a-ac8e-873c5473379e",
        "1f976076-a97b-44a7-b3d0-999c81a014ab",
        "ad2960d8-ba06-483f-97a7-2756ddec2f40",
        "5cf8bce2-a2e0-41d0-9117-8a4d635290a6",
        "0f027b4f-6ce0-4aa4-84db-a3d96c39a520",
        "7ebc341f-bc99-458c-a00f-1f509d5de3fd",
        "6731674b-1588-45db-a9a0-16b86d4ca464",
        "e69eaa92-b092-496c-b471-4d1486bb5a1c",
        "6c7c64c6-dff6-4bf7-8bb6-c6503669725e",
        "ef12cbc6-0b89-4885-890f-5daaa112d6ea",
        "8b01889d-d341-451c-ab59-df22400bee96",
        "c2336f2d-7743-4a33-a93d-d130af2a542d",
        "fbf20012-beb6-484f-aaea-b7a8d8372fb5",
        "11667bf8-5c9f-4b97-b374-b2d0252284ac",
        "c7992518-aa7f-4330-8964-bd0363df08f2",
        "db6c13eb-bd43-4977-b026-611d547a80f3",
        "ec6d1406-f4fe-466e-9fa8-09c9ca4db9ca",
        "73f81e92-09b4-403f-9afd-8659bd544424",
        "707ac50b-2df4-40a7-9cf3-0f68ec6b1b88",
        "dd3b7ddb-452f-4f39-ad03-b6403efd39e6",
        "2177b973-cb19-46ec-b1da-fe7826087c1d",
        "58c6b765-d554-4a87-ac59-dfd183427d57",
        "ad9f78b2-cd86-4585-89cd-8fcf58d1d2b0",
        "2dba8340-6ea3-4a67-a976-a8d233b8eab4",
        "53f4c05d-43ee-4e31-92da-4ac5782b6246",
        "5b97f762-2757-43c7-af2e-4359f7abbe15",
        "8b0cdd2f-43d3-4321-b087-2a28d2563524",
        "54aaee10-5e57-48e2-83ab-9c1122e4c7bd",
        "09b6e1cb-f33e-4ff5-8a4d-abf86a3d5088",
        "aa0eab1d-41dc-4bad-b0fc-cd084c49041e",
        "ebffe8e2-3ee8-4232-80af-54eba9c92a7b",
        "2dfa92fb-3302-45ee-801f-17832518794e",
        "ff70ad1d-8050-4d71-b108-5382c5f92d54",
        "8dc3174b-1d62-4363-8187-91a06473dc82",
        "a9fe8158-c511-4540-a879-b3aa711ab46a",
        "21130601-47c1-47d4-a719-f377d5f35822",
        "7d4e40fc-c7c7-416f-90c6-36d7edfd2705",
        "a10e3bff-5e0a-4f77-810e-23b1e835c1c3",
        "c4ca89f9-6599-45da-be56-5c06425e3a62",
        "7fcea61a-2674-40c5-bbfa-2dc1e125d4ae",
        "29d2b373-972d-4eaa-bc61-0db54dc45441",
        "9898bdf9-069a-4ff4-b444-b2d45356d463",
        "37862d3d-66c5-4eba-9ef0-d653d243a588",
        "739d7dba-7f91-472f-aec1-aec32df18df5",
        "517004a7-088d-42c8-b537-ba699d7e7b4d",
        "d3942c54-d547-489e-bbe3-f649a8a7aedd",
        "db0ec445-eb24-4fcd-935f-36f8d544baea",
        "fffe96af-6459-48ec-a046-fe87e1ff6811",
        "87b375b6-488f-413e-bf6b-3c8dd649235c",
        "3735792c-f415-42c6-90a7-4ca998084f34",
        "9e6c4bbf-8fc9-482c-91a2-fb6fc4d44625",
        "e5b3b161-fd29-4c64-b1ae-09fe3210542a",
        "35101f46-ffa1-4eee-888f-b5c0e99bc243",
        "86317018-97e9-41ae-9e07-d1bf89400459",
        "e298df64-8944-4917-a29b-81073f775839",
    ];

    const LCMD = useLCMD();
    const [isInBlacklist, setIsInBlacklist] = useState<boolean>(null);
    const [userId, setUserId] = useState<string>("");
    const { data: npsData, error, isError, isLoading: isNPSViewdStatusLoading, saveNPSViewed } = useNPS(userId);
    const formId = intl.getInitOptions().currentLocale == "de-DE" ? "cYzbwVzh" : "l2QWDGq1";
    const endDate = new Date(2024, 12, 1);
    const currentDate = new Date();
    const isNPSOver = endDate < currentDate;
    const [isModalOpen, setModalOpen] = useState(true);

    useEffect(() => {
        LCMD.getAuthUser((error, user) => {
            if (error) {
                return;
            }
            if (!(user.meta?.registrationDate && isOlderThanXDays(new Date(user.meta.registrationDate), 30))) {
                return;
            }

            setUserId(user.sub);
            setIsInBlacklist(
                blacklist.includes(user.sub) ||
                    user.email.includes("@lcmd.io") ||
                    user.email.includes("@lcmdigital.com"),
            );
        });
    }, []);

    useEffect(() => {
        //assert(!error, "Load NPS Error");
    }, [error, isError]);

    function hideModal() {
        setModalOpen(false);
    }

    function disableNPS() {
        if (userId == "") {
            return;
        }
        saveNPSViewed(userId);
    }

    const isNPSModalVisible =
        isInBlacklist !== null &&
        !isInBlacklist &&
        !isNPSOver &&
        !isNPSViewdStatusLoading &&
        !npsData?.viewed &&
        !isError;

    return (
        isNPSModalVisible && (
            <>
                <LcmdModal
                    isOpen={isModalOpen}
                    header={{ title: "" }}
                    isBlocking={true}
                    onDismiss={() => {
                        disableNPS();
                        hideModal();
                    }}
                >
                    <Widget
                        id={formId}
                        style={{ width: "800px", height: "600px" }}
                        className="my-form"
                        redirectTarget="_self"
                        hidden={{
                            user_id: userId,
                        }}
                    />
                </LcmdModal>
            </>
        )
    );
}
