import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ModalAssetHeader } from "../../ModalAsset";
import {
    CheckboxVisibility,
    DefaultButton,
    DetailsList,
    IColumn,
    Icon,
    PrimaryButton,
    SearchBox,
    Selection,
    SelectionMode,
} from "@fluentui/react";
import { ModalAssetFooter } from "../../ModalAsset/ModalAssetFooter";
import { useEffect, useState, useRef } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { RouteNames } from "../filter.interface";
import { useToDoModalFilterStore } from "../../../../app/store/toDoModalFilterStore";
import { styles } from "../filterModalStyles";

type ColumnItem = {
    key: string;
    name: string;
    status: string;
};

export function StatusFilter() {
    const { filterData, setFilterData } = useToDoModalFilterStore();

    const navigate = useNavigate();
    const allItems = useRef([
        {
            key: "open",
            name: "Open",
            status: intl.get("ModalToDoFilter.StatusFilter.statusOpen"),
        },
        {
            key: "overdue",
            name: "Overdue",
            status: intl.get("ModalToDoFilter.StatusFilter.statusOverdue"),
        },
        {
            key: "done",
            name: "Done",
            status: intl.get("ModalToDoFilter.StatusFilter.statusDone"),
        },
    ]);
    const [items, setItems] = useState(allItems.current);
    const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
    const [selection] = useState(
        new Selection({
            onSelectionChanged: () => {
                setSelectedIndices(selection.getSelectedIndices());
            },
        }),
    );

    const columns = [
        {
            key: "status",
            name: intl.get("ModalToDoFilter.StatusFilter.columnStatus"),
            fieldName: "status",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
    ];

    useEffect(() => {
        const localFilterData = filterData?.statusFilterData || [];
        localFilterData?.forEach(({ key }) => {
            const i = allItems.current.findIndex((s) => s.key === key);
            selection.setIndexSelected(i, true, false);
        });
    }, [filterData?.statusFilterData]);

    function headerTitle() {
        return (
            <>
                {intl.get("ModalToDoFilter.StatusFilter.filter")} / {intl.get("ModalToDoFilter.StatusFilter.status")}
            </>
        );
    }

    function renderItemColumn(item: ColumnItem, index?: number, column?: IColumn) {
        if (typeof column === "undefined") {
            return false;
        }
        const style = {
            fontSize: "14px",
            display: "flex",
        };
        const fieldContent = item[column.fieldName];

        switch (column.key) {
            case "status":
                let icon = null;
                switch (item.name) {
                    case "Open":
                        icon = (
                            <Icon
                                iconName={"LocationDot"}
                                styles={{
                                    root: { color: "#C1C5C7", fontSize: 18, marginRight: 5 },
                                }}
                            />
                        );
                        break;
                    case "Overdue":
                        icon = (
                            <Icon
                                iconName={"Clock"}
                                styles={{
                                    root: { color: "#D83B01", fontSize: 18, marginRight: 5 },
                                }}
                            />
                        );
                        break;
                    case "Done":
                        icon = (
                            <Icon
                                iconName={"CheckMark"}
                                styles={{
                                    root: { color: "#107C10", fontSize: 18, marginRight: 5 },
                                }}
                            />
                        );
                        break;
                }

                return (
                    <div style={style}>
                        {icon} {fieldContent}
                    </div>
                );
            default:
                return <div style={style}>{fieldContent}</div>;
        }
    }

    function footerLeftContent() {
        return (
            <DefaultButton
                styles={{
                    root: { border: "none" },
                    rootDisabled: { backgroundColor: "transparent" },
                }}
                disabled={!selectedIndices.length}
                text={intl.get("ModalToDoFilter.StatusFilter.clearFilters")}
                iconProps={{ iconName: "Delete" }}
                onClick={() => {
                    selection.setAllSelected(false);
                }}
            />
        );
    }

    function footerRightContent() {
        return (
            <PrimaryButton
                text={intl.get("ModalToDoFilter.StatusFilter.done")}
                onClick={() => {
                    setFilterData({
                        ...filterData,
                        statusFilterData: selectedIndices.map((i) => items[i]),
                    });

                    navigate(RouteNames.HOME);
                }}
            />
        );
    }

    function onChangeText(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string) {
        const { current: allStatuses } = allItems;

        setItems(
            text ? allStatuses.filter((i) => i.status?.toLowerCase().indexOf(text.toLowerCase()) > -1) : allStatuses,
        );
    }

    return (
        <div>
            <ModalAssetHeader
                onBackButtonChange={() => {
                    navigate(RouteNames.HOME);
                }}
                title={headerTitle()}
            />

            <div className={styles.body}>
                <div className={styles.searchField}>
                    <SearchBox placeholder={intl.get("ModalToDoFilter.StatusFilter.search")} onChange={onChangeText} />
                </div>
                <DetailsList
                    items={items}
                    columns={columns}
                    onRenderItemColumn={renderItemColumn}
                    setKey="set"
                    compact={true}
                    checkboxVisibility={CheckboxVisibility.always}
                    selectionMode={SelectionMode.multiple}
                    selectionPreservedOnEmptyClick={true}
                    enterModalSelectionOnTouch={true}
                    selection={selection}
                />
            </div>

            <ModalAssetFooter leftContent={footerLeftContent()} rightContent={footerRightContent()} />
        </div>
    );
}
