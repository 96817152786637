import { Dialog, DialogFooter, DialogType, PrimaryButton, IDialogProps, DefaultButton } from "@fluentui/react";
import * as React from "react";
import { ReactElement } from "react";
import { intl } from "lcmd2framework";

type ConfirmDialog = IDialogProps & {
    title: string;
    subText?: string;
    hidden: boolean;
    onOk: () => void;
    onCancel: () => void;
};

export function ConfirmDialog({ title, subText = "", hidden, onOk, onCancel }: ConfirmDialog): ReactElement {
    if (typeof title !== "string") {
        throw new Error("title param needs to be type of string");
    }

    if (typeof subText !== "string") {
        throw new Error("subText param needs to be type of string or undefined");
    }

    if (typeof hidden !== "boolean") {
        throw new Error("hidden param needs to be type of boolean");
    }

    if (typeof onOk !== "function") {
        throw new Error("onOk param needs to be a function");
    }

    if (typeof onCancel !== "function") {
        throw new Error("onCancel param needs to be a function");
    }

    return (
        <Dialog
            hidden={hidden}
            dialogContentProps={{
                type: DialogType.normal,
                title: title,
                subText: subText ? subText : null,
            }}
        >
            <DialogFooter>
                <PrimaryButton
                    onClick={() => {
                        onOk();
                    }}
                    text={intl.get("fw.ok")}
                />
                <DefaultButton
                    onClick={() => {
                        onCancel();
                    }}
                    text={intl.get("fw.cancel")}
                />
            </DialogFooter>
        </Dialog>
    );
}
