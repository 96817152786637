import { getLCMD } from "@/app/LCMContext";
import { useCalendarStore } from "../app/store/saveCalendarSettings";
import { ActiveWeekdays } from "../components/common/active-weekday-selector/ActiveWeekdaySelector.component";
import {
    CalendarSettingsExtension,
    WeekStartDayExtension,
} from "../components/settings/calendar-settings/CalendarSettings.view.component";
import { CalendarException } from "../components/settings/calendar-settings/calendarSettings.interfaces";
import { CoreExtensions } from "../extensions/core.extensions";

export interface SettingsData {
    firstDayInWeek: string | number;
    activeWeekDays: ActiveWeekdays;
    calendarExceptions?: CalendarException[];
}

export const saveSettings = (settings: SettingsData) => {
    const LCMD = getLCMD();
    const setChangedState = useCalendarStore.getState().setChanged;

    const calendarWorkingDaysAndExceptionsCommand: CalendarSettingsExtension = {
        lcmdx: 1.0,
        id: CoreExtensions.CALENDAR_DEFAULT_SETTINGS,
        calendar: {
            wd: settings.activeWeekDays,
            exceptions: settings.calendarExceptions || [],
        },
    };

    const weekStartDayUpdateCommand: WeekStartDayExtension = {
        lcmdx: 1.0,
        id: CoreExtensions.CALENDAR_SETTINGS,
        calendar: {
            weekStartDay: parseInt(`${settings.firstDayInWeek}`),
        },
    };

    LCMD.putExtension([weekStartDayUpdateCommand, calendarWorkingDaysAndExceptionsCommand]);
    setChangedState(false);
};
