import * as React from "react";
import { CanvasCommonProps } from "./Canvas";
import type { CanvasViewConst } from "../../../model/DataModel";
import {
    ColorPicker,
    ContextualMenu,
    DefaultButton,
    Facepile,
    FontWeights,
    getTheme,
    IconButton,
    IContextualMenuItem,
    IContextualMenuProps,
    IIconProps,
    Label,
    Link,
    mergeStyleSets,
    Pivot,
    PivotItem,
    PrimaryButton,
    SearchBox,
    Stack,
    TextField,
} from "@fluentui/react";
import { intl } from "../../GlobalHelperReact";
import { needsWhiteColor } from "../../../model/GlobalHelper";
import StackedDialog from "../StackedDialog";
import { registerSub } from "../../../model/ApiHelper";
import { Dialog, DialogScreen } from "../../api/Dialog";
import { useParticleContext } from "../../api/ParticleContext";
import DigitalPlanningBoard from "../DigitalPlanningBoard";
import { MainWorkerMessage } from "../../MainWorkerPipe";
import StackedDialogView from "../StackedDialogView";
import { SubCache } from "@/legacy/SubCache";

const theme = getTheme();
const classNames = mergeStyleSets({
    panel: {
        overscrollBehavior: "none",
    },
    container: {
        top: 60,
        //backgroundColor: "red",
        overscrollBehavior: "none",
    },
    search: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: "white",
    },
    trade: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: "white",
        textAlign: "center",
    },
    card: {
        marginTop: 16,
        marginBottom: 16,
        marginLeft: 16,
        marginRight: 16,
        padding: 8,
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        borderRadius: 2,
        height: 100,
        fontSize: 16,
        textOverflow: "ellipsis",
        overflow: "hidden",
        "&:focus": {
            border: "2px solid black",
        },
    },
    more: {
        float: "right",
    },
    tradeEMail: {
        width: "400",
    },
    rgb: {
        width: 50,
    },
    color: {
        width: 32,
        height: 32,
    },
    header: [
        theme.fonts.large,
        {
            flex: "0 0 auto",
            color: theme.palette.neutralPrimary,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px",
        },
    ],
});
const cancelIcon: IIconProps = { iconName: "Cancel" };
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginTop: "4px",
        display: "flex",
        marginLeft: "auto",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
const COLORS = [0x00b050, 0x00b0f0, 0x508cb8, 0xc00000, 0x7030a0, 0x748088, 0x579c98, 0xffe100, 0xe47005];

type CanvasAddPanelProps = CanvasCommonProps & {
    isOpen: boolean;
    onClose: () => void;
};

type CanvasAddPanelState = {
    const: CanvasViewConst;
    templates: any[];
    lib: any[];
    filter: string | null;
    editTrade: {
        id: number;
        name: string;
        color: number;
    } | null;
    editLibItem: {
        id: number;
    } | null;
    panel: string | "trades" | "lib";
    clip: (any & {})[];
};

export class CanvasAddPanel extends React.Component<CanvasAddPanelProps, CanvasAddPanelState> {
    private static personaCtxItems: IContextualMenuItem[] = [
        {
            key: "delete",
            text: "Remove",
        },
    ];
    state = {
        const: this.props.worker.canvas.viewConst,
        templates: null,
        lib: null,
        filter: null,
        editTrade: null,
        editLibItem: null,
        panel: "trades",
        clip: [],
    };
    EditDialog = function (props: { trade: any; onClose: () => void }) {
        const cb = React.useMemo(
            () => ({
                onCancel: props.onClose,
                onClose: props.onClose,
                onApply: (trade) => {
                    const trade_trade = "string" === typeof trade.trade ? trade.trade || null : undefined;
                    if (
                        -1 === trade.id ||
                        trade.color !== props.trade.color ||
                        trade.name !== props.trade.name ||
                        trade.subs !== props.trade.subs ||
                        trade_trade !== props.trade.trade
                    ) {
                        this.props.worker.postMessage([
                            "trade",
                            "update",
                            {
                                id: trade.id,
                                color: -1 === trade.id || trade.color !== props.trade.color ? trade.color : undefined,
                                name: -1 === trade.id || trade.name !== props.trade.name ? trade.name : undefined,
                                trade:
                                    -1 === trade.id || trade_trade !== props.trade.trade
                                        ? trade_trade || null
                                        : undefined,
                                subs: -1 === trade.id || trade.subs !== props.trade.subs ? trade.subs : undefined,
                            },
                        ]);
                    }
                    props.onClose();
                },
            }),
            [props.onClose],
        );
        const init = React.useMemo(() => {
            return function (ctx, state) {
                ctx.pushView(<this.TradeDialogView cb={cb} trade={props.trade} ctx={ctx} />);
            }.bind(this);
        }, [cb, props.trade]);
        return <StackedDialog width={800} height={500} onInitialView={init} isOpen={true} />;
    }.bind(this);
    LibItemScreen = function ({
        dialog,
        route: {
            params: { item, onClose },
        },
    }) {
        const LCMD = useParticleContext();
        const [name, setName] = React.useState("");
        React.useLayoutEffect(() => {
            dialog.setOptions({
                title: intl.get("addPanel.Library"),
                onOK: () => {
                    setName((name) => {
                        LCMD.worker.postMessage([
                            "lib",
                            "add",
                            {
                                id: item.id,
                                name,
                            },
                        ]);
                        return name; // no change
                    });
                    onClose();
                },
                onCancel: onClose,
                onClose: onClose,
            });
        }, [LCMD.worker, dialog, onClose, setName]);
        return (
            <Stack tokens={{ childrenGap: 15, padding: 15 }}>
                <TextField label="Name" value={name} onChange={(ev, v) => setName(v)} />
            </Stack>
        );
    }.bind(this);
    LibItemDialog = function ({ item, onClose }) {
        return (
            <Dialog
                initialScreen={(dialog) => {
                    dialog.pushScreen("main", {
                        item,
                        onClose,
                    });
                }}
                isOpen={true}
            >
                <DialogScreen name="main" component={this.LibItemScreen} />
            </Dialog>
        );
    }.bind(this);
    cardMenuProps: IContextualMenuProps = {
        items: [
            {
                key: "edit",
                text: intl.get("addPanel.Edit trade"),
                iconProps: { iconName: "Edit" },
            },
            {
                key: "delete",
                text: intl.get("addPanel.Delete trade"),
                iconProps: { iconName: "delete" },
                disabled: true,
            },
        ],
        isBeakVisible: false,
    };
    LibItem = function ({ item }) {
        //const id=[item.t, item.i, item.j].join('_');
        const _L = "_L" + (item.t + 1).toString(16);
        return (
            <div
                className={classNames.card}
                id={_L}
                data-lib-id={item.t + 1}
                tabIndex={-1}
                style={{
                    color: "black",
                    backgroundColor: "white",
                    boxSizing: "border-box",
                    border: "4px solid black",
                }}
            >
                {item.label}
            </div>
        );
    }.bind(this);
    ClipItem = function ({ item }) {
        const _C = "_C";
        return (
            <div
                className={classNames.card}
                data-image={item.image || undefined}
                id={_C}
                tabIndex={-1}
                style={{
                    color: "black",
                    backgroundColor: "white",
                    boxSizing: "border-box",
                    border: "4px solid black",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundImage: item?.image ? "url(" + item.image + ")" : undefined,
                }}
            >
                {item.name ||
                    (Array.isArray(item?.canvas) ? item.canvas.map((item) => <div>{item?.name || "?"}</div>) : "")}
            </div>
        );
    }.bind(this);
    onWorkerMsg = function (this: CanvasAddPanel, msg: MainWorkerMessage) {
        switch (msg[0]) {
            case "canvas":
                {
                    const canvas = msg[1];
                    const C = canvas?.viewConst || this.state.const;
                    if (C !== this.state.const || canvas?.tradesPatch) {
                        this.setState({
                            const: C,
                            templates: canvas.trades,
                            editTrade: canvas?.tradesPatch ? null : this.state.editTrade,
                        });
                    }
                }
                break;
            /*
            case "templates": {
                const trades=msg[1];
                this.setState({
                    templates: trades,
                    editTrade: null // trades.length>0?trades[0]:null
                });
            }   break;
            */
            case "wb":
                {
                    const wb = msg[1];
                    const lib = wb.stripes || this.state.lib;
                    if (lib !== this.state.lib) {
                        this.setState({
                            lib: lib,
                        });
                    }
                }
                break;
            case "toggle":
                {
                    if ("lib.props" === msg[1]) {
                        this.setState({
                            editLibItem: msg[2],
                        });
                    } else if ("trade.edit" === msg[1]) {
                        this.setState({
                            editTrade: msg[2],
                        });
                    } else if ("trade.create" === msg[1]) {
                        this.setState({
                            editTrade: {
                                id: -1,
                                name: "",
                                color: COLORS[0],
                            },
                        });
                    }
                }
                break;
            case "add":
                {
                    const C = this.state.const;
                    const add = msg[1];
                    if ("stop" === add.state && add.add) {
                        if ("L" === add?.mouseDown?.templateType) {
                            const i = this.state.lib.findIndex((e) => e.t + 1 === add?.mouseDown?.templateId);
                            if (0 <= i && i < this.state.lib.length && !C.readonly) {
                                this.props.worker.postMessage([
                                    "wb",
                                    "pastepp",
                                    {
                                        lib: add.mouseDown.templateId - 1,
                                        stripe: add.add.stripe,
                                        stripet: add.add.stripet,
                                        stripei: add.add.stripei,
                                        stripej: add.add.stripej,
                                        x1: add.add.x1,
                                        x2: add.add.x1 + 5,
                                        y: add.add.stripey,
                                    },
                                ]);
                            }
                        } else {
                            // add trade
                            const i = this.state.templates.findIndex((e) => e.id === add.add.trade);
                            if (0 <= i && i < this.state.templates.length && !C.readonly) {
                                const text = this.state.templates[i].name;
                                const days = 5;
                                const fs = DigitalPlanningBoard.calcCardFontSize(days * C.colPx, C.rowPx, text);
                                this.props.worker.postMessage([
                                    "add",
                                    {
                                        trade: add.add.trade,
                                        stripe: add.add.stripe,
                                        stripet: add.add.stripet,
                                        stripei: add.add.stripei,
                                        stripej: add.add.stripej,
                                        x1: add.add.x1,
                                        x2: add.add.x1 + 5,
                                        y: add.add.stripey,
                                        fs: fs,
                                        text: text,
                                    },
                                ]);
                            }
                        }
                    }
                }
                break;
            case "clip":
                {
                    const pushClipItem = (item) => {
                        this.setState((state) => {
                            const clip = state.clip.slice();
                            item.key = clip.length;
                            clip.unshift(item);
                            return { ...state, clip };
                        });
                    };
                    if ("paste" === msg[1]) {
                        const ev: ClipboardEvent = msg[2];
                        for (let i = 0; i < ev.clipboardData.files.length; i++) {
                            const f = ev.clipboardData.files[i];
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                pushClipItem({
                                    image: e.target.result,
                                });
                            };
                            reader.readAsDataURL(f);
                        }
                        ev.preventDefault();
                    } else if ("canvas" === msg[1]) {
                        const data = msg[2];
                        pushClipItem({
                            canvas: data,
                        });
                    }
                }
                break;
            default:
                break;
        }
    }.bind(this);
    private WaitForSUB = function (props: { ctx; entry }) {
        return <StackedDialogView title="Registering User..." actions={[]} onCancel={() => {}} />;
    }.bind(this);
    private AddExecuteApp = function (props: { onCancel; onApply; ctx; entry }) {
        const [entry, setEntry] = React.useState({ ...props.entry });
        return (
            <StackedDialogView
                title="LCM Execute App"
                onCancel={() => {}}
                actions={[
                    <DefaultButton
                        key={0}
                        text="Cancel"
                        onClick={() => {
                            if (props.onCancel) {
                                props.onCancel();
                            }
                            props.ctx.popView();
                        }}
                    />,
                    <PrimaryButton
                        key={1}
                        text="OK"
                        onClick={() => {
                            if (entry.email) {
                                props.ctx.replaceView(<this.WaitForSUB {...props} />);
                                registerSub(
                                    this.props.worker.auth?.auth_token,
                                    (error, result) => {
                                        if (!error && result?.sub) {
                                            if (props.onApply) {
                                                props.onApply({ ...entry, sub: result.sub });
                                            }
                                            props.ctx.popView();
                                        } else {
                                            if (props.onCancel) {
                                                props.onCancel();
                                            }
                                            props.ctx.popView();
                                        }
                                    },
                                    entry.email,
                                );
                                /*
                    this.props.worker.postMessage(["sub", "add", {
                        auth_token: this.props.worker.auth?.auth_token,
                        email: entry.email,
                        cb: this.props.worker.registerCallback((data)=>{
                            if (data?.sub) {
                                if (props.onApply) {
                                    props.onApply({ ...entry, sub: data.sub });
                                }
                                props.ctx.popView();
                            } else {
                                if (props.onCancel) {
                                    props.onCancel();
                                }
                                props.ctx.popView();
                            }
                        })
                    }]);
                    */
                            }
                        }}
                    />,
                ]}
            >
                <Stack tokens={{ childrenGap: 15 }}>
                    <TextField
                        inputClassName={classNames.tradeEMail}
                        label="E-Mail"
                        value={entry?.email || ""}
                        onChange={(ev) => {
                            const email = (ev.target as any).value;
                            setEntry((entry) => ({ ...entry, email: email }));
                        }}
                    />
                </Stack>
            </StackedDialogView>
        );
    }.bind(this);
    private ColorPicker = function (props: { cb; ctx; trade }) {
        const [trade, setTrade] = React.useState(props.trade);
        return (
            <StackedDialogView
                title="Edit Trade"
                onCancel={() => {
                    props.cb.onCancel();
                    props.ctx.popView();
                }}
                actions={[
                    <DefaultButton
                        key={0}
                        text="Cancel"
                        onClick={() => {
                            props.cb.onCancel();
                            props.ctx.popView();
                        }}
                    />,
                    <PrimaryButton
                        key={1}
                        text="OK"
                        onClick={() => {
                            props.cb.onApply(trade);
                            props.ctx.popView();
                        }}
                    />,
                ]}
            >
                <ColorPicker
                    color={"#" + trade.color.toString(16).padStart(6, "0")}
                    onChange={(ev, color) => {
                        const _color = Number.parseInt(color.hex, 16);
                        setTrade({ ...trade, color: _color });
                    }}
                    alphaType="none"
                />
            </StackedDialogView>
        );
    }.bind(this);
    private TradeDialogView = function (props: { cb; ctx; trade }) {
        const [trade, setTrade] = React.useState(this.fixTrade(this.setColorToTrade(props.trade, props.trade.color)));
        const [personas, setPersonas] = React.useState([]);
        const [personaCtx, setPersonaCtx] = React.useState(null);
        const onPersonaCtxItemsClick = React.useMemo(
            () => (ev, item) => {
                if ("delete" === item.key) {
                    setPersonaCtx((personaCtx) => {
                        if (personaCtx.persona?.data?.sub) {
                            const sub = personaCtx.persona.data.sub;
                            setTrade((trade) => {
                                const subs = { ...(trade.subs || {}) };
                                if (sub in (props.trade?.subs || {})) {
                                    subs[sub] = false; // was commited => mark deleted
                                } else {
                                    delete subs[sub]; // never commited => remove right away...
                                }
                                return { ...trade, subs: subs };
                            });
                            /*
                        this.props.worker.postMessage(["sub", "remove", {
                            auth_token: this.props.worker.auth?.auth_token,
                            sub: sub,
                            cb: this.props.worker.registerCallback((data)=>{
                                if (!(data.subs||{})[sub]) {
                                    setTrade((trade)=>{
                                        const subs=({...(trade.subs||{})});
                                        if (sub in (props.trade?.subs || {})) {
                                            subs[sub]=false; // was commited => mark deleted
                                        } else {
                                            delete subs[sub]; // never commited => remove right away...
                                        }
                                        return {...trade, subs: subs};
                                    })
                                }
                            })
                        }]);
                        */
                        }
                        return null;
                    });
                }
            },
            [setPersonaCtx, setTrade],
        );
        const onPersonaCtxItemsDismiss = React.useMemo(
            () => () => {
                setPersonaCtx(null);
            },
            [setPersonaCtx],
        );
        const cb = React.useMemo(
            () => ({
                onApply: (trade) => {
                    setTrade(trade);
                },
                onCancel: () => {},
            }),
            [setTrade],
        );
        React.useEffect(() => {
            SubCache.getPersonas(
                this.props.worker.auth?.auth_token,
                Object.getOwnPropertyNames(trade.subs || {}).filter((sub) => false !== trade.subs[sub]),
                (personas) => {
                    setPersonas(
                        personas.map((persona) => ({
                            ...persona,
                            onClick: (ev, persona) => {
                                setPersonaCtx({
                                    target: ev.target,
                                    persona: persona,
                                });
                            },
                        })),
                    );
                },
            );
        }, [trade.subs, setPersonaCtx]);
        const addButtonProps = React.useMemo(
            () => ({
                //ariaLabel: 'Add a new person to the Facepile',
                onClick: (ev: React.MouseEvent<HTMLButtonElement>) => {
                    props.ctx.pushView(
                        <this.AddExecuteApp
                            ctx={props.ctx}
                            onApply={(entry) => {
                                if (entry.sub) {
                                    setTrade((trade) => ({
                                        ...trade,
                                        subs: { ...(trade.subs || {}), [entry.sub]: {} },
                                    }));
                                }
                            }}
                        />,
                    );
                },
            }),
            [props.ctx],
        );
        //const color=[Number.parseInt(trade._r, 10).toString(16).padStart(2, '0'), Number.parseInt(trade._g, 10).toString(16).padStart(2, '0'), Number.parseInt(trade._b, 10).toString(16).padStart(2, '0')].join('');
        const color = trade.color;
        const sandbox_db =
            "number" === typeof this.props?.projectSandbox?.sandbox || this.props?.projectSandbox?.sandbox_db;
        return (
            <StackedDialogView
                title={intl.get("trade.edit.title")}
                actions={[
                    <DefaultButton key={0} text={intl.get("fw.cancel")} onClick={props.cb.onClose} />,
                    <PrimaryButton
                        key={1}
                        text={intl.get("fw.ok")}
                        onClick={() => {
                            props.cb.onApply(trade);
                        }}
                        data-userpilot-id="canvasAddPanel-okButton"
                    />,
                ]}
                onCancel={props.cb.onCancel}
            >
                <Stack
                    tokens={{
                        childrenGap: 15,
                    }}
                >
                    {"string" === typeof trade.trade ? (
                        <TextField
                            styles={{
                                root: {
                                    with: 240,
                                },
                            }}
                            id="trade_trade"
                            label={intl.get("trade.edit.trade")}
                            value={trade.trade}
                            onChange={(ev) => {
                                const name = (ev.target as any).value;
                                setTrade((trade) => ({ ...trade, trade: name }));
                            }}
                        />
                    ) : null}
                    <TextField
                        styles={{
                            root: {
                                with: 240,
                            },
                        }}
                        id="trade_name"
                        label={intl.get("trade.edit.name")}
                        value={trade.name}
                        onChange={(ev) => {
                            const name = (ev.target as any).value;
                            setTrade((trade) => ({ ...trade, name: name }));
                        }}
                        data-userpilot-id={"canvasAddPanel-newTradeName"}
                    />
                    <Label>{intl.get("trade.edit.color")}</Label>
                    <Stack
                        horizontal
                        tokens={{
                            childrenGap: 8,
                        }}
                    >
                        <TextField
                            prefix="R:"
                            styles={{ root: { width: 80 } }}
                            value={trade._r}
                            onChange={(ev) =>
                                setTrade(this._maskColor(trade, (ev.target as HTMLInputElement).value, 16, "_r"))
                            }
                        />
                        <TextField
                            prefix="G:"
                            styles={{ root: { width: 80 } }}
                            value={trade._g}
                            onChange={(ev) =>
                                setTrade(this._maskColor(trade, (ev.target as HTMLInputElement).value, 8, "_g"))
                            }
                        />
                        <TextField
                            prefix="B:"
                            styles={{ root: { width: 80 } }}
                            value={trade._b}
                            onChange={(ev) =>
                                setTrade(this._maskColor(trade, (ev.target as HTMLInputElement).value, 0, "_b"))
                            }
                        />
                        <IconButton
                            className={classNames.more}
                            styles={{
                                root: {
                                    color: "#565C60",
                                },
                            }}
                            iconProps={{
                                iconName: "ColorSolid",
                            }}
                            onClick={() => {
                                props.ctx.pushView(
                                    <this.ColorPicker cb={cb} ctx={props.ctx} trade={{ ...trade, color: color }} />,
                                );
                            }}
                            data-userpilot-id={"canvasAddPanel-colorPickerButton"}
                        />
                    </Stack>
                    <Stack
                        horizontal
                        tokens={{
                            childrenGap: 8,
                        }}
                    >
                        {COLORS.map((color) => (
                            <div
                                key={color}
                                className={classNames.color}
                                style={{
                                    backgroundColor: "#" + color.toString(16).padStart(6, "0"),
                                    outline: color === trade?.color ? "2px solid black" : undefined,
                                }}
                                onClick={() => setTrade(this.setColorToTrade(trade, color))}
                            />
                        ))}
                    </Stack>
                    {sandbox_db ? (
                        <>
                            <Label>LCM Execute App</Label>
                            <Facepile
                                personas={personas}
                                maxDisplayablePersonas={10}
                                showAddButton
                                addButtonProps={addButtonProps}
                            />
                            <ContextualMenu
                                items={CanvasAddPanel.personaCtxItems}
                                hidden={null === personaCtx}
                                target={personaCtx?.target || null}
                                onItemClick={onPersonaCtxItemsClick}
                                onDismiss={onPersonaCtxItemsDismiss}
                            />
                        </>
                    ) : null}
                </Stack>
            </StackedDialogView>
        );
    }.bind(this);
    private onContextMenuAs = function (this: CanvasAddPanel, menuProps: IContextualMenuProps) {
        const tradeId = Number.parseInt(
            ((menuProps?.target as HTMLElement)?.parentNode as HTMLElement)?.dataset?.tradeId,
        );
        if (!Number.isNaN(tradeId) && tradeId > 0) {
            const menuPropsBound = Object.assign({}, menuProps, {
                items: menuProps.items.map((entry) =>
                    Object.assign({}, entry, {
                        onClick: this.onContextMenu.bind(this, tradeId - 1),
                        disabled: entry.disabled || this.state.const.readonly,
                    }),
                ),
            });
            return <ContextualMenu {...menuPropsBound} />;
        } else {
            return null;
        }
    }.bind(this);
    Card = function ({ item }) {
        const w = needsWhiteColor(item.color);
        const color = w ? "rgb(255,255,255)" : "#1B2126";
        const _color = "#" + item.color.toString(16).padStart(6, "0");
        const _A = "_A" + (item.id + 1).toString(16);
        return (
            <div
                className={classNames.card}
                id={_A}
                data-trade-id={item.id + 1}
                tabIndex={-1}
                style={{
                    color: color,
                    backgroundColor: _color,
                }}
            >
                <IconButton
                    className={classNames.more}
                    styles={{
                        root: {
                            backgroundColor: "transparent",
                            color: color,
                        },
                    }}
                    iconProps={{
                        iconName: "MoreVertical",
                    }}
                    menuProps={this.cardMenuProps}
                    data-selection-disabled={true}
                    data-is-focusable={false}
                    split={false}
                    onRenderMenuIcon={() => null}
                    menuAs={this.onContextMenuAs}
                />
                {item.label}
            </div>
        );
    }.bind(this);
    private onChange = function (this: CanvasAddPanel, event: any) {
        const value: string = event.target.value;
        this.setState({
            filter: value.toLocaleLowerCase(),
        });
    }.bind(this);
    private onEditClose = function (this: CanvasAddPanel) {
        this.setState({
            editTrade: null,
            editLibItem: null,
        });
    }.bind(this);
    private onSearch = function (this: CanvasAddPanel, v: string) {
        this.setState({
            filter: "string" === typeof v && v.length > 0 ? v.toUpperCase() : null,
        });
    }.bind(this);
    private onSearchChange = function (this: CanvasAddPanel, ev: any, v: string) {
        this.setState({
            filter: "string" === typeof v && v.length > 0 ? v.toUpperCase() : null,
        });
    }.bind(this);
    private onNewTrade = function (this: CanvasAddPanel) {
        this.setState({
            editTrade: {
                id: -1,
                name: "",
                color: COLORS[0],
            },
        });
    }.bind(this);
    private onNewLibItem = function (this: CanvasAddPanel) {
        this.setState({
            editLibItem: {
                id: -1,
            },
        });
    }.bind(this);
    private onClearClip = function (this: CanvasAddPanel) {
        this.setState({
            clip: [],
        });
    }.bind(this);
    private onSelectPanel = function (this: CanvasAddPanel, item?: PivotItem) {
        if (item?.props?.itemKey) {
            this.setState({
                panel: item.props.itemKey,
            });
        }
    }.bind(this);

    componentDidMount(this: CanvasAddPanel) {
        this.props.worker.registerHandler(this.onWorkerMsg);
    }

    componentWillUnmount(this: CanvasAddPanel) {
        this.props.worker.unregisterHandler(this.onWorkerMsg);
    }

    render(this: CanvasAddPanel) {
        //
        /*
            <Panel key="cap" className={classNames.panel} isOpen={this.props.isOpen} headerText={intl.get("addPanel.Trades")} isBlocking={false}  onDismiss={this.props.onClose} closeButtonAriaLabel="Close" >
                <ScrollablePane onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp} className={classNames.container}>
                </ScrollablePane>
            </Panel>
        */
        return (
            <>
                {this.props.isOpen ? (
                    <div
                        key="cap"
                        className={classNames.panel}
                        style={{
                            position: "fixed",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            width: 340,
                            overflowY: "scroll",
                            zIndex: 200,
                            backgroundColor: "white",
                            boxShadow:
                                "rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px",
                        }}
                    >
                        <div
                            style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "white",
                                zIndex: 201,
                            }}
                        >
                            <div className={classNames.header}>
                                <Pivot selectedKey={this.state.panel} onLinkClick={this.onSelectPanel}>
                                    <PivotItem headerText={intl.get("addPanel.Trades")} itemKey="trades" />
                                    {true || "development" === process.env.NODE_ENV ? (
                                        <PivotItem headerText={intl.get("addPanel.Library")} itemKey="lib" />
                                    ) : null}
                                    {"development" === process.env.NODE_ENV ? (
                                        <PivotItem headerText="Clipboard" itemKey="clip" />
                                    ) : null}
                                </Pivot>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={this.props.onClose}
                                />
                            </div>
                            {"clip" !== this.state.panel ? (
                                <div className={classNames.search}>
                                    <SearchBox
                                        placeholder={intl.get("addPanel.Search")}
                                        value={this.state.filter || ""}
                                        onChange={this.onSearchChange}
                                        onSearch={this.onSearch}
                                    />
                                </div>
                            ) : null}
                        </div>
                        {"trades" === this.state.panel
                            ? (this.state.templates || [])
                                  .filter(
                                      (item) =>
                                          null === this.state.filter ||
                                          (item?.label || "").toUpperCase().indexOf(this.state.filter) >= 0,
                                  )
                                  .map((item) => <this.Card key={item.id} item={item} />)
                            : "lib" === this.state.panel
                            ? this.filterLib(this.state.filter).map((item) => <this.LibItem key={item.t} item={item} />)
                            : "clip" === this.state.panel
                            ? (this.state.clip || []).map((item) => <this.ClipItem key={item.key} item={item} />)
                            : null}
                        <div
                            style={{
                                position: "sticky",
                                bottom: 0,
                                backgroundColor: "white",
                                zIndex: 201,
                            }}
                        >
                            <div className={classNames.trade}>
                                {"trades" === this.state.panel ? (
                                    <Link onClick={this.onNewTrade} disabled={this.state.const.readonly}>
                                        {intl.get("addPanel.addTrade")}
                                    </Link>
                                ) : "lib" === this.state.panel ? (
                                    <Link
                                        onClick={this.onNewLibItem}
                                        disabled={this.state.const.readonly}
                                        data-drop-action={"lib-add"}
                                    >
                                        {intl.get("addPanel.addLibItem")}
                                    </Link>
                                ) : "clip" === this.state.panel ? (
                                    <Link onClick={this.onClearClip}>Clear Clipboard</Link>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
                {null !== this.state.editTrade ? (
                    <this.EditDialog key="cad" trade={this.state.editTrade} onClose={this.onEditClose} />
                ) : null}
                {null !== this.state.editLibItem ? (
                    <this.LibItemDialog key="lad" item={this.state.editLibItem} onClose={this.onEditClose} />
                ) : null}
            </>
        );
    }

    private setColorToTrade(trade, color) {
        const ret = {
            ...trade,
            color: color,
            _r: ((color >> 16) & 0xff).toString(10),
            _g: ((color >> 8) & 0xff).toString(10),
            _b: ((color >> 0) & 0xff).toString(10),
        };
        return ret;
    }

    private _maskColor(trade, v, s, p) {
        const _v = Number.parseInt(v, 10);
        const color = (trade.color & ~(0xff << s)) | ((v & 0xff) << s);
        return { ...trade, color: color, [p]: v };
    }

    private fixTrade(trade) {
        if (trade.trade && trade.name === trade.trade) {
            return { ...trade, trade: null };
        } else {
            return trade;
        }
    }

    private onContextMenu(this: CanvasAddPanel, tradeId) {
        const i_item = this.state.templates.findIndex((item) => item.id === tradeId);
        this.setState({
            editTrade: i_item >= 0 ? this.state.templates[i_item] : null,
        });
    }

    private filterLib(filter: string) {
        //(this.state.lib||[]).filter((item)=>null===this.state.filter || (item?.name||"").toUpperCase().indexOf(this.state.filter)>=0)
        return this.state.lib;
    }
}
