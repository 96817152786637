import * as React from "react";
import { ReactNode } from "react";

import { mergeStyleSets, Stack, StackItem } from "@fluentui/react";

const MAX_WIDTH = 240;
const MAX_HEIGHT = 300;

export type LayoutProps = {
    header: ReactNode;
    search: ReactNode;
    items: ReactNode;
};

const classNames = mergeStyleSets({
    layout: { width: MAX_WIDTH },
    itemsSections: { overflow: "auto" },
});

const tokensProps = { maxHeight: MAX_HEIGHT };
const headerTokensProps = { padding: 10, childrenGap: 5 };

export function Layout(props: LayoutProps) {
    const { header, search, items } = props;

    return (
        <Stack tokens={tokensProps} className={classNames.layout}>
            <Stack tokens={headerTokensProps}>
                {header}
                {search}
            </Stack>
            <StackItem className={classNames.itemsSections}>{items}</StackItem>
        </Stack>
    );
}
