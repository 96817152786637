import * as React from "react";
import { Icon } from "@fluentui/react";
import { FilterRowTop } from "./FilterRowTop";
import { intl } from "@/legacy/GlobalHelperReact";
import { filterRowStyles } from "./filterRowStyles";

type FilterItemRow = {
    label: string;
    badgeCount: number;
    data: any[];
    onModifyButtonClick: () => void;
};

export function StatusFilterRow({ label, badgeCount, onModifyButtonClick, data }: FilterItemRow) {
    const options = {
        open: (
            <Icon
                iconName={"LocationDot"}
                styles={{
                    root: { color: "#C1C5C7", fontSize: 18, marginRight: 3 },
                }}
            />
        ),
        overdue: (
            <Icon
                iconName={"Clock"}
                styles={{
                    root: { color: "#D83B01", fontSize: 18, marginRight: 3 },
                }}
            />
        ),
        done: (
            <Icon
                iconName={"CheckMark"}
                styles={{
                    root: { color: "#107C10", fontSize: 18, marginRight: 3 },
                }}
            />
        ),
    };

    function translateStatus(status: string) {
        switch (status) {
            case "open":
                return intl.get("ModalToDoFilter.StatusFilterRow.statusOpen");
            case "overdue":
                return intl.get("ModalToDoFilter.StatusFilterRow.statusOverdue");
            case "done":
                return intl.get("ModalToDoFilter.StatusFilterRow.statusDone");
        }
    }

    return (
        <div className={filterRowStyles.body}>
            <FilterRowTop onModifyButtonClick={onModifyButtonClick} label={label} badgeCount={badgeCount} />
            {data.map((s) => {
                return (
                    <div className={filterRowStyles.button} key={s.key}>
                        {options[s.key]}
                        <span>{translateStatus(s.key)}</span>
                    </div>
                );
            })}
        </div>
    );
}
