import * as React from "react";
import { DataModelFilterCollection, generateMoreColumn } from "lcmd2framework";
import {
    getTheme,
    ScrollablePane,
    SearchBox,
    SelectionMode,
    ConstrainMode,
    IColumn,
    DetailsList,
    Selection,
    DialogType,
} from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";
import { useLCMD } from "../../../../app/LCMContext";

const theme = getTheme();

export function ManageScreen({
    dialog,
    route: {
        params: { onEdit, filterOptions },
    },
}) {
    const LCMD = useLCMD();
    const [reload, setReload] = React.useState(0);
    const [filterCollection, setFilterCollection] = React.useState([]);
    React.useEffect(() => {
        return LCMD.loadFilterData((data) => {
            const wbs = data?.wbs || [];
            const trades = data?.trades || [];
            const filterCollection: DataModelFilterCollection = data?.filterCollection || [];
            setFilterCollection(filterCollection);
        });
    }, [setFilterCollection, reload]);
    const [selection, setSelection] = React.useState(new Selection());
    const [search, setSearch] = React.useState(null);
    const onSearch = React.useMemo(
        () => (v: string) => {
            setSearch("string" === typeof v && v.length > 0 ? v : null);
        },
        [setSearch],
    );
    const onSearchChange = React.useMemo(
        () => (ev: any, v: string) => {
            setSearch("string" === typeof v && v.length > 0 ? v : null);
        },
        [setSearch],
    );
    React.useLayoutEffect(() => {
        dialog.setOptions({
            title: intl.get("filter.manage.title"),
            onOK: () => {
                onEdit(undefined);
            },
            onCancel: onEdit.bind(null, undefined),
            onClose: onEdit.bind(null, undefined),
        });
    }, [dialog, onEdit]);
    const onContextMenu = React.useCallback(
        (selection: Selection, item: any, ev: any, action: any) => {
            if ("rename" === action.key) {
                dialog.pushScreen("save", {
                    LCMD,
                    filter: item,
                    onSave: (name: string) => {
                        const nextFilter = {
                            ...item._filter,
                            saveAs: {
                                hid: item._hid,
                                pos: item._pos,
                                name: name,
                            },
                        };

                        LCMD.setFilter(nextFilter, filterOptions);
                        setReload((value) => 1 + value);
                        dialog.popScreen();
                    },
                    onCancel: () => dialog.popScreen(),
                });
            } else if ("edit" === action.key) {
                dialog.pushScreen("main", {
                    LCMD,
                    editFilterEntityId: item._hid,
                    onClose: () => {
                        setReload((value) => 1 + value);
                        dialog.popScreen();
                    },
                });
            } else if ("delete" === action.key) {
                dialog.showPopup({
                    ok: {
                        text: null,
                        onClick: () => {
                            LCMD.setFilter(
                                {
                                    ...item._filter,
                                    saveAs: {
                                        hid: item._hid,
                                        pos: item._pos,
                                        name: null,
                                    },
                                },
                                filterOptions,
                            );
                            setReload((value) => 1 + value);
                            dialog.showPopup(null);
                        },
                        styles: {
                            root: {
                                backgroundColor: theme.semanticColors.severeWarningIcon,
                            },
                        },
                    },
                    cancel: {
                        text: null,
                        onClick: () => {
                            dialog.showPopup(null);
                        },
                    },
                    dialogContentProps: {
                        type: DialogType.normal,
                        title: intl.get("filter.manage.delete.title"),
                        closeButtonAriaLabel: "Close",
                        subText: intl.get("filter.manage.delete.subText", {
                            name: item.name,
                        }),
                    },
                });
            }
        },
        [dialog, setReload],
    );
    const items = React.useMemo(
        () =>
            filterCollection.filter(
                (item) =>
                    !search ||
                    0 === search.length ||
                    (item.name || "").toLowerCase().indexOf(search.toLowerCase()) >= 0,
            ),
        [filterCollection, search],
    );
    return (
        <div
            style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                rowGap: 5,
                padding: 8,
            }}
        >
            <SearchBox
                placeholder={intl.get("filter.manage.search")}
                value={search || ""}
                onChange={onSearchChange}
                onSearch={onSearch}
            />
            <ScrollablePane
                styles={{
                    root: {
                        width: "100%",
                        height: "100%",
                        position: "relative",
                    },
                }}
            >
                <DetailsList
                    compact={true}
                    columns={
                        [
                            {
                                key: "name",
                                name: intl.get("filter.manage.name"),
                                onRender: (item) => {
                                    return item?.name;
                                },
                            },
                            generateMoreColumn.call(
                                this,
                                {
                                    items: [
                                        {
                                            key: "rename",
                                            text: intl.get("filter.manage.cmd.rename.text"),
                                            title: intl.get("filter.manage.cmd.rename.title"),
                                            iconProps: {
                                                iconName: intl.get("filter.manage.cmd.rename.icon"),
                                            },
                                        },
                                        {
                                            key: "edit",
                                            text: intl.get("filter.manage.cmd.edit.text"),
                                            title: intl.get("filter.manage.cmd.edit.title"),
                                            iconProps: {
                                                iconName: intl.get("filter.manage.cmd.edit.icon"),
                                            },
                                        },
                                        {
                                            key: "delete",
                                            text: intl.get("filter.manage.cmd.delete.text"),
                                            title: intl.get("filter.manage.cmd.delete.title"),
                                            iconProps: {
                                                iconName: intl.get("filter.manage.cmd.delete.icon"),
                                            },
                                        },
                                    ],
                                },
                                selection,
                                onContextMenu,
                            ),
                        ] as IColumn[]
                    }
                    items={items}
                    selectionMode={SelectionMode.none}
                    selection={selection}
                    constrainMode={ConstrainMode.unconstrained}
                    useReducedRowRenderer={true}
                    getKey={(item) => item._hid}
                    onRenderDetailsHeader={() => null}
                    onRenderRow={(props, defaultRender) => {
                        return defaultRender({ ...props });
                    }}
                />
            </ScrollablePane>
        </div>
    );
}
