import * as React from "react";
import { intl } from "lcmd2framework";
import { useLCMD } from "../../app/LCMContext";
import { Settings } from "../Settings";
import { LcmdModal } from "../common/LcmModal";

import { PreferenceSettings } from "../settings/preferences/PreferenceSettings";
import { ProfileSettings } from "../settings/profile-settings/profile-settings";

export function ProjectAccountDialog(props: {}) {
    const LCMD = useLCMD();
    const navigationOptions = [
        {
            links: [
                {
                    name: intl.get("profileSettings.headline"),
                    key: "lcmd.settings.profile",
                    url: "",
                    view: <ProfileSettings />,
                },
                {
                    name: intl.get("Settings.Preferences.header"),
                    description: intl.get("Settings.Preferences.description"),
                    key: "lcmd.settings.preferences",
                    url: "",
                    view: <PreferenceSettings />,
                },
            ],
        },
    ];

    return (
        <LcmdModal
            header={{ title: intl.get("PersonalSettings.headerTitle") }}
            styles={{ main: { minWidth: "1200px", minHeight: "600px", width: "80%", height: "95%" } }}
            buttons={[]}
            isOpen={true}
            onDismiss={() => {
                LCMD.showDialog("dialog.project.account", false);
            }}
        >
            <Settings navigationOptions={navigationOptions}></Settings>
        </LcmdModal>
    );
}
