import { useState } from "react";
import { LCMDHookResult } from "../../app/types/LCMDHookResult";
import { useLCMD } from "../../app/LCMContext";

export function useCurrentBaseline() {
    const LCMD = useLCMD();
    const [updateRef, setUpdateRef] = useState(Symbol());
    const [hookState, setHookState] = useState<
        LCMDHookResult<{
            activebaseline: number;
        }>
    >({
        isLoading: true,
        isError: false,
        error: undefined,
        data: undefined,
    });

    const setBaseline = (activebaseline: number) => {
        if (!activebaseline) {
            throw new Error("Please provide baseline to store in Settings");
        }

        const newSettings = {
            ["global"]: {
                activebaseline: activebaseline,
            },
        };
        LCMD.setSettingsProps(newSettings, () => {
            LCMD.setBaselineId(activebaseline);
            setUpdateRef(Symbol());
        });
    };

    LCMD.useSettingsEffect(
        ["activebaseline"],
        ({ global }: { global: { activebaseline: number } }) => {
            const activebaseline: { activebaseline: number } = global?.activebaseline && {
                activebaseline: global.activebaseline,
            };
            setHookState({
                ...hookState,
                isLoading: false,
                data: activebaseline,
            });
        },
        [updateRef],
    );

    return { ...hookState, setBaseline } as const;
}
