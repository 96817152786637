import { UserMap } from "@/components/hooks/UserMapTypes";
import { useUserMap } from "@/components/hooks/userMap.hook";
import { intl } from "@/legacy/GlobalHelperReact";
import { useEffect, useMemo, useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { IconButton } from "../IconButton/IconButton";
import { Send } from "lucide-react";
import { useLCMD } from "@/app/LCMContext";
import { LCMDContextAuthUser } from "@/legacy/api/lcmd2framework";

type MentionsComponentProps = {
    className?: string;
    handleSendComment: (event, value: string) => void;
    styles?: any;
    mentionStyles?: any;
};

const mentionsComponentStyle = {
    input: {
        border: "1px solid black",
        borderRadius: "8px",
        padding: "5px",
    },
    suggestions: {
        list: {
            backgroundColor: "white",
            border: "1px solid #E1E4E5",
            fontSize: 14,
            maxHeight: "300px",
            overflowY: "auto",
        },
        item: {
            padding: "5px 15px",
            borderBottom: "1px solid #E1E4E5",
            "&focused": {
                backgroundColor: "#E1E4E5",
            },
        },
    },
    "&multiLine": {
        input: {
            padding: 9,
            overflow: "auto",
            height: 150,
        },
        highlighter: {
            padding: 9,
            boxSizing: "border-box",
            overflow: "hidden",
            height: 150,
        },
    },
};

export const MentionsComponent = ({
    className,
    handleSendComment,
    styles = null,
    mentionStyles,
}: MentionsComponentProps) => {
    const LCMD = useLCMD();
    const userMap: UserMap = useUserMap();
    const [text, setText] = useState("");
    const [currentUser, setCurrentUser] = useState<LCMDContextAuthUser>(null);
    const users = Object.entries(userMap).map((user) => Object.assign(user[1], { key: user[0] }));

    const usersToDisplay = useMemo(() => {
        if (currentUser) {
            return users
                .filter((user) => user.key !== currentUser.sub)
                .map((user) => ({
                    id: user.key,
                    display:
                        user.meta.firstName && user.meta.lastName
                            ? [user.meta.firstName, user.meta.lastName].filter(Boolean).join(" ")
                            : user.email,
                }));
        }
    }, [users, currentUser]);

    const sendComment = (event, input) => {
        const formattedInput = input
            .replace(/@\[[^\]]+\]\(([^)]+)\)/g, (match, p1) => `\${"${p1}"}`)
            .replace(/\n/g, "<br>");

        handleSendComment(event, formattedInput);
        setText("");
    };

    useEffect(() => {
        LCMD.getAuthUser((error, user) => {
            if (error) {
                console.error(error);
            } else {
                setCurrentUser(user);
            }
        });
    }, []);

    return (
        <div className="relative">
            <MentionsInput
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && !(e.key === "Enter" && e.shiftKey)) {
                        e.preventDefault();
                        sendComment(e, text);
                    }
                }}
                placeholder={intl.get("HistoryView.NewCommentTextareaPlaceholder")}
                singleLine={false}
                className={className}
                allowSuggestionsAboveCursor
                style={{ ...mentionsComponentStyle, ...styles }}
            >
                <Mention
                    trigger="@"
                    data={usersToDisplay}
                    markup="@[__display__](__id__)"
                    style={{ backgroundColor: "#E1E4E5", ...mentionStyles }}
                    renderSuggestion={(user) => <div>{user.display}</div>}
                />
            </MentionsInput>
            <IconButton
                icon={Send}
                className="absolute bottom-2 right-2 hover:bg-transparent"
                onClick={() => sendComment(null, text)}
            />
        </div>
    );
};
