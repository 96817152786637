import * as React from "react";
import { DefaultButton, IButtonProps } from "@fluentui/react";

export function DangerButton(props: IButtonProps) {
    const styles = { ...props.styles, color: "#D13501" };

    return (
        <DefaultButton {...props} styles={styles} className={"dangerButton"}>
            {props.children}
        </DefaultButton>
    );
}
