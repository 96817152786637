﻿import * as React from "react";
import { useEffect, useReducer } from "react";
import { CanvasCommonProps, CanvasCommonState, CanvasViewPinchState, CanvasViewPort, CanvasViewProps } from "./Canvas";
import { CanvasTaskData } from "../../../model/DataModel";
import { MainWorkerMessage } from "../../MainWorkerPipe";
import { assert, gfu } from "../../../model/GlobalHelper";

type CanvasDragOverlayProps = CanvasCommonProps &
    CanvasViewProps & {
        divRefs: { CO: HTMLDivElement };
        hidden: boolean;
    };

type CanvasDragOverlayState = CanvasCommonState & {
    tasks: CanvasTaskData[];
    availWidth: number;
    availHeight: number;
    viewWidth: number;
    viewHeight: number;
    viewLeft: number;
    viewTop: number;
    hidden: boolean;
    left: number;
    top: number;
    width: number;
    height: number;
    multisel: {
        left: number;
        top: number;
        width: number;
        height: number;
    }[];
    pinch: CanvasViewPinchState | null;
};

export function CanvasDragOverlay({ worker, view, divRefs, hidden }: CanvasDragOverlayProps) {
    const [state, setState] = useReducer(
        (current: CanvasDragOverlayState, update: Partial<CanvasDragOverlayState>): CanvasDragOverlayState => ({
            ...current,
            ...update,
        }),
        {
            scale: 1.0,
            const: worker.canvas.viewConst,
            rows: 0,
            cols: 0,
            l_min: 0,
            l_max: 0,
            tasks: [],
            availWidth: 0,
            availHeight: 0,
            viewLeft: 0,
            viewTop: 0,
            viewWidth: 0,
            viewHeight: 0,
            hidden: true,
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            multisel: [],
            pinch: null,
        },
    );

    useEffect(() => {
        worker.registerHandler(handleWorkerMsg);
        return () => {
            worker.unregisterHandler(handleWorkerMsg);
        };
    }, []);

    useEffect(() => {
        window.requestAnimationFrame(updateCanvas);
    }, [view.pinch, state.pinch]);

    function onRef(r: any) {
        divRefs.CO = r;
    }

    let _canvasRef: HTMLCanvasElement = null;

    function updateCanvas() {
        const canvas = _canvasRef;
        if (canvas?.getContext && state.cols > 0 && state.rows > 0 && state.scale > 0 && state.pinch) {
            const s = state.pinch.scale;
            const C = state.const;

            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, state.availWidth, state.availHeight);

            ctx.save();
            const pinch = state.pinch;
            const ev = pinch.ev;
            if (2 === ev.touches.length) {
                ctx.transform(pinch._s, 0, 0, pinch._s, -(pinch._s - 1) * pinch.mx, -(pinch._s - 1) * pinch.my);

                ctx.translate(pinch.mx - pinch._mx, pinch.my - pinch._my);
            }
            const ta = state.tasks;
            const tn = ta.length;
            for (let i = 0; i < tn; i++) {
                const t = ta[i];
                ctx.fillStyle = "#" + t.color.toString(16).padStart(6, "0");
                ctx.fillRect(
                    state.viewLeft + (t.left * s - pinch.left),
                    state.viewTop + (t.top * s - pinch.top),
                    (t.right - t.left) * s,
                    C.rowPx * s,
                );
            }
            ctx.restore();
        }
    }

    function handleWorkerMsg(msg: MainWorkerMessage) {
        switch (msg[0]) {
            case "canvas":
                {
                    const canvas = msg[1];
                    setState({ ..._updateView(canvas, view, state) });
                }
                break;
            case "view":
                {
                    const view = msg[1];
                    setState({ ..._updateView(null, view, state) });
                }
                break;
            case "drag":
                {
                    const C = state.const;
                    const drag = msg[1];
                    const scale = view.scale;
                    const t = drag.mouseDown?.task;
                    const inf2ColsBeforePx = view.inf2ColsBefore * C.colPx;
                    const inf2RowsPx = view.inf2Rows * C.rowPx;
                    switch (drag.state) {
                        case "start":
                            drag?.mouseDown?.task
                                ? (drag.multisel || []).map((t) => {
                                      const left = (t.left - drag?.mouseDown?.task.left) * scale;
                                      const top = (t.top - drag?.mouseDown?.task.top) * scale;
                                      return {
                                          left: left,
                                          top: top,
                                          width: (t.right - t.left) * scale,
                                          height: C.rowPx * scale,
                                      };
                                  })
                                : [];
                            setState({
                                hidden: false,
                                left: (t.left + inf2ColsBeforePx) * scale + view.rowHeaderWidth - view.left,
                                top: (t.top + inf2RowsPx) * scale + view.colHeaderHeight - view.top,
                                width: (t.right - t.left) * scale,
                                height: C.rowPx * scale,
                                multisel: drag?.mouseDown?.task,
                            });
                            break;
                        case "stop":
                            setState({ hidden: true, left: 0, top: 0, width: 0, height: 0 });
                            break;
                        case "move":
                            setState({
                                left:
                                    (drag.mouseDown.dragX + inf2ColsBeforePx) * scale + view.rowHeaderWidth - view.left,
                                top: (drag.mouseDown.dragY + inf2RowsPx) * scale + view.colHeaderHeight - view.top,
                            });
                            break;
                    }
                }
                break;
            case "add":
                {
                    const C = state.const;
                    const add = msg[1];
                    const scale = view.scale;
                    switch (add.state) {
                        case "start":
                            setState({
                                hidden: false,
                                left: add.mouseDown.dragX * scale + view.rowHeaderWidth - view.left,
                                top: add.mouseDown.dragY * scale + view.colHeaderHeight - view.top,
                                width: C.colPx * 5 * scale,
                                height: C.rowPx * scale,
                                multisel: [],
                            });
                            break;
                        case "stop":
                            setState({ hidden: true, left: 0, top: 0, width: 0, height: 0 });
                            break;
                        case "move":
                            setState({
                                left: add.mouseDown.dragX * scale + view.rowHeaderWidth - view.left,
                                top: add.mouseDown.dragY * scale + view.colHeaderHeight - view.top,
                            });
                            break;
                    }
                }
                break;
            case "pinch":
                {
                    const cmd = msg[1];
                    switch (cmd) {
                        case "view":
                            {
                                if (view.pinch) {
                                    if (view.pinch === state.pinch) {
                                        window.requestAnimationFrame(updateCanvas); // update canvas
                                    } else {
                                        setState(
                                            { pinch: view.pinch }, // update canvas in useEffect hook
                                        );
                                    }
                                } else {
                                    if (null !== state.pinch) {
                                        // update view
                                        if (0 === view.scaling_lock) {
                                            const pinch = state.pinch;
                                            const dx = pinch.mx - pinch._mx;
                                            const dy = pinch.my - pinch._my;
                                            const scale0 = pinch.scale;
                                            const scale = scale0 * pinch._s;
                                            const x = pinch.left / pinch.scale;
                                            const y = pinch.top / pinch.scale;
                                            const _x = x + pinch._mx / scale0 - pinch.rowHeaderWidth / scale0;
                                            const _y = y + pinch._my / scale0 - pinch.colHeaderHeight / scale0;
                                            const sx = _x * scale - (pinch.mx - pinch.rowHeaderWidth);
                                            const sy = _y * scale - (pinch.my - pinch.colHeaderHeight);

                                            view.scaling_lock++;
                                            worker.dispatchMessage([
                                                "zoom",
                                                {
                                                    scale: scale,
                                                    left: sx,
                                                    top: sy,
                                                },
                                            ]);
                                        }
                                        setState({ pinch: null });
                                    } else {
                                        assert(null === view.pinch && null === state.pinch);
                                    }
                                }
                            }
                            break;
                    }
                }
                break;
        }
    }

    function onCanvasRef(r: HTMLCanvasElement) {
        _canvasRef = r;
        window.requestAnimationFrame(updateCanvas);
    }

    function _updateView(canvas: any, view: CanvasViewPort | null, state: CanvasDragOverlayState) {
        const scale = view.scale || state.scale || 1;
        const C = canvas?.viewConst || state.const;
        const cols = gfu(canvas?.cols, state.cols, 0);
        const rows = gfu(canvas?.rows, state.rows, 0);
        const l_min = gfu(canvas?.l_min, state.l_min, 0);
        const l_max = gfu(canvas?.l_max, state.l_max, 0);
        const availWidth = gfu(view.availWidth, state.availWidth, 0);
        const availHeight = gfu(view.availHeight, state.availHeight, 0);
        const viewWidth = gfu(view.width, state.viewWidth, 0);
        const viewHeight = gfu(view.height, state.viewHeight, 0);
        const viewTop = gfu(view.colHeaderHeight, state.viewTop, 0);
        const viewLeft = gfu(view.rowHeaderWidth, state.viewLeft, 0);
        const tasks = canvas?.tasks || state.tasks || [];
        if (
            scale !== state.scale ||
            C !== state.const ||
            cols !== state.cols ||
            rows !== state.rows ||
            l_min !== state.l_min ||
            l_max !== state.l_max ||
            availWidth !== state.availWidth ||
            availHeight !== state.availHeight ||
            viewWidth !== state.viewWidth ||
            viewHeight !== state.viewHeight ||
            viewTop !== state.viewTop ||
            viewLeft !== state.viewLeft ||
            tasks !== state.tasks
        ) {
            window.requestAnimationFrame(updateCanvas);
            return {
                scale: scale,
                const: C,
                cols: cols,
                rows: rows,
                l_min: l_min,
                l_max: l_max,
                tasks: tasks,
                availWidth: availWidth,
                availHeight: availHeight,
                viewWidth: viewWidth,
                viewHeight: viewHeight,
                viewTop: viewTop,
                viewLeft: viewLeft,
            };
        } else {
            return null;
        }
    }

    return (
        <>
            <div
                id="CO"
                ref={onRef}
                style={{
                    display: hidden || state.hidden ? "none" : null,
                    left: state.hidden ? 0 : state.left,
                    top: state.hidden ? 0 : state.top,
                    width: state.hidden ? 0 : state.width,
                    height: state.hidden ? 0 : state.height,
                }}
            >
                {state.hidden
                    ? null
                    : state.multisel.map((r, i_r) => {
                          const _r = state;
                          return (
                              <div
                                  key={i_r}
                                  className="CO"
                                  style={{
                                      left: r.left,
                                      top: r.top,
                                      width: r.width,
                                      height: r.height,
                                  }}
                              />
                          );
                      })}
            </div>
            <canvas
                ref={onCanvasRef}
                width={state.availWidth}
                height={state.availHeight}
                style={{
                    display: state.pinch ? undefined : "none",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    background: "rgb(241,243,243)",
                    pointerEvents: "none",
                }}
            />
        </>
    );
}
