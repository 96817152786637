import { Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { ReasonCodes } from "./reason-codes";

export function ChangesSettings() {
    return (
        <Stack tokens={{ padding: "20px 22px" }}>
            <StackItem>
                <ReasonCodes></ReasonCodes>
            </StackItem>
        </Stack>
    );
}
