﻿import { IMessageBarStyles, MessageBar, MessageBarButton, MessageBarType } from "@fluentui/react";
import { intl } from "../../legacy/GlobalHelperReact";

export interface IConnectionErrorAlertBoxProps {
    defaultStyle: IMessageBarStyles;
    onReconnect: () => void;
    connectionState: {
        rt?: any;
        error?: any;
    };
}

export function ConnectionErrorAlertBox({ connectionState, defaultStyle, onReconnect }: IConnectionErrorAlertBoxProps) {
    return (
        <MessageBar
            styles={defaultStyle}
            delayedRender={false}
            messageBarType={connectionState.error ? MessageBarType.error : MessageBarType.warning}
            isMultiline={false}
            onDismiss={() => {}}
            dismissButtonAriaLabel="Close"
            actions={
                <div>
                    <MessageBarButton onClick={onReconnect}>
                        {intl.get("ConnectionErrorAlertBox.reconnect")}
                    </MessageBarButton>
                </div>
            }
        >
            {intl.get("ConnectionErrorAlertBox.connection_error")}
        </MessageBar>
    );
}
