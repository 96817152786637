import { LcmdModal2 } from "@/components/common/LcmModal2";
import { Alert } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { intl } from "@/legacy/GlobalHelperReact";
import { XCircle } from "lucide-react";
import { ReactNode } from "react";
import { FileUploadPopupState } from "../../types/initialState";

type FileUploadWrapperProps = {
    children: ReactNode;
    view: FileUploadPopupState["view"];
    onDone: (data: any) => void;
    onNext: () => void | Promise<void>;
    onHide: () => void;
};

export const FileUploadWrapper = ({ children, view, onDone, onNext, onHide }: FileUploadWrapperProps) => {
    const onDismiss = view?.message ? onNext : onHide;

    return (
        <LcmdModal2
            size="m"
            open={!!onDone}
            onOpenChange={onDismiss}
            className="pb-1"
            header={{ title: view?.title || "" }}
        >
            {view?.error && (
                <div className="relative mb-2 flex items-center">
                    <XCircle color="#ef4444" className="absolute inset-y-0 left-3 my-auto h-4 w-4 text-gray-500" />
                    <Alert variant="destructive" className="py-1 pl-8 pr-4 text-sm">
                        {view.error}
                    </Alert>
                </div>
            )}
            {view?.message ? <div>{view.message}</div> : view ? children : null}
            <div className="flex flex-none items-end justify-end gap-2 pt-0">
                <Button
                    variant="outline"
                    disabled={!view?.message && (!view || view.busy)}
                    onClick={view?.message ? onNext : onHide}
                >
                    {intl.get("legacyProjectDialog.cancel")}
                </Button>
                <Button
                    disabled={!view?.message && (!view || view?.busy || "start" === view?.name)}
                    onClick={onNext}
                    data-userpilot-id="fileUploadPopup-step1ContinueButton"
                >
                    {intl.get("legacyProjectDialog.next")}
                </Button>
            </div>
        </LcmdModal2>
    );
};
