import * as React from "react";
import { intl } from "lcmd2framework";
import { TradesList } from "../FilterDialog";
import { SearchBar } from "@/components/common/SearchBar/SearchBar";
import { ScrollArea } from "@/components/ui/scroll-area";

export function TradesScreen({
    dialog,
    route: {
        params: { trades, initiallySelected, multiselect, onSelected, title, commandBarItems },
    },
}) {
    const [selected, setSelected] = React.useState(initiallySelected);
    const [search, setSearch] = React.useState(null);
    const onSearch = React.useMemo(
        () => (v: string) => {
            setSearch("string" === typeof v && v.length > 0 ? v : null);
        },
        [setSearch],
    );
    const onSearchChange = React.useMemo(
        () => (ev: any, v: string) => {
            setSearch("string" === typeof v && v.length > 0 ? v : null);
        },
        [setSearch],
    );
    React.useLayoutEffect(() => {
        dialog.setOptions({
            title: title ? title : intl.get("filter.trades.title"),
            onOK: () => {
                onSelected(selected);
            },
            onClose: onSelected.bind(null, undefined),
            onCancel: onSelected.bind(null, undefined),
            commandBarItems,
        });
    }, [dialog, selected]);
    return (
        <div className="absolute inset-0 flex flex-col gap-y-1 p-2">
            <SearchBar
                value={search || ""}
                className="w-full"
                onSearch={onSearch}
                onChange={onSearchChange}
                placeholder={intl.get("filter.trades.search")}
            />
            <ScrollArea className="relative h-full w-full">
                <TradesList
                    trades={trades}
                    selected={selected}
                    setSelected={setSelected}
                    search={search}
                    multiselect={multiselect}
                />
            </ScrollArea>
        </div>
    );
}
