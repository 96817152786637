import * as React from "react";

import { Widget } from "../../layout/widget";

import { Header } from "./header";
import { Content } from "./content";
import { DashboardCtxPropI } from "../..";

type ActualPlanningBaselineProps = DashboardCtxPropI & {
    minWidth?: number;
    basicWidth?: number;
    onShowBaseline: (boolean) => void;
};

function ActualPlanningBaseline(props: ActualPlanningBaselineProps) {
    return (
        <Widget basicWidth={props.basicWidth} minWidth={props.minWidth} userflowId="chart-aktuelle-planung">
            <Header onShowBaseline={props.onShowBaseline} />
            <Content {...props} />
        </Widget>
    );
}

export { ActualPlanningBaseline };
