import * as React from "react";
import { useMemo, useState, useCallback, useEffect } from "react";

import { sortTrades } from "../utils/sort-trades";

import { ViewTypes, ViewTypeValueI } from "../../constants";
import { ResourceChartsCanvasState, ResourceChartsCanvasProps } from "../types";

import { Stack, mergeStyleSets } from "@fluentui/react";

import { Header } from "./Header";
import { Body } from "./Body";

const classNames = mergeStyleSets({
    column: {
        position: "absolute",
        overflow: "hidden",
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        padding: 10,
    },
});

const tokenProps = {
    childrenGap: 10,
};

export function LeftColumn(this: ResourceChartsCanvasState, props: ResourceChartsCanvasProps) {
    const trades = useMemo(() => {
        const tradesMap = props.kappa?.trade || {};

        // Reverse needs to keep order with in canvas chart
        return sortTrades(Object.values(tradesMap)).reverse();
    }, [props.kappa?.trade]);

    // TODO: refactor this when ready to get this property in lcmd2ssf
    const [selectedViewType, setSelectedViewType] = useState<ViewTypeValueI>(ViewTypes.byTrade);
    // TODO: refactor this when ready to get this property in lcmd2ssf
    const [dynamicScale, setDynamicScale] = useState(false);

    useEffect(() => {
        this.setOptions({
            ...props.kappa.view?.options,
            workforce: selectedViewType === ViewTypes.byWorkforce,
            dynamicView: dynamicScale,
        });
    }, [selectedViewType, dynamicScale]);

    const onDynamicScaleChange = useCallback(
        (dynamicView: boolean) => {
            setDynamicScale(dynamicView);
        },
        [this.setOptions],
    );

    const onViewTypeChange = useCallback(
        (viewType: ViewTypeValueI) => {
            setSelectedViewType(viewType);
        },
        [setSelectedViewType],
    );

    return (
        <Stack className={classNames.column} tokens={tokenProps}>
            <Header
                viewType={selectedViewType}
                onViewTypeChange={onViewTypeChange}
                dynamicScale={dynamicScale}
                onDynamicScaleChange={onDynamicScaleChange}
            />
            <Body trades={trades} />
        </Stack>
    );
}
