import * as React from "react";
import { FilterRowTop } from "./FilterRowTop";
import { Priority } from "../../../../common/Priority";
import { ItemPriority } from "../../../../ToDo/interface";
import { intl } from "@/legacy/GlobalHelperReact";
import { filterRowStyles } from "./filterRowStyles";

type PriorityFilterRow = {
    label: string;
    badgeCount: number;
    data: any[];
    onModifyButtonClick: () => void;
};

export function PriorityFilterRow({ label, badgeCount, onModifyButtonClick, data }: PriorityFilterRow) {
    function translatePriority(priorityNumber: ItemPriority) {
        switch (priorityNumber) {
            case ItemPriority.LOW:
                return intl.get("ModalToDoFilter.PriorityFilterRow.priorityLow");
            case ItemPriority.MEDIUM:
                return intl.get("ModalToDoFilter.PriorityFilterRow.priorityMedium");
            case ItemPriority.HIGH:
                return intl.get("ModalToDoFilter.PriorityFilterRow.priorityHigh");
        }
    }

    return (
        <div className={filterRowStyles.body}>
            <FilterRowTop onModifyButtonClick={onModifyButtonClick} label={label} badgeCount={badgeCount} />
            {data.map((priority) => {
                return (
                    <div className={filterRowStyles.button} key={priority.key}>
                        <Priority priorityNumber={priority.priority} hideLabel={true} />
                        <span style={{ marginLeft: 5 }}>{translatePriority(priority.priority)}</span>
                    </div>
                );
            })}
        </div>
    );
}
