﻿import { useLCMD } from "../LCMContext";
import { useEffect, useState } from "react";
import { Modal } from "@fluentui/react";

export interface IDownloadDialogProps {
    data: BlobPart;
    mime: string;
}

export function DownloadDialog({ data, mime }: IDownloadDialogProps) {
    const LCMD = useLCMD();
    const [url, setUrl] = useState(undefined);

    useEffect(() => {
        const blob = data
            ? new Blob([data], {
                  type: mime,
              })
            : null;
        const url = blob ? URL.createObjectURL(blob) : null;
        setUrl(url);
        return () => {
            URL.revokeObjectURL(url);
        };
    }, [setUrl, data, mime]);

    return (
        <Modal
            isOpen={true}
            onDismiss={() => {
                LCMD.showDialog("dialog.lcmd.download", false);
            }}
        >
            <a href={url}>Download</a>
        </Modal>
    );
}
