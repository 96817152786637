import { useEffect, useState } from "react";
import {
    BrowserAuthError,
    InteractionRequiredAuthError,
    InteractionStatus,
    IPublicClientApplication,
} from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AuthenticationResult } from "@azure/msal-common";

type MicrosoftSSOContext = {
    instance: IPublicClientApplication;
    isAuth: boolean;
    inProgress: InteractionStatus;
    result: AuthenticationResult;
};

export function useMicrosoftSSO(): MicrosoftSSOContext {
    const [result, setResult] = useState(null);

    const { instance, accounts, inProgress } = useMsal();
    const isAuth = useIsAuthenticated();

    useEffect(() => {
        const silentConfig = { account: accounts[0], scopes: ["User.Read"] };

        if (!isAuth || inProgress !== InteractionStatus.None) {
            return;
        }

        const loginPopup = async () => {
            try {
                const res = await instance.loginPopup(silentConfig);
                setResult(res);
            } catch (error) {
                console.error(error);
                if (error instanceof BrowserAuthError) {
                    // handle cancel/error login response
                } else {
                    throw error;
                }
            }
        };

        const getToken = async () => {
            try {
                const result = await instance.acquireTokenSilent(silentConfig);
                setResult(result);
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    await loginPopup();
                    return;
                }

                if (error instanceof BrowserAuthError) {
                    console.error(error);
                    return;
                }

                console.error(error);
                // todo: implement error for silentRequest
                throw new Error("Unexpected error while silent sso login");
            }
        };

        getToken();
    }, [inProgress, isAuth]);

    return { isAuth, result, inProgress, instance };
}
