import * as chartjs from "chart.js";
import "chartjs-adapter-date-fns";
import externalTooltipHandler from "./chartjs-external-tooltip";

chartjs.Chart.register(...chartjs.registerables); //@TODO optimize this? https://stackoverflow.com/questions/67060070/chart-js-core-js6162-error-error-line-is-not-a-registered-controller

function updateChartIfNeeded(
    config: {
        onRenderChartData;
    },
    chart,
    chartJSCtx: {
        canvasData;
        chartjs;
    },
    data,
) {
    if (chartJSCtx.chartjs && chartJSCtx.canvasData !== data) {
        chartJSCtx.canvasData = data;
        chartJSCtx.chartjs.data = config.onRenderChartData(chartJSCtx, chart, chartJSCtx.canvasData);
        chartJSCtx.chartjs.update();
    }
}

export function ChartJSFactory(
    config: {
        legendContainerId;
        type?;
        options?;
        externalTooltip?;
        onRenderChartData;
        onOutdatedChartData;
        onData;
    },
    chart,
) {
    const chartJSCtx = {
        canvasWidth: null,
        canvasHeight: null,
        chartjsCanvas: null,
        chartjs: null,
        canvasData: null,
    };
    return {
        onRenderChartJS: (chart, canvas) => {
            if (canvas.chart) {
                if (chartJSCtx.chartjsCanvas !== canvas.chart) {
                    if (chartJSCtx.chartjs) {
                        // new canvas element
                        chartJSCtx.chartjs.destroy();
                    }
                    chartJSCtx.canvasWidth = canvas.chart.width;
                    chartJSCtx.canvasHeight = canvas.chart.height;
                    chartJSCtx.chartjsCanvas = canvas.chart;
                    chartJSCtx.canvasData = canvas.overlayState?.data;

                    const chartConfig = {
                        type: config.type || "line",
                        data: config.onRenderChartData(chartJSCtx, chart, chartJSCtx.canvasData),

                        options: {
                            ...(config.options || {}),
                            animation: {
                                duration: 0, // general animation time
                            },
                            responsive: false, // IMPORTANT: Resizing will be done by the framework!
                            responsiveAnimationDuration: 0, // animation duration after a resize
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false,
                                },
                                tooltip: config.externalTooltip
                                    ? {
                                          enabled: false,
                                          external: externalTooltipHandler,
                                      }
                                    : undefined,
                            },
                        },
                    };

                    if (config.legendContainerId?.length > 0) {
                        chartConfig["plugins"] = [htmlLegendPlugin];
                        chartConfig.options.plugins["htmlLegend"] = {
                            // ID of the container to put the legend in
                            containerID: config.legendContainerId ? "legend-container-" + config.legendContainerId : "",
                        };
                    }
                    chartJSCtx.chartjs = new chartjs.Chart(chartJSCtx.chartjsCanvas, chartConfig);
                } else {
                    if (
                        chartJSCtx.canvasWidth !== canvas.chart.width ||
                        chartJSCtx.canvasHeight !== canvas.chart.height
                    ) {
                        chartJSCtx.canvasWidth = canvas.chart.width;
                        chartJSCtx.canvasHeight = canvas.chart.height;
                        chartJSCtx.chartjs.resize();
                    }
                }
                updateChartIfNeeded(config, chart, chartJSCtx, canvas.overlayState?.data);
            } else {
                if (chartJSCtx.chartjs) {
                    // new canvas element
                    chartJSCtx.chartjs.destroy();
                }
                chartJSCtx.chartjsCanvas = null;
                chartJSCtx.chartjs = null;
            }
        },
        onData: (chart, data) => {
            if (config.onData) {
                config.onData(chart, data);
                return;
            }
            if ("outdated" === data?.view) {
                config.onOutdatedChartData(true);
            } else {
                chart.setOverlayState(
                    {
                        data: data,
                    },
                    (state) => {
                        updateChartIfNeeded(config, chart, chartJSCtx, state?.data);
                    },
                );
            }
        },
    };
}

const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector("ul");

    if (!listContainer) {
        listContainer = document.createElement("ul");
        listContainer.style.display = "flex";
        listContainer.style.flexDirection = "column";
        listContainer.style.margin = "0";
        listContainer.style.padding = "0";

        legendContainer.appendChild(listContainer);
    }

    return listContainer;
};

const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);
        ul.style.maxHeight = "200px";
        ul.style.overflow = "scroll";
        ul.style.marginBottom = "10px";
        // Remove old legend items
        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach((item) => {
            const li = document.createElement("li");
            li.style.alignItems = "center";
            li.style.cursor = "pointer";
            li.style.display = "flex";
            li.style.flexDirection = "row";
            li.style.marginLeft = "10px";

            li.onclick = () => {
                const { type } = chart.config;
                if (type === "pie" || type === "doughnut") {
                    // Pie and doughnut charts only have a single dataset and visibility is per item
                    chart.toggleDataVisibility(item.index);
                } else {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };

            // Color box
            const boxSpan = document.createElement("span");
            boxSpan.style.background = item.fillStyle;
            boxSpan.style.borderColor = item.strokeStyle;
            boxSpan.style.borderWidth = item.lineWidth + "px";
            boxSpan.style.display = "inline-block";
            boxSpan.style.height = "9px";
            boxSpan.style.marginRight = "10px";
            boxSpan.style.marginBottom = "8px";
            boxSpan.style.width = "20px";
            boxSpan.style.minWidth = "20px";

            // Text
            const textContainer = document.createElement("p");
            textContainer.style.color = item.fontColor;
            textContainer.style.margin = "0";
            textContainer.style.padding = "0";
            textContainer.style.marginBottom = "8px";
            textContainer.style.textOverflow = "ellipsis";
            textContainer.style.whiteSpace = "nowrap";
            textContainer.style.overflow = "hidden";
            textContainer.style.fontSize = "14px";
            textContainer.style.textDecoration = item.hidden ? "line-through" : "";

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            ul.appendChild(li);
        });
    },
};
