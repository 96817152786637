import * as React from "react";

import { decimalToRgb } from "../../../../../utils/color/decimal-to-rgb";

import { FilterComponent, FilterTag } from "../FilterComponent";
import { TradeLabel } from "../../../../../common/trade/TradeLabel";

type item = { name: string; key: string; color: number };
type onRemoveItem = (item: { name: string; key: string; color: number }) => void;

export type TradesFilterComponentProps = {
    items: item[];
    onClick: () => void;
    onRemoveItem: onRemoveItem;
    userflowId?: string;
};

const FILTER_PREFIX = "filter.main.trades";

function TradeFilterItem({ item, onRemoveItem }: { item: item; onRemoveItem: onRemoveItem }) {
    return (
        <FilterTag item={item} onRemoveItem={onRemoveItem}>
            <TradeLabel color={decimalToRgb(item.color)} name={item.name} />
        </FilterTag>
    );
}

export function TradesFilterComponent({ items, onClick, onRemoveItem, userflowId }: TradesFilterComponentProps) {
    return (
        <FilterComponent
            prefix={FILTER_PREFIX}
            items={items}
            onClick={onClick}
            onRemoveItem={onRemoveItem}
            Item={TradeFilterItem}
            userflowId={userflowId}
        />
    );
}
