import { IButtonStyles, IconButton, mergeStyleSets, TooltipHost } from "@fluentui/react";
import React from "react";
import { HistoryActionName, HistoryMeta } from "../../../../app/interface";
import { createUserName } from "../../../Utils";
import { useUser } from "../../../hooks/userMap.hook";
import { useNavigate } from "react-router-dom";
import { CHANGES_PANEL_ROUTES } from "../../Shared/routers.enum";
import { HistoryTextBlock } from "../../Shared/HistoryTextBlock";
import { ReasonCode } from "../../Shared/ReasonCode";
import { useCanvasNavigation } from "@/components/hooks/useCanvasNavigation.hook";

type HistoryEntryProps = {
    columnType: "date" | "action" | string;
    historyEntry: HistoryMeta;
    index: number;
    userId: string;
};

const styles = mergeStyleSets({
    historyText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        cursor: "pointer",
        color: "#2C3032",
    },
    black: {
        color: "#000000",
    },
});

export function HistoryEntry({ historyEntry, columnType, index, userId }: HistoryEntryProps) {
    const { goTo } = useCanvasNavigation();
    const user = useUser(userId);
    const navigate = useNavigate();

    function navigateToProcess(pid: number) {
        goTo(pid);
    }

    function handleNavigation() {
        navigateToProcess(historyEntry.events[0].id);
        navigate(CHANGES_PANEL_ROUTES.HISTORY_DETAILS, { state: { histEntry: historyEntry, user: user } });
    }

    if (columnType === "date") {
        if (historyEntry.target === "card") {
            return (
                <div
                    className={styles.historyText}
                    onClick={() => {
                        navigateToProcess(historyEntry.events[0].id);
                    }}
                >
                    <span style={{ whiteSpace: "break-spaces" }}>
                        <HistoryTextBlock changeEvent={historyEntry} userName={createUserName(user)} />
                    </span>
                </div>
            );
        } else {
            // @refactor: underlaying ops needs to be fixed for "days-hack"
            const deviatingActionNames = [
                HistoryActionName.PROCESS_STARTDATE_EDIT,
                HistoryActionName.PROCESS_DAYS_EDIT,
                HistoryActionName.MULTI_START_AND_DAYS_EDIT,
                HistoryActionName.MULTI_STARTDATE_EDIT,
                HistoryActionName.MULTI_DAYS_EDIT,
            ];
            const reasonCodeEvent = !deviatingActionNames.includes(historyEntry?.action)
                ? historyEntry?.events.at(-1)
                : historyEntry?.events.at(0);

            return (
                <div className={styles.historyText} onClick={handleNavigation}>
                    <span style={{ whiteSpace: "break-spaces" }}>
                        <HistoryTextBlock changeEvent={historyEntry} userName={createUserName(user)} />
                        <ReasonCode processId={reasonCodeEvent.id} opId={reasonCodeEvent._} />
                    </span>
                </div>
            );
        }
    } else if (columnType === "action" && historyEntry.hasAction) {
        const buttonStyle: IButtonStyles = {
            icon: {
                fontSize: 12,
            },
        };
        return (
            <div style={{ fontFamily: "Inter", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                {(historyEntry.hasAction || []).map((action) => {
                    return (
                        <TooltipHost key={action} content={action} id={action}>
                            <IconButton key={action} styles={buttonStyle} iconProps={{ iconName: action }} />
                        </TooltipHost>
                    );
                })}
            </div>
        );
    } else {
        return null;
    }
}
