import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    IFacepilePersona,
    mergeStyleSets,
    ScrollablePane,
    Shimmer,
    Stack,
    StackItem,
    Sticky,
    StickyPositionType,
} from "@fluentui/react";
import { HistoryMultiReasons, HistoryReasons } from "./HistoryReasons";
import { ChangesHeader } from "../../Shared/ChangesHeader";
import { IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { useLCMD } from "../../../../app/LCMContext";
import { HistoryReasonsEmpty } from "./HistoryReasonsEmpty";
import { HistoryActionName, HistoryMeta } from "../../../../app/interface";
import { User } from "../../../hooks/UserMapTypes";
import { createUserName } from "../../../Utils";
import { HistoryTextBlock } from "../../Shared/HistoryTextBlock";
import { CPContext } from "../../Shared/CPContext";
import { MultipleChangesList } from "./MultipleChangesList";
import { intl } from "@/legacy/GlobalHelperReact";
import { ClockIcon } from "./ClockIcon";
import { WarningMessage } from "./WarningMessage";
import { ComplexProp } from "../../../../model/api/complexProp";
import { AreaPath } from "../../Shared/AreaPath";

type HistoryDetails = {
    showHeader?: boolean;
    height?: string;
};

// @refactor: change translation to an array for the priorities => code get´s cleaner
const priorityMap = new Map();
priorityMap.set("1", "high");
priorityMap.set("2", "medium");
priorityMap.set("3", "low");

export function HistoryDetails({ showHeader = true, height = "100vh" }: HistoryDetails) {
    const LCMD = useLCMD();
    const navigate = useNavigate();
    const [reasonCodes, setReasonCodes] = useState<IChoiceGroupOption[]>();
    const [oldValue, setOldValue] = useState(null);
    const [secondOldValue, setSecondOldValue] = useState(null);
    const { state: locationState }: { state: { histEntry: HistoryMeta; user: User } } = useLocation();
    const { onDismiss } = useContext(CPContext).state;
    const [trades, setTrades] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [uniquePath, setUniquePath] = useState(false);
    const [toDoItemChanges, setToDoItemChanges] = useState(new Map());
    const [formattedChanges, setFormattedChanges] = useState(new Map());

    // @refactor: underlaying ops needs to be fixed for "days-hack"
    const deviatingActionNames = [HistoryActionName.PROCESS_STARTDATE_EDIT, HistoryActionName.PROCESS_DAYS_EDIT];
    let reasonCodeEvent;

    const styles = mergeStyleSets({
        historyText: {
            fontSize: "14px",
            lineHeight: "20px",
        },
    });

    const areaPathes = LCMD.useAreaPaths(locationState.histEntry.events.map((event) => event.id));
    const showWarningMessage =
        showWarning &&
        !(
            locationState.histEntry.action === HistoryActionName.MULTI_START_EDIT ||
            locationState.histEntry.action === HistoryActionName.MULTI_PROCESS_CUT ||
            locationState.histEntry.action === HistoryActionName.MULTI_PROCESS_PASTE ||
            locationState.histEntry.action === HistoryActionName.MULTI_DELETED
        );

    LCMD.useTradesEffect((err, data) => {
        setTrades(data[Object.keys(data)[0]]);
    }, []);

    useEffect(() => {
        const { histEntry } = locationState;
        histEntry.events.forEach((event) => {
            LCMD.getProcessDetails(event.id, (err, data) => {
                if (data.ppid?.value === -1) {
                    setShowWarning(true);
                }
            });
        });
    }, [locationState]);

    useEffect(() => {
        const { histEntry } = locationState;
        console.log(histEntry);
        if (
            histEntry &&
            histEntry.target === "process" &&
            typeof histEntry.events[0].prop === "string" &&
            histEntry.events[0].prop !== "p"
        ) {
            const p = LCMD.getOldHistoryValue(histEntry.events[0].id, histEntry.events[0].prop, histEntry.events[0]._);
            p.then((oldVal) => {
                // if old val 0, means trade was set
                if (histEntry.events[0].prop.startsWith("#RW") && oldVal === 0) {
                    // handle trade name resolve
                    const trade = trades.find((t) => {
                        const val = histEntry.events.at(-1).prop.split("#RW")[1];
                        return t.id === +val;
                    });
                    setOldValue(trade?.name);
                } else {
                    setOldValue(oldVal);
                }
            });
        }

        if (
            histEntry.action === HistoryActionName.PROCESS_START_AND_DAYS_EDIT &&
            histEntry.events[1] &&
            histEntry.events[1].prop === "days"
        ) {
            LCMD.getOldHistoryValue(histEntry.events[1].id, histEntry.events[1].prop, histEntry.events[1]._).then(
                (value) => {
                    setSecondOldValue(value);
                },
            );
        }

        if (
            histEntry.action === HistoryActionName.PROCESS_DAYS_EDIT &&
            histEntry.events[1] &&
            histEntry.events[1].prop === "start"
        ) {
            setSecondOldValue(histEntry.events[1].value);
        }
    }, [locationState, trades]);

    useEffect(() => {
        //TODO: check if refactoring is necessary bc of stabi
        const { histEntry } = locationState;
        if (histEntry && histEntry.target === "actionItem") {
            const complexProp = new ComplexProp();
            const changes = new Map();
            const oldHistoryValuePromises = [];
            for (const historyEvent of histEntry.events) {
                oldHistoryValuePromises.push(
                    LCMD.getOldHistoryValue(historyEvent.id, historyEvent.prop, historyEvent._),
                );
            }
            Promise.allSettled(oldHistoryValuePromises).then((promiseResults) => {
                const historyEvents = histEntry.events;
                promiseResults.forEach((promiseResult, index) => {
                    if (promiseResult.status === "rejected") {
                        return;
                    }
                    const oldValue = promiseResult.value;
                    const historyEvent = historyEvents[index];

                    if (historyEvent.prop.includes("trade") || historyEvent.prop.includes("type")) {
                        return;
                    }

                    if (historyEvent.prop.includes("raisedBy") || historyEvent.prop.includes("responsible")) {
                        if (
                            oldValue !== null &&
                            oldValue.length === historyEvent.value.length &&
                            oldValue.every((value, index) => value === historyEvent.value[index])
                        ) {
                            return;
                        }

                        if (typeof historyEvent.value === "string") {
                            historyEvent.value = [historyEvent.value];
                        }
                    } else if (oldValue == historyEvent.value) {
                        return;
                    }

                    complexProp._reset(historyEvent.prop);
                    const propertyName = complexProp._value && complexProp.isACTIONITEM();
                    const property = propertyName.prop !== undefined ? propertyName.prop : "status";
                    const change = {
                        oldValue: oldValue, //oldValue || [] || null
                        newValue: historyEvent.value,
                    };
                    changes.set(property, change);
                });
                setToDoItemChanges(changes);
            });
        }
    }, [locationState]);

    useEffect(() => {
        const filteredChanges = filterChanges(toDoItemChanges);
        const getReadableChanges = async () => {
            const readableChanges = await makeChangesReadable(filteredChanges);
            setFormattedChanges(readableChanges);
        };
        getReadableChanges();
    }, [toDoItemChanges]);

    useEffect(() => {
        let mounted = true;
        LCMD.getAvailableReasonCodes({
            callback: (reasonCodes) => {
                if (!mounted) {
                    return;
                }
                setReasonCodes(reasonCodes.map((reasonCode) => ({ key: reasonCode.id, text: reasonCode.name })));
            },
        });

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        const pathes = [];
        if (areaPathes[0].path.length > 0) {
            areaPathes.forEach((areaPath) => {
                pathes.push(
                    areaPath.path
                        .map((path) => {
                            return path.name;
                        })
                        .join(" > "),
                );
            });
            const unique = [...new Set(pathes)];
            if (unique.length > 1) {
                setUniquePath(false);
            }
            if (unique.length === 1) {
                setUniquePath(true);
            }
        }
    }, [areaPathes]);

    if (
        locationState.histEntry.target === "process" &&
        reasonCodes?.length > 0 &&
        locationState?.histEntry?.events?.length > 0
    ) {
        const historyEntry = locationState.histEntry;
        reasonCodeEvent = !deviatingActionNames.includes(historyEntry?.action)
            ? historyEntry?.events.at(-1)
            : historyEntry?.events.at(0);
    }

    function filterChanges(changes) {
        const filteredChanges = new Map();
        if (changes.has("status")) {
            const statusChanges = changes.get("status");

            if (statusChanges.oldValue === null) {
                statusChanges.oldValue = "open";
            }
            filteredChanges.set("status", statusChanges);
        }

        if (changes.has("action")) {
            const actionChanges = changes.get("action");

            if (actionChanges.oldValue !== null || actionChanges.newValue !== "") {
                filteredChanges.set("action", actionChanges);
            }
        }

        if (changes.has("issue")) {
            const issueChanges = changes.get("issue");

            if (issueChanges.oldValue !== null || issueChanges.newValue !== "") {
                filteredChanges.set("issue", issueChanges);
            }
        }

        if (changes.has("responsible")) {
            const responsibleChanges = changes.get("responsible");
            if (responsibleChanges.oldValue !== null || responsibleChanges.newValue.length > 0) {
                filteredChanges.set("responsible", responsibleChanges);
            }
        }

        if (changes.has("raisedBy")) {
            const raisedByChanges = changes.get("raisedBy");
            if (raisedByChanges.oldValue !== null || raisedByChanges.newValue.length > 0) {
                filteredChanges.set("raisedBy", raisedByChanges);
            }
        }
        if (changes.has("priority")) {
            const raisedByChanges = changes.get("priority");
            if (raisedByChanges.oldValue === null) {
                raisedByChanges.oldValue = 2;
            }
            if (raisedByChanges.oldValue != raisedByChanges.newValue) {
                filteredChanges.set("priority", raisedByChanges);
            }
        }
        return filteredChanges;
    }

    async function makeChangesReadable(changes) {
        const readableChanges = new Map();

        if (changes.has("status")) {
            const oldStatusValue = changes.get("status").oldValue;
            const oldStatusText = oldStatusValue
                ? intl.get(`ToDoEditView.dropDownStatus.${oldStatusValue}`)
                : intl.get("ActionsView.TodoNoValue");
            const newStatusValue = changes.get("status").newValue;
            const newStatusText = newStatusValue
                ? intl.get(`ToDoEditView.dropDownStatus.${newStatusValue}`)
                : intl.get("ActionsView.TodoNoValue");
            readableChanges.set("status", { oldValue: oldStatusText, newValue: newStatusText });
        }

        if (changes.has("deadline")) {
            const deadlineDates = changes.get("deadline");
            const deadlineOld = getDateByTimestamp(deadlineDates.oldValue);
            const deadlineNew = getDateByTimestamp(deadlineDates.newValue);
            readableChanges.set("deadline", { oldValue: deadlineOld, newValue: deadlineNew });
        }

        if (changes.has("action")) {
            const actionOld = changes.get("action").oldValue ?? intl.get("ActionsView.TodoNoValue");
            const actionNew = changes.get("action").newValue ?? intl.get("ActionsView.TodoNoValue");
            readableChanges.set("action", { oldValue: actionOld, newValue: actionNew });
        }

        if (changes.has("issue")) {
            const issueOld = changes.get("issue").oldValue ?? intl.get("ActionsView.TodoNoValue");
            const issueNew = changes.get("issue").newValue ?? intl.get("ActionsView.TodoNoValue");
            readableChanges.set("issue", { oldValue: issueOld, newValue: issueNew });
        }

        if (changes.has("actionBy")) {
            const actionByOld = changes.get("actionBy").oldValue ?? intl.get("ActionsView.TodoNoValue");
            const actionByNew = changes.get("actionBy").newValue ?? intl.get("ActionsView.TodoNoValue");
            readableChanges.set("actionBy", { oldValue: actionByOld, newValue: actionByNew });
        }

        if (changes.has("responsible")) {
            const responsibleOldValue = changes.get("responsible").oldValue;
            const responsibleOldText = await getUserNamesByIds(responsibleOldValue);
            const responsibleNewValue = changes.get("responsible").newValue;
            const responsibleNewText = await getUserNamesByIds(responsibleNewValue);
            readableChanges.set("responsible", { oldValue: responsibleOldText, newValue: responsibleNewText });
        }

        if (changes.has("raisedBy")) {
            const raisedByOldValue = changes.get("raisedBy").oldValue;
            const raisedByOldText = await getUserNamesByIds(raisedByOldValue);
            const raisedByNewValue = changes.get("raisedBy").newValue;
            const raisedByNewText = await getUserNamesByIds(raisedByNewValue);
            readableChanges.set("raisedBy", { oldValue: raisedByOldText, newValue: raisedByNewText });
        }

        if (changes.has("priority")) {
            const priorityOldValue = changes.get("priority").oldValue;
            const priorityNewValue = changes.get("priority").newValue;
            const priorityOldText = priorityOldValue
                ? intl.get(`ToDoEditView.dropDownPriority.${priorityMap.get(priorityOldValue.toString())}`)
                : intl.get("ToDoEditView.dropDownPriority.medium");
            const priorityNewText = priorityNewValue
                ? intl.get(`ToDoEditView.dropDownPriority.${priorityMap.get(priorityNewValue.toString())}`)
                : intl.get("ToDoEditView.dropDownPriority.medium");
            readableChanges.set("priority", { oldValue: priorityOldText, newValue: priorityNewText });
        }

        return readableChanges;
    }

    async function getUserNamesByIds(ids: string[]) {
        let userNames = intl.get("ActionsView.TodoNoValue");
        if (ids === null || ids === undefined || ids.length <= 0 || typeof ids === "string") {
            return userNames;
        }
        const userNamesPromise = new Promise<string>((resolve) => {
            LCMD.getPersonas(ids, (personas: IFacepilePersona[]) => {
                const users = personas.map((persona) => persona.data) as [];
                resolve(users.map((user) => createUserName(user)).join(", "));
            });
        });
        try {
            userNames = await userNamesPromise;
            return userNames;
        } catch (e) {
            console.warn(e.message);
        }
    }

    function getDateByTimestamp(date: number) {
        return date && date > 0 ? new Date(date).toLocaleDateString() : intl.get("ActionsView.TodoNoValue");
    }

    return (
        <div style={{ width: "100%", height: height }}>
            <ScrollablePane
                styles={{
                    root: {
                        position: "relative",
                        height: "100%",
                        width: "100%",
                    },
                }}
            >
                {showHeader ? (
                    <Sticky stickyPosition={StickyPositionType.Header}>
                        <ChangesHeader
                            backgroundColor={"#F8FAFA"}
                            title={
                                <>
                                    {intl.get("ChangesPanel.headers.details")} \{" "}
                                    {locationState.histEntry.events[0].processName}
                                </>
                            }
                            onBackButtonChange={() => {
                                navigate(-1);
                            }}
                            onCloseButtonChange={() => {
                                onDismiss();
                            }}
                        />
                    </Sticky>
                ) : (
                    ""
                )}
                <div style={{ background: "#FFFFFF", padding: 18, borderTop: "1px solid #E1E4E5" }}>
                    <Stack horizontal>
                        <StackItem>
                            <ClockIcon />
                        </StackItem>
                        <StackItem styles={{ root: { paddingLeft: 18 } }} className={styles.historyText}>
                            <HistoryTextBlock
                                changeEvent={locationState.histEntry}
                                userName={createUserName(locationState.user)}
                                oldVal={oldValue}
                                oldSecondValue={secondOldValue}
                            />
                            {locationState.histEntry.action === HistoryActionName.AREA_DELETED && (
                                <>
                                    <AreaPath
                                        processId={locationState.histEntry.events[0]?.id}
                                        refTimestamp={locationState.histEntry.events[0]?._}
                                    />
                                    {" > " + locationState.histEntry.events[0].processName}
                                </>
                            )}
                        </StackItem>
                    </Stack>
                    {showWarningMessage ? <WarningMessage /> : null}
                    <Stack>
                        <StackItem>
                            {locationState.histEntry.events.length > 0 &&
                                (locationState.histEntry.action === HistoryActionName.MULTI_START_EDIT ||
                                    locationState.histEntry.action === HistoryActionName.MULTI_DAYS_EDIT ||
                                    locationState.histEntry.action === HistoryActionName.MULTI_START_AND_DAYS_EDIT ||
                                    locationState.histEntry.action === HistoryActionName.MULTI_DELETED ||
                                    locationState.histEntry.action === HistoryActionName.MULTI_PROCESS_PASTE ||
                                    locationState.histEntry.action === HistoryActionName.MULTI_PROCESS_CUT ||
                                    locationState.histEntry.action === HistoryActionName.MULTI_TRADE_EDIT ||
                                    locationState.histEntry.action === HistoryActionName.MULTI_PROCESS_CREATED ||
                                    locationState.histEntry.action === HistoryActionName.MULTI_NAME_EDIT ||
                                    locationState.histEntry.action ===
                                        HistoryActionName.MULTI_PROCESS_WORKFORCE_EDIT) && (
                                    <MultipleChangesList
                                        changeEvent={locationState.histEntry}
                                        uniquePath={uniquePath}
                                    />
                                )}
                        </StackItem>
                    </Stack>
                </div>

                {locationState.histEntry.target === "process" && (
                    <Stack style={{ padding: "0 22px 22px 22px" }}>
                        {typeof reasonCodes === "undefined" ? (
                            <Shimmer height={100} width={300} style={{ padding: "22px 0 11px 0" }} />
                        ) : (
                            <Fragment>
                                {reasonCodes.length > 0 && locationState.histEntry.events?.length > 0 ? (
                                    <Fragment>
                                        {locationState.histEntry.action.startsWith("multi") ? (
                                            <HistoryMultiReasons
                                                changes={locationState.histEntry.events}
                                                options={reasonCodes}
                                            />
                                        ) : (
                                            <HistoryReasons
                                                options={reasonCodes}
                                                processId={reasonCodeEvent?.id}
                                                opId={reasonCodeEvent?._}
                                            />
                                        )}
                                    </Fragment>
                                ) : (
                                    <HistoryReasonsEmpty />
                                )}
                            </Fragment>
                        )}
                    </Stack>
                )}
                {/*
                locationState.histEntry.target == "actionItem" && toDoItemChanges.size > 0 &&
                <Stack>
                    {
                        Array.from(formattedChanges, ([name, value]) => ({ name, value })).map(toDoChange => {
                            return (
                                <Stack key={toDoChange.name}>
                                    <StackItem>
                                        <b>{intl.get(`ToDoEditView.action.${toDoChange.name}`)}</b>
                                    </StackItem>
                                    <StackItem>
                                        <Stack horizontal verticalAlign={"center"}>
                                            <StackItem>
                                                {toDoChange.value.oldValue}
                                            </StackItem>
                                            <StackItem styles={{root: {paddingTop: 4}}}>
                                                <Icon iconName={Icons.Lcmd_Arrow_Right}/>
                                            </StackItem>
                                            <StackItem>
                                                {toDoChange.value.newValue}
                                            </StackItem>
                                        </Stack>
                                    </StackItem>
                                </Stack>
                            )
                        })
                    }
                </Stack>
            */}
            </ScrollablePane>
        </div>
    );
}
