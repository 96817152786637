import { MilestoneI, MilestoneState } from "./types";

export const lineHeight = 43;

export const headerHeight = 59;

export function getMilstoneStateColor(milestone: MilestoneI): string {
    if (milestone.b === -1) {
        return "#009BD4";
    }
    return ["#107C10", "#009BD4", "#D83B01"][getMilestoneState(milestone)];
}

export function getMilestoneState(milestone: MilestoneI): MilestoneState {
    if (milestone.x >= 0) {
        if (milestone.b >= 0) {
            if (milestone.x === milestone.b) {
                return MilestoneState.OnTime;
            } else if (milestone.x < milestone.b) {
                return MilestoneState.Early;
            } else {
                return MilestoneState.Delayed;
            }
        }
        return MilestoneState.Early;
    } else {
        return MilestoneState.Early;
    }
}
