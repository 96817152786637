export function downloadBuffer(buffer: ArrayBuffer, filename: string): void {
    // Convert buffer to a Blob
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and set the href attribute to the Blob URL
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    // Append the anchor to the document body (required for Firefox)
    document.body.appendChild(a);

    // Programmatically click the anchor to trigger the download
    a.click();

    // Clean up: remove the anchor and revoke the Blob URL
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}
