import { useContext, useEffect, useState } from "react";
import { Panel } from "../Panel";
import { SidebarContext } from "@/app/components/SidebarHost";
import { Button } from "@/components/ui/button";
import { useLCMD } from "@/app/LCMContext";
import { intl } from "@/legacy/GlobalHelperReact";
import { SidebarMenuItem } from "../interface";
import { DigitalPlanningBoardTradeDetails } from "@/legacy/api/lcmd2framework";
import { TradePanelsContent } from "./TradePanelsContent";
import { cn } from "@/lib/utils";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export const tradeTabs = {
    trades: "TRADES",
    newTrade: "NEW_TRADE",
};

type TradePanels = {
    activePanelView: SidebarMenuItem;
    activeMenuKey: string;
    setActiveMenuKey: (value: string) => void;
    setActivePanelView: (value: SidebarMenuItem) => void;
};

export const TradePanels = ({ activePanelView, activeMenuKey, setActiveMenuKey, setActivePanelView }: TradePanels) => {
    const LCMD = useLCMD();
    const propsContext = useContext(SidebarContext);

    const [activeTab, setActiveTab] = useState(tradeTabs.trades);
    const [trade, setTrade] = useState<Partial<DigitalPlanningBoardTradeDetails>>(null);

    const isTradePanelOpened = activeTab === tradeTabs.trades || activeMenuKey !== "com.lcmdigital.sidebar.trades";
    const isEditTradePanelOpened =
        !!propsContext?.selectedTrade &&
        activeMenuKey === "com.lcmdigital.sidebar.trades" &&
        activeTab !== tradeTabs.newTrade;

    useEffect(() => {
        if (activeTab === tradeTabs.newTrade) {
            propsContext?.sidebarCtx.setTradeData(null);
            setTrade({
                id: -1,
                name: "",
                color: null,
                icon: null,
            });
        } else {
            setTrade(propsContext?.selectedTrade);
        }
    }, [activeTab, propsContext?.selectedTrade]);

    useEffect(() => {
        if (!activePanelView) {
            setActiveTab(tradeTabs.trades);
        }
    }, [activePanelView]);

    const onApply = (
        selectedTrade: Partial<DigitalPlanningBoardTradeDetails>,
        editedTrade: Partial<DigitalPlanningBoardTradeDetails>,
    ) => {
        const trade_trade = "string" === typeof selectedTrade?.trade ? selectedTrade?.trade || null : undefined;
        const tradeColor = editedTrade?.color || 0x00b050;

        if (
            -1 === editedTrade.id ||
            tradeColor !== selectedTrade.color ||
            editedTrade.name !== selectedTrade.name ||
            editedTrade.subs !== selectedTrade.subs ||
            editedTrade.icon !== selectedTrade.icon ||
            trade_trade !== selectedTrade.trade
        ) {
            if (-1 === editedTrade.id) {
                WebAppTelemetryFactory.trackEvent("trade-icon-choosen", {
                    icon: editedTrade.icon,
                    name: editedTrade.name,
                    is_edited: "no"
                });
            } else {
                if (editedTrade.icon !== selectedTrade.icon) {
                    WebAppTelemetryFactory.trackEvent("trade-icon-choosen", {
                        icon: editedTrade.icon,
                        name: editedTrade.name,
                        is_edited: "yes"
                    });
                }
            }            

            LCMD.worker.postMessage([
                "trade",
                "update",
                {
                    id: editedTrade.id,
                    color: -1 === editedTrade.id || tradeColor !== selectedTrade.color ? tradeColor : undefined,
                    name:
                        -1 === editedTrade.id || editedTrade.name !== selectedTrade.name ? editedTrade.name : undefined,
                    trade:
                        -1 === editedTrade.id || trade_trade !== selectedTrade.trade ? trade_trade || null : undefined,
                    subs:
                        -1 === editedTrade.id || editedTrade.subs !== selectedTrade.subs ? editedTrade.subs : undefined,
                    icon:
                        -1 === editedTrade.id || editedTrade.icon !== selectedTrade.icon ? editedTrade.icon : undefined,
                },
            ]);
        }
    };

    return (
        <>
            <Panel
                isOpen={!!activePanelView}
                isTradesView={activeMenuKey === "com.lcmdigital.sidebar.trades"}
                tradesTab={activeTab}
                setTradesTab={setActiveTab}
                onClose={() => {
                    setActiveMenuKey(null);
                    setActivePanelView(null);
                    propsContext?.sidebarCtx.setTradeData(null);
                }}
                footer={
                    activeTab === tradeTabs.newTrade &&
                    activeMenuKey === "com.lcmdigital.sidebar.trades" && (
                        <Button
                            size="lg"
                            className="px-4 py-2 text-base font-medium leading-6 tracking-normal"
                            onClick={() => {
                                onApply(propsContext?.selectedTrade, trade);
                                setActiveTab(tradeTabs.trades);
                            }}
                        >
                            {intl.get("TradesView.CreateNewTradeTitle")}
                        </Button>
                    )
                }
            >
                {isTradePanelOpened ? (
                    activePanelView?.detailsContent
                ) : (
                    <TradePanelsContent
                        selectedTrade={propsContext?.selectedTrade}
                        trade={trade}
                        setTrade={setTrade}
                        colorPickerPosition="left"
                    />
                )}
            </Panel>
            <Panel
                withoutHeader
                isOpen={isEditTradePanelOpened}
                className={cn(
                    isEditTradePanelOpened
                        ? "right-[443px] opacity-100 transition-all"
                        : "right-0 opacity-0 transition-all",
                    "z-[9999]",
                )}
                footer={
                    <Button
                        size="lg"
                        onClick={() => {
                            onApply(propsContext?.selectedTrade, trade);
                            propsContext.sidebarCtx.setTradeData(null);
                        }}
                        className="px-4 py-2 text-base font-medium leading-6 tracking-normal"
                    >
                        {intl.get("TradesView.EditTradeButtonText")}
                    </Button>
                }
            >
                <TradePanelsContent
                    selectedTrade={propsContext?.selectedTrade}
                    trade={trade}
                    setTrade={setTrade}
                    colorPickerPosition="right"
                />
            </Panel>
        </>
    );
};
