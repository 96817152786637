import * as React from "react";
import { useRef, useState, useEffect } from "react";
import {
    mergeStyleSets,
    DefaultButton,
    Checkbox,
    TextField,
    Modal,
    PrimaryButton,
    Spinner,
    Stack,
    StackItem,
} from "@fluentui/react";
import Achtung from "./icons/Achtung";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { intl } from "@/legacy/GlobalHelperReact";
import { LCMDContextType } from "../../../../app/LCMContext";
import { useParticleContext } from "../../../../legacy/api/ParticleContext";
import { useTooltipStore } from "../../../../app/store/tooltipStore";
import { useCanvasStore } from "../../../../app/store/canvasStore";
import { EventDataValue } from "../../../../app/types/CoreEvents";
import { WebAppTelemetryFactory } from "../../../../app/services/WebAppTelemetry.service";

const popupStyles = mergeStyleSets({
    root: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
    },
    content: {
        background: "white",
        height: "300px",
        width: "436px",
        borderRadius: "8px",
        padding: "16px",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    info: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "15px 0px 20px 0px",
    },
    buttons: {
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
        columnGap: "10px",
    },
    button: {
        display: "grid",
        placeContent: "center",
        height: "32px",
        border: "1px solid #C1C5C7",
        borderRadius: "4px",
        fontWeight: 600,
        cursor: "pointer",
    },
    subContent: {
        padding: "16px 0px 0px 24px",
        display: "flex",
        flexDirection: "column",
    },
    selectReason: {
        background: "#F1F3F3",
        borderRadius: "8px",
        overflow: "auto",
        height: "145px",
        padding: "4px 16px 16px",
    },
    selectReasonForcedUsers: {
        background: "#F1F3F3",
        borderRadius: "8px",
        overflow: "auto",
        height: "185px",
        padding: "4px 16px 16px",
    },
    checkBox: {
        marginTop: "24px",
    },
    inputBlock: {
        button: {
            marginTop: "10px",
            borderRadius: "4px",
            border: "1px solid #C1C5C7",
            background: "#FFFFFF",
        },
        input: {
            border: " 1px solid #009BD4",
            borderRadius: "2px",
            padding: "6px 10px",
        },
        div: {
            marginTop: "10px",
        },
    },
});

export type ReasonDisturbanceProps = {
    onUndo: () => void;
    onClose: () => void;
    show: boolean;
};

type SelectedReason = {
    key: string;
    text: string;
};

// @refactor: change const "functions" to real functions
export function ReasonDisturbance({
    onUndo: handleUndo,
    onClose: handleClose,
    show: displayModal,
}: ReasonDisturbanceProps) {
    const secondsTimer = 10;
    const [timeLeft, setTimeLeft] = useState(secondsTimer);
    const [taskId, setTaskId] = useState<string>("");
    const intervalRef = useRef<NodeJS.Timeout>();
    const [selectedReasonCode, setSelectedReasonCode] = useState<SelectedReason>();
    // @refactor: find better way to handle counter state
    const [moveProcessButton, setMoveProcessButton] = useState<string>("");
    const [typedReason, setTypedReason] = useState<string>("");
    const [isReasonCodeRequired, setIsReasonCodeRequired] = useState<boolean>(true);

    const LCMD = useParticleContext() as LCMDContextType;
    const { isLoading: isCurrentUserLoading, data: currentUser } = LCMD.useCurrentUserId();

    const setShowTooltip = useTooltipStore((state) => state.setShowTooltip);
    const canvasEventData = useCanvasStore((state) => state.canvasEvent);

    const {
        isLoading: areReasonCodeSettingsLoading,
        data: reasonCodeSettings,
        saveObligatoryReasonCodeSetting,
    } = LCMD.useReasonCodeSettings();

    useEffect(() => {
        if (!reasonCodeSettings) {
            return;
        }

        if (reasonCodeSettings.isReasonCodeObligatory != isReasonCodeRequired) {
            setIsReasonCodeRequired(reasonCodeSettings.isReasonCodeObligatory);
        }
    }, [reasonCodeSettings, currentUser]);

    const saveShowModal = (isReasonCodeRequired) => {
        saveObligatoryReasonCodeSetting(isReasonCodeRequired);
    };

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setTimeLeft((timeLeft) => timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalRef.current!);
    }, []);

    useEffect(() => {
        if (timeLeft === 0) {
            hidePopup();
        }
    }, [timeLeft]);

    const hidePopup = () => {
        handleClose();
        clearStates();
    };

    const clearStates = () => {
        setTimeLeft(secondsTimer);
        setSelectedReasonCode(undefined);
        setMoveProcessButton("");
        setTaskId("");
    };

    const mouseEnter = () => {
        clearInterval(intervalRef.current!);
        intervalRef.current = undefined;
        setTimeLeft(secondsTimer);
        setMoveProcessButton("enter");
    };

    const mouseLeave = () => {
        setMoveProcessButton("");

        if (selectedReasonCode || isForcedUser) {
            clearInterval(intervalRef.current!);
            intervalRef.current = undefined;
            return;
        }

        intervalRef.current = setInterval(() => {
            setTimeLeft((timeLeft) => timeLeft - 1);
        }, 1000);
    };

    const handleReasonCodeChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ) => {
        setSelectedReasonCode(option);
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
    };

    const handleCheckboxChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setIsReasonCodeRequired(!isReasonCodeRequired);
    };

    const handleApply = async () => {
        if (!selectedReasonCode || !canvasEventData) {
            return;
        }

        WebAppTelemetryFactory.trackEvent("reason-code-assigned-in-the-popover");

        const attachReasonCodeToProcessPromise = [];
        canvasEventData.eventData.forEach((coreEvent: EventDataValue<any>) => {
            attachReasonCodeToProcessPromise[attachReasonCodeToProcessPromise.length] = LCMD.attachReasonCodeToProcess({
                pid: coreEvent.target.id,
                reasonCodeId: selectedReasonCode.key,
                opId: Number(coreEvent.opId),
            });
        });

        await Promise.allSettled(attachReasonCodeToProcessPromise);

        saveShowModal(isReasonCodeRequired);
        if (reasonCodeSettings.isReasonCodeObligatory && !isReasonCodeRequired) {
            setShowTooltip(true);
        }
        hidePopup();
    };

    const handleAbort = () => {
        handleUndo();
        saveShowModal(isReasonCodeRequired);
        hidePopup();
    };

    const handleTypedReasonChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ) => {
        setTypedReason(newValue);
    };

    const handleTypedReasonSet = () => {
        setSelectedReasonCode({ key: "Other", text: typedReason });
    };

    if (areReasonCodeSettingsLoading && !reasonCodeSettings) {
        return <Spinner />;
    }

    const isForcedUser = reasonCodeSettings.userList.includes(currentUser.sub);
    const showModal =
        (reasonCodeSettings?.isReasonCodeObligatory || isForcedUser) &&
        displayModal &&
        reasonCodeSettings?.reasonCodes.length > 0;

    const options: IChoiceGroupOption[] = reasonCodeSettings?.reasonCodes?.map((defaultReasonCode) => {
        return {
            key: defaultReasonCode.id,
            text: defaultReasonCode.name,
            styles: { root: { fontSize: 12 } },
        };
    });

    if (isForcedUser && intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
    }

    return (
        <Modal
            isOpen={showModal}
            isModeless={!isForcedUser}
            isBlocking={isForcedUser}
            styles={{ main: { position: "fixed", right: "65px", bottom: "5px", zIndex: 100000000 } }}
        >
            <div className={popupStyles.root}>
                <div className={popupStyles.content} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                    <div className={popupStyles.header}>
                        <Stack horizontal verticalAlign={"center"} tokens={{ childrenGap: "8px" }}>
                            <StackItem>
                                <Achtung />
                            </StackItem>
                            <StackItem styles={{ root: { fontSize: "16px", fontWeight: 600, lineHeight: "22px" } }}>
                                {intl.get("ReasonCodes.PopUpTitle")} {taskId}{" "}
                            </StackItem>
                        </Stack>
                    </div>
                    <div className={popupStyles.subContent}>
                        <div className={isForcedUser ? popupStyles.selectReasonForcedUsers : popupStyles.selectReason}>
                            <ChoiceGroup
                                styles={{ label: { fontSize: 10 }, flexContainer: { fontSize: 12 } }}
                                options={options}
                                onChange={handleReasonCodeChange}
                                label={intl.get("ReasonCodes.ReasonHeadline")}
                                selectedKey={selectedReasonCode?.key}
                            />
                            {selectedReasonCode?.key == "Other" && (
                                <div className={popupStyles.inputBlock}>
                                    <TextField
                                        placeholder={intl.get("ReasonCodes.DescribeReason")}
                                        onChange={handleTypedReasonChange}
                                    />
                                    <DefaultButton text={intl.get("ReasonCodes.Save")} onClick={handleTypedReasonSet} />
                                </div>
                            )}
                        </div>
                        <Stack>
                            {!isForcedUser && (
                                <Stack
                                    className={popupStyles.checkBox}
                                    horizontal
                                    verticalAlign={"space-between"}
                                    horizontalAlign={"space-between"}
                                >
                                    <StackItem
                                        styles={{
                                            root: {
                                                fontSize: "12px",
                                                lineHeight: 16,
                                                fontWeight: "400",
                                                color: "#565C60",
                                                "&:hover": { cursor: "pointer" },
                                            },
                                        }}
                                        onClick={handleCheckboxChange}
                                    >
                                        {intl.get("ReasonCodes.DontShowAgain")}
                                    </StackItem>
                                    <StackItem>
                                        <Checkbox
                                            boxSide="end"
                                            styles={{
                                                checkbox: { width: 16, height: 16 },
                                                checkmark: { width: 15, height: 15 },
                                            }}
                                            onChange={handleCheckboxChange}
                                            checked={!isReasonCodeRequired}
                                        />
                                    </StackItem>
                                </Stack>
                            )}
                        </Stack>
                        <div className={popupStyles.buttons}>
                            {/* {selectedReasonCode ? (
                  <div
                    className={popupStyles.button}
                    style={{
                      flex: '0 1 auto',
                      padding: '0px 14px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '6px',
                    }}
                    onClick={props.undo}>
                    <ArrowUndo /> Rückgängig
                  </div>
                ) : ( */}
                            <DefaultButton
                                className={popupStyles.button}
                                style={{ flex: "0 1 auto", padding: "0px 35px" }}
                                onClick={handleAbort}
                            >
                                {intl.get("ReasonCodes.Cancel")}
                            </DefaultButton>
                            {/*    )} */}
                            <PrimaryButton
                                className={popupStyles.button}
                                style={{
                                    flex: "1 1 auto",
                                }}
                                disabled={!selectedReasonCode}
                                onClick={handleApply}
                            >
                                {moveProcessButton !== "enter" &&
                                    !isForcedUser &&
                                    !selectedReasonCode &&
                                    `(${timeLeft})`}{" "}
                                {intl.get("ReasonCodes.Confirm")}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
