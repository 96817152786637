import { useEffect, useState } from "react";
import { LCMDContextCardStatus as DailyBoardAPICardStatus, LCMDContextCardStatus, intl } from "lcmd2framework";
import {
    LCMDContextCardDetailsState,
    LCMDContextTaskDetailsResult,
    LCMDContextTaskDetailsResultCardDetails,
} from "@/app/LCMDContextTypes";
import { Gauge } from "../Gauge/Gauge";
import { ProcessTooltipSlider } from "../ProcessTooltipSlider/ProcessTooltipSlider";
import { useLCMD } from "@/app/LCMContext";
import { formatDate } from "./Wrapper";
import { ScrollArea } from "@/components/ui/scroll-area";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { assert, ProcessStatuses } from "@/model/GlobalHelper";
import { getStatusForCards } from "./helper";
import { today } from "@/legacy/settings";
import { convertDaysToMilliseconds, convertMillisecondsToDays } from "@/utils/DateUtils";

export type ProcessTooltipType = {
    processDetails: LCMDContextTaskDetailsResult;
    canvasTaskId: number;
};

// TODO: refactor me so that I use e.g. startDate, endDate, ... instead of processDetails
export function ProcessTooltip({ processDetails, canvasTaskId }: ProcessTooltipType) {
    assert(processDetails !== undefined, "processDetails needs to be defined for ProcessTooltip");

    const LCMD = useLCMD();

    let tasks: LCMDContextTaskDetailsResultCardDetails[] = processDetails.cards?.value || [];
    tasks = tasks.map((task) => {
        return { ...task, date: convertMillisecondsToDays(task.date) };
    });

    useEffect(() => {
        let progressCount = 0;
        tasks?.forEach((task) => {
            if (task.s === LCMDContextCardStatus.DONE) {
                progressCount++;
            }
        });
        setProgress(progressCount);
    }, [processDetails]);

    const [progress, setProgress] = useState<number>(0);
    const startDate = formatDate(processDetails.start?.date);
    const endDate = formatDate(processDetails.end?.date);
    const currentTaskDate = formatDate(convertDaysToMilliseconds(tasks?.at(progress)?.date));
    const { s: statusForCards } = getStatusForCards(today, processDetails.end.value, tasks);

    function handleSliderChange(num: number) {
        if (num === 0 || num === tasks?.length) {
            LCMD.setProcessDetails(
                canvasTaskId,
                {
                    cards: {
                        s: num === 0 ? DailyBoardAPICardStatus.OPEN : DailyBoardAPICardStatus.DONE,
                    },
                },
                false,
            );
        } else {
            const cardIds: string[] = [];
            const states: Pick<LCMDContextCardDetailsState, "s">[] = [];
            tasks?.forEach((task, i) => {
                const newState = i < num ? DailyBoardAPICardStatus.DONE : DailyBoardAPICardStatus.OPEN;
                if (task.s == newState) {
                    return;
                }
                cardIds.push(task.tid);
                states.push({ s: newState });
            });

            LCMD.setCardsDetails(cardIds, states);
        }
        WebAppTelemetryFactory.trackEvent("process-tooltip-slider", {
            value: num,
            process_id: canvasTaskId,
        });
        setProgress(num);
    }

    return (
        <>
            <div className="mb-2 flex content-center items-center gap-x-2 overflow-hidden">
                <Gauge
                    value={tasks?.length ? Math.floor((100 / tasks?.length) * progress) : 0}
                    size="small"
                    showValue={true}
                />
                <div className="flex h-full max-h-[300px] flex-col overflow-hidden">
                    <p className="pb-1 text-xs leading-3 text-gray-400">{processDetails?.trades?.value[0].name}</p>
                    <ScrollArea className="overflow-y-auto">
                        <p className="mr-2 max-h-[200px] min-h-[21px] text-base font-semibold leading-5 tracking-tighter text-gray-50">
                            {processDetails?.name?.value} (
                            {intl.get(`charts.statusTracking.labels.${ProcessStatuses[statusForCards]}`)})
                        </p>
                    </ScrollArea>
                </div>
            </div>
            <ProcessTooltipSlider
                value={[progress]}
                valueLabel={currentTaskDate}
                valueLabelClass="bg-[#030712]"
                max={tasks?.length}
                step={1}
                startLabel={startDate}
                endLabel={endDate}
                onValueChange={(vals) => {
                    setProgress(vals[0]);
                }}
                onValueCommit={(vals) => handleSliderChange(vals[0])}
            />
        </>
    );
}
