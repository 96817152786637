import * as React from "react";

import { intl } from "@/legacy/GlobalHelperReact";
import { Separator } from "@fluentui/react";

import { format } from "date-fns";
import { de, enUS } from "date-fns/locale";

import { Stack, Text, mergeStyleSets, FontWeights } from "@fluentui/react";
import { formatWorkforceOutput } from "@/model/GlobalHelper";

export type HeaderProps = {
    date: number;
    totalWorkforce: number;
    isWorkforceSet: boolean;
};

const classNames = mergeStyleSets({
    date: { fontWeight: FontWeights.bold },
    totalWorkforce: { fontWeight: FontWeights.bold },
});

function Header({ date, totalWorkforce, isWorkforceSet }: HeaderProps) {
    const timeZoneOffsetDate = new Date(date);
    // add offset to have just a date without timezone
    const timestampWithoutTimezone = date + timeZoneOffsetDate.getTimezoneOffset() * 60 * 1_000;
    const dateLocales = { "de-DE": de, "en-US": enUS };

    return (
        <Stack>
            <Text variant="mediumPlus" className={classNames.date}>
                {format(timestampWithoutTimezone, intl.get("charts.resource.overview.titleDateFormat"), {
                    locale: dateLocales[intl.getInitOptions().currentLocale],
                })}
            </Text>
            <Text>
                <Text className={classNames.totalWorkforce}>{formatWorkforceOutput(totalWorkforce)}</Text>{" "}
                {isWorkforceSet
                    ? intl.get("charts.resource.overview.totalWorkforce")
                    : intl.get("charts.resource.overview.totalProcesses")}
            </Text>
            <Separator />
        </Stack>
    );
}

export { Header };
