import * as intl from "react-intl-universal";
// Passwords must contain 8 characters or more (up to 16 characters.)
// Passwords must contain the following criteria options:
// - UPPER CASE
// - lower case
// - numeric
// - special characters (!, $, etc.)
const PASSWORD_REG_EX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/;

const DEFAULT_ERROR_MESSAGE =
    "Password must contain 8 characters or more (up to 16 characters) and contains the following criteria options: UPPER CASE, lower case, numeric, special characters (!, $, etc.).";

export function validatePassword(password: string): undefined | string {
    return PASSWORD_REG_EX.test(password) ? undefined : intl.get("password.validateError").d(DEFAULT_ERROR_MESSAGE);
}
