import * as React from "react";

import { MainWorkerPipe } from "./MainWorkerPipe";
import { SubId } from "@/legacy/SubTypes";
import { SubCache } from "@/legacy/SubCache";

// @refactor: change sub type to SubId
export class Sub extends React.Component<
    {
        worker: MainWorkerPipe;
        sub: SubId,
        type?: number;
    },
    {
        result: any;
    }
> {
    state = {
        result: null,
    };

    componentDidMount() {
        const sub = this.props.sub;
        if (this.props.worker.auth?.auth_token && null !== sub && undefined !== sub) {
            const _cb = (result) => {
                this.setState({
                    result: result,
                });
            };
            SubCache.getSub(this.props.worker.auth.auth_token, sub, _cb);
        }
    }

    render(this: Sub) {
        const email = this.state.result ? this.state.result?.email || "-" : "...";
        if (1 === this.props.type) {
            return (
                <div
                    className="UO"
                    title={email}
                    style={
                        {
                            //color: c.fg,
                            //backgroundColor: c.bg
                        }
                    }
                >
                    {email}
                </div>
            );
        } else {
            return <span key={this.props.sub}>{email}</span>;
        }
    }
}
