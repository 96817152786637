import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ModalAssetHeader } from "../../ModalAsset";
import {
    CheckboxVisibility,
    DefaultButton,
    DetailsList,
    IColumn,
    PrimaryButton,
    SearchBox,
    Selection,
    SelectionMode,
} from "@fluentui/react";
import { Priority } from "../../../common/Priority";
import { ItemPriority } from "../../../ToDo/interface";
import { ModalAssetFooter } from "../../ModalAsset/ModalAssetFooter";
import { useEffect, useState, useRef } from "react";
import { intl } from "@/legacy/GlobalHelperReact";
import { RouteNames } from "../filter.interface";
import { useToDoModalFilterStore } from "../../../../app/store/toDoModalFilterStore";
import { styles } from "../filterModalStyles";

type ColumnItem = {
    key: string;
    name: string;
    priority: string;
};

export function PriorityFilter() {
    const { filterData, setFilterData } = useToDoModalFilterStore();

    const navigate = useNavigate();
    const allItems = useRef([
        {
            key: "High",
            name: "High",
            priority: ItemPriority.HIGH,
            priorityLabel: intl.get("ToDo.priority.high"),
        },
        {
            key: "Medium",
            name: "Medium",
            priority: ItemPriority.MEDIUM,
            priorityLabel: intl.get("ToDo.priority.medium"),
        },
        {
            key: "Low",
            name: "Low",
            priority: ItemPriority.LOW,
            priorityLabel: intl.get("ToDo.priority.low"),
        },
    ]);
    const [items, setItems] = useState(allItems.current);
    const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
    const [selection] = useState(
        new Selection({
            onSelectionChanged: () => {
                setSelectedIndices(selection.getSelectedIndices());
            },
        }),
    );

    useEffect(() => {
        const localFilterData = filterData?.priorityFilterData || [];
        localFilterData?.forEach(({ key }) => {
            const i = allItems.current.findIndex((p) => p.key === key);
            selection.setIndexSelected(i, true, false);
        });
    }, [selection, filterData?.priorityFilterData]);

    const columns = [
        {
            key: "priority",
            name: intl.get("ModalToDoFilter.PriorityFilter.priorityColumn"),
            fieldName: "priority",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
    ];

    function headerTitle() {
        return (
            <>
                {intl.get("ModalToDoFilter.PriorityFilter.filter")} /{" "}
                {intl.get("ModalToDoFilter.PriorityFilter.priority")}
            </>
        );
    }

    function onChangeText(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string) {
        const { current: allFilters } = allItems;

        setItems(
            text
                ? allFilters.filter((i) => i.priorityLabel?.toLowerCase().indexOf(text.toLowerCase()) > -1)
                : allFilters,
        );
    }

    function renderItemColumn(item: ColumnItem, index?: number, column?: IColumn) {
        if (typeof column === "undefined") {
            return false;
        }
        const style = { fontSize: "14px" };
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case "priority":
                return (
                    <span style={style}>
                        <Priority priorityNumber={fieldContent} hideLabel={false} />
                    </span>
                );
            default:
                return <div style={style}>{fieldContent}</div>;
        }
    }

    function footerLeftContent() {
        return (
            <DefaultButton
                styles={{
                    root: { border: "none" },
                    rootDisabled: { backgroundColor: "transparent" },
                }}
                disabled={!selectedIndices.length}
                text={intl.get("ModalToDoFilter.PriorityFilter.clearFilters")}
                iconProps={{ iconName: "Delete" }}
                onClick={() => {
                    selection.setAllSelected(false);
                }}
            />
        );
    }

    function footerRightContent() {
        return (
            <PrimaryButton
                text={intl.get("ModalToDoFilter.PriorityFilter.done")}
                onClick={() => {
                    setFilterData({
                        ...filterData,
                        priorityFilterData: selectedIndices.map((i) => items[i]),
                    });
                    navigate(RouteNames.HOME);
                }}
            />
        );
    }

    return (
        <div>
            <ModalAssetHeader
                onBackButtonChange={() => {
                    navigate(RouteNames.HOME);
                }}
                title={headerTitle()}
            />

            <div className={styles.body}>
                <div className={styles.searchField}>
                    <SearchBox
                        placeholder={intl.get("ModalToDoFilter.PriorityFilter.search")}
                        onChange={onChangeText}
                    />
                </div>
                <DetailsList
                    items={items}
                    columns={columns}
                    onRenderItemColumn={renderItemColumn}
                    setKey="set"
                    compact={true}
                    checkboxVisibility={CheckboxVisibility.always}
                    selectionMode={SelectionMode.multiple}
                    selectionPreservedOnEmptyClick={true}
                    enterModalSelectionOnTouch={true}
                    selection={selection}
                />
            </div>

            <ModalAssetFooter leftContent={footerLeftContent()} rightContent={footerRightContent()} />
        </div>
    );
}
