import * as React from "react";
import { StackItem, Stack } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";
import { WarningIcon } from "./WarningIcon";

export function WarningMessage() {
    return (
        <Stack
            styles={{
                root: {
                    background: "rgba(250, 65, 0, 0.2)",
                    padding: "16px",
                    height: "105px",
                    marginTop: "35px",
                    borderRadius: "4px",
                    color: "#2C3032",
                },
            }}
        >
            <StackItem styles={{ root: { fontWeight: 600 } }}>{intl.get("WarningMessage.title")}</StackItem>
            <StackItem>
                <Stack horizontal styles={{ root: { marginTop: "20px" } }}>
                    <WarningIcon />
                    <StackItem styles={{ root: { paddingLeft: "18px", fontWeight: 600 } }}>
                        {intl.get("WarningMessage.message")}
                    </StackItem>
                </Stack>
            </StackItem>
        </Stack>
    );
}
