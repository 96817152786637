import { Label } from "@/components/ui/label";
import { LegacyStartEndDateControl } from "@/legacy/GlobalHelperFluentUI";
import { intl } from "@/legacy/GlobalHelperReact";
import React, { forwardRef, useRef, useImperativeHandle, useEffect, MutableRefObject } from "react";

type Details2ViewProps = {
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
};

export const Details2View = forwardRef<HTMLFormElement, Details2ViewProps>(({ onSubmit }, ref) => {
    const formRef = useRef<HTMLFormElement>(null);
    const activeForm = formRef.current;

    useImperativeHandle(ref, () => formRef.current);

    useEffect(() => {
        if (ref && typeof ref === "object" && ref.current) {
            (ref as MutableRefObject<HTMLFormElement>).current = formRef.current;
        }
    }, [ref]);

    const defaultStartDate = new Date();
    const defaultEndDate = new Date(new Date().setMonth(defaultStartDate.getMonth() + 1));

    return (
        <div className="w-full">
            <form ref={formRef} onSubmit={onSubmit}>
                <input name="startDate" type="hidden" value={defaultStartDate.toISOString()} />
                <input name="endDate" type="hidden" value={defaultEndDate.toISOString()} />
                <div>
                    <Label htmlFor="formStartEnd">{intl.get("legacyProjectDialog.details2.label")}</Label>
                    <LegacyStartEndDateControl
                        utc={false}
                        defaultStart={defaultStartDate}
                        defaultEnd={defaultEndDate}
                        data-userpilot-id="fileUploadPopup-projectStartDate"
                        onChange={(start: Date, end: Date) => {
                            const startDate: HTMLInputElement = (activeForm?.elements || [])["startDate"];
                            const endDate: HTMLInputElement = (activeForm?.elements || [])["endDate"];
                            if (startDate) {
                                startDate.value = start ? start.toISOString() : "";
                            }
                            if (endDate) {
                                endDate.value = end ? end.toISOString() : "";
                            }
                        }}
                    />
                </div>
            </form>
        </div>
    );
});

Details2View.displayName = "Details2View";
