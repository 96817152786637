import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import { WidgetHeader } from "../../layout/widget-header";

import { TitleText } from "../../common/title-text";
import { Title } from "../../common/title";
import { Actions } from "../common/actions";

const PROGRESS_COLOR = "#107C10";
const PLANNING_COLOR = "#999EA1";

function Header() {
    return (
        <WidgetHeader
            actions={<Actions />}
            title={
                <Title>
                    <TitleText
                        text={intl.get("charts.progressVsPlanning.labels.progressVs")}
                        pointColor={PROGRESS_COLOR}
                    />
                    <TitleText
                        text={intl.get("charts.progressVsPlanning.labels.planning")}
                        pointColor={PLANNING_COLOR}
                    />
                </Title>
            }
        />
    );
}

export { Header };
