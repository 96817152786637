import { LCMDContextTaskDetailsResult, LCMDContextTodoResult } from "@/app/LCMDContextTypes";
import { useState, useEffect } from "react";
import { getOverallStabilityState, getStabilityViewState } from "../Utils";
import { ToDoProps } from "../ToDo/interface";

export type StabilityViewStateProps = {
    todos: LCMDContextTodoResult;
    stabilityItems: ToDoProps[];
    groupedItems?: Map<string, ToDoProps[]>;
    customStabilityItems: ToDoProps[];
    stableItemsCount: number;
    stabilityTotalCount: number;
};

const defaultState: StabilityViewStateProps = {
    todos: null,
    groupedItems: null,
    stabilityItems: [],
    customStabilityItems: [],
    stableItemsCount: 0,
    stabilityTotalCount: 0,
};

export const useStabilityViewData = (
    processDetails: LCMDContextTaskDetailsResult,
    selectedProcesses?: number[],
): { status: number; stabilityViewState: StabilityViewStateProps } => {
    const [status, setStatus] = useState<number>(0);
    const [stabilityViewState, setStabilityViewState] = useState<StabilityViewStateProps>(defaultState);

    useEffect(() => {
        const stabilityView = getStabilityViewState(processDetails);
        setStabilityViewState(stabilityView);
    }, [processDetails]);

    useEffect(() => {
        if (selectedProcesses.length === 0) {
            setStatus(0);
        } else if (processDetails?.todos?.today?.value) {
            const overallStabilityStatus = getOverallStabilityState(
                processDetails?.todos.today.value,
                stabilityViewState,
            );
            setStatus(overallStabilityStatus);
        }
    }, [processDetails, stabilityViewState, selectedProcesses]);

    return { status, stabilityViewState };
};
