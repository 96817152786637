import * as React from "react";
import { useState } from "react";
import { intl } from "lcmd2framework";
import { ToDoEditView } from "../../ToDo/EditView";
import { ItemState, ItemType, ToDoProps } from "../../ToDo/interface";
import { getEmptyToDo } from "../../ToDo/ActionItem.factory";
import { getToDoState } from "../../Utils";
import { useLCMD } from "../../../app/LCMContext";
import { getCurrentLanguage } from "../../utils/date/locale";
import { LCMDContextTaskDetailsResult } from "../../../app/LCMDContextTypes";
import { useStabilityViewData } from "@/components/hooks/useStabilityViewData.hook";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { ProcessTooltipSlider } from "@/components/common/ProcessTooltipSlider/ProcessTooltipSlider";
import { Button } from "@/components/ui/button";
import { Check, Pencil, Plus, RotateCcw, Trash2 } from "lucide-react";
import { SearchBar } from "@/components/common/SearchBar/SearchBar";
import { ScrollArea } from "@/components/ui/scroll-area";
import { LcmdModal2 } from "@/components/common/LcmModal2";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Counter } from "@/components/common/counter/CounterV2";
import { ToDoCheckbox } from "../ToDoCheckbox/ToDoCheckboxV2";
import { useProcessPlanTimestamp, useSelectedProcessIds } from "@/app/store/canvasStore";

type CreateStabilityModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
    onCreateStabilityCriteria: (newStability: { issue: string; leadTime: number }) => void;
};

function CreateStabilityCriteriaModal({ isOpen, onDismiss, onCreateStabilityCriteria }: CreateStabilityModalProps) {
    const [modalState, setModalState] = useState<{ issue: string; leadTime: number }>({ issue: "", leadTime: 0 });
    const btns = [
        <Button key="dismiss" variant="outline" onClick={onDismiss}>
            {intl.get("StabilityView.CancelButtonText")}
        </Button>,
        <Button
            key="add"
            onClick={() => {
                // todo: get all information and trigger close
                onCreateStabilityCriteria(modalState);
                WebAppTelemetryFactory.trackEvent("sidebar-stability-criteria-added");
            }}
        >
            {intl.get("StabilityView.AddButtonText")}
        </Button>,
    ];

    return (
        <LcmdModal2
            open={isOpen}
            onOpenChange={onDismiss}
            size="m"
            buttons={btns}
            header={{
                title: intl.get("StabilityView.HeaderTitleNew"),
            }}
        >
            <div>
                <Label htmlFor="criteriaName" className="font-normal">
                    {intl.get("StabilityView.CriteriaNameLabelText")}
                </Label>
                <Input
                    className="mt-1"
                    id="criteriaName"
                    value={modalState.issue}
                    onChange={(event) => {
                        event.preventDefault();
                        setModalState({ ...modalState, issue: event.target.value });
                    }}
                />
            </div>
            <div className="mt-5 flex">
                <Counter
                    label={intl.get("StabilityView.CriteriaLeadTimeLabelText")}
                    onChange={(n) => setModalState({ ...modalState, leadTime: n })}
                />
                <p className="flex items-end justify-end pb-1.5 pl-1 text-sm text-[#323130]">
                    {intl.get("StabilityCriteria.SettingsModal.WorkingDaysText")}
                </p>
            </div>
        </LcmdModal2>
    );
}

/*

    todo: get API for setting state for multiple stability items instead of iterating over the stabi items

 */
export function StabilityView() {
    const stabilityOverallStatusLabel = [
        intl.get("StabilityView.StabilityOverallStatusLabel.OnTime"),
        intl.get("StabilityView.StabilityOverallStatusLabel.Late"),
        intl.get("StabilityView.StabilityOverallStatusLabel.Done"),
    ];
    const stabilityOverallStatusBackgroundColor = ["#F1F3F3", "rgba(250, 65, 0, 0.2)", "rgba(95, 210, 85, 0.2)"];

    const [showNewModal, setShowNewModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const LCMD = useLCMD();
    const selectedProcesses = useSelectedProcessIds();
    const processPlanTimestamp = useProcessPlanTimestamp();
    const [selectedProcess, setSelectedProcess] = useState<{
        pid: number;
        name: string;
        areaPath: { id: number; name: string }[];
    }>(null);
    const [searchText, setSearchText] = useState("");
    const [editableTodos, setEditableTodos] = useState<ToDoProps[]>([getEmptyToDo()]);
    const [processDetails, setProcessDetails] = useState<LCMDContextTaskDetailsResult>(null);

    const { status, stabilityViewState } = useStabilityViewData(processDetails, selectedProcesses);
    const today = stabilityViewState.todos?.today?.value;

    LCMD.useProcessDetailsEffect(
        selectedProcesses,
        false,
        (error, data) => {
            setProcessDetails(data);

            if (data && !data.pid?.indeterminate && !Array.isArray(data.pid?.value)) {
                setSelectedProcess({ pid: data.pid?.value, name: data.name?.value, areaPath: data.areaPath });
                const temp = [...editableTodos];
                temp[0].process = { pid: data.pid?.value, name: data.name?.value, areaPath: data.areaPath };
                setEditableTodos(temp);
            }
        },
        [],
        processPlanTimestamp,
    );

    function handleStabilitiesStateChange(
        checked: boolean,
        stabilityItems: {
            pid: number;
            stab?: string;
        }[],
    ) {
        LCMD.setOrCreateTodoItemState(
            stabilityItems,
            { value: checked ? ItemState.DONE : ItemState.OPEN },
            checked ? {resolved : {value : +new Date()}} : null,
            (error, data) => {
                if (error) {
                    console.error(error);
                }
            },
        );
    }

    function handleRestartClick() {
        WebAppTelemetryFactory.trackEvent("stability-criteria-restart-button-clicked");
        const allElement = [
            ...stabilityViewState.stabilityItems.map((item) => ({ pid: item.process.pid, stab: item.stability })),
            ...stabilityViewState.customStabilityItems.map((item) => ({ pid: item.process.pid, stab: item.stability })),
        ];
        handleStabilitiesStateChange(false, allElement);
    }

    function handleAllCardsDoneClick() {
        WebAppTelemetryFactory.trackEvent("stability-criteria-allDone-button-clicked");
        const allElement = [
            ...stabilityViewState.stabilityItems.map((item) => ({ pid: item.process.pid, stab: item.stability })),
            ...stabilityViewState.customStabilityItems.map((item) => ({ pid: item.process.pid, stab: item.stability })),
        ];
        handleStabilitiesStateChange(true, allElement);
    }

    function handleSearch(newValue: string) {
        setSearchText(newValue || "");
    }

    function editToDo(todos: ToDoProps[]) {
        WebAppTelemetryFactory.trackEvent("stability-criteria-edit-button-clicked");
        setEditableTodos(todos);
        setShowEditModal(true);
    }

    function close() {
        setEditableTodos([{ ...getEmptyToDo(), process: selectedProcess }]);
        setShowEditModal(false);
    }

    function stabilityViewHasGroupedItems(): boolean {
        return Boolean(
            stabilityViewState?.groupedItems?.size >= 1 &&
                stabilityViewState?.groupedItems?.entries().next().value[1].length > 1,
        );
    }

    function getDateTextFromStability(stabilityItem): string {
        if (stabilityItem.deadline > 0) {
            return (
                new Date(stabilityItem.deadline).toUTCFormatString(getCurrentLanguage(), {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                }) +
                (stabilityItem.leadDays
                    ? " · " + stabilityItem.leadDays + intl.get("StabilityView.WorkingDaysShort")
                    : "")
            );
        }
        return " ";
    }

    return (
        <>
            <div>
                <div className="border-b border-solid border-[#E1E4E5] px-2 pb-2 pt-0">
                    <p className="text-lg font-semibold leading-6 tracking-tighter">
                        {intl.get("StabilityView.StabilityLabelText")}
                    </p>
                    <div
                        className="mt-3 rounded p-3"
                        style={{ backgroundColor: stabilityOverallStatusBackgroundColor[status] }}
                    >
                        <ProcessTooltipSlider
                            step={1}
                            disabled={true}
                            value={[stabilityViewState.stableItemsCount]}
                            trackClassName="h-1 bg-white mx-2"
                            thumbClassName="h-4 w-4 border-[#C8C6C4]"
                            rangeColor="#D83B01"
                            label={stabilityOverallStatusLabel[status]}
                            max={stabilityViewState.stabilityTotalCount}
                        />
                    </div>
                    <div className="mx-0 flex justify-between px-2 py-0 pb-3 pt-5">
                        <Button variant="outline" onClick={handleRestartClick} className="border-[#8a8886]">
                            <RotateCcw size={16} className="mr-2" />
                            {intl.get("TasksView.RestartButtonText")}
                        </Button>
                        <Button variant="outline" onClick={handleAllCardsDoneClick} className="border-[#8a8886]">
                            <Check size={16} className="mr-2" />
                            {intl.get("TasksView.DoneButtonText")}
                        </Button>
                    </div>
                </div>
                <div className="h-[inherit] py-2">
                    <SearchBar
                        className="w-100 px-2"
                        value={searchText}
                        onSearch={handleSearch}
                        onChange={(event, newValue) => handleSearch(newValue)}
                        placeholder={intl.get("StabilityView.SearchFieldPlaceholderText")}
                    />
                    <ScrollArea
                        style={{
                            width: "100%",
                            height: "calc(100% - 340px)",
                            position: "absolute",
                        }}
                        className="pb-0 pl-2 pt-2"
                    >
                        {stabilityViewHasGroupedItems() &&
                            // eslint-disable-next-line no-unsafe-optional-chaining
                            [...stabilityViewState?.groupedItems]
                                .filter((groupedItems) => {
                                    if (!stabilityViewState.todos.stabs[groupedItems[0]]?.label) {
                                        return false;
                                    }
                                    return stabilityViewState.todos.stabs[groupedItems[0]]?.label
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase());
                                })
                                .map((groupedToDos) => {
                                    const stabilityItem = groupedToDos[1][0];

                                    return (
                                        <ToDoCheckbox
                                            key={stabilityItem?.id}
                                            state={getToDoState(
                                                stabilityItem.status === ItemState.DONE,
                                                stabilityItem.deadline,
                                                today,
                                            )}
                                            menuProps={[
                                                {
                                                    key: "editMessage",
                                                    text: intl.get("ToDo.edit"),
                                                    icon: <Pencil size={16} color="#666666" />,
                                                    onClick: () => editToDo(groupedToDos[1]),
                                                },
                                            ]}
                                            headline={stabilityViewState.todos.stabs[stabilityItem.stability]?.label}
                                            subtext={getDateTextFromStability(stabilityItem)}
                                            onCheckBoxChange={(checked) => {
                                                const checkItems = groupedToDos[1].map((item) => {
                                                    return { pid: item.process.pid, stab: item.stability };
                                                });
                                                handleStabilitiesStateChange(checked, checkItems);
                                            }}
                                        />
                                    );
                                })}
                        {!stabilityViewHasGroupedItems() &&
                            Boolean(stabilityViewState?.stabilityItems) &&
                            stabilityViewState.stabilityItems
                                .filter((stabilityItem) => {
                                    return (
                                        !searchText ||
                                        stabilityViewState?.todos?.stabs[stabilityItem?.stability]?.label
                                            .toLowerCase()
                                            .includes(searchText.toLowerCase())
                                    );
                                })
                                .map((stabilityItem) => {
                                    return (
                                        <ToDoCheckbox
                                            key={stabilityItem?.id}
                                            state={getToDoState(
                                                stabilityItem.status === ItemState.DONE,
                                                stabilityItem.deadline,
                                                today,
                                            )}
                                            menuProps={[
                                                {
                                                    key: "editMessage",
                                                    text: intl.get("ToDo.edit"),
                                                    icon: <Pencil size={16} color="#666666" />,
                                                    onClick: () => {
                                                        editToDo([stabilityItem]);
                                                    },
                                                },
                                            ]}
                                            headline={stabilityItem.issue}
                                            subtext={getDateTextFromStability(stabilityItem)}
                                            onCheckBoxChange={(checked) => {
                                                handleStabilitiesStateChange(checked, [
                                                    {
                                                        pid: stabilityItem.process.pid,
                                                        stab: stabilityItem.stability,
                                                    },
                                                ]);
                                            }}
                                        />
                                    );
                                })}
                        {Boolean(stabilityViewState.customStabilityItems.length) && (
                            <p className="font-semibold">{intl.get("StabilityView.CustomStabilitiesText")}</p>
                        )}
                        {Boolean(stabilityViewState?.customStabilityItems) &&
                            stabilityViewState.customStabilityItems
                                .filter((stabilityItem) => {
                                    return (
                                        !searchText ||
                                        stabilityItem?.issue?.toLowerCase().includes(searchText.toLowerCase())
                                    );
                                })
                                .map((stabilityItem) => {
                                    return (
                                        <ToDoCheckbox
                                            key={stabilityItem?.id}
                                            state={getToDoState(
                                                stabilityItem.status === ItemState.DONE,
                                                stabilityItem.deadline,
                                                today,
                                            )}
                                            menuProps={[
                                                {
                                                    key: "editMessage",
                                                    text: intl.get("ToDo.edit"),
                                                    icon: <Pencil size={16} color="#666666" />,
                                                    onClick: () => {
                                                        editToDo([stabilityItem]);
                                                    },
                                                },
                                                {
                                                    key: "deleteEvent",
                                                    text: intl.get("ToDo.delete"),
                                                    icon: <Trash2 size={16} color="#666666" />,
                                                    onClick: () => {
                                                        LCMD.setOrCreateTodoItemState(
                                                            {
                                                                pid: stabilityItem.process.pid,
                                                                stab: stabilityItem.stability,
                                                            },
                                                            {
                                                                value: null,
                                                            },
                                                            null,
                                                            (error, data) => {
                                                                if (error) {
                                                                    console.error(error);
                                                                }
                                                            },
                                                        );
                                                    },
                                                },
                                            ]}
                                            headline={stabilityItem.issue}
                                            subtext={getDateTextFromStability(stabilityItem)}
                                            onCheckBoxChange={(checked) => {
                                                WebAppTelemetryFactory.trackEvent("stability-criteria-checkbox-clicked", {
                                                    checked: checked,
                                                });
                                                handleStabilitiesStateChange(checked, [
                                                    {
                                                        pid: stabilityItem.process.pid,
                                                        stab: stabilityItem.stability,
                                                    },
                                                ]);
                                            }}
                                        />
                                    );
                                })}
                    </ScrollArea>
                </div>
            </div>
            <div className="absolute bottom-0 flex h-16 w-full justify-end border-t border-gray-300 px-2 py-3">
                <Button
                    variant="outline"
                    onClick={() => {
                        setShowNewModal(!showNewModal);
                        WebAppTelemetryFactory.trackEvent("sidebar-new-stability-criteria-clicked");
                    }}
                    disabled={selectedProcesses?.length !== 1}
                    className="border-[#8a8886]"
                >
                    <Plus size={16} className="mr-2" />
                    {intl.get("StabilityView.NewStabilityCriteriaButtonText")}
                </Button>
            </div>
            {showEditModal && (
                <ToDoEditView
                    isOpen={showEditModal}
                    todos={editableTodos}
                    createItemType={ItemType.STABILITY_CRITERIA}
                    onCloseEditView={close}
                />
            )}
            {showNewModal && (
                <CreateStabilityCriteriaModal
                    isOpen={showNewModal}
                    onDismiss={() => setShowNewModal(false)}
                    onCreateStabilityCriteria={(newStability) => {
                        setShowNewModal(false);
                        const selectedProcess = selectedProcesses.at(0);
                        LCMD.setOrCreateTodoItemState(
                            {
                                pid: selectedProcess,
                                id: "stability",
                            },
                            null,
                            {
                                issue: { value: newStability.issue },
                                deadline: { value: -1 * newStability.leadTime },
                            },
                            (error, data) => {
                                if (error) {
                                    console.error(error);
                                    return;
                                }
                            },
                        );
                    }}
                />
            )}
        </>
    );
}
