import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { Dialog, DialogScreen, intl } from "lcmd2framework";
import { ChoiceGroup, Stack, TextField } from "@fluentui/react";
import {
    CalendarException,
    CalendarExceptionData,
    CalendarExceptionRepeatability,
} from "./calendarSettings.interfaces";
import { UTCDatePicker } from "../../../legacy/GlobalHelperFluentUI";

function CalendarExceptionMainScreen({
    dialog,
    route: {
        params: { onDismiss, onClose, calendarException },
    },
}: {
    dialog: any;
    route: {
        params: {
            onDismiss: () => void;
            onClose: (CalendarExceptionData) => void;
            calendarException?: CalendarException;
        };
    };
}) {
    const initialDialogValues = getInitialDialogValues(calendarException);
    const [name, setName] = useState(initialDialogValues.name);
    const [startDate, setStartDate] = useState(initialDialogValues.startDate);
    const [endDate, setEndDate] = useState(initialDialogValues.endDate);
    const [repeat, setRepeat] = useState<CalendarExceptionRepeatability>(
        initialDialogValues.repeat as CalendarExceptionRepeatability,
    );
    const dialogTitle = !calendarException
        ? intl.get("CalendarExceptionMainScreen.title.create")
        : intl.get("CalendarExceptionMainScreen.title.edit");

    const repeatOptions = useMemo<Array<{ key: CalendarExceptionRepeatability; text: string }>>(() => {
        return [
            {
                key: "none",
                text: intl.get("CalendarExceptionMainScreen.choiceGroupRepeat.options.none"),
                styles: { root: { paddingRight: "10px" } },
            },
            {
                key: "yearly",
                text: intl.get("CalendarExceptionMainScreen.choiceGroupRepeat.options.yearly"),
            },
        ];
    }, []);

    const startDateSelected = (date: Date) => {
        setStartDate(date);
        setEndDate(date);
    };

    useEffect(() => {
        const onOK =
            name && startDate && endDate && repeat
                ? () => {
                      onClose({ name, startDate, endDate, repeat });
                  }
                : undefined;

        dialog.setOptions({
            title: dialogTitle,
            onOK: onOK,
            onCancel: onDismiss,
            onClose: onDismiss,
        });
    }, [name, startDate, endDate, repeat, onDismiss]);

    function getInitialDialogValues(calendarException?: CalendarException) {
        if (!calendarException) {
            // set to zero hours, min, sec, ms
            const d = new Date().setUTCHours(0,0,0,0);
            return {
                name: "",
                startDate: new Date(d),
                endDate: new Date(d),
                repeat: "none",
            };
        }

        const startDate =
            typeof calendarException.value === "string"
                ? new Date(calendarException.value)
                : new Date(calendarException.value[0]);
        const endDate =
            typeof calendarException.value === "string"
                ? new Date(calendarException.value)
                : new Date(calendarException.value[1]);

        return {
            name: calendarException.name ?? "",
            startDate,
            endDate,
            repeat: calendarException.repeat || "none",
        };
    }

    return (
        <Stack style={{ width: "100%", padding: "20px 24px" }}>
            <TextField
                value={name}
                styles={{ root: { width: "530px" } }}
                label={intl.get("CalendarExceptionMainScreen.textFieldName.label")}
                onChange={(ev, newValue) => setName(newValue)}
            />
            <Stack
                styles={{ root: { maxWidth: "530px", padding: "16px 0" } }}
                horizontal
                horizontalAlign={"space-between"}
            >
                <UTCDatePicker
                    value={startDate}
                    style={{ width: "250px" }}
                    label={intl.get("CalendarExceptionMainScreen.startDate.label")}
                    onSelectDate={startDateSelected}
                />
                <UTCDatePicker
                    value={endDate}
                    style={{ width: "250px" }}
                    label={intl.get("CalendarExceptionMainScreen.endDate.label")}
                    onSelectDate={setEndDate}
                />
            </Stack>
            <ChoiceGroup
                label={intl.get("CalendarExceptionMainScreen.choiceGroupRepeat.label")}
                styles={{ flexContainer: { display: "flex" } }}
                options={repeatOptions}
                defaultSelectedKey={repeat}
                onChange={(ev, selectedOption) => setRepeat(selectedOption.key as CalendarExceptionRepeatability)}
            />
        </Stack>
    );
}

export function CalendarExceptionDialog({
    onDismiss,
    onClose,
    calendarException,
}: {
    onDismiss: () => void;
    onClose: (data: CalendarExceptionData) => void;
    calendarException?: CalendarException | any;
}) {
    return (
        <Dialog
            isOpen={true}
            initialScreen={(dialog) => {
                dialog.pushScreen("main", {
                    onDismiss,
                    onClose,
                    calendarException,
                });
            }}
        >
            <DialogScreen name="main" component={CalendarExceptionMainScreen} />
        </Dialog>
    );
}
