import * as React from "react";
import { DefaultButton } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";
import { filterRowStyles } from "./filterRowStyles";

type FilterItemRow = {
    label: string;
    badgeCount: number;
    onModifyButtonClick: () => void;
};

export function FilterRowTop({ label, badgeCount, onModifyButtonClick }: FilterItemRow) {
    return (
        <div className={filterRowStyles.top}>
            <div className={filterRowStyles.left}>
                <label className={filterRowStyles.label}>{label}</label>
                {badgeCount ? <span className={filterRowStyles.badge}>{badgeCount}</span> : null}
            </div>
            <DefaultButton
                iconProps={{ iconName: "MultiSelect" }}
                onClick={onModifyButtonClick}
                text={intl.get("ModalToDoFilter.Overview.buttonModify")}
            />
        </div>
    );
}
