import { useState, useCallback } from "react";

export type Status = {
    loading: boolean;
    loaded: boolean;
    error: null | { msg: string; code?: string | number };
}

const defaultStatus: Status = { loading: false, loaded: false, error: null };

export function useLoadingStatus(state = defaultStatus) {
    const [status, setStatus] = useState(state);

    const onError = useCallback(
        <T extends Status["error"]>(error: T) => {
            setStatus((status) => {
                return { ...status, loading: false, error };
            });

            return error;
        },
        [setStatus],
    );

    const onLoadingStart = useCallback(() => {
        setStatus((status) => {
            return { ...status, loading: true, error: null };
        });
    }, [setStatus]);

    const onLoaded = useCallback(
        <T extends unknown>(response: T) => {
            setStatus((status) => {
                return { ...status, loading: false, loaded: true };
            });

            return response;
        },
        [setStatus],
    );

    const observe = useCallback(
        <T>(promise: Promise<T>) => {
            onLoadingStart();
            return promise.then(onLoaded, onError);
        },
        [onLoadingStart, onLoaded, onError],
    );

    return { status, onError, onLoaded, onLoadingStart, observe };
}
