import * as React from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { getUrlLink } from "../Utils";
import { Pencil } from "lucide-react";
import { TextFieldWithCommit } from "@/legacy/GlobalHelperFluentUI";

type CardDialogLinkProps = {
    props: {
        _: any;
        value?: {
            value: any;
        };
        field: {
            key: any;
            label: any;
            type: any;
            suffix: any;
            default: any;
            width: any;
        };
        disabled?: any;
    };
    onChange: any;
};

export function CardDialogLink({ props, onChange }: CardDialogLinkProps) {
    const [visibleState, { toggle: toggleVisibleState }] = useBoolean(props.value || props.field.default);

    return (
        <>
            {visibleState ? (
                <div className="mt-1.5 flex flex-row content-center items-center">
                    <Pencil
                        size={16}
                        onClick={() => toggleVisibleState()}
                        aria-label="Edit"
                        className="cursor-pointer"
                    />
                    <a
                        className="ml-1.5 w-full max-w-[400px] overflow-hidden text-ellipsis whitespace-nowrap"
                        href={getUrlLink(props.value?.value || props.field.default)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {props.value?.value || props.field.default}
                    </a>
                </div>
            ) : (
                <TextFieldWithCommit
                    ts={props._}
                    key={props.field.key}
                    label={props.field.label}
                    type={props.field.type}
                    suffix={props.field.suffix}
                    styles={{
                        wrapper: {
                            marginTop: 10,
                            marginBottom: 10,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            columnGap: "10px",
                        },
                        fieldGroup: {
                            flexGrow: "1",
                            flexShrink: "1",
                            maxWidth: props.field.width,
                        },
                    }}
                    value={props.value || props.field.default || ""}
                    onChange={(value) => {
                        onChange(value);
                        toggleVisibleState();
                    }}
                    disabled={props.disabled}
                />
            )}
        </>
    );
}
