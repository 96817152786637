import * as React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

import { cn } from "@/lib/utils";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = React.forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
        className?: string;
        align?: "end" | "start" | "center";
        sideOffset?: number;
        position?: "bottom" | "top" | "right" | "left";
        avoidCollisions?: boolean;
    }
>(({ className, align = "center", sideOffset = 4, position = "bottom", avoidCollisions = false, ...props }, ref) => (
    <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
            ref={ref}
            align={align}
            sideOffset={sideOffset}
            avoidCollisions={avoidCollisions}
            side={position}
            className={cn(
                // changed z-index from 50 to 1000099 so that modal doesn't overlap the popover if it's in the modal
                // changed z-index from 1000099 to 100000100 so that popover doesn't get hidden behind #fluent-default-layer-host
                "z-[100000100] w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                className,
            )}
            onInteractOutside={(event) => {
                const target: HTMLElement = event.target as HTMLElement;
                if (!target.closest("#root")) {
                    event.preventDefault();
                }
            }}
            {...props}
        />
    </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent };
