import * as Comlink from "comlink";

import { WebWorkerLink } from "./webWorkerLink";

let WorkerLink = null;
let cachedWorkerLink = null;

export function initWorkerLink(worker: Worker) {
    // todo: check if here needs to be an error thrown and if worker is correct when switching projects
    console.warn("WorkerLink already set! Probably because of Project switch, check for side effects");
    // throw new Error("Worker already linked!");
    WorkerLink = Comlink.wrap(worker);
    cachedWorkerLink = null;
}

export async function getWorkerLink(): Promise<WebWorkerLink> {
    if (WorkerLink) {
        cachedWorkerLink = cachedWorkerLink || new WorkerLink();
        return cachedWorkerLink;
    } else {
        throw new Error("Worker link not init!");
    }
}
