import * as React from "react";

import { MilestoneI } from "../../types";

import { CheckboxVisibility, DetailsList, IColumn } from "@fluentui/react";
import { epochDayToDate } from "../../../Utils";
import { MilestoneStateComponent } from "../MilestoneStateComponent";
import { useLCMD } from "../../../../app/LCMContext";
import { useCanvasNavigation } from "@/components/hooks/useCanvasNavigation.hook";

type MilestonesListProps = {
    milestones: MilestoneI[];
    startTimestamp: number;
};

export function MilestonesList(props: MilestonesListProps) {
    const LCMD = useLCMD();
    const { goTo } = useCanvasNavigation()
    let { milestones, startTimestamp } = props;
    milestones = milestones.filter((m) => m.x >= 0);
    const columns: IColumn[] = [
        { name: "Name", fieldName: "n", key: "c_name", minWidth: 100 },
        // {name: "Baseline", fieldName: "n", key: "c_baseline", minWidth: 63, onRender: renderBaselineColumn},
        // {name: "Planned", fieldName: "n", key: "c_planned", minWidth: 63, onRender: renderDateColumn},
        // {name: "Diff.", fieldName: "n", key: "c_diff", minWidth: 23, onRender: renderDiffColumn},
        // {name: "Status.", fieldName: "n", key: "c_status", minWidth: 43, onRender: renderStateColumn}
    ];

    function renderDateColumn(milestone?: MilestoneI, index?: number, column?: IColumn): any {
        return new Date(startTimestamp + epochDayToDate(milestone.x).getTime()).toLocaleDateString();
    }
    function renderBaselineColumn(milestone?: MilestoneI, index?: number, column?: IColumn): any {
        if (milestone.b >= 0) {
            return new Date(startTimestamp + epochDayToDate(milestone.b).getTime()).toLocaleDateString();
        } else {
            return "---";
        }
    }
    function renderStateColumn(milestone?: MilestoneI, index?: number, column?: IColumn): any {
        return <MilestoneStateComponent milestone={milestone} />;
    }
    function renderDiffColumn(milestone?: MilestoneI, index?: number, column?: IColumn): any {
        if (milestone.b < 0) {
            return 0;
        }

        return milestone.b - milestone.x;
    }
    const onItemInvoked = React.useCallback(
        (item?: any, index?: number, ev?: Event) => {
            if ("number" === typeof item?.tid) {
                goTo(item.tid);
            }
        },
        [LCMD],
    );
    return (
        <DetailsList
            items={milestones}
            columns={columns}
            checkboxVisibility={CheckboxVisibility.hidden}
            onItemInvoked={onItemInvoked}
        />
    );
}
