import React, { useCallback, useMemo } from "react";
import { CONST } from "../../settings";
import { intl } from "../../GlobalHelperReact";
import { MainWorkerPipe } from "../../MainWorkerPipe";
import { cn } from "@/lib/utils";
import {
    CalendarDays,
    CalendarRange,
    CheckCircle2,
    Folders,
    LayoutPanelLeft,
    Presentation,
    Settings,
} from "lucide-react";
import { useCalendarStore } from "../../../app/store/saveCalendarSettings";
import { saveSettings } from "../../../utils/SettingsUtils";
import { useChangesPanelOpenStore } from "@/app/store/changesPanelOpenStore";
import { DigitalPlanningBoardViewKey } from "@/legacy/api/lcmd2framework";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import {
    NavigationMenu,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { Separator } from "@/components/ui/separator";
import { LCMDTooltip } from "@/components/common/Tooltip/LCMDTooltip";
import { CalloutMessage } from "@/components/common/ErrorNotifications/ReasonDisturbance/CalloutMessage";

type FrameworkHeaderProps = {
    worker: MainWorkerPipe;
    hidden?: boolean;
    left?: any;
    center?: any;
    right?: any;
    activeView: DigitalPlanningBoardViewKey;
    filesOverlay: boolean;
    workshopOnly?: boolean;
    metaData?: any;
};

type Tab = {
    view: string;
    title: string;
    visible: boolean;
    active: boolean;
    onBeforeNavigateAway?: (nextView: string) => Promise<void>;
    onHeaderTabClicked?: (activeView: DigitalPlanningBoardViewKey) => Promise<void>;
    icon?: JSX.Element;
    userflowId?: string;
};

type FrameworkHeaderTab = {
    title: string;
    visible?: boolean;
    active?: boolean;
    onHeaderTabClicked?: () => void;
    view?: string;
    icon?: JSX.Element;
    userflowId?: string;
};

type Tabs = Tab[];

const getTabs = (activeView: DigitalPlanningBoardViewKey, worker: MainWorkerPipe, workshopOnly?: boolean): Tabs => {
    const tabs = [
        {
            view: "dashboard",
            icon: <LayoutPanelLeft size={20} />,
            title: intl.get("fw.tab.overview"),
            visible: !workshopOnly && !(!activeView || activeView === "folder" || activeView === "login"),
            active: activeView === "dashboard",
        },
        {
            view: "workshop",
            icon: <Presentation size={20} />,
            title: intl.get("fw.tab.workshop"),
            visible:
                worker.config?.whiteboard?.showWelcome &&
                activeView &&
                !(activeView === "folder" || activeView === "login"),
            active: activeView === "workshop",
        },
        {
            view: "taktzone",
            title: intl.get("fw.tab.tz"),
            visible: false && !workshopOnly && activeView && !(activeView === "folder" || activeView === "login"),
            active: activeView === "taktzone",
        },
        {
            view: "tp",
            title: intl.get("fw.tab.tp"),
            visible: false && !workshopOnly && activeView && !(activeView === "folder" || activeView === "login"),
            active: activeView === "tp",
        },
        {
            view: "project",
            icon: <CalendarRange size={20} />,
            title: intl.get("fw.tab.pp"),
            visible: !workshopOnly && activeView && !(activeView === "folder" || activeView === "login"),
            active: activeView === "project",
            onBeforeNavigateAway: async () => {
                const isChangesPanelOpen = useChangesPanelOpenStore.getState().open;
                if (isChangesPanelOpen) {
                    worker.dispatchMessage(["toggle", "changes", "close", {}]);
                }
            },
            userflowId: "header-prozessplan-offen",
        },
        {
            view: "dailyboard",
            icon: <CalendarDays size={20} />,
            title: intl.get("fw.tab.db"),
            visible: !workshopOnly && activeView && !(activeView === "folder" || activeView === "login"),
            active: activeView === "dailyboard",
        },
        {
            view: "todo",
            icon: <CheckCircle2 size={20} />,
            title: intl.get("fw.tab.todo"),
            visible:
                typeof worker.config?.Todo === "function" &&
                !workshopOnly &&
                activeView &&
                !(activeView === "folder" || activeView === "login"),
            active: activeView === "todo",
        },
        {
            view: "settings",
            icon: <Settings size={20} />,
            title: intl.get("fw.tab.settings"),
            visible:
                typeof worker.config?.Settings === "function" &&
                !workshopOnly &&
                activeView &&
                !(activeView === "folder" || activeView === "login"),
            active: activeView === "settings",
            onBeforeNavigateAway: async () => {
                const calendarSettingsState = useCalendarStore.getState();
                if (calendarSettingsState.changed) {
                    saveSettings(calendarSettingsState.calendarSettings);
                }
            },
        },
    ];
    return tabs;
};

function FrameworkHeaderTab({
    title,
    visible,
    active,
    onHeaderTabClicked,
    view,
    icon,
    userflowId,
}: FrameworkHeaderTab) {
    if (!visible) {
        return null;
    }

    return (
        <NavigationMenuItem>
            <LCMDTooltip text={title} key={view}>
                <NavigationMenuLink
                    active={active}
                    className={cn(
                        navigationMenuTriggerStyle(),
                        "w-[36px] justify-start overflow-hidden text-nowrap transition-all",
                        "transition-all duration-200 ease-in-out",
                        active && "delay-50",
                    )}
                    style={{ maxWidth: active ? "150px" : "36px" }}
                    onClick={() => {
                        WebAppTelemetryFactory.trackEvent("frameworkHeader-" + view);
                        onHeaderTabClicked();
                    }}
                    data-userflow-id={active ? userflowId : null}
                >
                    <div>{icon}</div>
                    <div className={cn("duration-50 ml-2 transition-opacity", active ? "opacity-100" : "opacity-0")}>
                        {title}
                    </div>
                    {view == "settings" && <CalloutMessage />}
                </NavigationMenuLink>
            </LCMDTooltip>
        </NavigationMenuItem>
    );
}

function FrameworkHeader({
    worker,
    hidden,
    left,
    center,
    right,
    activeView,
    filesOverlay,
    workshopOnly,
}: FrameworkHeaderProps) {
    const onFilesClick = useCallback(() => {
        worker.dispatchMessage(["framework", "files", "folder" === activeView || !filesOverlay]);
    }, [worker, activeView, filesOverlay]);

    const tabs = useMemo(() => getTabs(activeView, worker, workshopOnly), [activeView, worker, workshopOnly]);

    const activeTab = tabs.find((tab) => tab.view === activeView);

    const tabsAreHidden = tabs.every((el) => !el.visible);

    return (
        <div
            key="TH"
            className="TH"
            style={{
                display: hidden ? "none" : null,
                height: CONST.titleHeight,
            }}
        >
            <span className="TL">
                <img
                    title={intl.get("fw.tooltip.icon")}
                    src="img/Logo.svg"
                    className="mr-2 self-center"
                    data-userflow-id="header-logo"
                />
                <NavigationMenu>
                    <NavigationMenuList>
                        <NavigationMenuItem>
                            <NavigationMenuLink
                                onClick={onFilesClick}
                                active={filesOverlay}
                                className={cn(navigationMenuTriggerStyle(), "px-2 py-2")}
                            >
                                {tabsAreHidden ? intl.get("fw.tab.projects") : <Folders className="h-5 w-5" />}
                            </NavigationMenuLink>
                        </NavigationMenuItem>
                        {!tabsAreHidden && (
                            <div className="px-4">
                                <Separator orientation="vertical" decorative className="h-5 bg-zinc-700" />
                            </div>
                        )}
                        {/* this functionality has been removed in the new design */}
                        {/* {"function" === typeof this.props.worker.config?.headerMetaComponent ? (
                            <this.props.worker.config.headerMetaComponent metaData={this.props.metaData} />
                        ) : (
                            <div style={{ maxWidth: 50 }}>{JSON.stringify(this.props.metaData)}</div>
                        )} */}
                        {tabs.map(({ active, onHeaderTabClicked, title, visible, view, icon, userflowId }, index) => (
                            <FrameworkHeaderTab
                                key={index}
                                title={title}
                                active={active}
                                visible={visible}
                                view={view}
                                icon={icon}
                                onHeaderTabClicked={async () => {
                                    activeTab.onBeforeNavigateAway && (await activeTab.onBeforeNavigateAway(view));
                                    onHeaderTabClicked && (await onHeaderTabClicked(activeView));
                                    worker.dispatchMessage(["framework", "toggle", view]);
                                }}
                                userflowId={userflowId}
                            />
                        ))}
                    </NavigationMenuList>
                </NavigationMenu>
                {left}
            </span>
            <div style={{ width: "calc(100% / 3)" }} className="flex grow items-center justify-center">
                {center}
            </div>
            <span className="TR">{right}</span>
        </div>
    );
}

export { FrameworkHeader };
