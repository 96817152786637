import { createContext, Dispatch } from "react";
import { HistoryActionName, HistoryMeta } from "../../../app/interface";
import { LCMDChangesPanelChanges, LCMDChangesPanelChangesTask } from "../../../app/LCMDContextTypes";

// export interface ICPContext {
//     onDismiss: () => void,
//     changes: LCMDChangesPanelChanges | null
// }

export enum ChangesPanelActionTypes {
    SET_TASKS = "setTasks",
    SET_HISTORY_FILTER = "setHistoryFilter",
    SET_HISTORY = "setHistory",
    SET_DISMISS = "setDismiss",
    SET_COLLAPSED = "setCollapsed",
    SET_CONFLICT_ACTIVATION_STATUS = "setConflictActivationStatus",
    SET_PAGE = "setNextPage",
    SET_SELECTED_CONDITION = "selectedCondition",
    SET_SORT_DIRECTION = "setSortDirection",
}

export type HistoryStateChanges = LCMDChangesPanelChanges & {
    counter: number;
};

export type ChangesPanelState = {
    historyFilter: Map<string, HistoryActionName[]>;
    onDismiss: () => void;
    changes: HistoryStateChanges;
    notCollapsed: string[];
    showConflicts: boolean;
    page: {
        next: number;
        current: number;
    };
    sortDirection: string;
    selectedCondition: string;
};

type ContextInterface = {
    state: ChangesPanelState;
    dispatch: Dispatch<ChangesPanelAction>;
};

export type ChangesPanelAction =
    | { type: ChangesPanelActionTypes.SET_HISTORY_FILTER; filter: Map<string, HistoryActionName[]> }
    | {
          type: ChangesPanelActionTypes.SET_HISTORY;
          history: HistoryMeta[];
          currentPage: number;
          nextPage: number;
          _: number;
      }
    | { type: ChangesPanelActionTypes.SET_TASKS; tasks: LCMDChangesPanelChangesTask[]; _: number }
    | { type: ChangesPanelActionTypes.SET_DISMISS; onDismiss: () => void }
    | { type: ChangesPanelActionTypes.SET_COLLAPSED; notCollapsed: string[] }
    | { type: ChangesPanelActionTypes.SET_CONFLICT_ACTIVATION_STATUS; status: boolean }
    | { type: ChangesPanelActionTypes.SET_PAGE; page: { next: number; current: number } }
    | { type: ChangesPanelActionTypes.SET_SELECTED_CONDITION; selectedCondition: string }
    | { type: ChangesPanelActionTypes.SET_SORT_DIRECTION; sortDirection: string };

export function ChangesPanelInitialState(): ChangesPanelState {
    return {
        historyFilter: new Map<string, HistoryActionName[]>(),
        onDismiss: () => {},
        changes: { tasks: [], history: [], _: null, counter: 0 },
        page: { current: null, next: null },
        notCollapsed: [],
        showConflicts: true,
        sortDirection: "descending",
        selectedCondition: "epochDay",
    };
}

export const CPContext = createContext<ContextInterface>({ state: ChangesPanelInitialState(), dispatch: null });

export function ChangesPanelReducer(state: ChangesPanelState, action: ChangesPanelAction) {
    switch (action.type) {
        case ChangesPanelActionTypes.SET_DISMISS:
            return { ...state, onDismiss: action.onDismiss };
        case ChangesPanelActionTypes.SET_TASKS:
            return {
                ...state,
                changes: {
                    ...state.changes,
                    tasks: action.tasks,
                    _: action._,
                    counter: state.changes._ != action._ ? ++state.changes.counter : state.changes.counter,
                },
            };
        case ChangesPanelActionTypes.SET_HISTORY_FILTER:
            return { ...state, historyFilter: action.filter };
        case ChangesPanelActionTypes.SET_HISTORY:
            return {
                ...state,
                page: { ...state.page, current: action.currentPage, next: action.nextPage },
                changes: { ...state.changes, history: [...action.history], _: action._, counter: 0 },
            };
        case ChangesPanelActionTypes.SET_COLLAPSED:
            return { ...state, notCollapsed: [...action.notCollapsed] };
        case ChangesPanelActionTypes.SET_CONFLICT_ACTIVATION_STATUS:
            return { ...state, showConflicts: action.status };
        case ChangesPanelActionTypes.SET_PAGE:
            return { ...state, page: { ...action.page } };
        case ChangesPanelActionTypes.SET_SELECTED_CONDITION:
            return { ...state, selectedCondition: action.selectedCondition };
        case ChangesPanelActionTypes.SET_SORT_DIRECTION:
            return { ...state, sortDirection: action.sortDirection };
    }
    return { ...state };
}
// export const CPContext = createContext({} as ICPContext);
