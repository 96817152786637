import { useEffect, useState } from "react";
import { HelperDateToUTCEpochMS, intl, LocalizedDatePicker } from "lcmd2framework";
import { weekNumber } from "weeknumber";
import { getCurrentLanguage } from "../../../utils/date/locale";
import { TrainInfo } from "../interface";
import { useLCMD } from "../../../../app/LCMContext";
import { LCMDContextTaskDetailsResult } from "../../../../app/LCMDContextTypes";
import { EditNameInput } from "@/components/common/EditNameInput";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";

type AreaSidebarViewProps = {
    data: LCMDContextTaskDetailsResult;
    trainInfos: { [trainId: string]: TrainInfo };
    onTrainInfos: (any) => void;
    libraryId: string;
    trainId: number;
    onOrderByNameSuffix: (any) => void;
    orderByNameSuffix: boolean;
};
export function TaktDependenciesTextField(props: { value: string; onChange: (value: string) => void }) {
    const LCMD = useLCMD();
    const [text, setText] = useState<string>(props.value);
    const [visibleText, setVisibleText] = useState<string>(props.value);
    const [value, setValue] = useState<{ text?: string; pid?: number }>(null);

    LCMD.useProcessDetailsEffect(
        value?.pid || null,
        false,
        (error, data) => {
            if (-1 === data?.pid?._ && (data?.pid as any)._taktId) {
                const v = (data.pid as any)._taktId;
                setValue({ text: v });
            } else if (data && value?.pid === data.pid?.value) {
                setValue({ text: ["#", data.pid.value.toString(10)].join("") });
            }
        },
        [setValue],
    );

    useEffect(() => {
        const v = text || "";
        if (v.startsWith("#")) {
            const pid = Number.parseInt(v.substring(1), 10);
            setValue({ pid });
        } else {
            props.onChange(v);
            setValue({ text: v });
        }
    }, [setValue, text]);

    useEffect(() => {
        if (undefined !== value?.text) {
            props.onChange(value?.text);
            setVisibleText(value?.text);
        }
    }, [setVisibleText, value?.text]);

    return (
        <EditNameInput
            label={intl.get("WhiteboardDetailsView.placeholder_dependency")}
            allowEmptyString
            name={visibleText}
            className="mb-1 max-w-full"
            saveName={(value) => setText(value)}
            placeholder={intl.get("WhiteboardDetailsView.no_dependencies")}
        />
    );
}

export function AreaSidebarView({
    data,
    trainInfos,
    onTrainInfos,
    libraryId,
    trainId,
    onOrderByNameSuffix,
    orderByNameSuffix,
}: AreaSidebarViewProps) {
    const LCMD = useLCMD();

    const trainInfo = trainInfos[trainId] || { id: trainId };
    const setTrainInfo = (state) => {
        onTrainInfos({ ...trainInfos, [trainId]: { ...trainInfo, ...state } });
    };

    return (
        <div className="m-2 border-b p-2.5">
            <EditNameInput
                name={data.name.value}
                label={intl.get("WhiteboardDetailsView.areaName")}
                className="max-w-full"
                placeholder={intl.get("WhiteboardDetailsView.areaNamePlaceholder")}
                saveName={(value) => {
                    const saveItem: { id?: number; name: string } = {
                        id: trainId,
                        name: value,
                    };
                    LCMD.setOrCreateLibraryItem(libraryId, saveItem);
                }}
            />
            <div className="border-b px-0 py-2">
                <LocalizedDatePicker
                    label={intl.get("WhiteboardDetailsView.start")}
                    placeholder={intl.get("WhiteboardDetailsView.placeholder_start_date")}
                    value={trainInfo.start}
                    disabled={trainInfo.dep && trainInfo.dep.length > 0}
                    onSelectDate={(date: Date) => {
                        setTrainInfo({
                            start: date,
                        });
                        LCMD.executeCommand({
                            cmd: "takting",
                            value: {
                                id: trainId,
                                start: HelperDateToUTCEpochMS(date),
                            },
                            preview: true,
                        });
                    }}
                />
                <p className="text-sm leading-5">
                    {trainInfo.start &&
                        intl.get("fw.startEndDateControl.cw", { cw: weekNumber(new Date(trainInfo.start)) })}{" "}
                    {trainInfo.start?.toLocaleDateString(getCurrentLanguage(), { weekday: "long" })}
                </p>
            </div>
            <div className="mb-2 border-b px-0 py-2">
                <div className="flex flex-col gap-2">
                    <Label className="font-semibold">{intl.get("WhiteboardDetailsView.label_takting")}</Label>
                    <div className="flex items-center gap-1.5">
                        <Switch
                            id="orderByName"
                            checked={orderByNameSuffix}
                            onCheckedChange={(checked) => {
                                onOrderByNameSuffix(checked);
                                LCMD.executeCommand({
                                    cmd: "takting",
                                    orderByNameSuffix: checked,
                                    preview: true,
                                });
                            }}
                        />
                        <Label htmlFor="orderByName">
                            {orderByNameSuffix
                                ? intl.get("WhiteboardDetailsView.on")
                                : intl.get("WhiteboardDetailsView.off")}
                        </Label>
                    </div>
                </div>
                <span className="text-[10px] text-zinc-500">{intl.get("WhiteboardDetailsView.infoText")}</span>
            </div>

            <TaktDependenciesTextField
                value={trainInfo.dep}
                onChange={(value) => {
                    setTrainInfo({
                        dep: value,
                    });
                    LCMD.executeCommand({
                        cmd: "takting",
                        value: {
                            id: trainId,
                            dep: value,
                        },
                        preview: true,
                    });
                }}
            />
            <span className="text-[10px] text-zinc-500">{intl.get("WhiteboardDetailsView.description")}</span>
        </div>
    );
}
