export type User = {
    id: string;
    email: string;
    fullName?: string;
    firstName?: string;
    lastName?: string;
};

export function usersFilter(text: string, users: User[]) {
    return users.filter((i) => {
        return (
            i.fullName?.toLowerCase().includes(text.toLowerCase()) ||
            i.email?.toLowerCase().includes(text.toLowerCase())
        );
    });
}
