import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { intl } from "@/legacy/GlobalHelperReact";
import { cn } from "@/lib/utils";
import { forwardRef } from "react";
import { FileUploadPopupState } from "../../types/initialState";

type DetailsViewProps = {
    data: FileUploadPopupState["data"];
    viewOptions?: {
        files?: FileList | File[];
        clone?: boolean;
    };
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => boolean;
};

export const DetailsView = forwardRef<HTMLFormElement, DetailsViewProps>(({ data, viewOptions, onSubmit }, ref) => {
    return (
        <div className="w-full">
            <div className={cn("pb-2.5", !data?.upload_result?.file && "hidden")}>
                {viewOptions?.clone ? (
                    <span>{intl.getHTML("legacyProjectDialog.details.successClone")}</span>
                ) : (
                    <span>
                        {intl.getHTML("legacyProjectDialog.details.successUpload", {
                            file: data?.upload_result?.file?.fileName || "?",
                        })}
                    </span>
                )}
            </div>
            <form ref={ref} onSubmit={onSubmit}>
                <Label className="text-[#323130]" htmlFor="formProjectName">
                    {intl.get("legacyProjectDialog.details.formProjectName.label")}
                </Label>
                <Input
                    id="formProjectName"
                    placeholder={intl.get("legacyProjectDialog.details.formProjectName.placeholder")}
                    defaultValue={data?.upload_result?.props?.projectTitle || data?.upload_result?.file?.fileName || ""}
                    className="mb-2 mt-1"
                    data-userpilot-id="fileUploadPopup-projectName"
                />
                <Label className="text-[#323130]" htmlFor="formCommitMessage">
                    {intl.get("legacyProjectDialog.details.formCommitMessage.label")}
                </Label>
                <Textarea
                    id="formCommitMessage"
                    data-userpilot-id="fileUploadPopup-projectDescription"
                    className="mb-2 mt-1"
                    rows={6}
                />
                <div className={cn(data?.clone && "hidden")}>
                    <Label htmlFor="formDefaultCalendar">
                        {intl.get("legacyProjectDialog.details.formDefaultCalendar.label")}
                    </Label>
                    <Select
                        name="formDefaultCalendar"
                        defaultValue="5"
                        data-userpilot-id="fileUploadPopup-projectDefaultCalendar"
                    >
                        <SelectTrigger>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="5">
                                {intl.get("legacyProjectDialog.details.formDefaultCalendar.5")}
                            </SelectItem>
                            <SelectItem value="6">
                                {intl.get("legacyProjectDialog.details.formDefaultCalendar.6")}
                            </SelectItem>
                            <SelectItem value="7">
                                {intl.get("legacyProjectDialog.details.formDefaultCalendar.7")}
                            </SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </form>
        </div>
    );
});

DetailsView.displayName = "DetailsView";
