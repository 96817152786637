import * as React from "react";

import { WidgetContent } from "../../layout/widget-content";
import { ChartJSFactory } from "../../common/chartjs";
import { Chart, intl } from "lcmd2framework";
import { DashboardCtxPropI } from "../..";

const dataView = {
    name: "dashboard.stability",
};

function onRenderChartData(ctx: any, chart: any, rawData: any) {
    //console.log("onRenderChartData");
    //console.log(rawData);
    const stability = rawData?.stability || [];
    return {
        labels: [
            intl.get("charts.scheduleStability.labels.threePlusDaysEarly"),
            intl.get("charts.scheduleStability.labels.oneThreeDaysEarly"),
            intl.get("charts.scheduleStability.labels.onTime"),
            intl.get("charts.scheduleStability.labels.oneThreeDaysLate"),
            intl.get("charts.scheduleStability.labels.threePlusDaysLate"),
        ],
        datasets: [
            {
                data: stability,
                label: "Status",
                backgroundColor: ["#107C10", "#088B72", "#009BD4", "#E47829", "#D83B01"],
            },
        ],
    };
}

function Content(props: DashboardCtxPropI) {
    const ActualBaselineChartFactory = React.useMemo(
        () =>
            ChartJSFactory.bind(null, {
                legendContainerId: "stab",
                type: "doughnut",
                onRenderChartData,
                onOutdatedChartData: props.dashboardCtx.onOutdatedChartData,
            }),
        [],
    );
    return (
        <WidgetContent height={200}>
            <Chart legendContainerId={"stab"} dataView={dataView} factory={ActualBaselineChartFactory} />
        </WidgetContent>
    );
}

export { Content };
