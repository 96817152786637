import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import { Icon, IStackTokens, Stack, Text } from "@fluentui/react";

const tokensProps: IStackTokens = { childrenGap: 5, padding: 5 };

function NotFoundFilters() {
    return (
        <Stack horizontal verticalAlign="center" horizontalAlign="center" tokens={tokensProps}>
            <Icon iconName="SearchIssue" />
            <Text>{intl.get("canvas.cmd.filter.cmd.search.notFoundText")}</Text>
        </Stack>
    );
}

export { NotFoundFilters };
