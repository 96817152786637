﻿/*`*
 * This code is taken from an external tooltip example of the official chart.js documentation:
 * https://www.chartjs.org/docs/latest/samples/tooltip/html.html
 */

import { Chart, TooltipModel } from "chart.js";

const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector(".chartjs-external-tooltip");

    if (tooltipEl) {
        return tooltipEl;
    }

    tooltipEl = document.createElement("div");
    tooltipEl.classList.add("chartjs-external-tooltip");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);

    return tooltipEl;
};

const externalTooltipHandler = ({ chart, tooltip }: { chart: Chart; tooltip: TooltipModel<any> }) => {
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((x) => x.lines);

        const tableHead = document.createElement("thead");

        titleLines.forEach((title) => {
            const tr = document.createElement("tr");
            const th = document.createElement("th");
            const text = document.createTextNode(title);

            th.style.borderWidth = "0";
            tr.style.borderWidth = "0";

            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
        });

        const tableBody = document.createElement("tbody");
        bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];

            const span = document.createElement("span");
            span.style.background = colors.backgroundColor as string;
            span.style.borderColor = colors.borderColor as string;
            span.style.borderWidth = "2px";
            span.style.marginRight = "10px";
            span.style.height = "10px";
            span.style.width = "10px";
            span.style.display = "inline-block";

            const tr = document.createElement("tr");
            tr.style.backgroundColor = "inherit";
            tr.style.borderWidth = "0";

            const td = document.createElement("td");
            td.style.borderWidth = "0";

            const text = document.createTextNode(body as unknown as any);

            td.appendChild(span);
            td.appendChild(text);
            tr.appendChild(td);
            tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector("table");

        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
    tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
    tooltipEl.style.font = (tooltip.options.bodyFont as any).string;
    tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
};

export default externalTooltipHandler;
