import * as React from "react";
import { Button } from "../../ui/button";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import { useEffect, useMemo, useState } from "react";
import { useFilterCollection } from "@/components/Filter/data-access-hooks/use-filter-collection";
import { intl } from "@/legacy/GlobalHelperReact";
import { useLCMD } from "@/app/LCMContext";
import { Separator } from "@/components/ui/separator";
import { ArrowDown, Pencil, Settings2, Trash2 } from "lucide-react";
import { DropdownMenuItemProps } from "@radix-ui/react-dropdown-menu";
import { cn } from "@/lib/utils";
import { FilterItem } from "../../Filter/data-access-hooks/use-filter-data";
import { Status } from "../../hooks/use-loading-status";
import { SearchBar } from "../SearchBar/SearchBar";

/* content menu */
//TODO: Build to shadCN
// const noFilterFoundItem: DropdownMenuItemProps = {
//     key: "noFiltersFound",
//     onRender: NotFoundFilters,
// };

type DropdownMenuItemPropsExtended = DropdownMenuItemProps & {
    text: string;
};

export type ContextualMenuProps = {
    onSelect: (filterId: string) => void;
    onClear: () => void;
    onManage: () => void;
    items: FilterItem[];
    selectedFilterId: string;
    refresh: () => void;
    status: Status;
};

function normalizeFilterText(text: string) {
    return text.trim().toLowerCase();
}

function ContextualMenu(props: ContextualMenuProps) {
    const [searchInput, setSearchInput] = useState("");
    const [loadingFiltersItem, setLoadingFiltersItem] = useState(null);
    const { items: filterItems, selectedFilterId, status, refresh } = props;

    useEffect(() => {
        setLoadingFiltersItem(
            !status.loaded
                ? {
                      key: "loading",
                      text: intl.get("fw.loading"),
                      disabled: true,
                  }
                : null,
        );
    }, [status.loaded]);

    const filterOptions: DropdownMenuItemPropsExtended[] = useMemo(() => {
        return filterItems.map((filterItem) => {
            return {
                key: filterItem._hid.toString(),
                text: filterItem.name || "?",
                canCheck: true,
                checked: selectedFilterId === filterItem._hid,
                onClick: () => props.onSelect(filterItem._hid),
            };
        });
    }, [filterItems, selectedFilterId, props.onSelect]);

    const filteredOptions = useMemo(() => {
        const filterByText = normalizeFilterText(searchInput);

        // when search input empty
        if (!filterByText) {
            return filterOptions;
        }

        const filteredOptions: DropdownMenuItemPropsExtended[] = filterOptions.filter(
            (option: DropdownMenuItemPropsExtended) => normalizeFilterText(option.text).includes(filterByText),
        );

        return filteredOptions.length
            ? filteredOptions
            : [{ key: "noFiltersFound", text: intl.get("canvas.cmd.filter.cmd.search.notFoundText") }];
    }, [filterOptions, searchInput]);

    const menuItems = useMemo(() => {
        return loadingFiltersItem ? [loadingFiltersItem] : filteredOptions;
    }, [loadingFiltersItem, filteredOptions]);

    return (
        <DropdownMenuContent className="w-56 p-0">
            <SearchBar
                placeholder={intl.get("canvas.cmd.filter.cmd.search.placeholder")}
                value={searchInput}
                onChange={(event) => {
                    event.preventDefault();
                    setSearchInput(event.target.value);
                    setTimeout(() => {
                        event.target.focus();
                    }, 0);
                }}
                onSearch={setSearchInput}
                inputClassName="rounded-none border-t-none focus-visible:ring-0 focus-visible:ring-offset-0 pl-9"
                searchBarClassName="h-4 w-4 text-black"
            />
            <DropdownMenuGroup className="p-1">
                <div className="max-h-64 overflow-y-auto">
                    {menuItems.length > 0 &&
                        menuItems.map((item) => (
                            <DropdownMenuCheckboxItem
                                checked={selectedFilterId?.toString() === item.key}
                                key={item.key}
                                onClick={() => {
                                    props.onSelect(item.key);
                                    refresh();
                                }}
                                className={cn(
                                    "focus:bg-[#E9ECED]",
                                    selectedFilterId?.toString() === item.key && "bg-[#E9ECED]",
                                )}
                                iconStrokeWidth={3}
                            >
                                {item.text}
                            </DropdownMenuCheckboxItem>
                        ))}
                </div>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                    key="edit"
                    onClick={props.onManage}
                    data-userflow-id="pp-filter-bearbeiten"
                    className="flex items-center gap-2"
                >
                    <Pencil size={16} />
                    {intl.get("canvas.cmd.filter.title")}
                </DropdownMenuItem>
                <DropdownMenuItem
                    key="clear"
                    onClick={() => {
                        props.onClear();
                        refresh();
                    }}
                    data-userflow-id="pp-filter-zurueksetzen"
                    className="flex items-center gap-2"
                >
                    <Trash2 size={16} />
                    {intl.get("canvas.cmd.filter.cmd.clear.text")}
                </DropdownMenuItem>
            </DropdownMenuGroup>
        </DropdownMenuContent>
    );
}

export function FilterButton({
    checked,
    userflowId,
    userflowId2,
}: {
    checked: boolean;
    userflowId?: string;
    userflowId2?: string;
}) {
    const LCMD = useLCMD();
    const filterCollection = useFilterCollection();

    return (
        <div className="group flex items-center rounded-md bg-secondary text-secondary-foreground duration-150 hover:bg-zinc-300 hover:duration-150">
            <Button
                variant="secondary"
                className={cn(
                    checked && "bg-gray-200 hover:bg-gray-200",
                    "flex h-8 items-center rounded-r-none bg-zinc-100 px-3 duration-150 hover:duration-150 group-hover:bg-zinc-300",
                )}
                onClick={() => LCMD.showDialog("filter", {})}
                userflowId={userflowId2}
            >
                <Settings2 size={20} className="mr-2" />
                <div>{intl.get("canvas.cmd.filter.text")}</div>
            </Button>
            <Separator
                orientation="vertical"
                decorative
                className={cn(
                    "h-4 bg-[#C1C5C7] transition-opacity duration-150",
                    checked ? "opacity-0 group-hover:opacity-100" : "opacity-100",
                )}
            />
            <DropdownMenu onOpenChange={filterCollection.refresh}>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant="secondary"
                        className={cn(
                            checked && "bg-zinc-300 text-secondary-foreground hover:bg-zinc-300",
                            "h-8 rounded-l-none px-2 duration-150 hover:duration-150 group-hover:bg-zinc-300",
                        )}
                        userflowId={userflowId}
                    >
                        <ArrowDown size={16} />
                    </Button>
                </DropdownMenuTrigger>
                <ContextualMenu
                    onSelect={(filterId) => LCMD.setFilter(filterId)}
                    onClear={() => LCMD.setFilter(null)}
                    onManage={() => LCMD.showDialog("filter", { manage: true })}
                    {...filterCollection}
                />
            </DropdownMenu>
        </div>
    );
}
