import * as React from "react";
import { assert, Dialog, DialogScreen, FilterDialogOptions, intl } from "lcmd2framework";
import { ManageScreen } from "./screens/ManageScreen";
import { MainScreen } from "./screens/MainScreen";
import { TaktzonesScreen } from "./screens/TaktzonesScreen";
import { TradesScreen } from "./screens/TradesScreen";
import { DateScreen } from "./screens/DateScreen";
import { SaveScreen } from "./screens/SaveScreen";
import {
    ConstrainMode,
    DetailsList,
    DetailsRowCheck,
    IColumn,
    ICommandBarItemProps,
    SelectionMode,
} from "@fluentui/react";
import { onRenderProcessName, renderRow } from "../../ProcessView";
import { useLCMD } from "../../../app/LCMContext";

export function FilterDialog(props: {
    isOpen: {
        manage?: boolean;
        modeDailyBoard?: boolean;
        trades?: {
            trades: number[];
            selected: (selected) => void;
            title?: string;
            multiselect?: boolean;
            commandBarItems?: ICommandBarItemProps[];
        };
        taktSelection?: { tz: number[] | null; selected: (tz: number[]) => void };
    } | null;
    onClose: () => void;
}) {
    return props.isOpen ? (
        <Dialog
            initialScreen={(dialog) => {
                if (props.isOpen?.manage) {
                    dialog.pushScreen("manage", {
                        onEdit: props?.onClose,
                        filterOptions: props.isOpen,
                    });
                } else {
                    dialog.pushScreen("main", {
                        onClose: props?.onClose,
                        filterOptions: props.isOpen,
                    });
                }
            }}
            isOpen={true}
        >
            <DialogScreen name="main" component={MainScreen} />
            <DialogScreen name="tz" component={TaktzonesScreen} />
            <DialogScreen name="trades" component={TradesScreen} />
            <DialogScreen name="date" component={DateScreen} />
            <DialogScreen name="save" component={SaveScreen} />
            <DialogScreen name="manage" component={ManageScreen} />
        </Dialog>
    ) : null;
}

// REFACTOR ME

const detailsRowCheckStyles20x20 = {
    check: {
        width: 20,
        height: 20,
        //opacity: 0.25
    },
};

function getTIDKey(item) {
    return item.tid;
}

function getIDKey(item) {
    return item.id;
}

function onChevronClick(this, selection, item: any, ev) {
    if (2 === item.s || 1 === item.s) {
        const collapse = 1 === item.s;
        this((collapsed) => {
            const ret = Object.assign({}, collapsed);
            if (collapse) {
                ret[item.tid] = true;
            } else {
                delete ret[item.tid];
            }
            return ret;
        });
    }
}

function onTZSelect(setSelected, item) {
    setSelected((selected) => {
        const ret = Object.assign({}, selected);
        if (item.sel) {
            delete ret[item.tid];
        } else {
            ret[item.tid] = item;
        }
        return ret;
    });
}

function isAllSelected(items: any[], selected, trades = false): boolean {
    if (trades) {
        return items.every((i) => i.id === selected[i.id]?.id);
    } else {
        return items.every((i) => i.tid === selected[i.tid]?.tid);
    }
}

function selectAll(items: any[], selected, setSelected, trades = false) {
    const idProp = trades ? "id" : "tid";
    if (isAllSelected(items, selected, trades)) {
        // deselect all
        const tmpSelected = { ...selected };
        items.forEach((i) => {
            delete tmpSelected[i[idProp]];
        });
        setSelected(tmpSelected);
    } else {
        // select all
        const s = {};
        items.forEach((i) => {
            s[i[idProp]] = i;
        });
        setSelected(s);
    }
}

function onTradeSelect(this: boolean, setSelected, item) {
    setSelected((selected) => {
        if (this) {
            const ret = Object.assign({}, selected);
            if (item.sel) {
                delete ret[item.id];
            } else {
                ret[item.id] = item;
            }
            return ret;
        } else {
            return {
                [item.id]: item,
            };
        }
    });
}

const generateTaktZoneColumns: (setCollapsed, setSelected) => IColumn[] = (setCollapsed, setSelected) => [
    {
        isGrouped: false,
        isMultiline: false,
        key: "column0",
        name: "",
        fieldName: "tid",
        minWidth: 20,
        maxWidth: 20,
        onRender: (item) => (
            <DetailsRowCheck
                styles={detailsRowCheckStyles20x20}
                canSelect={true}
                selected={item.sel}
                isVisible={true}
                compact={true}
                onClick={onTZSelect.bind(null, setSelected, item)}
            />
        ),
    },
    {
        isGrouped: false,
        isMultiline: false,
        key: "column1",
        name: intl.get("filter.tz.header"),
        fieldName: "n",
        minWidth: 100,
        onRender: onRenderProcessName.bind(setCollapsed, true, onChevronClick, null, true),
    },
];

const generateTradesColumns: (setSelected, multiselect) => IColumn[] = (setSelected, multiselect) => [
    {
        isGrouped: false,
        isMultiline: false,
        key: "column0",
        name: "",
        fieldName: "id",
        minWidth: 20,
        maxWidth: 20,
        onRender: (item) => (
            <DetailsRowCheck
                styles={detailsRowCheckStyles20x20}
                canSelect={true}
                selected={item.sel}
                isVisible={true}
                compact={true}
                onClick={onTradeSelect.bind(multiselect, setSelected, item)}
            />
        ),
    },
    {
        isGrouped: false,
        isMultiline: false,
        key: "column1",
        name: "Trades",
        fieldName: "name",
        minWidth: 100,
    },
];

function searchWBS(wbs: any[], search: string) {
    const terms = [search.trim().toLocaleLowerCase()];
    const n_terms = terms.length;
    const path = [];
    const n = wbs.length;
    for (let i = 0; i < n; i++) {
        const item = wbs[i];
        while (item.l < path.length) {
            path.pop();
        }
        assert(item.l === path.length);
        const name: string = (item.n || "").trim().toLocaleLowerCase();
        let hit = false;
        for (let i_term = 0; i_term < n_terms; i_term++) {
            hit = hit || name.indexOf(terms[i_term]) >= 0;
        }
        if (hit) {
            // mark path
            const n_path = path.length;
            for (let i_path = 0; i_path < n_path; i_path++) {
                path[i_path]._hit |= 0x1;
            }
            item._hit = 0x3;
        } else {
            item._hit = 0; // clear flag...
        }
        path.push(item);
    }
}

function filterWBS(wbs: any[], collapsed: any, selected: any, search: string) {
    const ret = [];
    if (search) {
        searchWBS(wbs, search);
    }
    const n = wbs.length;
    for (let i = 0; i < n; ) {
        const sel = wbs[i].tid in selected;
        if (wbs[i].tid in collapsed) {
            ret.push({ ...wbs[i], s: 2, sel: sel });
            let j = i + 1;
            while (j < n && wbs[j].l > wbs[i].l) j++;
            i = j;
        } else if (!search || wbs[i]._hit & 0x1) {
            const _hit = search ? wbs[i]._hit : 0;
            if (sel) {
                ret.push({ ...wbs[i++], sel: sel, hit: _hit });
            } else if (_hit) {
                ret.push({ ...wbs[i++], hit: _hit });
            } else {
                ret.push(wbs[i++]);
            }
        } else {
            i++; // skip...
        }
    }
    return ret;
}

function filterTrades(trades: any[], selected: any, search: string) {
    const filter = search
        ? function (item) {
              const name = (item?.name || "").trim().toLocaleLowerCase();
              return name.indexOf(this) >= 0;
          }.bind(search.trim().toLocaleLowerCase())
        : () => true;
    const ret = trades.filter(filter).map((item) => {
        const sel = item.id in selected;
        if (sel) {
            return { ...item, sel: sel };
        } else {
            return item;
        }
    });
    return ret;
}

export function TradesSelectionDialog(props: {
    isOpen: {
        title: string;
        tid: number;
        trades: number[];
        cb?: (sel: number[]) => void;
        multiselect?: boolean;
        commandBarItems?: ICommandBarItemProps[];
    } | null;
    onClose: () => void;
    options?: FilterDialogOptions;
}) {
    const LCMD = useLCMD();
    return null !== props.isOpen ? (
        <FilterDialog
            isOpen={{
                ...(props.options || {}),
                trades: {
                    trades: props.isOpen.trades,
                    title: props.isOpen.title,
                    multiselect: props.isOpen.multiselect,
                    selected: (selected) => {
                        if (selected) {
                            const sel = Object.getOwnPropertyNames(selected).map((_id) => Number.parseInt(_id));
                            if (props?.isOpen?.cb) {
                                props.isOpen.cb(sel);
                            } else {
                                LCMD.setProcessDetails(props.isOpen.tid, {
                                    trades: {
                                        value: sel,
                                    },
                                });
                            }
                        }
                        props.onClose();
                    },
                    commandBarItems: props.isOpen.commandBarItems,
                },
            }}
            onClose={props.onClose}
        />
    ) : null;
}

export function TaktzonesList({ tz, collapsed, selected, search, setCollapsed, setSelected }) {
    const columns = React.useMemo(() => generateTaktZoneColumns(setCollapsed, setSelected), []);
    const filteredTZ = filterWBS(tz, collapsed, selected, search);
    return (
        <DetailsList
            compact={true}
            columns={columns}
            items={filteredTZ}
            selectionMode={SelectionMode.none}
            constrainMode={ConstrainMode.unconstrained}
            useReducedRowRenderer={true}
            getKey={getTIDKey}
            onRenderDetailsHeader={() => (
                <DetailsRowCheck
                    styles={{ ...detailsRowCheckStyles20x20, check: { marginLeft: -2 } }}
                    canSelect={true}
                    selected={isAllSelected(filteredTZ, selected)}
                    isVisible={true}
                    compact={true}
                    onClick={() => {
                        selectAll(filteredTZ, selected, setSelected);
                    }}
                />
            )}
            onRenderRow={renderRow}
        />
    );
}

export function TradesList({ setSelected, multiselect, trades, selected, search }) {
    const columns = React.useMemo(() => generateTradesColumns(setSelected, multiselect), [setSelected, multiselect]);
    const filteredItems = filterTrades(trades, selected, search);
    return (
        <DetailsList
            compact={true}
            columns={columns}
            items={filteredItems}
            selectionMode={SelectionMode.none}
            constrainMode={ConstrainMode.unconstrained}
            useReducedRowRenderer={true}
            getKey={getIDKey}
            onRenderDetailsHeader={() =>
                multiselect ? (
                    <DetailsRowCheck
                        styles={{ ...detailsRowCheckStyles20x20, check: { marginLeft: -2 } }}
                        canSelect={true}
                        selected={isAllSelected(filteredItems, selected, true)}
                        isVisible={true}
                        compact={true}
                        onClick={() => {
                            selectAll(filteredItems, selected, setSelected, true);
                        }}
                    />
                ) : null
            }
            onRenderRow={renderRow}
        />
    );
}

export function DailyBoardTaktSelectionDialog(props: { tz: number[] | null; onClose: (selection?: number[]) => void }) {
    return (
        <FilterDialog
            onClose={props.onClose}
            isOpen={
                props.tz
                    ? {
                          taktSelection: {
                              tz: props.tz,
                              selected: props.onClose,
                          },
                      }
                    : null
            }
        />
    );
}
