import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ModalAssetHeader } from "../../ModalAsset";
import {
    CheckboxVisibility,
    DefaultButton,
    DetailsList,
    IColumn,
    Icon,
    PrimaryButton,
    SearchBox,
    SelectionMode,
    DetailsRowCheck,
} from "@fluentui/react";
import { ModalAssetFooter } from "../../ModalAsset/ModalAssetFooter";
import { useEffect, useState } from "react";
import { decimalToRgb } from "../../../utils/color/decimal-to-rgb";
import { Icons } from "../../../RegisteredIcons";
import { copyAndSort } from "../../../Utils";
import { intl } from "@/legacy/GlobalHelperReact";
import { RouteNames } from "../filter.interface";
import { useToDoModalFilterStore } from "../../../../app/store/toDoModalFilterStore";
import { styles } from "../filterModalStyles";

export function TradesFilter() {
    const { filterData, setFilterData, trades: allTrades } = useToDoModalFilterStore();

    const navigate = useNavigate();

    const [trades, setTrades] = useState(allTrades);
    const [selectedItems, setSelectedItems] = useState<Map<number, any>>(new Map());
    const [sortState, setSortState] = useState({ sortedDescending: false, fieldName: "name" });

    const [columns, setColumns] = useState<IColumn[]>([
        {
            key: "trade",
            name: "Name",
            fieldName: "trade",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: (ev, column) => {
                setSortState({
                    ...sortState,
                    sortedDescending: !column.isSortedDescending,
                });
                setColumns(
                    columns.map((col) => {
                        col.isSorted = col.key === column.key;
                        if (col.isSorted) {
                            col.isSortedDescending = !column.isSortedDescending;
                        }
                        return col;
                    }),
                );
            },
        },
    ]);

    useEffect(() => {
        const tempTrades = copyAndSort(allTrades, sortState.fieldName, sortState.sortedDescending);
        setTrades(tempTrades);
    }, [allTrades, sortState, filterData]);

    useEffect(() => {
        const localFilterData = filterData?.tradesFilterData || [];
        const tempMap = new Map();
        localFilterData?.forEach((obj) => {
            tempMap.set(obj.id, obj);
        });
        setSelectedItems(tempMap);
    }, [filterData?.tradesFilterData]);

    function headerTitle() {
        return (
            <>
                {intl.get("ModalToDoFilter.TradesFilter.filter")} / {intl.get("ModalToDoFilter.TradesFilter.trades")}
            </>
        );
    }

    function onChangeText(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string) {
        setTrades(text ? allTrades.filter((i) => i.name?.toLowerCase().indexOf(text.toLowerCase()) > -1) : allTrades);
    }

    function renderItemColumn(
        item: { id: string; color: number; name: string; label: string },
        index: number | undefined,
        column: IColumn | undefined,
    ) {
        if (typeof column === "undefined") {
            return false;
        }
        const style = {
            fontSize: "14px",
            display: "flex",
        };

        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case "trade":
                return (
                    <div style={style}>
                        <Icon iconName={Icons.Lcmd_Trade} styles={{ root: { color: decimalToRgb(item.color) } }} />
                        {fieldContent?.length ? fieldContent : item.name}
                    </div>
                );
            default:
                return <div style={style}>{fieldContent}</div>;
        }
    }

    function footerLeftContent() {
        return (
            <DefaultButton
                styles={{
                    root: { border: "none" },
                    rootDisabled: { backgroundColor: "transparent" },
                }}
                disabled={!selectedItems.size}
                text={intl.get("ModalToDoFilter.TradesFilter.clearFilters")}
                iconProps={{ iconName: "Delete" }}
                onClick={() => {
                    setTrades([...trades]);
                    setSelectedItems(new Map());
                }}
            />
        );
    }

    function footerRightContent() {
        return (
            <>
                {selectedItems?.size} {intl.get("ModalToDoFilter.TradesFilter.tradesSelected")}
                <PrimaryButton
                    text={intl.get("ModalToDoFilter.TradesFilter.done")}
                    styles={{ root: { marginLeft: 10 } }}
                    onClick={() => {
                        setFilterData({
                            ...filterData,
                            tradesFilterData: Array.from(selectedItems.values()),
                        });
                        navigate(RouteNames.HOME);
                    }}
                />
            </>
        );
    }

    return (
        <>
            <ModalAssetHeader
                onBackButtonChange={() => {
                    navigate(RouteNames.HOME);
                }}
                title={headerTitle()}
            />

            <div className={styles.body}>
                <div className={styles.searchField}>
                    <SearchBox placeholder={intl.get("ModalToDoFilter.TradesFilter.search")} onChange={onChangeText} />
                </div>
                <DetailsList
                    styles={{
                        root: {
                            maxHeight: 440,
                        },
                    }}
                    items={trades}
                    columns={columns}
                    onRenderItemColumn={renderItemColumn}
                    setKey="set"
                    compact={true}
                    checkboxVisibility={CheckboxVisibility.always}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick={true}
                    enterModalSelectionOnTouch={true}
                    onRenderRow={(propsRow, defaultRenderRow) => {
                        propsRow.onRenderDetailsCheckbox = (propsRowCheck, defaultRender1) => {
                            propsRowCheck.checked = selectedItems.get(propsRow.item.id);
                            return (
                                <DetailsRowCheck
                                    canSelect={true}
                                    isVisible={true}
                                    compact={true}
                                    selected={propsRowCheck.checked}
                                    onClick={() => {
                                        setTrades([...trades]);
                                        if (selectedItems.get(propsRow.item.id)) {
                                            selectedItems.delete(propsRow.item.id);
                                        } else {
                                            selectedItems.set(propsRow.item.id, propsRow.item);
                                        }
                                        setSelectedItems(selectedItems);
                                    }}
                                />
                            );
                        };

                        return defaultRenderRow({ ...propsRow });
                    }}
                />
            </div>

            <ModalAssetFooter leftContent={footerLeftContent()} rightContent={footerRightContent()} />
        </>
    );
}
