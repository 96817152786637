import { Fragment, useEffect, useState } from "react";

import {
    getTheme,
    mergeStyleSets,
    Text,
    Stack,
    Nav,
    INavStyleProps,
    INavStyles,
    IStyleFunctionOrObject,
    IRawStyle,
    INavLink,
    PrimaryButton,
    StackItem,
} from "@fluentui/react";
import * as React from "react";
import { CalendarSettingsView } from "./settings/calendar-settings/CalendarSettings.view.component";
import { StabilityCriteriaSettingsView } from "./settings/stability-criteria";
import { PdfSettingsView } from "./settings/pdf-settings/PdfSettings.view.component";
import { intl } from "lcmd2framework";
import { ICustomizedNavLink, ICustomizedNavLinkGroup } from "./interface";
import { ChangesSettings } from "./settings/changes-settings/changes-settings";
import { useCalendarStore } from "../app/store/saveCalendarSettings";
import { saveSettings } from "../utils/SettingsUtils";

const theme = getTheme();
const { palette, semanticColors, fonts } = theme;
const tokensProps = { maxWidth: "100%" };

const classNames = mergeStyleSets({
    layout: {
        backgroundColor: "#E5E5E5",
        overflow: "auto",
        width: "100%", // need to fill full width remove when Dashboard component will be insert not in flex box
    },
});

const styleForNav: IStyleFunctionOrObject<INavStyleProps, INavStyles> = (navItemProps) => {
    const rootStyle: IRawStyle = {
        backgroundColor: navItemProps.isSelected ? "#C7E7F4" : "transparent",
    };

    return {
        root: rootStyle,
        navItem: { backgroundColor: navItemProps.isSelected ? "#C7E7F4" : "transparent" },
    };
};

const navigationStyles: IStyleFunctionOrObject<INavStyleProps, INavStyles> = {
    root: {
        height: "100vh",
        boxSizing: "border-box",
        border: "1px solid #eee",
        overflowY: "auto",
        padding: "10px",
        minWidth: "240px",
    },
    navItem: {
        ".is-selected": {
            backgroundColor: "#C7E7F4",
        },
    },
};

export function Settings({
    navigationOptions,
    subView = "lcmd.settings.pdf",
}: {
    navigationOptions?: ICustomizedNavLinkGroup[] | undefined;
    subView?: string;
}) {
    const calendarSettingsStore = useCalendarStore();
    if (navigationOptions === undefined) {
        navigationOptions = [
            {
                links: [
                    {
                        name: intl.get("Settings.PDFExport"),
                        key: "lcmd.settings.pdf",
                        url: "",
                        view: <PdfSettingsView />,
                    },
                    {
                        name: intl.get("Settings.Calender"),
                        key: "lcmd.settings.calendar",
                        //TODO: make static text variable
                        description: "",
                        url: "",
                        view: <CalendarSettingsView />,
                    },
                    {
                        name: intl.get("Settings.StabilityCriteria"),
                        key: "lcmd.settings.stability-criteria",
                        url: "",
                        view: <StabilityCriteriaSettingsView />,
                    },
                    {
                        name: intl.get("ReasonCodesSettings.headline"),
                        key: "lcmd.settings.changes-settings",
                        url: "",
                        view: <ChangesSettings />,
                    },
                ],
            },
        ];
    }
    const selectedIndex = (naviOptions: ICustomizedNavLinkGroup[], subViewName: string): ICustomizedNavLink => {
        const view = naviOptions[0].links.find((l) => l.key === subViewName);
        if (!view) {
            console.error(`subView doesn't exist redirect to '${subView}'!`);
        }
        return view ? view : naviOptions[0].links[0];
    };

    const [activeSetting, setActiveSetting] = useState(selectedIndex(navigationOptions, subView));
    useEffect(() => {
        setActiveSetting(selectedIndex(navigationOptions, subView));
    }, [subView]);

    const navItemSelected = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        if (activeSetting.key == "lcmd.settings.calendar") {
            const calendarSettings = calendarSettingsStore.calendarSettings;
            saveSettings(calendarSettings);
        }
        setActiveSetting(item);
    };

    return (
        <Fragment>
            <Stack
                horizontal
                tokens={tokensProps}
                styles={{
                    root: {
                        width: "100%",
                    },
                }}
            >
                <Stack>
                    <Nav
                        groups={navigationOptions}
                        styles={navigationStyles}
                        selectedKey={activeSetting.key ? activeSetting.key : undefined}
                        onLinkClick={navItemSelected}
                    ></Nav>
                </Stack>

                <Stack
                    styles={{
                        root: {
                            position: "relative",
                            width: "100%",
                            margin: activeSetting.description?.length > 0 ? "10px 0" : null,
                        },
                    }}
                >
                    <StackItem>
                        <Stack
                            verticalAlign={!activeSetting.description ? "center" : null}
                            styles={{
                                root: { borderBottom: "1px solid #E1E4E5", minHeight: "60px", padding: "0 22px" },
                            }}
                            tokens={tokensProps}
                        >
                            <Stack horizontal verticalAlign={"center"}>
                                <Text variant="xxLarge" styles={{ root: { fontSize: "20px", lineHeight: "28px" } }}>
                                    {activeSetting.name}
                                </Text>
                                {false && <PrimaryButton>Save Changes</PrimaryButton>}
                            </Stack>
                            {activeSetting.description?.length > 0 && (
                                <Text variant="medium" styles={{ root: { maxWidth: "580px", paddingBottom: "20px" } }}>
                                    {activeSetting.description}
                                </Text>
                            )}
                        </Stack>
                    </StackItem>
                    <StackItem>{activeSetting.view}</StackItem>
                </Stack>
            </Stack>
        </Fragment>
    );
}
