import * as React from "react";

import { mergeStyleSets } from "@fluentui/react";

export type ColorPointProps = {
    color: string;
};

const classNames = mergeStyleSets({
    icon: {
        display: "inline-block",
        width: 10,
        height: 10,
        borderRadius: "50%",
        marginRight: 5,
    },
});

function ColorPoint(props: ColorPointProps) {
    const { color } = props;

    const style = { backgroundColor: color };

    return <span data-testid="colorPoint" style={style} className={classNames.icon} />;
}

export { ColorPoint };
