import { useLCMD } from "../../app/LCMContext";
import { useEffect, useState } from "react";

// should not be used! Need to define role/access system first
export function useT3RolesHook(): boolean {
    const LCMD = useLCMD();
    const [isAllowed, setIsAllowed] = useState<boolean>(false);
    useEffect(() => {
        LCMD.getProjectDetails((error, data) => {
            if (error || !data || !data.sub) {
                // do not allow editing
                return;
            }
            if ("admin" === data.meta?.role || !data.rbac[data.sub]) {
                setIsAllowed(true);
            } else {
                setIsAllowed(false);
            }
        });
    }, [LCMD, setIsAllowed]);
    return isAllowed;
}
