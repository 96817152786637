import { Dispatch, useState } from "react";
import { useLCMD } from "../../../app/LCMContext";
import { HistoryList, Source } from "../../ChangesPanel/Routes/HistoryList/HistoryList";
import { WebAppTelemetryFactory } from "../../../app/services/WebAppTelemetry.service";
import { MentionsComponent } from "@/components/common/MentionsComponent";
import posthog from "posthog-js";
import { HistoryNavigationHeader } from "./HistoryView";
import { ScrollablePane, SelectionMode, Stack, TextField } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";
import { HistoryMeta } from "@/app/interface";
import { DigitalPlanningBoardSelectionEvent } from "@/legacy/api/lcmd2framework";

type SidebarHistoryView = {
    historyEvents: HistoryMeta[];
    setCommentDisable: Dispatch<boolean>;
    selectedProcesses: DigitalPlanningBoardSelectionEvent;
    createNotification: (comment: string) => Promise<{ fromUserId: string; users: string[]; notification: any }>;
    commentDisabled: boolean;
};

export function SidebarHistoryView({
    historyEvents,
    setCommentDisable,
    selectedProcesses,
    createNotification,
    commentDisabled,
}: SidebarHistoryView) {
    const LCMD = useLCMD();
    const [comment, setComment] = useState("");

    async function handleSendComment(event, newValue) {
        if (newValue && Array.isArray(selectedProcesses?.pid) && selectedProcesses.pid.length === 1) {
            if (event) {
                event.preventDefault();
            }
            setCommentDisable(true);
            const notification = await createNotification(newValue);

            LCMD.setProcessComment(selectedProcesses.pid[0], newValue, notification);
            WebAppTelemetryFactory.trackEvent("comment-history");

            setComment("");
        }
    }

    return (
        <>
            <div className="flex h-full flex-col">
                <HistoryNavigationHeader label={intl.get("HistoryView.HistoryLabelText")} />
                <div style={{ height: "calc(100% - 60px)", position: "relative" }}>
                    <ScrollablePane style={{ overscrollBehavior: "none" }}>
                        <Stack styles={{}}>
                            <HistoryList
                                historyChanges={historyEvents}
                                selectionMode={SelectionMode.none}
                                onSelect={() => {}}
                                source={Source.SINGLE_PROCESS}
                            />
                        </Stack>
                    </ScrollablePane>
                </div>
                <MentionsComponent handleSendComment={handleSendComment} />
            </div>
        </>
    );
}
