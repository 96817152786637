import { cn } from "@/lib/utils";
import { useEffect, useRef, useState } from "react";

export const Gauge = ({
    value,
    size = "small",
    showValue = true,
    className,
}: {
    value: number;
    size: "small" | "medium" | "large";
    showValue: boolean;
    className?: string;
}) => {
    const ref = useRef(null);
    const circumference = 301.6; //2 * Math.PI * 48; // 2 * pi * radius
    const valueInCircumference = (value / 100) * circumference;
    const strokeDasharray = `${circumference} ${circumference}`;
    const initialOffset = circumference;
    const [strokeDashoffset, setStrokeDashoffset] = useState(initialOffset);

    useEffect(() => {
        const dynamicStrokeDashoffset = initialOffset - valueInCircumference;
        setStrokeDashoffset(dynamicStrokeDashoffset);
        ref.current.style.setProperty("--stroke-dashoffset", strokeDashoffset);
    }, [value]);

    const sizes = {
        small: {
            width: "44",
            height: "44",
            textSize: "text-xs",
        },
        medium: {
            width: "72",
            height: "72",
            textSize: "text-lg",
        },
        large: {
            width: "144",
            height: "144",
            textSize: "text-3xl",
        },
    };

    return (
        <div key={value} className="flex flex-col items-center justify-center relative">
            <svg
                fill="none"
                shapeRendering="crispEdges"
                height={sizes[size].height}
                width={sizes[size].width}
                viewBox="0 0 120 120"
                strokeWidth="2"
                className="transform -rotate-90"
            >
                <circle
                    className="text-[#333]"
                    strokeWidth="23"
                    stroke="currentColor"
                    fill="transparent"
                    shapeRendering="geometricPrecision"
                    r="48"
                    cx="60"
                    cy="60"
                />
                <circle
                    ref={ref}
                    className={cn("text-green-600 animate-gauge_fill", className)}
                    strokeWidth="23"
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={initialOffset}
                    shapeRendering="geometricPrecision"
                    strokeLinecap="butt"
                    stroke="currentColor"
                    fill="transparent"
                    r="48"
                    cx="60"
                    cy="60"
                    style={{
                        strokeDashoffset: strokeDashoffset,
                        transition: "stroke-dasharray 1s ease 0s,stroke 1s ease 0s",
                    }}
                />
            </svg>
            {showValue ? (
                <div className="absolute flex opacity-0 animate-gauge_fadeIn">
                    <p
                        className={`font-semibold text-base leading-5 tracking-tighter text-center text-gray-50 ${sizes[size].textSize}`}
                    >
                        {value}
                    </p>
                </div>
            ) : null}
        </div>
    );
};
