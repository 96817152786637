import { Shimmer, Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { ScheduleSettings } from "./ScheduleSettings";
import { useLCMD } from "../../../app/LCMContext";
import { useEffect } from "react";
import { FrameworkError } from "lcmd2framework";
import { CanvasSettings } from "./CanvasSettings";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

// current meta type, but should be extended in the ParticleContext
type Meta = {
    "firstName": string,
    "lastName": string,
    "prefs": {
        "showNWD": boolean,
        "fs": number
    }
}

export function PreferenceSettings() {
    const LCMD = useLCMD();
    const [meta, setMeta] = React.useState<Meta>(null);

    useEffect(() => {
        LCMD.getAuthUser(
            (error, user) => {
                if (!error) {
                    if(user){
                        const tmpMeta: Meta = {prefs: {}, ...user.meta};
                        setMeta(tmpMeta);
                    }
                }else{
                    console.error(error);
                }
            },
            { forceSync: true },
        );
    }, []);


    function isMetaDataLoading() {
        return Boolean(!meta);
    }

    function saveSettings(showNonWorkingDays: boolean, canvasFontSize: number) {
        const newMeta: Meta = {...meta || {firstName: "", lastName: ""}, prefs: { showNWD: showNonWorkingDays, fs: canvasFontSize }};
        LCMD.updateUserMeta(newMeta, (error: FrameworkError, result: { meta: { [name: string]: any } }) => {
            if (error) {
                //@TODO show error
                console.log(error);
            }
            setMeta(newMeta);
        });
    }

    function onFontsizeChange(enabled: boolean){
        WebAppTelemetryFactory.trackEvent("settings-preference-font-static", { enabled });
        saveSettings(meta?.prefs?.showNWD, enabled? -12:12);
    }

    function onNonWorkingDayChange(newValueKey: string){
        const _value = newValueKey == "alwaysShow";
        LCMD.setCalendarView(_value);
        saveSettings(_value, meta?.prefs?.fs);
    }

    return (
        <Stack tokens={{ childrenGap: 20 }}>
            <StackItem>
                {isMetaDataLoading() ? <Shimmer />:
                    <CanvasSettings
                        automaticTextResizingChecked={!(meta?.prefs?.fs) || meta?.prefs?.fs === -12}
                        onChange={onFontsizeChange}
                    />
                }
            </StackItem>
            <StackItem>
                {isMetaDataLoading() ? (
                    <Shimmer />
                ) : (
                    <ScheduleSettings
                        selected={meta?.prefs?.showNWD ? "alwaysShow" : "alwaysHide"}
                        onChange={onNonWorkingDayChange}
                    />
                )}
            </StackItem>
        </Stack>
    );
}
