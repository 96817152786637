import * as React from "react";
import { intl } from "lcmd2framework";

import { DefaultButton, IContextualMenuProps } from "@fluentui/react";
import { ContextualMenu } from "./ContextualMenu";
import { useLCMD } from "../../../app/LCMContext";

// Pass empty items to menuProps without that menuAs will not render
const defaultMenuProps = { items: [] };

export function FilterButton(props: { checked }) {
    const LCMD = useLCMD();
    const menu = React.useCallback(
        (menuProps: IContextualMenuProps) => (
            <ContextualMenu
                menuProps={menuProps}
                onSelect={(filterId) => LCMD.setFilter(filterId)}
                onClear={() => LCMD.setFilter(null)}
                onManage={() => LCMD.showDialog("filter", { manage: true })}
            />
        ),
        [LCMD],
    );

    return (
        <DefaultButton
            checked={props.checked}
            key="filter"
            text={intl.get("canvas.cmd.filter.text")}
            title={intl.get("canvas.cmd.filter.title")}
            split={true}
            onClick={() => LCMD.showDialog("filter", {})}
            styles={{
                rootChecked: {
                    backgroundColor: "rgb(237, 235, 233) !important",
                },
                splitButtonMenuButtonChecked: {
                    backgroundColor: "rgb(225, 223, 221) !important",
                },
            }}
            menuProps={defaultMenuProps}
            menuAs={menu}
        />
    );
}
