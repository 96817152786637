import { useLCMD } from "../../app/LCMContext";
import { useEffect, useState } from "react";

// should not be used! Need to define role/access system first
export function useCanCreateStabilityCriteria(): boolean {
    const LCMD = useLCMD();
    const [isAllowed, setIsAllowed] = useState<boolean>(false);
    useEffect(() => {
        LCMD.getProjectDetails((error, data) => {
            if (error || !data || !data.sub) {
                // do not allow editing
                setIsAllowed(false);
                return;
            }
            if ("readonly" === data.meta?.role) {
                setIsAllowed(false);
            } else {
                setIsAllowed(true);
            }
        });
    }, [LCMD, setIsAllowed]);
    return isAllowed;
}
