import * as React from "react";
import { mergeStyleSets, PrimaryButton } from "@fluentui/react";

export type ModalAssetFooterProps = {
    onCloseButtonChange?: () => void;
    leftContent?: JSX.Element;
    rightContent?: JSX.Element;
};
export function ModalAssetFooter({
    onCloseButtonChange = null,
    leftContent = null,
    rightContent = null,
}: ModalAssetFooterProps) {
    const styles = mergeStyleSets({
        footer: [
            {
                bottom: 0,
                left: 0,
                position: "absolute",
                width: "100%",
                flex: "1 1 auto",
                display: "flex",
                alignItems: "center",
                padding: "12px 24px",
                borderTop: "1px solid #E1E4E5",
                justifyContent: "space-between",
                boxSizing: "border-box",
            },
        ],
        leftSide: [{}],
        rightSide: [{}],
        rightSideContent: [
            {
                display: "inline-block",
                marginLeft: "15px",
            },
        ],
        leftSideContent: [
            {
                marginRight: "15px",
                display: "inline-block",
            },
        ],
    });

    return (
        <div className={styles.footer}>
            <div className={styles.leftSide}>
                {leftContent ? <div className={styles.leftSideContent}>{leftContent}</div> : null}
            </div>
            <div className={styles.rightSide}>
                {rightContent ? <div className={styles.rightSideContent}>{rightContent}</div> : null}
                {onCloseButtonChange ? <PrimaryButton onClick={onCloseButtonChange} /> : null}
            </div>
        </div>
    );
}
