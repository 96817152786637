import * as React from "react";
import { CanvasCommonProps, CanvasViewPort } from "./Canvas";
import { ActivityItem, mergeStyleSets } from "@fluentui/react";
import { assert } from "../../../model/GlobalHelper";
import { WorkerSession } from "../../../model/DataModel";
import { MainWorkerMessage } from "../../MainWorkerPipe";
import { useChangesPanelOpenStore } from "@/app/store/changesPanelOpenStore";

const classNames = mergeStyleSets({
    panel: {
        overscrollBehavior: "none",
    },
    container: {
        top: 60,
        //backgroundColor: "red",
        overscrollBehavior: "none",
    },
    search: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: "white",
    },
    taskText: {
        fontWeight: "bold",
    },
});

const styles = {
    ActivityItem: {
        root: {
            marginTop: 5,
            marginBottom: 15,
        },
    },
};

type CanvasChangesPanelProps = CanvasCommonProps & {
    divRefs: { SH: HTMLDivElement };
    view: CanvasViewPort;
};

type CanvasChangesPanelState = {
    isOpen: boolean;
    changes: any;
    dialog: any;
    selected: number[];
};

export class CanvasChangesPanel extends React.Component<CanvasChangesPanelProps, CanvasChangesPanelState> {
    state = {
        isOpen: false,
        changes: null,
        dialog: null,
        selected: [],
    };
    private _onClose = function (this: CanvasChangesPanel) {
        this.setState({
            isOpen: false,
            changes: null,
        });
    }.bind(this);
    onWorkerMsg = function (this: CanvasChangesPanel, msg: MainWorkerMessage) {
        switch (msg[0]) {
            case "canvas":
                if (this.state.isOpen) {
                    const canvas = msg[1];
                    const _ = canvas?.sync?.commited || 0;
                    if (canvas?.tasksPatch && _ > 0) {
                        const p = canvas?.tasksPatch;
                        if (_ !== this.state.changes._) {
                            this.props.worker.postMessage([
                                "changes",
                                "update",
                                {
                                    selected: this.state.selected,
                                },
                            ]);
                        }
                    }
                }
                break;
            case "task": // no break
            case "taskex":
                {
                    const opt = msg[1];
                    if (!opt.isWhiteboard) {
                        const selected = opt?.selected || [];
                        this.setState({
                            selected,
                        });
                        if (this.state.isOpen) {
                            this.props.worker.postMessage([
                                "changes",
                                "selected",
                                {
                                    selected: this.state.selected,
                                },
                            ]);
                        }
                    }
                }
                break;
            case "toggle":
                {
                    switch (msg[1]) {
                        case "changes":
                            {
                                const setChangesPanelOpenState = useChangesPanelOpenStore.getState().setOpen
                                const isOpen = msg[1] ? true : false;
                                if (msg[2] && msg[2] === "close") {
                                    this._onClose();
                                    setChangesPanelOpenState(false)
                                    return;
                                }
                                if (isOpen !== this.state.isOpen) {
                                    if (!this.state.isOpen) {
                                        assert(isOpen);
                                        setChangesPanelOpenState(true)
                                        this.setState(
                                            {
                                                isOpen: true,
                                                changes: {
                                                    _: 0,
                                                },
                                            },
                                            () => {
                                                this.props.worker.postMessage([
                                                    "changes",
                                                    "open",
                                                    {
                                                        selected: this.state.selected,
                                                    },
                                                ]);
                                            },
                                        );
                                    } else {
                                        assert(!isOpen);
                                        this._onClose();
                                    }
                                }
                            }
                            break;
                    }
                }
                break;
            case "changes":
                {
                    const changes_msg = msg[1];
                    const changes = changes_msg.changes || this.state.changes;
                    this.setState({
                        changes,
                    });
                }
                break;
            case "commit":
                {
                    // REMOVE ME? No longer needed!
                    switch (msg[1]) {
                        case "submit":
                            {
                                this.setState({
                                    dialog: {
                                        title: "Submit",
                                        subText: "Merge request submitted.",
                                    },
                                });
                            }
                            break;
                        case "commit2":
                            {
                                this.setState({
                                    dialog: {
                                        title: "Commited",
                                        subText: "New master commited.",
                                    },
                                });
                            }
                            break;
                        case "conflict":
                            {
                                this.setState({
                                    dialog: {
                                        title: "Conflict",
                                        subText: "Please reload.",
                                    },
                                });
                            }
                            break;
                        case "reopen":
                            {
                                this.setState({
                                    dialog: {
                                        title: "Reopened",
                                        subText: "Please reload.",
                                    },
                                });
                            }
                            break;
                        case "reopening":
                            {
                                this.setState({
                                    dialog: {
                                        title: "Reopening",
                                        subText: "Please wait...",
                                        wait: true,
                                    },
                                });
                            }
                            break;
                        case "submitting":
                            {
                                this.setState({
                                    dialog: {
                                        title: "Submitting",
                                        subText: "Please wait...",
                                        wait: true,
                                    },
                                });
                            }
                            break;
                        case "stream":
                            {
                                // sync message from stream...
                                if (null === this.state.dialog) {
                                    this.setState({
                                        dialog: {
                                            title: "Submitted",
                                            subText: "This sandbox has been submitted.",
                                        },
                                    });
                                } else {
                                    // current notification should be "submit"....
                                }
                            }
                            break;
                        case "delete":
                            {
                                this.setState({
                                    dialog: {
                                        title: "Deleted",
                                        subText: "Sandbox has been deleted.",
                                    },
                                });
                            }
                            break;
                    }
                }
                break;
            default:
                break;
        }
    }.bind(this);
    private TaskEvent = function (
        this: CanvasChangesPanel,
        props: {
            event: any;
        },
    ) {
        switch (props.event.type) {
            case 0:
                return (
                    <div>
                        &nbsp;&nbsp;{props.event.name}:{props.event.c}
                    </div>
                );
            case 1:
                return <div>&nbsp;&nbsp;Comment:{props.event.c}</div>;
            default:
                return <div></div>;
        }
    }.bind(this);
    private Task = function (
        this: CanvasChangesPanel,
        props: {
            task: {
                tid: number;
                name: string;
                events: any[];
            };
        },
    ) {
        return (
            <ActivityItem
                styles={styles.ActivityItem}
                activityDescription={
                    <>
                        <span>Task </span>
                        <span onClick={this.onActivityItem.bind(this, props.task)} className={classNames.taskText}>
                            {props.task.name || ""} (#{props.task.tid})
                        </span>
                        <span>:</span>
                    </>
                }
                comments={props.task.events.map((event) => (
                    <this.TaskEvent key={event._} event={event} />
                ))}
            />
        );
    }.bind(this);
    private onSubmit = function () {
        const sandbox_ts = (this.props?.projectSandbox as WorkerSession)?.sandbox_ts || 0;
        //const canCommit=this.state.changes?.canCommit;
        //console.log(JSON.stringify({canCommit:canCommit}));
        if (0 === sandbox_ts) {
            this.props.worker.postMessage(["commit", "submit", {}]);
        } else {
            this.props.worker.postMessage([
                "commit",
                "commit2",
                {
                    ts: sandbox_ts,
                },
            ]);
        }
    }.bind(this);
    private onDelete = function () {
        this.setState({
            dialog: {
                title: "Delete Sandbox",
                subText: "Delete Sandbox?",
                onOk: () => {
                    this.props.worker.postMessage(["commit", "delete", {}]);
                    this.setState((state) => {
                        return { ...state, dialog: { ...state.dialog, wait: true, onCancel: null } };
                    });
                },
                onCancel: () => {
                    this.setState({
                        dialog: null,
                    });
                },
            },
        });
    }.bind(this);

    componentDidMount(this: CanvasChangesPanel) {
        this.props.worker.registerHandler(this.onWorkerMsg);
    }

    componentWillUnmount(this: CanvasChangesPanel) {
        this.props.worker.unregisterHandler(this.onWorkerMsg);
    }

    private onActivityItem(this: CanvasChangesPanel, task: any) {
        if (task.tid > 0) {
            this.props.worker.gotoTask(task.tid, this.props.view, this.props.divRefs);
        }
    }

    private onDialogOK = function () {
        this.props.worker.dispatchMessage(["framework", "toggle", "folder"]);
    }.bind(this);

    private onRenderNavigationContent = function (props, defaultRender) {
        return <>{defaultRender!(props)}</>;
    }.bind(this);

    render(this: CanvasChangesPanel) {
        const CustomChangesPanel = this.props.worker.config.ChangesPanel;
        if (CustomChangesPanel) {
            return this.state.isOpen ? (
                <CustomChangesPanel onDismiss={this._onClose} changes={this.state.changes} />
            ) : null;
        }
    }
}
