/**
 * @description All core extensions are listed in the Enum below.
 *
 * Naming convention can be found under: https://www.notion.so/lcmd/Extension-Notation-a219597a84bc46b7951e9151986d2cc7
 *
 * @see when enum is changed check unit tests!
 */
export enum CoreExtensions {
    PDF_SETTINGS = "com.lcmd.core.settings.pdf",
    STABILITY_CRITERIA_SETTINGS = "com.lcmd.core.settings.stability_criteria",
    /* Week Start */
    CALENDAR_SETTINGS = "com.lcmd.core.calendar.settings",
    /* Workingdays and Calendar Exceptions  */
    CALENDAR_DEFAULT_SETTINGS = "com.lcmd.core.calendar.default",
}

export function isStabilityCriteriaExtension(extensionId: string) {
    return typeof extensionId === "string" && extensionId.startsWith(CoreExtensions.STABILITY_CRITERIA_SETTINGS);
}
