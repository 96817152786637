import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    DefaultButton,
    Text,
    IIconProps,
    PrimaryButton,
    Stack,
    StackItem,
    ScrollablePane,
    TextField,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { Counter } from "../../common/counter";
import { LcmdModal } from "../../common/LcmModal";
import { TradesList } from "../../Filter/dialogs/FilterDialog";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

const theme = getTheme();
const contentStyles = mergeStyleSets({
    content: {
        padding: "0 32px",
    },
    container: {
        minWidth: "600px",
        minHeight: "800px",
    },
    headline: {
        fontWeight: 600,
        padding: "5px 0px",
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: "0 0 auto",
            // borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px",
        },
    ],
    body: {
        flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
});

const minValue = 0;
type Props = {
    stabilityCriteriaToEdit?: any;
    onCancel: () => void;
    onClose: (stabilityCriteria: any) => void;
    showDialog: boolean;
    allTrades: any;
};
export const SettingsModal = ({ stabilityCriteriaToEdit, allTrades, onCancel, onClose, showDialog }: Props) => {
    const initialSelectedTrades = {};
    stabilityCriteriaToEdit?.trades.forEach((t) => {
        initialSelectedTrades[t.id] = t;
    });
    const [leadTime, setLeadTime] = useState(
        stabilityCriteriaToEdit?.leadTimeValue ? stabilityCriteriaToEdit.leadTimeValue : 0,
    );
    const [currentStabilityCriteria, setCurrentStabilityCriteria] = useState(stabilityCriteriaToEdit || {});
    const [currentStabilityCriteriaName, setCurrentStabilityCriteriaName] = useState(stabilityCriteriaToEdit?.name);
    const [selectedTrades, setSelectedTrades] = useState(initialSelectedTrades || {});
    const [isAllChecked, setAllChecked] = useState(false);
    const stabilityTitle = !stabilityCriteriaToEdit
        ? intl.get("StabilityCriteria.SettingsModal.NewHeadlineText")
        : intl.get("StabilityCriteria.SettingsModal.EditHeadlineText");

    const cancelIcon: IIconProps = { iconName: "Cancel" };

    useEffect(() => {
        if (Object.keys(selectedTrades).length == allTrades.length) {
            setAllChecked(true);
        } else {
            setAllChecked(false);
        }
    }, [selectedTrades]);

    const cleanUpModal = () => {
        setSelectedTrades([]);
        setLeadTime(0);
        setCurrentStabilityCriteriaName("");
        setCurrentStabilityCriteria({});
    };

    const changeLeadTimeValue = (value: number) => {
        if (value < minValue) {
            value = minValue;
        }
        setLeadTime(value);
    };

    const saveStabilityCriteria = () => {
        const stabilityCriteria: any = {
            name: currentStabilityCriteriaName,
            leadTimeValue: leadTime,
            trades: Object.values(selectedTrades),
        };
        if (stabilityCriteriaToEdit?.key) {
            stabilityCriteria.key = stabilityCriteriaToEdit.key;
        }
        WebAppTelemetryFactory.trackEvent("settings-stability-criteria-added");
        setCurrentStabilityCriteria(stabilityCriteria);
        onClose(stabilityCriteria);
        cleanUpModal();
    };

    const onSelectAll = (checked) => {
        const tradesToSelect = {};
        if (checked) {
            allTrades?.forEach((t) => {
                tradesToSelect[t.id] = t;
            });
        }
        setSelectedTrades(tradesToSelect);
    };

    const buttons = [
        <DefaultButton
            onClick={() => {
                onCancel();
                cleanUpModal();
            }}
        >
            {intl.get("StabilityCriteria.SettingsModal.CancelButtonText")}
        </DefaultButton>,
        <PrimaryButton onClick={saveStabilityCriteria}>
            {intl.get("StabilityCriteria.SettingsModal.ApplyButtonText")}
        </PrimaryButton>,
    ];
    return (
        <div>
            <LcmdModal
                header={{ title: stabilityTitle }}
                styles={{ main: { width: "600px", height: "800px", overflow: "hidden" } }}
                buttons={buttons}
                isOpen={showDialog}
                onDismiss={() => {
                    onCancel();
                }}
            >
                <Stack
                    className={contentStyles.content}
                    styles={{ root: { height: "100%", position: "relative" } }}
                    tokens={{ childrenGap: "25" }}
                >
                    <StackItem>
                        <TextField
                            label={intl.get("StabilityCriteria.SettingsModal.NameTextFieldLabel")}
                            value={currentStabilityCriteriaName}
                            onChange={(ev, newValue) => {
                                setCurrentStabilityCriteriaName(newValue);
                            }}
                        />
                    </StackItem>
                    <StackItem>
                        <Text className={contentStyles.headline}>
                            {intl.get("StabilityCriteria.SettingsModal.LeadTimeTextFieldLabel")}
                        </Text>
                        <Stack horizontal>
                            <StackItem>
                                <Counter
                                    min={0}
                                    value={leadTime}
                                    onChange={(newValue: number) => {
                                        changeLeadTimeValue(newValue);
                                    }}
                                />
                            </StackItem>
                            <StackItem styles={{ root: { paddingLeft: "5px" } }} align={"center"}>
                                {intl.get("StabilityCriteria.SettingsModal.WorkingDaysText")}
                            </StackItem>
                        </Stack>
                    </StackItem>
                    <Stack>
                        <StackItem>
                            <Text className={contentStyles.headline}>
                                {intl.get("StabilityCriteria.SettingsModal.AppliesToTextFieldLabel")}
                            </Text>
                        </StackItem>
                    </Stack>
                    <Stack style={{ position: "relative", height: "100%", marginTop: 0 }}>
                        <StackItem className={"scrollContent"}>
                            <ScrollablePane>
                                <TradesList
                                    trades={allTrades}
                                    selected={selectedTrades}
                                    setSelected={setSelectedTrades}
                                    search={null}
                                    multiselect={true}
                                />
                            </ScrollablePane>
                        </StackItem>
                    </Stack>
                </Stack>
            </LcmdModal>
        </div>
    );
};
