import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import { ViewTypes } from "../../../constants";

import { Item } from "./ItemV2";
import { Asterisk, CircleDashed, ListChecks, UserCircle2 } from "lucide-react";
import posthog from "posthog-js";

type ViewTypeOptionProps = {
    selectedType: string;
    onSelect: (type: string) => void;
};

function ViewTypeOption(props: ViewTypeOptionProps) {
    const { selectedType, onSelect } = props;

    const viewTypesOptions = [
        {
            type: ViewTypes.TRADE,
            iconName: UserCircle2,
            label: intl.get("canvas.cmd.view.types.trade.label"),
            iconStyle: {},
        },
        {
            type: ViewTypes.STATUS,
            iconName: CircleDashed,
            label: intl.get("canvas.cmd.view.types.status.label"),
            iconStyle: {},
        },
        {
            type: ViewTypes.STABILITY,
            iconName: ListChecks,
            label:
                // eslint-disable-next-line no-constant-condition
                false && posthog.getFeatureFlag("ab-checklist") === "test"
                    ? intl.get("canvas.cmd.view.types.checklist.label")
                    : intl.get("canvas.cmd.view.types.stability.label"),
            iconStyle: {},
        },
        {
            type: ViewTypes.CONFLICT,
            iconName: Asterisk,
            label: intl.get("canvas.cmd.view.types.conflict.label"),
            iconStyle: {
                fontSize: "1.0em",
                color: "white",
                backgroundColor: "#565C60",
                width: "20px",
                height: "20px",
                borderRadius: "20px",
                alignSelf: "center",
                lineHeight: "20px",
            },
        },
        // {
        //   type: "criticalpath",
        //   iconName: 'AsteriskSolid',
        //   label: intl.get('canvas.cmd.view.types.criticalpath.label'),
        //   iconStyle: {
        //     fontSize: "1.0em",
        //     color:'white',
        //     backgroundColor: '#565C60',
        //     width: '20px',
        //     height: "20px",
        //     borderRadius: '20px',
        //     alignSelf: 'center',
        //     lineHeight: "20px",
        //   }
        // },
    ];

    return (
        <div className={"flex justify-between"}>
            {viewTypesOptions.map((option) => {
                const { type, iconName, label, iconStyle } = option;

                const selected = selectedType === type;

                return (
                    <Item
                        key={type}
                        type={type}
                        label={label}
                        iconName={iconName}
                        onSelect={onSelect}
                        selected={selected}
                        iconStyle={iconStyle}
                        userflowId={`pp-view-${label}`}
                    />
                );
            })}
        </div>
    );
}

export { ViewTypeOption };
