import { DigitalPlanningBoardTradeDetails, DigitalPlanningBoardTradesEvent, intl } from "lcmd2framework";
import { Fragment, ReactElement, useEffect, useState } from "react";
import {
    CheckboxVisibility,
    DefaultButton,
    DetailsList,
    IColumn,
    IDetailsHeaderProps,
    IRenderFunction,
    Label,
    mergeStyleSets,
    PrimaryButton,
    ScrollablePane,
    SearchBox,
    SelectionMode,
    Stack,
    Sticky,
    StickyPositionType,
    Selection,
} from "@fluentui/react";
import { copyAndSort } from "../../../Utils";
import * as React from "react";
import { TradeLabel } from "../../../common/trade/TradeLabel";
import { decimalToRgb } from "../../../utils/color/decimal-to-rgb";
import { LcmdModal } from "../../../common/LcmModal";
import {
    LCMDContextTaskDetailsResult,
    LCMDContextTaskDetailsResultTradeDetails,
} from "../../../../app/LCMDContextTypes";

type TradeListItems = DigitalPlanningBoardTradeDetails & {
    key: string;
};

export const EditTradeModal = ({
    open,
    projectTrades,
    currentTrade,
    processDetails,
    onCancel,
    onClose,
}: {
    open: boolean;
    currentTrade: LCMDContextTaskDetailsResultTradeDetails;
    projectTrades: DigitalPlanningBoardTradesEvent;
    processDetails: LCMDContextTaskDetailsResult;
    onCancel: () => void;
    onClose: (selectedTrade: DigitalPlanningBoardTradeDetails) => void;
}) => {
    const trades: DigitalPlanningBoardTradeDetails[] = [];
    Object.keys(projectTrades).forEach((projectId) => {
        trades.push(...projectTrades[projectId]);
    });

    const [selected, setSelected] = useState(
        currentTrade
            ? {
                  id: currentTrade.trid,
                  trade: currentTrade.trade,
                  name: currentTrade.name,
                  color: currentTrade.color,
                  projectId: "",
                  label: currentTrade.name,
              }
            : null,
    );

    const [listItems, setListItems] = useState<TradeListItems[]>(
        trades.map((trade) => ({
            ...trade,
            key: trade.id.toString(),
        })),
    );

    const errorHTML = (
        <Fragment>
            <div style={{ margin: "8px", color: "red" }}>{intl.get("EditTradeModal.errorText")}</div>
        </Fragment>
    );

    const [currentItems, setCurrentItems] = useState<TradeListItems[]>([]);
    const [filterText, setFilterText] = useState<string>("");
    const [sortColumn, setSortColumn] = useState<IColumn>(null);
    const [error, setError] = useState(!selected);
    const [errorText, setErrorText] = useState(error ? errorHTML : undefined);

    const [selection, setSelection] = useState(
        new Selection({
            onSelectionChanged: () => {
                let selectedTrade = null;
                const selectedTradeKey = selection.getSelection();
                if (selectedTradeKey.length > 0) {
                    selectedTrade = listItems.find((item) => {
                        return item.key == selectedTradeKey[0].key;
                    });
                } else {
                    setError(true);
                }
                if (selectedTradeKey.length > 0 && selectedTrade) {
                    setSelected({
                        id: selectedTrade.id,
                        trade: selectedTrade.trade,
                        name: selectedTrade.name,
                        color: selectedTrade.color,
                        projectId: selectedTrade.projectId,
                        label: selectedTrade.name,
                    });
                    setError(false);
                } else {
                    setError(true);
                }
            },
        }),
    );

    useEffect(() => {
        const selectedItem = listItems.find((item) => {
            return item.key == currentTrade?.trid.toString();
        });
        selection.setItems(listItems);
        selection.setChangeEvents(true, false);
        selection.setKeySelected(selectedItem?.key, true, false);
    }, []);

    useEffect(() => {
        setErrorText(error ? errorHTML : undefined);
    }, [error]);

    useEffect(() => {
        let res = [...listItems];
        if (filterText || sortColumn) {
            if (filterText) {
                res = listItems.filter((i) => {
                    return (i.label || "").toLowerCase().indexOf(filterText.toLowerCase()) > -1;
                });
            }
            if (sortColumn) {
                const newColumns: IColumn[] = columns.slice();
                const currColumn: IColumn = newColumns.filter((currCol) => sortColumn.key === currCol.key)[0];
                newColumns.forEach((newCol: IColumn) => {
                    if (newCol === currColumn) {
                        currColumn.isSortedDescending = !currColumn.isSortedDescending;
                        currColumn.isSorted = true;
                    } else {
                        newCol.isSorted = false;
                        newCol.isSortedDescending = true;
                    }
                });
                res = copyAndSort(res, currColumn.key!, currColumn.isSortedDescending);
                setColumns(newColumns);
                setCurrentItems(res);
            }
        }
        setCurrentItems(res);
    }, [listItems, filterText, sortColumn]);

    const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        setSortColumn(column);
    };

    const classNames = mergeStyleSets({
        noClickCheckbox: { pointerEvents: "none" },
    });

    const modalButtons = [
        <DefaultButton onClick={onCancel}>{intl.get("EditTradeModal.cancelButton.text")}</DefaultButton>,
        <PrimaryButton
            disabled={error}
            onClick={() => {
                if (!error) {
                    onClose(selected);
                }
            }}
        >
            {intl.get("EditTradeModal.applyButton.text")}
        </PrimaryButton>,
    ];

    const [columns, setColumns] = useState<IColumn[]>([
        {
            key: "name",
            name: intl.get("EditTradeModal.columnName"),
            isResizable: true,
            isSorted: true,
            minWidth: 300,
            onRender: (item) => {
                return <TradeLabel name={item.name} color={decimalToRgb(item.color)} />;
            },
            onColumnClick: _onColumnClick,
        },
    ]);

    const _onFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        setFilterText(text);
    };

    //TODO: Put code in function for better unit testing
    const processIds =
        typeof processDetails.pid?.value === "number"
            ? `#${processDetails.pid?.value}`
            : processDetails.pid?.value
                  .slice(0, Math.min(processDetails.pid?.value.length, 15))
                  .map((value) => `#${value}`)
                  .join(", ");
    const processIdsTitle =
        typeof processDetails.pid?.value === "number"
            ? `#${processDetails.pid?.value}`
            : processDetails.pid?.value.map((value) => `#${value}`).join(", ");

    const modalHeader: ReactElement = (
        <Fragment>
            <Label
                title={processIdsTitle}
                styles={{
                    root: {
                        maxWidth: "475px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        lineHeight: "20px",
                        fontWeight: 400,
                        fontSize: "14px",
                    },
                }}
            >
                {processDetails.name && !processDetails.name.indeterminate
                    ? processIds + " " + processDetails.name.value
                    : processIds}
            </Label>
            <Label
                styles={{
                    root: {
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "20px",
                        lineHeight: "28px",
                    },
                }}
            >
                {intl.get("EditTradeModal.title")}
            </Label>
        </Fragment>
    );

    const renderFixedDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                <SearchBox
                    styles={{ root: { margin: "8px" } }}
                    placeholder={intl.get("EditTradeModal.searchBox.placeholder")}
                    onChange={_onFilter}
                />
                {defaultRender({ ...props, styles: { root: { paddingTop: "0" } } })}
            </Sticky>
        );
    };
    //TODO: Put headerTitle in function for better unit testing
    return (
        <LcmdModal
            onDismiss={onCancel}
            buttons={modalButtons}
            isOpen={open}
            header={{
                title:
                    processDetails.name && !processDetails.name.indeterminate
                        ? processIds + " " + processDetails.name.value
                        : processIds,
            }}
            styles={{ main: { width: "600px", height: "800px", overflow: "hidden" } }}
        >
            <Stack horizontal>
                <ScrollablePane>
                    {errorText}
                    <DetailsList
                        items={currentItems}
                        columns={columns}
                        selection={selection}
                        onRenderDetailsHeader={renderFixedDetailsHeader}
                        checkboxVisibility={CheckboxVisibility.always}
                        selectionMode={SelectionMode.single}
                        setKey="set"
                        selectionPreservedOnEmptyClick={true}
                        checkboxCellClassName={classNames.noClickCheckbox}
                    />
                </ScrollablePane>
            </Stack>
        </LcmdModal>
    );
};
