﻿/**
 * Stores the recently opened projects in the local storage.
 */
export class LastOpenedProjectsService {
    private static readonly localStorageKey = "projects::lastOpened";

    public static projectOpened(projectId: string): void {
        const lastOpenedProjects = LastOpenedProjectsService.loadLastOpenedProjects();
        lastOpenedProjects[projectId] = new Date();
        localStorage.setItem(LastOpenedProjectsService.localStorageKey, JSON.stringify(lastOpenedProjects));
    }

    public static getLastOpenedProjectIds(): string[] {
        const lastOpenedProjects = LastOpenedProjectsService.loadLastOpenedProjects();
        const lastOpenedProjectIds = Object.entries(lastOpenedProjects)
            .sort((a, b) => b[1].getTime() - a[1].getTime())
            .map((x) => x[0]);
        return lastOpenedProjectIds;
    }

    private static loadLastOpenedProjects(): ILastOpenedProjects {
        const lastEditedProjectsString = localStorage.getItem(LastOpenedProjectsService.localStorageKey);
        if (!lastEditedProjectsString) {
            return {};
        }

        // Reviver is also called on the root object with an empty string as key => only parse Date if key is defined
        return JSON.parse(lastEditedProjectsString, (key, value) => (key ? new Date(value) : value));
    }
}

interface ILastOpenedProjects {
    [key: string]: Date;
}
