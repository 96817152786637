import { DefaultButton, IButtonProps } from "@fluentui/react";
import { intl } from "../../../legacy/GlobalHelperReact";

type LoadMoreProps = IButtonProps & {
    loading: boolean;
};

//@todo maybe to consider, to handle loading state and loading data inside the loading component
export function LoadMoreButton(props: LoadMoreProps) {
    return (
        <DefaultButton
            disabled={props.loading}
            text={intl.get("LoadMoreButton.buttonText")}
            {...props}
            style={{ width: 200 }}
        />
    );
}
