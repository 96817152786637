import * as React from "react";
import { intl } from "@/legacy/GlobalHelperReact";

import { TimeframePeriodTypes, calcMonthsCount } from "../../../../../utils/timeframe";

import { Text } from "@fluentui/react";

export type TimeframeTypeProps = {
    type: string;
    dates: number[] | null;
};

const composeTitleByType = {
    [TimeframePeriodTypes.EVERY]: () => {
        return intl.get("filter.date.0.text");
    },
    [TimeframePeriodTypes.WEEKS]: (dates) => {
        const [, weeks] = dates;

        return intl.get(`filter.date.${weeks}.text`);
    },
    [TimeframePeriodTypes.MONTHS]: (dates) => {
        const months = calcMonthsCount(dates);

        return intl.get(`filter.date.PM${months}.text`);
    },
    [TimeframePeriodTypes.CUSTOM]: (dates) => {
        return intl.get("filter.date.-1.text") || "CUSTOM";
    },
};

export function TimeframeType(props: TimeframeTypeProps) {
    const { dates, type } = props;

    const composeTitle = composeTitleByType[type];

    return <Text>{composeTitle(dates)}</Text>;
}
