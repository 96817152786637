import { createContext, useEffect, useMemo, useState } from "react";
import { getNoProcessSelectedMenus } from "../../components/Sidebar/Sidebar.config";
import { intl } from "../../legacy/GlobalHelperReact";
import {
    DigitalPlanningBoardSelectionEvent,
    DigitalPlanningBoardSidebarContext,
    DigitalPlanningBoardTradesEvent,
    DigitalPlanningBoardViewDetails,
} from "lcmd2framework";
import { Sidebar } from "../../components/Sidebar";
import { TooltipHost } from "@fluentui/react";
import { CounterBubble, StyleTheme } from "@/components/common/counter/CounterBubble";
import { useCanvasNavigation } from "@/components/hooks/useCanvasNavigation.hook";
import { cn } from "@/lib/utils";
import { useLCMD } from "../LCMContext";
import { usePresentingMode } from "@/app/store/canvasStore";

export interface ISidebarContext {
    ppTS: number;
    wbID: string;
    wbTS: number;
    sidebarCtx: DigitalPlanningBoardSidebarContext;
    view: DigitalPlanningBoardViewDetails;
    top: number;
    hidden: boolean;
    marquee: boolean;
    selectedTrade: DigitalPlanningBoardTradesEvent;
}

export const SidebarContext = createContext<ISidebarContext>(null);

export interface ISidebarHostProps extends ISidebarContext {}

export function SidebarHost(props: ISidebarHostProps) {
    const presentingMode = usePresentingMode();
    const LCMD = useLCMD();
    const [menu, setMenu] = useState(null);
    const [selectedProcesses, setSelectedProcesses] = useState<number[]>([]);
    const canvasNavigation = useCanvasNavigation();
    const noSelectionMenu = useMemo(() => {
        return getNoProcessSelectedMenus(intl, canvasNavigation, props.view.key);
    }, [intl, canvasNavigation, props.view.key]);

    props.sidebarCtx.useSelectionEffect(
        (ev: DigitalPlanningBoardSelectionEvent) => {
            setSelectedProcesses(ev.pid);
        },
        [setSelectedProcesses],
    );

    useEffect(() => {
        //Kaulquappe feature: post selected process
        if (selectedProcesses.length >= 0 && window.top != window.self) {
            const messageObj = {
                action: "processesSelected",
                selectedProcesses: selectedProcesses,
            };

            parent.postMessage(messageObj, "*");
        }
    }, [selectedProcesses]);

    useEffect(() => {
        switch (props.view.key) {
            case "workshop":
            case "project":
                if (selectedProcesses.length === 0) {
                    setMenu(noSelectionMenu);
                } else {
                    setMenu(null);
                }
                break;
            default:
                setMenu(null);
        }
    }, [props.view.key, selectedProcesses]);

    useEffect(() => {
        setSelectedProcesses([]);
        LCMD.executeCommand({
            cmd: "marquee",
            value: false,
        });
    }, [props.view.key]);

    return (
        <div
            style={{
                display: props.hidden || presentingMode ? "none" : undefined,
                top: props.top,
            }}
            data-hidden={props.hidden || presentingMode}
            className={"fixed bottom-0 right-0 z-[10000] w-[60px] transition-all "}
        >
            <div
                className={cn(
                    "fixed right-[10px] z-20",
                    props.view.key === "workshop" ? "top-[116px]" : "top-[216px]",
                    !props.marquee && !selectedProcesses.length && "hidden",
                )}
            >
                <TooltipHost content={intl.get("Sidebar.SelectedProcesses", { number: selectedProcesses.length })}>
                    <CounterBubble value={selectedProcesses.length} maxValue={999} styleTheme={StyleTheme.GREY} />
                </TooltipHost>
            </div>

            <SidebarContext.Provider value={props}>
                {Boolean(
                    (props.view.key == "workshop" && props?.view?.libraries?.active) || props.view.key !== "workshop",
                ) && <Sidebar view={props.view.key} menus={menu} />}
            </SidebarContext.Provider>
        </div>
    );
}
