import * as React from "react";

import { Widget } from "../../layout/widget";

import { Header } from "./header";
import { Content } from "./content";
import { DashboardCtxPropI } from "../..";

type ScheduleStabilityProps = DashboardCtxPropI & {
    minWidth?: number;
    basicWidth?: number;
};

function ScheduleStability(props: ScheduleStabilityProps) {
    return (
        <Widget basicWidth={props.basicWidth} minWidth={props.minWidth} userflowId="chart-planungsstabilitaet">
            <Header />
            <Content {...props} />
        </Widget>
    );
}

export { ScheduleStability };
