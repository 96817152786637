﻿import * as React from "react";
import { MainWorkerPipe } from "../../MainWorkerPipe";

type DialogLoaderProps = {
    worker: MainWorkerPipe;
    dialog: null | {
        id: string;
        params: any;
    };
};

export function DialogLoader({ dialog, worker }: DialogLoaderProps) {
    const [_dialog, _setDialog] = React.useState(null as () => JSX.Element);
    React.useEffect(() => {
        if (dialog?.id) {
            const dialog_id = dialog.id;
            const dialogs = worker.config?.dialogs || {};
            const dialog_factory = dialogs[dialog_id];
            if (dialog_factory) {
                dialog_factory(dialog?.params)
                    .then((dialog) => {
                        _setDialog(dialog);
                    })
                    .catch((e) => {
                        console.warn(e);
                        _setDialog(null);
                    });
            } else {
                console.warn("No factory for " + dialog_id);
            }
        } else {
            _setDialog(null);
        }
    }, [dialog, _setDialog]);
    return _dialog || null;
}
