import { Dialog, DialogFooter, DialogType, PrimaryButton, IDialogProps } from "@fluentui/react";
import * as React from "react";
import { ReactElement } from "react";
import { intl } from "lcmd2framework";

type AlertDialog = IDialogProps & {
    title?: string;
    subText?: string;
    hidden: boolean;
    onClose: () => void;
};

export function AlertDialog({ title, subText = "", hidden, onClose }: AlertDialog): ReactElement {
    if (typeof title !== "string") {
        throw new Error("title param needs to be type of string");
    }

    if (typeof subText !== "string") {
        throw new Error("subText param needs to be type of string");
    }

    if (typeof hidden !== "boolean") {
        throw new Error("hidden param needs to be type of string");
    }

    if (typeof onClose !== "function") {
        throw new Error("onClose param needs to be a type of function");
    }

    return (
        <Dialog
            hidden={hidden}
            dialogContentProps={{
                type: DialogType.normal,
                title: title,
                subText: subText ? subText : null,
            }}
        >
            <DialogFooter>
                <PrimaryButton
                    onClick={() => {
                        onClose();
                    }}
                    text={intl.get("fw.ok")}
                />
            </DialogFooter>
        </Dialog>
    );
}
