import { mergeStyleSets, Stack, StackItem } from "@fluentui/react";
import * as React from "react";

export enum StyleTheme {
    BLUE = "blue",
    GREY = "grey",
    NONE = "none",
}

export function CounterBubble({
    value,
    maxValue = 0,
    styleTheme = StyleTheme.BLUE,
}: {
    value: number;
    maxValue?: number;
    styleTheme?: StyleTheme;
}) {
    const counter: string = maxValue != 0 && value > maxValue ? maxValue + "+" : value.toString();
    const padding: number = counter.length == 1 ? 6 : 4;
    let style;
    switch (styleTheme) {
        case StyleTheme.BLUE: {
            style = {
                backgroundColor: "#009BD4",
                color: "white",
            };
            break;
        }
        case StyleTheme.GREY: {
            style = {
                backgroundColor: "#F8FAFA",
                color: "#565C60",
                border: "1px #D0D4D6 solid",
            };
            break;
        }
        case StyleTheme.NONE:
        default: {
            style = {
                color: "#565C60",
            };
        }
    }

    const styles = mergeStyleSets({
        counter: {
            ...style,
            height: "20px",
            minWidth: "20px",
            fontSize: "12px",
            padding: `0 ${padding}px`,
            borderRadius: "11px",
            display: "flex",
            flexFlow: "row",
            justifyContent: "center"
        },
    });
    return (
        <Stack className={styles.counter} horizontalAlign={"center"}>
            <StackItem style={{ fontSize: 12 }}>{counter}</StackItem>
        </Stack>
    );
}
