import { EpochDaystoEpochMS, UNIT_TYPES, REL_TYPES, TableExportHelper } from "particlecore";
import { weekNumber } from "weeknumber";
import { Core, END_OF_DAY_MS, HelperEpochMSToLocalDate } from "./lcmd2core";
import { ProcessGetter } from "../model/api/processGetter";
import { TradesGetter } from "../model/api/tradesGetter";
import { ParticleGetter } from "../model/api/particleGetter";
import { ItemState } from "@/components/ToDo/interface";
import { isOverdue } from "@/components/Utils";
import { isStabilityCriteriaExtension } from "@/extensions/core.extensions";

export class XlsxExport {
    private readonly core: Core;
    private readonly pGtHelper: ProcessGetter;
    private readonly _GtHelper: ParticleGetter;
    private readonly trGtHelper: TradesGetter;

    constructor(core: Core) {
        this.core = core;
        this.pGtHelper = new ProcessGetter(core);
        this._GtHelper = new ParticleGetter(core);
        this.trGtHelper = new TradesGetter(core);
    }

    private _exportAsXLSX(
        ctx: {
            rows: { l: number[]; tid: number }[];
        },
        ta: number[],
        l: number[],
    ) {
        for (let i_ta = 0; i_ta < ta.length; i_ta++) {
            const tid = ta[i_ta];

            let t_c;
            if (Array.isArray((t_c = this.pGtHelper.reset(tid).aux("_c")))) {
                if (t_c.length > 0) {
                    this._exportAsXLSX(ctx, t_c, [...l, tid]);
                } else {
                    // empty takt zone
                }
            } else {
                ctx.rows.push({ l, tid });
            }
        }
    }

    private _exportFieldsAsXLSX(_line, lcmx_header, fields, values, td = null) {
        const ensureHeader = function (lcmx) {
            for (let i_lcmx = 0; i_lcmx < lcmx.length; i_lcmx++) {
                const field = lcmx[i_lcmx];
                if (lcmx_header.findIndex((h) => h.key === field.key) < 0) {
                    lcmx_header.push(field);
                    if (field.choice) {
                        ensureHeader(field.choice);
                    }
                }
            }
        };
        ensureHeader(fields || []);

        for (let i_lcmx = 0; i_lcmx < lcmx_header.length; i_lcmx++) {
            const field = lcmx_header[i_lcmx];
            const field_v = (values || {})[field.key];
            let value;
            if (null !== field_v && "object" === typeof field_v) {
                if (field_v.suffix) {
                    value = [field_v.value || field.default || "", field_v.suffix || ""].join(" ");
                } else {
                    value = field_v.value || field.default || null;
                }
            } else {
                value = field_v || field.default || null;
            }
            if (Array.isArray(field?.dropdown) && !field?.export?.key) {
                const _value = field.dropdown.reduce(
                    (ret, e) => {
                        if (e === value) {
                            return e;
                        } else if (e?.key === value) {
                            return e?.text;
                        } else {
                            return ret;
                        }
                    },
                    value ? ["INVALID VALUE:", value].join(":") : value,
                );

                if (isStabilityCriteriaExtension(field?.key) && td?._trades[0]) {
                    value = Boolean(_value) ? _value : ItemState.OPEN;
                    const safeIds= this.core.getSafeIDs({ trids: [td._trades[0].id] });
                    if (safeIds?.trids[0] && field["stability.trades"].includes( safeIds.trids[0] )) {
                        const today = new Date();
                        const deadline = new Date();
                        deadline.setDate(deadline.getDate() - field["stability.lead"]);
                        value = isOverdue(Boolean(ItemState.DONE === value), deadline.getTime(), today.getTime()) ? "overdue" : value ;
                    }
                }

            }
            _line.push(value);
        }
    }

    exportAsXLSX(opt?: { cards?: boolean; refs?: boolean; translations?: Map<string, string> }) {
        const columnHeaderTranslations = opt?.translations;

        const formatRel = (rel) => {
            let lag = rel.lag.toString();
            if (!lag.startsWith("-") && !lag.startsWith("+")) {
                lag = "+" + lag;
            }
            const unit = rel.unit ? UNIT_TYPES[rel.unit] : "";
            const type = REL_TYPES[rel.type] || "";
            return [rel.targetId, type, lag, unit].join("");
        };
        const cards = opt?.cards || false;
        const ctx = {
            rows: [] as { l: number[]; tid: number }[],
        };
        this._exportAsXLSX(ctx, this.pGtHelper.reset(0).aux("_c") || [], []);
        const writer = new TableExportHelper();
        //const lines=[] as string[];
        const maxLevel = ctx.rows.reduce((ret, row) => Math.max(ret, row.l.length), 0);
        //const header=[];
        const lcmx_task_header = [];
        const lcmx_process_header = [];

        //lines.push(null); // reserve space, add later
        if (cards) {
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("id") : "ID");
            for (let i = 1; i <= maxLevel; i++) {
                writer.pushHeader(
                    `${columnHeaderTranslations ? columnHeaderTranslations.get("taktZoneLevel") : "TaktZone Level"} ${i.toString(10)}`,
                );
            }
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("date") : "Date");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("processName") : "Process");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("processId") : "ProcessId");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("task") : "Task");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("status") : "Status");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("description") : "Description");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("tradeName") : "Trade Name");
            writer.pushHeader(
                columnHeaderTranslations ? columnHeaderTranslations.get("tradeDescription") : "Trade Description",
            );
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("workforce") : "Workforce");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("statusChanged") : "Status last updated");
        } else {
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("id") : "ID");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("processName") : "Process");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("startDate") : "Start Date");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("endDate") : "End Date");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("status") : "Status");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("duration") : "Duration");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("trade") : "Trade");
            writer.pushHeader(
                columnHeaderTranslations
                    ? columnHeaderTranslations.get("tradeBackgroundColor")
                    : "Trade Background Color",
            );
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("predecessors") : "Predecessors");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("successor") : "Successor");
        }

        for (let i_row = 0; i_row < ctx.rows.length; i_row++) {
            const line = [];
            const row = ctx.rows[i_row];
            if (row.tid > 0) {
                const td = this.core.getProcessDetailsVer1(row.tid, {withoutLayout: true});
                if (cards) {
                    for (let i = 0; i < row.l.length; i++) {
                        const n = this.pGtHelper.reset(row.l[i]).value<string>("name", "");
                        line.push(n + (opt?.refs ? " (#" + row.l[i] + ")" : ""));
                    }
                    for (let i = row.l.length; i < maxLevel; i++) {
                        line.push(null);
                    }
                    const cards = td._cards || [];
                    for (let i_card = 0; i_card < cards.length; i_card++) {
                        const card = cards[i_card];
                        const _line = [];
                        _line.push([card.tid, "-", card.aid, "#", card.i].join("")); // TaslId
                        _line.push(...line.slice());
                        _line.push(new Date(EpochDaystoEpochMS(card.date)));
                        _line.push(td.name);
                        _line.push(td.id); // ProcessId
                        _line.push(card.name);
                        switch (card.s) {
                            case 0 /*DailyBoardAPICardStatus.OPEN*/:
                                _line.push("OPEN");
                                break;
                            case 1 /*DailyBoardAPICardStatus.IN_PROGRESS*/:
                                _line.push("IN_PROGRESS");
                                break;
                            case 2 /*DailyBoardAPICardStatus.DONE*/:
                                _line.push("DONE");
                                break;
                            case 3 /*DailyBoardAPICardStatus.IN_APPROVAL*/:
                                _line.push("IN_APPROVAL");
                                break;
                            default:
                                _line.push("?");
                                break;
                        }

                        _line.push(card.desc || null);
                        if (td._trades && td._trades.length > 0) {
                            const _trade = td._trades[0];
                            _line.push(_trade.name || null);
                            _line.push(_trade.trade || null);
                        } else {
                            _line.push(null);
                            _line.push(null);
                        }
                        const wf = "number" === typeof card.wf ? card.wf : td.wf;
                        _line.push(wf > 0 ? wf : null);
                        _line.push(card.statusChangedTimestamp ? new Date(card.statusChangedTimestamp) : null);
                        this._exportFieldsAsXLSX(_line, lcmx_process_header, td.fields?.process, td.lcmx);
                        this._exportFieldsAsXLSX(_line, lcmx_task_header, td.fields?.task, card.lcmx);
                        writer.pushRow(_line);
                    }
                } else {
                    const processGetter = new ProcessGetter(this.core, row.tid);
                    // processes only
                    const isSubDayUnit = 0 !== (td.isFixed & (1 << 2));
                    line.push(row.tid);
                    line.push(td.name);
                    line.push(new Date(td.start));

                    const processComments = processGetter.getComments();
                    const wrappedComments = [...processComments.values()].map((comment, index) => {
                        const separator = `${index + 1}.`;
                        return separator + comment;
                    });

                    const endDate = td.start < td.end ? td.end - END_OF_DAY_MS : td.end;
                    const formattedEndDate = td.isMilestone ? td.end : HelperEpochMSToLocalDate(endDate);

                    line.push(new Date(formattedEndDate));

                    if (td.isMilestone) {
                        line.push(td.milestoneState === 2 ? 1 : 0);
                    } else if (td.progress && td.progress.cards > 0) {
                        line.push(td.progress.done / td.progress.cards);
                    } else {
                        line.push(0);
                    }

                    line.push(isSubDayUnit ? 0 : td.wd);

                    if (td._trades && td._trades.length > 0) {
                        const _trade = td._trades[0];
                        line.push(_trade.name || _trade.trade || null);
                        const hex = (_trade.color || 0).toString(16).padStart(6, "0");
                        const r = Number.parseInt(hex.substr(0, 2), 16);
                        const g = Number.parseInt(hex.substr(2, 2), 16);
                        const b = Number.parseInt(hex.substr(4, 2), 16);
                        line.push(["RGB(", [r, g, b].join(","), ")"].join(""));
                    } else {
                        line.push(null);
                        line.push(null);
                    }

                    const predecessors = td._predecessors.map((rel) => formatRel(rel));
                    const successors = td._successors.map((rel) => formatRel(rel));
                    line.push(predecessors.join(","));
                    line.push(successors.join(","));

                    this._exportFieldsAsXLSX(line, lcmx_process_header, td.fields?.process, td.lcmx, td);

                    for (let i = 0; i < row.l.length; i++) {
                        const n = this.pGtHelper.reset(row.l[i]).value<string>("name", "");
                        line.push(n + (opt?.refs ? " (#" + row.l[i] + ")" : ""));
                    }
                    for (let i = row.l.length; i < maxLevel; i++) {
                        line.push(null);
                    }

                    // Takt Zones
                    const taktZones = row.l
                        .map((item) => this.pGtHelper.reset(item).value<string>("name", ""))
                        .join("_");
                    line.push(taktZones);

                    // Takt Zones ID
                    let taktZonesId = [];
                    for (let i = 0; i <= row.l.length - 1; i++) {
                        taktZonesId.push(row.l[i]);
                    }
                    line.push(taktZonesId.join(","));

                    // CW Start
                    line.push(weekNumber(new Date(td.start)));

                    // CW End
                    line.push(weekNumber(new Date(td.end)));

                    // Comments
                    line.push(wrappedComments.join("\r\n"));

                    writer.pushRow(line);
                }
            }
        }

        for (let i_lcmx = 0; i_lcmx < lcmx_process_header.length; i_lcmx++) {
            const field = lcmx_process_header[i_lcmx];
            writer.pushHeader(field.label);
        }

        for (let i_lcmx = 0; i_lcmx < lcmx_task_header.length; i_lcmx++) {
            const field = lcmx_task_header[i_lcmx];
            writer.pushHeader(field.label);
        }
        if (!cards) {
            for (let i = 1; i <= maxLevel; i++) {
                writer.pushHeader(
                    `${columnHeaderTranslations ? columnHeaderTranslations.get("taktZoneLevel") : "TaktZone Level"} ${i.toString(10)}`,
                );
            }

            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("taktZones") : "TaktZones");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("taktZonesId") : "TaktZones ID");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("cwStart") : "CW Start");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("cwEnd") : "CW End");
            writer.pushHeader(columnHeaderTranslations ? columnHeaderTranslations.get("comments") : "Comments");
        }
        //lines[0]=header.join(sep);

        //return new Uint8Array(ctx.buffer, 0, ctx.ofs);
        return writer;
    }
}
