﻿export class RestApiClient {
    public static async post<T>(url: string, data: unknown, token?: string): Promise<{ status: number; data?: T }> {
        return await RestApiClient.sendRequest(url, "POST", data, token);
    }

    private static async sendRequest<T>(
        url: string,
        method: "GET" | "POST",
        data: unknown,
        token?: string,
    ): Promise<{ status: number; data?: T }> {
        const response = await fetch(url, {
            method,
            headers: {
                "content-type": "application/json",
                authorization: token ? `token ${token}` : undefined,
            },
            body: typeof data === "string" ? data : JSON.stringify(data),
        });

        try {
            return {
                status: response.status,
                data: (await response.json()) as T,
            };
        } catch {
            return {
                status: response.status,
            };
        }
    }
}
