import React, { useEffect, useState } from "react";
import { CheckCircle, Eye, EyeOff, X } from "lucide-react";
import passwordValidator from "password-validator";
import { intl } from "lcmd2framework";
import { Label } from "@/components/ui/label";
import { Message } from "@/components/ui/message";
import { Input } from "@/components/ui/input";

type PasswordFieldProps = {
    value: string;
    message?: string;
    onChange?: (newValue: string) => void;
    withValidator: boolean;
    name?: string;
    onValidChanged?: (valid: boolean) => void;
    autoComplete?: string;
};

export function PasswordField({
    value,
    message,
    onChange,
    withValidator,
    name = "password",
    onValidChanged,
    autoComplete,
}: PasswordFieldProps) {
    const [pwValue, setPwValue] = useState(value);
    const [showPassword, setShowPassword] = useState(false);
    const [showValidator, setShowValidator] = useState(false);

    function handleChange(evt) {
        if (onChange) {
            onChange(evt.target.value);
        }
        setPwValue(evt.target.value);
    }
    function togglePasswordVisibility() {
        setShowPassword(!showPassword);
    }

    return (
        <div className={"space-y-1"}>
            <Label htmlFor={name}>{intl.get("LcmdLogin.Password.Label")}</Label>
            <div className={"relative"}>
                <Input
                    name={name}
                    type={showPassword ? "text" : "password"}
                    value={pwValue}
                    onChange={handleChange}
                    onFocus={() => {
                        setShowValidator(true);
                    }}
                    onBlur={() => {
                        setShowValidator(false);
                    }}
                    autoComplete={autoComplete}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 cursor-pointer">
                    {showPassword ? (
                        <EyeOff className="h-6 w-6" onClick={togglePasswordVisibility} />
                    ) : (
                        <Eye className="h-6 w-6" onClick={togglePasswordVisibility} />
                    )}
                </div>
                {withValidator && showValidator && (
                    <div className={"absolute bottom-0 -right-96 z-40"}>
                        <PasswordValidator
                            value={pwValue}
                            onValidChanged={(valid) => {
                                onValidChanged && onValidChanged(valid);
                            }}
                        ></PasswordValidator>
                    </div>
                )}
            </div>
            {message && <Message>{message}</Message>}
        </div>
    );
}

type PasswordValidator = {
    value: string;
    onValidChanged: (valid: boolean) => void;
};

function PasswordValidator({ value, onValidChanged }: PasswordValidator) {
    const successIcon = <CheckCircle className={"text-green-600"} />;
    const failIcon = <X className={"text-red-600"} />;
    const minLength = 8;
    const validator = new passwordValidator();

    const [isValid, setIsValid] = useState(false);

    validator.is().min(minLength).has().uppercase().has().lowercase().has().digits(1).has().symbols().has().lowercase();
    const errorList = validator.validate(value, { list: true });
    function checkIfPasswordContains(term: string): JSX.Element {
        return (errorList as string[]).includes(term) ? failIcon : successIcon;
    }

    useEffect(() => {
        if ((errorList as string[]).length <= 0) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [errorList]);

    useEffect(() => {
        onValidChanged(isValid);
    }, [onValidChanged, isValid]);

    return (
        <div className="w-[360px] p-4 bg-neutral-800 rounded-lg shadow flex-col items-start gap-4 inline-flex">
            <div className="text-white text-sm font-medium">{intl.get("LcmdLogin.PasswordValidator.Requirements")}</div>
            <div className={"flex gap-2"}>
                <div className={"flex flex-col gap-2"}>
                    <div className="flex gap-2">
                        <div className=""> {checkIfPasswordContains("min")} </div>
                        <div className="text-white text-sm">
                            {intl.get("LcmdLogin.PasswordValidator.Characters", { number: minLength })}
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <div className=""> {checkIfPasswordContains("lowercase")} </div>
                        <div className="text-white text-sm font-medium">
                            {intl.get("LcmdLogin.PasswordValidator.Lowercase")}
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <div className=""> {checkIfPasswordContains("symbols")} </div>
                        <div className="text-white text-sm font-medium">
                            {intl.get("LcmdLogin.PasswordValidator.Symbols")}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex gap-2">
                        <div className=""> {checkIfPasswordContains("uppercase")} </div>
                        <div className="text-white text-sm font-medium">
                            {intl.get("LcmdLogin.PasswordValidator.Uppercase")}
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <div className=""> {checkIfPasswordContains("digits")} </div>
                        <div className="text-white text-sm font-medium">
                            {intl.get("LcmdLogin.PasswordValidator.Numbers")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
