import { createHmac } from "crypto";
import { Buffer } from "buffer";

export class UUID5 {
    raw: Buffer = null;

    public constructor(_raw: Buffer) {
        this.raw = _raw;
    }

    private static ch2q(ctx) {
        if (ctx.ofs < 0) {
            return 0;
        } else if ("A" <= ctx.str[ctx.ofs] && ctx.str[ctx.ofs] <= "Z") {
            return ctx.str[ctx.ofs++].codePointAt(0) - "A".codePointAt(0) + 10;
        } else if ("0" <= ctx.str[ctx.ofs] && ctx.str[ctx.ofs] <= "9") {
            return ctx.str[ctx.ofs++].codePointAt(0) - "0".codePointAt(0);
        } else {
            ctx.ofs = -1;
            return 0;
        }
    }

    private static qtoch(q) {
        const ret =
            q < 10 ? String.fromCodePoint("0".codePointAt(0) + q) : String.fromCodePoint("A".codePointAt(0) + (q - 10));
        return ret;
    }

    private static toHex(n: number) {
        const ret = n.toString(16);
        return ret.length < 2 ? "0" + ret : ret;
    }

    static fromUUID(str: string) {
        let uuid5: UUID5 = null;
        try {
            const rawStr = str.toLowerCase().replace(/[^a-z0-9]/g, "");
            if (32 === rawStr.length) {
                const raw = Buffer.from(rawStr, "hex");
                if (raw && 16 == raw.byteLength) {
                    const ret = Buffer.alloc(16);
                    ret[0x0] = raw[15];
                    ret[0x1] = raw[14];
                    ret[0x2] = raw[13];
                    ret[0x3] = raw[12];
                    ret[0x4] = raw[11];
                    ret[0x5] = raw[10];
                    ret[0x6] = raw[9];
                    ret[0x7] = raw[8];
                    ret[0x8] = raw[7];
                    ret[0x9] = raw[6];
                    ret[0xa] = raw[5];
                    ret[0xb] = raw[4];
                    ret[0xc] = raw[3];
                    ret[0xd] = raw[2];
                    ret[0xe] = raw[1];
                    ret[0xf] = raw[0];
                    uuid5 = new UUID5(ret);
                }
            }
        } catch (e) {
            // return null
        }
        return uuid5;
    }

    static fromUUID5(str) {
        let uuid5: UUID5 = null;
        try {
            if (26 === str.length && "0" <= str[0] && str[0] <= "7") {
                const b = new Uint8Array(16);
                const q = new Uint8Array(8);
                const ctx = { str: str, ofs: 0 };
                b[0] = (UUID5.ch2q(ctx) << 5) | (UUID5.ch2q(ctx) << 0);
                for (let i = 1; i < 16; i += 5) {
                    for (let j = 0; j < 8; j++) {
                        q[j] = UUID5.ch2q(ctx);
                    }
                    b[i + 0] = ((q[0] & 0x1f) << 3) | ((q[1] & 0x1c) >> 2);
                    b[i + 1] = ((q[1] & 0x03) << 6) | ((q[2] & 0x1f) << 1) | ((q[3] & 0x10) >> 4);
                    b[i + 2] = ((q[3] & 0x0f) << 4) | ((q[4] & 0x1e) >> 1);
                    b[i + 3] = ((q[4] & 0x01) << 7) | ((q[5] & 0x1f) << 2) | ((q[6] & 0x18) >> 3);
                    b[i + 4] = ((q[6] & 0x07) << 5) | ((q[7] & 0x1f) >> 0);
                }
                uuid5 = new UUID5(Buffer.from(b));
            }
        } catch (e) {
            // ignore error
        }
        return uuid5;
    }

    toUUID5() {
        const b16 = this.raw;
        let ret = "";
        ret += UUID5.qtoch((b16[0] & 0xe0) >> 5);
        ret += UUID5.qtoch((b16[0] & 0x1f) >> 0);
        const q = new Uint8Array(8);
        for (let i = 1; i < 16; i += 5) {
            q[0] = (b16[i + 0] & 0xf8) >> 3;
            q[1] = ((b16[i + 0] & 0x07) << 2) | ((b16[i + 1] & 0xc0) >> 6);
            q[2] = (b16[i + 1] & 0x3e) >> 1;
            q[3] = ((b16[i + 1] & 0x01) << 4) | ((b16[i + 2] & 0xf0) >> 4);
            q[4] = ((b16[i + 2] & 0x0f) << 1) | ((b16[i + 3] & 0x80) >> 7);
            q[5] = (b16[i + 3] & 0x7c) >> 2;
            q[6] = ((b16[i + 3] & 0x03) << 3) | ((b16[i + 4] & 0xe0) >> 5);
            q[7] = (b16[i + 4] & 0x1f) << 0;
            for (let j = 0; j < 8; j++) {
                ret += UUID5.qtoch(q[j]);
            }
        }
        return ret;
    }

    toUUID() {
        return [
            UUID5.toHex(this.raw[15]),
            UUID5.toHex(this.raw[14]),
            UUID5.toHex(this.raw[13]),
            UUID5.toHex(this.raw[12]),
            "-",
            UUID5.toHex(this.raw[11]),
            UUID5.toHex(this.raw[10]),
            "-",
            UUID5.toHex(this.raw[9]),
            UUID5.toHex(this.raw[8]),
            "-",
            UUID5.toHex(this.raw[7]),
            UUID5.toHex(this.raw[6]),
            "-",
            UUID5.toHex(this.raw[5]),
            UUID5.toHex(this.raw[4]),
            UUID5.toHex(this.raw[3]),
            UUID5.toHex(this.raw[2]),
            UUID5.toHex(this.raw[1]),
            UUID5.toHex(this.raw[0]),
        ].join("");
    }

    toString() {
        return this.toUUID5();
    }

    generateSecret(auth_secret: Buffer) {
        const digest = createHmac("sha256", auth_secret).update(this.raw).digest();
        const dev_secret16 = Buffer.alloc(16);
        digest.copy(dev_secret16, 0, 0, 16);
        return new UUID5(dev_secret16);
    }

    equals(uuid5: UUID5) {
        if (16 === this.raw?.byteLength && 16 === uuid5?.raw?.byteLength) {
            return this.raw.equals(uuid5.raw);
        } else {
            return null === (this.raw || null) && null === (uuid5?.raw || null);
        }
    }

    static validateSecret(devKey: UUID5, devSecret: UUID5, auth_secret: Buffer) {
        let ret = false;
        try {
            const _devSecret = devKey.generateSecret(auth_secret);
            ret = _devSecret.equals(devSecret);
        } catch (e) {}
        return ret;
    }

    getVersion() {
        if (0x80 === (this.raw[7] & 0xc0)) {
            const version = (this.raw[9] & 0xf0) >> 4;
            return version;
        } else {
            return -1;
        }
    }
}
