import { useEffect, useState } from "react";
import { LCMDHookResult } from "../../app/types/LCMDHookResult";
import { SERVICES } from "../../model/services";

interface LCMDHookResultExtends extends LCMDHookResult<{ viewed: boolean }> {
    saveNPSViewed: (userId: string) => void;
}
export function useNPS(userId: string) {
    function checkRowKey(rowKey: string) {
        fetch(`${SERVICES.NPS_SERVICE}/${rowKey}`)
            .then(async (response) => {
                if (response?.ok) {
                    const data = await response.json();
                    setHookResult({ ...hookResult, isLoading: false, data: { viewed: Boolean(data.viewed) } });
                } else {
                    setHookResult({
                        ...hookResult,
                        isLoading: false,
                        isError: true,
                        error: new Error(`Response Code: ${response?.status}`),
                    });
                }
            })
            .catch((error) => {
                setHookResult({
                    ...hookResult,
                    isLoading: false,
                    isError: true,
                    error: new Error(`NPS Service is Offline: ${error}`),
                });
            });
    }

    function saveNPSViewed(rowKey: string) {
        fetch(SERVICES.NPS_SERVICE, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ "lcmd-user-id": rowKey }),
        }).then(async (response) => {
            if (response?.ok) {
                setHookResult({ ...hookResult, isLoading: false, data: { viewed: true } });
            } else {
                setHookResult({
                    ...hookResult,
                    isLoading: false,
                    isError: true,
                    error: new Error(`Response Code: ${response?.status}`),
                });
            }
        });
    }

    const [hookResult, setHookResult] = useState<LCMDHookResultExtends>({
        isLoading: true,
        isError: false,
        error: undefined,
        data: undefined,
        saveNPSViewed,
    });

    useEffect(() => {
        if (!userId || userId == "") {
            return;
        }
        checkRowKey(userId);
    }, [userId]);

    return hookResult;
}
