import * as React from "react";
import { ScrollablePane, SearchBox } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";
import { TaktzonesList } from "../FilterDialog";

export function TaktzonesScreen({
    dialog,
    route: {
        params: { tz, initiallySelected, onSelected },
    },
}) {
    const [collapsed, setCollapsed] = React.useState({});
    const [selected, setSelected] = React.useState(initiallySelected);
    const [search, setSearch] = React.useState(null);
    const onSearch = React.useMemo(
        () => (v: string) => {
            setSearch("string" === typeof v && v.length > 0 ? v : null);
        },
        [setSearch],
    );
    const onSearchChange = React.useMemo(
        () => (ev: any, v: string) => {
            setSearch("string" === typeof v && v.length > 0 ? v : null);
        },
        [setSearch],
    );
    React.useLayoutEffect(() => {
        dialog.setOptions({
            title: intl.get("filter.tz.title"),
            onOK: () => {
                onSelected(selected);
            },
            onClose: onSelected.bind(null, undefined),
            onCancel: onSelected.bind(null, undefined),
        });
    }, [dialog, selected]);
    return (
        <div
            style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                rowGap: 5,
                padding: 8,
            }}
        >
            <SearchBox
                placeholder={intl.get("filter.tz.search")}
                value={search || ""}
                onChange={onSearchChange}
                onSearch={onSearch}
            />
            <ScrollablePane
                styles={{
                    root: {
                        width: "100%",
                        height: "100%",
                        position: "relative",
                    },
                }}
            >
                <TaktzonesList
                    tz={tz}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    selected={selected}
                    setSelected={setSelected}
                    search={search}
                />
            </ScrollablePane>
        </div>
    );
}
