import { PriorityFilter } from "./PriorityFilter";
import { Overview } from "./Overview";
import { StatusFilter } from "./SatusFilter";
import { TradesFilter } from "./TradesFilter";
import { ResponsibleFilter } from "./ResponsibleFilter";
import { DateFilter } from "./DateFilter";
import { ToDoTypeFilter } from "./ToDoTypeFilter";
import { AreasFilter } from "./AreasFilter";
import { RaisedOnFilter } from "./RaisedOnFilter";
import { RaisedByFilter } from "./RaisedByFilter";
import { RouteObject } from "react-router-dom";

const filterRoutes: RouteObject[] = [
    {
        path: "/",
        element: <Overview />,
    },
    {
        path: "/priority-filter",
        element: <PriorityFilter />,
    },
    {
        path: "/status-filter",
        element: <StatusFilter />,
    },
    {
        path: "/trades-filter",
        element: <TradesFilter />,
    },
    {
        path: "/areas-filter",
        element: <AreasFilter />,
    },
    {
        path: "/responsible-filter",
        element: <ResponsibleFilter />,
    },
    {
        path: "/date-filter",
        element: <DateFilter />,
    },
    {
        path: "/raised-on-filter",
        element: <RaisedOnFilter />,
    },
    {
        path: "/todo-type-filter",
        element: <ToDoTypeFilter />,
    },
    {
        path: "/raised-by-filter",
        element: <RaisedByFilter />,
    },
];

export default filterRoutes;
