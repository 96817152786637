import React, { forwardRef } from "react";
import { intl } from "lcmd2framework";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

type PrimaveraViewProps = {
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    projects: any[];
};

export const PrimaveraView = forwardRef<HTMLFormElement, PrimaveraViewProps>(({ onSubmit, projects }, ref) => (
    <div className="flex min-h-[400px] w-full flex-col justify-center rounded-lg bg-slate-100">
        <form
            ref={ref}
            onSubmit={onSubmit}
            className="flex min-h-[400px] w-full flex-col items-center justify-center rounded-lg bg-slate-100 text-center"
        >
            <div className="flex w-96 flex-col items-center justify-center gap-4 text-center">
                <img width={160} height={160} className="mx-auto" src="/img/3d-folder-icon.png" />
                <div className="mx-auto text-sm font-normal leading-6 text-slate-500">
                    {intl.get("legacyProjectDialog.primavera.text")}
                </div>
                <div className="w-5/6 cursor-pointer">
                    <Select name="formActivityCode">
                        <SelectTrigger className="w-full border-gray-400">
                            <SelectValue placeholder={intl.get("legacyProjectDialog.primavera.placeholder")} />
                        </SelectTrigger>
                        <SelectContent side="bottom" className="h-60" avoidCollisions={false}>
                            <SelectGroup>
                                {projects.map((option) => (
                                    <SelectItem key={option.uid.toString()} value={option.uid.toString()}>
                                        {option.name}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </form>
    </div>
));

PrimaveraView.displayName = "PrimaveraView";
