// @dario: wie siehst du das ?
// @todo: sollten wir die ReasonCode Types in einem Module wrappen ?

import {
    Core,
    HiveGetter,
    ParticleGetter,
    ProcessGetter,
    ProcessId,
    TableExportHelper,
    TradesGetter
} from "../lcmd2core";


export type ReasonCodeId = string;
export type AttachedReasonCodeId = number;
export type ReasonCode = {
    id: ReasonCodeId
    name: string
    deleted?: boolean
}
export type AttachedReasonCode = {
    id: AttachedReasonCodeId

    /**
     * @description the id of the linked operation
     */
    opid: number

    /**
     * @description the id of the linked process (@see ProcessGetter to retrieve the Process Values)
     */
    pid: ProcessId
    /**
     * @description the id of the attached ReasonCode (@see ReasonCode)
     */
    rcid: ReasonCodeId
}

// @dario: wie findest du dieses Vorgehen?
module ReasonCodeParameter {
    type DefaultCommandParameter = {pid: ProcessId, reasonCodeId: ReasonCodeId, opId: number, attachedReasonCodeId: AttachedReasonCodeId};
    export type Create = Omit<DefaultCommandParameter, "attachedReasonCodeId">;
    export type Update = DefaultCommandParameter;
    export type Delete = Pick<DefaultCommandParameter, "attachedReasonCodeId" | "pid">

}


export class ReasonCodesService {

    constructor(private core: Core) {
    }

    getAll(applyGlobalFilter= false) : Map<ProcessId, ReturnType<Core["getProcessReasonCodes"]>> | null{
        const allProcessIds = this.core.getAllProcessesIds({processesOnly: true, includeAll: false});
        const processGetter = new ProcessGetter(this.core);
        const particleGetter = new ParticleGetter(this.core);
        const filter = this.core.getFilter();
        const dateFilter = filter?.d ? Core.filterToX1X2(filter.d) : null;
        const processesWithReasonCodes: Map<ProcessId, ReturnType<Core["getProcessReasonCodes"]>> = new Map();
        allProcessIds.forEach((processId) => {
            processGetter.reset(processId);
            if(applyGlobalFilter) {
                if(!processGetter.isVisible()){
                    return;
                }
                const startDate = processGetter.aux<number>("_x1");
                if(dateFilter && !(startDate >= dateFilter._x1 && startDate <= dateFilter._x2)){
                    return;
                }
            }

            const response = this.core.getProcessReasonCodes(processGetter, particleGetter);
            if(response){
                processesWithReasonCodes.set(processId, response);
            }
        })

        return processesWithReasonCodes.size > 0 ? processesWithReasonCodes : null;
    }

    getByProcessId(processId: ProcessId){
        const processGetter = new ProcessGetter(this.core);
        const particleGetter = new ParticleGetter(this.core);
        return this.core.getProcessReasonCodes(processGetter.reset(processId), particleGetter);
    }

    getByOpId(processId: ProcessId, opId: number){
        const attachedReasonCodes = this.getByProcessId(processId);
        if(!attachedReasonCodes){
            return;
        }

        let found;
        Array.from(attachedReasonCodes).forEach(([key, value]) => {
            if(value.opid === opId){
                found = value;
                return true;
            }
        });
        return found;
    }

    getAvailableReasonCodes({deleted = false}: {deleted: boolean}){
        const hGt=new HiveGetter(this.core);
        hGt.reset("lcmd.settings.global");
        const jsonValue = hGt.value<string>("reasoncodes");

        try {
            const reasonCodes: ReasonCode[] = JSON.parse(jsonValue);
            if(deleted === false){
                return reasonCodes.filter((reasonCode) => !reasonCode.deleted);
            }
            return reasonCodes;
        } catch (e) {
            return [];
        }
    }

    create({pid, reasonCodeId, opId}: ReasonCodeParameter.Create){
        this.core.setOrCreateReasonCode(null, pid, reasonCodeId, opId);
    }

    update({pid, reasonCodeId, opId, attachedReasonCodeId}: ReasonCodeParameter.Update){
        if(!attachedReasonCodeId){
            throw new Error("attachedReasonCodeId musst be set");
        }
        // @todo: check if pid has changed. if yes throw error or change api to work
        this.core.setOrCreateReasonCode(attachedReasonCodeId, pid, reasonCodeId, opId);
    }

    delete({pid, attachedReasonCodeId}: ReasonCodeParameter.Delete){
        if(!attachedReasonCodeId){
            throw new Error("attachedReasonCodeId musst be set");
        }
        // @todo: check if pid has changed. if yes throw error or change api to work
        this.core.deleteAttachedReasonCode(attachedReasonCodeId, pid);
    }

    async exportAsXLSX(opt?: {translations?: Map<string, string>}){
        const reasonCodeExportHelper = this.generateXlsx(opt);
        if(!reasonCodeExportHelper){
            throw new Error("ReasonCode export generation failed");
        }
        return this.fetchXlsx(reasonCodeExportHelper);
    }

    generateXlsx(opt?: {translations?: Map<string, string>}){
        const exportHelper: TableExportHelper = new TableExportHelper();

        try {
            const availableReasonCodes = this.getAvailableReasonCodes({deleted: true});
            const availableReasonCodesCache = new Map(availableReasonCodes.map(reasonCode => [reasonCode.id, reasonCode]));

            exportHelper.pushHeader(opt?.translations?.get("reasonCodeId") ? opt?.translations?.get("reasonCodeId") : "Störgrund ID");
            exportHelper.pushHeader(opt?.translations?.get("reasonCode") ? opt?.translations?.get("reasonCode") : "Störgrund");
            exportHelper.pushHeader(opt?.translations?.get("processId") ? opt?.translations?.get("processId") : "Prozess ID");
            exportHelper.pushHeader(opt?.translations?.get("processName") ? opt?.translations?.get("processName") : "Prozessname");
            exportHelper.pushHeader(opt?.translations?.get("trade") ? opt?.translations?.get("trade") : "Gewerk des Prozesses");

            const allAttachedReasonCodes = this.getAll();
            if(allAttachedReasonCodes){
                const processGetter = new ProcessGetter(this.core);
                const tradeGetter = new TradesGetter(this.core);
                allAttachedReasonCodes.forEach((processesWithAttachedReasonCodes) => {
                    processesWithAttachedReasonCodes?.forEach((attachedReasonCode) => {
                        processGetter.reset(attachedReasonCode.pid);
                        const trades = this.core.getTradesForProcess(processGetter, tradeGetter);
                        const reasonCodeDetails = availableReasonCodesCache.get(attachedReasonCode.rcid);
                        const processName = processGetter.value<string>("name", "");
                        const tradeName = trades.reduce((tradeNames, trade) => {
                            return tradeNames !== "" ?  `${tradeNames}, ${trade.name}` :  `${trade.name}`
                        }, "");

                        exportHelper.pushRow([attachedReasonCode.rcid, reasonCodeDetails?.name || "", processGetter.id, processName, tradeName]);
                    })
                })
            }
            return exportHelper;
        } catch (e) {

        }
    }

    async fetchXlsx(tableExportHelper: TableExportHelper){
        if(!this.core.auth_token){
            console.error("Authtoken is missing");
            throw new Error("Authtoken is missing");
        }

        try {
            const response = await tableExportHelper.fetchXSLX(this.core.auth_token);
            return response;
        } catch (e) {
            console.log("error fetching reasonCode Export", e);
        }
    }
}
