import { Panel, PanelType } from "@fluentui/react";

import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useLCMD } from "../../app/LCMContext";
import { HistoryView } from "./Routes/HistoryList/HistoryView";
import { createMemoryRouter, RouteObject, RouterProvider } from "react-router-dom";
import { HistoryFilter } from "./Routes/HistoryFilter/HistoryFilter";
import { HistoryDetails } from "./Routes/HistoryDetails/HistoryDetails";
import { ConflictList } from "./Routes/ConflictList/ConflictList";
import { CHANGES_PANEL_ROUTES } from "./Shared/routers.enum";
import { changesPanelClassNames } from "./Shared/styles";
import { ChangesPanelActionTypes, ChangesPanelInitialState, ChangesPanelReducer, CPContext } from "./Shared/CPContext";
import { HistoryActionName } from "../../app/interface";
import { LCMDChangesPanelChanges } from "../../app/LCMDContextTypes";
import { WebAppTelemetryFactory } from "../../app/services/WebAppTelemetry.service";

export function ChangesPanel(props: { onDismiss: () => void; changes: LCMDChangesPanelChanges | null }) {
    // const [changes, setChanges] = useReducer((current, updated) => (
    //     {...current, ...updated}
    // ), props.changes)

    const LCMD = useLCMD();
    const [cpState, dispatch] = useReducer(ChangesPanelReducer, ChangesPanelInitialState());
    const [showWarning, setShowWarning] = useState<boolean>(true);

    useEffect(() => {
        dispatch({ type: ChangesPanelActionTypes.SET_DISMISS, onDismiss: props.onDismiss });
    }, [props.onDismiss]);

    useEffect(() => {
        if (props.changes?.tasks?.length > 0) {
            dispatch({ type: ChangesPanelActionTypes.SET_TASKS, tasks: props.changes.tasks || [], _: props.changes._ });
        } else if (props.changes?.tasks?.length == 0) {
            dispatch({ type: ChangesPanelActionTypes.SET_TASKS, tasks: cpState.changes.tasks, _: props.changes._ });
        }
        if (props.changes?.history?.length > 0) {
            dispatch({
                type: ChangesPanelActionTypes.SET_HISTORY,
                history: [],
                currentPage: 0,
                nextPage: 1,
                _: props.changes._,
            });
        }

        if (props.changes.showConflicts != cpState.showConflicts) {
            dispatch({
                type: ChangesPanelActionTypes.SET_CONFLICT_ACTIVATION_STATUS,
                status: props.changes.showConflicts,
            });
        }
        LCMD.getProjectDetails((error, data) => {
            if (error) {
                console.warn(error.message);
                dispatch({
                    type: ChangesPanelActionTypes.SET_HISTORY_FILTER,
                    filter: new Map<string, HistoryActionName[]>(),
                });
                return;
            }

            if (sessionStorage.getItem(`lcmd.historyFilters_${data.pid.toString()}`)) {
                dispatch({
                    type: ChangesPanelActionTypes.SET_HISTORY_FILTER,
                    filter: new Map(JSON.parse(sessionStorage.getItem(`lcmd.historyFilters_${data.pid.toString()}`))),
                });
            }
        });
    }, [props.changes]);

    // useEffect(()=> {
    //     setChanges(props.changes)
    // }, [props])

    const routes: RouteObject[] = [
        {
            path: CHANGES_PANEL_ROUTES.HISTORY_VIEW,
            element: <HistoryView />,
            loader: () => {
                WebAppTelemetryFactory.trackEvent("global-changes-view");
                return true;
            },
        },
        {
            path: CHANGES_PANEL_ROUTES.CONFLICT_LIST,
            element: <ConflictList />,
        },
        {
            path: CHANGES_PANEL_ROUTES.HISTORY_FILTER,
            element: <HistoryFilter />,
        },
        {
            path: CHANGES_PANEL_ROUTES.HISTORY_DETAILS,
            element: <HistoryDetails />,
            loader: () => {
                WebAppTelemetryFactory.trackEvent("detail-view-of-global-changes");
                return true;
            },
        },
    ];

    const router = useMemo(() => {
        return createMemoryRouter(routes, {
            initialEntries: ["/"],
            initialIndex: 1,
        });
    }, []);

    //<Panel type={PanelType.custom} customWidth={"420px"} className={changesPanelClassNames.panel}
    //                   headerText={intl.get("changesPanel.Changes")} isBlocking={false}
    //                   isOpen={true} onDismiss={props.onDismiss} closeButtonAriaLabel="Close">
    return (
        <Panel
            type={PanelType.custom}
            customWidth={"420px"}
            className={changesPanelClassNames.panel}
            isBlocking={false}
            isOpen={true}
            onRenderHeader={() => <></>}
            styles={{
                commands: {
                    display: "none",
                    padding: 0,
                },
                header: {
                    display: "none",
                    padding: 0,
                },
                content: {
                    padding: 0,
                },
            }}
        >
            <CPContext.Provider value={{ state: cpState, dispatch }}>
                <React.StrictMode>
                    <RouterProvider router={router} />
                </React.StrictMode>
            </CPContext.Provider>
        </Panel>
    );
}
