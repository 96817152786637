﻿import { useLayoutEffect, useState } from "react";
import { useLCMD } from "../../app/LCMContext";
import { UploadAttachmentControl } from "../../legacy/GlobalHelperFluentUI";

type AttachmentFieldWithCommitProps = {
    label?: string;
    onChange: (value: { type?: string; value: string; suffix?: string }) => void;
    initialCard: { tid: number; aid: number; i: number };
};

export function AttachmentFieldWithCommit({ initialCard, label, onChange }: AttachmentFieldWithCommitProps) {
    const LCMD = useLCMD();
    const [cb, setCB] = useState(undefined);

    useLayoutEffect(() => {
        let mounted: boolean = true;
        const cb = LCMD.worker.registerCallback((data) => {
            if (mounted && data?.blobId) {
                onChange({
                    type: "attachment",
                    value: data.blobId,
                });
            }
        });
        setCB(cb);
        return () => {
            mounted = false;
        };
    }, [setCB, onChange]);

    return "number" === typeof cb ? (
        <div className="flex flex-row justify-between">
            <div className="py-1">{label || ""}</div>
            <div className="box-border grow-0 pt-0.5">
                <UploadAttachmentControl card={initialCard} statusKey="attachments" cb={cb} />
            </div>
        </div>
    ) : null;
}
